import { render, staticRenderFns } from "./Request.vue?vue&type=template&id=07388566&scoped=true"
import script from "./Request.vue?vue&type=script&lang=js"
export * from "./Request.vue?vue&type=script&lang=js"
import style0 from "./Request.vue?vue&type=style&index=0&id=07388566&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07388566",
  null
  
)

export default component.exports