<template>
  <div id="invoice-container">
    <div class="invoice">
      <div class="invoice-box">
        <table>
          <tr>
            <td style="text-align: right">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a3/Senator_International_Logo_Stand_2016.svg/1200px-Senator_International_Logo_Stand_2016.svg.png"
                style="max-width:180px;">
              <br>
              <span style="text-align-last: right; margin: 0px; color: darkred">First class global logistics</span>
              <hr style=" width:32%; text-align:left; margin-right:5px; border-color:red; margin-top:0px; margin-bottom: 0px;">
            </td>
          </tr>
          <tr>
            <td>
              <div class="primary--dark d-inline-block">
                <p>2-Importacion-1-Colombia-Mexico</p>
              </div>
              <div class="primary--dark d-inline-block float-right">
                <p>7/29/2021</p>
              </div>
            </td>
          </tr>
        </table>
        <table class="elevation-border">
          <tr>
            <td class="px-2">
              <div class="h-100">
                <div class="d-block my-auto">
                  <img src="https://upload.wikimedia.org/wikipedia/commons/2/2e/Logo_RP.jpg" style=" max-width:200px"/>
                  <p class="primary--dark" style="margin-top: 5px;">Ruhrpumpen</p>
                </div>
              </div>
            </td>
            <td
              class="elevation-border"
              style="width: 200px; min-height: 150px; text-align-last:center;"
            >
              <h5 class="primary--dark">Total estimado</h5>
              <div>
                <div class="primary--dark d-inline-block">
                  <h1>100</h1>
                </div>
                <div class="d-inline-block primary--dark">
                  <h5 class="font-weight-light">USD</h5>
                </div>
              </div>
              <h5 class="primary--dark">Vigencía: </h5>
              <h5 class="primary--dark"><small class="font-weight-light">7/22/2021 - </small></h5>
            </td>
          </tr>
        </table>
      </div>
      <div class="timeline-box">
        <table style="width: 100%; height: 100px">
          <tbody>
            <tr>
              <td>
                <div id="timeline-wrap">
                  <div id="timeline"></div> <!-- This is the individual marker-->
                  <div class="marker mfirst timeline-icon one">
                    <img
                        class="img-icon-small"
                        src="https://www.nicepng.com/png/full/335-3350851_other-vehicles-icon-truck-icon-white-png.png"
                    />
                    <div class="marker-description text-center line-height-small d-inline-block">
                      <h6 class="primary--dark font-weight-bold">Bogotá</h6>
                      <small class="font-weight-light primary--dark font-size-small">Origen</small>
                    </div>
                  </div> <!-- / marker -->
                  <!-- This is the individual marker-->
                  <div class="marker m2 timeline-icon two">
                    <img
                        src="https://pacificoceanmarine.com/wp-content/uploads/2016/02/icon-charter-white.png"
                        class="img-icon-small"
                    />
                    <div class="marker-description text-center line-height-small d-inline-block">
                      <h6 class="primary--dark font-weight-bold">Buenaventura</h6>
                      <small class="font-weight-light primary--dark font-size-small">POL</small>
                    </div>
                  </div> <!-- / marker -->
                  <div class="marker m3 timeline-icon two">
                    <img
                        src="https://pacificoceanmarine.com/wp-content/uploads/2016/02/icon-charter-white.png"
                        class="img-icon-small"
                    >
                    <div class="marker-description text-center line-height-small d-inline-block">
                      <h6 class="primary--dark font-weight-bold">Manzanillo</h6>
                      <small class="font-weight-light primary--dark font-size-small">POD</small>
                    </div>
                  </div> <!-- / marker -->
                  <div class="marker mlast timeline-icon two">
                    <img
                        src="https://www.nicepng.com/png/full/335-3350851_other-vehicles-icon-truck-icon-white-png.png"
                        class="img-icon-small"
                    >
                    <div class="marker-description text-center line-height-small d-inline-block">
                      <h6 class="primary--dark font-weight-bold">Monterrey</h6>
                      <small class="font-weight-light primary--dark font-size-small">Destino</small>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="my-2 invoice-body">
        <table class="my-2 w-100">
          <thead>
            <tr>
              <th style="width: 50%" class="px-2 text-left">Manejo de carga en origen</th>
              <th style="width: 16%" class="px-2 text-left">Costo</th>
              <th style="width: 16%" class="px-2 text-left">Cantidad</th>
              <th style="width: 16%" class="px-2 text-left">Total</th>
            </tr>
          </thead>
        </table>
        <table class="w-100 elevation-border py-2" style="min-height: 40px">
          <tbody>
            <tr>
              <td style="width: 50%">&nbsp;</td>
              <td style="width: 16%">&nbsp;</td>
              <td style="width: 16%">&nbsp;</td>
              <td style="width: 16%" class="text-left font-size-small px-2">
                <span class="font-weight-bold">Subtotal:</span>
                <span> 416</span>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="my-2 w-100">
          <thead>
            <tr>
              <th style="width: 50%" class="px-2 text-left">Manejo de carga en origen</th>
              <th style="width: 16%" class="px-2 text-left">Costo</th>
              <th style="width: 16%" class="px-2 text-left">Cantidad</th>
              <th style="width: 16%" class="px-2 text-left">Total</th>
            </tr>
          </thead>
        </table>
        <table class="w-100 elevation-border py-2" style="min-height: 40px">
          <tbody>
            <tr>
              <td style="width: 50%" class="px-2 text-left">Prueba</td>
              <td style="width: 16%" class="px-2 text-left">10</td>
              <td style="width: 16%" class="px-2 text-left">5</td>
              <td style="width: 16%" class="px-2 text-left">50</td>
            </tr>
            <tr>
              <td style="width: 50%">&nbsp;</td>
              <td style="width: 16%">&nbsp;</td>
              <td style="width: 16%">&nbsp;</td>
              <td style="width: 16%" class="text-left font-size-small px-2">
                <span class="font-weight-bold">Subtotal:</span>
                <span> 50</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="my-2 notes-section">
        <table class="w-100">
          <thead>
            <tr>
              <th class="px-2 text-left"><span>Notas</span></th>
            </tr>
          </thead>
        </table>
        <table class="elevation-border w-100">
          <tbody>
            <tr>
              <td class="float-left primary--dark px-2 py-2">
                <ul style="text-align:-webkit-left;">
                  <li><small>Estás son las notas del pricer chido</small></li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="my-2 invoice-footer">
        <div class="py-2 px-2">
          <p style=" color: #A9A9A9; margin: 0px"><small>e-mail - oscar.ventas@senator-int.com</small></p>
          <p style="color: #A9A9A9; margin: 0px"><small>telefono - 8120688363</small></p>
          <p style="color: #A9A9A9; margin: 0px"><small> web - www.senator.com</small></p>
        </div>
        <div class="w-100 text-right">
          <small class="font-weight-bold">Terminos y condiciones</small>
          <br>
          <small class="primary--dark font-size-x-small line-height-x-small">
            “Tarifa basada en requerimientos por parte del cliente en caso de cambiar, la tarifa puede variar. <br /> * Las
            Tarifas tendran la vigencia establecida en esta cotización. <br /> *Estas tarifas son aplicables para carga
            general / No aplica para mercancias peligrosas, perecederas, con sobrepeso o sobre dimensión, salvo la
            indicación de lo contrario en la tarifa <br /> *Las tarifas antes mencionadas estan sujetas a cambio con o sin
            previo aviso. <br /> *Estas tarifas cambiaran de acuerdo a los cambios en la descripción de la mercancia. <br />
            *Servicio sujeto a disponibilidad de equipo y espacios. <br /> *Dependiendo la temporada las lineas pueden
            implementar recargos como PSS, GRl, etc., con o sin previo aviso. <br /> *No incluyen empaque y embalaje de las
            mercancias, estiba de carga y descarga de mercancia y/o contenedores, ni fumigación. <br /> “La presente oferta
            no cubre ningun servicio adicional a los mencionados y cotizados.. <br /> “La presente oferta excluye cualquier
            cargo fuera de nuestro control/responsabilidad. <br /> * No incluye maniobras para carga y descarga de la
            mercancia, esta son responsabilidades tecnicas del embarcador y/o cliente, <br /> *Todos los tiempo de transito
            son estimados, ya sea en servicio, Terrestre, Maritimo o Aéreo. <br /> *Como condición para la utilización de
            servicios por parte de Senator Internacional, el cliente libera a Senator Internacional de cualquier
            responsabilidad legal que derive o surja con motivo del transporte terrestre, maritimo o aéreo involucrado.
            <br /> *No incluye seguro de la mercancia, en caso de requerirla es el .65% sobre el valor factura, En caso de
            no
            contratar seguro de la mercancia, la mercancia viaja bajo riesgo total del cliente. <br /> *No se aceptan
            reclamos especiales, perdidas de producción, caducidad de la vigencia del producto, retrasos en entregas o en
            recepción de mercancias en ningun caso, considerando, entre otros, los siguientes: <br /> (1) retrasos por mal
            clima, accidente o carga <br /> (2) retrasos en transito causados por parte del FFCC / comitentes /
            Transportistas o causas ajenas a Senator Internacional. <br /> (3) cambios de horario. <br /> (4) retrasos por
            falta de documentación por parte del cliente o cualquier tipo de retraso ajeno a Senator Internacional. <br />
            (5) retrasos por cuenta del AA externo a Senator Internacional. <br /> (6) retrasos por parte de la naviera
            ajenas a Senator Internacional <br /> “No incluye pesaje de la mercancia, si se requiere, favor de solicitarlo
            previamente por escrito. </small>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "PdfTest",
  mounted() {
    this.$store.dispatch('working', false)
  }
}
</script>

<style scoped>
#invoice-container {
  max-width: 920px;
}

#timeline-wrap {
  position: relative;
  margin-bottom: 30px;
  width: 80%;
  margin-left: 60px;
}

#padingg {
  padding-top: 10px;
  padding-left: 10px;
}

#timeline {
  height: 1px;
  width: 100%;
  background-color: #aabbc4;
  position: relative;
}

.marker {
  z-index: 1000;
  color: #024D96;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  margin-left: -25px;
  background-color: #024D96;
  border-radius: 50%;
}

.marker:hover {
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.timeline-icon.one {
  background-color: #024D96 !important;
}

.timeline-icon.two {
  background-color: #024D96 !important;
}

.timeline-icon.three {
  background-color: #024D96 !important;
}

.timeline-icon.four {
  background-color: #024D96 !important;
}

.mfirst {
  top: -25px;
}

.m2 {
  top: -25px;
  left: 32.5%
}

.m3 {
  top: -25px;
  left: 66%
}

.mlast {
  top: -25px;
  left: 100%
}

.timeline-panel {
  margin-top: 20%;
  width: 500px;
  height: 200px;
  background-color: #cbd0df;
  border-radius: 15px;
  position: relative;
  text-align: left;
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
  float: left;
}

.timeline-panel:after {
  content: '';
  position: absolute;
  margin-top: -12%;
  left: 10%;
  width: 0;
  height: 0;
  border: 12px solid transparent;
  border-bottom: 15px solid #cbd0df;
}

.invoice {
  max-width: 800px;
  margin: auto;
  padding: 30px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  font-size: 16px;
  line-height: 24px;
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  color: #555;
  border-radius: 15px 15px 15px 15px;
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.invoice-box table tr td:nth-child(2) {
  text-align: right;
}

.invoice-box table tr.information table td {
  padding-bottom: 40px;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

/** RTL **/
.rtl {
  direction: rtl;
  font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
}

.rtl table {
  text-align: right;
}

.rtl table tr td:nth-child(2) {
  text-align: left;
}

.primary--dark {
  color: #A9A9A9
}

.d-inline-block{
  display: inline-block
}

.float-right {
  float: right
}

.px-2 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.elevation-border{
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .15)
}

.font-weight-light{
  font-weight: lighter;
}
.my-auto{
  margin-top: auto;
  margin-bottom: auto;
}

.img-icon-small{
  vertical-align: middle;
  width: 1.5em;
}

.text-center{
  text-align: center
}

.font-size-small{
  font-size: small;
}

.font-size-x-small{
  font-size: x-small;
}

.line-height-small{
  line-height: 15px
}

.line-height-x-small{
  line-height: 5px
}

.font-weight-bold {
  font-weight: bold;
}

.marker-description{
  width: 100px;
  position: absolute;
  left: -50%;
  bottom: -70%;
}

.px-1{
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.my-1{
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.my-2{
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.py-2{
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.w-100{
  width: 100%;
}

.text-left{
  text-align: left !important;
}

.text-right{
  text-align: right !important;
}
</style>
