<template>
  <div>
    <v-bottom-sheet inset v-model="sheet">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="blue darken-2" dark right bottom fixed v-bind="attrs" v-on="on" fab>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar flat dark color="primary">
          <v-toolbar-title><v-icon left>mdi-table-edit</v-icon> Agregar Cliente </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark left @click="sheet = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <Customers :Companies="Customers" @update="getCustomers"></Customers>
      </v-card>
    </v-bottom-sheet>

    <v-card elevation="0" tile width="2000" class="pt-10">

      <v-row justify="center">
        <v-col md="12">
          <v-container style="margin-left: 0px; margin-right: 20px"  tile elevation="0">
            <v-row justify="center">
              <v-col md="7">
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    dense
                    solo
                    label="Buscar"
                    single-line
                    hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <template>
              <v-row justify="center">
                <v-col md="12">
                  <v-container>
                    <v-data-table
                        :headers="HeaderCustomers"
                        :items-per-page="50"
                        :items="Customers"
                        :hide-default-footer="true"
                        item-key="id"
                    >
                    <!-- Data validation -->
                    <template v-slot:item.name = {item}>
                        {{item.name ? item.name : 'NA'}}
                    </template>
                    <template v-slot:item.address = {item}>
                        {{item.address ? item.address : 'NA'}}
                    </template>
                      <template v-slot:item.taxNumber = {item}>
                        {{item.taxNumber ? item.taxNumber : 'NA'}}
                      </template>
                    <template v-slot:item.phone = {item}>
                        {{item.phone ? item.phone : 'NA'}}
                    </template>
                    <template v-slot:item.email = {item}>
                        {{item.email ? item.email : 'NA'}}
                    </template>
                    <template v-slot:item.configuration.annualShipments = {item}>
                        {{item.configuration.annualShipments ? item.configuration.annualShipments : 'NA'}}
                    </template>
                    <template v-slot:item.configuration.creditDays = {item}>
                        {{item.configuration.creditDays ? item.configuration.creditDays : 'NA'}}
                    </template>
                    <!-- Data validation -->
                      <template v-slot:item.edit="{item}">
                        <v-btn text @click="GetDataCustomer(item.id)">
                          <v-icon color="primary">mdi-table-edit</v-icon>
                        </v-btn>
                      </template>

                      <template v-slot:item.reporte="{item}">
                        <v-btn text @click="GetDataCustomerReporte(item.id, item.name)">
                          <v-icon color="primary">mdi-file-excel</v-icon>
                        </v-btn>
                      </template>

                    </v-data-table>
                  </v-container>
                </v-col>
              </v-row>
            </template>
          </v-container>

        </v-col>
      </v-row>





    </v-card>

    <v-dialog v-model="dialog" width="1000">

      <v-card>
        <v-toolbar
            color="primary"
            dark
            flat
        >
          <template v-slot:extension>
            <v-tabs
                v-model="tabs"
            >
              <v-tab>
                Editar cliente
              </v-tab>
              <v-tab @click="getCsupplier">
                Provedor de cliente
              </v-tab>
              <v-tab>
                Compradores de clientes
              </v-tab>
              <v-tab @click="getClientEmployees">
                Usuarios Cliente
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>

        <v-tabs-items v-model="tabs">
          <v-tab-item>
            <v-card flat>
              <customers :CustomerData="DataCustomers" :Companies="Customers" @update="getCustomers">
              </customers>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <template>
              <v-row justify="center">
                <v-col md="12">
                  <v-container>
                    <v-data-table
                        :headers="HeaderSCustomers"
                        :items-per-page="50"
                        :items="DataCsuppliers"
                        :hide-default-footer="true"
                        item-key="id"
                        :search="search"
                    >
                      <!-- Data validation -->
                      <template v-slot:item.name = {item}>
                        {{item.name ? item.name : 'NA'}}
                      </template>
                      <template v-slot:item.address = {item}>
                        {{item.address ? item.address : 'NA'}}
                      </template>
                      <template v-slot:item.phone = {item}>
                        {{item.phone ? item.phone : 'NA'}}
                      </template>
                      <template v-slot:item.email = {item}>
                        {{item.email ? item.email : 'NA'}}
                      </template>
                      <!-- Data validation -->
                      <template v-slot:item.edit="{item}">
                        <v-btn text @click="getClientSupplier(item.id)">
                          <v-icon color="primary">mdi-table-edit</v-icon>
                        </v-btn>
                      </template>

                    </v-data-table>
                  </v-container>
                </v-col>
              </v-row>
            </template>

            <template>
              <v-form v-model="valid">
                <v-container>
                  <v-row>
                    <v-col
                        cols="12"
                        md="4"
                    >
                      <v-text-field
                          v-model="CsupplierName"
                          :counter="10"
                          label="Nombre de empresa"
                          required
                      ></v-text-field>
                    </v-col>

                    <v-col
                        cols="12"
                        md="4"
                    >
                      <v-text-field
                          v-model="CsupplierEmail"
                          :counter="10"
                          label="Correo elctronico"
                      ></v-text-field>
                    </v-col>

                    <v-col
                        cols="12"
                        md="4"
                    >
                      <v-text-field
                          v-model="CsupplierPhone"
                          label="Tel"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                        cols="12"
                        md="4"
                    >
                      <v-text-field
                          v-model="CsupplierRfc"
                          :counter="10"
                          label="RFC"
                          required
                      ></v-text-field>
                    </v-col>

                    <v-col
                        cols="12"
                        md="8"
                    >
                      <v-text-field
                          v-model="CsupplierAddress"
                          label="Direccion"
                      ></v-text-field>
                    </v-col>

                  </v-row>
                  <v-row >
                    <v-col style="text-align: right">
                      <v-btn @click="enviarEvidencia" color="primary">
                        Crear Nuevo
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </template>
            <v-dialog
                v-model="dialog2"
                width="1000"
            >

              <v-card>
                <v-form v-model="valid">
                  <v-container>
                    <v-row>
                      <v-col cols="8">
                        <v-row>
                          <v-col
                              cols="12"
                              md="4"
                          >
                            <v-text-field
                                v-model="ClientSupplier.name"
                                label="Nombre de empresa"
                                item-text="name"
                                required
                                item-value="name"
                            ></v-text-field>
                          </v-col>

                          <v-col
                              cols="12"
                              md="4"
                          >
                            <v-text-field
                                v-model="ClientSupplier.email"
                                label="Correo elctronico"
                                item-text="email"
                            ></v-text-field>
                          </v-col>

                          <v-col
                              cols="12"
                              md="4"
                          >
                            <v-text-field
                                v-model="ClientSupplier.telephone"
                                label="Tel"
                                item-text="telephone"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                              cols="12"
                              md="4"
                          >
                            <v-text-field
                                v-model="ClientSupplier.taxNumber"
                                label="RFC"
                                item-text="taxNumber"
                            ></v-text-field>
                          </v-col>

                          <v-col
                              cols="12"
                              md="8"
                          >
                            <v-text-field
                                v-model="ClientSupplier.address"
                                label="Direccion"
                                item-text="address"
                            ></v-text-field>
                          </v-col>

                        </v-row>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-col style="text-align-last: right">
                            <v-btn @click="PaymentAdvanced(id)" color="primary">
                              actualizar cliente
                            </v-btn>
                          </v-col>
                        </v-card-actions>
                        <v-divider></v-divider>
                            <v-row>
                              <v-col
                                  cols="12"
                                  md="3"
                              >
                                <v-text-field
                                    v-model="NombreProducto"
                                    label="Nombre de product"
                                    item-text="name"
                                    required
                                    item-value="name"
                                ></v-text-field>
                              </v-col>

                              <v-col
                                  cols="12"
                                  md="2"
                              >
                                <v-text-field
                                    v-model="sku"
                                    label="sku"
                                ></v-text-field>
                              </v-col>

                              <v-col
                                  cols="12"
                                  md="2"
                              >
                                <v-text-field
                                    v-model="unitPrice"
                                    label="Precio unitario"
                                ></v-text-field>
                              </v-col>
                              <v-col
                                  cols="12"
                                  md="2"
                              >
                                <v-select
                                    v-model="currencyId"
                                    label="Moneda"
                                    :items="Currencies"
                                    item-text="code"
                                    item-value="id"
                                ></v-select>
                              </v-col>

                            </v-row>
                            <v-row>
                              <v-col cols="12">
                                <v-text-field
                                    v-model="description"
                                    label="Descripcion"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row style="justify-content: right">
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-col>
                                    <v-btn @click="postProdcts" color="primary">
                                      agregar Producto
                                    </v-btn>
                                  </v-col>
                                </v-card-actions>
                            </v-row>
                      </v-col>
                      <v-col cols="4">
                        <v-col md="12">
                          <v-container>
                            <v-data-table
                                :headers="HeaderSProductos"
                                :items-per-page="50"
                                :items="DataCProducts"
                                :hide-default-footer="true"
                                item-key="id"
                                :search="search"
                            >
                              <!-- Data validation -->
                              <template v-slot:item.name = {item}>
                                {{item.name ? item.name : 'NA'}}
                              </template>
                              <!-- Data validation -->
                              <template v-slot:item.edit="{item}">
                                <v-btn text @click="getProdct(item.id)">
                                  <v-icon color="primary">mdi-table-edit</v-icon>
                                </v-btn>
                              </template>

                            </v-data-table>
                          </v-container>
                        </v-col>

                        <v-dialog
                            v-model="dialog6"
                            width="700"
                        >
                          <v-card>
                            <v-form v-model="valid">
                              <v-container>
                                <v-row>
                                  <v-col cols="12"
                                         md="3">
                                    <v-text-field
                                        v-model="DataCProduct.name"
                                        label="Nombre de producto"
                                        required
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                      cols="12"
                                      md="3"
                                  >
                                    <v-text-field
                                        v-model="DataCProduct.sku"
                                        label="sku"
                                        required
                                    ></v-text-field>
                                  </v-col>

                                  <v-col
                                      cols="12"
                                      md="3"
                                  >
                                    <v-text-field
                                        v-model="DataCProduct.unitPrice"
                                        label="Precio unitario"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                      cols="12"
                                      md="3"
                                  >
                                    <v-select
                                        v-model="DataCProduct.currencyId"
                                        label="Moneda"
                                        :items="Currencies"
                                        item-text="code"
                                        item-value="id"
                                    ></v-select>
                                  </v-col>

                                </v-row>
                                <v-row>
                                  <v-col
                                      cols="12"
                                      md="12"
                                  >
                                    <v-text-field
                                        v-model="DataCProduct.description"
                                        label="Descripción"
                                        required
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-form>



                            <v-divider></v-divider>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-row>
                                <v-col style="text-align-last: right">
                                  <v-btn @click="PutProdct" color="primary">
                                    actualizar
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card>
            </v-dialog>
          </v-tab-item>
          <v-tab-item>
            <template>
              <v-row justify="center">
                <v-col md="12">
                  <v-container>
                    <v-data-table
                        :headers="HeaderSBuyers"
                        :items-per-page="50"
                        :items="buyers"
                        :hide-default-footer="true"
                        item-key="id"
                        :search="search"
                    >
                      <!-- Data validation -->
                      <template v-slot:item.name = {item}>
                        {{item.name ? item.name : 'NA'}}
                      </template>
                      <template v-slot:item.address = {item}>
                        {{item.address ? item.address : 'NA'}}
                      </template>
                      <template v-slot:item.phone = {item}>
                        {{item.phone ? item.phone : 'NA'}}
                      </template>
                      <template v-slot:item.email = {item}>
                        {{item.email ? item.email : 'NA'}}
                      </template>
                      <!-- Data validation -->
                      <template v-slot:item.edit="{item}">
                        <v-btn text @click="getBuyer(item.id)">
                          <v-icon color="primary">mdi-table-edit</v-icon>
                        </v-btn>
                      </template>

                    </v-data-table>
                  </v-container>
                </v-col>
              </v-row>
            </template>

            <template>
              <v-form v-model="valid">
                <v-container>
                  <v-row>
                    <v-col cols="12"
                           md="3">
                      <v-text-field
                          v-model="CsupplierBussnesName"
                          label="Nombre de Empresa"
                          required
                      ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        md="3"
                    >
                      <v-text-field
                          v-model="CsupplierName"
                          label="Nombre de contacto"
                          required
                      ></v-text-field>
                    </v-col>

                    <v-col
                        cols="12"
                        md="3"
                    >
                      <v-text-field
                          v-model="CsupplierEmail"
                          label="Correo elctronico"
                      ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        md="3"
                    >
                      <v-text-field
                          v-model="CsupplierPhone"
                          label="Tel"
                      ></v-text-field>
                    </v-col>

                  </v-row>
                  <v-row>
                    <v-col
                        cols="12"
                        md="3"
                    >
                      <v-text-field
                          v-model="CsupplierRfc"
                          label="RFC"
                          required
                      ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        md="3"
                    >
                      <v-text-field
                          v-model="CbuyerState"
                          label="Estado"
                          required
                      ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        md="3"
                    >
                      <v-text-field
                          v-model="CbuyerCity"
                          label="Ciudad"
                          required
                      ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        md="3"
                    >
                      <v-text-field
                          v-model="CbuyerCountry"
                          label="Pais"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                        cols="12"
                        md="12"
                    >
                      <v-text-field
                          v-model="CsupplierAddress"
                          label="Direccion"
                      ></v-text-field>
                    </v-col>


                  </v-row>
                  <v-row >
                    <v-col style="text-align: right">
                      <v-btn @click="NewBuyer" color="primary">
                        Crear Nuevo
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </template>
            <v-dialog
                v-model="dialog3"
                width="700"
            >

              <v-card>
                <v-form v-model="valid">
                  <v-container>
                    <v-row>
                      <v-col cols="12"
                             md="3">
                        <v-text-field
                            v-model="Clientbuyer.businessName"
                            label="Nombre de Empresa"
                            required
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          md="3"
                      >
                        <v-text-field
                            v-model="Clientbuyer.name"
                            label="Nombre de contacto"
                            required
                        ></v-text-field>
                      </v-col>

                      <v-col
                          cols="12"
                          md="3"
                      >
                        <v-text-field
                            v-model="Clientbuyer.email"
                            label="Correo elctronico"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          md="3"
                      >
                        <v-text-field
                            v-model="Clientbuyer.telephone"
                            label="Tel"
                        ></v-text-field>
                      </v-col>

                    </v-row>
                    <v-row>
                      <v-col
                          cols="12"
                          md="3"
                      >
                        <v-text-field
                            v-model="Clientbuyer.taxNumber"
                            label="RFC"
                            required
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          md="3"
                      >
                        <v-text-field
                            v-model="Clientbuyer.state"
                            label="Estado"
                            required
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          md="3"
                      >
                        <v-text-field
                            v-model="Clientbuyer.city"
                            label="Ciudad"
                            required
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          md="3"
                      >
                        <v-text-field
                            v-model="Clientbuyer.country"
                            label="Pais"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                          cols="12"
                          md="12"
                      >
                        <v-text-field
                            v-model="Clientbuyer.address"
                            label="Direccion"
                        ></v-text-field>
                      </v-col>


                    </v-row>
                  </v-container>
                </v-form>



                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-row>
                    <v-col style="text-align-last: right">
                      <v-btn @click="putBuyer" color="primary">
                        actualizar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-tab-item>
          <v-tab-item>
            <v-row class="pa-2" v-for="(user, index) in usersClient" :key="index">
              <v-col>
                <v-text-field v-model="user.name" label="Nombre"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field v-model="user.lastName" label="Apellido"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field v-model="user.email" label="E-mail"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field v-model="user.jobPosition" label="Puesto"></v-text-field>
              </v-col>
              <v-col class="d-flex justify-center align-center">
<!--                  <v-btn
                      icon
                      color="error"
                      small
                      @click.native.stop
                      @click="deleteSupplierSummarie(index)"
                      v-if="suppliersSummarie.length > 1"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>-->
                <v-btn
                    icon
                    color="primary"
                    small
                    @click="addEmployee"
                >
                  <v-icon>mdi-plus-circle</v-icon>
                </v-btn>

              </v-col>
            </v-row>
            <v-row >
              <v-col class="d-flex justify-end align-center">
                <v-btn color="primary" @click="saveEmployee()" >Guardar</v-btn>

              </v-col>
            </v-row>
<!--            <v-row class="d-flex justify-end">
              <v-btn color="primary" @click="addEmployees" >Guardar</v-btn>
            </v-row>-->
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import Customers from "../../components/Accounts_Admin/Customers.vue";
import debouncePromise from "debounce-promise";
var fileDownload = require('js-file-download')
export default {
name: "List-Customers",
  components: {Customers},
  data(){
    return{
      valid: null,
      tabs: 0,
      sku: null,
      unitPrice: null,
      Producto:null,
      Currencies: [],
      currencyId: null,
      name:null,
      NombreProducto: null,
      DataCProduct:[],
      description: null,
      dialog3:null,
      CsupplierRfc:null,
      CsupplierPhone:null,
      CsupplierEmail:null,
      CsupplierAddress:null,
      CsupplierBussnesName: null,
      dialog:false,
      Clientbuyer:[],
      dialog2: false,
      sheet: false,
      CsupplierName:null,
      CBuyerName:null,
      CBuyerBussnesName: null,
      CBuyerRfc: null,
      CBuyerAddress: null,
      CBuyerEmail: null,
      CBuyerPhone: null,
      CbuyerState: null,
      CbuyerCity: null,
      CbuyerCountry: null,
      doc4: null,
      dialog6: false,
      id:null,
      Csupplier:null,
      search:'',
      CustomersStatus:null,
      buyers: [],
      DataCProducts: [],
      DataCsuppliers:[],
      ClientSupplier:[],
      DataCustomers:[],
      HeaderCustomers:[
        {text: 'Company', value: 'name'},
        {text: 'Address', value: 'address'},
        {text: 'RFC', value: 'taxNumber'},
        {text: 'Codigo postal', value: 'postalCode'},
        {text: 'Phone', value: 'phone'},
        {text: 'Email', value: 'email'},
        {text: 'Editar', value: 'edit'},
        {text: 'Reporte', value: 'reporte'}
      ],
      HeaderSCustomers:[
        {text: 'Company', value: 'name'},
        {text: 'ID', value: 'id'},
        {text: 'Address', value: 'address'},
        {text: 'Phone', value: 'telephone'},
        {text: 'Email', value: 'email'},
        {text: 'RFC', value: 'taxNumber'},
        {text: 'Editar', value: 'edit'}
      ],
      HeaderSProductos:[
        {text: 'Producto', value: 'name'},
        {text: 'Editar', value: 'edit'}
      ],
      HeaderSBuyers:[
        {text: 'Company', value: 'name'},
        {text: 'ID', value: 'id'},
        {text: 'Address', value: 'address'},
        {text: 'Phone', value: 'telephone'},
        {text: 'Email', value: 'email'},
        {text: 'RFC', value: 'taxNumber'},
        {text: 'Editar', value: 'edit'}
      ],
      Customers:[],
      usersClient: [
        {
          name: null,
          lastName: null,
          email: null,
          jobPosition: null,
          id: 0
        }
      ],
      companyId: null,
    }
  },
  watch: {
    search(val) {
      this.searchClients(val)
    },
  },
  methods:{
    searchClients: debouncePromise(function (search){
      if (search === '') {
        return
      }
      this.getClients(search)
    }, 1000),
    getClients(search) {
      this.$http.get(`Companies/find`,{
            params: {
              name: search,
            }
          }
      ).then((response) => {
        this.Customers = response.data.data
      })
    },
    addEmployee() {
      let obj = {
        name: null,
        lastName: null,
        email: null,
        jobPosition: null,
        id: 0,

      }
      this.usersClient.push(obj)
    },
    saveEmployee() {
      // user.companyId = this.companyId;
      this.DataCustomers.employees = this.usersClient
      this.DataCustomers.city = null
      this.DataCustomers.country = null
      this.$store.dispatch("working", true);
        this.$http.put(`Companies/${this.companyId}`,this.DataCustomers).then((response) => {
          console.log(response)
          if (response.status === 200) {
            this.$toast.info('Usuario agregado con exito')
          } else {
            this.$toast.error('Algo salio contacte a sistemas')
          }
        }).catch((error) => {
          console.log(error)
        }).finally(() => {
          this.getClientEmployees();
          this.$store.dispatch("working", false);
        })
      /*else {

      this.$http.put(`Companies/${this.companyId}/clients/${user.id}`,user).then((response) => {
        console.log(response)
        if (response.status === 200) {
          this.$toast.info('Usuario actualizado con exito')
        } else {
          this.$toast.error('Algo salio contacte a sistemas')
        }
      }).catch((error) => {
        console.log(error)
      }).finally(() => {
        this.getClientEmployees();
        this.$store.dispatch("working", false);
      })
      }*/

    },
    getClientEmployees() {
      this.$store.dispatch("working", true);
      this.$http.get(`Companies/${this.companyId}/employees`).then((response) => {
        console.log(response)
        if (response.data.data.length > 0) {
          this.usersClient = response.data.data;
        }
      }).catch((error) => {
        console.log(error)
      }).finally(() => {
        this.$store.dispatch("working", false);
      })
    },
    getProdcts() {
      // this.$store.dispatch("working", true);
      this.$http.get(`Companies/${this.DataCustomers.id}/products`).then((response) => {
        if (response.data.code == 200) {
          this.DataCProducts = response.data.data;
        }
      });
    },
    getProdct: function(id) {
      // this.$store.dispatch("working", true);
      this.$http.get(`Companies/${this.DataCustomers.id}/products/${id}`).then((response) => {
        if (response.data.code == 200) {
          this.DataCProduct = response.data.data;
          this.dialog6 = true;
        }
      });
    },

    PutProdct() {
      let Producto = {
        id: this.DataCProduct.id,
        name: this.DataCProduct.name,
        companyId: this.DataCustomers.id,
        sku: this.DataCProduct.sku,
        clientSupplierId: this.ClientSupplier.id,
        unitPrice: this.DataCProduct.unitPrice,
        currencyId: this.DataCProduct.currencyId,
        description: this.DataCProduct.description,


      };
      console.log(Producto)


      this.$store.dispatch("working", true);
      this.$http
          .put(
              `Companies/${this.DataCustomers.id}/products/${this.DataCProduct.id}`,
              Producto
          )
          .then((response) => {
            console.log(response);
            this.$toast.info("Se a creado comprador de cliente");
            this.NombreProducto = null,
                this.sku = null,
                this.unitPrice= null,
                this.description = null,
                this.currencyId= null,
                this.getProdcts();


          })
          .catch((error) => {
            console.log(error);
            this.$toast.error("Ocurrio un error");
          })
          .finally(() => {
            this.$store.dispatch("working", false);
          });

    },


    postProdcts() {
      let Producto = {
        id: 0,
        name: this.NombreProducto,
        companyId: this.DataCustomers.id,
        sku: this.sku,
        clientSupplierId: this.ClientSupplier.id,
        unitPrice: this.unitPrice,
        currencyId: this.currencyId,
        description: this.description,


      };
      console.log(Producto)


      this.$store.dispatch("working", true);
      this.$http
          .post(
              `Companies/${this.DataCustomers.id}/products`,
              Producto
          )
          .then((response) => {
            console.log(response);
            this.$toast.info("Se a creado comprador de cliente");
                this.NombreProducto = null,
                this.sku = null,
                this.unitPrice= null,
                this.description = null,
                this.currencyId= null,
                this.getProdcts();


          })
          .catch((error) => {
            console.log(error);
            this.$toast.error("Ocurrio un error");
          })
          .finally(() => {
            this.$store.dispatch("working", false);
          });

    },

    getCsupplier() {
      // this.$store.dispatch("working", true);
      this.$http.get(`Companies/${this.DataCustomers.id}/suppliers`).then((response) => {
        if (response.data.code == 200) {
          this.DataCsuppliers = response.data.data;
          this.getProdcts();
        }
      });
    },

    getClientSupplier:function (id){
      // this.$store.dispatch("working", true);
      this.$store.dispatch("working", true);
      this.$http.get(`Companies/${this.DataCustomers.id}/suppliers/${id}`).then((response) => {
        if (response.data.code == 200) {
          this.ClientSupplier = response.data.data;
          this.dialog2 = true;
          this.$store.dispatch("working", false);
        }
      });
    },
    PaymentAdvanced(){

      let doc = {
        id: this.ClientSupplier.id,
        name: this.ClientSupplier.name,
        companyId: this.DataCustomers.id,
        Rfc: this.ClientSupplier.taxNumber,
        Phone:this.ClientSupplier.telephone,
        email:this.ClientSupplier.email,
        address:this.ClientSupplier.address,

      };
          this.$http.put(`Companies/${this.DataCustomers.id}/suppliers/${this.ClientSupplier.id}`, doc)
              .then((response) => {
            if (response.data.code === 200) {
              this.$toast.info("Confirmado con exito!");
              this.dialog2 = false;
            } else {
              this.$toast.error("Ocurrio un error");
            }
            console.log("No paso")
          }).catch((error) => {
            console.log(error)
          }).finally(() => {
          })

    },

    putBuyer(){

      let doc4 = {
        id: this.Clientbuyer.id,
        name: this.Clientbuyer.name,
        businessName: this.Clientbuyer.businessName,
        companyId: this.DataCustomers.id,
        taxNumber: this.Clientbuyer.taxNumber,
        address:this.Clientbuyer.address,
        telephone:this.Clientbuyer.telephone,
        email:this.Clientbuyer.email,
        state: this.Clientbuyer.state,
        city: this.Clientbuyer.city,
        country: this.Clientbuyer.country,
      };
      this.$http.put(`Companies/${this.DataCustomers.id}/clients/${this.Clientbuyer.id}`, doc4)
          .then((response) => {
            if (response.data.code === 200) {
              this.$toast.info("Confirmado con exito!");
              this.dialog2 = false;
            } else {
              this.$toast.error("Ocurrio un error");
            }
            console.log("No paso")
          }).catch((error) => {
        console.log(error)
      }).finally(() => {
      })

    },


    getBuyer:function (id){
      // this.$store.dispatch("working", true);
      this.$store.dispatch("working", true);
      this.$http.get(`Companies/${this.DataCustomers.id}/clients/${id}`).then((response) => {
        if (response.data.code == 200) {
          this.Clientbuyer = response.data.data;
          console.log(this.Clientbuyer)
          this.dialog3 = true;
          this.$store.dispatch("working", false);
        }
      });
    },

    NewBuyer() {
      let doc2 = {
        id: 0,
        name: this.CsupplierName,
        businessName: this.CsupplierBussnesName,
        companyId: this.DataCustomers.id,
        taxNumber: this.CsupplierRfc,
        address:this.CsupplierAddress,
        telephone:this.CsupplierPhone,
        email:this.CsupplierEmail,
        state: this.CbuyerState,
        city: this.CbuyerCity,
        country: this.CbuyerCountry,
      };
      console.log(doc2)


      this.$store.dispatch("working", true);
      this.$http
          .post(
              `Companies/${this.DataCustomers.id}/clients`,
              doc2
          )
          .then((response) => {
            console.log(response);
            this.$toast.info("Se a creado comprador de cliente");
                this.CsupplierName = null,
                this.CsupplierBussnesName = null,
                this.DataCustomers.id = null,
                this.CsupplierRfc = null,
                this.CsupplierAddress = null,
                this.CsupplierPhone = null,
                this.CsupplierEmail = null,
                this.CbuyerState = null,
                this.CbuyerCity = null,
                this.CbuyerCountry = null

          })
          .catch((error) => {
            console.log(error);
            this.$toast.error("Ocurrio un error");
          })
          .finally(() => {
            this.$store.dispatch("working", false);
          });

    },



    enviarEvidencia() {
      let doc2 = {
        id: 0,
        name: this.CsupplierName,
        companyId: this.DataCustomers.id,
        rfc: this.CsupplierRfc,
        phone:this.CsupplierPhone,
        email:this.CsupplierEmail,
        address:this.CsupplierAddress,

      };
      console.log(doc2)


      this.$store.dispatch("working", true);
      this.$http
          .post(
              `Companies/${this.DataCustomers.id}/suppliers`,
              doc2
          )
          .then((response) => {
            console.log(response);
            this.$toast.info("Se a creado un provedor de cliente");
            this.CsupplierName = null;
            this.DataCustomers.id = null;
            this.CsupplierRfc = null;
            this.CsupplierEmail = null;
            this.CsupplierAddress = null;
            this.CsupplierPhone = null;
            this.getCsupplier();

          })
          .catch((error) => {
            console.log(error);
            this.$toast.error("Ocurrio un error");
          })
          .finally(() => {
            this.$store.dispatch("working", false);
          });

    },
    getCurrencies() {
      // this.$store.dispatch("working", true);
      this.$http.get("currencies").then((response) => {
        if (response.data.code == 200) {
          this.Currencies = response.data.data;
          console.log(this.Currencies, "aquiiiii")
        }
      });
    },

    getCustomers:function (){
      this.dialog = false;
      this.$store.dispatch('working',true);

      this.$http.get('Companies', {
        headers: {
          'Cache-Control': 'max-age=5'
        }
      }).then(response =>{
        console.log(response.data.data);
        this.Customers = response.data.data;
        this.CustomersStatus = this.Customers.length
        this.$emit('CustomersQuantity',this.CustomersStatus);
      }).catch(error =>{
        console.log(error);
      }).finally(()=>{
        this.$store.dispatch('working',false)
      })
    },
    GetDataCustomer:function (id){
      this.companyId = id;
      // this.getClientEmployees(id);
      this.dialog = true;
      //this.id = id;
      this.$store.dispatch('working',true)

      this.$http.get(`Companies/${id}`).then(response => {
        console.log(response)
        this.DataCustomers = response.data.data;
        this.buyers = this.DataCustomers.buyers
        this.getCsupplier();
       console.log(this.DataCustomers)

      }).catch(error=>{
        console.log(error)
      }).finally(()=>{
        this.$store.dispatch('working',false);
      })
    },

    GetDataCustomerReporte:function (id, name){
      this.$store.dispatch('working',true)

      this.$http.get(`Shipping/client/${id}/report`).then(response => {
        console.log(response.data.data);
        fileDownload(response.data, `${name}.csv`);
        this.$toast.info('Pdf Generado');


      }).catch(error=>{
        console.log(error)
      }).finally(()=>{
        this.$store.dispatch('working',false);
      })
    },



  },
  mounted() {
    this.getCustomers();
    this.getCurrencies();
  }
}
</script>

<style scoped>
>>> .container {
  min-width: 100%;
}
</style>
