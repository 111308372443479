<template>
  <AuthBase>
    <template v-slot:LoginFields>

      <v-form v-on:submit.prevent="login" ref="form">
        <br><br><br><br><br>

        <h1 class="font-weight-light text-center display-2 text--white">User Login</h1>

        <br><br>

        <v-row justify="center">
          <v-col md="10">
            <v-text-field
                v-model="userdata.username"
                label="Usuario"
                name="username"
                @change="validateSubmit"
                prepend-icon="mdi-account"
                :rules="[rules.requiredUser]"
            />
            <br>
            <v-text-field
                v-model="userdata.password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                name="password"
                label="Contraseña"
                @change="validateSubmit"
                :rules="[rules.requiredPassword]"
                prepend-icon="mdi-key"
                @click:append="show1 = !show1"
            ></v-text-field>


          </v-col>

          <v-col class="mt-2" align="center">
            <v-btn type="submit" class="py-3" style="background: rgb(255,255,255);
background: linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(237,242,248,1) 0%, rgba(218,229,240,1) 0%, rgba(1,75,152,1) 100%); color: white" :block="$vuetify.breakpoint.smAndDown">
              Iniciar sesión
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </AuthBase>
</template>

<script>
import AuthBase from "@/components/Auth/AuthBase";

export default {
  name: "LoginFields",
  components: {AuthBase},
  data() {
    return {
      validate: true,
      show1: false,
      userdata: {
        username: null,
        password: null
      },
      rules: {
        requiredUser: value => !!value || 'Nombre Requerido.',
        requiredPassword: value => !!value || 'Contraseña Requerido.',
      },
    }
  },
  methods: {

    validateSubmit: function () {
      if (this.userdata.username.length > 0 && this.userdata.password.length > 0) {
        this.validate = false;
      }
    },

    login: function () {

      if (this.$refs.form.validate()) {
        this.$store.commit('reset');
        this.$store.dispatch('working', true);

        // Deberia ser con esto pero por alguna razón el back no lo acepta
        // let formData = new FormData();
        // formData.append("username", this.userdata.username);
        // formData.append("password", this.userdata.password);
        // formData.append("grant_type", "password");
        // formData.append("scope", "api");


        this.$http.post('User/Login', `grant_type=password&username=${this.userdata.username}&password=${this.userdata.password}`, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }).then(async response => {

          this.$toast.success("Bienvenido" + " " + response.data.Firstname + " " + response.data.LastName)
          this.$store.dispatch("name", response.data.Firstname)
          this.$store.dispatch("lastname", response.data.LastName)
          this.$store.dispatch("token", response.data.access_token)
          this.$store.dispatch("roles", JSON.parse(response.data.Roles))
          this.$store.dispatch("UserId", response.data.UserId)
          this.$http.defaults.headers["Authorization"] = "Bearer " + response.data.access_token;

          let UserRole = this.$store.getters.roles;

          if (UserRole[0] == 'Pricing') {
            this.$router.push('/Pricing')
          } else if (UserRole[0] == 'Ventas') {
            this.$router.push('/Sales')
          } else if (UserRole[0] == 'Operaciones') {
            this.$router.push('/operationList2')
          }
          console.log('token', this.$store.getters.token)
          if (this.$store.getters.token) {
            this.$hub.startConnection(this.$store.getters.token);
            console.log(this.$hub.Connection)
            await this.$hubChat.startConnection(this.$store.getters.token);
            this.$signalChat.defaults.headers["Authorization"] = "Bearer " + this.$store.getters.token;
            console.log(this.$hubChat.Connection.state)
           /* if (this.$hubChat.Connection.state !== 'Connected') {
              this.$router.replace({name: "Login"});
            }*/
            console.log('chathub')
            console.log(this.$hubChat)
            if (this.$store.getters.roles.indexOf('Ventas') > -1) {
              this.$hub.Connection.on("RequestedToSupplier", ({id}) => {

                let func = () => this.$router.push(`/ProceedQuoteSales/${id}`)
                this.$toast.info("La cotización de la orden " + id + " fue solicitada a proveedor", {}, func);
              })
              this.$hub.Connection.on("OrderSendToSales", ({id}) => {
                const audio = new Audio(require('@/assets/notification.wav'))
                audio.play();
                this.$desktopNotify.showNotification("Senator", {
                  body: "Nueva cotización registrada."
                })

                let func = () => this.$router.push(`/ProceedQuoteSales/${id}`)
                this.$toast.info("La cotización de la orden " + id + " está lista para cotizar", {}, func);
              })
              // this.$hub.Connection.on("OrderCreated", ({id}) => {
              //   const audio = new Audio(require('@/assets/notification.wav'))
              //   audio.play();
              //   let func = () => this.$router.push(`/ProceedQuoteSales/${id}`)
              //   this.$desktopNotify.showNotification("Senator", {
              //     body: "Nueva cotización registrada."
              //   })
              //   this.$toast.success("La cotización fue registrada y asignada a pricing", {}, func)
              // })
            }

            if (this.$store.getters.roles.indexOf('Pricing') > -1) {
              this.$hub.Connection.on("OrderRenegotiationStatus", ({id}) => {
                const audio = new Audio(require('@/assets/notification.wav'))
                audio.play();
                this.$desktopNotify.showNotification("Senator", {
                  body: "Nueva cotización registrada."
                })

                let func = () => this.$router.push(`/ProceedQuote/${id}`)
                this.$toast.info(`Se asigno la orden ${id} para renegociación`, {}, func);
              })

              this.$hub.Connection.on("OrderCanceled", ({id}) => {

                let func = () => this.$router.push(`/ProceedQuote/${id}`)
                this.$toast.info(`Se cancelo la orden ${id}`, {}, func);
              })

              this.$hub.Connection.on("OrderSold", ({id}) => {

                let func = () => this.$router.push(`/ProceedQuote/${id}`)
                this.$toast.info(`Orden ${id} vendida`, {}, func);
              })

              this.$hub.Connection.on("OrderCreated", ({id}) => {
                const audio = new Audio(require('@/assets/notification.wav'))
                audio.play();
                this.$desktopNotify.showNotification("Senator", {
                  body: "Nueva cotización registrada."
                })

                let func = () => this.$router.push(`/ProceedQuote/${id}`)
                this.$toast.info("Se asigno una nueva orden.", {}, func);
              })
            }

            if (this.$store.getters.roles.indexOf('Operaciones') > -1) {
              this.$hub.Connection.on("SendMessage", (message) => {
                console.log(message)
                this.$toast.info(message)
              })
            }

            //este metodo se debe consumir cuando se actualiza los order price
            // this.$hub.Connection.on("OrderPricesUpdate", ({id}) => {
            //   let func = () => this.$router.push(`/ProceedQuote/${id}`)
            //   this.$toast.info("Se actualizo los precios de una orden", {}, func);
            // })


            this.$hub.Connection.on("QuotationUpdated", ({orderId}) => {

              let func = () => this.$router.push(`/ProceedQuote/${orderId}`)
              this.$toast.info("La cotización de la orden " + orderId + " fue actualizada", {}, func);
            })

            this.$hub.Connection.on("QuotationCreated", ({orderId}) => {

              let func = () => this.$router.push(`/ProceedQuote/${orderId}`)
              this.$toast.info("La cotización de la orden " + orderId + " fue actualizada", {}, func);
            })

            this.$hub.Connection.on("OrderUpdated", ({id}) => {

              let func = () => this.$router.push(`/ProceedQuote/${id}`)
              this.$toast.info("Se actualizo una orden", {}, func);
            })

            this.$hub.Connection.on("OrderReturnToSales", ({id}) => {

              const audio = new Audio(require('@/assets/notification.wav'))
              audio.play();
              this.$desktopNotify.showNotification("Senator", {
                body: "Nueva cotización registrada."
              })

              let func = () => this.$router.push(`/ProceedQuoteSales/${id}`)
              this.$toast.info("Devuelto a ventas", {}, func);
            })

            this.$hub.Connection.on("OrderRejected", ({id}) => {

              let func = () => this.$router.push(`/ProceedQuote/${id}`)
              this.$toast.info("Se rechazo una orden", {}, func);
            })

          }


          /* if (UserRole.indexOf('Pricing') > -1) {
             this.$router.push('/Pricing')
           } else if (UserRole.indexOf('Ventas') > -1) {
             this.$router.push('/Sales')
           }*/


        }).catch(() => {
          this.$toast.error("Usuario o contraseña Invalidos")

        }).finally(() => this.$store.dispatch('working', false))
      } else {
        console.log("ERROR")
      }


    },
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>
