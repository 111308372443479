<template>
  <div>
    <v-card elevation="0" tile class="pt-10">
      <v-row justify="center">
        <v-col md="12">
          <v-container tile elevation="0">
            <template>
              <v-row justify="center">
                <v-col md="12">
                  <v-container>
                    <v-data-table
                        :hide-default-footer="true"
                        :headers="headers"
                        :items="Data"
                        :single-expand="singleExpand"
                        :expanded.sync="expanded"
                        item-key="id"
                        :search="search"
                    >


                      <template v-slot:item.urgent="{item}">
                        {{item.urgent ?'Si':'No'}}
                      </template>

                      <template v-slot:item.salesManager="{item}">
                        {{item.salesManager ? `${item.salesManager.firstName} ${item.salesManager.lastName}` : ''}}
                      </template>

                      <template v-slot:item.pricer="{item}">
                        {{item.pricer ? `${item.pricer.firstName} ${item.pricer.lastName}` : ''}}
                      </template>

                      <template v-slot:item.dangerous="{item}">
                        {{item.dangerous?'Si':'No'}}
                      </template>


                      <template v-slot:item.document>
                        <v-icon color="#546E7A">mdi-file-pdf</v-icon>
                      </template>
                      <template v-slot:item.status = "{item}">
                        <v-row>
                          <v-col class="pb-0" md="10">
                            <v-select
                                v-model="item.statusOrder"
                                outlined
                                dense
                                class="pa-0 mt-3"
                                @change="showModal(item.id, item.statusOrder)"
                                label="Enviado a pricing"
                                :items="OrderStatus"
                                item-value="id"
                                item-text="name"
                            >

                            </v-select>
                          </v-col>
                        </v-row>
                      </template>

                      <template v-slot:item.action="{item}">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click="proceed(item.id)" v-bind="attrs" v-on="on">
                              <v-icon color="primary">mdi-table-edit</v-icon>
                            </v-btn>
                          </template>
                          <span>Editar</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                    <v-pagination
                        v-model="options.page"
                        :length="MetaData.totalPages"
                        @input="GetData()"
                        @next="GetData()"
                        @previous="GetData()"
                    ></v-pagination>

                  </v-container>
                </v-col>
              </v-row>
            </template>
          </v-container>

        </v-col>
      </v-row>

    </v-card>

    <v-dialog
        v-model="requestModal"
        width="1000"
    >
      <div>


        <v-toolbar
            dark
            class="primary white--text"
        >
          <h2 class="font-weight-regular"><v-icon left size="25">mdi-comment-text-outline</v-icon>Comentarios</h2>


          <v-spacer></v-spacer>
          <v-btn
              icon
              dark
              @click="requestModal = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>


          <v-card>
          <v-card-text>
            <br>
            <h2 class="font-weight-bold text-center">¿Porque se solicita a Proveedor?</h2>
            <br>
            <v-row>
              <v-col md="4">
                <v-select
                    v-model="Refuse.orderReasonId"
                    class="ml-6"
                    dense
                    outlined
                    :items="RefuseReasons"
                    item-value="Id"
                    item-text="Reasons"
                >
                </v-select>
              </v-col>
              <v-col md="10">
                <v-textarea
                    v-model="Refuse.comments"
                    outlined
                    class="ml-6 pa-0"
                    name="input-7-1"
                    label="Comentarios"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="2">
                <v-btn color="primary" class="ml-6" @click="SendRequested()">
                  Enviar Comentarios
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </v-dialog>

    <v-dialog
        v-model="ChangeStatus"
        width="1000"
    >
      <div>


        <v-toolbar
            dark
            class="primary white--text"
        >
          <h2 class="font-weight-regular"><v-icon left size="25">mdi-comment-text-outline</v-icon>Comentarios</h2>


          <v-spacer></v-spacer>
          <v-btn
              icon
              dark
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>


        <v-card>
          <v-card-text>
            <br>
            <h2 class="font-weight-bold text-center">¿Porque la necesidad de regresar la cotización?</h2>
            <br>
            <v-row>
              <v-col md="4">
                <v-select
                    v-model="Refuse.orderReasonId"
                    class="ml-6"
                    dense
                    outlined
                    :items="RefuseReasons"
                    item-value="Id"
                    item-text="Reasons"
                >
                </v-select>
              </v-col>
              <v-col md="10">
                <v-textarea
                    v-model="Refuse.comments"
                    outlined
                    class="ml-6 pa-0"
                    name="input-7-1"
                    label="Comentarios"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="2">
                <v-btn color="primary" class="ml-6" @click="SendSales()">
                  Enviar Comentarios
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </v-dialog>



  </div>
</template>

<script>

export default {
  name: "Pending",
  props:{
    active:Number,
    id:Number
  },
  watch:{
    active:function (){
      this.GetData();
    },
    '$store.state.filterPricingColumns': {
      handler: function (value) {
        this.headers = value
      },
      deep: true
    },

  },
  data(){
    return{
      e1:1,
      requestModal:null,
      OrderStatusID:null,
      ChangeStatus:null,
      MetaData:{},

    options:{
        page: 1,
        itemsPerPage: 10,
        pageStart: 0,
        pageStop: 0,
        pageCount: 0,
        itemsLength: 0
    },
      OrderStatus:[
        {
          id:1,
          name:'Solicitado a Proveedor'
        },

      ],
      counter:0,
      ResumeTotal:0,
      items: [
        {
          action: 'mdi-ticket',
          items: [{ title: 'List Item' }],
          title: 'Attractions',
        },
        {
          action: 'mdi-silverware-fork-knife',
          active: true,
          items: [
            { title: 'Breakfast & brunch' },
            { title: 'New American' },
            { title: 'Sushi' },
          ],
          title: 'Dining',
        },
        {
          action: 'mdi-school',
          items: [{ title: 'List Item' }],
          title: 'Education',
        },
        {
          action: 'mdi-run',
          items: [{ title: 'List Item' }],
          title: 'Family',
        },
        {
          action: 'mdi-bottle-tonic-plus',
          items: [{ title: 'List Item' }],
          title: 'Health',
        },
        {
          action: 'mdi-content-cut',
          items: [{ title: 'List Item' }],
          title: 'Office',
        },
        {
          action: 'mdi-tag',
          items: [{ title: 'List Item' }],
          title: 'Promotions',
        },
      ],
      dense:true,
      counterElement:0,
      Resume:[],
      newElement:[],

      inputs: [{
        name: '',
        rate:'',
        quantity:'',
        total:''
      }],
      tab:0,
      desserts: [
        {
          name: 'Frozen Yogurt',
          calories: 159,
        },
      ],
      originAddress: "",
      destinationAddress: "",
      dialog:false,
      QuantityStatus:0,
      proceedItemData:[],

      headers: [],
      Data:[],
      RefuseReasons: [{Id: 1, Reasons: 'No hay disponibilidad', Status: true},
        {Id: 2, Reasons: 'No hay informacion', Status: true},
        {Id: 3, Reasons: 'No hay Ruta', Status: true}],
      Refuse:{
        orderId:null,
        comments:null,
        orderReasonId:1
      },
      Email:{
        suppliers: [],
        serviceId:1,
        subject:"Cotizacion Maritima",
        message:"Detalle de cotizacion"
      },

      testdialog:false,
      search:"",
      expanded: [],
      singleExpand: false,
      idOrder:0
    }
  },
  methods:{
    updateOriginAddress(text){
      this.originAddress = text
    },
    updateDestinationAddress(text){
      this.destinationAddress = text
    },
    originSelected(selectedItem) {
      this.originAddress = selectedItem.address
      this.origin = selectedItem
      console.log(this.origin)

      if(this.origin) {
        this.QuotData.origin["city"] = this.origin.placeDetails.address_components.filter((value) => value.types.findIndex(a => a === 'locality') !== -1)[0].long_name
        this.QuotData.origin["state"] = this.origin.placeDetails.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0].long_name
        this.QuotData.origin["country"] =  this.origin.placeDetails.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
        this.QuotData.origin["coordinates"] = this.origin.placeDetails.location
        this.QuotData.AutocompleteOrigin = true;
      }
      this.setPath()
    },
    destinationSelected: function (selectedItem) {
      this.destinationAddress = selectedItem.address
      this.destination = selectedItem
      this.QuotData.destination = this.destination

      if(this.destination) {
        this.QuotData.destination["city"] = this.destination.placeDetails.address_components.filter((value) => value.types.findIndex(a => a === 'locality') !== -1)[0].long_name
        this.QuotData.destination["state"] = this.destination.placeDetails.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0].long_name
        this.QuotData.destination["country"] =  this.destination.placeDetails.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
        this.QuotData.destination["coordinates"] = this.destination.placeDetails.location
        this.QuotData.AutocompleteDestination = true;
      }

      this.setPath()
    },
    GetData:function (){
      this.$store.dispatch('working',true);

      this.$http.get('Orders/assigned',{
        params:{
          PageSize:this.options.itemsPerPage,
          PageNumber:this.options.page,
        }
      }).then(response => {
        if(response.data.code == 200){
          console.log(response);
          let res = response.data.data;
          this.MetaData = response.data.meta;
          this.Data = res.map(item => {
            console.log(item.cargo)
            console.log(item.cargo.filter(x => x.Dangerous).length > 0)
            return {
              ...item,
              departureDate: this.$luxon(item.departureDate, {output: "full"}),
              //departureDate: new Date(item.departureDate).toLocaleDateString("es-MX"),
              createdAt: this.$luxon(item.createdAt, {output: {
                format: "short",
                locale: "es-MX"
              }}),
              salesManager: {
                ...item.salesManager,
                name: item.salesManager ? `${item.salesManager.firstName} ${item.salesManager.lastName}` : ''
              },
              dangerous: item.cargo.filter(x => x.Dangerous).length > 0,
            }
          })
          console.log(this.Data)
        }

        if (response.data.code == 400 || response.data.code == 500)
          this.$toast.error("Algo salio mal, intente mas tarde")
      }).catch(error => {

        console.log(error);
      }).finally(()=>{
        this.$store.dispatch('working',false);
      })
      this.active = 0;
    },
    RecibeData:function (data){

      console.log(data);

      this.Email.suppliers.push(
          {
            id: data[0].id,
            contact:data[0].idContact
          }

      )

      console.log(this.Email);

    },
    proceed:function (id){
      this.dialog = true;
      this.id = id;
      this.$store.dispatch("idOrder", this.id)
      this.$router.push({path: `ProceedQuote/${this.id}`})

    },
    showModal:function (id,Status) {

      this.id = id;

      if (Status == 1){
        this.SendRequested()
      }

      if (Status == 2){
        this.SendSales();
      }

    },
    SendRequested:function () {

      this.$store.dispatch('working', true)
      this.$http.put(`Orders/${this.id}/requested`).then(response => {
        if(response.data.code == 200){
          console.log(response.data.data);
          this.$toast.info("Enviado a Solicitado a Proveedor");
          this.requestModal = false;
          this.GetData();
          this.$emit('change', true)
        }

        if (response.data.code == 400 || response.data.code == 500)
          this.$toast.error("Algo salio mal, intente mas tarde")
      }).catch(error=>{


        this.$toast.error("Ocurrio un error");
        console.log(error)
      }).finally(()=>{
        this.$store.dispatch('working',false);
      });
    },
    SendSales:function () {

      this.$store.dispatch('working', true)
      this.$http.post(`Orders/${this.id}/send/sales`).then(response => {


        if (response.data.code == 200){
          this.$toast.info("Enviado a ventas");
          this.ChangeStatus = false;
          this.GetData();
          this.$emit('change', true)
          console.log(response.data.data);
         // this.$router.push({name: 'Quote'})
        }


        if (response.data.code !==200){
          this.$toast.error("Necesita registrar precio")
         // this.$router.push({name: 'Quote'})
        }

        if (response.data.code == 400 || response.data.code == 500)
          this.$toast.error("Algo salio mal, intente mas tarde")
      }).catch(error=>{
        if(error.response.status == 401){
          this.$store.commit('reset');
          this.$toast.error("La sesion expiro");
          this.$router.replace('/login')
        }

        this.$toast.error("Ocurrio un error");
        console.log(error)
      }).finally(()=>{
        this.$store.dispatch('working',false);
      });
    },

    GetSignalData(data){
      console.log(data)
    },
    UpdateItemData: function(data){
      this.Data.unshift(data);
      console.log(this.Data)
    }

  },
  mounted() {
    this.headers = this.$store.state.filterPricingColumns
    this.GetData();
  }
}
</script>

<style scoped>
>>> .container {
  min-width: 100%;
}
</style>
