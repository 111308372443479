<template>
  <v-row justify="center">
    <v-col cols="12">
      <v-tabs
          v-model="tab"
          background-color="white"
          centered
          color="grey"
          height="150"
          icons-and-text
      >
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab @click="dataFilter=[],search='' ">
          <CardStatus>
            <template v-slot:card-icon>
              mdi-email-search
            </template>
            <template v-slot:card-title>
              Solicitadas
            </template>
            <template v-slot:QuantityStatus>
              <h1 class="font-weight-regular pt-3 text-center primary--text">{{ RequestQuantity }}</h1>
            </template>
          </CardStatus>
        </v-tab>
        <v-tab @click="dataFilter=[],search='' ">
          <CardStatus>
            <template v-slot:card-icon>
              mdi-archive-arrow-down
            </template>

            <template v-slot:card-title>
              Recibidas
            </template>

            <template v-slot:QuantityStatus>
              <h1 class="font-weight-regular pt-3 text-center primary--text">{{ ReceivedQuantity }}</h1>
            </template>
          </CardStatus>
        </v-tab>
        <v-tab @click="dataFilter=[],search='' ">
          <CardStatus>
            <template v-slot:card-icon>
              mdi-email-fast
            </template>
            <template v-slot:card-title>
              Enviadas
            </template>

            <template v-slot:QuantityStatus>
              <h1 class="font-weight-regular pt-3 text-center primary--text">{{ SendQuantity }}</h1>
            </template>
          </CardStatus>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-row class="mt-3 " justify="center">
          <v-col md="7">
            <v-text-field v-model="search"  dense hide-details label="Buscar" outlined
                          single-line>
              <template v-slot:append class="ma-0 pt-0">
                <v-chip-group
                    dense
                    active-class="primary--text"
                    v-model="chip"
                    class="mt-0 pt-0"
                >
                  <v-chip
                      dense
                      filter
                      outlined
                  >
                    Folio
                  </v-chip>
                  <v-chip
                      dense
                      filter
                      outlined
                  >
                    Cliente
                  </v-chip>
                  <v-chip
                      dense
                      filter
                      outlined
                  >
                    Responsable
                  </v-chip>
                </v-chip-group>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
<!--        <v-row justify="center" class="mb-2">
          <v-chip-group
              active-class="primary&#45;&#45;text"
              v-model="chip"
          >
            <v-chip
                filter
                outlined
            >
              Folio
            </v-chip>
            <v-chip
                filter
                outlined
            >
              Cliente
            </v-chip>
            <v-chip
                filter
                outlined
            >
              Responsable
            </v-chip>
          </v-chip-group>
        </v-row>-->
        <div v-if="dataFilter.length>0">
          <v-card elevation="0" tile>
            <v-data-table
                :headers="headers"
                :hide-default-footer="true"
                :items="dataFilter"
                item-key="id"
                :items-per-page="itemsPP"
            >


              <template v-slot:item.urgent="{item}">
                {{ item.urgent ? 'Si' : 'No' }}
              </template>

              <template v-slot:item.salesManager="{item}">
                <div v-if="item.salesManager !== null">
                  {{ item.salesManager.firstName ? item.salesManager.firstName : '' }} {{ item.salesManager.lastName }}=
                </div>
              </template>

              <template v-slot:item.dangerous="{item}">
                {{ item.dangerous ? 'Si' : 'No' }}
              </template>

              <template v-slot:item.salesManager="{item}">
                <div v-if="item.salesManager !== null">
                  {{ item.salesManager.firstName ? item.salesManager.firstName : '' }} {{ item.salesManager.lastName }}
                </div>
              </template>

              <template v-slot:item.document>
                <v-icon color="#546E7A">mdi-file-pdf</v-icon>
              </template>
              <template v-slot:item.Pricer="{item}">
                <div v-if="item.Pricer !== null">
                  {{ item.Pricer.firstName ? item.Pricer.firstName : ''}} {{ item.Pricer.lastName }}
                </div>
              </template>
              <template v-slot:item.view="{item}">

                <v-btn  text ripple @click="proceed(item.Id)">

                  <v-icon color="primary">mdi-notebook-outline</v-icon>

                </v-btn>


              </template>

              <template v-slot:item.status="{item}">
                <v-row>
                  <v-col class="pb-0" md="10">
                    <v-select
                        v-model="item.statusOrder"
                        :items="OrderStatus"
                        class="pa-0 mt-3"
                        dense
                        item-text="name"
                        item-value="id"
                        label="Enviado a pricing"
                        outlined
                        @change="showModal(item.id, item.statusOrder)"
                    >

                    </v-select>
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
          </v-card>
        </div>
        <div v-if="dataFilter.length==0">
          <v-tab-item>
            <Request
                ref="requestRef"
                v-bind:active="activePending=1"
                v-on:SalesRequestQuantity="RequestQuantity = $event"
            ></Request>
          </v-tab-item>
          <v-tab-item>
            <Received
                ref="receivedRef"
                v-on:SalesReceivedQuantity="ReceivedQuantity = $event"
            ></Received>
          </v-tab-item>
          <v-tab-item>
            <Send
                ref="sendRef"
                v-on:SaleSendQuantity="SendQuantity = $event"
            ></Send>
          </v-tab-item>
        </div>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>
<script>
import CardStatus from "@/components/Sales/List/Card-Status";
import Received from "@/components/Sales/List/Tables/Received";
import Request from "@/components/Sales/List/Tables/Request";
import Send from "@/components/Sales/List/Tables/Send";
import debouncePromise from "debounce-promise";

export default {
  name: "Table-Menu",
  components: {Send, Received, Request, CardStatus},
  data() {
    return {
      itemsPP: 50,
      chip: 0,
      dataFilter: [],
      headers: [
        {text: 'Folio', value: 'RefCode'},
        {
          text: 'Recibida',
          value: 'CreatedAt'
        },
        {text: 'Mercancia lista', value: 'DepartureDate'},
        {
          text: 'Cliente',
          value: 'Client.name'
        },
        {
          text: 'Responsable',
          value: 'Pricer'
        },
        {
          text: 'Servicio',
          value: 'OrderType.description'
        },
        {
          text: 'Status',
          value: 'StatusOrder'
        },
        {
          text: 'Urgente',
          value: 'Urgent'
        },
        {
          text: 'Urgente',
          value: 'Urgent'
        },
        {
          text: 'Detalles',
          value: 'view'
        },

      ],
      RequestQuantity: 0,
      ReceivedQuantity: 0,
      SendQuantity: 0,
      loading: false,
      search: '',
      selected: [],
      tab: 0,
      idOrder:null,
    }
  },
  watch: {
    chip: {
      handler(newValue, oldVlue) {
      console.log(newValue, oldVlue)
        if (newValue === undefined || oldVlue === undefined){
          this.chip = 0
          this.search = null
        }
      }
    },
    search: debouncePromise(function (event) {
      this.findOrders(event)
    }, 1000)
  },
  computed: {
    allSelected() {
      return this.selected.length === this.items.length
    },
    categories() {
      const search = this.search.toLowerCase()

      if (!search) return this.items

      return this.items.filter(item => {
        const text = item.text.toLowerCase()

        return text.indexOf(search) > -1
      })
    },
    selections() {
      const selections = []

      for (const selection of this.selected) {
        selections.push(selection)
      }
      return selections
    },
  },
  methods: {
    updateOrderCount: function (order) {
      if (order)
        this.addOrderToData(order)

      this.$http.get('Orders/count/sales', {
        headers: {
          'Cache-Control': 'max-age=5'
        }
      }).then(response => {
        if (response.data.code == 200) {
          this.RequestQuantity = response.data.data.open
          this.ReceivedQuantity = response.data.data.received
          this.SendQuantity = response.data.data.send
        }
      }).catch(error => {
        if (error.response.state === 401) {
          this.$store.commit('reset');
          this.$toast.error("La sesion expiro");
          this.$router.replace('/login')
        }
      }).finally(() => {
        this.$store.dispatch('working', false);
      })
    },
    addOrderToData(data) {

      let order = {
        ...data,
        departureDate: new Date(data.departureDate).toLocaleDateString("es-MX"),
        createdAt: new Date(data.createdAt).toLocaleDateString("es-MX"),
        pricer: {
          ...data.pricer,
          name: `${data.pricer.firstName} ${data.pricer.lastName}`
        },
        dangerous: data.cargo.filter(x => x.Dangerous).length > 0
      }

      console.log(order)
      const REQUEST_STATUS = ["Cotización Pendiente", "Pendiente Pricing", "Solicitado a Proveedor", "Cotizacion Incompleta"];
      const RECEIVED_STATUS = ["Enviado a Ventas", "Cotizacion Incompleta"];
      const SEND_STATUS = ["Enviada a cliente"];

      if (this.tab == 0 && REQUEST_STATUS.findIndex(x => x === order.statusOrder) !== -1)
        this.$refs['requestRef'].UpdateItemData(order)
      if (this.tab == 1 && RECEIVED_STATUS.findIndex(x => x === order.statusOrder) !== -1)
        this.$refs['receivedRef'].UpdateItemData(order)
      if (this.tab == 2 && SEND_STATUS.findIndex(x => x === order.statusOrder) !== -1)
        this.$refs['sendRef'].UpdateItemData(order)
    },
    findOrders(event) {
      if (this.chip === 0){
        this.dataFilter = [];
        this.$http.get('Orders/Find', {
          params: {
            Refcode: event,
            Status: '',
            PageSize: 15,
          }
        }).then(response => {
          console.log(response.data.data)
          this.dataFilter = response.data.data
        }).catch(error => {
          console.log(error)
        }).finally(() => {
          console.log('finally')
        })
      }
      if (this.chip === 1){
        this.dataFilter = [];
        this.$http.get('Orders/Find', {
          headers: {
            'Cache-Control': 'max-age=3'
          },
          params: {
            Client: event,
            Status: '',
            PageSize: 15,
          }
        }).then(response => {
          console.log(response.data.data)
          this.dataFilter = response.data.data
        }).catch(error => {
          console.log(error)
        }).finally(() => {
          console.log('finally')
        })
      }
      if (this.chip === 2){
        this.dataFilter = [];
        this.$http.get('Orders/Find', {
          headers: {
            'Cache-Control': 'max-age=3'
          },
          params: {
            Pricing: event,
            Status: '',
            PageSize: 15,
          }
        }).then(response => {
          console.log(response.data.data)
          this.dataFilter = response.data.data
        }).catch(error => {
          console.log(error)
        }).finally(() => {
          console.log('finally')
        })
      }
    },
    proceed:function (id){
      console.log(id)
      this.idOrder = id;
      this.$router.push({path: `ProceedQuoteSales/${this.idOrder}`})
    },
  },
  mounted() {
    this.updateOrderCount();
    this.$hub.Connection.on("OrderPricesUpdate", (data) => {

      this.updateOrderCount(data)
    })
    this.$hub.Connection.on("OrderCreated", (data) => {

      this.updateOrderCount(data)
    })
    this.$hub.Connection.on("OrderUpdated", (data) => {

      this.updateOrderCount(data)
    })
    this.$hub.Connection.on("OrderRenegotiationStatus", (data) => {

      this.updateOrderCount(data)
    })
    this.$hub.Connection.on("OrderReturnToSales", (data) => {

      this.updateOrderCount(data)
    })
    this.$hub.Connection.on("OrderSendToSales", (data) => {

      this.updateOrderCount(data)
    })
    this.$hub.Connection.on("OrderSold", (data) => {

      this.updateOrderCount(data)
    })
    this.$hub.Connection.on("OrderRejected", (data) => {

      this.updateOrderCount(data)
    })
  },
  beforeDestroy() {
    this.$hub.Connection.off("OrderPricesUpdate", this.updateOrderCount)
    this.$hub.Connection.off("OrderCreated", this.updateOrderCount)
    this.$hub.Connection.off("OrderUpdated", this.updateOrderCount)
    this.$hub.Connection.off("OrderRenegotiationStatus", this.updateOrderCount)
    this.$hub.Connection.off("OrderReturnToSales", this.updateOrderCount)
    this.$hub.Connection.off("OrderSendToSales", this.updateOrderCount)
    this.$hub.Connection.off("OrderSold", this.updateOrderCount)
    this.$hub.Connection.off("OrderRejected", this.updateOrderCount)
  }

}
</script>

<style scoped>
>>>.v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
  display: flex;
  flex: 1 1 auto;
  position: relative;
  align-self: center;
}

>>>.v-text-field--outlined.v-input--dense .v-label {
  top: 5px;
}

>>> .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner {
  padding-top: 0 !important;
margin-top: 0 !important;
}
.v-tab /deep/ {
  text-transform: none !important;
}

>>> .v-data-table td {
  padding: 0 20px;
}
</style>
