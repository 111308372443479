<template>
  <div>
    <v-row justify="center" no-gutters class="w-100">
      <v-tabs
          v-model="tab"
          background-color="white"
          icons-and-text
          centered
          color="grey"
          height="150"
      >
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab @click="dataFilter=[],search='' ">
          <CardStatus>
            <template v-slot:card-icon>
              mdi-timer-alert
            </template>
            <template v-slot:card-title>
              Pendientes
            </template>
            <template v-slot:QuantityStatus>
              <h1 class="font-weight-regular pt-3 text-center primary--text">{{ AssignedQty }}</h1>
            </template>
          </CardStatus>
        </v-tab>
        <v-tab @click="dataFilter=[],search='' ">
          <CardStatus>
            <template v-slot:card-icon>
              mdi-email-search
            </template>

            <template v-slot:card-title>
              Solicitadas
            </template>

            <template v-slot:QuantityStatus>
              <h1 class="font-weight-regular pt-3 text-center primary--text">{{ AnsweredQty }}</h1>
            </template>
          </CardStatus>
        </v-tab>
        <v-tab @click="dataFilter=[],search='' ">
          <CardStatus>
            <template v-slot:card-icon>
              mdi-email-fast
            </template>
            <template v-slot:card-title>
              Enviadas
            </template>

            <template v-slot:QuantityStatus>
              <h1 class="font-weight-regular pt-3 text-center primary--text">{{ SentQty }}</h1>
            </template>
          </CardStatus>
        </v-tab>
        <v-tab @click="dataFilter=[],search='',GetData(8) ">
          <CardStatus>
            <template v-slot:card-icon>
              mdi-check
            </template>
            <template v-slot:card-title>
              Por Confirmar
            </template>

            <template v-slot:QuantityStatus>
              <h1 class="font-weight-regular pt-3 text-center primary--text">{{ shippingConfirmation }}</h1>
            </template>
          </CardStatus>
        </v-tab>
        <v-tab @click="dataFilter=[],search='',GetData(7) ">
          <CardStatus>
            <template v-slot:card-icon>
              mdi-check-all
            </template>
            <template v-slot:card-title>
              Confirmadas
            </template>

            <template v-slot:QuantityStatus>
              <h1 class="font-weight-regular pt-3 text-center primary--text">{{ shippingConfirmed }}</h1>
            </template>
          </CardStatus>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" style="width: 100%">
        <v-row justify="center" class="mt-12 pt-3 pb-2">
          <v-col md="8" class="d-flex">
            <v-menu offset-y min-width="200" :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    icon
                    class="my-auto"
                    id="table-column-filter"
                >
                  <v-icon>mdi-table-filter</v-icon>
                </v-btn>
              </template>
              <v-list
              >
                <v-list-item-group
                    v-model="activeColumns"
                    multiple
                >
                    <v-list-item
                        v-for="(item, index) in columns"
                        :key="`${index}-column`"
                    >
                      <v-list-item-action>
                        <v-checkbox
                            :input-value="activeColumns.includes(index)"
                            :label="item.text"
                        ></v-checkbox>
                      </v-list-item-action>
                    </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
            <v-text-field outlined v-model="search" append-icon="mdi-magnify" dense class="pl-2"  label="Buscar" single-line hide-details>
              <template v-slot:append class="ma-0 pt-0">
                <v-chip-group
                    dense
                    active-class="primary--text"
                    v-model="chip"
                    class="mt-0 pt-0"
                >
                  <v-chip
                      dense
                      filter
                      outlined
                  >
                    Folio
                  </v-chip>
                  <v-chip
                      dense
                      filter
                      outlined
                  >
                    Cliente
                  </v-chip>
                  <v-chip
                      dense
                      filter
                      outlined
                  >
                    Responsable
                  </v-chip>
                </v-chip-group>
              </template>
            </v-text-field>
            <v-btn @click="$router.push('/Pricing/Dashboard')" class="ml-2 my-auto" color="primary">
              Analytics
              <v-icon>mdi-chart-line</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <div v-if="dataFilter.length==0">
          <v-tab-item>
            <Pending
                ref="requestRef"
                @change="updateOrderCount"
                v-bind:active="activePending=1" v-on:PendingQuantity="PendingQuantity = $event"
            ></Pending>
          </v-tab-item>
          <v-tab-item>
            <Request
                ref="receivedRef"
                v-on:RequestQuantity="RequestQuantity = $event"
            ></Request>
          </v-tab-item>
          <v-tab-item>
            <Send
                ref="sendRef"
                v-on:SendQuantity="SendQuantity = $event"
            ></Send>
          </v-tab-item>
          <v-tab-item>
            <v-data-table
                :hide-default-footer="true"
                :items="items2"
                :headers="headers"
                :single-expand="singleExpand"
                :expanded.sync="expanded"
                item-key="id"
                :search="search"
            >
              <template v-slot:item.salesManager="{item}">
                {{item.salesManager ? `${item.salesManager.firstName} ${item.salesManager.lastName}` : ''}}
              </template>
              <template v-slot:item.urgent="{item}">
                {{item.urgent ?'Si':'No'}}
              </template>
              <template v-slot:item.dangerous="{item}">
                {{item.dangerous?'Si':'No'}}
              </template>
              <template v-slot:item.action="{item}">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon @click="proceed(item.id)" v-bind="attrs" v-on="on">
                      <v-icon color="primary">mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
              </template>
              <template v-slot:item.status = "{item}">
                <v-row>
                  <v-col class="pb-0" md="10">
                    {{ item.statusOrder }}
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
            <v-pagination
                v-model="options.page"
                :length="MetaData.totalPages"
                @input="GetData1()"
                @next="GetData1()"
                @previous="GetData1()"
            ></v-pagination>
          </v-tab-item>
          <v-tab-item>
            <v-data-table
                :hide-default-footer="true"
                :items="items2"
                :headers="headers"
                :single-expand="singleExpand"
                :expanded.sync="expanded"
                item-key="id"
                :search="search"
            >
              <template v-slot:item.salesManager="{item}">
                {{item.salesManager ? `${item.salesManager.firstName} ${item.salesManager.lastName}` : ''}}
              </template>
              <template v-slot:item.urgent="{item}">
                {{item.urgent ?'Si':'No'}}
              </template>
              <template v-slot:item.dangerous="{item}">
                {{item.dangerous?'Si':'No'}}
              </template>
              <template v-slot:item.action="{item}">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon @click="proceed(item.id)" v-bind="attrs" v-on="on">
                      <v-icon color="primary">mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
              </template>
              <template v-slot:item.status = "{item}">
                <v-row>
                  <v-col class="pb-0" md="10">
                    {{ item.statusOrder }}
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
            <v-pagination
                v-model="options.page"
                :length="MetaData.totalPages"
                @input="GetData2()"
                @next="GetData2()"
                @previous="GetData2()"
            ></v-pagination>
          </v-tab-item>
        </div>
        <div v-else>
          <v-data-table
              :hide-default-footer="true"
              :items="dataFilter"
              :headers="headers"
              :single-expand="singleExpand"
              :expanded.sync="expanded"
              item-key="id"

          >
            <template v-slot:item.urgent="{item}">
              {{item.urgent ?'Si':'No'}}
            </template>

            <template v-slot:item.salesManager="{item}">
              {{item.salesManager ? `${item.salesManager.firstName} ${item.salesManager.lastName}` : ''}}
            </template>

            <template v-slot:item.pricer="{item}">
              <v-row>
                <v-col class="pb-0" md="10">
                  <v-select
                      v-model="item.pricerId"
                      outlined
                      dense
                      class="pa-0 mt-3"
                      @change="updatePricer(item)"
                      label="Pricer"
                      :items="pricingUsers"
                      :item-text="opt => `${opt.firstName} ${opt.lastName}`"
                      item-value="userId"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </template>

            <template v-slot:item.dangerous="{item}">
              {{item.dangerous?'Si':'No'}}
            </template>


            <template v-slot:item.document>
              <v-icon color="#546E7A">mdi-file-pdf</v-icon>
            </template>
            <template v-slot:item.status = "{item}">
              <v-row>
                <v-col class="pb-0" md="10">
                  <v-select
                      v-model="item.statusOrder"
                      outlined
                      dense
                      class="pa-0 mt-3"
                      @change="showModal(item.id, item.statusOrder)"
                      label="Enviado a pricing"
                      :items="OrderStatus"
                      item-value="id"
                      item-text="name"
                  >

                  </v-select>
                </v-col>
              </v-row>
            </template>

            <template v-slot:item.action="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon @click="proceed(item.id)" v-bind="attrs" v-on="on">
                    <v-icon color="primary">mdi-table-edit</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </div>
      </v-tabs-items>
    </v-row>
    <v-tour name="pricingTour" :steps="steps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>
  </div>
</template>
<script>
import Pending from "@/components/Pricing/Tables/Pending";
import Request from "@/components/Pricing/Tables/Request";
import Send from "@/components/Pricing/Tables/Send";
import CardStatus from "@/components/Sales/List/Card-Status"
import {debounce} from "debounce";

export default  {
  name: "Table-Menu",
  components: {Pending, CardStatus, Request, Send},
  data() {
    return {
      pricingUsers: [],
      activeColumns: [],
      columns: [
        { text: 'Folio', value: 'refCode'},
        { text: 'Fecha Creación', value: 'createdAt'},
        { text: 'Pricer', value: 'pricer'},
        { text: 'Cliente', value: 'client.name' },
        { text: 'Responsable', value: 'salesManager'},
        { text: 'Material Peligroso', value: 'dangerous' },
        { text: 'Urgente', value: 'urgent' },
        { text: 'Status', value: 'status' },
      ],
      MetaData:{},
      options:{
        page: 1,
        itemsPerPage: 10,
        pageStart: 0,
        pageStop: 0,
        pageCount: 0,
        itemsLength: 0
      },
      cardStatus: 0,
      chip: 0,
      itemsPP: 50,
      dataFilter:[],
      headers: [],
      AssignedQty: 0,
      AnsweredQty: 0,
      SentQty: 0,
      shippingConfirmation: 0,
      shippingConfirmed: 0,
      loading: false,
      search:"",
      expanded: [],
      singleExpand: false,
      selected: [],
      tab: 0,
      items2: [],
      steps: [
        {
          target: "#table-column-filter",
          header: {
            title: "Filtrar Columnas",
          },
          content: "Selecciona las columnas que deseas visualizar en la tabla"
        }
      ],
      tourOptions: {
        debug: true,
        hightlight: true,
        labels: {
          buttonSkip: 'Salir',
          buttonPrevious: 'Anterior',
          buttonNext: 'Siguiente',
          buttonStop: 'Finalizar'
        }
      },
      tourCallbacks: {
        onSkip: this.skipPricingTour,
        onFinish: this.skipPricingTour
      }
    }
  },
  watch: {
      activeColumns: {
        handler() {
          console.log("=>(Table-Menu.vue:399) this.activeColumns", this.activeColumns);
          const selectedColumns = [
            ...this.activeColumns.map(index => this.columns[index]),
            { text: 'Proceder', value: 'action'},
            { text: '', value: 'data-table-expand'}
          ]
          this.headers = selectedColumns
          console.log("=>(Table-Menu.vue:404) selectedColumns", selectedColumns);
          this.$store.dispatch('updateFilterPricingColumns', selectedColumns);
        }
      },
      dataFilter: {
        handler() {
          console.log("=>(Table-Menu.vue:411) this.dataFilter", this.dataFilter);
        }
      },
      columns: {
        handler() {
          // this.activeColumns = this.columns ? this.columns.map(column => column.text) : []
        }
      },
      chip: {
        handler() {
          if (this.chip === undefined) {
            this.chip = 0
            this.search = null
          }
        }
      },
    search: debounce(function (event) {
      this.findOrders(event)
    }, 1000)
  },
  computed: {
    allSelected() {
      return this.selected.length === this.items.length
    },
    categories() {
      const search = this.search.toLowerCase()

      if (!search) return this.items

      return this.items.filter(item => {
        const text = item.text.toLowerCase()

        return text.indexOf(search) > -1
      })
    },
    selections() {
      const selections = []

      for (const selection of this.selected) {
        selections.push(selection)
      }
      return selections
    },
  },
  methods: {
    updatePricer: function(item){
      this.$store.dispatch('working', true)
      this.$http.patch(`Orders/${item.id}`, [
        {
          "op": "replace",
          "path": "/pricerId",
          "value": item.pricerId
        },
      ])
        .finally(() => {
          this.$store.dispatch('working', false)
        })
    },
    getPricers: function () {
      this.$http.get('Employees/Pricing', {
        headers: {
          'Cache-Control': 'max-age=10000'
        }
      })
          .then(response => {
            this.pricingUsers = response.data.data;
          })
          .catch(error => {
            console.log(error)
          })
    },
    GetData2:function() {
      this.$http.get('Orders/confirmed', {
        params:{
          PageSize:this.options.itemsPerPage,
          PageNumber:this.options.page,
        }
      }).then((response) => {
        let res = response.data.data
        this.MetaData = response.data.meta;
        this.items2 = res.map((element) => {
          return {
            ...element,
            TransportationCategory: element.transportationCategory,
            TransportationCategoryId: element.transportationCategoryId,
            Origin: element.origin,
            Destination: element.destination,
            RefCode: element.refCode,
            SalesManager: element.salesManager,
            StatusOrder: 'Por Confirmar',
            Notification: element.notification,
            CreatedAt: element.createdAt,
            Client: element.client,
            Id: element.id,
          }
        })
      })
    },
    GetData1: function () {
      this.$http.get('Orders/confirmation', {
        params:{
          PageSize:this.options.itemsPerPage,
          PageNumber:this.options.page,
        }
      }).then((response) => {
        let res = response.data.data
        this.MetaData = response.data.meta;
        this.items2 = res.map((element) => {
          return {
            ...element,
            TransportationCategory: element.transportationCategory,
            TransportationCategoryId: element.transportationCategoryId,
            Origin: element.origin,
            Destination: element.destination,
            refCode: element.RefCode,
            SalesManager: element.salesManager,
            StatusOrder: 'Por Confirmar',
            Notification: element.notification,
            CreatedAt: element.createdAt,
            Client: element.client,
            Id: element.id,
          }
        })
      })
    },
    GetData: function (idStatus) {
      console.log(idStatus)
      if (idStatus === 8) {
        this.$http.get('Orders/confirmation').then((response) => {
          let res = response.data.data
          this.items2 = res.map((item) => {
            return {
              ...item,
              departureDate: this.$luxon(item.departureDate, {output: "full"}),
              //departureDate: new Date(item.departureDate).toLocaleDateString("es-MX"),
              createdAt: this.$luxon(item.createdAt, {output: {
                format: "short",
                locale: "es-MX"
              }}),
              salesManager: {
                ...item.salesManager,
                name: item.salesManager ? `${item.salesManager.firstName} ${item.salesManager.lastName}` : ''
              },
              dangerous: item.cargo.filter(x => x.Dangerous).length > 0,
            }
          })
        })

      }
      if (idStatus === 7) {
        this.$http.get('Orders/confirmed').then((response) => {
          let res = response.data.data
          this.items2 = res.map((item) => {
            return {
              ...item,
              departureDate: this.$luxon(item.departureDate, {output: "full"}),
              //departureDate: new Date(item.departureDate).toLocaleDateString("es-MX"),
              createdAt: this.$luxon(item.createdAt, {output: {
                format: "short",
                locale: "es-MX"
              }}),
              salesManager: {
                ...item.salesManager,
                name: item.salesManager ? `${item.salesManager.firstName} ${item.salesManager.lastName}` : ''
              },
              dangerous: item.cargo.filter(x => x.Dangerous).length > 0,
            }
          })
        })
      }
    },
    SendRequested:function () {

      this.$store.dispatch('working', true)
      this.$http.put(`Orders/${this.id}/requested`).then(response => {
        if(response.data.code == 200){
          console.log(response.data.data);
          this.$toast.info("Enviado a Solicitado a Proveedor");
          this.requestModal = false;
          this.GetData();
          this.$emit('change', true)
        }

        if (response.data.code == 400 || response.data.code == 500)
          this.$toast.error("Algo salio mal, intente mas tarde")
      }).catch(error=>{


        this.$toast.error("Ocurrio un error");
        console.log(error)
      }).finally(()=>{
        this.$store.dispatch('working',false);
      });
    },
    SendSales:function () {

      this.$store.dispatch('working', true)
      this.$http.post(`Orders/${this.id}/send/sales`).then(response => {


        if (response.data.code == 200){
          this.$toast.info("Enviado a ventas");
          this.ChangeStatus = false;
          this.GetData();
          this.$emit('change', true)
          console.log(response.data.data);
          // this.$router.push({name: 'Quote'})
        }


        if (response.data.code !==200){
          this.$toast.error("Necesita registrar precio")
          // this.$router.push({name: 'Quote'})
        }

        if (response.data.code == 400 || response.data.code == 500)
          this.$toast.error("Algo salio mal, intente mas tarde")
      }).catch(error=>{
        if(error.response.status == 401){
          this.$store.commit('reset');
          this.$toast.error("La sesion expiro");
          this.$router.replace('/login')
        }

        this.$toast.error("Ocurrio un error");
        console.log(error)
      }).finally(()=>{
        this.$store.dispatch('working',false);
      });
    },
    showModal:function (id,Status) {

      this.id = id;

      if (Status == 1){
        this.SendRequested()
      }

      if (Status == 2){
        this.SendSales();
      }

    },
    OrderStatus:[
      {
        id:1,
        name:'Solicitado a Proveedor'
      },

    ],
    updateOrderCount(order) {
      console.log(order)
      if (order)
        this.addOrderToData(order)

      this.$http.get('Orders/count/pricer').then(response => {
        if (response.data.code == 200) {
          this.SentQty = response.data.data.answered
          this.AssignedQty = response.data.data.assigned
          this.AnsweredQty = response.data.data.requested
          this.shippingConfirmation = response.data.data.shippingConfirmation
          this.shippingConfirmed = response.data.data.shippingConfirmed
        }
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.$store.dispatch('working', false);
      })
    },
    addOrderToData(data) {
      console.log(data.statusOrder)
      console.log(this.$refs)
      const ASSIGNED_STATUS = ["Pendiente Pricing", "Renegociación"];
      const REQUESTED_STATUS = ["Solicitado a Proveedor"];
      const SEND_STATUS = ["Enviado a Ventas", "Enviada a cliente", "Vendida", "Cancelada"];

      if (this.tab === 0 && ASSIGNED_STATUS.findIndex(x => x === data.statusOrder) !== -1)
        this.$refs['requestRef'].UpdateItemData(data)
      if (this.tab === 1 && REQUESTED_STATUS.findIndex(x => x === data.statusOrder) !== -1)
        this.$refs['receivedRef'].UpdateItemData(data)
      if (this.tab === 2 && SEND_STATUS.findIndex(x => x === data.statusOrder) !== -1)
        this.$refs['sendRef'].UpdateItemData(data)
    },
    findOrders(event){
      if (this.chip === 0) {
        this.$http.get('Orders/Find',{
          headers: {
            'Cache-Control': 'max-age=3'
          },
          params: {
            Refcode:event,
            Status:'',
            PageSize: 15,
          }
        }).then(response => {
          console.log("=>(Table-Menu.vue:669) response.data.data", response.data.data);
          this.dataFilter = response.data.data.map((element) => {
            return {
            ...element,
            TransportationCategory: element.TransportationCategory,
            TransportationCategoryId: element.TransportationCategoryId,
            Origin: element.Origin,
            Destination: element.Destination,
            refCode: element.RefCode,
            salesManager: element.SalesManager,
            StatusOrder: 'Por Confirmar',
            Notification: element.notification,
            createdAt: element.CreatedAt,
            client: element.Client,
            id: element.Id,
            pricer: element.Pricer,
            pricerId: element.PricerId
          }
          })
        }).catch(error=>{
          console.log(error)
        }).finally(()=>{
          console.log('finally')
        })
      }
      if (this.chip === 1) {
        this.$http.get('Orders/Find',{
          headers: {
            'Cache-Control': 'max-age=3'
          },
          params: {
            Client:event,
            Status:'',
            PageSize: 15,
          }
        }).then(response => {
          console.log("=>(Table-Menu.vue:657) response.data.data", response.data.data);
          this.dataFilter = response.data.data.map((element) => {
            return {
              ...element,
              TransportationCategory: element.TransportationCategory,
              TransportationCategoryId: element.TransportationCategoryId,
              Origin: element.Origin,
              Destination: element.Destination,
              refCode: element.RefCode,
              salesManager: element.SalesManager,
              StatusOrder: 'Por Confirmar',
              Notification: element.notification,
              createdAt: element.CreatedAt,
              client: element.Client,
              id: element.Id,
              pricer: element.Pricer,
              pricerId: element.PricerId
            }
          })
        }).catch(error=>{
          console.log(error)
        }).finally(()=>{
          console.log('finally')
        })
      }
      if (this.chip === 2) {
        this.$http.get('Orders/Find',{
          headers: {
            'Cache-Control': 'max-age=3'
          },
          params: {
            SalesManager:event,
            Status:'',
            PageSize: 15,
          }
        }).then(response => {
          console.log(response.data.data)
          this.dataFilter = response.data.data.map((element) => {
            return {
              ...element,
              TransportationCategory: element.TransportationCategory,
              TransportationCategoryId: element.TransportationCategoryId,
              Origin: element.Origin,
              Destination: element.Destination,
              refCode: element.RefCode,
              salesManager: element.SalesManager,
              StatusOrder: 'Por Confirmar',
              Notification: element.notification,
              createdAt: element.CreatedAt,
              client: element.Client,
              id: element.Id,
              pricer: element.Pricer,
              pricerId: element.PricerId
            }
          })
        }).catch(error=>{
          console.log(error)
        }).finally(()=>{
          console.log('finally')
        })
      }
    },
    proceed:function (id){
      console.log("=>(Table-Menu.vue:780) id", id);

      this.$router.push(`/ProceedQuote/${id}`)
    },
    skipPricingTour() {
      console.log("=>(Table-Menu.vue:760) skipPricingTour");
      this.$store.dispatch('updateShowPricingMainTour', false)

    }
  },
  mounted() {
    this.activeColumns = this.$store.state.filterPricingColumns
        .map(({text}) => this.columns.findIndex(column => column.text === text))
        .filter(index => index !== -1);
    this.headers = this.$store.state.filterPricingColumns
    this.getPricers();
    console.log("=>(Table-Menu.vue:795) this.headers", this.headers);


    this.updateOrderCount();

    this.$hub.Connection.on("OrderPricesUpdate", () => {

      this.updateOrderCount
    })
    this.$hub.Connection.on("OrderCreated", () => {

      this.updateOrderCount
    })
    this.$hub.Connection.on("OrderUpdated", () => {

      this.updateOrderCount
    })
    this.$hub.Connection.on("OrderRenegotiationStatus", () => {

      this.updateOrderCount
    })
    this.$hub.Connection.on("OrderReturnToSales", () => {

      this.updateOrderCount
    })
    this.$hub.Connection.on("OrderSendToSales", () => {

      this.updateOrderCount
    })
    this.$hub.Connection.on("OrderSold", () => {

      this.updateOrderCount
    })
    this.$hub.Connection.on("OrderRejected", () => {

      this.updateOrderCount
    })
    console.log(this.$hub.Connection)
  },
  beforeDestroy() {
    this.$hub.Connection.off("OrderPricesUpdate", this.updateOrderCount)
    this.$hub.Connection.off("OrderCreated", this.updateOrderCount)
    this.$hub.Connection.off("OrderUpdated", this.updateOrderCount)
    this.$hub.Connection.off("OrderRenegotiationStatus", this.updateOrderCount)
    this.$hub.Connection.off("OrderReturnToSales", this.updateOrderCount)
    this.$hub.Connection.off("OrderSendToSales", this.updateOrderCount)
    this.$hub.Connection.off("OrderSold", this.updateOrderCount)
    this.$hub.Connection.off("OrderRejected", this.updateOrderCount)
    console.log(this.$hub.Connection)
  }

}
</script>

<style scoped>
>>>.v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
  display: flex;
  flex: 1 1 auto;
  position: relative;
  align-self: center;
}
>>> .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner {
  padding-top: 0 !important;
  margin-top: 0 !important;
}
>>>.v-text-field--outlined.v-input--dense .v-label {
  top: 5px;
}
.v-tab /deep/ {
  text-transform: none !important;
}

.v-data-table td {
  padding: 0 20px;
}

.dashboard-overlay >>> .v-overlay__content {
  width: calc(100% - 56px);
  height: calc(100% - 56px);
  min-width: calc(100% - 56px);
  min-height: calc(100% - 56px);
}
</style>
