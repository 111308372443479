<template>
  <v-card class="mx-auto border-rounded" tile elevation="3" max-width="1000">
    <v-card-text>
      <v-row justify="center">
        <!--  Quote header row  -->
        <v-col cols="12">
          <slot name="DetailQuote">
            <v-row class="detail-quote">
              <v-col cols="3">
                <v-select v-model="transport"
                          :items="transporte"
                          item-text="name"
                          item-value="id"
                          dense
                          :readonly="EditMode"
                          prepend-inner-icon="las la-route"
                          label="Transporte"
                          @change="sendData"
                          :rules="transportRules"></v-select>
              </v-col>
              <v-col cols="3">
                <v-select prepend-inner-icon="las la-list-ul"
                          v-model="service"
                          :items="Service"
                          item-text="description"
                          item-value="id"
                          label="Servicio"
                          dense
                          @change="sendData"
                          :rules="ServiceRules"></v-select>
              </v-col>
              <v-col cols="3">
                <v-select prepend-inner-icon="las la-exchange"
                          v-model="Route"
                          item-text="description"
                          item-value="id"
                          dense
                          :items="TypeRoute"
                          :disabled="TypeRoute.disabled"
                          label="Tipo"
                          @change="sendData"
                          :rules="TipeRules"></v-select>
              </v-col>
              <v-col cols="3" v-if="transport!==3">
                <v-select :disabled="showIncoterm"
                          v-model="Incoterm"
                          :items="Incoterms"
                          item-text="code"
                          dense
                          item-value="id"
                          prepend-inner-icon="las la-boxes"
                          label="Incoterm"
                          @change="sendData"
                          :rules="IncotermRules"></v-select>
              </v-col>
            </v-row>
          </slot>
        </v-col>
        <!--  Quote body row  -->
        <v-col md="12" class="py-0 d-flex mx-7 px-3">
          <v-row>
            <v-col cols="8">
              <slot name="autocomplete"></slot>
              <v-select @change="sendData"
                        v-model="filterRoute"
                        dense class="col-md-5 ml-3"
                        outlined
                        label="Tipo de Movimiento"
                        v-if="transportation==1"
                        :key="keyfilter"
                        :items="searchFilter"
                        item-text="description"
                        item-value="id">

                        </v-select>

                        <v-select @change="sendData"
                        v-model="filterRoute"
                        v-if="transportation==2"
                        label="Tipo de Movimiento"
                        dense class="col-md-5 ml-3"
                        outlined
                        :key="keyfilter"
                        :items="searchFilterAereo"
                        item-text="description"
                        item-value="id">
                        </v-select>
            </v-col>
            <v-col cols="4">
              <slot name="Duedate">
                <v-menu
                    ref="menu"
                    v-model="menu2"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="internalDate"
                        label="Dia de salida"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="internalDate"
                      color="primary"
                      no-title
                      scrollable
                      @input="menu2 = false"
                      :min="incrementDate(new Date(),1).toISOString().substr(0, 10)"
                  >
                  </v-date-picker>
                </v-menu>
              </slot>
            </v-col>
          </v-row>
        </v-col>

        <slot name="cargo"></slot>
        <slot name="searchButton"></slot>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "QuotData",
  props: {
    Typetransportation: Number,
    TypeService: Number,
    Inconterm: Number,
    filterRoute: {
      type:Number,
      default: 1
    },
    Type: Number,
    dueDate: {
      type: String,
      default: new Date().toISOString().substr(0, 10)
    },
    origin: {},
    destination: {},
    EditQuote: {},
    AutocompleteOrigin: Boolean,
    AutocompleteDestination: Boolean,
    EditMode: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    changeOrigin: {
      get: function () {
        return this.AutocompleteOrigin
      },
    },

    transport: {
      get: function () {
        return this.Typetransportation
      },

      set: function (val) {
        this.transportation = val;
        // this.sendData();
      }

    },

    service: {
      get: function () {
        return this.TypeService
      },

      set: function (val) {
        this.transportationService = val;
        // this.sendData();
      }

    },

    Incoterm: {
      get: function () {
        return this.Inconterm
      },

      set: function (val) {
        this.incoterm = val;
        // this.sendData();
      }

    },

    Route: {
      get: function () {
        return this.Type
      },

      set: function (val) {
        this.type = val;

        // this.sendData();
      }

    },
  },
  watch: {
    internalDate() {
      this.sendData();
    },

    menu2(val) {
      this.$emit('dateMenuChange', val)
    },
    transport() {
      this.defaultPlaces();
      this.ChangeTransportation();
      this.getIncoterms();
      this.getOrderIncoterms();
    },
    path() {
      this.$emit('pathUpdated', this.path)
    },
    originAddressSearch() {
      this.googleSearch();
    },
    Servicedef: function (val) {
      if (val == 'NA') {
        this.$toast.error("Es necesario elegir un servicio porfavor contacte un asesor")

      }
    }
  },
  data: () => ({
    internalDate: new Date().toISOString().substr(0, 10),
    transportation: 1,
    showIncoterm: false,
    transportationService: null,
    type: null,
    searchFilter:[
      {
        id:4,
        description:"Puerta a puerto"
      },
      {
        id:2,
        description:"Puerto a puerto"
      },
      {
        id:3,
        description: "Puerto a puerta"
      },
      {
        id:1,
        description: "Puerta a puerta"
      }
    ],
    searchFilterAereo:[
      {
        id:4,
        description:"Puerta a aeropuerto"
      },
      {
        id:2,
        description:"Aeropuerto a aeropuerto"
      },
      {
        id:3,
        description: "Aeropuerto a puerta"
      },
      {
        id:1,
        description: "Puerta a puerta"
      }
    ],
    incoterm: null,
    date: null,
    filterOptions: false,
    destinationAddress: "",
    originAddress: "",
    Service: [],
    OrderType: null,
    transporte: [],
    transportedef: "",
    nameRules: [
      v => !!v || 'Nombre es requerido',
    ],
    transportRules: [
      v => !!v || 'Transporte es requerido',
    ],
    ServiceRules: [
      v => !!v || 'Servicio es requerido',
    ],
    TipeRules: [
      v => !!v || 'Tipo es requerido',
    ],
    IncotermRules: [
      v => !!v || 'Incoterm es requerido',
    ],
    Servicedef: "",
    TypeRoutedef: "",
    TypeRoute: [],
    Incoterms: [],
    menu2: false,
    keyfilter:0
  }),
  methods: {
    setSearchFilter(transportation){
      if (transportation === 1){
        alert(transportation)
        this.searchFilter = [
          {
            id:1,
            description:"Puerta a puerto"
          },
          {
            id:2,
            description:"Puerto a puerto"
          },
          {
            id:3,
            description: "Puerto a puerta"
          },
          {
            id:4,
            description: "Puerta a puerta"
          }
        ]
        this.filterRoute = null;
      }

      if (transportation === 2){
        this.searchFilter = []
        this.filterRoute = null;
      }
      this.keyfilter++;



      console.log('Este mero',this.searchFilter)




    },
    incrementDate(dateInput, increment) {
      let dateFormatTotime = new Date(dateInput);
      return new Date(dateFormatTotime.getTime() + (increment * 86400000));
    },
    QuotDataDefault() {
      this.Typetransportation = null;
      this.TypeService = null;
      this.Inconterm = null;
      this.Type = null;
      this.internalDate = this.incrementDate(new Date(), 1).toISOString().substr(0, 10);

      /*this.QuotData = {
        Typetransportation: null,
        TypeService:null,
        Inconterm:null,
        typeRoute:null,
        dueDate:new Date().toISOString().substr(0, 10),
        origin: {},
        destination: {},
        searchVariables: {},
        DestinationPort:{},
        OriginPort:{},
        AutocompleteOrigin:false,
        AutocompleteDestination:false,
        DataService:{},
      }*/
    },

    googleSearchLocation: function (location) {
      return new Promise(resolve => {
        this.geocode.geocode({
          location
        }, (results, status) => {
          if (status == "OK") {
            resolve(results)
          } else {
            console.error(status)
          }
        })
      });
    },
    getOrderTypes: function () {

      this.$http.get('OrderTypes')
          .then(response => {
            if (response.data.code == 200) {
              console.log(response.data.data);
              let res = response.data.data;
              this.TypeRoute = res.map(item => {
                return {
                  ...item,
                  disabled: false,
                }
              });
            /*  let index = this.TypeRoute.findIndex(x=> x.description == 'Nacional')
              this.TypeRoute[index].disabled = true;*/

            }

            if (response.data.code == 400 || response.data.code == 500)
              this.$toast.error("Algo salio mal, intente mas tarde")

          })
          .catch(error => {
            if (error.response.status == 401) {
              this.$store.commit('reset');
              this.$toast.error("La sesion expiro");
              this.$router.replace('/login')
            }
            console.log(error)
          })
          .finally(() => this.loading = false)
    },
    defaultPlaces() {
      this.origin = {};
      this.destination = {};
      this.originAddress = {};
      this.destinationAddress = {};
    },
    async getIncoterms() {
      try {
        const response = await this.$http.get('Incoterms', {
          headers: {
            'Cache-Control': 'max-age=500'
          }
        });

        if (response.data.code === 400 || response.data.code === 500) {
          this.$toast.error("Algo salió mal, intente más tarde");
          return;
        }

        if (response.data.code === 200) {
          this.Incoterms = response.data.data;
        }

        const filterMap = {
          '1-1': [1, 2, 4, 5, 6, 10],
          '1-2': [2, 4, 5, 6, 10, 11],
          '2-1': [1, 2, 4, 5, 6, 7, 10, 11],
          '2-2': [5, 7, 10, 11],
        };

        const key = `${this.Typetransportation}-${this.Type}`;
        const filterIds = filterMap[key] || [];

        this.Incoterms = this.Incoterms.filter(incoterm => filterIds.includes(incoterm.id));

      } catch (error) {

        console.error(error);
        this.errored = true;
      } finally {
        this.loading = false;
      }
    },
    getOrderIncoterms: function () {
    },
    async getTransportationCategories(val) {
      try {
        this.loading = true; // Asumiendo que tienes una propiedad 'loading' para manejar el estado de carga.
        const response = await this.$http.get(`TransportationCategories/${val}`);

        if (response.data.code === 200) {
          this.Service = response.data.data.map(item => ({
            ...item,
            disabled: [14, 16, 15].includes(item.id),
          }));
          console.log(this.Service); // Conservamos la consola log para verificar los datos si es necesario.
        } else if (response.data.code === 400 || response.data.code === 500) {
          this.$toast.error("Algo salió mal, intente más tarde");
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$store.commit('reset');
          this.$toast.error("La sesión expiró");
          this.$router.replace('/login');
        } else {
          console.error('Error al obtener categorías de transporte:', error);
        }
        this.errored = true;
      } finally {
        this.loading = false;
      }
    },
    async getTransport() {
      try {
        const response = await this.$http.get('Transportation');

        if (response.data.code === 200) {
          this.transporte = response.data.data;
          if (this.transport) {
            this.getTransportationCategories(this.transport);
          }
        } else if (response.data.code === 400 || response.data.code === 500) {
          this.$toast.error("Algo salió mal, intente más tarde");
        }
      } catch (error) {
        console.error(error);
        this.errored = true;
      } finally {
        this.loading = false;  // Asumiendo que tienes una propiedad 'loading' para manejar el estado de carga.
      }
    },
    ChangeTransportation() {
      if (this.transport == 1) {
        this.showIncoterm = false;
        this.$emit('Transporte', 1)
        this.getTransportationCategories(this.transport)

      }

      if (this.transport == 2) {
        this.showIncoterm = false;
        this.$emit('Transporte', 2)
        this.getTransportationCategories(this.transport)


      }

      if (this.transport == 3) {
        this.showIncoterm = true;
        this.$emit('Transporte', 3)
        this.getTransportationCategories(this.transport)

      }


    },
    sendData: function () {
      // console.log(obj)
      // console.log("Send Data",this.transportation)
      if (this.type && this.transportation)
        this.getIncoterms();

      let QuotData = {
        transport: this.transportation,
        service: this.transportationService,
        incoterm: this.incoterm,
        typeRoute: this.type,
        origin: this.origin,
        destination: this.destination,
        dueDate: this.internalDate,
        filterRoute:this.filterRoute,
        DataService: {
          Container: false,
          Pallets: false,
          Bulk: false,
          tab: 0
        }
      }
      if (QuotData.service == 11 || QuotData.service == 2 || QuotData.service == 3 || QuotData.service == 12 || QuotData.service == 13) {
        QuotData.DataService = {
          Container: false,
          Pallets: true,
          Bulk: true,
          tab: 0
        }
      } else {
        QuotData.DataService = {
          Container: true,
          Pallets: false,
          Bulk: true,
          tab: 1
        }
      }
      // console.log("Quot Data:", QuotData);
      this.$store.dispatch('filterRoute',this.filterRoute);
      console.log('aqui', QuotData)
      this.$emit('QuotData', QuotData);
    },
    setDataOrder() {
      // console.log("This Data", this);
      this.transport = this.Typetransportation
      this.service = this.TypeService
      this.Incoterm = this.Inconterm
      this.Route = this.Type
      this.sendData();
      // console.log("This Data", this.transport);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.internalDate = this.incrementDate(new Date(), 1).toISOString().substr(0, 10);
      // console.log("Bind data",this.Typetransportation)
      this.setDataOrder();
      this.getIncoterms();
      this.getOrderTypes();
      this.getTransport();
    });

  },


}
</script>

<style scoped>
.detail-quote >>> .v-input__slot::before {
  border-style: none !important;
}
</style>
