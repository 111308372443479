import { render, staticRenderFns } from "./EditQuote.vue?vue&type=template&id=134d8e77&scoped=true"
import script from "./EditQuote.vue?vue&type=script&lang=js"
export * from "./EditQuote.vue?vue&type=script&lang=js"
import style0 from "./EditQuote.vue?vue&type=style&index=0&id=134d8e77&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "134d8e77",
  null
  
)

export default component.exports