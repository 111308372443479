<template>
  <div class="py-12">
    <v-card width="800" elevation="24" class="mx-auto"  style="border-radius:20px">
      <v-row>

        <v-col md="6" sm="12" class="pa-0">
          <v-img src="@/assets/image/pexels-pixabay-326410.png" style="border-bottom-left-radius: 20px; border-top-left-radius: 20px"></v-img>
        </v-col>

        <v-col md="6" class="py-10">
          <slot name="LoginFields"></slot>
        </v-col>

      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
name: "AuthBase"
}
</script>

<style scoped>

</style>
