<template>
    <div>
      <preview />
    </div>
</template>

<script>
import preview from "../../../components/Test/previewComponent.vue";
export default {
name: "PreviewComponent",
components: {
    preview
}
}
</script>

<style scoped>

</style>