<template>
  <div>
    <div v-if="(type === 1 || type === 2) && routes.length > 0" class="mr-2 ml-2">
      <v-text-field
          v-model="shipment.masterBl.bookingNumber"
          label="Booking Number"
      ></v-text-field>
      <div v-for="(route,index) in routes" :key="index">
        <v-row v-if="route.isFirst">
          <v-col cols="12">
            <v-menu
                :ref="'menu' + index"
                v-model="route.menu"
                :close-on-content-click="false"
                :return-value.sync="route.departureDate"
                min-width="auto"
                offset-y
                transitions="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="route.departureDate"
                    append-icon="mdi-calendar"
                    dense
                    label="Fecha de Origen"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="route.departureDate"
                  color="primary"
                  no-title
                  scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click="route.menu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                    color="primary"
                    text
                    @click="$refs['menu' + index][0].save(route.departureDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <div v-if="route.transportationId===1 && route.orderTypeId===1 && route.isFirst ">
          <v-select
              v-model="route.event"
              :items="ImpoSelect"
              append-icon="mdi-ferry"
              dense
              item-text="name"
              item-value="id"
              label="Evento"
              menu-props="auto"
              @change="setLabelAereo(route.event, index)"
          ></v-select>
        </div>
        <div v-if="route.transportationId=== 2 && route.orderTypeId===1 && route.isFirst">
          <v-select
              v-model="route.event"
              :items="ImpoSelect"
              append-icon="mdi-airplane"
              dense
              item-text="name"
              item-value="id"
              label="Evento"
              menu-props="auto"
              @change="setLabelAereo(route.event, index)"
          ></v-select>
        </div>
        <div v-if="route.transportationId=== 1 && route.orderTypeId===2 && route.isFirst">
          <v-select
              v-model="route.event"
              :items="expoOcean"
              append-icon="mdi-ferry"
              dense
              item-text="name"
              item-value="id"
              label="Evento"
              menu-props="auto"
              @change="setLabel(route.event, index)"
          ></v-select>
        </div>
        <div v-if="route.transportationId=== 2 && route.orderTypeId===2 && route.isFirst">
          <v-select
              v-model="route.event"
              :items="expoAir"
              append-icon="mdi-airplane"
              dense
              item-text="name"
              item-value="id"
              label="Evento"
              menu-props="auto"
              @change="setLabelAereo(route.event, index)"
          ></v-select>
        </div>
      </div>
      <div v-if="routes.length > 0">
        <span v-if="shipment.users.length > 0" class="mb-6">
<!--          <div  v-if="value.users.length === 3" class="mb-6">
            <b class="mr-1">Operador: </b>
                      <span v-if="value.users[2]"> {{value.users[2].user.firstName}} {{value.users[2].user.lastName}}</span>
          </div>
          <div  v-if="value.users.length === 2" class="mb-6">
            <b class="mr-1">Operador: </b>
                      <span v-if="value.users[1]"> {{value.users[1].user.firstName}} {{value.users[1].user.lastName}}</span>
          </div>
          <div  v-if="value.users.length === 1" class="mb-6">
            <b class="mr-1">Operador: </b>
                      <span v-if="value.users[0]"> {{value.users[0].user.firstName}} {{value.users[0].user.lastName}}</span>
          </div>-->
          <div class="mb-6">
            <b class="mr-1">Operador: </b>
          <span>{{value.users[operator].user.firstName}} {{value.users[operator].user.lastName}}</span>
          </div>
                    </span>
        <v-row class="mr-2 ml-2 mb-2">
           <v-btn color="white" block @click="saveRutaNShipment">Guardar</v-btn>
        </v-row>
        <v-row class="mr-2 ml-2 mb-2">
            <v-btn color="primary" @click="operDailog" block >Cambiar de status</v-btn>
        </v-row>
      </div>
    </div>

    <v-dialog
        v-model="dialog2"
        width="500"
    >

      <v-card>
        <v-card-title class="text-h5 primary white--text" style="text: white">
          Confirmar embarque
        </v-card-title>

        <v-row>
          <v-col>
            <v-card-text class="mt-2">
              Estas seguro que deseas enviar a operciones?
            </v-card-text>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="Confirmshipping"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <div v-if="type === 3  && routes.length > 0" class="mr-2 ml-2 mt-2">
      <div  v-for="(route,index) in routes" :key="index">
        <div v-if="route.transportationId == 3">
          <h4 v-if="route.isFirst" >Pick up</h4>
          <h4 v-if="route.isLast">Delivery</h4>
          <SLandAutocomplete
              v-if="route.isFirst || route.isLast"
              :id="1"
              :items="originItems"
              :placeholder="route.location.address"
              :search="route.originAddress"
              :selections="originSelections"
              dense
              label="Origen"
              transportation="SEA"
              @change="originSelectedTerrestre"
              @change2="setIndex(index)"
              @updateAddress="updateOriginAddress"
              @updateItems="updateOriginItems"
          >
          </SLandAutocomplete>

          <!-- Esta es laa fecha cuando es primero -->
          <v-row v-if="route.isFirst">
            <v-col cols="12">
              <v-menu
                  :ref="'menu' + index"
                  v-model="route.menu"
                  :close-on-content-click="false"
                  :return-value.sync="route.departureDate"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="route.departureDate"
                      append-icon="mdi-calendar"
                      dense
                      label="Pick Up Date"
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="route.departureDate"
                    color="primary"
                    no-title
                    scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      color="primary"
                      text
                      @click="route.menu = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                      color="primary"
                      text
                      @click="$refs['menu' + index][0].save(route.departureDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <!-- Esta es la fecha cuando es ultimo-->
<!--          <SLandAutocomplete
              v-if="route.isLast"
              label="Destino"
              :search="destinationAddress"
              :items="destinationItems"
              :selections="destination"
              :filterRoute="4"
              transportation="AIR"
              :placeholder="route.location.address"
              @change="destinationSelectedTerrestre"
              @updateAddress="updateDestinationAddress"
              @updateItems="updateDestinationItems"
              :id="4"
              dense
          >
          </SLandAutocomplete>-->
          <v-row v-if="route.isLast">
            <v-col cols="12">
              <v-menu
                  :ref="'menu' + index"
                  v-model="route.menu"
                  :close-on-content-click="false"
                  :return-value.sync="route.arrivalDate"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="route.arrivalDate"
                      append-icon="mdi-calendar"
                      dense
                      label="Delivery date"
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="route.arrivalDate"
                    color="primary"
                    no-title
                    scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      color="primary"
                      text
                      @click="route.menu = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                      color="primary"
                      text
                      @click="$refs['menu' + index][0].save(route.arrivalDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

<!--          <v-row>
            <v-col cols="12" >
              <v-text-field
                  label="Conocimiento"
                  outlined
                  dense
                  v-model="shipment.refNumber"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                  label="Orden de Cliente"
                  outlined
                  dense
                  v-model="shipment.masterBl.houseBls[0].refNumber"
              >
              </v-text-field>
            </v-col>
          </v-row>-->
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                  v-if="route.isFirst"
                  label="Embarcador"
                  v-model="shipment.masterBl.houseBls[0].mEmbarcador"
                  :search-input.sync="shipment.masterBl.houseBls[0].mSearch"
                  item-text="companyName"
                  item-value="id"
                  clearable
                  dense
                  outlined
                  return-object
                  @keydown.delete="shipment.masterBl.houseBls[0].mFirst = false"
                  @click="emOrCon(0, 1)"
                  @click:clear="emOrCon(0, 1), shipment.masterBl.houseBls[0].mFirst = false"
                  :items="shipment.masterBl.houseBls[0].mItems"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                  label="Consignatario"
                  v-if="route.isFirst"
                  v-model="shipment.masterBl.houseBls[0].cConsig"
                  :search-input.sync="shipment.masterBl.houseBls[0].cSearch"
                  item-text="companyName"
                  item-value="id"
                  clearable
                  outlined
                  dense
                  return-object
                  @keydown.delete="shipment.masterBl.houseBls[0].cFirst = false"
                  @click="emOrCon(0, 2)"
                  @click:append-outer="emOrCon(0, 2)"
                  @click:clear="emOrCon(0, 2), shipment.masterBl.houseBls[0].cFirst = false"
                  :items="shipment.masterBl.houseBls[0].cItems"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </div>
      </div>
        <div>
          <v-autocomplete
              :items="operators"
              v-model="shipment.users[operator2].userId"
              :item-text="item => item.firstName + ' ' + item.lastName"
              item-value="userId"
              label="Operador"
              @click="getOperators"
              outlined
              dense
          ></v-autocomplete>
        </div>

      <div v-if="routes.length > 0">
        <v-row class="mr-2 ml-2 mb-2">
          <v-btn color="white" block @click="saveRutaNShipment">Guardar</v-btn>
        </v-row>
        <v-row class="mr-2 ml-2 mb-2">
          <v-btn color="primary" @click="dialog2 = true" block >Cambiar de status</v-btn>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import SLandAutocomplete from "@/components/google-maps-autocomplete-vuetify/SLandAutocomplete";
import debouncePromise from "debounce-promise";
export default {
  name: "timeLinePricing",
  components: {SLandAutocomplete},
  comments: { SLandAutocomplete },
  props: {
    elTab: Number,
    value: Object,
    type: Number,
  },
  data: (vm) => ({
    indexHouse: null,
    provedoress: [{}],
    search: null,
    dialog2: false,
    operators: [],
    destinationAddress: "",
    destinationItems: [],
    indexRutas: null,
    destination: {},
    originSelections: {},
    originAddress: "",
    origin: {},
    originItems: [],
    ImpoSelect: [
      {name: 'Confirmación de recolección', id: 'pickup_confirmation'},
      {name: 'Confirmación de zarpe', id: 'departure_confirmation'},
      {name: 'Confirmación de arribo', id: 'arrival_confirmation'},
      {name: 'Proceso de despacho', id: 'clearance_process'},
      {name: 'Entrega', id: 'delivery'},
    ],
    fechaExpoOcean: [
      {name: 'recoleccion'},
      {name: 'ingreso a terminal'},
      {name: 'despacho de mercancia'},
      {name: 'confirmacion de zarpe'},
      {name: 'arribo'},
      {name: 'entrega'},
    ],
    expoOcean: [
      {name: 'Confirmación de recolección', id: 'pickup_confirmation'},
      {name: 'Ingreso a terminal', id: 'entry_container_terminal'},
      {name: 'Despacho de mercancía', id: 'dispatch_merchandise'},
      {name: 'Confirmación de zarpe', id: 'departure_confirmation'},
      {name: 'Confirmación de arribo', id: 'arrival_confirmation'},
      {name: 'Entrega', id: 'delivery'},
    ],
    fechaExpoAir: [
      {name: 'recolección'},
      {name: 'salida de vuelo'},
      {name: 'arribo de vuelo'},
      {name: 'despacho'},
      {name: 'entrega'},
    ],
    isCon: null,
    expoAir: [
      {name: 'Confirmación de recolección', id: 'pickup_confirmation'},
      {name: 'Confirmación de salida de vuelo', id: 'departure_confirmation'},
      {name: 'Confirmación de arribo de vuelo', id: 'arrival_confirmation'},
      {name: 'Proceso de despacho', id: 'dispatch_merchandise'},
      {name: 'Entrega', id: 'delivery'},
    ],
    fechaLand: [
      {name: 'transito a shipper'},
      {name: 'llegada a shipper'},
      {name: 'carga y tránsito a shipper'},
      {name: 'llegada de recibidor'},
      {name: 'descarga'},
    ],
    landSelects: [
      {name: 'Transito a shipper', id: 'transit_to_shipper'},
      {name: 'LLego con shipper', id: 'arrive_to_shipper'},
      {name: 'Cargando y en transito a recibidor', id: 'loading_transit_to_receiver'},
      {name: 'LLego a recibidor', id: 'arrived_to_receiver'},
      {name: 'Descargando', id: 'Of_loaded'},
    ],
    isTerrestre0: false,
    shipin: null,
    typeCon: '',
    typeEM: '',
    itemsConsig: [{}],
    consig: null,
    routes: [],
    shipment: JSON.parse(JSON.stringify(vm.value)),
    justChanged: true,
  }),
  watch: {
    'shipment.masterBl.houseBls': {
      handler(){
        console.log('cambie houseBls')
        if (this.justChanged){
          console.log('primero')
          // this.justChanged = false;
        } else {
          if (this.isCon){
            if (this.shipment.masterBl.houseBls[this.indexHouse].mFirst){
              return;
            } else {
              if (this.shipment.masterBl.houseBls[this.indexHouse].mSearch === '' || this.shipment.masterBl.houseBls[this.indexHouse].mSearch === null) return;
              this.searchDebounce3(this.shipment.masterBl.houseBls[this.indexHouse].mSearch)
            }
          }
          if (!this.isCon) {
            if (this.shipment.masterBl.houseBls[this.indexHouse].cFirst){
              return;
            } else {
              if (this.shipment.masterBl.houseBls[this.indexHouse].cSearch === '' || this.shipment.masterBl.houseBls[this.indexHouse].cSearch === null ) return;
              console.log('entre a este')
              this.searchDebounce4(this.shipment.masterBl.houseBls[this.indexHouse].cSearch)
            }
          }
        }
      },
      deep: true
    },
    tab: {
      handler() {
        console.log('test')
      },
      deep: true
    }
  },
  computed: {
    operator: {
      get: function () {
        return this.value.users.findIndex(x => x.user.roles[0].roleId === 3)
      }
    },
    operator2: {
      get: function () {
        return this.shipment.users.findIndex(x => x.user.roles[0].roleId === 3)
      }
    }
  },
  methods: {
    operDailog(){
      this.saveRutaNShipment();
      this.dialog2 = true
    },

    getAllSupplyers4(search){
      console.log(search)
      // if (this.shipment.masterbl.houseBls[this.indexHouse].cSearch === '') return
      this.shipment.masterBl.houseBls[this.indexHouse].cFirst = true
      this.shipment.masterBl.houseBls[this.indexHouse].cItems = []
      this.$http.get('Suppliers/all/search', {
        params: {
          businessPartnerName: this.shipment.masterBl.houseBls[this.indexHouse].cSearch
        }
      }).then((response) => {
        console.log(response.data.data)
        let aux = response.data.data
        console.log(aux)
        if (aux === null){
          console.log(aux)
          this.shipment.masterBl.houseBls[this.indexHouse].cFirst = false
        } else {
          aux = aux.map((element) => {
            return {
              ...element,
              companyName: element.name,
            }
          })
          aux.forEach((element) => {
            console.log(element)
            this.shipment.masterBl.houseBls[this.indexHouse].cItems.push(element)
          })
        }

      })
    },
    getAllSupplyers3(search){
      console.log(search)
      // if (this.shipment.masterbl.houseBls[this.indexHouse].mSearch === '') return
      this.shipment.masterBl.houseBls[this.indexHouse].mFirst = true
      this.shipment.masterBl.houseBls[this.indexHouse].mItems = []
      this.$http.get('Suppliers/all/search', {
        params: {
          businessPartnerName: this.shipment.masterBl.houseBls[this.indexHouse].mSearch
        }
      }).then((response) => {
        console.log(response.data.data)
        let aux = response.data.data
        console.log(aux)
        if (aux === null) {
          console.log(aux)
        } else {
          aux = aux.map((element) => {
            return {
              ...element,
              companyName: element.name,
            }
          })
          aux.forEach((element) => {
            console.log(element)
            this.shipment.masterBl.houseBls[this.indexHouse].mItems.push(element)
          })
        }

      })
    },
    searchDebounce4: debouncePromise(function (search) {
      if (search === '') return
      this.getAllSupplyers4(search)
    }, 1000),
    searchDebounce3: debouncePromise(function (search) {
      if (search === '') return
      this.getAllSupplyers3(search)
    }, 2000),
    getAllSupplyers(search){
      this.provedoress = []
      this.$http.get(`Suppliers/all/search`,{
            params: {
              businessPartnerName: search,
            }
          }
      ).then((response) => {
        console.log(response)
        console.log(response)
        let aux = response.data.data
        if (aux === null){
          console.log(aux)
        } else {
          aux.forEach((element) =>{
            this.provedoress.push(element)
          })
          console.log(this.provedoress)
          console.log(this.items)
        }
      })
    },
    searchDebounce: debouncePromise(function (search) {
      this.getAllSupplyers(search)
    }, 1000),
    emOrCon(index, type){
      if (type === 1) {
        console.log('entre a emOrCon')
        this.indexHouse = index
        this.isCon = true
        this.justChanged = false
      }
      if (type === 2) {
        this.indexHouse = index
        this.isCon = false
        this.justChanged = false
      }
    },
    updateDestinationAddress(text) {
      this.destinationAddress = text
    },
    updateDestinationItems(items) {
      this.destinationItems = items;
    },
    Confirmshipping(){
      /*this.saveRutaNShipment();  esta comentado porque si le tiro los 2 servicios al mimso tiempo truena */
      this.$store.dispatch('working', true);
      this.$http.post(`Shipping/${this.shipment.id}/confirm`).then(response => {
        if (response.data.code == 200) {
          this.dialog2 = false;
          this.$toast.info("Embarque Confirmado");
        }
        if (response.data.code == 400 || response.data.code == 500)
          this.$toast.error("Algo salio mal, intente mas tarde")
      }).catch(() => {
        // console.log(error)
      }).finally(() => {
        this.$store.dispatch('working', false);
      })
    },

    getOperators: function () {
      this.$store.dispatch('working', true)
      this.$http.get('Employees/operators')
          .then(response => {
            console.log(response)
            this.operators = response.data.data;
          })
          .catch(error => {
            if (error.response.status == 401) {
              this.$store.commit('reset');
              this.$toast.error("La sesion expiro");
              this.$router.replace('/login')
            }
          }).finally(() => this.$store.dispatch('working', false))
    },

    updateOriginItems(items) {
      this.originItems = items;
      this.routes[this.indexRutas].itemsPort = items
    },
    setIndex(index) {
      console.log(index)
      this.indexRutas = index
    },
    updateOriginAddress(text) {
      this.originAddress = text;
      this.routes[this.indexRutas].originAddress = text;
    },
    originSelectedTerrestre(selections) {
      console.log('llegue')
      this.origin = selections;
      this.routes[this.indexRutas].originAddress = selections.description

      this.$nextTick(() => {
        try {
          this.routes[this.indexRutas].location.address = this.origin.description
          this.routes[this.indexRutas].location.country = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
          this.routes[this.indexRutas].location.state = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
          this.routes[this.indexRutas].location.city = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' || a === "sublocality" || a === "administrative_area_level_3" || a === 'neighborhood') !== -1)[0]?.long_name
          this.routes[this.indexRutas].location.state = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
          this.routes[this.indexRutas].location.city = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' ||
              a === "sublocality" ||
              a === "administrative_area_level_3" ||
              a === 'neighborhood' ||
              a === "administrative_area_level_2") !== -1)[0]?.long_name
          this.routes[this.indexRutas].location.coordinates = `${this.origin.details.location.lat},${this.origin.details.location.lng}`;
          this.routes[this.indexRutas].AutocompleteOrigin = true;
        } catch (e) {
          console.log(e)
        }
      })
    },
    destinationSelectedTerrestre(selections) {
      this.destination = selections;
      this.destinationAddress = selections.description;

      this.$nextTick(() => {
        try {
          this.QuotData.destination["address"] = this.destination.description
          this.QuotData.destination["country"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
          this.QuotData.destination["state"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
          this.QuotData.destination["city"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' || a === "sublocality" || a === "administrative_area_level_3" || a === 'neighborhood') !== -1)[0]?.long_name
          this.QuotData.destination["state"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
          this.QuotData.destination["city"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' ||
              a === "sublocality" ||
              a === "administrative_area_level_3" ||
              a === 'neighborhood' ||
              a === "administrative_area_level_2") !== -1)[0]?.long_name
          this.QuotData.destination["coordinates"] = this.destination.details.location;
          this.QuotData.AutocompleteDestination = true;
        } catch (e) {
          this.QuotData.destination = {}
        }
      })
    },
    setLabelTerrestre(event, indexRoute){
      console.log(event, indexRoute);
      let index = this.landSelects.findIndex(x => x.id === event)
      this.routes[indexRoute].labelDate = 'Fecha de ' + this.fechaLand[index].name
      this.routes[indexRoute].labelHora = 'Hora de ' + this.fechaExpoOcean[index].name
    },
    setLabelAereo(event, indexRoute) {
      console.log(event, indexRoute)
      let index = this.expoAir.findIndex(x => x.id === event)
      console.log(index)
      this.routes[indexRoute].labelDate = 'Fecha de ' + this.fechaExpoAir[index].name
      this.routes[indexRoute].labelHora = 'Hora de ' + this.fechaExpoAir[index].name
    },
    setLabel(event, indexRoute){
      let index = this.expoOcean.findIndex(x=> x.id === event)
      // console.log(index, indexRoute)
      // console.log(this.routes[indexRoute].labelDate, this.fechaExpoOcean[index].name)
      this.routes[indexRoute].labelDate = 'Fecha de ' + this.fechaExpoOcean[index].name
      this.routes[indexRoute].labelHora = 'Hora de ' + this.fechaExpoOcean[index].name

    },
    saveRoute() {
      this.$store.dispatch("working", true);
      let rutasEnviar = JSON.parse(JSON.stringify(this.routes))
      console.log(rutasEnviar)
      rutasEnviar.forEach((element, index) => {
        if (element.isFirst) {
          rutasEnviar[index].arrivalDate = null
        }
        if (element.isLast) {
          rutasEnviar[index].departureDate = null
        }
        if (element.location.id === 0) {
          rutasEnviar.splice(index, 1)
        }
      })
      this.$http.put(`shipping/${this.shipment.id}/routes`, rutasEnviar).then((response) => {
        if (response.data.code === 200) {
          this.$toast.info("Se a guardado la información");
        } else {
          this.$toast.error("Ocurrio un error");

        }
      }).catch((error) => {
        console.log(error)
      }).finally(() => {
        this.$store.dispatch("working", false);
        /*setTimeout(() => {
          this.updateOrder();
        }, 1000)*/
      })
    },
    saveRutaNShipment(){
      this.updateShipment()
      //
      setTimeout(() => {
        this.saveRoute()
      }, 2000)
    },
    sortRoutes() {
      console.log(this.value.routes.length)
      if (this.value.routes.length === 0) {
        this.routes = []
      } else {
        this.routes = JSON.parse(JSON.stringify(this.value.routes))
        console.log(this.routes)
        let delivery = JSON.parse(JSON.stringify(this.value.deliveryTypeId))
        let orderid = JSON.parse(JSON.stringify(this.value.orderTypeId))
        let transId = JSON.parse(JSON.stringify(this.value.transportationId))
        console.log('deliveryID: ', delivery, 'OrderID: ', orderid)
        this.routes.sort((a, b) => {
          return a.position - b.position
        })
        let length = this.routes.length
        this.routes = this.routes.map((element, index) => {
          element.departureDate = (this.formatDates(2, element.departureDate))
          element.arrivalDate = (this.formatDates(2, element.arrivalDate))
          element.departureTime = (this.formatDates(1, element.departureTime)) ?? ''
          element.arrivalTime = (this.formatDates(1, element.arrivalTime)) ?? ''
          return {
            ...element,
            supplierId: this.value.supplierId,
            hasChanged: false,
            itemsPort: [],
            AutocompleteOrigin: false,
            originAddress: "",
            destinationAddress: "",
            fab: false,
            menuTime: false,
            menuTime2: false,
            deliveryTypeId: delivery,
            menu2: false,
            menu: false,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            isFirst: index === 0,
            isLast: index === length - 1,
            transportationId: transId,
            orderTypeId: orderid,
            labelDate: 'Fecha de',
            labelHora: 'Hora de',
          }
        })
        if (this.routes.length === 1) {
          this.routes[0].isLast = false;
        }
        console.log(this.routes)
        //this.$store.dispatch("timeLine")
      }


    },
    formatDates(type, data) {
      if (type === 1) {
        data = this.$luxon(data, {
          input: {format: "iso", zone: "local"},
          output: {format: "time24"}
        })
      }
      if (type === 2) {
        if (data === null){
          data = null
        } else {
          data = data.substr(0, 10)
        }
      }
      return data
    },
    updateShipment() {
      console.log("shipment: ", this.shipment);
      let emails = this.shipment.emails;
      console.log(emails);
      console.log(this.shipment.incotermId)
      console.log(this.shipment)
      if(this.shipment.transportationId == 1){
        let updateShiping = {
          industry: this.shipment.industry,
          BusinessId: this.shipment.businessId,
          deliveryTypeId: this.shipment.deliveryType.id,
          orderTypeId: this.shipment.orderTypeId,
          id: this.shipment.id,
          IncotermId: this.shipment.incotermId,
          ClientId: this.shipment.client.id,
          TransportationId: this.shipment.transportationId,
          transportationcategoryId: this.shipment.transportationcategoryId,
          ClientSupplierName: "NA", // Donde esta esto?
          ReferenceNumber: "NA", // Donde esta esto?
          ShippingStatusId: this.shipment.shippingStatusId,
          ContactName: "this.shipme" + "ntToServer.contactName", //Donde esta esto?
          mails: this.shipment.emails ? this.shipment.emails.join(';') : null,
          Comments: "sjadjas", //Donde esta esto?
          ChangeTypeId: 2, // que es esto?
          QuotationId: this.shipment.quotationId,
          services: this.services,
          masterbl: {
            id: this.shipment.masterBl.id ?? 0,
            shipper: {
            },
            consignee: {
            },
            shippingLine: {
              supplierId: this.shipment.masterBl.shippingLine.supplierId,
            },
            documents: this.shipment.masterBl.documents,
            supplierId: this.shipment.masterBl.supplierId, //MasterBL
            bookingNumber: this.shipment.masterBl.bookingNumber,
            refNumber: this.shipment.masterBl.refNumber,
            shipName: this.shipment.masterBl.shipName, //Nombre de barco
            tripNumber: this.shipment.masterBl.tripNumber, //Nombre de viaje
            storageDays: this.shipment.masterBl.storageDays,
            delaysInPort: this.shipment.masterBl.delaysInPort,
            additionalInfo: this.shipment.masterBl.additionalInfo,
            houseBls: this.shipment.masterBl.houseBls,
          },
        };
        updateShiping = this.setEm(updateShiping)
        updateShiping = this.setCon(updateShiping)
        console.log(updateShiping)
        // console.log(updateShiping.masterbl)
        /*if (this.shipment.masterBl.houseBls === null || this.shipment.masterBl.houseBls.lenght === 0){
          console.log('nada')
        } else {
          updateShiping.masterbl.houseBls.forEach((element, index) => {
            // todo esto deberia estar en una funcion xd
            if (element.cConsig !== null){
              if (element.cConsig.type === 'ClientSupplier'){
                updateShiping.masterbl.houseBls[index].consignee = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].consignee.clientSupplierId = element.cConsig.id
                updateShiping.masterbl.houseBls[index].consignee.supplier = element.cConsig
                console.log(updateShiping.masterbl.houseBls[index].consignee)
              }
              if (element.cConsig.type === 'Client') {
                updateShiping.masterbl.houseBls[index].consignee = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].consignee.clientId = element.cConsig.id
                updateShiping.masterbl.houseBls[index].consignee.supplier = element.cConsig
              }
              if (element.cConsig.type === 'Forwarder') {
                updateShiping.masterbl.houseBls[index].consignee = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].consignee.forwarderId = element.cConsig.id
                updateShiping.masterbl.houseBls[index].consignee.supplier = element.cConsig
              }
              if (element.cConsig.type === 'Supplier'){
                updateShiping.masterbl.houseBls[index].consignee = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].consignee.supplierId = element.cConsig.id
                updateShiping.masterbl.houseBls[index].consignee.supplier = element.cConsig
              }
              if (element.cConsig.type === 'ClientBuyer'){
                updateShiping.masterbl.houseBls[index].consignee = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].consignee.clientBuyerId = element.cConsig.id
                updateShiping.masterbl.houseBls[index].consignee.supplier = element.cConsig
              }
            }
            if (element.mEmbarcador !== null) {
              if (element.mEmbarcador.type === 'ClientSupplier'){
                updateShiping.masterbl.houseBls[index].shipper = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].shipper.clientSupplierId = element.mEmbarcador.id
                updateShiping.masterbl.houseBls[index].shipper.supplier = element.mEmbarcador
              }
              if (element.mEmbarcador.type === 'Forwarder'){
                updateShiping.masterbl.houseBls[index].shipper = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].shipper.forwarderId = element.mEmbarcador.id
                updateShiping.masterbl.houseBls[index].shipper.supplier = element.mEmbarcador
              }
              if (element.mEmbarcador.type === 'Supplier'){
                updateShiping.masterbl.houseBls[index].shipper = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].shipper.supplierId = element.mEmbarcador.id
                updateShiping.masterbl.houseBls[index].shipper.supplier = element.mEmbarcador
              }
              if (element.mEmbarcador.type === 'ClientBuyer'){
                updateShiping.masterbl.houseBls[index].shipper = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].shipper.clientBuyerId = element.mEmbarcador.id
                updateShiping.masterbl.houseBls[index].shipper.supplier = element.mEmbarcador
              }
              if (element.mEmbarcador.type === 'Client'){
                updateShiping.masterbl.houseBls[index].shipper = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].shipper.clientId = element.mEmbarcador.id
                updateShiping.masterbl.houseBls[index].shipper.supplier = element.mEmbarcador
              }
            }
          })
        }*/

        this.$store.dispatch("working", true);
        this.$http
            .put(`Shipping/${this.shipment.id}`, updateShiping)
            .then((response) => {
              console.log(response);
              this.prices = response.data.data;
              this.masterFiles = [];
              this.houseFiles = [];
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              setTimeout(() => {
                /*let item = {
                  id: this.shipment.id
                }*/
              }, 500)
              this.isGuardar = true
              this.$store.dispatch("working", false);

            });

      }
      if(this.shipment.transportationId == 2){
        let updateShiping = {
          industry: this.shipment.industry,
          BusinessId: this.shipment.businessId,
          deliveryTypeId: this.shipment.deliveryType.id,
          orderTypeId: this.shipment.orderTypeId,
          id: this.shipment.id,
          IncotermId: this.shipment.incotermId,
          ClientId: this.shipment.client.id,
          TransportationId: this.shipment.transportationId,
          transportationcategoryId: this.shipment.transportationcategoryId,
          ClientSupplierName: "NA", // Donde esta esto?
          ReferenceNumber: "NA", // Donde esta esto?
          ShippingStatusId: this.shipment.shippingStatusId,
          ContactName: "this.shipme" + "ntToServer.contactName", //Donde esta esto?
          mails: 'jmv971110@gmail.com', //Donde esta esto?
          Comments: "sjadjas", //Donde esta esto?
          ChangeTypeId: 2, // que es esto?
          QuotationId: this.shipment.quotationId,
          services: this.services,
          masterbl: {
            id: this.shipment.masterBl.id ?? 0,
            shipper: {
            },
            consignee: {
            },
            shippingLine: {
              supplierId: this.shipment.masterBl.shippingLine.supplierId,
            },
            documents: this.shipment.masterBl.documents,
            supplierId: this.shipment.masterBl.supplierId, //MasterBL
            bookingNumber: this.shipment.masterBl.bookingNumber,
            refNumber: this.shipment.masterBl.refNumber,
            shipName: this.shipment.masterBl.shipName, //Nombre de barco
            tripNumber: this.shipment.masterBl.tripNumber, //Nombre de viaje
            storageDays: this.shipment.masterBl.storageDays,
            delaysInPort: this.shipment.masterBl.delaysInPort,
            additionalInfo: this.shipment.masterBl.additionalInfo,
            houseBls: this.shipment.masterBl.houseBls,
          },
        };
        updateShiping = this.setEm(updateShiping)
        updateShiping = this.setCon(updateShiping)
        console.log(updateShiping)
        console.log(updateShiping.masterbl)
        /*if (this.shipment.masterBl.houseBls === null || this.shipment.masterBl.houseBls.lenght === 0){
          console.log('nada')
        } else {
          updateShiping.masterbl.houseBls.forEach((element, index) => {
            // todo esto deberia estar en una funcion xd
            if (element.cConsig !== null){
              if (element.cConsig.type === 'ClientSupplier'){
                updateShiping.masterbl.houseBls[index].consignee = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].consignee.clientSupplierId = element.cConsig.id
                updateShiping.masterbl.houseBls[index].consignee.supplier = element.cConsig
                console.log(updateShiping.masterbl.houseBls[index].consignee)
              }
              if (element.cConsig.type === 'Client') {
                updateShiping.masterbl.houseBls[index].consignee = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].consignee.clientId = element.cConsig.id
                updateShiping.masterbl.houseBls[index].consignee.supplier = element.cConsig
              }
              if (element.cConsig.type === 'Forwarder') {
                updateShiping.masterbl.houseBls[index].consignee = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].consignee.forwarderId = element.cConsig.id
                updateShiping.masterbl.houseBls[index].consignee.supplier = element.cConsig
              }
            }
            if (element.mEmbarcador.type === 'ClientSupplier'){
              updateShiping.masterbl.houseBls[index].shipper = {supplier: [], clientSupplierId: null,}
              updateShiping.masterbl.houseBls[index].shipper.clientSupplierId = element.mEmbarcador.id
              updateShiping.masterbl.houseBls[index].shipper.supplier = element.mEmbarcador
            }
            if (element.mEmbarcador.type === 'Forwarder'){
              updateShiping.masterbl.houseBls[index].shipper = {supplier: [], clientSupplierId: null,}
              updateShiping.masterbl.houseBls[index].shipper.forwarderId = element.mEmbarcador.id
              updateShiping.masterbl.houseBls[index].shipper.supplier = element.mEmbarcador
            }
            if (element.mEmbarcador.type === 'Supplier'){
              updateShiping.masterbl.houseBls[index].shipper = {supplier: [], clientSupplierId: null,}
              updateShiping.masterbl.houseBls[index].shipper.supplierId = element.mEmbarcador.id
              updateShiping.masterbl.houseBls[index].shipper.supplier = element.mEmbarcador
            }
            if (element.mEmbarcador.type === 'ClientBuyer'){
              updateShiping.masterbl.houseBls[index].shipper = {supplier: [], clientSupplierId: null,}
              updateShiping.masterbl.houseBls[index].shipper.clientBuyerId = element.mEmbarcador.id
              updateShiping.masterbl.houseBls[index].shipper.supplier = element.mEmbarcador
            }
            if (element.mEmbarcador.type === 'Client'){
              updateShiping.masterbl.houseBls[index].shipper = {supplier: [], clientSupplierId: null,}
              updateShiping.masterbl.houseBls[index].shipper.clientId = element.mEmbarcador.id
              updateShiping.masterbl.houseBls[index].shipper.supplier = element.mEmbarcador
            }
          })
        }*/
        this.$store.dispatch("working", true);
        this.$http
            .put(`Shipping/${this.shipment.id}`, updateShiping)
            .then((response) => {
              console.log(response);
              this.prices = response.data.data;
              this.masterFiles = [];
              this.houseFiles = [];
              this.$toast.info("MasterBl Actualizado");
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              this.$store.dispatch("working", false);
            });
      }
      if(this.shipment.transportationId == 3){
        let updateShiping = {
          industry: this.shipment.industry,
          users: this.shipment.users,
          BusinessId: this.shipment.businessId,
          deliveryTypeId: this.shipment.deliveryType.id,
          orderTypeId: this.shipment.orderTypeId,
          id: this.shipment.id,
          IncotermId: this.shipment.incotermId,
          ClientId: this.shipment.client.id,
          TransportationId: this.shipment.transportationId,
          transportationcategoryId: this.shipment.transportationcategoryId,
          ClientSupplierName: "NA", // Donde esta esto?
          ReferenceNumber: this.shipment.refNumber, // Donde esta esto?
          ShippingStatusId: this.shipment.shippingStatusId,
          ContactName: "this.shipme" + "ntToServer.contactName", //Donde esta esto?
          mails: 'jmv971110@gmail.com', //Donde esta esto?
          Comments: "sjadjas", //Donde esta esto?
          ChangeTypeId: 2, // que es esto?
          QuotationId: this.shipment.quotationId,
          services: this.services,
          masterbl: {
            id: this.shipment.masterBl.id ?? 0,
            shipper: {
            },
            consignee: {
            },
            shippingLine: {
              // supplierId: this.shipment.masterBl.shippingLine.supplierId,
            },
            documents: this.shipment.masterBl.documents,
            supplierId: this.shipment.masterBl.supplierId, //MasterBL
            bookingNumber: this.shipment.masterBl.bookingNumber,
            refNumber: this.shipment.masterBl.refNumber,
            shipName: this.shipment.masterBl.shipName, //Nombre de barco
            tripNumber: this.shipment.masterBl.tripNumber, //Nombre de viaje
            storageDays: this.shipment.masterBl.storageDays,
            delaysInPort: this.shipment.masterBl.delaysInPort,
            additionalInfo: this.shipment.masterBl.additionalInfo,
            houseBls: this.shipment.masterBl.houseBls,
          },
        };
        updateShiping = this.setEm(updateShiping)
        updateShiping = this.setCon(updateShiping)
        console.log(updateShiping)
        // console.log(updateShiping.masterbl)
        if (this.shipment.masterBl.houseBls === null || this.shipment.masterBl.houseBls.length === 0){
          console.log('nada')
        } else {
          updateShiping.masterbl.houseBls.forEach((element, index) => {

            // TODO esto deberia estar en una funcion xd
            if (element.cConsig !== null){
              if (element.cConsig.type === 'Supplier') {
                updateShiping.masterbl.houseBls[index].consignee = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].consignee.supplierId = element.cConsig.id
                updateShiping.masterbl.houseBls[index].consignee.supplier = element.cConsig
              }
              if (element.cConsig.type === 'ClientSupplier'){
                updateShiping.masterbl.houseBls[index].consignee = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].consignee.clientSupplierId = element.cConsig.id
                updateShiping.masterbl.houseBls[index].consignee.supplier = element.cConsig
                console.log(updateShiping.masterbl.houseBls[index].consignee)
              }
              if (element.cConsig.type === 'Client') {
                updateShiping.masterbl.houseBls[index].consignee = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].consignee.clientId = element.cConsig.id
                updateShiping.masterbl.houseBls[index].consignee.supplier = element.cConsig
              }
              if (element.cConsig.type === 'Forwarder') {
                updateShiping.masterbl.houseBls[index].consignee = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].consignee.forwarderId = element.cConsig.id
                updateShiping.masterbl.houseBls[index].consignee.supplier = element.cConsig
              }
            }
            if (element.mEmbarcador !== null) {
              if (element.mEmbarcador.type === 'ClientSupplier'){
                updateShiping.masterbl.houseBls[index].shipper = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].shipper.clientSupplierId = element.mEmbarcador.id
                updateShiping.masterbl.houseBls[index].shipper.supplier = element.mEmbarcador
              }
              if (element.mEmbarcador.type === 'Forwarder'){
                updateShiping.masterbl.houseBls[index].shipper = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].shipper.forwarderId = element.mEmbarcador.id
                updateShiping.masterbl.houseBls[index].shipper.supplier = element.mEmbarcador
              }
              if (element.mEmbarcador.type === 'Supplier'){
                updateShiping.masterbl.houseBls[index].shipper = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].shipper.supplierId = element.mEmbarcador.id
                updateShiping.masterbl.houseBls[index].shipper.supplier = element.mEmbarcador
              }
              if (element.mEmbarcador.type === 'ClientBuyer'){
                updateShiping.masterbl.houseBls[index].shipper = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].shipper.clientBuyerId = element.mEmbarcador.id
                updateShiping.masterbl.houseBls[index].shipper.supplier = element.mEmbarcador
              }
              if (element.mEmbarcador.type === 'Client'){
                updateShiping.masterbl.houseBls[index].shipper = {supplier: [], clientSupplierId: null,}
                updateShiping.masterbl.houseBls[index].shipper.clientId = element.mEmbarcador.id
                updateShiping.masterbl.houseBls[index].shipper.supplier = element.mEmbarcador
              }
            }
          })
        }

        this.$store.dispatch("working", true);
        this.$http
            .put(`Shipping/${this.shipment.id}`, updateShiping)
            .then((response) => {
              console.log(response);
              this.prices = response.data.data;
              this.masterFiles = [];
              this.houseFiles = [];
              this.$toast.info("MasterBl Actualizado");
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              setTimeout(() => {
                /*let item = {
                  id: this.shipment.id
                }*/
              }, 500)
              this.isGuardar = true
              this.$store.dispatch("working", false);

            });

      }
    },
    getCount() {
      this.$http.get("Shipping/count").then((response) => {
        console.log(response);
        this.counts = response.data.data;
      });
    },

    searchCase(){
      if (this.shipment.emails !== null && this.shipment.emails.length > 0){
        let aux = this.shipment.emails.split(';')
        this.shipment.emails = aux
      }
      console.log('entre en searchCase')
      if (this.shipment.masterBl.shipper){
        if (this.shipment.masterBl.shipper.clientBuyerId){
          this.provedoress = [];
          this.provedoress.push(this.shipment.masterBl.shipper.supplier)
          this.provedoress = this.provedoress.map((element) => {
            return{
              ...element,
              name: element.companyName
            }
          })
          this.shipin = this.shipment.masterBl.shipper.supplier.id
          this.typeEM = 'clientBuyer'
        }
        if (this.shipment.masterBl.shipper.clientSupplierId){
          this.provedoress = [];
          this.provedoress.push(this.shipment.masterBl.shipper.supplier)
          this.provedoress = this.provedoress.map((element) => {
            return{
              ...element,
              name: element.companyName
            }
          })
          this.shipin = this.shipment.masterBl.shipper.supplier.id
          this.typeEM = 'clientSupplier'
        }
        if (this.shipment.masterBl.shipper.forwarderId){
          this.provedoress = [];
          this.provedoress.push(this.shipment.masterBl.shipper.supplier)
          this.provedoress = this.provedoress.map((element) => {
            return{
              ...element,
              name: element.companyName
            }
          })
          this.shipin = this.shipment.masterBl.shipper.supplier.id
          this.typeEM = 'forwarder'
        }
        if (this.shipment.masterBl.shipper.supplierId){
          this.provedoress = [];
          this.provedoress.push(this.shipment.masterBl.shipper.supplier)
          this.provedoress = this.provedoress.map((element) => {
            return{
              ...element,
              name: element.companyName
            }
          })
          this.shipin = this.shipment.masterBl.shipper.supplier.id
          this.typeEM = 'supplier'
        }
        if (this.shipment.masterBl.shipper.clientId){
          this.provedoress = [];
          this.provedoress.push(this.shipment.masterBl.shipper.supplier)
          this.provedoress = this.provedoress.map((element) => {
            return{
              ...element,
              name: element.companyName
            }
          })
          this.shipin = this.shipment.masterBl.shipper.supplier.id
          this.typeEM = 'Client'
        }
      }
      if (this.shipment.masterBl.consignee) {
        if (this.shipment.masterBl.consignee.supplier.type === null){
          this.itemsConsig = []
          this.consig = null
          this.typeCon = ''
        } else {
          if(this.shipment.masterBl.consignee.clientBuyerId){
            this.itemsConsig = []
            this.itemsConsig.push(this.shipment.masterBl.consignee.supplier)
            this.itemsConsig = this.itemsConsig.map((element) => {
              return{
                ...element,
                name: element.companyName
              }
            })
            this.consig = this.shipment.masterBl.consignee.supplier.id
            this.typeCon = 'clientBuyer'
          }
          if(this.shipment.masterBl.consignee.clientSupplierId){
            this.itemsConsig = []
            this.itemsConsig.push(this.shipment.masterBl.consignee.supplier)
            this.itemsConsig = this.itemsConsig.map((element) => {
              return{
                ...element,
                name: element.companyName
              }
            })
            this.consig = this.shipment.masterBl.consignee.supplier.id
            this.typeCon = 'clientSupplier'
          }
          if(this.shipment.masterBl.consignee.forwarderId){
            this.itemsConsig = []
            this.itemsConsig.push(this.shipment.masterBl.consignee.supplier)
            this.itemsConsig = this.itemsConsig.map((element) => {
              return{
                ...element,
                name: element.companyName
              }
            })
            this.consig = this.shipment.masterBl.consignee.supplier.id
            this.typeCon = 'forwarder'
          }
          if(this.shipment.masterBl.consignee.supplierId){
            this.itemsConsig = []
            this.itemsConsig.push(this.shipment.masterBl.consignee.supplier)
            this.itemsConsig = this.itemsConsig.map((element) => {
              return{
                ...element,
                name: element.companyName
              }
            })
            this.consig = this.shipment.masterBl.consignee.supplier.id
            this.typeCon = 'supplier'
          }
          if(this.shipment.masterBl.consignee.clientId){
            this.itemsConsig = []
            this.itemsConsig.push(this.shipment.masterBl.consignee.supplier)
            this.itemsConsig = this.itemsConsig.map((element) => {
              return{
                ...element,
                name: element.companyName
              }
            })
            this.consig = this.shipment.masterBl.consignee.supplier.id
            this.typeCon = 'Client'
          }
        }
      }
      if (Array.isArray(this.shipment.masterBl.houseBls)) {
        this.shipment.masterBl.houseBls.forEach((element, index) => {
          console.log('entre al forEarch')
          if (element.shipper.clientBuyerId) {
            this.shipment.masterBl.houseBls[index].mEmbarcador = element.shipper.clientBuyerId
            this.shipment.masterBl.houseBls[index].mItems.push(element.shipper.supplier)
            this.indexHouse = index
          }
          if (element.shipper.clientSupplierId) {
            this.shipment.masterBl.houseBls[index].mEmbarcador = element.shipper.clientSupplierId
            this.shipment.masterBl.houseBls[index].mItems.push(element.shipper.supplier)
            this.indexHouse = index
          }
          if (element.shipper.forwarderId) {
            this.shipment.masterBl.houseBls[index].mEmbarcador = element.shipper.forwarderId
            this.shipment.masterBl.houseBls[index].mItems.push(element.shipper.supplier)
            this.indexHouse = index
          }
          if (element.shipper.supplierId) {
            console.log('entre a supplierId')
            this.shipment.masterBl.houseBls[index].mItems.push(element.shipper.supplier)
            this.shipment.masterBl.houseBls[index].mEmbarcador = element.shipper.supplierId
            console.log(element.shipper.supplier)
            console.log(element.shipper.supplierId)
            this.indexHouse = index
            this.justChanged = true
          }
          if (element.shipper.supplierId) {
            console.log('entre a supplierId')
            this.shipment.masterBl.houseBls[index].mItems.push(element.shipper.supplier)
            this.shipment.masterBl.houseBls[index].mEmbarcador = element.shipper.supplierId
            console.log(element.shipper.supplier)
            console.log(element.shipper.supplierId)
            this.indexHouse = index
            this.justChanged = true
          }
          if (element.shipper.clientId) {
            this.shipment.masterBl.houseBls[index].mItems.push(element.shipper.supplier)
            this.shipment.masterBl.houseBls[index].mEmbarcador = element.shipper.clientId
            this.indexHouse = index
            this.justChanged = true
          }
          if (element.consignee.clientId) {
            this.shipment.masterBl.houseBls[index].cItems.push(element.consignee.supplier)
            this.shipment.masterBl.houseBls[index].cConsig = element.consignee.clientId
            this.indexHouse = index
            this.justChanged = true
          }
          if (element.consignee.forwarderId) {
            this.shipment.masterBl.houseBls[index].cItems.push(element.consignee.supplier)
            this.shipment.masterBl.houseBls[index].cConsig = element.consignee.forwarderId
            this.indexHouse = index
            this.justChanged = true
          }
          if (element.consignee.supplierId) {
            this.shipment.masterBl.houseBls[index].cItems.push(element.consignee.supplier)
            this.shipment.masterBl.houseBls[index].cConsig = element.consignee.supplierId
            this.indexHouse = index
            this.justChanged = true
          }
          if (element.consignee.clientSupplierId) {
            this.shipment.masterBl.houseBls[index].cItems.push(element.consignee.supplier)
            this.shipment.masterBl.houseBls[index].cConsig = element.consignee.clientSupplierId
            this.indexHouse = index
            this.justChanged = true
          }
        });
      }else {
        console.error('houseBls is not an array:', this.shipment.masterBl.houseBls);
      }
    },
    setEm(shipment){
      console.log('setEm')
      if (this.shipin === null){
        return shipment
      }
      if (typeof this.shipin === 'number'){
        if (this.typeEM === 'supplier'){
          shipment.masterbl.shipper = {
            supplierId: this.shipin
          }
          return shipment
        }
        if (this.typeEM === 'forwarder') {
          shipment.masterbl.shipper = {
            forwarderId:  this.shipin
          }
          return shipment
        }
        if (this.typeEM === 'clientSupplier') {
          shipment.masterbl.shipper = {
            clientSupplierId:  this.shipin
          }
          return shipment
        }
        if (this.typeEM === 'clientSupplier') {
          shipment.masterbl.shipper = {
            clientSupplierId:  this.shipin
          }
          return shipment
        }
        if (this.typeEM === 'clientBuyer') {
          shipment.masterbl.shipper = {
            clientBuyerId:  this.shipin
          }
          return shipment
        }
        if (this.typeEM === 'Client') {
          shipment.masterbl.shipper = {
            clientId:  this.shipin
          }
          return shipment
        }
        if (this.typeEM === ''){
          shipment.masterbl.shipper = {}
          return shipment
        }
        return shipment
      }
      if (typeof this.shipin === 'object'){
        console.log('object')
        if (this.shipin.type === 'ClientSupplier'){
          shipment.masterbl.shipper = {
            clientSupplierId: this.shipin.id
          }
          return shipment
        }
        if (this.shipin.type === 'Forwarder'){
          shipment.masterbl.shipper = {
            forwarderId: this.shipin.id
          }
          return shipment
        }
        if (this.shipin.type === 'Supplier'){
          shipment.masterbl.shipper = {
            supplierId: this.shipin.id
          }
          return shipment
        }
        if (this.shipin.type === 'ClientBuyer'){
          shipment.masterbl.shipper = {
            clientBuyerId: this.shipin.id
          }
          return shipment
        }
        if (this.shipin.type === 'Client') {
          console.log('Client')
          console.log(this.shipin.id)
          shipment.masterbl.shipper = {
            clientId:  this.shipin.id
          }
          console.log(shipment)
          return shipment
        }
        if (this.shipin.type === ''){
          shipment.masterbl.shipper = {}
          return shipment
        }
      }
      return shipment
    },
    setCon(shipment){
      console.log(typeof this.consig, shipment)
      if (this.consig === null){
        return shipment
      }
      if (typeof this.consig === 'number'){
        if (this.typeCon === 'supplier'){
          shipment.masterbl.consignee = {
            supplierId: this.consig
          }
          return shipment
        }
        if (this.typeCon === 'forwarder') {
          shipment.masterbl.consignee = {
            forwarderId:  this.consig
          }
          return shipment
        }
        if (this.typeCon === 'clientSupplier') {
          shipment.masterbl.consignee = {
            clientSupplierId:  this.consig
          }
          return shipment
        }
        if (this.typeCon === 'Client') {
          shipment.masterbl.consignee = {
            clientId:  this.consig
          }
          return shipment
        }
        /*if (this.consig.type === 'Client'){
          shipment.masterbl.consignee = {
            clientId: this.consig.id
          }
          return shipment
        }*/
        if (this.typeCon === 'clientBuyer') {
          shipment.masterbl.consignee = {
            clientBuyerId:  this.consig
          }
          return shipment
        }
        if (this.consig === ''){
          shipment.masterbl.consignee = {}
          return shipment
        }
        return shipment
      }
      if (typeof this.consig === 'object'){
        if (this.consig === null) return shipment
        if (this.consig.type === 'ClientSupplier'){
          shipment.masterbl.consignee = {
            clientSupplierId: this.consig.id
          }
          return shipment
        }
        if (this.consig.type === 'Forwarder'){
          shipment.masterbl.consignee = {
            forwarderId: this.consig.id
          }
          return shipment
        }
        if (this.consig.type === 'Supplier'){
          shipment.masterbl.consignee = {
            supplierId: this.consig.id
          }
          return shipment
        }
        if (this.consig.type === 'ClientBuyers'){
          shipment.masterbl.consignee = {
            clientBuyerId: this.consig.id
          }
          return shipment
        }
        if (this.consig.type === 'Client'){
          shipment.masterbl.consignee = {
            clientId: this.consig.id
          }
          return shipment
        }
        if (this.consig.type === ''){
          shipment.masterbl.consignee = {}
          return shipment
        }
      }
      return shipment
    },
  },
  mounted() {
    this.sortRoutes()
    this.searchCase()
    this.getOperators();
  },
}
</script>

<style scoped>

</style>
