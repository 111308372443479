import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";
import status from './status';
import timeLine from './timeLine'

Vue.use(Vuex)

const getDefaultState = () => {
  return {
    token: "eyJhbGciOiJSUzI1NiIsImtpZCI6IldYWFFNRUEwMy0zSkdLODdZRFNIVUdZQjlFWkxRWEs5VTRaRE5NRkYiLCJ0eXAiOiJhdCtqd3QifQ.eyJzdWIiOiIzIiwiSXNBcHBsaWNhdGlvbiI6ImZhbHNlIiwib2lfcHJzdCI6IndlYmFwcCIsImNsaWVudF9pZCI6IndlYmFwcCIsIm9pX3Rrbl9pZCI6ImEyNjQwOTgzLTQxYTktNDk0ZC04ODAwLWQyMmQ4MWQ1YmQ0MSIsImV4cCI6MTY0Njc3NzU4NCwiaXNzIjoiaHR0cHM6Ly9sb2NhbGhvc3Q6NzAyNS8iLCJpYXQiOjE2NDY3NzM5ODR9.RSf2YxskQ7BFpbBinsIt14R-kFUOrhBxoTYDbQmAQAhF59rPLj1M1syKkRlj_HpyHFpbzINaHTyooi1xrBH4ZRedkc5RVzgNcUoabd-dwyt5XaXZ2k8_9aRlBgyPWiFIQsxVyPQ4et9A5rhDDJv1Y0AqCygAUk0bBn6dViAhKIkZ2LPgq0L-ZeO-Tlmc07CEmp2n44S-DJntYZMsooXjQcZ8QMHrwmxpBHcp0v1-HwLfYmEaFfqBscILggiAqUjtYgYj32j0M3UO0JDgabHhAafjDeMEXgdoee8rjBUQa876Pk1NgzkDc1x267rEhUPkXoTX4tz7nIesq-PMHTMQiA",
    name: null,
    roles: [],
    lastname: null,
    working: false,
    idOrder:null,
    UserId: null,
    filterRoute:null,
    updateNotifications: false,
    filterPricingColumns: [
      { text: 'Folio', value: 'RefCode'},
      { text: 'Cliente', value: 'Client.name' },
      { text: 'Responsable', value: 'salesManager'},
      { text: 'Material Peligroso', value: 'dangerous' },
      { text: 'Urgente', value: 'urgent' },
      { text: 'Status', value: 'status' },
      { text: 'Proceder', value: 'action', persistent: true},
      { text: '', value: 'data-table-expand', persistent: true},
    ],
    showPricingMainTour: true,
  };
};



export default new Vuex.Store({
  modules: {
    status, timeLine
  },
  state:getDefaultState,
  getters:{
    name: state => {
      return state.name;
    },

    lastname: state => {
      return state.lastname;
    },
    UserId: state => {
      return state.UserId;
    },
    token: state => {
      return state.token;
    },

    working: state => {
      return state.working;
    },

    roles: state => {
      return state.roles;
    },

    idOrder: state => {
      return state.idOrder;
    },
    updateNotifications: state => {
      return state.updateNotifications;
    },
    filterRoute: state => {
      return state.filterRoute;
    },
    filterPricingColumns: state => {
      return state.filterPricingColumns;
    },
    showPricingMainTour: state => {
      return state.showPricingMainTour;
    }
  },
  mutations: {

    reset: state => {
      const s = getDefaultState();
      Object.keys(s).forEach(key => {
        state[key] = s[key];
      });
    },

    token: (state, payload) => {
      state.token = payload
    },

    working: (state, payload) => {
      state.working = payload;
    },

    name: (state, payload) => {
      state.name = payload;
    },
    UserId: (state, payload) => {
      state.UserId = payload;
    },

    lastname: (state,payload) => {
      state.lastname = payload;
    },

    roles: (state, payload) => {
      state.roles = payload
    },

    idOrder: state => {
      return state.idOrder;
    },

    updateNotifications: (state,payload) => {
      state.updateNotifications = payload
    },
    filterRoute: (state,payload) => {
      state.filterRoute = payload
    },
    filterPricingColumns: (state, payload) => {
      state.filterPricingColumns = payload
    },
    showPricingMainTour: (state, payload) => {
      state.showPricingMainTour = payload
    }

  },
  actions: {
    working: ({ commit }, payload) => {
      commit("working", payload);
    },

    token: ({ commit }, payload) => {
      commit("token", payload);
    },
    name: ({ commit }, payload) => {
      commit("name", payload);
    },
    UserId:  ({ commit }, payload) => {
      commit("UserId", payload);
    },

    lastname: ({ commit }, payload) => {
      commit("lastname", payload);
    },

    roles: ({ commit }, payload) => {
      commit("roles", payload);
    },

    idOrder: ({ commit }, payload) => {
      commit("idOrder", payload);
    },

    updateNotifications: ({commit}, payload) => {
      commit("updateNotifications",payload);
    },
    filterRoute: ({commit}, payload) => {
      commit("filterRoute",payload);
    },
    updateFilterPricingColumns: ({ commit }, payload) => {
      commit("filterPricingColumns", payload);
    },
    updateShowPricingMainTour: ({ commit }, payload) => {
      commit("showPricingMainTour", payload);
    }
  },
  plugins: [createPersistedState()]
});

