<template>
  <div class="mt-12">
    <br><br><br><br>
    <LoginFields></LoginFields>
  </div>

</template>

<script>
import LoginFields from "@/components/Auth/LoginFields";
export default {
name: "Index-Login",
  components: {LoginFields}
}
</script>

<style scoped>

</style>