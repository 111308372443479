<template>
  <div>
    <v-stepper v-model="e6" vertical>

      <v-stepper-step :complete="e6 > 1" step="1" @click="e6 = 1">
        Tipo de Proveedor
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-row justify="center">
          <v-col md="2">
            <v-btn @click="e6 = 2,suppliers.supplierTypeId=2" :outlined="suppliers.supplierTypeId!==2" x-large fab color="primary">
              <v-icon>las la-plane</v-icon>
            </v-btn>
            <br>
            <span class="font-weight-regular black--text offset-1">Aereo</span>
          </v-col>

          <v-col md="2">
            <v-btn @click="e6 = 2,suppliers.supplierTypeId=3" :outlined="suppliers.supplierTypeId!==3" x-large fab color="primary">
              <v-icon>las la-truck</v-icon>
            </v-btn>
            <br>
            <span class="font-weight-regular black--text ml-1">Terrestre</span>
          </v-col>

          <v-col md="2">
            <v-btn @click="e6 = 2,suppliers.supplierTypeId=4" :outlined="suppliers.supplierTypeId!==4" x-large fab color="primary">
              <v-icon>las la-ship</v-icon>
            </v-btn>
            <br>
            <span class="font-weight-regular black--text ml-1">Maritimo</span>
          </v-col>

          <v-col md="2">
            <v-btn @click="e6 = 2,suppliers.supplierTypeId=5" :outlined="suppliers.supplierTypeId!==5" x-large fab color="primary">
              <v-icon>las la-industry</v-icon>
            </v-btn>
            <br>
            <span class="font-weight-regular black--text ml-n5">Comercializadora</span>
          </v-col>

          <v-col md="2">
            <v-btn @click="e6 = 2,suppliers.supplierTypeId=6" :outlined="suppliers.supplierTypeId!==6" x-large fab color="primary">
              <v-icon>las la-search</v-icon>
            </v-btn>
            <br>
            <span class="font-weight-regular black--text">Inspection</span>
          </v-col>
        </v-row>

        <br>

        <v-row justify="center">
          <v-col md="2">
            <v-btn @click="e6 = 2,suppliers.supplierTypeId=7" :outlined="suppliers.supplierTypeId!==7" x-large fab color="primary">
              <v-icon>las la-shield-alt</v-icon>
            </v-btn>
            <br>
            <span class="font-weight-regular black--text ml-n2">Aseguradora</span>
          </v-col>

          <v-col md="2">
            <v-btn @click="e6 = 2,suppliers.supplierTypeId=8" :outlined="suppliers.supplierTypeId!==8" x-large fab color="primary">
              <v-icon>las la-boxes</v-icon>
            </v-btn>
            <br>
            <span class="font-weight-regular black--text ml-n1">Almacenaje</span>
          </v-col>

          <v-col md="2">
            <v-btn @click="e6 = 2,suppliers.supplierTypeId=9" :outlined="suppliers.supplierTypeId!==9" x-large fab color="primary">
              <v-icon>las la-truck-loading</v-icon>
            </v-btn>
            <br>
            <span class="font-weight-regular black--text ml-1">Embalaje</span>
          </v-col>

          <v-col md="2">
            <v-btn @click="e6 = 2,suppliers.supplierTypeId=10" :outlined="suppliers.supplierTypeId!==10" x-large fab color="primary">
              <v-icon>las la-coins</v-icon>
            </v-btn>
            <br>
            <span class="font-weight-regular black--text ml-n2">Financieras</span>
          </v-col>
          <v-col md="2">
            <v-btn @click="e6 = 2,suppliers.supplierTypeId=11" :outlined="suppliers.supplierTypeId!==11" x-large fab color="primary">
              <v-icon>las la-handshake</v-icon>
            </v-btn>
            <br>
            <span class="font-weight-regular black--text">Agente en origen</span>
          </v-col>
        </v-row>
      </v-stepper-content>



      <v-stepper-step :complete="e6 > 2" step="2" @click="e6 = 2">
        Datos de compañia
      </v-stepper-step>

      <v-stepper-content step="2">

        <v-form>
          <v-container>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field v-model="suppliers.companyName" :rules="nameRules" label="Nombre(s)" required>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="suppliers.email" label="Correo">
                </v-text-field>
              </v-col>


              <v-col cols="12" md="3">
                <v-text-field v-model="suppliers.phone" label="Celular"></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field v-model="suppliers.taxNumber" label="RFC" :rules="RfcRules" required></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field label="Dias de credito" v-model="suppliers.configuration.creditDays">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-autocomplete label="Origen de provedor" item-text="name" :items="Country" item-value="id" v-model="suppliers.countryId" required>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <v-autocomplete label="Origen de provedor"  :items="typeSuppliers" v-model="suppliers.AccountingType" required>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-btn color="primary" @click="e6 = 3">
          Continuar
        </v-btn>
        <v-btn @click="e6 = 1">
          Atras
        </v-btn>

      </v-stepper-content>



      <v-stepper-step :complete="e6 > 3" step="3" @click="e6 = 3">
        Contactos
      </v-stepper-step>

      <v-stepper-content step="3">

        <v-form>
          <v-container>
            <v-row>
              <div v-for="(contact,index) in suppliers.contact" :key="contact">
                <v-row>
                  <v-col cols="12" md="3">
                    <v-text-field v-model="contact.name" label="Nombre de Contacto"
                                  ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-text-field v-model="contact.lastname"
                                  label="Apellido de Contacto" ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-text-field v-model="contact.email"
                                  label="email de Contacto" ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-text-field v-model="contact.extension" label="Extension"
                                  ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-text-field v-model="contact.phone" label="Telefono de Contacto"
                                  ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-autocomplete v-model="contact.supplierContactRoleId" :items="SupplierContactRole"
                                    item-text="descripcion" item-value="id" label="Rol de Contacto">
                    </v-autocomplete>
                  </v-col>

                  <v-col md="3">
                    <v-btn v-if="index!=0" icon @click="removeContact(index)">
                      <v-icon color="red" x-large>mdi-minus-circle</v-icon>
                    </v-btn>

                    <v-btn v-if="index == suppliers.contact.length - 1" icon @click="addContact()" class="ml-3">
                      <v-icon color="primary" x-large>mdi-plus-circle</v-icon>
                    </v-btn>
                  </v-col>




                </v-row>
                <br><br>
              </div>
            </v-row>

          </v-container>
        </v-form>


        <v-btn color="primary" @click="e6 = 4">
          Continuar
        </v-btn>
        <v-btn @click="e6 = 2">
          Atras
        </v-btn>



      </v-stepper-content>



      <v-stepper-step :complete="e6 > 4" step="4" @click="e6 = 4">
        Informacion Empresarial
      </v-stepper-step>

      <v-stepper-content step="4">
        <v-form v-if="suppliers.accounts.length > 0">
          <v-container>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field v-model="suppliers.accounts[0].accountNumber" label="Tarjeta de deposito"
                              ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="suppliers.accounts[0].clabe" label="Clabe"
                              ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field v-model="suppliers.accounts[0].bank" label="Banco">
                </v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-file-input label="Logo de Proveedor" v-model="suppliers.configuration.logo"
                              @change="ConvertFile(suppliers.configuration.logo)"></v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-form>

        <v-btn v-if="SupplierData" color="primary" @click="UpdateUser()">
          Actualizar
        </v-btn>
        <v-btn v-else color="primary" @click="NewSupplier()">
          Guardar
        </v-btn>
        <v-btn @click="e6 = 3">
          Atras
        </v-btn>
      </v-stepper-content>


    </v-stepper>
  </div>
</template>

<script>
export default {
  name: "Suppliers",
  props: {
    SupplierData: {}
  },
  watch: {
    SupplierData: function () {
      this.setSupplier()
    }
  },
  data() {
    return {
      disabledInitialIndex:false,
      fullName: {},
      Country:{},
      typeSuppliers: ['Nacional', 'Extranjero', 'Acreador (Nacional)', 'Acreador (Extranjero)'],
      suppliers: {
        supplierTypeId: null,
        companyName: null,
        phone: null,
        taxNumber: null,
        clients: [{
          ClientName: "juan perez",
          ContactInfo: "123456",
          letterReference: 'YXNvZMOxa2Fsw7Fza2TDsUFEUw==',
        }],
        accounts: [{
          accountNumber: null,
          bank: null,
          clabe:null,
        }],
        contact: [{
          name: null,
          lastname: null,
          email: null,
          phone: null,
          extension: null,
          supplierContactRoleId: null,
        }],
        configuration: {
          creditDays: 1,
          logo: null
        },
      },
      e6: 1,
      SupplierContactRole: [],
      CuentaRules: [
        v => !!v || 'Tarjeta de deposito es requerido',
      ],
      BankRules: [
        v => !!v || 'Banco es requerido',
      ],
      HomePhoneRules: [
        v => !!v || 'Telefono es requerido',
      ],
      nameRules: [
        v => !!v || 'Nombre es requerido',
      ],
      emailRules: [
        v => !!v || 'E-mail es requerido',
        v => /.+@.+\..+/.test(v) || 'Correo Invalido',
      ],
      lastNameRules: [
        v => !!v || 'Apellido es requerido',
      ],
      cellphoneRules: [
        v => !!v || 'Celular es requerido',
      ],
      RfcRules: [
        v => !!v || 'RFC es requerido',
      ],
      ExtencionRules: [
        v => !!v || 'Tel. de extension es requerido',
      ],
      CreditoRules: [
        v => !!v || 'Dias de credito es requerido',
      ],
      RolContactRules: [
        v => !!v || 'Rol de contacto es requerido',
      ],
    }
  },
  methods: {
    addContact(){
      this.suppliers.contact.push(
          {
            name: null,
            lastname: null,
            email: null,
            phone: null,
            extension: null,
            supplierContactRoleId: null,
          }
      )
    },
    GetCountry: function () {
      this.$store.dispatch('working', true);
      this.$http.get('Countries')
          .then(response => {
            if (response.data.code == 200) {
              this.Country = response.data.data;

            }

            if (response.data.code == 400 || response.data.code == 500)
              this.$toast.error("Algo salio mal, intente mas tarde")

          })
          .catch(error => {

            console.log(error)
          }).finally(() => this.$store.dispatch('working', false))
    },
    removeContact(index){
      if (index!=0)
        this.suppliers.contact.splice(index,1)
    },
    NewSupplier: function () {
      this.suppliers.language = 1;
      this.suppliers.description = 'null';
      this.$store.dispatch('working', true);
      this.$http.post('Suppliers', this.suppliers)
          .then(response => {
            if (response.data.code == 200) {
              console.log(response.data)
              this.$toast.success("Proveedor Agregado");
              this.clearData();
              this.$emit("created");
            }else {
              this.$toast.error("Algo salio mal, intente mas tarde")
            }
          })
          .catch(error => {
            if (error.response.status == 401) {
              this.$store.commit('reset');
              this.$toast.error("La sesion expiro");
              this.$router.replace('/login')
            }
            console.log(error)
            this.$toast.error("Error al añadir Proveedor, intente mas tarde");

          })
          .finally(() => this.$store.dispatch('working', false))
    },


    UpdateUser: function () {
      this.suppliers.type = null;
      this.suppliers.country = null;
      this.$store.dispatch('working', true);
      console.log(this.suppliers)
      this.$http.put(`Suppliers/${this.SupplierData.id}`, this.suppliers)
          .then(response => {
            if (response.data.code == 200) {
              console.log(response.data)
              this.$toast.success("Se a actualizado el provedor");
              this.$emit("closeDialog");
              this.$emit("created");
            }else {
              this.$toast.error("Algo salio mal, intente mas tarde")
            }
          })
          .catch(error => {
            if (error.response.status == 401) {
              this.$store.commit('reset');
              this.$toast.error("La sesion expiro");
              this.$router.replace('/login')
            }
            console.log(error)
            this.$toast.error("Error al añadir Proveedor, intente mas tarde");

          })
          .finally(() => this.$store.dispatch('working', false))
    },
    clearData(){
      this.suppliers.supplierTypeId = null;
      this.suppliers.companyName = null;
      this.suppliers.email = null;
      this.suppliers.phone = null;
      this.suppliers.taxNumber = null;
      this.suppliers.configuration.creditDays = null;
      this.suppliers.countryId = null;
      this.suppliers.contact[0].name = null;
      this.suppliers.contact[0].lastname = null;
      this.suppliers.contact[0].email = null;
      this.suppliers.contact[0].extension = null;
      this.suppliers.contact[0].phone = null;
      this.suppliers.contact[0].supplierContactRoleId = null;
      this.suppliers.accounts[0].accountNumber = null;
      this.suppliers.accounts[0].clabe = null;
      this.suppliers.accounts[0].bank = null;
      this.suppliers.configuration.logo = null;










    },

    GetContactRoles: function () {
      this.$store.dispatch('working', true)
      this.$http.get('SupplierContactRoles/Role')
          .then(response => {
            if (response.data.code == 200) {
              this.SupplierContactRole = response.data.data;
            }

            if (response.data.code == 400 || response.data.code == 500)
              this.$toast.error("Algo salio mal, intente mas tarde")
          })
          .catch(error => {
            if (error.response.status == 401) {
              this.$store.commit('reset');
              this.$toast.error("La sesion expiro");
              this.$router.replace('/login')
            }

            console.log(error)

          }).finally(() => this.$store.dispatch('working', false))
    },
    UploadFile: function (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    ConvertFile: function (file) {

      this.UploadFile(file).then(response => {
        console.log(response);
        var data = response.split(',')[1];
        this.suppliers.configuration.logo = data;
      })
          .catch(error => {
            console.log(error)
          })

    },
    setSupplier() {
      if (this.SupplierData) {
        this.suppliers.supplierTypeId = 1
        this.suppliers = this.SupplierData
        if (this.suppliers.accounts.length <= 0) {
          let obj = {
            accountNumber: null,
            bank: null,
            clabe:null,
          }
          this.suppliers.accounts.push(obj);
        }
      }
    }
  },
  mounted() {
    this.GetContactRoles();
    this.GetCountry();
  }
}
</script>

<style scoped>

</style>
