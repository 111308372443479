<template>
  <div>
    <v-img
        src="@/assets/cotizacion.png"
        lazy-src="https://picsum.photos/id/11/100/60"
        max-height="250"
    >
    </v-img>

    <h1 class="font-weight-regular text-center">Quick quote!</h1>
    <br>
    <RequestQuote v-if="QuotData.TypeService !=11"
        v-on:data="dialog=$event">
    </RequestQuote>


    <QuotData
        @QuotData="RecibeData"
        v-bind="QuotData"
        ref="quotDataSlot"
        v-on:Transporte="showComponent=$event"
        @searchQuote="searchQuotation"
        @dateMenuChange="changeAutocompleteStatus($event)"
        class="mb-3"
    >
      <template slot="autocomplete">
        <div class="row no-gutters position-relative" v-if="showComponent==1">
          <div class="col-6 fixed-col-size">
            <googleMapsAutocompleteVuetifyNew
                label="Origen"
                :search="originAddress"
                :items="originItems"
                :selections="origin"
                transportation="SEA"
                placeholder="Ingrese un origen"
                @change="originSelected"
                @updateAddress="updateOriginAddress"
                @updateItems="updateOriginItems"
                :id="3"
            >
            </googleMapsAutocompleteVuetifyNew>
          </div>
          <div class="col-6 fixed-col-size">
            <googleMapsAutocompleteVuetifyNew
                label="Destino"
                :search="destinationAddress"
                :items="destinationItems"
                :selections="destination"
                transportation="SEA"
                placeholder="Ingrese un destino"
                @change="destinationSelected"
                @updateAddress="updateDestinationAddress"
                @updateItems="updateDestinationItems"
                @menuChanged="isDestinationOpen = $event"
                :id="4"
            >
            </googleMapsAutocompleteVuetifyNew>
          </div>
        </div>

        <div class="row no-gutters position-relative" v-if="showComponent==2">
          <div class="col-6 fixed-col-size">
            <googleMapsAutocompleteVuetifyAereo
                label="Origen"
                :search="originAddress"
                :items="originItems"
                :selections="origin"
                @change="originSelectedAereo"
                @updateAddress="updateOriginAddress"
                @updateItems="updateOriginItems"
                @menuChanged="isOriginOpen = $event"
                :id="3"
                transportation="AIR"
                placeholder="Ingrese un origen"
            >
            </googleMapsAutocompleteVuetifyAereo>
          </div>
          <div class="col-6 fixed-col-size">
            <googleMapsAutocompleteVuetifyAereo
                label="Destino"
                :search="destinationAddress"
                :items="destinationItems"
                :selections="destination"
                @change="destinationSelectedAereo"
                @updateAddress="updateDestinationAddress"
                @updateItems="updateDestinationItems"
                @menuChanged="isDestinationOpen = $event"
                :id="4"
                transportation="AIR"
                placeholder="Ingrese un Destino"
            >
            </googleMapsAutocompleteVuetifyAereo>
          </div>
        </div>

        <div class="row no-gutters position-relative" v-if="showComponent==3">
          <div class="col-6 fixed-col-size">
            <googleMapsAutocompleteVuetifyTerrestre
                label="Origen"
                :search="originAddress"
                :items="originItems"
                :selections="origin"
                @change="originSelectedTerrestre"
                @updateAddress="updateOriginAddress"
                @updateItems="updateOriginItems"
                placeholder="Ingrese un origen"
            >
            </googleMapsAutocompleteVuetifyTerrestre>
          </div>
          <div class="col-6 fixed-col-size">
            <googleMapsAutocompleteVuetifyTerrestre
                label="Destino"
                :search="destinationAddress"
                :items="destinationItems"
                :selections="destination"
                @change="destinationSelectedTerrestre"
                @updateAddress="updateDestinationAddress"
                @updateItems="updateDestinationItems"
                placeholder="Ingrese un Destino"
            >
            </googleMapsAutocompleteVuetifyTerrestre>
          </div>
        </div>


      </template >
      <template slot="cargo" v-if="QuotData.TypeService==11">
        <v-col md="12" class="ma-auto" v-for="(loadData,index) in load" :key="loadData.id">
          <v-row justify="center">
            <v-col md="3">
              <v-text-field v-model="loadData.containerQuantity" class="ml-2" type="number" dense outlined label="Cantidad" ></v-text-field>
            </v-col>
            <v-col md="3">
              <v-select
                  outlined
                  dense
                  v-model="loadData.container"
                  :items="containers"
                  item-text="description"
                  label="Tipo de contenedor"
              ></v-select>
            </v-col>
            <v-col md="3">
              <v-text-field v-model="loadData.containerWeigth" type="number" dense outlined label="Peso" ></v-text-field>
            </v-col>
            <v-col md="2">
              <v-btn icon v-if="load.length == index+1" @click="addLoad"><v-icon size="30" color="primary">mdi-plus-circle</v-icon></v-btn>
              <v-btn icon v-if="load.length>1 && load.length != index+1" @click="DeleteLoad(load.id)"><v-icon size="30" color="red">mdi-minus-circle</v-icon></v-btn>

            </v-col>
          </v-row>
        </v-col>
      </template>
      <template slot="searchButton" v-if="QuotData.TypeService==11">
        <v-btn rounded class="mb-n4 searchButton" @click="searchQuotation">
          <v-icon>mdi-magnify</v-icon>
          Search
        </v-btn>
      </template>
    </QuotData>

<!--    <v-btn @click="testRate">rate</v-btn>-->

    <v-container fluid>
      <Offers
          ref="offersComp"
          v-bind:QuotData = QuotData
          :search="search"
          :searchOffer="searchOffer"
      >
        <template slot="Map">
          <Map
              class="border-rounded"
              :path="path"
              :markers="markers"
          ></Map>
        </template>
      </Offers>
    </v-container>

    <v-dialog
        v-model="dialog"
        class="pa-0"
        width="1200"
        transition="dialog-bottom-transition"
        scrollable
    >
      <v-card>
        <v-toolbar
            flat
            dark
            color="primary"
        >

          <v-toolbar-title>
            <v-icon>mdi-frequently-asked-questions</v-icon>
            Request Quote
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
              icon
              dark
              left
              @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text :key="redrawCreateOrder">

          <Maritimo
              v-if="showComponent == 1"
              v-bind="QuotData"
              @OrderCreated="OrderCreated"
          >
            <template v-slot:quoteData>
              <QuotData
                  v-on:Transporte="showComponent=$event"
                  v-on:QuotData="RecibeData"
                  ref="quotDataSlot"
                  @markersUpdated="setMarkers"
                  @dateMenuChange="changeAutocompleteStatus($event)"
                  v-bind="QuotData"
              >
                <template slot="autocomplete">
                  <div class="row no-gutters position-relative">
                    <div class="col-6 fixed-col-size">
                      <googleMapsAutocompleteVuetifyNew
                          label="Origen"
                          :search="originAddress"
                          :items="originItems"
                          :selections="origin"
                          transportation="SEA"
                          placeholder="Ingrese un origen"
                          @change="originSelected"
                          @updateAddress="updateOriginAddress"
                          @updateItems="updateOriginItems"
                          @menuChanged="isOriginOpen = $event"
                          :id="3"
                      >
                      </googleMapsAutocompleteVuetifyNew>
                    </div>
                    <div class="col-6 fixed-col-size">
                      <googleMapsAutocompleteVuetifyNew
                          label="Destino"
                          :search="destinationAddress"
                          :items="destinationItems"
                          :selections="destination"
                          transportation="SEA"
                          placeholder="Ingrese un destino"
                          @change="destinationSelected"
                          @updateAddress="updateDestinationAddress"
                          @updateItems="updateDestinationItems"
                          @menuChanged="isDestinationOpen = $event"

                          :id="4"
                      >
                      </googleMapsAutocompleteVuetifyNew>
                    </div>
                  </div>
                </template>
              </QuotData>
            </template>

          </Maritimo>
          <Aereo
              v-if="showComponent == 2"
              v-bind="QuotData"
              v-on:Transporte="showComponent=$event"
              @OrderCreated="OrderCreated"
          >
            <template v-slot:quoteData>
              <QuotData
                  ref="quotDataSlot"
                  v-on:QuotData="RecibeData"
                  @markersUpdated="setMarkers"
                  @dateMenuChange="changeAutocompleteStatus($event)"
                  v-bind="QuotData"
              >
                <template slot="autocomplete">
                  <div class="row no-gutters position-relative">
                    <div class="col-6 fixed-col-size">
                      <googleMapsAutocompleteVuetifyAereo
                          label="Origen"
                          :search="originAddress"
                          :items="originItems"
                          :selections="origin"
                          @change="originSelectedAereo"
                          @updateAddress="updateOriginAddress"
                          @updateItems="updateOriginItems"
                          @menuChanged="isOriginOpen = $event"
                          :id="1"
                          transportation="AIR"
                          placeholder="Ingrese un origen"
                      >
                      </googleMapsAutocompleteVuetifyAereo>
                    </div>
                    <div class="col-6 fixed-col-size">
                      <googleMapsAutocompleteVuetifyAereo
                          label="Destino"
                          :search="destinationAddress"
                          :items="destinationItems"
                          :selections="destination"
                          @change="destinationSelectedAereo"
                          @updateAddress="updateDestinationAddress"
                          @updateItems="updateDestinationItems"
                          @menuChanged="isDestinationOpen = $event"
                          :id="2"
                          transportation="AIR"
                          placeholder="Ingrese un Destino"
                      >
                      </googleMapsAutocompleteVuetifyAereo>
                    </div>
                  </div>
                </template>
              </QuotData>
            </template>

          </Aereo>
          <Terrestre
              v-if="showComponent == 3"
              v-bind="QuotData"
              v-on:Transporte="showComponent=$event"
              @OrderCreated="OrderCreated"
              @resetQuotData="QuotDataDefault"
          >
            <template v-slot:quoteData>
              <QuotData
                  ref="quotDataSlot"
                  v-on:QuotData="RecibeData"
                  @markersUpdated="setMarkers"
                  @dateMenuChange="changeAutocompleteStatus($event)"
                  v-bind="QuotData"
              >

                <template slot="autocomplete">
                  <div class="row no-gutters position-relative">
                    <div class="col-6 fixed-col-size">
                      <googleMapsAutocompleteVuetifyTerrestre
                          label="Origen"
                          :search="originAddress"
                          :items="originItems"
                          :selections="origin"
                          @change="originSelectedTerrestre"
                          @updateAddress="updateOriginAddress"
                          @updateItems="updateOriginItems"
                          placeholder="Ingrese un origen"
                      >
                      </googleMapsAutocompleteVuetifyTerrestre>
                    </div>
                    <div class="col-6 fixed-col-size">
                      <googleMapsAutocompleteVuetifyTerrestre
                          label="Destino"
                          :search="destinationAddress"
                          :items="destinationItems"
                          :selections="destination"
                          @change="destinationSelectedTerrestre"
                          @updateAddress="updateDestinationAddress"
                          @updateItems="updateDestinationItems"
                          placeholder="Ingrese un Destino"
                      >
                      </googleMapsAutocompleteVuetifyTerrestre>
                    </div>
                  </div>
                </template>

              </QuotData>
            </template>

          </Terrestre>

        </v-card-text>
      </v-card>
    </v-dialog>

  </div>

</template>

<script>
import Aereo from "@/components/Quote/Aereo";
import Terrestre from "@/components/Quote/Terrestre";
import googleMapsAutocompleteVuetifyAereo from "@/components/google-maps-autocomplete-vuetify/indexAereo";
// import googleMapsAutocompleteVuetify from "@/components/google-maps-autocomplete-vuetify/index";
import googleMapsAutocompleteVuetifyNew from "@/components/google-maps-autocomplete-vuetify/index_new";
import googleMapsAutocompleteVuetifyTerrestre from "@/components/google-maps-autocomplete-vuetify/indexTerrestre";

import RequestQuote from "@/components/Quote/RequestQuote";
import QuotData from "@/components/Quote/QuotData";
import Offers from "@/components/Quote/Offers";
import Map from "@/components/Map/Map";
import Maritimo from "@/components/Quote/Maritimo";

export default {
  name: "IndexQuote",
  components: {
    Aereo,
    Terrestre,
    Maritimo,
    Map,
    Offers,
    QuotData,
    RequestQuote,
    // googleMapsAutocompleteVuetify,
    googleMapsAutocompleteVuetifyNew,
    googleMapsAutocompleteVuetifyAereo,
    googleMapsAutocompleteVuetifyTerrestre,

  },
  data: () => ({
    redrawCreateOrder: 0,
    rate:[
      {
        typename: "StandardFCLRate",
        maritime: {
          _id: "60c772c24cd795cc7809bccd",
          frequency: "Semanal",
          reference_number: "TA_LATAM-ALL_LATAM",
          validity_from: "Desconocido",
          validity: "1582934400000",
          validity_to: "Desconocido",
          travel_time: "5 dias",
          supplier: {
            _id: "601495c6007a633af0171365",
            name: "CMA CGM",
            details: {
              logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/CMA_CGM_logo.svg/1024px-CMA_CGM_logo.svg.png"
            }
          },
          pol: {
            _id: "5fbd2ec13469a15224eab795",
            name: "Manzanillo",
            city: "Manzanillo",
            country: "Mexico",
            code: "20120",
            unlocs: [
              "MXZLO"
            ]
          },
          pod: {
            _id: "5fbd2ec13469a15224eab4f1",
            name: "Buenaventura",
            city: "Buenaventura",
            country: "Colombia",
            code: "30151",
            unlocs: [
              "COBUN"
            ]
          },
          container: [
            {
              type: [
                "20DC",
                "20GP",
                "20STD",
                "OF20",
                "20DRY",
                ""
              ],
              name: "20 Dry Freight Container",
              price: 165,
              currency: "USD",
              fees: []
            },
            {
              type: [
                "40HC",
                "40HQ",
                "OF40HC",
                "40HDRY"
              ],
              name: "40 High Cube Container",
              price: 315,
              currency: "USD",
              fees: []
            }
          ],
          vesselFees: [
            {
              rate: "1",
              amount: "17",
              currency: "USD",
              description: "ISPS"
            },
            {
              rate: "1",
              amount: "70",
              currency: "USD",
              description: "Doc_Fee"
            }
          ]
        },
        origin: [],
        bestOffer: {
          originId: null,
          maritimeId: "60c772c24cd795cc7809bccd",
          price: 165
        }
      }
    ],
    searchOffer:false,
    containers:[],
    load:[
      {
        id:1,
        container:"20DC",
        containerQuantity:1,
        containerWeigth:21000,
      }
    ],
    count:0,
    container:"20DC",
    containerQuantity:1,
    containerWeigth:21000,
    showComponent: 2,
    autocomplete: "",
    isOriginOpen: false,
    isDestinationOpen: false,
    isOriginOpen2: false,
    isDestinationOpen2: false,
    UserRole: null,
    originAddress: "",
    originSelections: {},
    destinationSelections: {},
    originItems: [],
    originQuoteAddress: "",
    destinationAddress: "",
    destinationItems: [],
    destinationQuoteAddress: "",
    path: [],
    markers: [],
    availablePortsOrigin: [],
    portSelectedOrigin: {},
    availablePortsDestination: [],
    portSelectedDestination: {},
    origin: {},
    destination: {},
    filterOptions: false,
    dialog: false,
    QuotData: {
      Typetransportation: null,
      TypeService: null,
      Inconterm: null,
      typeRoute: null,
      dueDate: new Date().toISOString().substr(0, 10),
      origin: {

      },
      destination: {},
      searchVariables: {},
      DestinationPort: {},
      OriginPort: {},
      AutocompleteOrigin: false,
      AutocompleteDestination: false,
      DataService: {},
      rate:null
    },
    RangePrices: ['1- 100 USD', '100 - 300 USD', '300 - 1000', '> 1000'],
    commodities: ['Agricultura', 'Alimentación', 'Comercio', 'Construcción', 'Educación'],
    price: 0,
    Incoterms:[],
    commodity: '',
    portsOfLoading: [],
    expand: false,
    slider: 45,
    search: {
      origin: {
        city:null,
        country:null,
        code:null,
        port:{
          city:null,
          country:null
        }
      },
      destiny: {
        city:null,
        country:null,
        code:null,
        port:{
          city:null,
          country:null
        }
      },
      departure_date: null,
      containers: [
        "1x20DRYx10"
      ],
      incoterm: null,
      serviceType: null
    },
  }),
  watch: {
    showComponent() {
      //alert(ShowComponent)
      this.defaultPlaces();
    },
    QuotData: {
      handler() {
        console.log(this.QuotData)

      },
      deep: true
    }
  },
  methods: {
    changeAutocompleteStatus(val) {
      // console.log(val)
      if (val) {
        this.isOriginOpen = false;
        this.isDestinationOpen = false;
        this.isOriginOpen2 = false;
        this.isDestinationOpen2 = false;
      }
    },

    addLoad(){
      this.count++;
      this.load.push(
          {
          id:this.count,
          container:"20DC",
          containerQuantity:1,
          containerWeigth:21000,
        }
      )
    },

    DeleteLoad(id){
      var index = this.load.findIndex(element=>element.id == id)
      this.load.splice(index,1);
    },

    originSelectedTerrestre(selections) {
      this.origin = selections;

      this.$nextTick(() => {
        this.QuotData.origin["address"] = this.origin.description
        this.QuotData.origin["city"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' || a === "sublocality" || a === "administrative_area_level_3") !== -1)[0].long_name
        this.QuotData.origin["state"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0].long_name
        this.QuotData.origin["country"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
        this.QuotData.origin["coordinates"] = this.origin.details.location;
        this.QuotData.AutocompleteOrigin = true;
      })
    },
    destinationSelectedTerrestre(selections) {
      this.destination = selections;

      this.$nextTick(() => {
        this.QuotData.destination["address"] = this.destination.description
        this.QuotData.destination["city"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' || a === "sublocality" || a === "administrative_area_level_3") !== -1)[0].long_name
        this.QuotData.destination["state"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0].long_name
        this.QuotData.destination["country"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
        this.QuotData.destination["coordinates"] = this.destination.details.location;
        this.QuotData.AutocompleteDestination = true;

      })
    },
    originSelectedAereo(selections) {
      try {
        this.origin = selections
        this.$nextTick(() => {
          try {
            if (!Array.isArray(this.origin)) {
              this.QuotData.origin["city"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality') !== -1)[0].long_name
              this.QuotData.origin["state"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0].long_name
              this.QuotData.origin["country"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
              this.QuotData.origin["coordinates"] = this.origin.details.location;
              this.QuotData.origin["airports"] = this.origin.airports;
              this.QuotData.origin["address"] = this.origin.description;
              this.QuotData.AutocompleteOrigin = true;
              let index = this.origin.airports.findIndex(e => e.selected);
              if (index !== -1) {
                this.QuotData.origin["airports"].name = this.origin.airports[index].name;
                this.QuotData.origin["airports"].city = this.origin.airports[index].city;
                this.QuotData.origin["airports"].coordinates = `${this.origin.airports[index].lat},${this.origin.airports[index].lon}`;
                this.QuotData.origin["airports"].country = this.origin.airports[index].country;
                this.QuotData.OriginPort = this.origin.airports[index];
              }
            }
          } catch (e) {
            throw new Error(`There was an error while selecting the item ${JSON.stringify(selections)} \n` + e)
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    defaultPlaces() {
      this.originItems = [];
      this.destinationItems = [];
      this.origin = {};
      this.destination = {};
      this.originAddress = '';
      this.destinationAddress = '';
    },
    destinationSelectedAereo(selections) {
      try {
        this.destination = selections
        this.$nextTick(() => {
          try {
            if (!Array.isArray(this.destination)) {
              this.QuotData.destination["city"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality') !== -1)[0].long_name
              this.QuotData.destination["state"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0].long_name
              this.QuotData.destination["country"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
              this.QuotData.destination["coordinates"] = this.destination.details.location;
              this.QuotData.destination["airports"] = this.destination.airports;
              this.QuotData.destination["address"] = this.destination.description;
              this.QuotData.AutocompleteOrigin = true;
              let index = this.destination.airports.findIndex(e => e.selected);
              if (index !== -1) {
                this.QuotData.origin["airports"].name = this.destination.airports[index].name;
                this.QuotData.origin["airports"].city = this.destination.airports[index].city;
                this.QuotData.origin["airports"].coordinates = `${this.destination.airports[index].lat},${this.destination.airports[index].lon}`;
                this.QuotData.origin["airports"].country = this.destination.airports[index].country;
                this.QuotData.DestinationPort = this.destination.airports[index];
              }
            }
          } catch (e) {
            throw new Error(`There was an error while selecting the item ${JSON.stringify(selections)} \n` + e)
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    testRate(){
      this.QuotData.rate = this.rate;
      this.dialog = true;
    },
    testObject(){
      let request = {
        search: {
          origin: {
            city: "Manzanillo",
            country: "MX",
            code: "MXZLO",
            port: {
              city: "Manzanillo",
              country: "Mexico"
            }
          },
          destiny: {
            city: "Buenaventura",
            country: "CO",
            code: "USMIA",
            port: {
              city: "Buenaventura",
              country: "Colombia"
            }
          },
          departure_date: "2022-01-14",
          containers: [
            "1x20DRYx10"
          ],
          incoterm: "CFR",
          serviceType: "EXPO"
        }
      };

      this.$http.post('http://3.140.230.151:5200/',{
        query:`${request}`
      }).then(response =>{
        console.log(response.data);
      }).catch(error=>{
        console.log(error)
      }).finally(()=>{

      })



    },
    searchQuotation: function () {
      this.search.origin.city = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality') !== -1)[0].long_name
      this.search.origin.country = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
      let index = this.origin.ports.findIndex(e => e.selected);
      if (index !== -1) {
        this.search.origin.port.city = this.origin.ports[index].city;
        this.search.origin.port.country = this.origin.ports[index].country;
        this.search.origin.code = this.origin.ports[index].unlocs[0]
      }

      this.search.destiny.city = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality') !== -1)[0].long_name
      this.search.destiny.country = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
      let indexDestiny = this.destination.ports.findIndex(e => e.selected);
      if (indexDestiny !== -1) {
        this.search.destiny.port.city = this.destination.ports[indexDestiny].city;
        this.search.destiny.port.country = this.destination.ports[indexDestiny].country;
        this.search.destiny.code = this.destination.ports[indexDestiny].unlocs[0]
      }
      this.search.departure_date = this.QuotData.dueDate;


      if (this.QuotData.Type == 1)
        this.search.serviceType = "IMPO"

      if (this.QuotData.Type == 2)
        this.search.serviceType = "EXPO"

      let incoterm = this.Incoterms.find(element => element.id == this.QuotData.Inconterm)
      this.search.incoterm = incoterm.code

      //this.search.containers[0] = `${this.containerQuantity}x${this.container}x${this.containerWeigth}`
      this.$refs.offersComp.searchOffers();

    },
    OrderCreated() {
      this.dialog = false;
      this.originAddress = ""
      this.origin = {}
      this.originItems = []
      this.destinationAddress = ""
      this.destination = {}
      this.destinationItems = []
      this.$nextTick(() => {
        this.QuotData = {
          Typetransportation: null,
          TypeService: null,
          Inconterm: null,
          typeRoute: null,
          dueDate: new Date().toISOString().substr(0, 10),
          origin: {},
          destination: {},
          searchVariables: {},
          DestinationPort: {},
          OriginPort: {},
          AutocompleteOrigin: false,
          AutocompleteDestination: false,
          DataService: {},
        };
        this.redrawCreateOrder += 1;
      })
      //this.QuotDataDefault();
    },
    QuotDataDefault() {
      this.$refs.quotDataSlot.QuotDataDefault();
    },
    updateDestinationAddress(text) {
      this.destinationAddress = text
    },
    updateOriginAddress(text) {
      this.originAddress = text
    },
    updateOriginItems(items) {
      this.originItems = items;
    },
    updateDestinationItems(items) {
      this.destinationItems = items;
    },
    originSelected(selections) {
      try {
        this.origin = selections
        this.$nextTick(() => {
          try {
            if (!Array.isArray(this.origin)) {
              this.QuotData.origin["city"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality') !== -1)[0].long_name
              this.QuotData.origin["state"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0].long_name
              this.QuotData.origin["country"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
              this.QuotData.origin["coordinates"] = this.origin.details.location;
              this.QuotData.origin["ports"] = this.origin.ports;
              this.QuotData.origin["address"] = this.origin.description;
              this.QuotData.AutocompleteOrigin = true;
              let index = this.origin.ports.findIndex(e => e.selected);
              this.QuotData.origin["ports"] = this.origin.ports;
              this.setPath()

              this.origin.address = this.originSelections.description;
              if (index !== -1) {
                this.QuotData.origin["ports"].name = this.origin.ports[index].name;
                this.QuotData.origin["ports"].city = this.origin.ports[index].city;
                this.origin.ports[index].coordinates[0] = parseInt(this.origin.ports[index].coordinates[0]);
                this.origin.ports[index].coordinates[1] = parseInt(this.origin.ports[index].coordinates[1]);
                this.QuotData.origin["ports"].coordinates = `${this.origin.ports[index].coordinates[0]},${this.origin.ports[index].coordinates[1]}`;
                this.QuotData.origin["ports"].country = this.origin.ports[index].country;
              }
              // console.log(this.QuotData.origin["ports"]);
            }
          } catch (e) {
            throw new Error(`There was an error while selecting the item ${JSON.stringify(selections)} \n` + e)
          }
        })
      } catch (error) {
        console.log(error)
        throw new Error(`There was an error while selecting a new origin ${JSON.stringify(selections)} \n` + error)
      }
    },
    destinationSelected: function (selections) {
      this.destination = selections
      this.$nextTick(() => {
        if (!Array.isArray(this.destination)) {
          this.QuotData.destination["city"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality') !== -1)[0].long_name
          this.QuotData.destination["state"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0].long_name
          this.QuotData.destination["country"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
          this.QuotData.destination["coordinates"] = this.destination.details.location
          this.QuotData.destination["ports"] = this.destination.ports;
          this.QuotData.AutocompleteDestination = true;
          this.QuotData.destination["address"] = this.destination.description;

          let index = this.destination.ports.findIndex(e => e.selected);
          this.QuotData.destination["ports"] = this.destination.ports;
          this.setPath()

          // this.Origin.address = this.originSelections.description;
          if (index !== -1) {
            this.QuotData.destination["ports"].name = this.origin.ports[index].name;
            this.QuotData.destination["ports"].city = this.origin.ports[index].city;
            this.destination.ports[index].coordinates[0] = parseInt(this.destination.ports[index].coordinates[0]);
            this.destination.ports[index].coordinates[1] = parseInt(this.destination.ports[index].coordinates[1]);
            this.QuotData.destination["ports"].coordinates = `${this.destination.ports[index].coordinates[0]},${this.destination.ports[index].coordinates[1]}`;
            this.QuotData.destination["ports"].country = this.destination.ports[index].country;
          }

          this.setPath()
        }
      })
    },
    setMarkers: function (markers) {
      console.log(markers)
    },
    setPath: function () {
      try {
        this.path = [];
        this.markers = [];
        this.availablePortsOrigin = [];
        this.availablePortsDestination = [];

        if (this.origin.description) {
          const selectedIndex = this.origin.ports.findIndex(x => x.selected)

          if (this.origin.ports.length > 1) {
            this.origin.ports.forEach(port => {
              if (!port.selected) this.availablePortsOrigin.push({
                type: 'port',
                location: {lat: port.lat, lng: port.lon}
              })
            });
            this.markers.push(...this.availablePortsOrigin)
            this.path.push({
              type: 'place',
              location: this.origin.details.location
            })
          }

          if (selectedIndex !== -1) {
            this.portSelectedOrigin = this.origin.ports[selectedIndex]
            this.QuotData.OriginPort = this.portSelectedOrigin;

            if ((this.portSelectedOrigin.city !== this.QuotData.origin.city) || (this.portSelectedOrigin.lat !== this.QuotData.origin.coordinates.lat && this.portSelectedOrigin.lat !== this.QuotData.origin.coordinates.lat)) {
              this.path.push({
                type: 'port',
                location: {lat: this.origin.ports[selectedIndex].lat, lng: this.origin.ports[selectedIndex].lon}
              })
            }
          }
        }

        if (this.destination.description) {
          const selectedIndex = this.destination.ports.findIndex(x => x.selected)

          if (selectedIndex !== -1) {
            this.portSelectedDestination = this.destination.ports[selectedIndex]
            this.QuotData.DestinationPort = this.portSelectedDestination;

            if ((this.portSelectedDestination.city !== this.QuotData.destination.city) || (this.portSelectedDestination.lat !== this.QuotData.destination.coordinates.lat && this.portSelectedDestination.lat !== this.QuotData.destination.coordinates.lat)) {
              this.path.push({
                type: 'port',
                location: {
                  lat: this.destination.ports[selectedIndex].lat,
                  lng: this.destination.ports[selectedIndex].lon
                }
              })
            }
          }

          if (this.destination.ports.length > 1) {
            this.destination.ports.forEach(port => {
              if (!port.selected) this.availablePortsDestination.push({
                type: 'port',
                location: {lat: port.lat, lng: port.lon}
              })
            });
            this.markers.push(...this.availablePortsDestination)
            this.path.push({type: 'place', location: this.destination.details.location})
          }
        }

      } catch (error) {
        throw new Error(`There was an error while creating a path for map in origin: ${JSON.stringify(this.origin)} or destination: ${JSON.stringify(this.destination)} \n` + error)
      }
    },
    RecibeData: function (data) {


      this.QuotData.Typetransportation = data.transport;
      this.QuotData.TypeService = data.service;
      this.QuotData.Inconterm = data.incoterm;
      this.QuotData.Type = data.typeRoute;
      this.QuotData.dueDate = data.dueDate;
      this.QuotData.AutocompleteOrigin = false;
      this.QuotData.DataService = data.DataService;

      if (this.origin.description) {
        this.QuotData.origin["city"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality') !== -1)[0].long_name
        this.QuotData.origin["state"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0].long_name
        this.QuotData.origin["country"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
        this.QuotData.origin["coordinates"] = this.origin.details.location
        this.QuotData.AutocompleteOrigin = true;
      }

      if (this.destination.description) {
        this.QuotData.destination["city"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality') !== -1)[0].long_name
        this.QuotData.destination["state"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0].long_name
        this.QuotData.destination["country"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
        this.QuotData.destination["coordinates"] = this.destination.details.location
        this.QuotData.AutocompleteDestination = true;
      }


    },
    getLoadingTypes: function () {

      this.$http.get('LoadingTypes')
          .then(response => {
            console.log(response.data.data);
            this.LoadingTypes = response.data.data;
            //this.Incoterm = response.data.data[0].code;
            // console.log("inco def",this.Incoterm);
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.loading = false)
    },


    getIncoterms: function () {
      this.$http.get('Incoterms', {
        headers: {
          'Cache-Control': 'max-age=1000'
        }
      })
          .then(response => {
            if (response.data.code == 200)
              this.Incoterms = response.data.data;
          }).catch(error => {
        console.log(error);
        this.errored = true
      })
          .finally(() => this.loading = false)
    },

    getContainers: function () {
      this.$http.get('Containers')
          .then(response => {
            this.containers = response.data.data
            this.containers = this.containers.filter(element => element.transportationId == 1)
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => this.loading = false)
    },


  },
  mounted() {
    this.getLoadingTypes();
    this.getIncoterms();
    this.getContainers();
    this.UserRole = this.$store.getters.roles
    if (this.UserRole.indexOf('Ventas') == -1) {
      this.$toast.info('Su usuario no puede crear cotizaciones');
    }
  }
}
</script>

<style scoped lang="scss">

.fixedOption {
  position: fixed;
  z-index: 2;
  bottom: 1em;
  right: 2em;
}

.border-rounded {
  border-radius: 10px;
}

.fixed-col-size {
  min-width: 250px;
}

.searchButton {
  z-index: 2;
}

.position-relative {
  position: relative;
}
</style>

