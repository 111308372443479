<template>
  <div>
    <v-card>

      <v-card-text>
        <br>

        <h2 class="font-weight-bold text-center">Motivos de renegociacion</h2>
        <br>

        <v-row>
          <v-col md="4">
            <v-select
                v-model="Refuse.orderReasonId"
                class="ml-6"
                dense
                outlined
                :items="RefuseReasons"
                item-value="Id"
                item-text="Reasons"
            >
            </v-select>
          </v-col>

          <v-col md="10">
            <v-textarea
                v-model="Refuse.comments"
                outlined
                class="ml-6 pa-0"
                name="input-7-1"
                label="Comentarios"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col md="10" class="ml-5">
              <file-drag v-model="Refuse.historyDocumentsDrag" @change="ConvertFile()"></file-drag>
          </v-col>
        </v-row>




        <v-row>
          <v-col md="2">
            <v-btn color="primary" class="ml-6" @click="RefuseOrder()">
              Enviar Comentarios
            </v-btn>
          </v-col>
        </v-row>


      </v-card-text>

    </v-card>
  </div>
</template>

<script>
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import FileDrag from "../Global/FileDrag/FileDrag";
export default {
  name: "RenegotiationComments",
  props: {
    id: {
      type: Number
    },
    activeComment: {
      type: Boolean,
      default: false
    },
  },
  components:{
    FileDrag,
  },
  data(){
    return{
      document:null,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        dictDefaultMessage: 'Adjuntar Archivos'

      },
      dialog:false,
      Refuse:{
        orderId:null,
        comments:null,
        orderReasonId:7,
        historyDocuments:[],
        historyDocumentsDrag:[]
      },
      RefuseReasons: [
        {Id: 5, Reasons: 'Cambio de Fecha', Status: true},
        {Id: 6, Reasons: 'Se Vencio', Status: true},
        {Id: 7, Reasons: 'Esta Caro', Status: true},
        {Id: 8, Reasons: 'Cambio de Servicio', Status: true}
      ],
    }
  },
  methods:{
    RefuseOrder: function () {
      this.Refuse.orderId = this.id;
      this.$store.dispatch('working', true)
      this.$http.put(`Orders/renegotiation/${this.Refuse.orderId}`,this.Refuse).then(response => {
        console.log(response.data.data);
        this.Refuse.comments = null;
        this.$emit('closeDialog',false)
        this.$toast.info("Se envio a renegociar");
      }).catch(error=>{
        console.log(error)
      }).finally(()=>{
        this.$store.dispatch('working',false);
      });
    },
    UploadFile: function (file) {
      return new Promise((resolve, reject) => {

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error);
      });
    },

    ConvertFile: async function () {

      let documents = this.Refuse.historyDocumentsDrag;

      for (const doc of documents) {
        const result = await this.UploadFile(doc);
        const data = result.split(',')[1];
        const dotPosition = doc.name.lastIndexOf('.');
        const fileExt = doc.name.substr(dotPosition + 1);
        const fileName = dotPosition === -1 ? doc.name : doc.name.substr(0, dotPosition);
        this.Refuse.historyDocuments.push(
            {
              documentTypeId: 103,
              file: data,
              fileName: fileName,
              fileExtension: fileExt
            }
        )
      }
      console.log(this.Refuse)
    },
  }
}
</script>

<style scoped>

</style>
