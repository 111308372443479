<template>
  <v-overlay :value="active" :opacity="opacity" :absolute="absolute">
    <div class="earth"></div>
  </v-overlay>

</template>

<script>
export default {
  name: "VLoading",
  props: {
    active: {
      type: Boolean,
      default: false
    },

    opacity: {
      type: Number,
      default: 0.46
    },

    absolute: {
      type: Boolean,
      default: false,
    }
  },
}
</script>

<style scoped>

.earth {
  width: 500px;
  height: 200px;
  background-image: url("../../../assets/continenteLogo.gif");
  background-size: cover;
  margin: 0 auto;
}

</style>
