<template>
  <div>
    <v-stepper v-model="e6" vertical>
      <v-stepper-step :complete="e6 > 1" step="1" @click="e6 = 1">
        Area
        <small>Area sera donde estará ubicado en personal</small>
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-row justify="center">

          <v-col md="2">

            <v-btn @click="e6 = 2,employee.roleId=1" outlined x-large fab color="primary">
              <v-icon>las la-user</v-icon>
            </v-btn>
            <br>
            <span class="font-weight-regular black--text offset-1">Ventas</span>
          </v-col>

          <v-col md="2">
            <v-btn @click="e6 = 2,employee.roleId=2" outlined x-large fab color="primary">

              <v-icon>las la-hands-helping</v-icon>

            </v-btn>
            <br>
            <span class="font-weight-regular black--text offset-1">Pricing</span>

          </v-col>

          <v-col md="2">
            <v-btn @click="e6 = 2,employee.roleId=3" outlined x-large fab color="primary">
              <v-icon>las la-list</v-icon>
            </v-btn>
            <br>
            <span class="font-weight-regular black--text">Operacion</span>
          </v-col>

          <v-col md="2">
            <v-btn @click="e6 = 2,employee.roleId=4" outlined x-large fab color="primary">
              <v-icon>las la-hand-holding-usd</v-icon>
            </v-btn>
            <br>
            <span class="font-weight-regular black--text offset-1">Admin</span>

          </v-col>
        </v-row>
      </v-stepper-content>

      <v-stepper-step :complete="e6 > 2" step="2" @click="e6 = 2">
        Datos personales
      </v-stepper-step>

      <v-stepper-content step="2">
        <v-form>
          <v-container>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field v-model="employee.firstname" :rules="nameRules" label="Nombre(s)" required></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field v-model="employee.lastname" :rules="lastNameRules" label="Apellido" required>
                </v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field v-model="employee.personalEmail" :rules="emailRules" label="Correo Electronico Personal"
                  required></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field v-model="employee.phoneNumber" :rules="cellphoneRules" label="Celular" required>
                </v-text-field>
              </v-col>

            </v-row>
          </v-container>
        </v-form>

        <v-form>
          <v-container>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field v-model="employee.homePhone" :rules="HomePhoneRules" label="Tel de Domicilio" required>
                </v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field v-model="employee.taxNumber" :rules="RfcRules" label="RFC" required></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field v-model="employee.socialSecurity" :rules="SegurosocialRules"
                  label="Numero de seguro social" required></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field v-model="employee.universalId" :rules="CurpRules" label="Curp"></v-text-field>
              </v-col>

            </v-row>
          </v-container>
        </v-form>

        <v-form>
          <v-container>
            <v-row>
              <v-col cols="12" md="3">
                <v-autocomplete return-object v-model="countrySelected" label="Pais" :rules="CountryRules" required
                  :items="Country" item-value="id" item-text="name"></v-autocomplete>
              </v-col>

              <v-col cols="12" md="3">
                <v-autocomplete return-object v-model="stateSelected" label="Estado" :rules="CountryRules" required
                  :items="States" item-value="id" item-text="name"></v-autocomplete>
              </v-col>

              <v-col cols="12" md="3">
                <v-autocomplete v-model="employee.cityId" label="Ciudad" :rules="CityRules" :items="Cities"
                  item-value="id" item-text="name" required></v-autocomplete>
              </v-col>

              <v-col cols="12" md="3">
                <v-autocomplete v-model="employee.languageId" label="Lenguaje" :items="Languages" :rules="LanguajeRules"
                  item-value="id" item-text="description" required></v-autocomplete>
              </v-col>



            </v-row>
          </v-container>
        </v-form>


        <v-form>
          <v-container>
            <v-row>
              <v-col cols="12" md="3">
                <v-file-input v-model="employee.Documentation[0].file" :rules="INERules"
                  @change="ConvertFile(employee.Documentation[0].file,0)" label="Copia de IFE"></v-file-input>
              </v-col>

              <v-col cols="12" md="3">
                <v-file-input v-model="employee.Documentation[1].file" :rules="ComprobanteRules"
                  @change="ConvertFile(employee.Documentation[1].file,1)" label="Copia de comporbante de domicilio">
                </v-file-input>
              </v-col>

              <v-col cols="12" md="3">
                <v-file-input v-model="employee.Documentation[2].file" :rules="CurriculumRules"
                  @change="ConvertFile(employee.Documentation[2].file,2)" label="Curriculum"></v-file-input>
              </v-col>

            </v-row>
          </v-container>
        </v-form>




        <v-btn color="primary" @click="e6 = 3">
          Continuar
        </v-btn>
        <v-btn @click="e6 = 1">
          Atras
        </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="e6 > 3" step="3" @click="e6 = 3">
        Informacion Empresarial
      </v-stepper-step>

      <v-stepper-content step="3">
        <v-form>
          <v-container>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field v-model="employee.monthlySalary" :rules="SueldoMensualRules" :counter="10" type="number"
                  label="Sueldo Mensual" required></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field v-model="employee.extensionOffice" :rules="ExtencionRules" :counter="10"
                  label="Extencion de oficina tel" required></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field v-model="employee.email" :rules="emailcompanyRules" label="Correo empresarial" required>
                </v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field v-model="employee.Accounts[0].accountNumber" :rules="CuentaRules"
                  label="Tarjeta de deposito" required></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field v-model="employee.Accounts[0].bank" :rules="BankRules" label="Banco" required>
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>

        <v-btn color="primary" @click="e6 = 4">
          Continuar
        </v-btn>
        <v-btn @click="e6 = 2">
          Atras
        </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="e6 > 4" step="4" @click="e6 = 4">
        Cuenta
      </v-stepper-step>

      <v-stepper-content step="4">
        <v-form>
          <v-container>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field v-model="employee.Configuration.email" :rules="UserEmailRules" label="Cuenta de Email"
                  required></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field v-model="employee.Configuration.pwd" :rules="PasswordRules" label="Password" required>
                </v-text-field>
              </v-col>

            </v-row>


            <v-row>

            </v-row>
          </v-container>
        </v-form>

        <v-btn color="primary" @click="e6 = 1,NewEmployee()">
          Continuar
        </v-btn>
        <v-btn @click="e6 = 3">
          Atras
        </v-btn>
      </v-stepper-content>


    </v-stepper>
  </div>
</template>

<script>
  export default {
    name: "Employees",
    props: {
      EmployeeData: {}
    },
    data() {
      return {
        countrySelected: {},
        stateSelected: {},
        Cities: [],

        States: [],

        Country: [],

        employee: {
          phoneNumber: null,
          homePhone: null,
          roleId: null,
          taxNumber: null,
          universalId: null,
          socialSecurity: null,
          monthlySalary: null,
          extensionOffice: null,
          personalEmail: null,
          email: null,
          firstname: null,
          lastname: null,
          cityId: null,
          countryId: null,
          languageId: null,

          userRoles: {
            RoleId: null
          },
          Configuration: {
            email: null,
            pwd: null,
          },
          Accounts: [{
            accountNumber: null,
            bank: null
          }],
          Documentation: [{
              documentTypeId: 1,
              file: null,
              fileName: "INE",
              fileExtension: "PNG"
            },

            {
              documentTypeId: 2,
              file: null,
              fileName: "Comprobante de Domicilio",
              fileExtension: "PNG"
            },

            {
              documentTypeId: 3,
              file: null,
              fileName: "Curriculum",
              fileExtension: "PDF"
            }
          ]
        },

        nameRules: [
          v => !!v || 'Nombre es requerido',
        ],

        lastNameRules: [
          v => !!v || 'Apellido es requerido',
        ],

        emailRules: [
          v => !!v || 'E-mail es requerido',
          v => /.+@.+\..+/.test(v) || 'Correo Invalido',
        ],

        cellphoneRules: [
          v => !!v || 'Celular es requerido',
        ],

        HomePhoneRules: [
          v => !!v || 'Telefono es requerido',
        ],

        RfcRules: [
          v => !!v || 'RFC es requerido',
        ],

        SegurosocialRules: [
          v => !!v || 'Seguro social es requerido',
        ],

        CurpRules: [
          v => !!v || 'Curp es requerido',
        ],

        CountryRules: [
          v => !!v || 'Seleccione País',
        ],

        CityRules: [
          v => !!v || 'Seleccione Ciudad',
        ],

        INERules: [
          v => !!v || 'Copia de INE requerido',
        ],

        ComprobanteRules: [
          v => !!v || 'Comprobante de domicilio es requerido',
        ],

        CurriculumRules: [
          v => !!v || 'Curriculum es requerido',
        ],

        LanguajeRules: [
          v => !!v || 'Lenguaje es requerido',
        ],

        SueldoMensualRules: [
          v => !!v || 'Sueldo Mensual es requerido',
        ],

        ExtencionRules: [
          v => !!v || 'Tel. de extension es requerido',
        ],

        emailcompanyRules: [
          v => !!v || 'Correo Empresarial es requerido',
          v => /.+@.+\..+/.test(v) || 'Correo Invalido',
        ],

        CuentaRules: [
          v => !!v || 'Tarjeta de deposito es requerido',
        ],

        BankRules: [
          v => !!v || 'Banco es requerido',
        ],

        UserEmailRules: [
          v => !!v || 'Email es requerido',
          v => /.+@.+\..+/.test(v) || 'Correo Invalido',
        ],

        PasswordRules: [
          v => !!v || 'Password es requerido',
        ],

        e6: 1,
      }

    },
    methods: {
      NewEmployee: function () {

        //this.$store.dispatch('working',true);
        var monthlySalary = 0;
        monthlySalary = parseInt(this.employee.monthlySalary);
        this.employee.monthlySalary = monthlySalary;
        console.log(this.employee)

        this.$http.post('Employees', this.employee)
          .then(response => {
            if(response.data.code == 200){
              console.log(response.data)
              this.$toast.success("Empleado Agregado");
            }

            if (response.data.code == 400 || response.data.code == 500)
              this.$toast.error("Algo salio mal, intente mas tarde")
          })
          .catch(error => {
            if(error.response.status == 401){
              this.$store.commit('reset');
              this.$toast.error("La sesion expiro");
              this.$router.replace('/login')
            }

            console.log(error)
            this.$toast.error("Error al añadir empleado, intente mas tarde");
          })
          .finally(() => this.$store.dispatch('working', false))
      },

      GetLanguages: function () {
        this.$store.dispatch('working', true)
        this.$http.get('Languages')
          .then(response => {
            if(response.data.code == 200){
              this.Languages = response.data.data;
            }

            if (response.data.code == 400 || response.data.code == 500)
              this.$toast.error("Algo salio mal, intente mas tarde")
          })
          .catch(error => {
            if(error.response.status == 401){
              this.$store.commit('reset');
              this.$toast.error("La sesion expiro");
              this.$router.replace('/login')
            }

            console.log(error)

          }).finally(() => this.$store.dispatch('working', false))
      },

      GetCountry: function () {
        this.$store.dispatch('working', true);
        this.$http.get('Countries')
          .then(response => {
            if(response.data.code == 200){
              this.Country = response.data.data;
            }

            if (response.data.code == 400 || response.data.code == 500)
              this.$toast.error("Algo salio mal, intente mas tarde")
          })
          .catch(error => {
            if(error.response.status == 401){
              this.$store.commit('reset');
              this.$toast.error("La sesion expiro");
              this.$router.replace('/login')
            }
            console.log(error)

          }).finally(() => this.$store.dispatch('working', false))
      },

      GetCities: function (code) {
        console.log(code);

        this.$store.dispatch('working', true);
        this.$http.get(`Cities/state/${code}`)
          .then(response => {
            if(response.data.code == 200){
              this.Cities = response.data.data;
            }

            if (response.data.code == 400 || response.data.code == 500)
              this.$toast.error("Algo salio mal, intente mas tarde")

          })
          .catch(error => {
            if(error.response.status == 401){
              this.$store.commit('reset');
              this.$toast.error("La sesion expiro");
              this.$router.replace('/login')
            }

            console.log(error)

          }).finally(() => this.$store.dispatch('working', false))
      },

      GetState: function (code) {
        console.log(code);
        this.$store.dispatch('working', true);
        this.$http.get(`States/Country/${code}`)
          .then(response => {
            if(response.data.code == 200){
              this.States = response.data.data;
            }

            if (response.data.code == 400 || response.data.code == 500)
              this.$toast.error("Algo salio mal, intente mas tarde")
          })
          .catch(error => {
            if(error.response.status == 401){
              this.$store.commit('reset');
              this.$toast.error("La sesion expiro");
              this.$router.replace('/login')
            }

            console.log(error)

          }).finally(() => this.$store.dispatch('working', false))
      },

      UploadFile: function (file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      },

      ConvertFile: function (file, index) {

        this.UploadFile(file).then(response => {


            console.log(response);
            var data = response.split(',')[1];
            this.employee.Documentation[index].file = data;
          })
          .catch(error => {
            console.log(error)
          })

      },

      setEmployee() {
        if (this.EmployeeData) {
          this.employee.userRoles.RoleId = this.EmployeeData.user.roles[0].role.name
          this.employee.firstname = this.EmployeeData.user.firstName
          this.employee.lastname = this.EmployeeData.user.lastName
          this.employee.personalEmail = this.EmployeeData.personalEmail
          this.employee.phoneNumber = this.EmployeeData.user.phoneNumber
          this.employee.homePhone = this.EmployeeData.homePhone
          this.employee.taxNumber = this.EmployeeData.taxNumber
          this.employee.socialSecurity = this.EmployeeData.socialSecurity
          this.employee.universalId = this.EmployeeData.universalId
            this.employee.countryId = this.EmployeeData.user.configuration.countryId
            this.countrySelected.id = this.EmployeeData.user.configuration.countryId
          // this.stateSelected.id =
          this.employee.cityId = this.EmployeeData.user.configuration.cityId
          this.employee.languageId = this.EmployeeData.user.configuration.languageId
          // Ine
          // Comprobante
          // Curriculum

          this.employee.monthlySalary = this.EmployeeData.monthlySalary
          this.employee.extensionOffice = this.EmployeeData.extensionOffice
          this.employee.email = this.EmployeeData.user.email
          this.employee.Accounts[0].accountNumber = this.EmployeeData.accounts[0].accountNumber
          this.employee.Accounts[0].bank = this.EmployeeData.accounts[0].bank

          this.employee.Configuration.email = this.EmployeeData.user.email
          this.employee.Configuration.pwd = this.EmployeeData.configuration.pwd
         }
        console.log('this is data E', this.employee)
        console.log('this is prop E', this.EmployeeData)
        console.log('this is', this.employee.cityId)
      }
    },
    watch: {
      countrySelected: function () {
        console.log(this.countrySelected)
        this.employee.countryId = this.countrySelected.id
        this.GetState(this.countrySelected.iso2);
      },
      stateSelected: function () {
        console.log(this.stateSelected);
        this.GetCities(this.stateSelected.id)
      },
      EmployeeData: function () {
        this.setEmployee()
      }
    },
    mounted() {
      this.GetLanguages()
      this.GetCountry()
    }
  }
</script>

<style scoped>

</style>
