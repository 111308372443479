<template>
  <div>
    <div v-for="(item, i) in items" :key="i" v-intersect="onIntersect" :data-last="i === items.length - 1">
      <slot :item="item" :index="i"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfiniteScroll",
  props: {
    items: {
      type: Array,
      default: function () {
        return []
      }
    },
    lastPage: {
      type: Number,
      default: null
    },
    page: {
      type: Number,
      default: 1
    }
  },
  mounted() {
    this.$emit('loadMoreItems', this.page);
  },
  methods: {
    onIntersect(entries) {
      // If the last item intersects and theres more pages to load, emit 'loadMoreItems'
      if (entries[0].isIntersecting && entries[0].target.dataset.last && this.page < this.lastPage) {
        this.$emit('loadMoreItems', this.page + 1);
      }
    }

  }

}
</script>

<style scoped>

</style>
