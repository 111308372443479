<template>
  <div>
    <v-card elevation="0" tile class="pt-10">
      <v-row justify="center">
        <v-col md="12">
          <v-container tile elevation="0">
            <template>
              <v-row justify="center">
                <v-col md="12">
                  <v-container>
                    <v-data-table
                        :hide-default-footer="true"
                        :pagination="options"
                        :headers="headers"
                        :items="Data"
                        :single-expand="singleExpand"
                        :expanded.sync="expanded"
                        item-key="name"
                        :search="search"
                    >



                      <template v-slot:item.urgent="{item}">
                        {{item.urgent ?'Si':'No'}}
                      </template>

                      <template v-slot:item.action="{item}">
                            <v-btn  text ripple @click="Proceed(item.id)">
                                <v-icon color="primary">mdi-notebook-outline</v-icon>
                            </v-btn>

                          </template>
                          <span>Detalles</span>
                      <template v-slot:item.salesManager="{item}">
                        {{item.salesManager ? `${item.salesManager.firstName} ${item.salesManager.lastName}` : ''}}
                      </template>
                      <template v-slot:item.pricer="{item}">
                        {{item.pricer ? `${item.pricer.firstName} ${item.pricer.lastName}` : ''}}
                      </template>
                      <template v-slot:item.status = "{item}">
                        <v-row>
                          <v-col class="pb-0" md="10">
                            <v-select
                                v-model="OrderStatusID"
                                outlined
                                dense
                                label="Cambiar Status"
                                @change="showModal(item.id, OrderStatusID)"
                                :items="OrderStatus"
                                item-value="id"
                                item-text="name"
                                class="mt-3"
                            >

                            </v-select>
                          </v-col>
                        </v-row>
                      </template>


                    </v-data-table>
                    <v-pagination
                        v-model="options.page"
                        :length="MetaData.totalPages"
                        @input="GetData()"
                        @next="GetData()"
                        @previous="GetData()"
                    ></v-pagination>
                  </v-container>
                </v-col>
              </v-row>
            </template>
          </v-container>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog
        v-model="PendingModal"
        width="1000"
    >
      <div>


        <v-toolbar
            dark
            class="primary white--text"
        >
          <h2 class="font-weight-regular"><v-icon left size="25">mdi-comment-text-outline</v-icon>Comentarios</h2>


          <v-spacer></v-spacer>
          <v-btn
              icon
              dark
              @click="requestModal = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>


        <v-card>
          <v-card-text>
            <br>
            <h2 class="font-weight-bold text-center">¿Porque se solicita a Proveedor?</h2>
            <br>
            <v-row>
              <v-col md="4">
                <v-select
                    v-model="Refuse.orderReasonId"
                    class="ml-6"
                    dense
                    outlined
                    :items="RefuseReasons"
                    item-value="Id"
                    item-text="Reasons"
                >
                </v-select>
              </v-col>
              <v-col md="10">
                <v-textarea
                    v-model="Refuse.comments"
                    outlined
                    class="ml-6 pa-0"
                    name="input-7-1"
                    label="Comentarios"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="2">
                <v-btn color="primary" class="ml-6" @click="SendPending()">
                  Enviar Comentarios
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </v-dialog>

    <v-dialog
        v-model="ChangeStatus"
        width="1000"
    >
      <div>


        <v-toolbar
            dark
            class="primary white--text"
        >
          <h2 class="font-weight-regular"><v-icon left size="25">mdi-comment-text-outline</v-icon>Comentarios</h2>


          <v-spacer></v-spacer>
          <v-btn
              icon
              dark

          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>


        <v-card>
          <v-card-text>
            <br>
            <h2 class="font-weight-bold text-center">¿Porque la necesidad de regresar la cotización?</h2>
            <br>
            <v-row>
              <v-col md="4">
                <v-select
                    v-model="Refuse.orderReasonId"
                    class="ml-6"
                    dense
                    outlined
                    :items="RefuseReasons"
                    item-value="Id"
                    item-text="Reasons"
                >
                </v-select>
              </v-col>
              <v-col md="10">
                <v-textarea
                    v-model="Refuse.comments"
                    outlined
                    class="ml-6 pa-0"
                    name="input-7-1"
                    label="Comentarios"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="2">
                <v-btn color="primary" class="ml-6" @click="SendSales()">
                  Enviar Comentarios
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: "Request",
  props:{
    id:{
      type:Number
    }
  },
  watch: {
    '$store.state.filterPricingColumns': {
      handler: function (value) {
        this.headers = value
      },
      deep: true
    },
  },
  data(){
    return{
      ChangeStatus:null,
      PendingModal:null,
      QuantityStatus:0,
      e1:1,
      MetaData:{},
      options:{
        page: 1,
        itemsPerPage: 10,
        pageStart: 0,
        pageStop: 0,
        pageCount: 0,
        itemsLength: 0
      },
      newFees:false,
      OrderStatusID:null,
      RefuseReasons: [{Id: 1, Reasons: 'No hay disponibilidad', Status: true},
        {Id: 2, Reasons: 'No hay informacion', Status: true},
        {Id: 3, Reasons: 'No hay Ruta', Status: true}],
      Refuse:{
        orderId:null,
        comments:null,
        orderReasonId:1
      },
      OrderStatus:[
        {
          id:1,
          name:'Pendiente a Pricing'
        },

        {
          id:2,
          name:'Enviado a Ventas'
        },
      ],
      DeNuevo:false,
      items: [
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me 2' },
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me 2' }
      ],
      renegotiation:{
        id:null,
      },
      Data:[],
      headers: [],
      testdialog:false,
      emaildialog:false,
      search:"",
      expanded: [],
      singleExpand: false,
      idOrder:null
    }
  },
  methods:{
    GetData:function (){
      this.$store.dispatch('working',true);

      this.$http.get('Orders/requested',
          {
            params:{
              PageSize:this.options.itemsPerPage,
              PageNumber:this.options.page,
            }
          }).then(response => {
        if(response.data.code == 200){
          let res = response.data.data;
          this.Data = res.map(item => {
            return {
              ...item,
              departureDate: this.$luxon(item.departureDate, {output: "full"}),
              createdAt: this.$luxon(item.createdAt, {output: {
                format: "short",
                locale: "es-MX"
              }}),
            }
          })
          this.MetaData = response.data.meta;
          console.log(this.MetaData);
          console.log(this.Data);
        }

        if (response.data.code == 400 || response.data.code == 500)
          this.$toast.error("Algo salio mal, intente mas tarde")
      }).catch(error => {

        console.log(error);
      }).finally(()=>{
        this.$store.dispatch('working',false);
      })
    },
    showModal:function (id,Status) {
      this.id = id;

      if (Status == 1){
        this.PendingModal = true;
        this.Refuse.orderId = id;
      }

      if (Status == 2){
        this.SendSales();
      }
    },
    Proceed:function (id){
      this.dialog = true;
      this.id = id;
      this.$store.dispatch("idOrder", this.id)
      this.$router.push({path: `ProceedQuote/${this.id}`})

    },
    SendPending:function () {

      this.renegotiation.id = this.id;
      this.$store.dispatch('working', true)
      this.$http.put(`Orders/renegotiation/${this.id}`,this.Refuse).then(response => {
        if(response.data.code == 200){
          console.log(response.data.data);
          this.$toast.info("Enviado a pricing");
          this.PendingModal = false;
          this.GetData();
        }

        if (response.data.code == 400 || response.data.code == 500)
          this.$toast.error("Algo salio mal, intente mas tarde")
      }).catch(error=>{
        console.log(error)
        this.$toast.error("Ocurrio un error");
        console.log(error)
      }).finally(()=>{
        this.$store.dispatch('working',false);
      });
    },
    SendSales:function () {

      this.$store.dispatch('working', true)
      this.$http.post(`Orders/${this.id}/send/sales`).then(response => {
        if(response.data.code == 200){
          console.log(response.data.data);
          this.$toast.info("Enviado a ventas");
          this.ChangeStatus = false;
          this.GetData();
        }
        if (response.data.code == 400 || response.data.code == 500)
          this.$toast.error("Algo salio mal, intente mas tarde")
      }).catch(error=>{

        this.$toast.info("Ocurrio un error");
        console.log(error)
      }).finally(()=>{
        this.$store.dispatch('working',false);
      });
    },
    detail:function (id){
      console.log(id)
      this.emaildialog = true;
      this.idOrder = id;
    },
    UpdateItemData: function(data){
      console.log('Change data')
      this.Data.unshift(data);
      console.log(this.Data)
    }
  },
  mounted() {
    this.GetData();
    this.headers = this.$store.state.filterPricingColumns
  }
}
</script>

<style scoped>
>>> .container {
  min-width: 100%;
}
</style>
