<template>
  <div>
    <v-row
        no-gutters
        class="pl-0 w-100"
    >
      <v-col md="8" class="px-0">
        <v-card
            outlined
            class="elevation-0 w-100 rounded-sm my-2"
            v-for="(El, index) in Fees"
            :key="`PriceKey${index}`"
            v-click-outside="{
         handler: () => El.dense = true,
      }"
        >
          <v-container
              @click="El.dense ? El.dense = false : () => {}"
              class="rounded-sm"
          >
            <section
                v-if="!showRatableWeigth"
            >
              <v-row no-gutters>
                <v-col
                    class="my-auto text-center"
                >
                  <div class="d-flex">
                    <div class="pr-2">
                      <div v-if="El.confirmedPricing==true && El.confirmedSales==true && DataOrder.assignToPricer">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                color="green"
                                dark
                                v-bind="attrs"
                                v-on="on"
                            >
                              mdi-check-circle
                            </v-icon>
                          </template>
                          <span>Confirmado por Pricing</span>
                        </v-tooltip>

                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                color="green"
                                dark
                                v-bind="attrs"
                                v-on="on"
                            >
                              mdi-check-circle-outline
                            </v-icon>
                          </template>
                          <span>Confirmado por Pricing</span>
                        </v-tooltip>
                      </div>
                      <div v-if="El.confirmedSales==true && El.statusOrder == 'Renegociación'">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                color="green"
                                size="18"
                                dark
                                v-bind="attrs"
                                v-on="on"
                            >
                              mdi-check-circle-outline
                            </v-icon>
                          </template>
                          <span>Confirmado por Pricing</span>
                        </v-tooltip>

                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                color="green"
                                size="18"
                                dark
                                v-bind="attrs"
                                v-on="on"
                            >
                              mdi-checkbox-blank-circle-outline
                            </v-icon>
                          </template>
                          <span>Confirmado por Pricing</span>
                        </v-tooltip>

                      </div>
                      <div v-if="El.confirmedPricing == true && El.confirmedSales == false && DataOrder.assignToPricer">

                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                color="green"
                                size="18"
                                dark
                                v-bind="attrs"
                                v-on="on"
                            >
                              mdi-check-circle-outline
                            </v-icon>
                          </template>
                          <span>Confirmado por Pricing</span>
                        </v-tooltip>
                      </div>
                    </div>
                    <div class="ml-5">
                      <span class="font-weight-bold">Proveedor:&nbsp;</span>
                      <span>{{ El.supplierId > 0 ? El.supplier.companyName : '' }}</span>
                    </div>
                    <div class="ml-5">
                      <span class="font-weight-bold">Agente:&nbsp;</span>
                      <span>{{ El.forwarder ? El.forwarder.supplier.companyName : '' }}</span>
                    </div>
                  </div>
                </v-col>
                <v-col
                    md="5"
                    class="my-auto text-center"
                >
                  <div class="d-flex">
                    <span class="font-weight-bold">Ruta:&nbsp;</span>
                    <span>{{
                        El.pricesOrigins[0] ? (El.pricesOrigins[0].port ? El.pricesOrigins[0].port.name : El.pricesOrigins[0].city) : 'NA'
                      }}</span>
                    <span class="px-1">-</span>
                    <span>{{
                        El.pricesDestination[0] ? (El.pricesDestination[0].port ? El.pricesDestination[0].port.name : El.pricesDestination[0].city) : 'NA'
                      }}</span>
                  </div>
                </v-col>
                <v-col
                    md="2"
                    class="d-flex"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon @click="El.showAdditionalData = !El.showAdditionalData" class="px-2" v-bind="attrs"
                              v-on="on">mdi-information-outline
                      </v-icon>
                    </template>
                    <span>Información Adicional</span>
                  </v-tooltip>
                  <v-menu
                      offset-y
                  >
                    <template v-slot:activator="{on: menu}">
                      <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip, attrs }">
                          <v-icon v-bind="attrs" class="px-2" v-on="{ ...tooltip, ...menu }">mdi-file-document</v-icon>
                        </template>
                        <span>Documentos</span>
                      </v-tooltip>
                    </template>
                    <v-card flat tile width="500">
                      <v-card-title class="heading">
                        <v-list-item dense>
                          <v-list-item-content>
                            Documentos
                          </v-list-item-content>
                          <v-list-item-action v-if="El.priceDocuments.length ">
                            <v-btn small color="primary">
                              Descargar todos
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </v-card-title>
                      <v-card-text>
                        <span v-if="El.priceDocuments.length === 0">
                          No hay documentos.
                        </span>
                        <v-virtual-scroll
                            :items="El.priceDocuments"
                            height="300"
                            item-height="64"
                            v-else
                        >
                          <template v-slot:default="{item}">
                            <v-list-item :key="item.id" @click="downloadDocument(item)" dense>
                              <v-list-item-content>
                                <v-list-item-title class="text-wrap">
                                  {{ item.fileName }}
                                </v-list-item-title>
                              </v-list-item-content>
                              <v-list-item-action>
                                <v-btn icon color="primary">
                                  <v-icon>mdi-cloud-download</v-icon>
                                </v-btn>
                              </v-list-item-action>
                            </v-list-item>
                            <v-divider></v-divider>
                          </template>
                        </v-virtual-scroll>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="primary" class="px-2" @click="AddQuote(El.id)" v-bind="attrs" v-on="on">mdi-send
                      </v-icon>
                    </template>
                    <span>Enviar</span>
                  </v-tooltip>

                  <div v-if="El.isUpdating">
                    <v-icon class="px-2">
                      mdi-sync
                    </v-icon>
                    <span
                        class="text-caption"
                    >Guardando...</span>
                  </div>
                  <div v-else>
                    <v-icon class="px-2">
                      mdi-cloud-check-outline
                    </v-icon>
                  </div>

                </v-col>
              </v-row>
              <v-row
                  v-if="El.showAdditionalData"
                  no-gutters
                  class="w-100 d-block my-1"
              >
                <div class="rounded-lg grey lighten-2 w-100 py-2">
                  <v-row no-gutters class="w-100 my-1 mx-2">
                    <v-col md="3" v-if="El.validity">
                      <span class="text-caption font-weight-bold">Vigencia: </span><span
                        class="text-caption">{{ El.validity }}</span>
                    </v-col>
                    <v-col md="3" v-if="El.departureTime">
                      <span class="text-caption font-weight-bold">Salida: </span><span
                        class="text-caption">{{ El.departureTime }}</span>
                    </v-col>
                  </v-row>
                  <v-row class="w-100 my-1 mx-2" v-html="generateAdditionalDataForPrice(index)" no-gutters>
                  </v-row>
                  <v-row>
                    <v-col>
                      <h5>Comentarios del pricer</h5>
                      <pre>
                        {{ El.comments }}
                      </pre>
                    </v-col>
                  </v-row>
                </div>
              </v-row>
              <v-row
                  justify="center"
                  no-gutters
              >
                <v-col md="12" class="pa-2">
                  <section
                      v-if="isPriceInQuotation(El.id) == null"
                  >
                    <v-simple-table
                        :dense="El.dense"
                    >
                      <template v-slot:default>
                        <thead>
                        <th class="text-left">
                          Cargo
                        </th>

                        <th class="text-left">
                          Price
                        </th>

                        <th class="text-left">
                          Quantity
                        </th>

                        <th class="text-left">
                          Total
                        </th>

                        <th class="text-left">
                          Moneda
                        </th>

                        <th class="text-left" v-if="DataOrder.transportationCategoryId != 1">
                          Contenedor
                        </th>

                        <th class="text-left">

                        </th>

                        </thead>
                        <tbody>
                        <tr
                            v-for="(item) in El.priceFees"
                            :key="`priceFees${item.id}`"
                            :class="item.isVisible ? '' :'text--disabled'"
                            class="table-fee-row"
                        >
                          <td>
                            <v-text-field
                                readonly
                                :disabled="item.isVisible ? false:true"
                                :value="item.name"
                                v-model="item.name"
                                class="table-fee-input"
                                rounded
                                hide-details
                            ></v-text-field>
                          </td>

                          <td>
                            <v-text-field
                                readonly
                                :disabled="item.isVisible ? false:true"
                                :value="item.unitPrice"
                                @change="calculateTableTotal"
                                v-model="item.unitPrice"
                                class="table-fee-input"
                                rounded
                                hide-details
                            ></v-text-field>
                          </td>

                          <td>
                            <v-text-field
                                readonly
                                :disabled="item.isVisible ? false:true"
                                :value="item.quantity"
                                @change="calculateTableTotal"
                                v-model="item.quantity"
                                class="table-fee-input"
                                rounded
                                hide-details
                            ></v-text-field>
                          </td>

                          <td>
                            <v-text-field
                                readonly
                                rounded
                                :disabled="item.isVisible ? false:true"
                                type="number"
                                :value="item.subtotal"
                                v-model="item.subtotal"
                                class="table-fee-input"
                                hide-details
                            ></v-text-field>
                          </td>

                          <td>
                            <span>{{ item.currency ? item.currency.code : '-' }}</span>
                          </td>

                          <td v-if="DataOrder.transportationCategoryId != 1">
                            <span>{{ El.container ? El.container.description : '-' }}</span>
                          </td>

                          <!--                          <td class="table-actions-row">-->
                          <!--                            <v-icon-->
                          <!--                                color="primary"-->
                          <!--                                disabled-->
                          <!--                            >{{ item.isVisible ? 'mdi-eye' : 'mdi-eye-off' }}-->
                          <!--                            </v-icon>-->
                          <!--                          </td>-->
                        </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </section>
                  <section
                      v-else
                  >
                    <QuotationDetails
                        :dense="El.dense"
                        :colorVis="visbleByClient"
                        v-model="QuotationsData.quotationDetails[isPriceInQuotation(El.id)]"
                        :order="DataOrder"
                        :container="El.container ? El.container.description : '-'"
                        @change="setFeesAndUpdateQuotation"
                        @setVisible="setQuotationFeeVisibleAndUpdate"

                    ></QuotationDetails>
                  </section>
                </v-col>
              </v-row>
              <v-expand-transition v-if="QuotationsData.quotationDetails.length > 0">
                <div v-if="QuotationsData.quotationDetails.findIndex(x => x.orderPriceId === El.id) !== -1">
                  <v-row
                      justify="center"
                      no-gutters
                      v-if="El.showTotalPrice"
                  >
                    <v-col md="3">
                      <v-text-field
                          label="Profit"
                          class="pa-0"
                          type="number"
                          v-model="QuotationsData.quotationDetails[QuotationsData.quotationDetails.findIndex(x => x.orderPriceId === El.id)].profit"
                          @input="calculateQuoteDetailProfit(QuotationsData.quotationDetails.findIndex(x => x.orderPriceId === El.id),true)"
                          dense
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                          label="Profit %"
                          class="pa-0"
                          type="number"
                          v-model="QuotationsData.quotationDetails[QuotationsData.quotationDetails.findIndex(x => x.orderPriceId === El.id)].profitPercentage"
                          @input="calculateQuoteDetailProfitPercentage(QuotationsData.quotationDetails.findIndex(x => x.orderPriceId === El.id),true)"
                          dense
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                          label="Discount"
                          class="pa-0"
                          type="number"
                          v-model="QuotationsData.quotationDetails[QuotationsData.quotationDetails.findIndex(x => x.orderPriceId === El.id)].discount"
                          @input="calculateQuoteDetailDiscount(QuotationsData.quotationDetails.findIndex(x => x.orderPriceId === El.id),true)"
                          dense
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                          label="Discount %"
                          class="pa-0"
                          type="number"
                          v-model="QuotationsData.quotationDetails[QuotationsData.quotationDetails.findIndex(x => x.orderPriceId === El.id)].discountPercentage"
                          @input="calculateQuoteDetailDiscountPercentage(QuotationsData.quotationDetails.findIndex(x => x.orderPriceId === El.id),true)"
                          dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <span v-if="El.showTotalPrice" class="font-weight-regular">Total: {{
                      QuotationsData.quotationDetails[QuotationsData.quotationDetails.findIndex(x => x.orderPriceId === El.id)].total
                    }} </span>
                </div>
                <!--                <div v-else>
                                  <span v-if="El.showTotalPrice" class="font-weight-regular">Total: {{ El.total }} </span>
                                </div>-->
              </v-expand-transition>
            </section><!-- Container Cargo Price Sections -->
            <section
                v-else
            >
              <v-row no-gutters>
                <v-col
                    class="my-auto text-center"
                >
                  <div class="d-flex">
                    <div class="pr-2">
                      <div v-if="El.confirmedPricing==true && El.confirmedSales==true && DataOrder.assignToPricer">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                color="green"
                                dark
                                v-bind="attrs"
                                v-on="on"
                            >
                              mdi-check-circle
                            </v-icon>
                          </template>
                          <span>Confirmado por Pricing</span>
                        </v-tooltip>

                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                color="green"
                                dark
                                v-bind="attrs"
                                v-on="on"
                            >
                              mdi-check-circle-outline
                            </v-icon>
                          </template>
                          <span>Confirmado por Pricing</span>
                        </v-tooltip>
                      </div>
                      <div v-if="El.confirmedSales==true && El.statusOrder == 'Renegotiation'">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                color="green"
                                size="18"
                                dark
                                v-bind="attrs"
                                v-on="on"
                            >
                              mdi-check-circle-outline
                            </v-icon>
                          </template>
                          <span>Confirmado por Pricing</span>
                        </v-tooltip>

                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                color="green"
                                size="18"
                                dark
                                v-bind="attrs"
                                v-on="on"
                            >
                              mdi-checkbox-blank-circle-outline
                            </v-icon>
                          </template>
                          <span>Confirmado por Pricing</span>
                        </v-tooltip>

                      </div>
                      <div v-if="El.confirmedPricing == true && El.confirmedSales == false && DataOrder.assignToPricer">

                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                color="green"
                                size="18"
                                dark
                                v-bind="attrs"
                                v-on="on"
                            >
                              mdi-check-circle-outline
                            </v-icon>
                          </template>
                          <span>Confirmado por Pricing</span>
                        </v-tooltip>
                      </div>
                    </div>
                    <div class="ml-5">
                      <span class="font-weight-bold">Proveedor:&nbsp;</span>
                      <span>{{ El.supplierId > 0 ? El.supplier.companyName : '' }}</span>
                    </div>
                    <div class="ml-5">
                      <span class="font-weight-bold">Agente:&nbsp;</span>
                      <span>{{ El.forwarder ? El.forwarder.supplier.companyName : '' }}</span>
                    </div>
                  </div>
                </v-col>
                <v-col
                    md="5"
                    class="my-auto text-center"
                >
                  <div class="d-flex">
                    <span class="font-weight-bold">Ruta:&nbsp;</span>
                    <span>{{
                        El.pricesOrigins[0] ? (El.pricesOrigins[0].port ? El.pricesOrigins[0].port.name : El.pricesOrigins[0].city) : 'NA'
                      }}</span>
                    <span class="px-1">-</span>
                    <span>{{
                        El.pricesDestination[0] ? (El.pricesDestination[0].port ? El.pricesDestination[0].port.name : El.pricesDestination[0].city) : 'NA'
                      }}</span>
                  </div>
                </v-col>
                <v-col
                    md="2"
                    class="d-flex"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon @click="El.showAdditionalData = !El.showAdditionalData" class="px-2" v-bind="attrs"
                              v-on="on">mdi-information-outline
                      </v-icon>
                    </template>
                    <span>Información Adicional</span>
                  </v-tooltip>
                  <v-menu
                      offset-y
                  >
                    <template v-slot:activator="{on: menu}">
                      <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip, attrs }">
                          <v-icon v-bind="attrs" class="px-2" v-on="{ ...tooltip, ...menu }">mdi-file-document</v-icon>
                        </template>
                        <span>Documentos</span>
                      </v-tooltip>
                    </template>
                    <v-card flat tile width="500">
                      <v-list-item dense>
                        <v-list-item-content>
                          <v-list-item-title class="heading">
                            Documentos
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action v-if="El.priceDocuments.length ">
                          <v-btn icon color="primary" @click="downloadAll(El)">
                            <v-icon>mdi-download-multiple</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-card-text>
                        <span v-if="El.priceDocuments.length === 0">
                          No hay documentos.
                        </span>
                        <v-virtual-scroll
                            :items="El.priceDocuments"
                            height="300"
                            item-height="64"
                            v-else
                        >
                          <template v-slot:default="{item}">
                            <v-list-item :key="item.id" @click="downloadDocument(item)" dense>
                              <v-list-item-content>
                                <v-list-item-title class="text-wrap">
                                  {{ item.fileName }}
                                </v-list-item-title>
                              </v-list-item-content>
                              <v-list-item-action>
                                <v-btn icon color="primary">
                                  <v-icon>mdi-cloud-download</v-icon>
                                </v-btn>
                              </v-list-item-action>
                            </v-list-item>
                            <v-divider></v-divider>
                          </template>
                        </v-virtual-scroll>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="primary" class="px-2" @click="AddQuote(El.id)" v-bind="attrs" v-on="on">mdi-send
                      </v-icon>
                    </template>
                    <span>Enviar</span>
                  </v-tooltip>

                  <div v-if="El.isUpdating && !El.hasError">
                    <v-icon class="px-2">
                      mdi-sync
                    </v-icon>
                    <span
                        class="text-caption"
                    >Guardando...</span>
                  </div>
                  <div v-else-if="El.hasError">
                    <v-icon
                        color="red"
                        class="px-2"
                    >
                      mdi-sync
                    </v-icon>
                    <span
                        class="text-caption"
                    >Error...</span>
                  </div>
                  <div v-else>
                    <v-icon class="px-2">
                      mdi-cloud-check-outline
                    </v-icon>
                  </div>

                </v-col>
              </v-row>
              <v-row
                  v-if="El.showAdditionalData"
                  no-gutters
                  class="w-100 d-block my-1"
              >
                <div class="rounded-lg grey lighten-2 w-100 py-2">
                  <v-row no-gutters class="w-100 my-1 mx-2">
                    <v-col md="3" v-if="El.validity">
                      <span class="text-caption font-weight-bold">Vigencia: </span><span
                        class="text-caption">{{ El.validity }}</span>
                    </v-col>
                    <v-col md="3" v-if="El.departureTime">
                      <span class="text-caption font-weight-bold">Salida: </span><span
                        class="text-caption">{{ El.departureTime }}</span>
                    </v-col>
                  </v-row>
                  <v-row class="w-100 my-1 mx-2" v-html="generateAdditionalDataForPrice(index)" no-gutters>
                  </v-row>
                  <v-row>
                    <v-col>
                      <h5>Comentarios del pricer</h5>
                      <pre>
                        {{ El.comments }}
                      </pre>
                    </v-col>
                  </v-row>
                </div>
              </v-row>
              <v-row
                  justify="center"
                  no-gutters
              >
                <v-col md="12" class="pa-2">
                  <section
                      v-if="isPriceInQuotation(El.id) == null"
                  >
                    <v-simple-table :dense="El.dense">
                      <template v-slot:default>
                        <thead>
                        <th class="text-left">
                          Cargos
                        </th>

                        <th class="text-left">
                          Price
                        </th>

                        <th class="text-left">
                          Ratable Weight
                        </th>

                        <th class="text-left">
                          Total
                        </th>

                        <th class="text-left">
                          Moneda
                        </th>


                        <th class="text-left">
                        </th>

                        </thead>
                        <tbody>
                        <tr
                            v-for="(item) in El.priceFees"
                            :key="`priceFees${item.id}`"
                            :class="item.isVisible ? '' :'text--disabled'"
                            class="table-fee-row"
                        >

                          <td>
                            <v-text-field
                                readonly
                                :disabled="item.isVisible ? false:true"
                                :value="item.name"
                                v-model="item.name"
                                class="table-fee-input"
                                rounded
                                hide-details
                            ></v-text-field>
                          </td>

                          <td>
                            <v-text-field
                                readonly
                                :disabled="item.isVisible ? false:true"
                                :value="item.unitPrice"
                                @change="calculateTableTotal"
                                v-model="item.unitPrice"
                                class="table-fee-input"
                                rounded
                                hide-details
                            ></v-text-field>
                          </td>

                          <td>
                            <span v-if="item.ratableWeight != ratableWeightDef" class="red--text">
                              {{ item.ratableWeight }}
                            </span>

                            <span v-else>
                              {{ item.ratableWeight }}
                            </span>
                          </td>

                          <td>
                            <v-text-field
                                readonly
                                rounded
                                :disabled="item.isVisible ? false:true"
                                type="number"
                                :value="item.subtotal"
                                v-model="item.subtotal"
                                class="table-fee-input"
                                hide-details
                            ></v-text-field>
                          </td>

                          <td>
                            <span>{{ item.currency ? item.currency.code : '-' }}</span>
                          </td>

                          <!--                          <td class="table-actions-row">
                                                      <v-icon
                                                          color="primary"
                                                          disabled
                                                      >{{ item.isVisible ? 'mdi-eye' : 'mdi-eye-off' }}
                                                      </v-icon>
                                                    </td>-->
                        </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </section>
                  <section v-else>
                    <QuotationDetails
                        :colorVis="visbleByClient"
                        :dense="El.dense"
                        v-model="QuotationsData.quotationDetails[isPriceInQuotation(El.id)]"
                        :order="DataOrder"
                        :container="El.container ? El.container.description : '-'"
                        @change="setFeesAndUpdateQuotation"
                        @setVisible="setQuotationFeeVisibleAndUpdate"
                    ></QuotationDetails>
                  </section>
                </v-col>
              </v-row>
              <v-expand-transition v-if="QuotationsData.quotationDetails.length > 0">
                <div v-if="QuotationsData.quotationDetails.findIndex(x => x.orderPriceId === El.id) !== -1">
                  <v-row
                      justify="center"
                      no-gutters
                      v-if="El.showTotalPrice"
                  >
                    <v-col md="3">
                      <v-text-field
                          label="Profit este"
                          class="pa-0"
                          type="number"
                          v-model="QuotationsData.quotationDetails[QuotationsData.quotationDetails.findIndex(x => x.orderPriceId === El.id)].profit"
                          @input="calculateQuoteDetailProfit(QuotationsData.quotationDetails.findIndex(x => x.orderPriceId === El.id),true)"
                          dense
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                          label="Profit %"
                          class="pa-0"
                          type="number"
                          v-model="QuotationsData.quotationDetails[QuotationsData.quotationDetails.findIndex(x => x.orderPriceId === El.id)].profitPercentage"
                          @input="calculateQuoteDetailProfitPercentage(QuotationsData.quotationDetails.findIndex(x => x.orderPriceId === El.id),true)"
                          dense
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                          label="Discount"
                          class="pa-0"
                          type="number"
                          v-model="QuotationsData.quotationDetails[QuotationsData.quotationDetails.findIndex(x => x.orderPriceId === El.id)].discount"
                          @input="calculateQuoteDetailDiscount(QuotationsData.quotationDetails.findIndex(x => x.orderPriceId === El.id),true)"
                          dense
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                          label="Discount %"
                          class="pa-0"
                          type="number"
                          v-model="QuotationsData.quotationDetails[QuotationsData.quotationDetails.findIndex(x => x.orderPriceId === El.id)].discountPercentage"
                          @input="calculateQuoteDetailDiscountPercentage(QuotationsData.quotationDetails.findIndex(x => x.orderPriceId === El.id),true)"
                          dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <span v-if="El.showTotalPrice" class="font-weight-regular">Total: {{
                      QuotationsData.quotationDetails[QuotationsData.quotationDetails.findIndex(x => x.orderPriceId === El.id)].total
                    }} </span>
                </div>
                <!--                <div v-else>
                                  <span v-if="El.showTotalPrice" class="font-weight-regular">Total: {{ El.total }} </span>
                                </div>-->
              </v-expand-transition>
            </section><!-- Pallet Cargo Price Sections -->
          </v-container>

        </v-card>
      </v-col>
      <v-col md="4" class="px-0">
        <v-row>
          <v-col md="12">
            <v-card
                style="border-radius: 5px"
                outlined
                elevation="0"
            >
              <v-spacer></v-spacer>
              <v-tabs color="primary" background-color="primary" dark v-model="tabs">
                <v-tab>Resumen</v-tab>
                <v-tab>Historial</v-tab>
              </v-tabs>
              <v-tabs-items v-model="tabs">
                <v-tab-item class="ma-2">
                  <v-row no-gutters v-if="QuotationsData.quotationDetails">

                    <v-col md="1" v-if="QuotationsData.quotationDetails.length > 0">
                      <!--                      <v-tooltip top>
                                              <template v-slot:activator="{ on, attrs }">
                                                <v-icon @click="showPreview" v-bind="attrs" v-on="on" class="mr-2">mdi-file-find</v-icon>
                                              </template>
                                              <span>Vista Previa</span>
                                            </v-tooltip>-->
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon @click="GeneratePdf" v-bind="attrs" v-on="on">mdi-file</v-icon>
                        </template>
                        <span>Generar Pdf</span>
                      </v-tooltip>
                    </v-col>

                    <v-col md="3">
                      <div v-if="QuotationsData.isUpdating">
                        <v-icon>
                          mdi-sync
                        </v-icon>
                        <span
                            class="text-caption"
                        >Guardando...</span>
                      </div>
                      <div v-else>
                        <v-icon>
                          mdi-cloud-check-outline
                        </v-icon>
                      </div>
                    </v-col>

                    <v-col md="3" :key="keyBtn">
                      <v-btn v-if="showButtonSale" text color="primary" @click="getDialog" >VENDER</v-btn>
                    </v-col>
                    <v-col md="3">
                      <v-btn v-if="showButtonSale" @click="getDialog2"  text color="primary">Enviar a cliente</v-btn>
                    </v-col>

                    <!--                      <v-col sm="3" offset="3">
                                          <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon @click="BuildModal=true" v-bind="attrs" v-on="on">mdi-table-edit</v-icon>

                                            </template>
                                            <span>Preview</span>
                                          </v-tooltip>
                                        </v-col>-->
                  </v-row>
                  <v-row
                      no-gutters
                      class="w-100 my-2"
                  >
                    <v-simple-table
                        id="quotationTable"
                        v-sortable-data-table
                        @sorted="saveOrder"
                    >
                      <template v-slot:default>
                        <thead>
                        <th class="text-left">
                          Proveedor
                        </th>
                        <th v-if="showTotalPrice == false" class="text-left">
                          Compras
                        </th>

                        <th v-if="showTotalPrice == true" class="text-left">
                          Compra
                        </th>

                        <th v-if="showTotalPrice == true" class="text-left">
                          Profit $
                        </th>
                        <th v-if="showTotalPrice == true" class="text-left">
                          Venta
                        </th>

                        <th v-if="showTotalPrice == false" class="text-left">
                          Profits $
                        </th>
                        <th v-if="showTotalPrice == false" class="text-left">
                          Ventas
                        </th>

                        <th class="text-left" >

                        </th>
                        </thead>
                        <tbody>
                        <tr v-for="(resQ, index) in QuotationsData.quotationDetails"
                            :key="`quoteData${resQ.id}key${index}`"
                            :class="resQ.isVisible ? '' :'text--disabled'"
                        >
                          <td class="pa-0">
                            {{ resQ.price.supplier.companyName }}
                          </td>

                          <td class="pa-0" v-if="resQ.price.showTotalPrice==true">
                            {{ resQ.subtotal }}
                          </td>

                          <td class="pa-0" v-if="resQ.price.showTotalPrice==false">
                            <v-menu
                                offset-y
                            >
                              <template v-slot:activator="{on: menu}">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on: tooltip, attrs }">
                                    <v-icon v-bind="attrs" class="px-2" v-on="{ ...tooltip, ...menu }">mdi-notebook
                                    </v-icon>
                                  </template>
                                  Compra
                                </v-tooltip>
                              </template>
                              <v-card flat tile width="300">
                                <v-card-text>
                                  <v-row>
                                    <v-col md="6">
                                      <span class="font-weight-bold black--text">Compra</span>
                                    </v-col>
                                    <v-col md="6">
                                      <span class="font-weight-bold black--text">Moneda</span>
                                    </v-col>
                                  </v-row>

                                  <div v-for="ResElement in resQ.fees" :key="ResElement.id">

                                    <v-row>
                                      <v-col md="6">
                                        <span class="black--text">{{ ResElement.total }}</span>
                                      </v-col>
                                      <v-col md="6">
                                        <span class="black--text">{{ ResElement.currency.code }}</span>
                                      </v-col>
                                    </v-row>
                                  </div>

                                </v-card-text>

                              </v-card>
                            </v-menu>
                          </td>
                          <td v-if="resQ.price.showTotalPrice == true" class="pa-0 px-3">
                            {{ resQ.profit }}
                          </td>
                          <td v-if="resQ.price.showTotalPrice == true" class="pa-0">
                            {{ resQ.total }}
                          </td>

                          <td v-if="resQ.price.showTotalPrice == false" class="pa-0">
                            <v-menu
                                offset-y
                            >
                              <template v-slot:activator="{on: menu}">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on: tooltip, attrs }">
                                    <v-icon v-bind="attrs" class="px-2" v-on="{ ...tooltip, ...menu }">
                                      mdi-cash-multiple
                                    </v-icon>
                                  </template>
                                  Profit
                                </v-tooltip>
                              </template>
                              <v-card flat tile width="300">
                                <v-card-text>
                                  <v-row>
                                    <v-col md="6">
                                      <span class="font-weight-bold black--text">Profit</span>
                                    </v-col>
                                    <v-col md="6">
                                      <span class="font-weight-bold black--text">Moneda</span>
                                    </v-col>
                                  </v-row>

                                  <div v-for="ResElement in resQ.fees" :key="ResElement.id">

                                    <v-row>
                                      <v-col md="6">
                                        <span class="black--text">{{ ResElement.profit }}</span>
                                      </v-col>
                                      <v-col md="6">
                                        <span class="black--text">{{ ResElement.currency.code }}</span>
                                      </v-col>
                                    </v-row>
                                  </div>

                                </v-card-text>

                              </v-card>
                            </v-menu>
                          </td>
                          <td v-if="resQ.price.showTotalPrice == false" class="pa-0">
                            <v-menu
                                offset-y
                            >
                              <template v-slot:activator="{on: menu}">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on: tooltip, attrs }">
                                    <v-icon color="black" v-bind="attrs" class="px-2" v-on="{ ...tooltip, ...menu }">
                                      mdi-currency-usd
                                    </v-icon>
                                  </template>
                                  Venta
                                </v-tooltip>
                              </template>
                              <v-card flat tile width="300">
                                <v-card-text>
                                  <v-row>
                                    <v-col md="6">
                                      <span class="font-weight-bold black--text">Venta</span>
                                    </v-col>
                                    <v-col md="6">
                                      <span class="font-weight-bold black--text">Moneda</span>
                                    </v-col>
                                  </v-row>

                                  <div v-for="ResElement in resQ.fees" :key="ResElement.id">

                                    <v-row>
                                      <v-col md="6">
                                        <span class="black--text">{{ ResElement.subtotal }}</span>
                                      </v-col>
                                      <v-col md="6">
                                        <span class="black--text">{{ ResElement.currency.code }}</span>
                                      </v-col>
                                    </v-row>
                                  </div>

                                </v-card-text>

                              </v-card>
                            </v-menu>
                          </td>

                          <td style="max-width: 30px;" v-if="DataOrder.statusOrder !== 'Vendida'" >
                            <v-icon @click="DeletePrice(resQ.orderPriceId,resQ.quotationId)" color="red">mdi-minus
                            </v-icon>
                            <!--                            <v-icon color="primary" @click="setQuotationDetailVisible(index)">
                                                          {{ resQ.isVisible ? 'mdi-eye' : 'mdi-eye-off' }}
                                                        </v-icon>-->
                          </td>

                        </tr>
                        <!--                        <v-divider v-if="QuotationsData.quotationDetails[index + 1]"></v-divider>-->
                        </tbody>
                      </template>

                    </v-simple-table>

                  </v-row>
                  <span v-if="showResumeTotal" class="font-weight-regular">Total: {{ ResumeTotal }} </span>
                </v-tab-item>
                <v-tab-item>
                  <OrderHistory :idOrder="Id"></OrderHistory>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" width="80%"
              v-on:sellOrder="sellOrder = $event"
              v-on:sellDataOrder="recibeDataOrder"
              v-on:closeDialog="dialog= $event"
    >
      <v-card>
        <v-toolbar flat dark color="primary">
          <v-toolbar-title><v-icon left>mdi-table-edit</v-icon> Registrar Embarque </v-toolbar-title>
          <v-spacer></v-spacer>
          <div  @click="sellOrder2()">
            <TruckButton ref="Truck"></TruckButton>
          </div>
        </v-toolbar>
        <v-row>
          <v-col cols="6">
            <v-card class="ml-2 mt-2" >
              <v-card-title>
                Compra
                <v-spacer></v-spacer>
                <div class="mr-4">
                  Vencimiento: {{  infoQuotation.length === 0 ? 'N/A' : $luxon(infoQuotation[0].price.validity) }}
                </div>
               Total: ${{ getTotalCompra() }}
              </v-card-title>
              <v-data-table
                  v-for="(items,i) in infoQuotation"
                  :key="i"
                  :headers="headersCompra"
                  :items="items.price.priceFees"
              >
                <template v-slot:item.hasVat="{item}">
                  {{item.hasVat ? 'Si' : 'No'}}
                </template>
                <template v-slot:item.hasRet="{item}">
                  {{item.hasRet ? 'Si' : 'No'}}
                </template>

                <template v-slot:item.RatableWeight="{item}">
                  <span v-if="item.ratableWeight != null">
                    {{item.ratableWeight}}
                  </span>
                  <span v-else>
                    N/A
                  </span>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card class="mr-2 mt-2">
              <v-card-title>
                Venta
                <v-spacer></v-spacer>
                <div class="mr-4">

                    Profit - Total: ${{ getProfit() }}
                </div>
                <div class="mr-4">
                  Porcentaje: {{ getPorcentaje() }}%
                </div>
                Total: ${{ getTotalVenta() }}
              </v-card-title>
              <v-data-table
                  v-for="(items,i) in infoQuotation"
                  :key="i"
                  :headers="headersVenta"
                  :items="items.fees"
              >
                <template v-slot:item.currencyId="{item}">
                  <div v-if="item.currencyId === 1">
                    USD
                  </div>
                  <div v-if="item.currencyId === 34">
                    EUR
                  </div>
                  <div v-if="item.currencyId === 27">
                    MXN
                  </div>
                </template>
                <template v-slot:item.suma=" { item } ">
                  {{ item.unitPrice * item.quantity + item.profit }}
                </template>
                <template v-slot:item.hasVat="{item}">
                  {{item.hasVat ? 'Si' : 'No'}}
                </template>
                <template v-slot:item.hasRet="{item}">
                  {{item.hasRet ? 'Si' : 'No'}}
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <br>
        <v-divider></v-divider>
        <br>
        <v-form
            ref="shipment"
            lazy-validation>
          <v-row>
            <v-col cols="4">
              <v-card class="ml-2">
                <v-card-title>
                  Consignatario/cliente
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-row >
                    <v-col md="4">
                      <v-text-field label="Cliente (nombre de empresa)" dense
                                    :rules="ClientRules" v-model="shipment.ClientSupplierName"   item-value="id" item-text="name" >
                      </v-text-field>
                    </v-col>

                    <v-col md="4">
                      <v-text-field :rules="ClientContactRules" label="Contacto" dense v-model="shipment.ContactName"  item-value="id" item-text="name" >
                      </v-text-field>
                    </v-col>

                    <v-col md="4">
                      <v-text-field :rules="OrderClientRules" v-model="shipment.ReferenceNumber"  label="Orden de cliente" dense>
                      </v-text-field>
                    </v-col>


                  </v-row>
                  <v-row>
                    <v-col md="12">
                      <v-combobox multiple
                                  dense
                                  small-chips
                                  label="Correos de cliente, a quien contactar"
                                  hide-selected
                                  :rules="emailValid"
                                  :search-input:sync="searchEmails"
                                  @change="formatEmails"
                                  v-model="shipment.Mails">
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                Escribe un correo y presiona <kbd>enter</kbd> para agregarlo.
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-combobox>
                    </v-col>

                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card >
                <v-card-title>
                  Operación
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-row>
                    <v-col md="4">
                      <v-autocomplete label="Operador" dense :items="operators"
                                      :rules="OperatorRules"   v-model="shipment.OperatorId"  item-value="userId" :item-text="item => item.firstName +' '+ item.lastName" >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="6"  class="mt-2">
                      <v-radio-group
                          column
                          color="primary"
                          @change="clickNeedsPricer"
                          v-model="ispricer"
                      >
                        <v-radio
                            active-class="font-weight-black"
                            label="Sin Pricer"
                            :value="false"
                        ></v-radio>
                        <v-radio
                            active-class="font-weight-black"
                            label="Con Pricer"
                            :value="true"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card class="mr-2">
                <v-card-title>
                  Documentos
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-row>
                    <v-col md="10">
                      <v-textarea
                          outlined
                          v-model="shipment.Comments"
                          name="input-7-1"
                          label="Comentarios"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="12">
                      <file-drag v-model="shipment.DocumentDrag" @change="ConvertFile()"></file-drag>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>


      </v-card>
    </v-dialog>


    <v-dialog
        v-model="dialogQ"
        fullscreen
        hide-overlay
        persistent
        transition="dialog-bottom-transition"
    >
      <v-card v-if="EditOrder.folio && EditOrder.masterPrice"
              :key="dialogKey"
              :loading="previewLoading"
              :disabled="previewLoading">
        <v-toolbar
            flat
            dark
            color="primary"
        >
          <v-btn
              icon
              dark
              @click="closePreviewDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Vista Previa</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
                dark
                text
                @click="savePreviewChanges"
            >
              Guardar
            </v-btn>
            <v-btn
                dark
                text
                @click="generatePdfPreview"
            >
              Descargar
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card class="mx-auto"
                max-width="1250">
          <v-card-text>
            <v-row>
              <v-col
                  style="text-align:-webkit-right"
                  class="mr-5"
              >
                <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a3/Senator_International_Logo_Stand_2016.svg/1200px-Senator_International_Logo_Stand_2016.svg.png"
                    style="max-width:180px;">
                <br>
                <span>First class global logistics</span>
                <hr style=" width:32%; text-align:left; margin-right:5px; border-color:red; margin-top:0px; margin-bottom: 0px;">
              </v-col>
            </v-row>
            <v-row dense class="mt-2">
              <v-col cols="2">
                <h4 class="font-weight-regular">{{ EditOrder.folio ? EditOrder.folio : 'NA' }}</h4>
              </v-col>
              <v-col cols="2">
                <v-text-field label="Ref Cliente" v-model="EditOrder.refNumber" dense></v-text-field>
              </v-col>
              <v-col cols="2" offset="6">
                <h4 class="font-weight-regular text-right">
                  {{
                    new Date().toISOString() | luxon({
                      output: {
                        zone: "local",
                        format: "date_short"
                      }
                    })
                  }}
                </h4>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card>
                  <v-card-text>
                    <v-row>
                      <v-col cols="3">
                        <v-card-text>
                          <v-img :src="companyLogo" contain class="mb-6" height="128">
                            <v-btn
                                color="error"
                                class="text-none mr-2"
                                fab
                                depressed
                                small
                                :loading="isSelecting"
                                @click="clearCompanyLogo"
                            >
                              <v-icon>
                                mdi-delete-variant
                              </v-icon>
                            </v-btn>
                            <v-btn
                                color="primary"
                                class="text-none"
                                fab
                                depressed
                                small
                                :loading="isSelecting"
                                @click="onButtonClick"
                            >
                              <v-icon>
                                mdi-cloud-upload
                              </v-icon>
                            </v-btn>
                            <input
                                ref="uploader"
                                class="d-none"
                                type="file"
                                accept="image/*"
                                @change="onFileChanged"
                            >
                          </v-img>
                          <v-text-field
                              dense
                              v-model="EditOrder.client.name"
                              label="Cliente"
                          ></v-text-field>
                          <v-text-field
                              dense
                              v-model="EditOrder.client.email"
                              label="Correo"
                          ></v-text-field>
                        </v-card-text>
                      </v-col>
                      <v-col class="justify-center align-center" v-if="QuotationsData.quotationDetails">
                        <div class="row no-gutters position-relative" v-if="EditOrder.transportationId === 1">
                          <v-col cols="4" class="fixed-col-size">
                            <googleMapsAutocompleteVuetify
                                ref="autoOrigen"
                                label="Origen"
                                :search="originAddress"
                                :items="originItems"
                                :selections="Originn"
                                transportation="SEA"
                                placeholder="Ingrese un origen"
                                @change="originSelected"
                                @updateAddress="updateOriginAddress"
                                @updateItems="updateOriginItems"
                                :id="3"
                            >
                            </googleMapsAutocompleteVuetify>
                          </v-col>
                          <v-col cols="4" class="fixed-col-size">
                            <googleMapsAutocompleteVuetify
                                ref="autoDestino"
                                label="Destino"
                                :search="destinationAddress"
                                :items="destinationItems"
                                :selections="destination"
                                transportation="SEA"
                                placeholder="Ingrese un destino"
                                @change="destinationSelected"
                                @updateAddress="updateDestinationAddress"
                                @updateItems="updateDestinationItems"
                                :id="4"
                            >
                            </googleMapsAutocompleteVuetify>
                          </v-col>
                        </div>
                        <div class="row no-gutters position-relative" v-else-if="EditOrder.transportationId === 2">
                          <v-col cols="4">
                            <googleMapsAutocompleteVuetifyAereo
                                ref="autoOrigen"
                                label="Origen"
                                :search="originAddress"
                                :items="originItems"
                                :selections="Originn"
                                transportation="SEA"
                                placeholder="Ingrese un origen"
                                @change="originSelected"
                                @updateAddress="updateOriginAddress"
                                @updateItems="updateOriginItems"
                                :id="3"
                            >
                            </googleMapsAutocompleteVuetifyAereo>
                          </v-col>
                          <v-col cols="4">
                            <googleMapsAutocompleteVuetifyAereo
                                ref="autoDestino"
                                label="Destino"
                                :search="destinationAddress"
                                :items="destinationItems"
                                :selections="destination"
                                transportation="SEA"
                                placeholder="Ingrese un destino"
                                @change="destinationSelected"
                                @updateAddress="updateDestinationAddress"
                                @updateItems="updateDestinationItems"
                                :id="4"
                            >
                            </googleMapsAutocompleteVuetifyAereo>
                          </v-col>
                        </div>
                        <div class="row no-gutters position-relative" v-if="EditOrder.transportationId === 3">
                          <v-col cols="6" class="fixed-col-size">
                            <googleMapsAutocompleteVuetifyTerrestre
                                ref="autoOrigen"
                                label="Origen"
                                :search="originAddress"
                                :items="originItems"
                                :selections="Originn"
                                @change="originSelectedTerrestre"
                                @updateAddress="updateOriginAddress"
                                @updateItems="updateOriginItems"
                                placeholder="Ingrese un origen"
                                :id="3"
                            >
                            </googleMapsAutocompleteVuetifyTerrestre>
                          </v-col>
                          <v-col cols="6" class="fixed-col-size">
                            <googleMapsAutocompleteVuetifyTerrestre
                                ref="autoDestino"
                                label="Destino"
                                :search="destinationAddress"
                                :items="destinationItems"
                                :selections="destination"
                                @change="destinationSelectedTerrestre"
                                @updateAddress="updateDestinationAddress"
                                @updateItems="updateDestinationItems"
                                placeholder="Ingrese un Destino"
                                :id="4"
                            >
                            </googleMapsAutocompleteVuetifyTerrestre>
                          </v-col>
                        </div>
                        <v-row justify="center">
                          <v-col cols="12" class="fixed-col-size" v-if="this.EditOrder.masterPrice">
                            <sen-preview-time-line
                                :key="pTimeLineKey"
                                :prices="[EditOrder.masterPrice]"></sen-preview-time-line>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <template v-if="QuotationsData.quotationDetails">
              <template v-if="EditOrder.previewQuotations">
                <v-row v-for="(quotation,index) in EditOrder.previewQuotations" :key="`${quotation.id}${index}`">
                  <v-col>
                    <v-card>
                      <v-card-text>
                        <template>
                          <v-simple-table>
                            <template v-slot:default>
                              <thead>
                              <tr>
                                <th>
                                  Cargo
                                </th>
                                <th>
                                  Costo
                                </th>
                                <th>
                              <span v-if="!showRatableWeigth">
                                Cantidad
                              </span>
                                  <span v-else>
                                Ratable Weight
                              </span>
                                </th>
                                <th>
                                  Subtotal
                                </th>
                                <th>
                                  Moneda
                                </th>
                              </tr>
                              </thead>
                              <template v-for="currency in quotation.feeGroups">
                                <tbody :key="currency[0].currencyName">
                                <template v-for="(cargo,index) in currency">
                                  <tr :key="`${cargo.detailId}${index}`">
                                    <td>
                                      {{ cargo.name }}
                                    </td>
                                    <td>
                                      {{ cargo.price.toFixed(2) }}
                                    </td>
                                    <td>
                              <span v-if="!showRatableWeigth">
                                {{ cargo.quantity }}
                              </span>
                                      <span v-else>
                                {{ cargo.ratableWeight }}
                              </span>
                                    </td>
                                    <td>
                                      {{ cargo.total.toFixed(2) }}
                                      <small v-if="cargo.hasVat">+IVA</small>
                                      <small v-else-if="cargo.hasRet">-IVA+RET</small>
                                    </td>
                                    <td>
                                      {{ cargo.currencyName }}
                                    </td>
                                  </tr>
                                </template>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    <strong>Subtotal: </strong>
                                    <span>{{ sumPreview(currency).toFixed(2) }}</span>
                                  </td>
                                  <td></td>
                                </tr>
                                </tbody>
                              </template>
                            </template>
                          </v-simple-table>
                        </template>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
            </template>
            <v-row>
              <v-col>
                <h4 class="ml-5">Detalles de carga</h4>
                <v-card>
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <sen-cargo-detail :load-data="EditOrder.cargo"
                                          :control="EditOrder.transportationCategoryId"
                                          :edit-mode="true"></sen-cargo-detail>
                      </v-col>
                    </v-row>
                    <v-row justify="center" v-if="EditOrder.masterPrice">
                      <v-col cols="3">
                        <v-menu offset-y>
                          <template v-slot:activator="{ on: menu, attrs }">
                            <v-form v-model="vigency" ref="myFormVigency">
                              <v-text-field
                                  v-model="EditOrder.masterPrice.validity"
                                  label="Vigencia"
                                  append-icon="mdi-calendar"
                                  class="more-options-text"
                                  :rules="rules.required"
                                  readonly
                                  hide-details
                                  v-bind="attrs"
                                  v-on="menu"
                              ></v-text-field>
                            </v-form>

                          </template>
                          <v-date-picker
                              v-model="EditOrder.masterPrice.validity"
                              class="included"
                              no-title
                              scrollable
                              :min="incrementDate(new Date(),1).toISOString().substr(0, 10)"
                              color="primary"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu.save(date)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                            label="Dias de transito"
                            v-model.number="EditOrder.masterPrice.transportationTime"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                            label="Commodity"
                            v-model="EditOrder.commodity"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                    outlined
                    name="input-7-4"
                    label="Notas"
                    v-model="EditOrder.terms"
                ></v-textarea>
              </v-col>
            </v-row>
            <div class="ml-5">
              <div class="py-2 px-2">
                <p style=" color: #A9A9A9; margin: 0px"><small>E-mail - {{ EditOrder && EditOrder.salesManager && EditOrder.salesManager.email ? EditOrder.salesManager.email : 'NA' }}</small></p>
                <p style="color: #A9A9A9; margin: 0px"><small>Telefono - 8120688363</small></p>
                <p style="color: #A9A9A9; margin: 0px"><small>Web - www.senator.com</small></p>
              </div>
              <h3>Terminos y condiciones</h3>
              <small class="primary--dark font-size-x-small line-height-x-small">
                “Tarifa basada en requerimientos por parte del cliente en caso de cambiar, la tarifa puede variar. <br/>
                *
                Las
                Tarifas tendran la vigencia establecida en esta cotización. <br/> *Estas tarifas son aplicables para
                carga
                general / No aplica para mercancias peligrosas, perecederas, con sobrepeso o sobre dimensión, salvo la
                indicación de lo contrario en la tarifa <br/> *Las tarifas antes mencionadas estan sujetas a cambio con
                o
                sin
                previo aviso. <br/> *Estas tarifas cambiaran de acuerdo a los cambios en la descripción de la mercancia.
                <br/>
                *Servicio sujeto a disponibilidad de equipo y espacios. <br/> *Dependiendo la temporada las lineas
                pueden
                implementar recargos como PSS, GRl, etc., con o sin previo aviso. <br/> *No incluyen empaque y embalaje
                de
                las
                mercancias, estiba de carga y descarga de mercancia y/o contenedores, ni fumigación. <br/> “La presente
                oferta
                no cubre ningun servicio adicional a los mencionados y cotizados.. <br/> “La presente oferta excluye
                cualquier
                cargo fuera de nuestro control/responsabilidad. <br/> * No incluye maniobras para carga y descarga de la
                mercancia, esta son responsabilidades tecnicas del embarcador y/o cliente, <br/> *Todos los tiempo de
                transito
                son estimados, ya sea en servicio, Terrestre, Maritimo o Aéreo. <br/> *Como condición para la
                utilización
                de
                servicios por parte de Senator Internacional, el cliente libera a Senator Internacional de cualquier
                responsabilidad legal que derive o surja con motivo del transporte terrestre, maritimo o aéreo
                involucrado.
                <br/> *No incluye seguro de la mercancia, en caso de requerirla es el .65% sobre el valor factura, En
                caso
                de
                no
                contratar seguro de la mercancia, la mercancia viaja bajo riesgo total del cliente. <br/> *No se aceptan
                reclamos especiales, perdidas de producción, caducidad de la vigencia del producto, retrasos en entregas
                o
                en
                recepción de mercancias en ningun caso, considerando, entre otros, los siguientes: <br/> (1) retrasos
                por
                mal
                clima, accidente o carga <br/> (2) retrasos en transito causados por parte del FFCC / comitentes /
                Transportistas o causas ajenas a Senator Internacional. <br/> (3) cambios de horario. <br/> (4) retrasos
                por
                falta de documentación por parte del cliente o cualquier tipo de retraso ajeno a Senator Internacional.
                <br/>
                (5) retrasos por cuenta del AA externo a Senator Internacional. <br/> (6) retrasos por parte de la
                naviera
                ajenas a Senator Internacional <br/> “No incluye pesaje de la mercancia, si se requiere, favor de
                solicitarlo
                previamente por escrito. </small>
            </div>
          </v-card-text>
        </v-card>
      </v-card>
      <VLoading :active="previewLoading"
                :opacity="0.45"
                :absolute="true"
                style="z-index: 999"></VLoading>
    </v-dialog>

    <v-dialog v-model="dialog3" width="80%"
              v-on:sellDataOrder="recibeDataOrder"
              v-on:closeDialog="dialog3= $event"
    >
      <v-card>
        <v-toolbar flat dark color="primary">
          <v-toolbar-title><v-icon left>mdi-table-edit</v-icon>Venta a enviar a cliente </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="white" :disabled="getProfit() <= 0" style="color: #0d4d98"  @click="send2Client"><b>Enviar a cliente</b></v-btn>
        </v-toolbar>
        <v-row>
          <v-col cols="12">
            <v-card class="mr-2 mt-2">
              <v-card-title>
                Venta
                <v-spacer></v-spacer>
                <div class="mr-4">

                  Profit - Total: ${{ getProfit() }}
                </div>
                <div class="mr-4">
                  Porcentaje: {{ getPorcentaje() }}%
                </div>
                Total: ${{ getTotalVenta() }}
              </v-card-title>
              <v-data-table
                  v-for="(items,i) in infoQuotation"
                  :key="i"
                  :headers="headersVenta"
                  :items="items.fees"
              >
                <template v-slot:item.suma=" { item } ">
                  {{ item.unitPrice * item.quantity + item.profit }}
                </template>
                <template v-slot:item.hasVat="{item}">
                  {{item.hasVat ? 'Si' : 'No'}}
                </template>
                <template v-slot:item.hasRet="{item}">
                  {{item.hasRet ? 'Si' : 'No'}}
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <confetti ref="confeti"/>

  </div>

</template>

<script>
import FileDrag from "../Global/FileDrag/FileDrag";
import VLoading from "@/components/Global/loading/loader";
import googleMapsAutocompleteVuetify from "@/components/google-maps-autocomplete-vuetify/index_new";
import googleMapsAutocompleteVuetifyAereo from "@/components/google-maps-autocomplete-vuetify/SAirportAutocomplete";
import googleMapsAutocompleteVuetifyTerrestre from "@/components/google-maps-autocomplete-vuetify/SLandAutocomplete";
import SenPreviewTimeLine from '@/components/Sales/SenPreviewTimeLine'
import QuotationDetails from './Quotation/QuotationDetails';
import Sortable from 'sortablejs';
import TruckButton from "@/components/TruckButton"
// let download = require('downloadjs');
import debouncePromise from 'debounce-promise';
import {debounce} from "debounce";

import Price from "../../prototypes/price";
import Quotation from "../../prototypes/quotation"
import OrderHistory from "../Quote/QuotDetail/OrderHistory";
import SenCargoDetail from "@/components/Sales/SenCargoDetail";
import confetti from "@/components/confetti";

var fileDownload = require('js-file-download')

const _GLOBALS = {
  TRANSPORTATION: {
    MARITIME: 1,
    AIR: 2,
    LAND: 3,
  },
  ORDER_TYPE: {
    IMPORT: 1,
    EXPORT: 2,
    NATIONAL: 3
  },
  SUPPLIER_TYPES: {
    AIR: 2,
    LAND: 3,
    MARITIME: 4,
    MARKETING: 5,
    INSPECTION: 6,
    CARGO_SAFE: 7,
    WAREHOUSING: 8,
    PACKAGING: 9,
    FINANCING: 10
  }
}

export default {
  name: "FormatFeesSales",
  components: {
    SenCargoDetail,
    OrderHistory,
    QuotationDetails,
    SenPreviewTimeLine,
    googleMapsAutocompleteVuetify,
    googleMapsAutocompleteVuetifyTerrestre,
    googleMapsAutocompleteVuetifyAereo,
    VLoading,
    FileDrag,
    confetti,
    TruckButton
  },
  props: {
    statusOrder: {
      type: String
    },
    UpdateDataOrder: {
      type: Boolean
    },
    Id: {
      type: Number
    },
    IdQuotation: {
      type: Number
    },
  },
  computed: {
    buttonText() {
      return this.selectedFile ? this.selectedFile.name : this.defaultButtonText
    },
    companyLogo() {
      return this.EditOrder.client?.configuration?.logo ? 'data:image/png;base64,' + this.EditOrder.client.configuration.logo : require('@/assets/placeholder.png')
    },
    showButtonSale() {
      return this.QuotationsData.quotationDetails?.length > 0 && this.DataOrder.statusOrder != 'Vendida';
    }
  },
  watch: {

    sellOrder: function (val) {
      if (val)
        this.SellOrder();
    },

    UpdateDataOrder: function () {
      this.GetDataOrder();
    },
    IdQuotation: function () {
      this.GetQuotation()
    },
    Id: function () {
      this.GetDataOrder();
      this.GetOrderPrices();
    },
    Origin: {
      handler() {
        this.Fees[0].pricesOrigins[0] = this.Origin;
        this.Fees[1].pricesOrigins[0] = this.Origin
      },
      deep: true
    },
    Destination: {
      handler() {
        this.Fees[0].pricesDestination[0] = this.Destination;
        this.Fees[1].pricesDestination[0] = this.Destination;
      },
      deep: true
    }
  },
  data: () => ({
    Services_: {
      type: Object,
      default: function () {
        return {

          needsPricerConfirmation: true,
          hasPricerConfirmed: false,
          espacios: "   ",
          Almacenaje: 0,
          validShipment: true,
          Seguro: 0,
          Embalaje: 0,
          Financiamiento: 0,
          Recoleccion: 0,
          DespachoEnOrigen: 0,
          GastosPortuariosOrigen: 0,
          FleteMaritimo: 0,
          GastosPortuariosDestino: 0,
          DespachoDeImportacion: 0,
          Entrega: 0,
          AgenteAduanal: 0,
          Almacenamiento: 0,
          CustodiaArmada: 0,
          Paletizadora: 0,
          Inspeccion: 0,
          Maniobras: 0,
          SeguroMercancias: 0,
          GoodsCost: null,
          resulImage: ""
        }
      }
    },
    ispricer: true,
    emails: "",
    emails2: "",
    infoQuotation:[],
    itemsCompra2:[],
    itemsVenta2:[],
    headersCompra: [
      {
        text: 'Cargo',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      { text: 'Price', value: 'unitPrice' },
      { text: 'Quantity', value: 'quantity' },
      { text: 'Ratable Weight', value: 'RatableWeight' },
      { text: 'Moneda', value: 'currency.code' },
      { text: 'IVA', value: 'hasVat' },
      { text: 'RET', value: 'hasRet' },
      { text: 'Total', value: 'total' },
    ],
    headersVenta: [
      {
        text: 'Cargo',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      { text: 'Price', value: 'total' },
      { text: 'Quantity', value: 'quantity' },
      { text: 'Profit', value: 'profit' },
      { text: 'Descuento', value: 'discount' },
      { text: 'Moneda', value: 'currencyId' },
      { text: 'IVA', value: 'hasVat' },
      { text: 'RET', value: 'hasRet' },
      { text: 'Total', value: 'suma' },
    ],
    searchEmails: null,
    emailValid: [
      v => {
        if (!v || v.length < 1) return "Debes agregar al menos un correo";
        else if (!v || v.length > 0) {
          for (let i = 0; i < v.length; i++) {
            if (!/.+@.+\..+/.test(v[i])) {
              return "Un correo no es valido.";
            }
          }
        }
        return true;
      }
    ],
    companies:[],
    ContactCompany:[],
    Operators:[],
    operators: [],
    TypeChange:[
      {
        id:1,
        name:'Ruta',
      },
      {
        id:2,
        name:'Precio'
      },
      {
        id:3,
        name:'Puertos'
      },
      {
        id:4,
        name:'Origen'
      },
      {
        id:5,
        name:'Destino'
      },
      {
        id:6,
        name:'Proveedor'
      },
      {
        id:7,
        name:'Cliente'
      }
    ],
    shipment:{
      hasPricerConfirmed: false,
      needsPricerConfirmation: true,
      ClientSupplierName:null,
      ContactName:null,
      ReferenceNumber:null,
      OperatorId:null,
      QuotationId:null,
      Comments:null,
      ChangeTypeId:null,
      HouseSupplierEmail: "hardcodiado@gmail.com",
      Mails: null,
      DocumentDrag:[],
      Documents:[],
    },
    ClientRules: [
      v => !!v || 'Cliente es requerido',
    ],
    ClientContactRules: [
      v => !!v || 'Contacto de cliente es requerido',
    ],
    OrderClientRules: [
      v => !!v || 'Orden de cliente es requerido',
    ],
    OperatorRules: [
      v => !!v || 'Operador es requerido',
    ],
    commentRules: [
      v => !!v || 'Comentarios es requerido',
    ],
    conSin: [
      v => !!v || 'Opcion requerida',
    ],
    documents:[
      {
        file:null,
        fileName:null,
        fileExtension:null
      }
    ],
    emailnotification:[
      {
        email:null,
      }
    ],
    e6:1,
    putFeesCall: null,
    pTimeLineKey: 0,
    keyBtn: 0,
    rules: {
      required: [value => !!value || ""]
    },
    ratableWeightDef: 0,
    vigency: false,
    defaultButtonText: '',
    selectedFile: null,
    showSellButton: false,
    isSelecting: false,
    previewLoading: false,
    visbleByClient:false,
    dialogKey: 0,
    EditOrder: {},
    dialogQ: false,
    showResumeTotal: true,
    showRatableWeigth: false,
    destinationAddress: "",
    sellOrder: false,
    showQuotationTotalPrice: false,
    destinationItems: [],
    dialog: false,
    dialog3: false,
    filesDrag: [],
    destinationSelections: {},
    originItems: [],
    originSelections: {},
    items: [
      {
        type: "success",
        color: 'red lighten-2',
        icon: 'mdi-star',
      },
      {
        type: "info",
        color: 'purple darken-1',
        icon: 'mdi-book-variant',
      },
      {
        type: "info",
        color: 'green lighten-1',
        icon: 'mdi-airballoon',
      },

    ],
    originAddress: "",
    Originn: {},
    Origin: {},
    destination: {},
    showAddSupplier: false,
    SupplierTypes: [
      {
        id: 1,
        name: 'Maritimo',
        icon: 'las la-water'
      },
      {
        id: 2,
        name: 'Aereo',
        icon: 'las la-plane'
      },
      {
        id: 3,
        name: 'Terrestre',
        icon: 'las la-truck'
      }
    ],
    Quotation: {
      orderId: null,
      profit: 0,
      suggested: true,
      discount: 0,
      discountPercentage: 0,
      profitPercentage: 0,
      quotationDetails: [
        {
          quotationId: 0,
          orderPriceId: null,
          isVisible: true,
          profit: 0,
          discount: 0,
          discountPercentage: 0,
          profitPercentage: 0,
          total: 0
        },
      ]
    },
    VolumetricWeigth:
        {
          total: 0,
          cargo: []
        },
    QuotationsData: {
      quotationDetails: []
    },
    tabs: 0,
    QuoteData: {
      id: null,
      orderId: null,
      profit: 0,
      suggested: true,
      discount: 0,
      discountPercentage: 0,
      profitPercentage: 0,
      quotationDetails: []
    },
    QuotationDetails: [],
    BuildQuote: [],
    ResumeTotal: 0,
    showTotalPrice: true,
    BuildModal: false,
    DataOrder: {},
    DefaultSupplier: null,
    Fees: [],
    Suppliers: [],
    Element: [
      {
        id: 0,
        name: 'Nombre del cargo',
        dense: true,
        total: 0,
        table: [
          {
            id: 0,
            name: 'Nombre',
            rate: 0,
            quantity: 0,
            total: 0,
          }
        ]
      }
    ],
  }),
  methods: {


    send2Client() {
      this.$store.dispatch('working', true)
      this.$http.post(`orders/${this.QuotationsData.orderId}/send/client?quotationId=${this.QuotationsData.id}`).then((response) => {
        console.log(response)
        this.$toast.success("Enviado con exito!");
        this.dialog3 = false;
      }).catch((error) => {
        console.log(error)
        this.$toast.error("Algo salio mal");
      }).finally(() => {
        this.$store.dispatch('working', false)
      })
    },
    clickNeedsPricer(){
      if(this.ispricer == true){
        this.shipment.hasPricerConfirmed = false
        this.shipment.needsPricerConfirmation = true
      }else{
        this.shipment.hasPricerConfirmed = true
        this.shipment.needsPricerConfirmation = false
      }
    },
    getProfit(){
      let total = 0
      this.infoQuotation.forEach((element) => {
        element.fees.forEach((fee) => {
          total = total + (fee.profit || 0)
        })
      })
      return total
    },
    getPorcentaje(){
      let total = 0
      this.infoQuotation.forEach((element) => {
        element.price.priceFees.forEach((fee) => {
          total = total + (fee.unitPrice * fee.quantity || 0)
        })
      })

      let total2 = 0;
      this.infoQuotation.forEach((element) => {
        element.fees.forEach((fee) => {
          total2 = total2 + (fee.unitPrice * fee.quantity + fee.profit || 0)
        })
      })

      let total3 = (total2 - total)
      let division = total3/total2
      division = division * 100
      return division = division.toFixed(2)
    },
    getTotalCompra(){
      let total = 0
      this.infoQuotation.forEach((element) => {
        element.price.priceFees.forEach((fee) => {
          total = total + (fee.unitPrice * fee.quantity)
        })
      })
      return total
    },
    getTotalVenta(){
      let total = 0;
      this.infoQuotation.forEach((element) => {
        element.fees.forEach((fee) => {
          total = total + (fee.unitPrice * fee.quantity + fee.profit)
        })
      })
      console.log(total)
      return total
    },
    formatEmails(){
      this.emails2 = ""
      this.emails.forEach((element) => {
        this.emails2= this.emails2 + element + ";"
      })
      let emails3 = this.emails2.slice(0,-1)
      this.emails2 = emails3
    },
    getCustomers: function () {
      this.$store.dispatch('working', true)
      this.$http.get('Companies', {
        headers: {
          'Cache-Control': 'max-age=5'
        }
      })
          .then(response => {
            console.log(response)
            this.showAddCompany = false
            this.companies = response.data.data;
          })
          .catch(error => {
            if (error.response.status == 401) {
              this.$store.commit('reset');
              this.$toast.error("La sesion expiro");
              this.$router.replace('/login')
            }
          }).finally(() => this.$store.dispatch('working', false))
    },
    getOperators: function () {
      this.$store.dispatch('working', true)
      this.$http.get('Employees/operators')
          .then(response => {
            console.log(response)
            this.operators = response.data.data;
          })
          .catch(error => {
            if (error.response.status == 401) {
              this.$store.commit('reset');
              this.$toast.error("La sesion expiro");
              this.$router.replace('/login')
            }
          }).finally(() => this.$store.dispatch('working', false))
    },
    addDocuments(){
      this.shipment.Document.push({
        file:null,
        fileName:null,
        fileExtension:null
      });
    },

    UploadFile: function (file) {
      return new Promise((resolve, reject) => {

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error);
      });
    },

    ConvertFile: async function () {

      let documents = this.shipment.DocumentDrag;

      for (const doc of documents) {
        const result = await this.UploadFile(doc);
        const data = result.split(',')[1];
        const dotPosition = doc.name.lastIndexOf('.');
        const fileExt = doc.name.substr(dotPosition + 1);
        const fileName = dotPosition === -1 ? doc.name : doc.name.substr(0, dotPosition);
        this.shipment.Documents.push(
            {
              documentTypeId: 103,
              file: data,
              fileName: fileName,
              fileExtension: fileExt
            }
        )
      }

    },

    addEmail(email){
      this.shipment.Mails.push(email);
    },
    setTerms(terms) {
      this.EditOrder.terms = terms;
    },
    closePreviewDialog() {
      this.savePreviewChanges().then(() => {
        this.dialogQ = false

      })
    },
    generatePdfPreview() {
      this.savePreviewChanges().then(() => {
        this.GeneratePdf();
      });
    },
    savePreviewChanges() {
      return new Promise((resolve) => {
        this.$store.dispatch('working', true);
        let priceUpdate = new Price(this.EditOrder.masterPrice);
        priceUpdate.priceDocuments = [];

        let targetOrder = {
          id: this.EditOrder.id,
          transportationId: this.EditOrder.transportationId,
          commentsToPricer: this.EditOrder.commentsToPricer,
          transportationCategoryId: this.EditOrder.transportationCategoryId,
          incotermId: this.EditOrder.icontermId,
          assignToPricer: this.EditOrder.assignToPricer,
          orderTypeId: this.EditOrder.orderTypeId,
          landTransfer: this.EditOrder.landTransfer,
          goodsCost: this.EditOrder.goodsCost,
          landTransportation: this.EditOrder.landTransportation,
          landDedicated: this.EditOrder.landDedicated,
          notifications: this.EditOrder.notifications,
          refNumber: this.EditOrder.refNumber,
          landFull: this.EditOrder.landFull,
          landServiceId: this.EditOrder.landServiceId,
          terms: this.EditOrder.terms,
          origin: [...this.EditOrder.origin],
          destination: [...this.EditOrder.destination],
          statusOrder: this.EditOrder.statusOrder,
          pol: this.EditOrder.pol,
          pod: this.EditOrder.pod,
          departureDate: this.EditOrder.dep,
          industryId: this.EditOrder.industryId,
          budget: this.EditOrder.budget,
          commodity: this.EditOrder.commodity,
          insideBillOfLanding: this.EditOrder.insideBillOfLanding,
          cargo: this.EditOrder.cargo.map((cargo) => {
            let cargoc = JSON.parse(JSON.stringify(cargo))
            if (cargoc.type === "Pallets") {
              cargoc.containerId = null;
              cargoc.loadingTypeId = 2;
            } else {
              cargoc.loadingTypeId = null;
            }
            return cargoc
          }),
          services: this.EditOrder.services.map((service) => {
            let servicec = JSON.parse(JSON.stringify(service))
            delete servicec.service
            delete servicec.servicesDocuments
            return {
              ...servicec
            }
          }),
          clientId: this.EditOrder.clientId,
          pricerId: this.EditOrder.pricerId,
          salesManagerId: this.EditOrder.salesManagerId,
          landServices: this.EditOrder.landServices,
          incoterm: this.EditOrder.incoterm,
          frequency: this.EditOrder.frequency,
          urgent: this.EditOrder.urget,
          comments: this.EditOrder.comments
        };

        let client = JSON.parse(JSON.stringify(this.EditOrder.client))
        delete client.services
        delete client.configuration.company

        Promise.all([
          this.$http.put(`Orders/${targetOrder.id}`, targetOrder),
          this.$http.put(`Companies/${this.EditOrder.client.id}`, client),
          this.$http.put(`Orders/${targetOrder.id}/prices`, [priceUpdate]),
          this.$http.put(`Orders/${targetOrder.id}/terms`, `"${targetOrder.terms}"`,
              {
                headers: {
                  'Content-Type': 'application/json'
                }
              }
          )
        ]).then(() => {
          // console.log(response.data.data);
          // this.DataOrder.terms = targetOrder.terms;
          this.$toast.info("Orden Actualizada");
          this.$emit('previewSaved');
        }).finally(() => {
          this.$store.dispatch('working', false)
          resolve(true)

        })
      })
    },
    incrementDate(dateInput, increment) {
      let dateFormatTotime = new Date(dateInput);
      return new Date(dateFormatTotime.getTime() + (increment * 86400000));
    },
    toB64: function (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    clearCompanyLogo() {
      this.EditOrder.client.configuration.logo = null
    },
    onButtonClick() {
      this.isSelecting = true
      window.addEventListener('focus', () => {
        this.isSelecting = false
      }, {once: true})

      this.$refs.uploader.click()
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0]
      this.toB64(this.selectedFile).then(response => {
        let data = response.split(',')[1];
        this.EditOrder.client.configuration.logo = data
      })
      // do something
    },
    showPreview() {
      this.$store.dispatch('working', true);
      this.validateIfLastVisible()
      this.previewLoading = true;
      this.QuotationsData.id = this.IdQuotation
      this.QuotationsData.orderId = this.Id;

      let quotation = new Quotation(this.QuotationsData);


      this.QuotationsData.isUpdating = true;
      this.$http.put(`Quotation/${this.IdQuotation}`, quotation).finally(() => {
        this.QuotationsData.isUpdating = false
        // console.log("Master", this.EditOrder.masterPrice)
        this.$nextTick(() => {
          this.dialogQ = true;
        })

      }).finally(() => {
        this.GetQuotation();
        this.$store.dispatch('working', false);
      })
    },
    sumPreview(currencyCargos) {
      return currencyCargos.reduce((acc, cargo) => {
        return acc + cargo.total
      }, 0)
    },
    async saveOrder(event) {
      const movedItem = this.QuotationsData.quotationDetails.splice(event.oldIndex, 1)[0];
      this.QuotationsData.quotationDetails.splice(event.newIndex, 0, movedItem);

      await this.UpdateQuotation();
      this.GetQuotation();
    },
    validateQuotationDetailTotal() {
      let unique = [];
      this.QuotationsData.quotationDetails.forEach((element, index) => {
        unique = [...new Set(this.QuotationsData.quotationDetails[index].fees.map(item => item.currency.id))];
        if (unique.length > 1) {
          this.showQuotationTotalPrice = false;
        } else {
          this.showQuotationTotalPrice = true;
        }

      })


    },
    validateTotal() {
      let unique = [];
      this.Fees.forEach((element, index) => {
        unique = [...new Set(this.Fees[index].priceFees.map(item => item.currencyId))];
        if (unique.length > 1) {
          this.Fees[index].showTotalPrice = false;
        } else {
          this.Fees[index].showTotalPrice = true;
        }

      })


    },
    validateTotalFee(index) {
      let unique = [];
      unique = [...new Set(this.Fees[index].priceFees.map(item => item.currencyId))];
      if (unique.length > 1) {
        this.Fees[index].showTotalPrice = false;
        // this.showTotalPrice = false;
      } else {
        this.Fees[index].showTotalPrice = true;
        //this.showTotalPrice = true;
      }
    },
    validateRatableWeight() {
      if (this.DataOrder.transportationCategoryId === 2 ||
          this.DataOrder.transportationCategoryId === 1 ||
          this.DataOrder.transportationCategoryId === 3 ||
          this.DataOrder.transportationCategoryId === 11 ||
          this.DataOrder.transportationCategoryId === 12 ||
          this.DataOrder.transportationCategoryId === 13)
        this.showRatableWeigth = false;
      else
        this.showRatableWeigth = true;
    },
    downloadAll(price) {
      price.priceDocuments.forEach((pDocument) => {
        this.downloadDocument(pDocument)
      })
    },
    downloadDocument(doc) {
      this.$store.dispatch('working', true);
      this.$http({
        url: `Orders/Price/${doc.orderPriceId}/Document/${doc.id}`,
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        fileDownload(response.data, `${doc.fileName}.${doc.fileExtension}`);
        // this.$toast.info('Pdf Generado');
        // var file = new Blob([response.data], {type: 'application/pdf'});
        // var fileURL = URL.createObjectURL(file);
        // window.open(fileURL);
      }).catch(() => {
        this.$toast.error("Algo salio mal, intente mas tarde")
        // console.log(error);
      }).finally(() => {
        this.$store.dispatch('working', false);
      })
    },
    sellOrder2(){
      console.log("Putas")

      this.$refs.shipment.validate();

      if( this.$refs.shipment.validate()){
        this.$refs.Truck.toggleOrder()
        setTimeout(() => {
          this.$store.dispatch('working', true)
          setTimeout(() => {this.$store.dispatch('working', false)}, 2000)

        },4000)
        /*this.shipment.needsPricerConfirmation
        this.shipment.hasPricerConfirmed*/
        this.shipment.QuotationId = this.IdQuotation;
        delete this.shipment.DocumentDrag;

        /*      this.shipment.HouseSupplierEmail = this.emails2*/
        let emails = null;
        emails = this.shipment.Mails.join(';')
        this.shipment.Mails = emails.trim()
        let test = JSON.parse(JSON.stringify(emails.split(';')))
        console.log(test)
        console.log(emails)
        /*this.shipment.Mails = test
        console.log(this.shipment.Mails)*/
        this.setOrderSold().then(({data}) => {
          this.shipment.Mails = test
          if (data.code === 200) {
            const audio = new Audio(require('@/assets/binanshous_day.wav'))
            audio.play();
            this.$toast.success('Cotización Vendida')
            this.$refs.confeti.start();
            this.dialog = false;
            this.$emit('sellOrder')
            this.DataOrder.statusOrder = 'Vendida'
            this.GetQuotation()
            this.keyBtn += 1

          } else {
            console.error(data)
            this.shipment.Mails = test
            this.$toast.error('Hubo un error, contactese con el administrador')
          }
        }).catch(error => {
          console.error(error)
          this.$toast.error('Hubo un error, contactese con el administrador')
        }).finally(() => {
          this.sellOrder = false;
          this.$store.dispatch('working', false)
        })

      }
      else {
        this.$toast.error('Llene los campos requeridos');
      }
    },
    SellOrder: function () {
      this.$store.dispatch('working', true)
      this.setOrderSold().then(({data}) => {

        if (data.code === 200) {
          this.$toast.success('Cotización Vendida')
          this.dialog = false;
          this.$emit('sellOrder')
          this.DataOrder.statusOrder = 'Vendida'
          this.GetQuotation()
          this.keyBtn += 1

        } else {
          console.error(data)
          this.$toast.error('Hubo un error, contactese con el administrador')
        }
      }).catch(error => {
        console.error(error)
        this.$toast.error('Hubo un error, contactese con el administrador')
      }).finally(() => {
        this.sellOrder = false;
        this.$store.dispatch('working', false)
      })
    },
    setOrderSold: function () {
      let cleanShipment = JSON.parse(JSON.stringify(this.shipment))

      return this.$http.post(`Orders/${this.Id}/sold`, cleanShipment)
    },
    recibeDataOrder(data) {
      data.QuotationId = this.IdQuotation;
      this.shipment = data;
      delete this.shipment.DocumentDrag;
      // console.log(data);
    },
    setQuotationFeeVisibleAndUpdate(evt) {
      let detailIndex = this.QuotationsData.quotationDetails.findIndex(x => x.id == evt[0].detailId)

      if (detailIndex == -1) {
        throw new Error('No se encontró este elemento')
      }

      let feesUpdated = this.QuotationsData.quotationDetails[detailIndex].fees.map((item, index) => {
        return {
          ...item,
          ...evt[index]
        }
      })

      this.QuotationsData.quotationDetails[detailIndex].fees = feesUpdated
      this.mapAndUpdateQuotation()
    },
    isPriceInQuotation(priceId) {
      try {
        if (Object.prototype.hasOwnProperty.call(this.QuotationsData, 'quotationDetails')) {
          let index = this.QuotationsData.quotationDetails.findIndex(x => x.orderPriceId === priceId)
          if (index === -1) {
            return null;
          }
          // console.log(this.QuotationsData.quotationDetails[index])
          return index
        }

        return null;
      } catch (error) {
        alert(error)
        console.error(error)
      }
    },
    generateAdditionalDataForPrice(index) {

      let response = ""

      if (this.Fees[index].transportationTime)
        response += `<div class="col-md-3"><span class="text-caption font-weight-bold">Tiempo de transporte:</span><span
                        class="text-caption"> ${this.Fees[index].transportationTime}</span></div>`

      if (_GLOBALS.TRANSPORTATION.MARITIME == this.Fees[index].transportationId)
        response += this.getAdditionalDataForMaritime(index)

      if (_GLOBALS.TRANSPORTATION.LAND == this.Fees[index].transportationId)
        response += this.getAdditionalDataForMaritimeLand(index)

      if (!response)
        response += `<div class="col-md-12 text-center"><span class="text-caption">No se registro información adicional para este precio</span></div>`

      return response
    },
    getAdditionalDataForMaritime(index) {
      let response = ""
      if (this.Fees[index].freeDays)
        response += `<div class="col-md-3"><span class="text-caption font-weight-bold">Días libres: </span><span class="text-caption">${this.Fees[index].freeDays}</span></div>`

      if (this.Fees[index].originStorage)
        response += `<div class="col-md-3"><span class="text-caption font-weight-bold">Alm. Org: </span><span class="text-caption"> ${this.Fees[index].originStorage}</span></div>`

      if (this.Fees[index].destinationStorage)
        response += `<div class="col-md-3"><span class="text-caption font-weight-bold">Alm. Destino: </span><span class="text-caption">${this.Fees[index].destinationStorage}</span></div>`

      return response
    },
    getAdditionalDataForMaritimeLand(index) {
      let response = ""

      if (!this.Fees[index].priceConditions) {
        return response;
      }

      if (this.Fees[index].priceConditions.loadingTime)
        response += `<div class="col-md-3"><span class="text-caption font-weight-bold">Carga: </span><span class="text-caption">${this.Fees[index].priceConditions.loadingTime} ${this.Fees[index].priceConditions.loadingTime > 1 ? 'horas' : 'hora'}</span></div>`
      if (this.Fees[index].priceConditions.dischargeTime)
        response += `<div class="col-md-3"><span class="text-caption font-weight-bold">Descarga: </span><span class="text-caption">${this.Fees[index].priceConditions.dischargeTime} ${this.Fees[index].priceConditions.dischargeTime > 1 ? 'horas' : 'hora'}</span></div>`
      if (this.Fees[index].priceConditions.demurrageCost)
        response += `<div class="col-md-3"><span class="text-caption font-weight-bold">Estadia: </span><span class="text-caption">${this.Fees[index].priceConditions.demurrageCost} x dia</span></div>`

      return response
    },
    setQuotationDetailVisible(index) {
      this.QuotationsData.quotationDetails[index].isVisible = !this.QuotationsData.quotationDetails[index].isVisible
      this.mapAndUpdateQuotation()
    },
    setFeeVisible(priceIndex, feeIndex) {

      this.Fees[priceIndex].priceFees[feeIndex].isVisible = !this.Fees[priceIndex].priceFees[feeIndex].isVisible
      this.updatePrice(priceIndex)
    },
    updatePrice: debouncePromise(function (priceIndex) {
      this.Fees[priceIndex].isUpdating = true;
      let price = new Price(this.Fees[priceIndex]);
      if (price.validate()) {
        this.updatePrices([price])
            .catch(error => {
              this.Fees[priceIndex].hasError = true;
              this.Fees[priceIndex].isUpdating = false;
              console.error(error)
            })
            .finally(() => {
              this.Fees[priceIndex].hasError = false;
              this.Fees[priceIndex].isUpdating = false;
            })
      } else {
        this.Fees[priceIndex].isUpdating = false;
      }
    }, 5000),
    updatePrices(prices) {
      return this.$http.put(`Orders/${this.Id}/prices`, prices)
    },
    setFeesAndUpdateQuotation(evt) {
      if (this.QuotationsData.isUpdating) return

      let detailIndex = this.QuotationsData.quotationDetails.findIndex(x => x.id == evt[0].quotationDetailId)

      if (detailIndex == -1) {
        throw new Error('No se encontró este elemento')
      }
      console.log("Set Fees And Update", evt)
      let feesUpdated = this.QuotationsData.quotationDetails[detailIndex].fees = evt;
      // console.log("Set Fees And Update", feesUpdated)
      // this.QuotationsData.quotationDetails[detailIndex].fees = feesUpdated
      let hasIndividualProfit = feesUpdated.some(fee => fee.profit > 0);

      if (hasIndividualProfit) {
        this.QuotationsData.quotationDetails[detailIndex].profit = feesUpdated.reduce((a, b) => {
          return a + b.profit
        }, 0)
        this.QuotationsData.quotationDetails[detailIndex].discount = feesUpdated.reduce((a, b) => {
          return a + b.discount
        }, 0)
      }
      this.calculateQuoteDetailTotal(detailIndex)
      this.calculateResumeTotal();

      let cleanFees = feesUpdated.map(({currency, currencyName, ...item}) => {
        console.log(currencyName)
        console.log(currency)
        console.log(item.total)
        return {
          ...item,
        }
      });

      this.putFeesCall = debouncePromise(() => this.putFees(this.QuotationsData.quotationDetails[detailIndex], cleanFees, detailIndex), 5000, {leading: true})
      // console.log("New Profit", this.QuotationsData.quotationDetails[detailIndex].profit);
    },
    putFees(qDetail, fees, detailIndex) {
      if (!this.dialogQ || !this.QuotationsData.isUpdating) {
        this.QuotationsData.isUpdating = true;
        return this.$http.put(`Quotation/${qDetail.quotationId}/detail/${qDetail.id}/fees`, fees).then((response) => {
          this.QuotationsData.quotationDetails[detailIndex].fees = response.data.data;
          console.table("Detail Index Affected", detailIndex);
          console.table("Original Fees", this.QuotationsData.quotationDetails[detailIndex].fees);
        }).catch(() => {
        }).finally(() => {
          this.GetQuotation();
          this.QuotationsData.isUpdating = false
        })
      }
    },
    validateIfLastVisible() {
      let fees = [];
      this.QuotationsData.quotationDetails.forEach((detail) => {
        fees = [...fees, ...detail.fees]
      })

      let feeGroups = fees.reduce((acc, obj) => {
        const property = obj["currencyName"];
        acc[property] = acc[property] || [];
        acc[property].push(obj);
        return acc;
      }, {})

      for (let feeG in feeGroups) {
        let feeGroup = feeGroups[feeG];
        const invisible = feeGroup.filter(obj => !obj.isVisible)

        if (invisible.length === feeGroup.length) {
          breakHere:
              for (let i = 0; i < this.QuotationsData.quotationDetails.length; i++) {
                let detail = this.QuotationsData.quotationDetails[i]
                if (detail.id === feeGroup[0].quotationDetailId) {
                  for (let j = 0; j < detail.fees.length; j++) {
                    let fee = detail.fees[j]
                    if (fee.currencyName === feeG) {
                      fee.isVisible = true;
                      break breakHere;
                    }
                  }
                }
              }
        }
      }

    },
    mapAndUpdateQuotation() {
      this.validateIfLastVisible()
      this.QuotationsData.id = this.IdQuotation
      this.QuotationsData.orderId = this.Id;
      this.QuotationsData.quotationDetails.map((detail, index) => {
        detail.position = index + 1;
      })

      // Validate if last visible
      let quotation = new Quotation(this.QuotationsData);

      if (quotation.validate() && !this.QuotationsData.isUpdating) {
        // quotation.quotationDetails.forEach((detail) => {
        //   delete detail.price;
        // })
        this.updateQuotation(quotation)
      } else {
        // console.log("Ya hay una actualizando o es invalida");
        //allert('Error, en actualización')
      }
    },
    updateQuotation: debounce(function (quotation) {
      if (!this.dialogQ) {
        this.QuotationsData.isUpdating = true;
        return this.$http.put(`Quotation/${this.IdQuotation}`, quotation).finally(() => {
          this.GetQuotation();
          this.QuotationsData.isUpdating = false
        })
      }
    }, 5000),
    calculateQuoteDetailProfit(detailIndex, isInput = false) {
      let priceIndex = this.Fees.findIndex(x => x.id === this.QuotationsData.quotationDetails[detailIndex].orderPriceId)
      let priceTotal = parseFloat(this.Fees[priceIndex].total);

      let profit = 0;
      if (isInput) {
        this.QuotationsData.quotationDetails[detailIndex].fees.forEach((item) => {
          item.profit = 0;
        })
      }

      this.QuotationsData.quotationDetails[detailIndex].profit = Number(this.QuotationsData.quotationDetails[detailIndex].profit) + profit - this.QuotationsData.quotationDetails[detailIndex].profitFees;
      this.QuotationsData.quotationDetails[detailIndex].profitFees = profit

      this.QuotationsData.quotationDetails[detailIndex].profitPercentage = (this.QuotationsData.quotationDetails[detailIndex].profit) * 100 / priceTotal;

      this.calculateQuoteDetailTotal(detailIndex)
      this.$nextTick(() => {
        this.mapAndUpdateQuotation()
      })

    },
    calculateQuoteDetailProfitPercentage(detailIndex, isInput = false) {
      let priceIndex = this.Fees.findIndex(x => x.id === this.QuotationsData.quotationDetails[detailIndex].orderPriceId);
      let priceTotal = parseFloat(this.Fees[priceIndex].total);

      if (isInput) {
        this.QuotationsData.quotationDetails[detailIndex].fees.forEach((item) => {
          item.profit = 0;
        })
      }

      this.QuotationsData.quotationDetails[detailIndex].profit = parseFloat(this.QuotationsData.quotationDetails[detailIndex].profit);
      this.QuotationsData.quotationDetails[detailIndex].profitPercentage = parseFloat(this.QuotationsData.quotationDetails[detailIndex].profitPercentage);

      this.QuotationsData.quotationDetails[detailIndex].profit = priceTotal / 100 * this.QuotationsData.quotationDetails[detailIndex].profitPercentage;

      this.calculateQuoteDetailTotal(detailIndex)
      this.mapAndUpdateQuotation()
    },
    calculateQuoteDetailDiscount(detailIndex, isInput = false) {
      let priceIndex = this.Fees.findIndex(x => x.id === this.QuotationsData.quotationDetails[detailIndex].orderPriceId);
      let priceTotal = parseFloat(this.Fees[priceIndex].total);

      let discount = 0;

      if (isInput) {
        this.QuotationsData.quotationDetails[detailIndex].fees.forEach((item) => {
          item.discount = 0;
        })
      }

      this.QuotationsData.quotationDetails[detailIndex].discount = parseFloat(this.QuotationsData.quotationDetails[detailIndex].discount) + discount - this.QuotationsData.quotationDetails[detailIndex].discountFees;
      this.QuotationsData.quotationDetails[detailIndex].discountFees = discount
      this.QuotationsData.quotationDetails[detailIndex].discountPercentage = this.QuotationsData.quotationDetails[detailIndex].discount * 100 / priceTotal;

      this.calculateQuoteDetailTotal(detailIndex)

      this.$nextTick(() => {
        this.mapAndUpdateQuotation()
      })
    },
    calculateQuoteDetailDiscountPercentage(detailIndex) {
      let priceIndex = this.Fees.findIndex(x => x.id === this.QuotationsData.quotationDetails[detailIndex].orderPriceId);
      let priceTotal = parseFloat(this.Fees[priceIndex].total);

      this.QuotationsData.quotationDetails[detailIndex].discount = parseFloat(this.QuotationsData.quotationDetails[detailIndex].discount);
      this.QuotationsData.quotationDetails[detailIndex].discountPercentage = parseFloat(this.QuotationsData.quotationDetails[detailIndex].discountPercentage);
      this.QuotationsData.quotationDetails[detailIndex].discount = priceTotal / 100 * this.QuotationsData.quotationDetails[detailIndex].discountPercentage;

      this.calculateQuoteDetailTotal(detailIndex)

      this.$nextTick(() => {
        this.mapAndUpdateQuotation()
      })
    },
    calculateQuoteDetailTotal(detailIndex) {
      // let priceIndex = this.Fees.findIndex(x => x.id === this.QuotationsData.quotationDetails[detailIndex].orderPriceId);
      let detailTotal = this.QuotationsData.quotationDetails[detailIndex].fees.reduce((acc, item) => {
        return acc + item.total;
      }, 0)

      console.log("Detail Total", detailTotal);

      let total = Number(detailTotal);
      let profit = Number(this.QuotationsData.quotationDetails[detailIndex].profit);
      let discount = Number(this.QuotationsData.quotationDetails[detailIndex].discount);
      this.QuotationsData.quotationDetails[detailIndex].total = Number(total + profit - discount)
      this.QuotationsData.quotationDetails[detailIndex].subtotal = Number(total)

      console.log("Detail t", {...this.QuotationsData.quotationDetails[detailIndex]})

      //this.calculateResumeTotal()
    },
    add(id) {
      this.counter++;
      let index = this.Fees.findIndex(e => e.id === id);
      this.Fees[index].priceFees.push({
        id: 0,
        name: 'Nombre',
        rate: 0,
        quantity: 0,
        total: 0,
        currencyId: 1,
        isVisible: true,
        subtotal: 0,
        profit: 0,
        profitPercentage: 0,
        discount: 0,
        discountPercentage: 0,
      });
    },
    updateOriginAddress(text) {
      this.originAddress = text
    },
    updateDestinationItems(items) {
      this.destinationItems = items;
    },
    updateDestinationAddress(text) {
      this.destinationAddress = text
    },
    TableProfit(PriceId, FeeId) {

      let price = this.Fees.findIndex(p => p.id === PriceId);
      let fee = this.Fees[price].priceFees.findIndex(pf => pf.id === FeeId);
      let Fees = this.Fees[price].priceFees[fee];

      Fees.total = parseFloat(Fees.total);
      Fees.profit = parseFloat(Fees.profit);
      Fees.subtotal = parseFloat(Fees.subtotal)
      Fees.discount = parseFloat(Fees.discount);

      Fees.total = Fees.total + Fees.profit - Fees.discount;

      Fees.profitPercentage = parseFloat(Fees.profitPercentage);

      Fees.profitPercentage = Fees.profit * 100 / Fees.subtotal;

      this.calculateTableTotal()
    },
    TableDiscount(PriceId, FeeId) {

      let price = this.Fees.findIndex(p => p.id === PriceId);
      let fee = this.Fees[price].priceFees.findIndex(pf => pf.id === FeeId);
      let Fees = this.Fees[price].priceFees[fee];

      Fees.total = parseFloat(Fees.total);
      Fees.profit = parseFloat(Fees.profit);
      Fees.subtotal = parseFloat(Fees.subtotal)
      Fees.discount = parseFloat(Fees.discount);

      Fees.total = Fees.total + Fees.profit - Fees.discount;

      Fees.discountPercentage = parseFloat(Fees.discountPercentage);

      Fees.discountPercentage = Fees.discount * 100 / Fees.subtotal;

      this.calculateTableTotal()
    },
    calculateTableTotal() {

      // this.QuotationsData.quotationDetails?.map((qd) => {
      //   let totalElement = 0;
      //   qd.fees.forEach((fee) => {
      //     if (this.DataOrder.transportationCategoryId == 2 ||
      //         this.DataOrder.transportationCategoryId == 3 ||
      //         this.DataOrder.transportationCategoryId == 11 ||
      //         this.DataOrder.transportationCategoryId == 12 ||
      //         this.DataOrder.transportationCategoryId == 13) {
      //       if (!qd.isTotalPrice) {
      //         fee.subtotal = fee.unitPrice * fee.quantity
      //       } else {
      //         fee.subtotal = fee.total
      //       }
      //     } else {
      //       if (!qd.isTotalPrice) {
      //         fee.subtotal = fee.unitPrice * fee.ratableWeight
      //       } else {
      //         fee.subtotal = fee.total
      //       }
      //     }
      //
      //     fee.total = fee.subtotal + fee.profit - fee.discount
      //     totalElement += Number(fee.total);
      //   });
      //   qd.total = Number(1500);
      //
      //   return qd;
      // })

      this.Fees = this.Fees.map((item) => {
        // let totalElement = 0;
        item.priceFees.forEach((element) => {
          //Calculate for non-container cargo

          if (this.DataOrder.transportationCategoryId == 2 ||
              this.DataOrder.transportationCategoryId == 3 ||
              this.DataOrder.transportationCategoryId == 11 ||
              this.DataOrder.transportationCategoryId == 12 ||
              this.DataOrder.transportationCategoryId == 13) {
            if (!item.isTotalPrice) {
              element.subtotal = element.unitPrice * element.quantity
            } else {
              element.subtotal = element.total
            }
          }
          //Calculate for container cargo
          else {
            if (!item.isTotalPrice) {
              element.subtotal = element.unitPrice * element.ratableWeight
            } else {
              element.subtotal = element.total
            }

          }
          element.total = element.subtotal + element.profit - element.discount
          // let totalElement += parseFloat(element.total);
          // totalElement = totalElement + element.profit - element.discount
        });
        item.total = parseFloat(1500);

        return item;
      })

      this.validateTotal();


    },
    originSelectedAereo(selections) {
      this.originAddress = selections.description
      this.Originn = selections;

      this.$nextTick(() => {
        let origin = {};

        origin.address = this.Originn.description
        origin.country = this.Originn.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
        origin.state = this.Originn.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.Originn.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
        origin.city = this.Originn.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' ||
            a === "sublocality" ||
            a === "administrative_area_level_3" ||
            a === 'neighborhood' ||
            a === "administrative_area_level_2") !== -1)[0]?.long_name
        origin.coordinates = `${this.Originn.details.location.lat},${this.Originn.details.location.lng}`;
        let index = this.Originn.airports.findIndex(e => e.selected);


        if (index !== -1) {
          origin.port = {}
          origin.airports.name = this.Originn.airports[index].name;
          origin.airports.city = this.Originn.airports[index].city;
          this.Originn.airports[index].coordinates[0] = parseFloat(this.Originn.airports[index].coordinates[0]);
          this.Originn.airports[index].coordinates[1] = parseFloat(this.Originn.airports[index].coordinates[1]);
          origin.airports.coordinates = `${this.Originn.airports[index].coordinates[0]},${this.Originn.airports[index].coordinates[1]}`;
          origin.airports.country = this.Originn.airports[index].country;
        }

        this.EditOrder.masterPrice.pricesOrigins = [origin];
        this.pTimeLineKey++;
      })
    },
    destinationSelectedAereo(selections) {
      this.destination = selections;
      this.destinationAddress = selections.description;

      this.$nextTick(() => {
        let destination = {}
        destination.country = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
        destination.state = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
        destination.city = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' ||
            a === "sublocality" ||
            a === "administrative_area_level_3" ||
            a === 'neighborhood' ||
            a === "administrative_area_level_2") !== -1)[0]?.long_name
        destination.coordinates = `${this.destination.details.location.lat},${this.destination.details.location.lng}`;
        destination.address = this.destination.description;
        let index = this.destination.airports.findIndex(e => e.selected);


        if (index !== -1) {
          destination.airports = {}
          destination.airports.name = this.destination.airports[index].name;
          destination.airports.city = this.destination.airports[index].city;
          this.destination.airports[index].coordinates[0] = parseFloat(this.destination.airports[index].coordinates[0]);
          this.destination.airports[index].coordinates[1] = parseFloat(this.destination.airports[index].coordinates[1]);
          destination.airports.coordinates = `${this.destination.airports[index].coordinates[0]},${this.destination.airports[index].coordinates[1]}`;
          destination.airports.country = this.destination.airports[index].country
        }

        this.EditOrder.masterPrice.pricesDestination = [destination]
        this.pTimeLineKey++;
      })
    },
    originSelected(selectedItem) {
      this.originAddress = selectedItem.description
      this.Originn = selectedItem;

      this.$nextTick(() => {
        let origin = {};

        origin.address = this.Originn.description
        origin.country = this.Originn.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
        origin.state = this.Originn.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.Originn.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
        origin.city = this.Originn.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' ||
            a === "sublocality" ||
            a === "administrative_area_level_3" ||
            a === 'neighborhood' ||
            a === "administrative_area_level_2") !== -1)[0]?.long_name
        origin.coordinates = `${this.Originn.details.location.lat},${this.Originn.details.location.lng}`;
        let index = this.Originn.ports.findIndex(e => e.selected);


        if (index !== -1) {
          origin.port = {}
          origin.port.name = this.Originn.ports[index].name;
          origin.port.city = this.Originn.ports[index].city;
          this.Originn.ports[index].coordinates[0] = parseFloat(this.Originn.ports[index].coordinates[0]);
          this.Originn.ports[index].coordinates[1] = parseFloat(this.Originn.ports[index].coordinates[1]);
          origin.port.coordinates = `${this.Originn.ports[index].coordinates[0]},${this.Originn.ports[index].coordinates[1]}`;
          origin.port.country = this.Originn.ports[index].country;
        }

        this.EditOrder.masterPrice.pricesOrigins = [origin];
        this.pTimeLineKey++;
      })
    },
    originSelectedTerrestre(selections) {
      this.originAddress = selections.description;
      this.Originn = selections;

      this.$nextTick(() => {

        let origin = {}

        origin.address = this.Originn.description
        origin.country = this.Originn.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
        origin.state = this.Originn.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.Originn.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
        origin.city = this.Originn.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' ||
            a === "sublocality" ||
            a === "administrative_area_level_3" ||
            a === 'neighborhood' ||
            a === "administrative_area_level_2") !== -1)[0]?.long_name
        origin.coordinates = `${this.Originn.details.location.lat},${this.Originn.details.location.lng}`;

        this.EditOrder.masterPrice.pricesOrigins = [origin]
        this.pTimeLineKey++;
      })
    },
    destinationSelectedTerrestre(selections) {
      this.destination = selections;
      this.destinationAddress = selections.description;

      this.$nextTick(() => {
        let destination = {}

        destination.address = this.destination.description
        destination.country = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
        destination.state = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
        destination.city = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' ||
            a === "sublocality" ||
            a === "administrative_area_level_3" ||
            a === 'neighborhood' ||
            a === "administrative_area_level_2") !== -1)[0]?.long_name
        destination.coordinates = `${this.destination.details.location.lat},${this.destination.details.location.lng}`;
        this.EditOrder.masterPrice.pricesDestination = [destination]
        this.pTimeLineKey++;

      })
    },
    destinationSelected: function (selections) {
      this.destination = selections;
      this.destinationAddress = selections.description;

      this.$nextTick(() => {
        let destination = {}
        destination.country = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
        destination.state = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
        destination.city = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' ||
            a === "sublocality" ||
            a === "administrative_area_level_3" ||
            a === 'neighborhood' ||
            a === "administrative_area_level_2") !== -1)[0]?.long_name
        destination.coordinates = `${this.destination.details.location.lat},${this.destination.details.location.lng}`;
        destination.address = this.destination.description;
        let index = this.destination.ports.findIndex(e => e.selected);


        if (index !== -1) {
          destination.port = {}
          destination.port.name = this.destination.ports[index].name;
          destination.port.city = this.destination.ports[index].city;
          this.destination.ports[index].coordinates[0] = parseFloat(this.destination.ports[index].coordinates[0]);
          this.destination.ports[index].coordinates[1] = parseFloat(this.destination.ports[index].coordinates[1]);
          destination.port.coordinates = `${this.destination.ports[index].coordinates[0]},${this.destination.ports[index].coordinates[1]}`;
          destination.port.country = this.destination.ports[index].country
        }

        this.EditOrder.masterPrice.pricesDestination = [destination]
        this.pTimeLineKey++;
      })
    },
    updateOriginItems(items) {
      // console.log('Origin Updates', items);
      this.originItems = items;
    },
    SendNotes: function () {
      this.$http.put(`Orders/${this.Id}/terms`, `"${this.DataOrder.terms}"`,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
      ).then(data => {
        if (data.data.code === 200) {
          // console.log('ok');
        } else {
          console.error(data)
          this.$toast.error('Ocurrio un error al generar las notas');
        }
      }).catch(error => {
        console.error(error)
        //allert('Error')
      })
    },
    async AddQuote(PriceId) {
      let index = this.Fees.findIndex(e => e.id === PriceId);
      this.validateTotalFee(index);
      let indexQuoteDetail = this.QuotationsData.quotationDetails.findIndex(element => element.orderPriceId == PriceId)
      let exist = indexQuoteDetail !== -1
      if (exist) {

        this.QuotationsData.quotationDetails[indexQuoteDetail] = {
          id: this.QuotationsData.quotationDetails[indexQuoteDetail].id,
          name: this.Fees[index].supplier.companyName,
          supplierId: this.Fees[index].supplier.supplierTypeId,
          showTotalPrice: this.Fees[index].showTotalPrice,
          quotationId: 0,
          orderPriceId: this.Fees[index].id,
          isVisible: true,
          profit: this.QuotationsData.quotationDetails[indexQuoteDetail].profit || 0,
          fees: this.QuotationsData.quotationDetails[indexQuoteDetail].fees,
          sale: 0,
          total: 0,
          discount: 0,
          bought: this.Fees[index].total,
          discountPercentage: 0,
          profitPercentage: 0,
        }

        for (let i = 0; i < this.QuotationsData.quotationDetails.length; i++) {
          let tmpDetail = {...this.QuotationsData.quotationDetails[indexQuoteDetail]}

          console.log("🚀 ~ AddQuote ~ this.QuotationsData.quotationDetails[indexQuoteDetail]:", this.QuotationsData.quotationDetails[indexQuoteDetail])
          tmpDetail.fees.forEach(fee => {
            delete fee.currency;
          })
          let fees = await this.$http.put(`Quotation/${this.IdQuotation}/detail/${this.QuotationsData.quotationDetails[indexQuoteDetail].id}/fees`, tmpDetail.fees);
          this.QuotationsData.quotationDetails[indexQuoteDetail].fees = fees.data.data;
        }

        await this.UpdateQuotation();


        //this.validateQuotationDetailTotal();

        this.calculateResumeTotal();

        this.validateRatableWeight();


      }

      if (!exist) {
        this.QuotationsData.quotationDetails.push(
            {
              id: 0,
              name: this.Fees[index].supplier.companyName,
              supplierId: this.Fees[index].supplier.supplierTypeId,
              quotationId: 0,
              orderPriceId: this.Fees[index].id,
              showTotalPrice: this.Fees[index].showTotalPrice,
              fees: this.Fees[index].priceFees,
              isVisible: true,
              profit: 0,
              sale: 0,
              total: 0,
              discount: 0,
              bought: this.Fees[index].total,
              discountPercentage: 0,
              profitPercentage: 0,
            }
        )
        await this.UpdateQuotation();
        this.showSellButton = true;

      }

    },
    removeElement(id) {

      this.counterElement--;

      for (let i = 0; i < this.Element.length; i++) {
        if (this.Element[i].id == id) {
          this.Element.splice(i, 1);
        }
      }

      // console.log(this.Element);

    },
    remove(id) {
      let index = this.BuildQuote.findIndex(e => e.id === id);
      this.BuildQuote.splice(index, 1);

    },
    removeFee(PriceId, Feeid) {
      let index = this.Fees.findIndex(e => e.id === PriceId);
      let indexFee = this.Fees[index].priceFees.findIndex(e => e.id === Feeid);
      this.Fees[index].priceFees.splice(indexFee, 1);
    },
    denseTable(id) {

      this.Fees.forEach((element) => {
        if (element.id == id) {
          element.dense = false;
        }
      })

    },
    GetSupplierName: function (id, supplierId) {
      // console.log(id);
      let index = this.Fees.findIndex(function (el) {
        return el.id == id; // or el.nombre=='T NORTE';
      });
      this.Suppliers.forEach((element) => {
        if (element.id == supplierId) {
          // console.log(id);
          this.Fees[index].supplier.companyName = element.companyName
        }
      })
    },
    DeletePrice(OrderPriceId, quotationId) {
      this.$http.delete(`Quotation/QuotationDetail/${OrderPriceId}/${quotationId}`).then(response => {
        this.showSellButton = false;

        if (response.data.code == 400 || response.data.code == 500)
          this.$toast.error("Algo salio mal, intente mas tarde")
      }).catch(() => {
      }).finally(() => {
        this.GetQuotation();
        this.$store.dispatch('working', false);
      })
    },
    calculateTotal() {
      // //allert('ok')
      this.Fees = this.Fees.map((item) => {
        let totalElement = 0;
        item.priceFees.forEach((element) => {
          totalElement += parseFloat(element.total);
          // totalElement = totalElement + element.profit - element.discount
        });
        item.total = parseFloat(totalElement);
        //item.subtotal = item.total;

        return item;
      })
      this.validateTotal();
    },
    calculateResumeTotal() {
      let totalElement = 0;

      let unique = [];
      let uniquePrice = [];
      this.QuotationsData.quotationDetails.forEach(element => {
        console.log("Total este", {...element});
        unique.push(element.fees.map(elementFee => elementFee.currencyId));
        unique = unique.map(x => parseInt(x, 10));
        const allEqual = arr => arr.every(val => val === arr[0]);
        let equals = allEqual(unique)

        if (element.price.showTotalPrice == true)
          element.price.showTotalPrice = 1;

        else if (element.price.showTotalPrice == false)
          element.price.showTotalPrice = 0

        uniquePrice.push(element.price.showTotalPrice);

        let equalsPrice = allEqual(uniquePrice);


        if (element.price.showTotalPrice == 1 && equals && equalsPrice) {
          this.showResumeTotal = true;

          // No se por que no esta respetando lo ya calculado
          let detailTotal = element.fees.reduce((acc, item) => {
            return acc + item.total;
          }, 0)
          element.total = detailTotal + element.profit - element.discount;

          totalElement += Number(element.total);
        } else
          this.showResumeTotal = false
      })

      this.ResumeTotal = totalElement;
    },
    GetOrderPrices: function () {
      return new Promise((resolve, reject) => {
        if (!this.Id || this.Id == 0) {
          reject('Id is not defined')
        }

        this.$http.get(`Orders/${this.Id}/prices`, {
            headers: {
              'Cache-Control': 'max-age=3'
            }
          })
            .then(response => {
              if (response.data.code == 200) {
                let res = response.data.data;

                res.forEach(item => {
                  item.priceFees.forEach(element => {
                    element.subtotal = element.price * element.quantity
                  });
                });

                this.Fees = res.map(item => this.mapPrice(item));
                this.calculateTableTotal();

                resolve(true)
                this.validateTotal();

              }


              if (response.data.code == 400 || response.data.code == 500)
                this.$toast.error("Algo salio mal, intente mas tarde")
            })
            .catch(error => {
              console.error(error)
            })
            .finally(() => {
              this.$store.dispatch('working', false);
            })
      })
    },
    mapPrice(price) {
      try {
        // console.log(price)
        this.update = true;
        let origins = price.pricesOrigins.map((x) => {
          if (!x.orderPriceId)
            x.orderPriceId = price.Id

          let port = null
          if (x.port) {
            port = {
              name: x.port.name,
              city: x.port.city.name,
              country: x.port.city.state.country.name,
              coordinates: x.port.coordinates,
            }
          }

          delete x.cityId;
          delete x.portId;

          return {
            ...x,
            city: x.city.name,
            state: x.city.state.name,
            country: x.city.state.country.iso2,
            coordinates: x.coordinates,
            port
          }
        });
        // console.log(origins)
        let destinations = price.pricesDestination.map((x) => {
          let port = null
          if (x.port) {
            port = {
              name: x.port.name,
              city: x.port.city.name,
              country: x.port.city.state.country.name,
              coordinates: x.port.coordinates,
            }
          }

          delete x.cityId;
          delete x.portId;

          return {
            ...x,
            IdFee: this.counterElement,
            city: x.city.name,
            state: x.city.state.name,
            country: x.city.state.country.iso2,
            coordinates: x.coordinates,
            port
          }
        })

        if (price.validity) {
          price.validity = price.validity.substr(0, 10)
        }
        if (price.departureTime) {
          price.departureTime = price.departureTime.substr(0, 10)
        }

        return {
          ...price,
          dense: true,
          name: 'Cargo',
          pricesOrigins: origins,
          pricesDestination: destinations,
          bought: 0,
          showAdditionalData: false,
          isUpdating: false,
          hasError: false,
          sale: 0,
          subtotal: 0,
          ResumeVisible: true,
        }
      } catch (error) {
        // console.error(error)
        throw new Error(error)
      }

    },
    calculateProfitPorcentage: function (PriceId) {

      let pos = this.Fees.findIndex(e => e.id === PriceId);

      this.Fees[pos].profit = parseFloat(this.Fees[pos].profit);

      this.Fees[pos].total = parseFloat(this.Fees[pos].total);

      this.Fees[pos].discount = parseFloat(this.Fees[pos].discount);

      this.Fees[pos].profitPercentage = parseFloat(this.Fees[pos].profitPercentage);

      this.Fees[pos].profit = this.Fees[pos].total / 100 * this.Fees[pos].profitPercentage;

      // this.Fees[pos].total = this.Fees[pos].total + this.Fees[pos].profit - this.Fees[pos].discount;

      //   this.calculateTotal();


    },
    calculateTableProfitPorcentage: function (PriceId, FeeId) {

      let pos = this.Fees.findIndex(e => e.id === PriceId);

      let tablePos = this.Fees[pos].priceFees.findIndex(e => e.id == FeeId);

      this.Fees[pos].priceFees[tablePos].profit = parseFloat(this.Fees[pos].priceFees[tablePos].profit);

      this.Fees[pos].subtotal = parseFloat(this.Fees[pos].subtotal);

      this.Fees[pos].priceFees[tablePos].total = parseFloat(this.Fees[pos].priceFees[tablePos].total);

      this.Fees[pos].priceFees[tablePos].profitPercentage = parseFloat(this.Fees[pos].priceFees[tablePos].profitPercentage);

      this.Fees[pos].priceFees[tablePos].profitPercentage = this.Fees[pos].priceFees[tablePos].profit * 100 / this.Fees[pos].priceFees[tablePos].subtotal


    },
    calculateTableProfit: function (PriceId, FeeId) {

      let pos = this.Fees.findIndex(e => e.id === PriceId);

      let tablePos = this.Fees[pos].priceFees.findIndex(e => e.id == FeeId);

      this.Fees[pos].priceFees[tablePos].profit = parseFloat(this.Fees[pos].priceFees[tablePos].profit);

      this.Fees[pos].priceFees[tablePos].profitPercentage = parseFloat(this.Fees[pos].priceFees[tablePos].profitPercentage);

      this.Fees[pos].priceFees[tablePos].profit = this.Fees[pos].priceFees[tablePos].total / 100 * this.Fees[pos].priceFees[tablePos].profitPercentage;

    },
    calculateTableDiscount: function (PriceId, FeeId) {

      let pos = this.Fees.findIndex(e => e.id === PriceId);

      let tablePos = this.Fees[pos].priceFees.findIndex(e => e.id == FeeId);

      this.Fees[pos].priceFees[tablePos].discount = parseFloat(this.Fees[pos].priceFees[tablePos].discount);

      this.Fees[pos].priceFees[tablePos].discountPercentage = parseFloat(this.Fees[pos].priceFees[tablePos].discountPercentage);

      this.Fees[pos].priceFees[tablePos].discount = this.Fees[pos].priceFees[tablePos].total / 100 * this.Fees[pos].priceFees[tablePos].profitPercentage;

    },
    calculateTableDiscountPercentage: function (PriceId, FeeId) {

      let pos = this.Fees.findIndex(e => e.id === PriceId);

      let tablePos = this.Fees[pos].priceFees.findIndex(e => e.id == FeeId);

      this.Fees[pos].priceFees[tablePos].profit = parseFloat(this.Fees[pos].priceFees[tablePos].profit);

      this.Fees[pos].subtotal = parseFloat(this.Fees[pos].subtotal);

      this.Fees[pos].priceFees[tablePos].total = parseFloat(this.Fees[pos].priceFees[tablePos].total);

      this.Fees[pos].priceFees[tablePos].profitPercentage = parseFloat(this.Fees[pos].priceFees[tablePos].profitPercentage);

      this.Fees[pos].priceFees[tablePos].profitPercentage = this.Fees[pos].priceFees[tablePos].profit * 100 / this.Fees[pos].priceFees[tablePos].subtotal


    },
    calculateResume: function (quotationId) {
      let pos = this.QuotationsData.quotationDetails.findIndex(e => e.quotationId === quotationId)
      let FeeResume = this.QuotationsData.quotationDetails[pos];
      FeeResume.profit = parseFloat(FeeResume.profit)
      FeeResume.profitPercentage = parseFloat(FeeResume.profitPercentage);
      FeeResume.discount = parseFloat(FeeResume.discount);
      FeeResume.sale = parseFloat(FeeResume.sale);
      FeeResume.bought = parseFloat(FeeResume.bought)
      FeeResume.sale = FeeResume.profit + FeeResume.bought - FeeResume.discount;
      //this.calculateResumeTotal();
    },
    calculateResumeDiscount: function (PriceId) {

      let pos = this.BuildQuote.findIndex(e => e.id === PriceId);
      this.BuildQuote[pos].discount = parseFloat(this.BuildQuote[pos].discount);
      this.BuildQuote[pos].discountPercentage = parseFloat(this.BuildQuote[pos].discountPercentage);
      this.BuildQuote[pos].sale = parseFloat(this.BuildQuote[pos].sale);
      this.BuildQuote[pos].bought = parseFloat(this.BuildQuote[pos].bought);
      this.BuildQuote[pos].profit = parseFloat(this.BuildQuote[pos].profit)
      this.BuildQuote[pos].discountPercentage = this.BuildQuote[pos].discount * 100 / this.BuildQuote[pos].bought;
      this.BuildQuote[pos].sale = this.BuildQuote[pos].bought + this.BuildQuote[pos].profit - this.BuildQuote[pos].discount;
      //this.calculateResumeTotal();

    },
    calculateTableDiscountPorcentage: function (PriceId, FeeId) {

      let pos = this.Fees.findIndex(e => e.id === PriceId);

      let tablePos = this.Fees[pos].priceFees.findIndex(e => e.id == FeeId);

      this.Fees[pos].priceFees[tablePos].discount = parseFloat(this.Fees[pos].priceFees[tablePos].discount);

      //  this.Fees[pos].subtotal = parseFloat(this.Fees[pos].subtotal);

      this.Fees[pos].priceFees[tablePos].total = parseFloat(this.Fees[pos].priceFees[tablePos].total);

      this.Fees[pos].priceFees[tablePos].discountPercentage = parseFloat(this.Fees[pos].priceFees[tablePos].discountPercentage);

      this.Fees[pos].priceFees[tablePos].discount = this.Fees[pos].priceFees[tablePos].total / 100 * this.Fees[pos].priceFees[tablePos].discountPercentage;

      this.calculateTableTotal();

      // this.Fees[pos].subtotal = this.Fees[pos].total + this.Fees[pos].profit;

    },
    calculateDiscountPercentage: function (PriceId) {

      let pos = this.Fees.findIndex(e => e.id === PriceId);
      this.Fees[pos].discountPercentage = parseFloat(this.Fees[pos].discountPercentage);
      this.Fees[pos].total = parseFloat(this.Fees[pos].total);
      this.Fees[pos].discount = this.Fees[pos].total / 100 * this.Fees[pos].discountPercentage;
      this.Fees[pos].sale = this.Fees[pos].subtotal - this.Fees[pos].discount;

      this.calculateTotal();

    },
    GetDataOrder: function () {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('working', true)
        this.$http.get(`Orders/${this.Id}`, {
          headers: {
            'Cache-Control': 'max-age=3'
          }
        }).then(response => {
          if (response.data.code == 200) {
            this.DataOrder = response.data.data;
            this.EditOrder = response.data.data;

            this.$http.get(`Companies/${this.DataOrder.clientId}`).then(response => {
              this.EditOrder.client = response.data.data;
              console.log(this.EditOrder)
              if(!this.EditOrder.client){
                this.EditOrder.client.name  = ''
                this.EditOrder.client.email  = ''
              }
            })

            let folio = "S";

            folio += this.DataOrder.id + "-";

            if (_GLOBALS.ORDER_TYPE.IMPORT == this.DataOrder.orderTypeId) {
              folio += "I"
            }
            if (_GLOBALS.ORDER_TYPE.EXPORT == this.DataOrder.orderTypeId) {
              folio += "E"
            }
            if (_GLOBALS.ORDER_TYPE.NATIONAL == this.DataOrder.orderTypeId) {
              folio += "N"
            }
            if (_GLOBALS.TRANSPORTATION.MARITIME == this.DataOrder.transportationId) {
              folio += "M"
            }
            if (_GLOBALS.TRANSPORTATION.LAND == this.DataOrder.transportationId) {
              folio += "T"
            }
            if (_GLOBALS.TRANSPORTATION.AIR == this.DataOrder.transportationId) {
              folio += "A"
            }

            if (this.DataOrder.transportationCategory.description == 'Servicio Express') {
              this.DataOrder.transportationCategory.description = 'SE';
            }

            if (this.DataOrder.transportationCategory.description == 'Hand Carrier') {
              this.DataOrder.transportationCategory.description = 'HC';
            }

            if (this.DataOrder.transportationCategory.description == 'Servicio Regular') {
              this.DataOrder.transportationCategory.description = 'SR';
            }

            if (this.cleanText(this.DataOrder.transportationCategory.description) == 'Paqueteria Aerea') {
              this.DataOrder.transportationCategory.description = 'PA';
            }

            if (this.DataOrder.transportationCategory.description == 'Air Charter') {
              this.DataOrder.transportationCategory.description = 'ACH';
            }

            if (this.cleanText(this.DataOrder.transportationCategory.description) == 'Arrastres de contenedores') {
              this.DataOrder.transportationCategory.description = 'ADC';
            }

            if (this.cleanText(this.DataOrder.transportationCategory.description) == 'Bulk-RoRo') {
              this.DataOrder.transportationCategory.description = 'BRR';
            }

            if (this.cleanText(this.DataOrder.transportationCategory.description) == 'Bulk-BreakBulk') {
              this.DataOrder.transportationCategory.description = 'BBB';
            }


            folio += this.DataOrder.transportationCategory.description
            folio += this.DataOrder.origin[0].country ? this.DataOrder.origin[0].country.substring(0, 3).toUpperCase() + "-" : ""
            folio += this.DataOrder.destination[0].country ? this.DataOrder.destination[0].country.substring(0, 3).toUpperCase() : ""
            this.DataOrder.folio = folio;
            this.EditOrder.folio = folio;


            let ratableWeight = 0;
            if (this.DataOrder.transportationCategoryId == 9 || this.DataOrder.transportationId == 2) {
              ratableWeight = parseFloat(this.DataOrder.cargo.reduce((a, b) => a + b.volumetricWeight, 0))
              this.ratableWeightDef = ratableWeight

            }


          }

          this.validateRatableWeight();


          if (response.data.code == 400 || response.data.code == 500)
            this.$toast.error("Algo salio mal, intente mas tarde")
        }).catch(error => {
          console.error(error)
          reject(error)
        }).finally(() => {
          this.$store.dispatch('working', false);
          resolve(true)
        })
      })
    },
    calculateVolumetricWeight() {


      let total = 0;
      this.VolumetricWeigth.cargo.forEach(element => {
        total = total + element;
      })
      this.VolumetricWeigth.total = total;



    },
    getSuppliers: function () {
      this.$store.dispatch('working', true);
      this.$http.get('Suppliers', {
        headers: {
          'Cache-Control': 'max-age=30'
        }
      }).then(response => {
        if (response.data.code == 200) {
          // console.log(response.data.data);
          this.Suppliers = response.data.data;
        }

        if (response.data.code == 400 || response.data.code == 500)
          this.$toast.error("Algo salio mal, intente mas tarde")
      }).catch(() => {
        // console.log(error);
      }).finally(() => {
        this.$store.dispatch('working', false);
      })
    },
    ChangesQuotation: function (QuotationId) {
      console.log("🚀 ~ QuotationId:", QuotationId)
      
      // if (this.QuoteData.length < 1) {
      //   this.UpdateQuotation();
      // }
      // if (this.QuoteData.length > 0)
      //     //this.UpdateQuotation();
      //   this.NewQuotation(QuotationId);
    },
    GeneratePdf: function () {
      this.$store.dispatch('working', true);
      this.validateIfLastVisible()
      this.QuotationsData.id = this.IdQuotation
      this.QuotationsData.orderId = this.Id;

      let quotation = new Quotation(this.QuotationsData);

      this.QuotationsData.isUpdating = true;
      this.$http.put(`Quotation/${this.IdQuotation}`, quotation).finally(async () => {

        // this.QuotationsData.isUpdating = false
        // Send fees to update
        for (let i = 0; i < this.QuotationsData.quotationDetails.length; i++) {
          let tmpDetail = {...this.QuotationsData.quotationDetails[i]}

          tmpDetail.fees.forEach(fee => {
            delete fee.currency;
          })
          let fees = await this.$http.put(`Quotation/${this.IdQuotation}/detail/${this.QuotationsData.quotationDetails[i].id}/fees`, tmpDetail.fees);
          this.QuotationsData.quotationDetails[i].fees = fees.data.data;
        }
        this.InsertPriceIntoQuotation();

        this.$http({
          url: `Orders/generate/pdf/${this.Id}/${this.IdQuotation}`,
          method: 'POST',
          responseType: 'blob', // important
        }).then(response => {
          fileDownload(response.data, `${this.DataOrder.refCode}.pdf`);
          this.$toast.info('Pdf Generado');

        }).catch(() => {
          this.$toast.error("Algo salio mal, intente mas tarde")

        }).finally(() => {
          this.QuotationsData.isUpdating = false;
          this.$store.dispatch('working', false);
        })
      })
    },
    UpdateFees: function () {
      this.$store.dispatch('working', true);
      this.$http.put(`Orders/${this.Id}/prices`, this.Fees).then(response => {
        if (response.data.code == 200) {
          // console.log(response.data.data);
          this.$toast.info('Precio Actualizado');
          this.GetOrderPrices().then(() => {
            this.InsertPriceIntoQuotation()
          });
        }

      }).catch(() => {
        // console.log(error);
        this.$toast.error('Ocurrio un error intente mas tarde');
      }).finally(() => {
        this.$store.dispatch('working', false);
      })
    },
    NewQuotation: function () {
      this.$store.dispatch('working', true);
      this.$http.post(`Quotation`, this.Quotation).then(response => {
        if (response.data.code == 200) {
          // console.log(response.data)
          this.$toast.info("Cotizacion Guardada");
        }
        if (response.data.code == 400 || response.data.code == 500)
          this.$toast.error("Algo salio mal, intente mas tarde")
      }).catch(() => {
        // console.log(error)
      }).finally(() => {
        this.GetQuotation();
        this.$store.dispatch('working', false);
      })
    },
    InsertPriceIntoQuotation: function () {
      let details = this.QuotationsData.quotationDetails.map((item) => {


        // Check if all fees are in position
        if (item.fees.some((fee) => fee.position === 0)) {
          item.fees = item.fees.map((item, index) => {
            return {
              ...item,
              position: index + 1,
            }
          })
        }

        let price = this.Fees.find(fee => fee.id == item.orderPriceId)
        if (price && item.fees.length === 0) {
          let results = price.priceFees;
          if (Object.prototype.hasOwnProperty.call(item, 'fees')) {
            results = price.priceFees.filter(({id: id1}) => !item.fees.some(({orderPriceFeesId: id2}) => id2 === id1));
          }

          let fees = results.map(priceFeeItem => {
            // console.log('aquo');
            let quotationFee = {}

            if (Object.prototype.hasOwnProperty.call(item, 'fees')) {
              let feeItem = item.fees.find(x => x.orderPriceFeesId == priceFeeItem.id)
              if (feeItem) {
                quotationFee = feeItem
              }
            }

            if (!quotationFee.quotationDetailId) {
              quotationFee.quotationDetailId = 0
            }

            if (!quotationFee.unitPrice) {
              quotationFee.unitPrice = 0
            }

            if (!quotationFee.profit) {
              quotationFee.profit = 0
              quotationFee.profitPercentage = 0
            }

            if (!quotationFee.discount) {
              quotationFee.discount = 0
              quotationFee.discountPercentage = 0
            }

            if (!quotationFee.subtotal) {
              quotationFee.subtotal = priceFeeItem.subtotal
              quotationFee.total = priceFeeItem.subtotal
            }

            if (!Object.prototype.hasOwnProperty.call(quotationFee, 'volumetricWeight') || this.DataOrder.transportationCategoryId !== 1) {
              quotationFee.ratableWeight = priceFeeItem.ratableWeight
            }

            if (!Object.prototype.hasOwnProperty.call(quotationFee, 'isVisible')) {
              quotationFee.isVisible = true
            }

            if (!quotationFee.quotationDetailId) {
              quotationFee.hasVat = priceFeeItem.hasVat;
              quotationFee.hasRet = priceFeeItem.hasRet;
            }

            return {
              ...quotationFee,
              quotationDetailId: item.id,
              name: priceFeeItem.name,
              unitPrice: priceFeeItem.unitPrice,
              quantity: priceFeeItem.quantity,
              orderPriceFeesId: priceFeeItem.id,
              currency: priceFeeItem.currency,
              currencyName: priceFeeItem.currency.code,
            }
          })

          let orderFees = [
            ...item.fees,
            ...fees
          ] || [];

          orderFees = orderFees.sort((a, b) => a.position - b.position);
          orderFees.forEach((f) => {
            f.quotationDetailId = item.id;
          })

          return {
            ...item,
            price: {
              ...item.unitPrice,
              ...price
            },
            fees: orderFees
            // fees: fees,
            // multiCurrency: !price.showTotalPrice
          }
        } else {

          let orderFees = item.fees.sort((a, b) => a.position - b.position);
          orderFees.forEach((f) => {
            f.quotationDetailId = item.id;
          })
          return {
            ...item,
            price: {
              ...item.unitPrice,
              ...price,
            },
            fees: orderFees
          }
        }
      })

      this.QuotationsData.quotationDetails.forEach((item, index) => {
        if (item.fees && item.fees.length > 0) {
          this.setFeesAndUpdateQuotation(item.fees)
        }

        this.calculateQuoteDetailTotal(index);
      });

      // console.log(details);

      this.QuotationsData.quotationDetails = details
      this.QuotationsData.quotationDetails.sort((a, b) => a.position - b.position)
      if(this.QuotationsData.quotationDetails[0]?.position != 1) {
        let counter = 1
        this.QuotationsData.quotationDetails.forEach(detail => {
          detail.position = counter
          counter++
        })
      }
      this.EditOrder.masterPrice = this.QuotationsData.quotationDetails?.find(detail => detail.position === 1)?.price;

      // console.log("Details", this.EditOrder.masterPrice)

      let sdetails = JSON.parse(JSON.stringify(details))

      let previewQuotations = sdetails.map((detail) => {
        let feeGroups = detail.fees.reduce((acc, obj) => {
          const property = obj["currencyName"];
          acc[property] = acc[property] || [];
          acc[property].push(obj);
          return acc;
        }, {})

        let keyLength = Object.keys(feeGroups).length

        if (keyLength > 1) {
          for (let feeG in feeGroups) {
            let feeGroup = feeGroups[feeG];

            const invisible = feeGroup.filter(obj => !obj.isVisible)
            const withProfit = feeGroup.filter((fee) => fee.profit > 0)

            if (invisible.length > 0 && invisible.length < feeGroup.length) {
              let toDistribure = feeGroup.reduce((acc, obj) => {
                if (!obj.isVisible) {
                  return acc + obj.total
                } else {
                  return acc
                }
              }, 0)

              const invisibleWithProfit = withProfit.filter(fee => !fee.isVisible)
              let visible = feeGroup.filter(obj => obj.isVisible)

              if (invisibleWithProfit.length > 0 && invisibleWithProfit.length < withProfit.length) {
                const invisibleProfitToDisitribute = invisibleWithProfit.reduce((acc, fee) => {
                  return acc + fee.profit
                }, 0) / (withProfit.length - invisibleWithProfit.length)
                const priceToDistribute = invisibleWithProfit.reduce((acc, fee) => {
                  return acc + fee.total
                }, 0) / (withProfit.length - invisibleWithProfit.length)
                const addToTotal = (invisibleProfitToDisitribute + priceToDistribute)
                visible.forEach((fee) => {
                  if (fee.profit > 0 && fee.isVisible) {
                    let divider = this.showRatableWeigth ? fee.ratableWeight : fee.quantity;
                    fee.total += (addToTotal + fee.profit)
                    fee.price += ((addToTotal + fee.profit) / divider)
                  }
                })
              }

              const distribute = toDistribure / visible.length
              visible.forEach((obj) => {
                let divider = this.showRatableWeigth ? obj.ratableWeight : obj.quantity;
                obj.total += distribute;
                obj.price += (distribute / divider);
              })
              feeGroups[feeG] = visible;
            } else {
              feeGroup.forEach((fee) => {
                let divider = this.showRatableWeigth ? fee.ratableWeight : fee.quantity;
                fee.total += fee.profit
                fee.total -= fee.discount
                fee.price += ((fee.profit - fee.discount) / divider)
              })

              feeGroups[feeG] = feeGroup;
            }
          }
        } else {
          let feeG = Object.keys(feeGroups)[0];
          let feeGroup = feeGroups[feeG];

          const invisible = feeGroup.filter(obj => !obj.isVisible)
          const withProfit = feeGroup.filter((fee) => fee.profit > 0)

          let profit = detail.profit;
          let discount = detail.discount;

          if (invisible.length > 0 && invisible.length < feeGroup.length) {
            const toDistribute = feeGroup.reduce((acc, obj) => {
              if (!obj.isVisible) {
                return acc + obj.total
              } else {
                return acc
              }
            }, 0)

            let visible = detail.fees.filter(obj => obj.isVisible)
            if (withProfit.length === 0) {
              const profitToDistribute = profit / visible.length
              const distribute = toDistribute / visible.length;
              // let discountToDistribute = discount / detail.fees.length

              visible.forEach((fee) => {
                let divider = this.showRatableWeigth ? fee.ratableWeight : fee.quantity;
                fee.total += profitToDistribute + distribute
                fee.price += ((profitToDistribute + distribute) / divider)
              })
            } else {
              const invisibleWithProfit = withProfit.filter(fee => !fee.isVisible)

              if (invisibleWithProfit.length > 0 && invisibleWithProfit.length < withProfit.length) {
                const invisibleProfitToDisitribute = invisibleWithProfit.reduce((acc, fee) => {
                  return acc + fee.profit
                }, 0) / (withProfit.length - invisibleWithProfit.length)
                const priceToDistribute = invisibleWithProfit.reduce((acc, fee) => {
                  return acc + fee.total
                }, 0) / (withProfit.length - invisibleWithProfit.length)
                const addToTotal = (invisibleProfitToDisitribute + priceToDistribute)
                visible.forEach((fee) => {
                  if (fee.profit > 0 && fee.isVisible) {
                    let divider = this.showRatableWeigth ? fee.ratableWeight : fee.quantity;
                    fee.total += (addToTotal + fee.profit)
                    fee.price += ((addToTotal + fee.profit) / divider)
                  }
                })
              } else {
                if (withProfit.length === 0) {
                  const profitToDistribute = profit / visible.length
                  const distribute = toDistribute / visible.length;

                  visible.forEach((fee) => {
                    let divider = this.showRatableWeigth ? fee.ratableWeight : fee.quantity;
                    fee.total += (profitToDistribute + distribute)
                    fee.price += ((profitToDistribute + distribute) / divider)
                  })
                } else {
                  const distribute = toDistribute / visible.length;

                  visible.forEach((fee) => {
                    let divider = this.showRatableWeigth ? fee.ratableWeight : fee.quantity;
                    fee.total += (fee.profit + distribute)
                    fee.price += ((fee.profit + distribute) / divider)
                  })
                }
              }

            }
            feeGroups[feeG] = visible;
          } else {
            if (withProfit.length === 0) {
              let profitToDistribute = profit / feeGroup.length
              let discountToDistribute = discount / feeGroup.length

              feeGroup.forEach((fee) => {
                let divider = this.showRatableWeigth ? fee.ratableWeight : fee.quantity;
                fee.total += profitToDistribute
                fee.price += ((profitToDistribute - discountToDistribute) / divider)
                fee.total -= discountToDistribute
              })
            } else {
              feeGroup.forEach((fee) => {
                let divider = this.showRatableWeigth ? fee.ratableWeight : fee.quantity;
                fee.total += fee.profit
                fee.price += ((fee.profit - fee.discount) / divider)
                fee.total -= fee.discount
              })
            }
            feeGroups[feeG] = feeGroup;
          }
        }


        return {
          ...detail,
          feeGroups: feeGroups
        }
      })

      this.EditOrder.previewQuotations = previewQuotations

      this.dialogKey += 1;

      this.$nextTick(() => {

        this.calculateResumeTotal()
        if (this.dialogQ) {
          if (this.DataOrder.transportationId === 1) {
            this.$refs.autoOrigen.searchPlaces(this.EditOrder.masterPrice.pricesOrigins[0].address).then(() => {
              let originSelected = this.originItems[0];
              originSelected.selected = true;
              // console.log("Llego aqui");
              if (this.EditOrder.masterPrice.pricesOrigins[0].port) {
                let portSelected = originSelected.ports.findIndex(x => x.name == this.EditOrder.masterPrice.pricesOrigins[0].port.name)
                // console.log("portSelected",portSelected);
                if (portSelected !== -1) {
                  originSelected.ports[portSelected].selected = true;
                } else {
                  throw new Error("Port not found in results")
                }
              }
              this.originSelected(originSelected)
            }).finally(() => {
              this.$refs.autoDestino.searchPlaces(this.EditOrder.masterPrice.pricesDestination[0].address).then(() => {
                let destintionSelected = this.destinationItems[0];
                destintionSelected.selected = true;
                // console.log("Llego aqui");
                if (this.EditOrder.masterPrice.pricesDestination[0].port) {
                  let portSelected = destintionSelected.ports.findIndex(x => x.name == this.EditOrder.masterPrice.pricesDestination[0].port.name)
                  // console.log("portSelected",portSelected);
                  if (portSelected !== -1) {
                    destintionSelected.ports[portSelected].selected = true;
                  } else {
                    throw new Error("Port not found in results")
                  }
                }
                // console.log("Origin Selected",destintionSelected);

                this.destinationSelected(destintionSelected)
                this.previewLoading = false;
              })
            })
          } else if (this.DataOrder.transportationId === 2) {
            this.$refs.autoOrigen.searchPlaces(this.EditOrder.masterPrice.pricesOrigins[0].address).then(() => {
              let originSelected = this.EditOrder.masterPrice.originItems[0];
              originSelected.selected = true;
              // console.log("Llego aqui");
              if (this.EditOrder.masterPrice.pricesOrigins[0].airports) {
                let portSelected = originSelected.airports.findIndex(x => x.name == this.EditOrder.masterPrice.pricesOrigins[0].airports.name)
                // console.log("portSelected",portSelected);
                if (portSelected !== -1) {
                  originSelected.airports[portSelected].selected = true;
                } else {
                  throw new Error("Port not found in results")
                }
              }
              this.originSelected(originSelected)
            }).finally(() => {
              this.$refs.autoDestino.searchPlaces(this.EditOrder.masterPrice.pricesDestination[0].address).then(() => {
                let destintionSelected = this.destinationItems[0];
                destintionSelected.selected = true;
                // console.log("Llego aqui");
                if (this.EditOrder.masterPrice.pricesDestination[0].airports) {
                  let portSelected = destintionSelected.airports.findIndex(x => x.name == this.EditOrder.masterPrice.pricesDestination[0].airports.name)
                  // console.log("portSelected",portSelected);
                  if (portSelected !== -1) {
                    destintionSelected.airports[portSelected].selected = true;
                  } else {
                    throw new Error("Port not found in results")
                  }
                }
                // console.log("Origin Selected",destintionSelected);

                this.destinationSelected(destintionSelected)
                this.previewLoading = false;
              })
            })
          } else if (this.DataOrder.transportationId === 3) {
            this.$refs.autoOrigen.searchPlaces(this.EditOrder.masterPrice.pricesOrigins[0].address).then(() => {
              let originSelected = this.originItems[0];
              originSelected.selected = true;
              this.originSelectedTerrestre(originSelected)
            }).finally(() => {
              this.$refs.autoDestino.searchPlaces(this.EditOrder.masterPrice.pricesDestination[0].address).then(() => {
                let destintionSelected = this.destinationItems[0];
                destintionSelected.selected = true;

                this.destinationSelectedTerrestre(destintionSelected)
              })


              this.previewLoading = false;
            });
          }
        }
      })
    },
    getDialog2() {

      console.log(this.DataOrder, "HOLA")
      this.GetQuotation()
      this.dialog3 = true
    },
    async getDialog(){
      console.log(this.DataOrder)

      this.QuotationsData.id = this.IdQuotation
      this.QuotationsData.orderId = this.Id;

      let quotation = new Quotation(this.QuotationsData);

      this.QuotationsData.isUpdating = true;
      this.$http.put(`Quotation/${this.IdQuotation}`, quotation).finally(async () => {
        for (let i = 0; i < this.QuotationsData.quotationDetails.length; i++) {
          let tmpDetail = {...this.QuotationsData.quotationDetails[i]}

          tmpDetail.fees.forEach(fee => {
            delete fee.currency;
          })
          let fees = await this.$http.put(`Quotation/${this.IdQuotation}/detail/${this.QuotationsData.quotationDetails[i].id}/fees`, tmpDetail.fees);
          this.QuotationsData.quotationDetails[i].fees = fees.data.data;
        }
        this.InsertPriceIntoQuotation();

        this.dialog=true
        this.GetQuotation()
        // this.previewItem()
      })
    },

    GetQuotation: function () {
      // //allert('Quotation')
      this.$http.get(`Quotation/${this.IdQuotation}`).then(response => {
        console.log('antes de fallar 2', response)
        if (response.data.code == 200) {
          try {
            // console.log(response.data)
            let res = response.data.data;
            this.visbleByClient = res.isVisible;
            console.log(this.visbleByClient, "aqui estoyyy")
            // if
            let details = res.quotationDetails.map((item) => {
              let detailTotal = item.fees.reduce((acc, item) => {
                return acc + item.unitPrice;
              }, 0)
              this.itemsVenta2 = res.quotationDetails[0].fees
              console.log('antes de fallar', res)
              this.itemsCompra2 = res.quotationDetails[0].price.priceFees
              this.infoQuotation = res.quotationDetails


              return {
                ...item,
                bought: detailTotal ? detailTotal : item.price.total,
                profitFees: 0,
                discountFees: 0,
                total: detailTotal ? detailTotal : item.price.total + item.profit - item.discount,
                sale: detailTotal ? detailTotal : item.price.total + item.profit - item.discount,
              }
            })

            res.quotationDetails = details;
            res.isUpdating = false
            this.QuotationsData = res;
            this.InsertPriceIntoQuotation()

            this.EditOrder.quotationDetails = this.QuotationDetails;

            this.EditOrder.quotationDetails


          } catch (error) {
            console.error(error)
            throw new Error(error)
          }
        }

        if (response.data.code == 400 || response.data.code == 500)
          this.$toast.error("Algo salio mal, intente mas tarde")
      }).catch(() => {
        // console.log(error)
      }).finally(() => {
        this.$store.dispatch('working', false);
      })
    },
    UpdateQuotation: async function () {
      this.SendNotes();
      this.$store.dispatch('working', true);

      // Acomodamos el array para que se muestre siempre el primer quotation con el mismo transportation ID que el de la orden.....
      /*if(this.QuotationsData.quotationDetails.some((el) => el.price)){
        let index = this.QuotationsData.quotationDetails.findIndex(detail => detail.price.transportationId === this.DataOrder.transportationId);
        this.QuotationsData.quotationDetails.unshift(this.QuotationsData.quotationDetails.splice(index, 1)[0]);
      }
*/
      this.QuotationsData.quotationDetails.map((detail, index) => {
        detail.position = index + 1;
      })
      this.QuotationsData.id = this.IdQuotation
      this.QuotationsData.orderId = this.Id;

      let tempData = JSON.parse(JSON.stringify(this.QuotationsData))
      // let quotation = new Quotation(this.QuotationsData);
      tempData.quotationDetails.forEach((detail) => {
        delete detail.price;
        delete detail.fees;
      })

      this.$http.put(`Quotation/${this.IdQuotation}`, tempData).then(response => {
        if (response.data.code == 200) {
          // console.log(response.data)
          this.$toast.info('Cotizacion Actualizada');  
        }
        if (response.data.code == 400 || response.data.code == 500){
          this.$toast.error("Algo salio mal, intente mas tarde")
        }
      }).catch(() => {
        // console.log(error)
      }).finally(() => {
        this.GetQuotation();
        this.$store.dispatch('working', false);
      })
    },
  },
  mounted() {
    this.getCustomers();
    this.getOperators();
  },
  beforeMount() {

    const pageAccessedByReload = (
        (window.performance.navigation && window.performance.navigation.type === 1) ||
        window.performance
            .getEntriesByType('navigation')
            .map((nav) => nav.type)
            .includes('reload')
    );

    if (!pageAccessedByReload) {
      this.$hub.Connection.on("QuotationUpdated", () => {

        this.GetOrderPrices().then(() => {
          this.GetQuotation();
        })
      })

      this.$hub.Connection.on("QuotationCreated", () => {

        this.GetOrderPrices().then(() => {
          this.GetQuotation();
        })
      })
    }



    this.GetDataOrder().then(() => {
      this.GetOrderPrices().then(() => {
        this.GetQuotation();
      });
    })
    ;
    this.getSuppliers();
  },
  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        const options = {
          animation: 150,
          onUpdate: function (event) {
            vnode.child.$emit('sorted', event)
          }
        }
        // console.log("Este mero");
        // console.log(el.getElementsByTagName('tbody')[0]);
        Sortable.create(el.getElementsByTagName('tbody')[0], options)
      }
    }
  },
}
</script>

<style scoped lang="scss">

@import '~vuetify/src/components/VStepper/_variables.scss';

.fixed-col-size {
  min-width: 250px;
}

.v-timeline:before {
  bottom: 0;
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  width: 0px;
}

//> > > .v-text-field {
//  font-size: .950rem;
//  width: 100px;
//}

#quotationTable {
  width: 100%;
}

#quotationTableQ {
  width: 50%;
}

#quotationTable tbody > tr > td {
  width: 20%
}

#quotationTableQ tbody > tr > td {
  width: 10%
}

.table-fee-row > td {
  min-width: 100px;
  max-width: 250px;
  padding: 0 !important;
}

.table-fee-row > td.table-actions-row {
  max-width: 30px;
  min-width: 30px;
}

.table-fee-input > > > .v-input__control .v-input__slot {
  padding: 0;
}

.table-fee-input {
  padding: 0;
  margin: 0
}
</style>
