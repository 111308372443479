`<template>
  <div>
    <v-card elevation="0">
      <v-row justify="center">
        <v-col md="12">
            <template>
              <v-row>
                <v-col md="12">
                    <v-data-table
                        :headers="headers"
                        :hide-default-footer="true"
                        :pagination="options"
                        :items="Data"
                        :items-per-page="25"
                        dense
                        item-key="name"
                        :search="search"
                    >
                      <template v-slot:item.view="{item}">
                        <v-btn  text ripple @click="Proceed(item.id)">

                          <v-icon color="primary">mdi-notebook-outline</v-icon>

                        </v-btn>
                      </template>

                      <template v-slot:item.urgent="{item}">
                        <v-icon :color="item.urgent?'red':''">mdi-alert</v-icon>
                      </template>

                      <template v-slot:item.isExpired="{item}">
                        <v-icon :color="item.isExpired?'red':''">mdi-alert</v-icon>
                      </template>

                      <template v-slot:item.pricerData="{item}">
                        <div v-if="item.pricer != null">
                          {{item.pricer & item.pricer.firstName ?  item.pricer.firstName : "" }} {{item.pricer && item.pricer.lastName ?  item.pricer.lastName : "" }}
                        </div>
                        <div v-else-if="item.salesManager">
                          {{item.salesManager && item.salesManager.firstName ? item.salesManager.firstName : "" }} {{item.salesManager && item.salesManager.lastName ? item.salesManager.lastName : "" }}
                        </div>
                      </template>
                      <template v-slot:item.createdAt="{item}">
                        {{ item.createdAt }}
                      </template>

                    </v-data-table>
                    <v-pagination
                        v-model="options.page"
                        :length="MetaData.totalPages"
                        @input="GetData()"
                        @next="GetData()"
                        @previous="GetData()"
                    ></v-pagination>
                </v-col>
              </v-row>
            </template>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog v-model="newQuote" class="pa-0" width="1200" transition="dialog-bottom-transition" scrollable>
      <v-card>
        <v-toolbar
            flat
            dark
            color="primary"
        >

          <v-toolbar-title><v-icon>mdi-frequently-asked-questions</v-icon> New Quote</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
              icon
              dark
              left
              @click="newQuote = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <Maritimo
              v-if="showComponent == 1"
              @OrderCreated="OrderCreated()"
              v-bind="QuotData"
          >
            <template v-slot:quoteData>
              <QuotData
                  v-on:QuotData="RecibeData"
                  v-on:Transporte="showComponent = $event"
                  @markersUpdated="setMarkers"
                  v-bind="QuotData"
              >
                <template slot="autocomplete">
                  <div class="row no-gutters position-relative">
                    <div class="col-6 fixed-col-size">
                      <googleMapsAutocompleteVuetify
                          label="Origen"
                          :search="originAddress"
                          :items="originItems"
                          :selections="origin"
                          transportation="SEA"
                          placeholder="Ingrese un origen"
                          @change="originSelected"
                          @updateAddress="updateOriginAddress"
                          @updateItems="updateOriginItems"
                          :isOpen="true"
                          :id="1"
                      >
                      </googleMapsAutocompleteVuetify>
                    </div>
                    <div class="col-6 fixed-col-size">
                      <googleMapsAutocompleteVuetify
                          label="Destino"
                          :search="destinationAddress"
                          :items="destinationItems"
                          :selections="destination"
                          transportation="SEA"
                          placeholder="Ingrese un destino"
                          @change="destinationSelected"
                          @updateAddress="updateDestinationAddress"
                          @updateItems="updateDestinationItems"
                          :id="2"
                      >
                      </googleMapsAutocompleteVuetify>
                    </div>
                  </div>
                </template>
              </QuotData>
            </template>

          </Maritimo>

          <Aereo
              v-if="showComponent === 2"
              v-bind="QuotData"
              @OrderCreated="OrderCreated()"
          >
            <template v-slot:quoteData>
              <QuotData
                  v-on:QuotData="RecibeData"
                  v-on:Transporte="showComponent=$event"
                  @markersUpdated="setMarkers"
                  v-bind="QuotData"
              >
                <template slot="autocomplete">
                  <div class="row no-gutters position-relative">
                    <div class="col-6 fixed-col-size">
                      <googleMapsAutocompleteVuetify
                          label="Origen"
                          :search="originAddress"
                          :items="originItems"
                          :selections="origin"
                          transportation="SEA"
                          placeholder="Ingrese un origen"
                          @change="originSelected"
                          @updateAddress="updateOriginAddress"
                          @updateItems="updateOriginItems"
                          :isOpen="true"
                          :id="3"
                      >
                      </googleMapsAutocompleteVuetify>
                    </div>
                    <div class="col-6 fixed-col-size">
                      <googleMapsAutocompleteVuetify
                          label="Destino"
                          :search="destinationAddress"
                          :items="destinationItems"
                          :selections="destination"
                          transportation="SEA"
                          placeholder="Ingrese un destino"
                          @change="destinationSelected"
                          @updateAddress="updateDestinationAddress"
                          @updateItems="updateDestinationItems"
                          :id="4"
                      >
                      </googleMapsAutocompleteVuetify>
                    </div>
                  </div>
                </template>
              </QuotData>
            </template>

          </Aereo>

          <Terrestre
              v-if="showComponent == 3"
              v-bind="QuotData"
              @OrderCreated="OrderCreated()"
          >
            <template v-slot:quoteData>
              <QuotData
                  v-on:QuotData="RecibeData"
                  v-on:Transporte="showComponent=$event"
                  @markersUpdated="setMarkers"
                  v-bind="QuotData"
              >
                <template slot="autocomplete">
                  <div class="row no-gutters position-relative">
                    <div class="col-6 fixed-col-size">
                      <googleMapsAutocompleteVuetify
                          label="Origen"
                          :search="originAddress"
                          :items="originItems"
                          :selections="origin"
                          transportation="SEA"
                          placeholder="Ingrese un origen"
                          @change="originSelected"
                          @updateAddress="updateOriginAddress"
                          @updateItems="updateOriginItems"
                          :isOpen="true"
                          :id="3"
                      >
                      </googleMapsAutocompleteVuetify>
                    </div>
                    <div class="col-6 fixed-col-size">
                      <googleMapsAutocompleteVuetify
                          label="Destino"
                          :search="destinationAddress"
                          :items="destinationItems"
                          :selections="destination"
                          transportation="SEA"
                          placeholder="Ingrese un destino"
                          @change="destinationSelected"
                          @updateAddress="updateDestinationAddress"
                          @updateItems="updateDestinationItems"
                          :id="4"
                      >
                      </googleMapsAutocompleteVuetify>
                    </div>
                  </div>
                </template>
              </QuotData>
            </template>

          </Terrestre>

        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="testdialog" class="pa-0" width="1200" transition="dialog-bottom-transition" scrollable>
      <v-card>
        <v-toolbar flat dark color="primary">

          <v-toolbar-title>
            <v-icon>mdi-frequently-asked-questions</v-icon> Edit Quote
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark left @click="testdialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <QuotData class="mt-8" v-bind="QuotData">
            <template slot="autocomplete">
              <div class="row no-gutters position-relative">
                <div class="col-6 fixed-col-size">
                  <googleMapsAutocompleteVuetify
                      ref="originAutocomplete"
                      label="Origen"
                      :search="originAddress"
                      :items="originItems"
                      :selections="origin"
                      transportation="SEA"
                      placeholder="Ingrese un origen"
                      @change="originSelected"
                      @updateAddress="updateOriginAddress"
                      @updateItems="updateOriginItems"
                      :isOpen="true"
                      :id="3"
                  >
                  </googleMapsAutocompleteVuetify>
                </div>
                <div class="col-6 fixed-col-size">
                  <googleMapsAutocompleteVuetify
                      ref="destinationAutocomplete"
                      label="Destino"
                      :search="destinationAddress"
                      :items="destinationItems"
                      :selections="destination"
                      transportation="SEA"
                      placeholder="Ingrese un destino"
                      @change="destinationSelected"
                      @updateAddress="updateDestinationAddress"
                      @updateItems="updateDestinationItems"
                      :id="4"
                  >
                  </googleMapsAutocompleteVuetify>
                </div>
              </div>
            </template>
          </QuotData>
          <Maritimo v-if="showComponent == 1" @OrderUpdated="GetData()" v-bind:DataOrder="DataOrder" v-bind="QuotData" v-bind:Edit="Edit">
            <template v-slot:btn-quote>
              ¡Actualizar Cotizacion!
            </template>
          </Maritimo>

          <Aereo v-if="showComponent == 2" v-bind:DataOrder="DataOrder" v-bind:LoadData="DataOrder.cargo" v-bind:Services="DataOrder.services">
            <template v-slot:btn-quote>
              <v-btn color="primary" x-large>¡Actualizar Cotizacion!</v-btn>
            </template>
          </Aereo>

          <Terrestre2 v-if="showComponent == 3" v-bind:DataOrder="DataOrder" v-bind:LoadData="DataOrder.cargo" v-bind:Services="DataOrder.services">
            <template v-slot:btn-quote>
              <v-btn color="primary" x-large>¡Actualizar Cotizacion!</v-btn>
            </template>
          </Terrestre2>

        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

  import googleMapsAutocompleteVuetify from "@/components/google-maps-autocomplete-vuetify/index";
  import Maritimo from "../../../Quote/Maritimo";
  import QuotData from "../../../Quote/QuotData";
  import Aereo from "../../../Quote/Aereo";
  import Terrestre from "../../../Quote/Terrestre";
  import folio_mixin from "@/mixins/folio_mixin";

  let connection;



  export default {
    name: "Request",
    mixins: [folio_mixin],
    components: {
      Terrestre,
      Aereo,
      QuotData,
      Maritimo,


      googleMapsAutocompleteVuetify
    },
    templates: {
      inputdate: {
        zone: "client",
        // format: "yyyy-MM-dd",
        format: "dd/MM/yyyy"
      }
    },
    watch:{
      idOrder(val){
        this.GetDataOrder(val);
      }
    },

    data() {
      return {
        dialog: false,
        DataOrder:{},
        e1: 1,
        search:"",
        MetaData:{},
        Edit:{},
        dataService:{
          Almacenaje: 0,
          Seguro: 0,
          Embalaje: 0,
          Financiamiento: 0,
          Recoleccion: 0,
          DespachoEnOrigen: 0,
          GastosPortuariosOrigen: 0,
          FleteMaritimo: 0,
          GastosPortuariosDestino: 0,
          DespachoDeImportacion: 0,
          Entrega: 0,
          AgenteAduanal:0,
          Almacenamiento:0,
          CustodiaArmada:0,
          Paletizadora:0,
          Inspeccion:0,
          Maniobras:0,
          SeguroMercancias:0,
          resulImage: ""
        },
        idOrder:null,
        options:{
          page: 1,
          itemsPerPage: 25,
          pageStart: 0,
          pageStop: 0,
          pageCount: 0,
          itemsLength: 0
        },
        QuotDataProp:{},
        showComponent:1,
        dataRealTime: "",
        originAddress: "",
        destinationAddress: "",
        originSelections: {},
        destinationSelections: {},
        destinationItems: [],
        originItems: [],
        newQuote: false,
        QuotData: {
          Typetransportation: null,
          TypeService: null,
          Inconterm: null,
          typeRoute: null,
          dueDate: null,
          origin: {},
          destination: {},
          searchVariables: {},
          DestinationPort: {},
          OriginPort: {},
          DataService:{},
          Services:{
            Almacenaje: 0,
            Seguro: 0,
            Embalaje: 0,
            Financiamiento: 0,
            Recoleccion: 0,
            DespachoEnOrigen: 0,
            GastosPortuariosOrigen: 0,
            FleteMaritimo: 0,
            GastosPortuariosDestino: 0,
            DespachoDeImportacion: 0,
            Entrega: 0,
            AgenteAduanal:0,
            Almacenamiento:0,
            CustodiaArmada:0,
            Paletizadora:0,
            Inspeccion:0,
            Maniobras:0,
            SeguroMercancias:0,
            resulImage: ""
          },
          AutocompleteOrigin: false,
          AutocompleteDestination: false,

        },
        Typetransportation: null,
        TypeService: null,
        Inconterm: null,
        typeRoute: null,
        dueDate: null,
        origin: {},
        destination: {},
        searchVariables: {},
        DestinationPort: {},
        OriginPort: {},
        AutocompleteOrigin: false,
        AutocompleteDestination: false,
        proceedItemData: [],
        headers: [
          { text: 'Folio', value: 'refCode' },
          {
            text: 'Recibida',
            value: 'createdAt'
          },
          { text: 'Mercancia lista', value: 'departureDate' },
          {
            text: 'Cliente',
            value: 'client.name'
          },
          {
            text: 'Responsable',
            value: 'pricerData'
          },
          {
            text: 'Servicio',
            value: 'orderType.description'
          },
          {
            text: 'Status',
            value: 'statusOrder'
          },
          {
            text: 'Expirada',
            value: 'isExpired'
          },
          {
            text: 'Urgente',
            value: 'urgent'
          },
          {
            text: 'Detalles',
            value: 'view'
          },

        ],
        Data: [],
        testdialog: false,

      }
    },
    methods: {
      Proceed:function (id){
        console.log(id)
        this.proceed = true;
        this.idOrder = id;
        this.$router.push({path: `ProceedQuoteSales/${this.idOrder}`})
      },
      GetData: function () {
        this.testdialog = false;
        this.$store.dispatch('working', true);

        this.$http.get('Orders/Open',
            {
              params:{
                PageSize:this.options.itemsPerPage,
                PageNumber:this.options.page,
              }
            }
        ).then(response => {
          if (response.data.code == 200) {
            let res = response.data.data;
            this.MetaData = response.data.meta
            this.Data = res.map(item => {
              return {
                ...item,
                departureDate: this.$luxon(item.departureDate, {output: "full"}),
               // departureDate: new Date(item.departureDate).toLocaleDateString("es-MX"),
               // createdAt: new Date(item.createdAt).toLocaleDateString("es-MX")+" "+new Date(item.createdAt).toLocaleTimeString("es-MX"),
               // createdAt: new Date(item.createdAt).toLocaleTimeString("es-MX"),
                createdAt: this.$luxon(item.createdAt, {output: "full"}),
                dangerous: item.cargo.filter(x => x.Dangerous).length > 0,
                folio: this.returnFolio(item),
              }
            })
            console.log(this.Data);
            this.QuantityStatus = this.Data.length
            console.log(this.QuantityStatus);
            this.$emit('SalesReceivedQuantity', this.QuantityStatus);
          }

          if (response.data.code == 400 || response.data.code == 500) {
            this.$toast.error("Algo salio mal, intente mas tarde")
          }



        }).catch(error => {

          console.log(error)

        }).finally(() => {
          this.$store.dispatch('working', false);
        })

      },
      setMarkers: function(markers){
        console.log(markers)
      },
      OrderCreated(){
        this.newQuote = false;
        this.originAddress = "",
        this.origin = {},
        this.originItems  = [],
        this.destinationAddress = "",
        this.destination = {},
        this.destinationItems  = [],
        this.QuotDataDefault();
      },
      QuotDataDefault(){
        this.QuotData = {
          Typetransportation: null,
          TypeService:null,
          Inconterm:null,
          typeRoute:null,
          dueDate:new Date().toISOString().substr(0, 10),
          origin: {},
          destination: {},
          searchVariables: {},
          DestinationPort:{},
          OriginPort:{},
          AutocompleteOrigin:false,
          AutocompleteDestination:false,
          DataService:{},
        }
      },
      RecibeData:function (data){
        console.log(data)
        this.QuotData.Typetransportation = data.transport;
        this.QuotData.TypeService = data.service;
        this.QuotData.Inconterm = data.incoterm;
        this.QuotData.Type = data.typeRoute;
        this.QuotData.dueDate = data.dueDate;
        this.QuotData.AutocompleteOrigin = false;
        this.QuotData.DataService = data.DataService;

        if(this.origin.description) {
          console.log(this.origin)
          this.QuotData.origin["city"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality') !== -1)[0].long_name
          this.QuotData.origin["state"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0].long_name
          this.QuotData.origin["country"] =  this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
          this.QuotData.origin["coordinates"] = this.origin.details.location
          this.QuotData.AutocompleteOrigin = true;
        }

        if(this.destination.description) {
          console.log(this.destination)
          this.QuotData.destination["city"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality') !== -1)[0].long_name
          this.QuotData.destination["state"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0].long_name
          this.QuotData.destination["country"] =  this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
          this.QuotData.destination["coordinates"] = this.destination.details.location
          this.QuotData.AutocompleteDestination = true;
        }

        console.log(this.QuotData)

      },


      GetSignalData(data) {
        console.log(data)
      },
      updateOriginAddress(text){
        this.originAddress = text
      },
      updateOriginItems(items){
        console.log(items)
        this.originItems = items;
      },
      updateDestinationItems(items){
        this.destinationItems = items;
      },
      updateDestinationAddress(text){
        this.destinationAddress = text
      },
      originSelected(selections) {
        try {
          console.log(selections)
          this.origin = selections
          this.$nextTick(() => {
            try {
              if(!Array.isArray(this.origin)) {
                if(this.origin.details){
                  this.QuotData.origin["city"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality') !== -1)[0].long_name
                  this.QuotData.origin["state"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0].long_name
                  this.QuotData.origin["country"] =  this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
                  this.QuotData.origin["coordinates"] = this.origin.details.location;
                }
                this.QuotData.origin["ports"] = this.origin.ports;
                this.QuotData.origin["address"] = this.origin.description;
                this.QuotData.AutocompleteOrigin = true;
                let index = this.origin.ports.findIndex(e => e.selected);

                this.QuotData.origin["ports"] = this.origin.ports;


                this.origin.address = this.originSelections.description;
                if(index !== -1) {
                  this.QuotData.OriginPort = this.origin.ports[index]
                  this.QuotData.origin["ports"].name = this.origin.ports[index].name;
                  this.QuotData.origin["ports"].city = this.origin.ports[index].city;

                  if(Array.isArray(this.origin.ports[index].coordinates)){
                    this.origin.ports[index].coordinates[0] = parseInt(this.origin.ports[index].coordinates[0]);
                    this.origin.ports[index].coordinates[1] = parseInt(this.origin.ports[index].coordinates[1]);
                    this.QuotData.origin["ports"].coordinates = `${this.origin.ports[index].coordinates[0]},${this.origin.ports[index].coordinates[1]}`;
                  }

                  this.QuotData.origin["ports"].country = this.origin.ports[index].country;
                }
                console.log(this.QuotData.origin);
              }
            }
            catch(e){
              throw new Error(`There was an error while selecting the item ${JSON.stringify(selections)} \n` + e)
            }
          })
        } catch(error){
          console.log(error)
          throw new Error(`There was an error while selecting a new origin ${JSON.stringify(selections)} \n` + error)
        }
      },
      destinationSelected: function (selections) {
        this.destination = selections
        this.$nextTick(() => {
          if(!Array.isArray(this.destination)) {
            if(this.destination.details){
              this.QuotData.destination["city"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality') !== -1)[0].long_name
              this.QuotData.destination["state"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0].long_name
              this.QuotData.destination["country"] =  this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
              this.QuotData.destination["coordinates"] = this.destination.details.location
            }
            this.QuotData.destination["ports"] = this.destination.ports;
            this.QuotData.AutocompleteDestination = true;
            this.QuotData.destination["address"] = this.destination.description;

            let index = this.destination.ports.findIndex(e => e.selected);
            this.QuotData.OriginPort = this.origin.ports[index]
            this.QuotData.destination["ports"] = this.destination.ports;
            console.log(index)

            // this.Origin.address = this.originSelections.description;
            if(index !== -1) {
              this.QuotData.DestinationPort = this.destination.ports[index]
              this.QuotData.destination["ports"].name = this.origin.ports[index].name;
              this.QuotData.destination["ports"].city = this.origin.ports[index].city;
              this.destination.ports[index].coordinates[0] = parseInt(this.destination.ports[index].coordinates[0]);
              this.destination.ports[index].coordinates[1] = parseInt(this.destination.ports[index].coordinates[1]);
              this.QuotData.destination["ports"].coordinates = `${this.destination.ports[index].coordinates[0]},${this.destination.ports[index].coordinates[1]}`;
              this.QuotData.destination["ports"].country = this.destination.ports[index].country;
            }

          }
        })
      },

      NewOrder(){
        let UserRole = this.$store.getters.roles;

        if (UserRole.indexOf('Ventas') > -1) {
          this.newQuote=true;
        }else
          this.$toast.info('Su usuario no puede crear cotizaciones');
      },

      DetailQuote: function (id) {
        this.idOrder = id;
      },
      GetDataService(){
         this.DataOrder.services.forEach(element=>{

           if (element.serviceId == 14) {
             this.QuotData.Services.Financiamiento = '1';
           }

           if (element.serviceId == 8) {
             this.QuotData.Services.Seguro = '1';

           }

           if(element.serviceId == 16){
             this.QuotData.Services.Almacenaje = '1'
           }

           if (element.serviceId == 1) {
             this.QuotData.Services.Embalaje = '1';
           }

           if (element.serviceId == 2) {
             this.QuotData.Services.Recoleccion = '1';
           }

           if (element.serviceId == 3) {
             this.QuotData.Services.DespachoEnOrigen = '1';
           }

           if (element.serviceId == 4)
             this.QuotData.Services.GastosPortuariosOrigen = '1'

           if (element.serviceId == 5) {
             this.QuotData.Services.FleteMaritimo = '1';
           }

           if (element.serviceId == 10)
             this.QuotData.Services.GastosPortuariosDestino = '1'

           if (element.serviceId == 12)
             this.QuotData.Services.DespachoDeImportacion = '1'

           if (element.serviceId == 13)
             this.QuotData.Services.Entrega = '1'

         })
        console.log(this.dataService);
      },
      GetDataOrder(id){
        this.$store.dispatch('working', true);
        this.$http.get(`Orders/${id}`, {
          headers: {
            'Cache-Control': 'max-age=3'
          }
        }).then(({data}) => {
          if (data.code == 200) {
            this.DataOrder = data.data
            this.setQuoteData()
            this.testdialog = true;
            this.showComponent = this.DataOrder.transportationId;
            this.Edit.edit = true;
            this.Edit.idOrder = this.idOrder;
            let origin = data.data.origin[0]
            let pol = data.data.pol
            let destination = data.data.destination[0]
            let pod = data.data.pod

            this.$refs['originAutocomplete'].searchPlaces(origin.address).then(() => {
              let originSelected = this.originItems[0]
              if(pol){
                let portSelected = originSelected.ports.findIndex(x => x.name == pol.name)
                if(portSelected !== -1)
                  originSelected.ports[portSelected].selected = true
                else
                  throw new Error('Port not found in results')
              }

              this.origin = originSelected
              this.originSelected(this.origin)
            }).finally(() => {
              this.$refs['destinationAutocomplete'].searchPlaces(destination.address).then(() => {
                let destinationSelected = this.destinationItems[0]
                if(pod){
                  let portSelected = destinationSelected.ports.findIndex(x => x.name == pod.name)
                  if(portSelected !== -1)
                    destinationSelected.ports[portSelected].selected = true
                  else
                    throw new Error('Port not found in results')
                }

                this.destination = destinationSelected
                this.destinationSelected(this.destination)
              })
            })

            this.GetDataService();
          }

          if (data.code == 400 || data.code == 500) {
            this.$toast.error("Algo salio mal, intente mas tarde")
          }
        }).catch(error => {
          console.error(error);

          if (error.response.code == 401) {
            this.$store.commit('reset');
            this.$toast.error("La sesion expiro");
            this.$router.replace('/login')
          }

        }).finally(() => {
          this.$store.dispatch('working', false);
        })

      },
      UpdateItemData: function (data) {
        this.Data.unshift(data);
      },
      setQuoteData(){
        console.log(this.DataOrder)
        this.QuotData.Typetransportation = this.DataOrder.transportationId;
        this.QuotData.TypeService = this.DataOrder.transportationCategoryId;
        this.QuotData.Inconterm = this.DataOrder.incotermId;
        this.QuotData.Type = this.DataOrder.orderTypeId;
        this.QuotData.dueDate = this.DataOrder.departureDate;
        this.QuotData.dueDate = this.moment(this.QuotData.dueDate).format('YYYY-MM-DD');
        this.QuotData.DataService = this.DataOrder.cargo;

        if(this.origin.description) {
          console.log(this.origin)
          this.QuotData.origin["city"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality') !== -1)[0].long_name
          this.QuotData.origin["state"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0].long_name
          this.QuotData.origin["country"] =  this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
          this.QuotData.origin["coordinates"] = this.origin.details.location
          this.QuotData.AutocompleteOrigin = true;
        }

        if(this.destination.description) {
          console.log(this.destination)
          this.QuotData.destination["city"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality') !== -1)[0].long_name
          this.QuotData.destination["state"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0].long_name
          this.QuotData.destination["country"] =  this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
          this.QuotData.destination["coordinates"] = this.destination.details.location
          this.QuotData.AutocompleteDestination = true;
        }


      }
    },
    mounted() {
      console.log(this.$el + ' is mounted')

      this.GetData();

      connection = this.$hub.Connection;

      console.log(connection)


      connection.on("OrderReturnToSales", this.GetSignalData)
    },
    destroyed() {
      console.log(this.$el + ' is destroyed')
      console.log(connection)
      connection.off('OrderReturnToSales', this.GetSignalData)
    }

  }
</script>

<style scoped lang="scss">

.fixedOption{
  position: fixed;
  z-index: 2;
  bottom: 1em;
  right: 2em;
}

.border-rounded {
  border-radius: 10px;
}

.fixed-col-size{
  min-width: 250px;
}

.searchButton{
  z-index: 2;
}

.position-relative {
  position: relative;
}

.notification{
  cursor: pointer;
}

>>> .container {
  min-width: 100%;
}

</style>

`
