import { render, staticRenderFns } from "./FileDrag.vue?vue&type=template&id=d9f8d984&scoped=true"
import script from "./FileDrag.vue?vue&type=script&lang=js"
export * from "./FileDrag.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d9f8d984",
  null
  
)

export default component.exports