<template>
  <div>
    <v-row>
      <v-col md="12">
        <v-card flat>
          <v-card-text>
            <br>
            <div v-for="(load,index) in loadingData" :key="index">
              <v-row>
                <v-col md="1" class="pa-0 ml-2">
                  <label class="text-caption">Qty</label>
                  <v-text-field type="number" outlined dense v-model="load.quantity"
                                :rules="[v => !!v || ' Requerido']"
                                :name="`loadingData[${index}][quantity]`" @change="sendData(index)"></v-text-field>
                </v-col>

                <v-col md="1" class="pa-0 ml-2">
                  <label class="text-caption">Largo</label>
                  <v-text-field v-model="load.large" step="0.01" :rules="[v => !!v || ' Requerido']"
                                :name="`loadingData[${index}][large]`" type="Number" outlined
                                dense @change="sendData(index)"></v-text-field>
                </v-col>
                <v-col md="1" class="pa-0 ml-2">
                  <label class="text-caption">Ancho</label>
                  <v-text-field v-model="load.width" step="0.01" :rules="[v => !!v || ' Requerido']"
                                @change="sendData(index)" :name="`loadingData[${index}][width]`" outlined dense
                                type="Number"></v-text-field>
                </v-col>
                <v-col md="1" class="pa-0 ml-2">
                  <label class="text-caption">Alto</label>
                  <v-text-field v-model="load.height" @change="sendData(index)" step="0.01"
                                :rules="[v => !!v || ' Requerido']" :name="`loadingData[${index}][height]`"
                                type="Number" outlined
                                dense class="pa-0"></v-text-field>
                </v-col>

                <v-col md="2" class="pa-0 ml-2">
                  <label class="text-caption">Unidad de medida</label>
                  <v-select :items="UnitsOfLength"
                            @change="sendData(index)"
                            :rules="[v => !!v || ' Requerido']"
                            v-model="load.unitMeasure" :name="`loadingData[${index}][UnitMeasure]`" item-text="name"
                            item-value="name"
                            outlined dense
                  >
                  </v-select>

                </v-col>

                <v-col md="2" class="pa-0 ml-2">
                  <label class="text-caption">Tipo de embalaje</label>
                  <v-select :items="LoadingType" @change="sendData(index)" :rules="[v => !!v || ' Requerido']"
                            v-model="Unit" item-value="id" item-text="description"
                            outlined dense></v-select>
                </v-col>

                <v-col md="2">
                  <v-radio-group
                      v-model="load.stackable"
                      column
                      @change="sendData(index)"
                      :name="`loadingData[${index}][stackable]`"
                      color="primary"
                  >
                    <v-radio
                        active-class="font-weight-black"
                        label="No estibable"
                        :value="false"
                    ></v-radio>
                    <v-radio
                        active-class="font-weight-black"
                        label="Estibable"
                        :value="true"
                    ></v-radio>
                  </v-radio-group>
                </v-col>


              </v-row>

              <v-row>


                <v-col md="2" class="pa-0 ml-2">

                  <label class="text-caption">Peso</label>
                  <v-text-field step="0.01" :rules="[v => !!v || ' Requerido']" type="Number" v-model="load.weight"
                                :name="`loadingData[${index}][weight]`" @change="sendData(index)" outlined dense
                  ></v-text-field>

                </v-col>

                <v-col md="2" class="pa-0 ml-2">
                  <label class="text-caption">Unidad de peso</label>
                  <v-select :rules="[v => !!v || ' Requerido']" :items="Units" v-model="load.weightUnitMeasure" item-value="name"
                            item-text="name" outlined dense>
                  </v-select>
                </v-col>

                <v-col md="2">
                  <v-radio-group
                      v-model="load.totalWeight"
                      column
                      @change="sendData(index)"
                  >
                    <v-radio
                        label="Peso total"
                        :value="true"
                    ></v-radio>
                    <v-radio
                        label="Peso por pallet"
                        :value="false"
                    ></v-radio>
                  </v-radio-group>
                </v-col>


                <v-col md="2" class="mt-0">

                  <v-checkbox color="primary" v-model="load.dangerous" :name="`loadingData[${index}][dangerous]`"
                              @change="sendData(index)" label="Peligroso"></v-checkbox>
                </v-col>



                <v-col md="1">

                  <v-btn class="mt-3" icon color="pink" @click="deleteLoad(index)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <div v-if="load.dangerous == true">


                <v-divider></v-divider>

                <v-row>
                  <v-col md="4">
                    <v-col md="12">
                      <v-file-input class=""
                                    v-model="load.IMO"
                                    prepend-icon=""
                                    prepend-inner-icon="mdi-paperclip"
                                    label="Clasificacion IMO"
                                    @change="sendData(index)"
                                    outlined
                                    :rules="editMode ? []:[v => !!v || ' Requerido']"
                                    dense></v-file-input>
                    </v-col>
                  </v-col>

                  <v-col md="4">
                    <v-col md="12">
                      <v-file-input class=""
                                    v-model="load.UN"
                                    prepend-icon=""
                                    prepend-inner-icon="mdi-paperclip"
                                    label="Numero UN"
                                    @change="sendData(index)"
                                    outlined
                                    :rules="editMode ? []:[v => !!v || ' Requerido']"
                                    dense></v-file-input>
                    </v-col>
                  </v-col>

                </v-row>

                <v-divider></v-divider>

              </div>
            </div>
            <v-btn text color="primary" @click="add()">+ Añadir carga</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "load_detail_aereo",
  computed: {},
  props: {
    control: {
      default: 11,
      type: Number
    },
    loadDetail: Object,
    loadData: Array,
    editMode: {
      type: Boolean,
      default: false,
    },

  },
  watch: {
    control: function () {
      this.ValidateContainer();
      this.getContainers();
    }
  },
  data() {
    return {
      Legal: true,
      Unit: 2,
      LoadingType: [],
      UnitOfLength: 2,
      currentUnit: {
        id: 2,
        name: 'Kg',
        value: 1
      },
      currentUnitOfLength: {
        id: 2,
        name: 'Mts',
        value: 1
      },
      container: null,
      Pallets: null,
      bulk: null,
      Units: [{
        id: 1,
        name: 'Gr',
        value: 1000
      },
        {
          id: 2,
          name: 'Kg',
          value: 1
        },
        {
          id: 3,
          name: 'Ton',
          value: 0.001
        },
        {
          id: 4,
          name: 'Oz',
          value: 0.028
        },
        {
          id: 5,
          name: 'Lb',
          value: 0.453
        }
      ],
      UnitsOfLength: [{
        id: 1,
        name: 'Cm',
        value: 100
      },
        {
          id: 2,
          name: 'Mts',
          value: 1
        },
        {
          id: 3,
          name: 'Pulgada',
          value: 0.0254
        },
        {
          id: 4,
          name: 'Pies',
          value: 0.3048
        },
      ],
      messageWeigth: '',
      user: {
        lastname: ""
      },
      Containers: [],
      containers: "",
      orders: {
        companyId: null,
        commodity: null,
        budget: null,
        status: null,
        urgent: null,
        comments: null,
        frequence: null,
        orderTypeId: null,
        date: null,
        insideBillOfLanding: null,
        dueDate: null,
        originCountry: null,
        originCity: null,
        destinationCountry: null,
        destinationCity: null,
        OrderUsers: [{
          UserId: null,
        }],
        orderCargo: [{
          quantity: 0,
          large: 0,
          width: 0,
          heigth: 0,
          weigth: '21630.00000000',
          loadingTypeId: 2,
          stackable: true,
          tiltable: null,
          packaging: null,
          dangerous: null,
          containerId: null,
          temperature: null,
          dangerSubdivisionId: null,
          color: null,
        }

        ],

      },
      loadingTypeId: 2,
      loadingData: [{
        stackable: true,
        tiltable: false,
        totalWeight: true,
        containerId: null,
        loadingTypeId: 2,
        type: "Pallets",
        quantity: 1,
        weight: 0,
        documents: [
          {
            documentTypeId: 16,
            file: null,
            fileExtension: 'PDF',
            fileName: 'IMO'
          },

          {
            documentTypeId: 16,
            file: null,
            fileExtension: 'PDF',
            fileName: 'UN'
          },

        ],
        dangerous: false,
        large: 0,
        width: 0,
        legal: true,
        height: 0,
        unitMeasure: 'Cm',
        weightUnitMeasure: "Kg",
        Unit: "Kg"
      }],
      Element: [{
        qtyp: 0,
        TipoPal: 0,
        Medidas: 0,
        Gross: 0,
        qty: 0,
        peligroso: null,
        estibable: null,
        line_total: 0,
        id: 1,
      }],
    }

  },
  methods: {
    resetCargo() {
      this.loadingData = [];
      this.add();
    },
    add() {
      this.loadingData.push({
        stackable: true,
        tiltable: false,
        totalWeight: true,
        containerId: null,
        documents: [
          {
            documentTypeId: 16,
            file: null,
            fileExtension: 'PDF',
            fileName: 'IMO'
          },

          {
            documentTypeId: 16,
            file: null,
            fileExtension: 'PDF',
            fileName: 'UN'
          },

        ],
        loadingTypeId: 2,
        type: "Pallets",
        quantity: 1,
        dangerous: false,
        unitMeasure: 'Cm',
        weightUnitMeasure: "Kg",
        weight: 0,
        legal: true,
        large: 0,
        width: 0,
        height: 0,
        Unit: 2
      })
      this.$nextTick(() => {
        this.$emit('loading', this.loadingData)
      })
    },
    deleteLoad(index) {
      this.loadingData.splice(index, 1)
    },

    UploadFile: function (file) {

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },

    ConvertFile: function (file, index, docindex) {
      console.log(file.name);


      this.UploadFile(file).then(response => {


        console.log(response);
        var data = response.split(',')[1];
        console.log(data)
        this.loadingData[index].documents[docindex].file = data;
        console.log(this.loadingData[index].documents)
      })
          .catch(error => {
            console.log(error)
          })


    },

    ConvertToKilograms: function (equivalence, value) {
      return value / equivalence;
    },
    ConvertToMeters: function (equivalence, value) {
      return value / equivalence;
    },
    /*CalculateWeigth: function (index) {
      const selectedUnit = this.Units.find(x => x.name == this.loadingData[index].Unit);
      let value = this.loadingData[index].weight;
      this.loadingData[index].WeightUnitMeasure = selectedUnit.name;
      if (this.currentUnit.name == 'Kg') {
        value = value * selectedUnit.value
      } else {
        let standardWeight = this.ConvertToKilograms(this.currentUnit.value, value)
        value = standardWeight * selectedUnit.value;
      }
      this.currentUnit = selectedUnit
      this.loadingData[index].weight = value
    },
    CalculateUnitOfLength: function (index) {
      const selectedUnitOfLength = this.UnitsOfLength.find(x => x.id == this.UnitOfLength);
      let large = this.loadingData[index].large;
      let width = this.loadingData[index].width;
      let height = this.loadingData[index].height;

      if (this.currentUnitOfLength.name == 'Mts') {
        large = large * selectedUnitOfLength.value
        width = width * selectedUnitOfLength.value
        height = height * selectedUnitOfLength.value
        console.log(large);

      } else {
        let standardLarge = this.ConvertToMeters(this.currentUnitOfLength.value, large)
        large = standardLarge * selectedUnitOfLength.value;
        console.log(large);

        let standardWidth = this.ConvertToMeters(this.currentUnitOfLength.value, width)
        large = standardWidth * selectedUnitOfLength.value;

        let standardheight = this.ConvertToMeters(this.currentUnitOfLength.value, height)
        large = standardheight * selectedUnitOfLength.value;
      }
      this.currentUnitOfLength = selectedUnitOfLength
      this.loadingData[index].large = large
      this.loadingData[index].width = width
      this.loadingData[index].height = height
    },*/
    validateWeigth: function (index) {

      if (this.loadingData[index].weight > 21630.00000000) {
        this.messageWeigth = "Aplican Cargos"
      } else {
        this.messageWeigth = ''
      }

      this.$emit('loading', this.loadingData);

    },

    GetLoadingTypes: function () {
      this.$store.dispatch('working', true);
      this.$http.get(`LoadingTypes`).then(response => {
        this.LoadingType = response.data.data
        console.log(response);
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.$store.dispatch('working', false)
      })
    },

    ValidateContainer: function () {
      if (this.control == 9) {
        this.Pallets = false;
        this.bulk = false;
      }

      if (this.control == 11) {
        this.Pallets = true;
        this.bulk = true;
      }

      if (this.control == 12) {
        this.Pallets = false;
        this.bulk = false;
      }

      if (this.control == 13) {
        this.Pallets = false;
        this.bulk = false;
      }

    },
    sendData: function (id) {
      if (!this.loadingData[id].modified) this.loadingData[id].modified = true;

      if (!this.loadingData[id].documents && this.loadingData[id].dangerous) {
        this.loadingData[id].documents = [
          {
            documentTypeId: 16,
            file: null,
            fileExtension: 'PDF',
            fileName: 'IMO'
          },

          {
            documentTypeId: 16,
            file: null,
            fileExtension: 'PDF',
            fileName: 'UN'
          },
        ]
      }

      if (this.loadingData[id].IMO) {
        this.UploadFile(this.loadingData[id].IMO).then(response => {
          let data = response.split(',')[1];
          this.loadingData[id].documents[0].file = data;
        }).catch(error => {
          console.log(error)
        })
      }

      if (this.loadingData[id].UN) {
        this.UploadFile(this.loadingData[id].UN).then(response => {
          let data = response.split(',')[1];
          this.loadingData[id].documents[1].file = data;
        }).catch(error => {
          console.log(error)
        })
      }

      /*var quantity = 0;
      quantity = parseInt(this.loadingData[id].quantity);
      this.loadingData[id].quantity = quantity;

      var large = 0;
      large = parseInt(this.loadingData[id].large);
      this.loadingData[id].large = large;

      var width = 0;
      width = parseInt(this.loadingData[id].width);
      this.loadingData[id].width = width;

      var height = 0;
      height = parseInt(this.loadingData[id].height);
      this.loadingData[id].height = height;

      var weight = 0;
      weight = parseInt(this.loadingData[id].weight);
      this.loadingData[id].weight = weight;
*/
      this.$emit('loading', this.loadingData)
    },
    getContainers: function () {
      this.$http.get('Containers')
          .then(response => {
            console.log(response.data.data);
            this.Containers = response.data.data;
            this.container = response.data.data[0].code;

          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.loading = false)
    },
    setLoadDetail() {
      if (this.loadData.length > 0) {
        this.loadingData = [];
        this.loadingData = this.loadData
      }
      this.$nextTick(() => {
        this.$emit('loading', this.loadingData)
      })
    }
  },
  mounted() {
    this.getContainers();
    this.$nextTick(() => {
      this.setLoadDetail();
      this.GetLoadingTypes();
    })
  }
}
</script>

<style scoped>

</style>
