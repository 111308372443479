<template>
  <!-- TODO
 si el back jotea con el campo de medidas borrarlo antes de mandar los datos,
 pasar todo a numeros,
 buscar que hacer con lo de peligroso y esos campos
 -->
  <div>
    <v-expansion-panels >
      <v-expansion-panel>
        <v-expansion-panel-header
          expand-icon=""
          class="headline primary font-weight-regular white--text"
          @click="getContainers"
        >
          <v-row>
            <v-col md="6" class="pa-0">
              <v-icon color="white" size="30" left>las la-th-list</v-icon>
              Detalles de Envio
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container>
            <!--                        <load_detail></load_detail>-->

            <v-tabs
              centered
              icons-and-text
              v-model="tab"
              v-if="
                localValue.transportationId === 1 ||
                  localValue.transportationId === 2 ||
                  localValue.transportationId === 3
              "
            >
              <v-tab class="ml-3" :disabled="tabContainer">
                <span> <v-icon>las la-pallet</v-icon> Containers </span>
              </v-tab>
              <v-tab :disabled="tabBoxes">
                <span> <v-icon>las la-boxes</v-icon> Boxes/Pallets </span>
              </v-tab>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <br />
                    <div
                      v-for="(cargoI, index) in localValue.cargo"
                      :key="index"
                    >
                      <v-row>
                        <v-col md="1" sm="4" xs="12" class="ml-2">
                          <label class="text-caption">Qty</label>
                          <v-text-field
                            type="number"
                            outlined
                            dense
                            v-model="cargoI.quantity"
                          ></v-text-field>
                        </v-col>
                        <v-col md="2" sm="5" xs="12" class="pl-0">
                          <label class="text-caption">Tipo de Contenedor</label>
                          <v-autocomplete
                            dense
                            outlined
                            :items="itemsContainers"
                            item-text="description"
                            item-value="id"
                            v-model="cargoI.containerId"
                          >
                          </v-autocomplete>
                        </v-col>

                        <v-col md="2" class=" pl-0">
                          <label class="text-caption">Peso</label>
                          <v-text-field
                            type="Number"
                            step="0.01"
                            outlined
                            dense
                            :disabled="cargoI.legal"
                            v-model="cargoI.weight"
                          >
                          </v-text-field>
                        </v-col>

                        <v-col md="2" class=" pl-0 ml-0">
                          <label class="text-caption">Unidad de Peso</label>
                          <v-select
                            outlined
                            dense
                            :items="Units"
                            v-model="cargoI.unit"
                            item-text="name"
                            item-value="name"
                          ></v-select>
                        </v-col>
                        <v-col md="1 mt-2">
                          <v-checkbox
                            color="primary"
                            label="Legal"
                            v-model="cargoI.legal"
                          ></v-checkbox>
                        </v-col>
                        <v-col md="1" class="ml-3 mt-2">
                          <v-checkbox
                            color="primary"
                            label="Peligroso"
                            v-model="cargoI.dangerous"
                          ></v-checkbox>
                        </v-col>
<!--                        <v-col md="1" class="ml-8 mt-2">
                          <v-checkbox
                            color="primary"
                            label="Medidas"
                            v-model="cargoI.medidas"
                          ></v-checkbox>
                        </v-col>-->

                        <v-col md="1" class="ml-5 mt-2">
                          <v-btn
                            class="mt-3"
                            icon
                            color="pink"
                            @click="removeLoad(index)"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row v-if="(localValue.transportationId === 3 && localValue.transportationCategoryId === 2) || (localValue.transportationId === 3 && localValue.transportationCategoryId === 3)">
                      <v-col cols="2">
                        <v-checkbox color="primary" label="Full" class="mt-8" v-model="localValue.landFull" ></v-checkbox>

                      </v-col>
                      <v-col  class="mt-2">
                            <v-radio-group
                              column
                              color="primary"
                              v-model="cargoI.tiltable"
                            >
                              <v-radio
                                active-class="font-weight-black"
                                label="No estibable"
                                :value="false"
                              ></v-radio>
                              <v-radio
                                active-class="font-weight-black"
                                label="Estibable"
                                :value="true"
                              ></v-radio>
                            </v-radio-group>
                          </v-col>
                      </v-row>
                      <div>
                        <v-divider></v-divider>

                        <v-row v-if="cargoI.dangerous">
                          <v-col md="4">
                            <v-col md="12">
                              <v-file-input
                                class=""
                                prepend-icon=""
                                prepend-inner-icon="mdi-paperclip"
                                label="Clasificacion IMO"
                                @change="formatDoc(index)"
                                outlined
                                dense
                                v-model="cargoI.IMO"
                              ></v-file-input>
                            </v-col>
                          </v-col>

                          <v-col md="4">
                            <v-col md="12">
                              <v-file-input
                                class=""
                                prepend-icon=""
                                prepend-inner-icon="mdi-paperclip"
                                label="Numero UN"
                                outlined
                                dense
                                @change="formatDoc(index)"
                                v-model="cargoI.UN"
                              ></v-file-input>
                            </v-col>
                          </v-col>
                        </v-row>
                      </div>
                      <div>
<!--                        <v-divider class="mb-3" v-if="isMedidas"></v-divider>
                        <v-row v-if="cargoI.medidas">
                          <v-col md="1" class="pa-0 pt-3 ml-3">
                            <label>Largo</label>
                            <v-text-field
                              outlined
                              v-model="cargoI.large"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col md="1" class="pa-0 pt-3 ml-1">
                            <label>Ancho</label>
                            <v-text-field
                              outlined
                              dense
                              type="Number"
                              v-model="cargoI.width"
                            ></v-text-field>
                          </v-col>
                          <v-col md="1" class="pa-0 pt-3 ml-1">
                            <label>Alto</label>
                            <v-text-field
                              type="Number"
                              v-model="cargoI.height"
                              outlined
                              dense
                              class="pa-0"
                            ></v-text-field>
                          </v-col>
                          <v-col md="2" class="ml-1 pa-0 pt-3">
                            <label>Unidad de Medida</label>
                            <v-select
                              :items="UnitsOfLength"
                              item-value="name"
                              item-text="name"
                              outlined
                              v-model="cargoI.unitMeasure"
                              dense
                            ></v-select>
                          </v-col>
                        </v-row>-->
                      </div>
                    </div>

                    <v-btn text color="primary" @click="addLoad"
                      >+ Añadir carga</v-btn
                    >
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <br />
                    <div>
                      <v-form
                        v-for="(cargoI, index) in localValue.cargo"
                        :key="index"
                      >
                        <v-row>
                          <v-col md="1" class="pa-0 pt-3">
                            <label>Qty</label>
                            <v-text-field
                              type="number"
                              outlined
                              dense
                              v-model="cargoI.quantity"
                            ></v-text-field>
                          </v-col>

                          <v-col md="1" class="pa-0 pt-3 ml-3">
                            <label>Largo</label>
                            <v-text-field
                              outlined
                              dense
                              v-model="cargoI.large"
                            ></v-text-field>
                          </v-col>
                          <v-col md="1" class="pa-0 pt-3 ml-1">
                            <label>Ancho</label>
                            <v-text-field
                              outlined
                              dense
                              type="Number"
                              v-model="cargoI.width"
                            ></v-text-field>
                          </v-col>
                          <v-col md="1" class="pa-0 pt-3 ml-1">
                            <label>Alto</label>
                            <v-text-field
                              type="Number"
                              outlined
                              dense
                              class="pa-0"
                              v-model="cargoI.height"
                            ></v-text-field>
                          </v-col>

                          <v-col md="2" class="ml-1 pa-0 pt-3">
                            <label>Unidad de Medida</label>
                            <v-select
                              outlined
                              dense
                              :items="UnitsOfLength"
                              item-text="name"
                              item-value="name"
                              v-model="cargoI.unitMeasure"
                            ></v-select>
                          </v-col>
                          <v-col md="2" class="ml-1 pa-0 pt-3" v-if="localValue.transportationId === 2">
                            <label>Tipo de embalaje</label>
                            <v-select
                              outlined
                              dense
                              :items="LoadingType"
                              item-text="description"
                              item-value="description"
                              v-model="cargoI.cargoType"
                              
                            ></v-select>
                          </v-col>

                          <v-col md="2" class="mt-2">
                            <v-checkbox
                              color="primary"
                              label="Peligroso"
                              v-model="cargoI.dangerous"
                            ></v-checkbox>
                          </v-col>

                          <v-col md="1" class="mt-2">
                            <v-radio-group
                              column
                              color="primary"
                              v-model="cargoI.tiltable"
                            >
                              <v-radio
                                active-class="font-weight-black"
                                label="No estibable"
                                :value="false"
                              ></v-radio>
                              <v-radio
                                active-class="font-weight-black"
                                label="Estibable"
                                :value="true"
                              ></v-radio>
                            </v-radio-group>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col md="2" class="pa-0 pt-3">
                            <label>Peso</label>
                            <v-text-field
                              type="Number"
                              step="0.01"
                              outlined
                              dense
                              v-model="cargoI.weight"
                            ></v-text-field>
                          </v-col>

                          <v-col md="2" class="pa-0 pt-3 ml-3">
                            <label>Unidad de peso</label>
                            <v-select
                              :items="Units"
                              item-value="name"
                              item-text="name"
                              outlined
                              dense
                              v-model="cargoI.unit"
                            ></v-select>
                          </v-col>

                          <v-col md="3">
                            <v-radio-group column v-model="cargoI.totalWeight">
                              <v-radio
                                label="Peso total"
                                :value="true"
                              ></v-radio>
                              <v-radio
                                label="Peso por pallet"
                                :value="false"
                              ></v-radio>
                            </v-radio-group>
                          </v-col>

                          <v-col md="1">
                            <v-btn
                              class="mt-6"
                              icon
                              color="pink"
                              @click="removeLoad(index)"
                            >
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row v-if="cargoI.dangerous" class="mt-2">
                          <v-col md="4">
                            <v-col md="12">
                              <v-file-input
                                class=""
                                prepend-icon=""
                                prepend-inner-icon="mdi-paperclip"
                                label="Clasificacion IMO"
                                @change="formatDoc(index)"
                                outlined
                                dense
                                v-model="cargoI.IMO"
                              ></v-file-input>
                            </v-col>
                          </v-col>

                          <v-col md="4">
                            <v-col md="12">
                              <v-file-input
                                class=""
                                prepend-icon=""
                                prepend-inner-icon="mdi-paperclip"
                                label="Numero UN"
                                outlined
                                dense
                                @change="formatDoc(index)"
                                v-model="cargoI.UN"
                              ></v-file-input>
                            </v-col>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                      </v-form>
                    </div>
                    <v-btn text color="primary" @click="addLoad"
                      >+ Añadir carga</v-btn
                    >
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import test from "./mixins/dataBinding";

export default {
  data() {
    return {
      IMO: null,
    UN: null,
      itemsContainers: [],
      LoadingType: [],
      tabContainer: false,
      tabBoxes: false,
      tabBulk: false,
      tab: "",
      UnitsOfLength: [
        {
          id: 1,
          name: "Cm",
          value: 100,
        },
        {
          id: 2,
          name: "Mts",
          value: 1,
        },
        {
          id: 3,
          name: "Pulgada",
          value: 0.0254,
        },
        {
          id: 4,
          name: "Pies",
          value: 0.3048,
        },
      ],
      Units: [
        {
          id: 1,
          name: "Gr",
          value: 1000,
        },
        {
          id: 2,
          name: "Kg",
          value: 1,
        },
        {
          id: 3,
          name: "Ton",
          value: 0.001,
        },
        {
          id: 4,
          name: "Oz",
          value: 0.028,
        },
        {
          id: 5,
          name: "Lb",
          value: 0.453,
        },
      ],
    };
  },
  mixins: [test],
  watch: {
    "localValue.transportationCategoryId"() {
      if (this.localValue.transportationCategoryId === 9) {
        this.tab = 1;
        this.tabContainer = true;
        this.tabBoxes = false;
        this.tabBulk = true;
      }
      if (
        this.localValue.transportationCategoryId === 11 ||
        this.localValue.transportationCategoryId === 12 ||
        this.localValue.transportationCategoryId === 13
      ) {
        this.tab = 0;
        this.tabContainer = false;
        this.tabBoxes = true;
        this.tabBulk = true;
      }
      if (this.localValue.transportationCategoryId === 1) {
        this.tab = 1;
        this.tabContainer = true;
        this.tabBoxes = false;
        this.tabBulk = true;
      }
      if (this.localValue.transportationCategoryId === 2 || this.localValue.transportationCategoryId === 3) {
        this.tab = 0;
        this.tabContainer = false;
        this.tabBoxes = true;
        this.tabBulk = true;
      }
    },
    "localValue.transportationId"() {
      if (this.localValue.transportationId === 2 || this.localValue.transportationId === 3) {
        this.tab = 1;
        this.tabContainer = true;
        this.tabBoxes = false;
        this.tabBulk = true;
      }
      if (this.localValue.transportationId === 1) {
        this.tab = 0;
        this.tabContainer = false;
      }
    },
    tab() {
      this.localValue.cargo = [];
      this.addLoad();
    },
  },
  mounted() {
    this.GetLoadingTypes();
  },
  methods: {
    formatDoc(index) {
      if (this.localValue.cargo[index].IMO) {
       this.UploadFile(this.localValue.cargo[index].IMO).then(response => {
         let data = response.split(',')[1];
         this.localValue.cargo[index].documents[0].file = data;
       }).catch(error => {
          console.log(error)
        })
      }
      if (this.localValue.cargo[index].UN) {
       this.UploadFile(this.localValue.cargo[index].UN).then(response => {
         let data = response.split(',')[1];
         this.localValue.cargo[index].documents[1].file = data;
       }).catch(error => {
          console.log(error)
        })
      }
    },
    UploadFile: function (file) {

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    getContainers() {
      this.$http("Containers").then((response) => {
        this.itemsContainers = response.data.data;
        console.log(this.localValue)
        if(this.localValue.transportationId === 3){
          this.itemsContainers = this.itemsContainers.filter(element => element.transportationId === 3)
          console.log(this.itemsContainers)
        }
        if(this.localValue.transportationId === 1){
          this.itemsContainers = this.itemsContainers.filter(element => element.transportationId === 1)
          console.log(this.itemsContainers)
        }

      });
    },
    removeLoad(index) {
      this.localValue.cargo.splice(index, 1);
      console.log(index);
    },
    addLoad() {
      let newCargo = {
        stackable: false,
        tiltable: true,
        totalWeight: true,
        containerId: 1,
        cargoType: '',
        documents: [
          {
            documentTypeId: 16,
            file: null,
            fileExtension: 'PDF',
            fileName: 'IMO'
          },

          {
            documentTypeId: 16,
            file: null,
            fileExtension: 'PDF',
            fileName: 'UN'
          },

        ],
        quantity: 1,
        landFull: false,
        weight: 21000,
        dangerous: false,
        large: 0,
        width: 0,
        legal: true,
        height: 0,
        unitMeasure: "Mts",
        unit: "Kg",
        weightUnitMeasure: "Kg",
        modified: true,
        loadingTypeId: null,
        medidas: false,
      };
      if (this.tab === 0) {
        newCargo.cargoType = "Container";
        newCargo.loadingTypeId = null;
      }
      if (this.tab === 1) {
        newCargo.cargoType = "Pallets";
        newCargo.containerId = null;
        newCargo.loadingTypeId = 2;
      }
      this.localValue.cargo.push(newCargo);
    },
    GetLoadingTypes: function() {
      this.$http.get(`LoadingTypes`).then((response) => {
        this.LoadingType = response.data.data;
        console.log(response);
      });
    },
  },
};
</script>
