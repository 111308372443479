<template>
  <v-container fluid>
    <v-row>
      <v-col cols="2">
        <h3 class="primary--text">S11527-EMFCLITA-MEX</h3>
      </v-col>
      <v-col cols="1" offset="1">
        <p class="caption text-center">
          Conexion Sensor <br />
          <v-icon color="black">mdi-broadcast</v-icon>
        </p>
      </v-col>
      <v-col cols="1">
        <p class="caption text-center">
          Conexion API <br />
          <v-icon color="green">mdi-api</v-icon>
        </p>
      </v-col>
      <v-col cols="1">
        <p class="caption text-center">
          Conexion satelital <br />
          <v-icon color="green">mdi-satellite-variant</v-icon>
        </p>
      </v-col>
      <v-col cols="1">
        <p class="caption text-center">
          Conexion GPS <br />
          <v-icon color="black">mdi-crosshairs-gps</v-icon>
        </p>
      </v-col>
      <v-col cols="2" offset="3">
        <p class="caption">Ultima vez actualizada: 08/02/2022 <br />10:20 AM</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="9">
        <v-row>
          <v-col>
            <v-card outline>
              <v-toolbar flat color="transparent" dense>
                <v-row>
                  <v-col>
                    <v-toolbar-title class="subtitle-2"
                    >Estado: En transito</v-toolbar-title>
                  </v-col>
                  <v-col>
                    <v-toolbar-title class="subtitle-2" style="text-align: right"
                    >LLegada: 14/02/2022</v-toolbar-title>
                  </v-col>

                </v-row>
              </v-toolbar>
              <v-divider></v-divider>
              <v-row>
                <v-col cols="12" style="padding-bottom: 0px">
                  <v-stepper alt-labels flat>
                    <v-stepper-header>
                      <v-stepper-step step="3" complete>
                        Inico
                      </v-stepper-step>

                      <v-divider></v-divider>

                      <v-stepper-step step="4" complete>
                        Pendiente
                      </v-stepper-step>

                      <v-divider></v-divider>

                      <v-stepper-step step="6" complete> Por salir </v-stepper-step>
                      <v-divider></v-divider>

                      <v-stepper-step step="6" complete> En transito </v-stepper-step>
                      <v-divider></v-divider>

                      <v-stepper-step step="6"> Arribado </v-stepper-step>
                      <v-divider></v-divider>

                      <v-stepper-step step="6"> Entregado </v-stepper-step>
                      <v-divider></v-divider>

                      <v-stepper-step step="6"> Pago pendiente </v-stepper-step>
                      <v-divider></v-divider>

                      <v-stepper-step step="6">Finalizado </v-stepper-step>
                      <v-divider></v-divider>

                      <v-stepper-step step="6">Cancelado </v-stepper-step>
                    </v-stepper-header>
                  </v-stepper>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" style="padding-top: 0px; padding-bottom: 0px">
                  <Map
                      style="width: 100%; height: 450px"
                      class="border-rounded"
                      :path="path"
                      :clusters="clusters"
                  ></Map>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-toolbar flat color="transparent" dense>
                <v-toolbar-title class="subtitle-2">
                  Venta
                </v-toolbar-title>
              </v-toolbar>
              <v-divider></v-divider>
              <v-data-table
                  :headers="accountHeaders"
                  :items="accountItems"
              ></v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-toolbar flat color="transparent" dense>
                <v-toolbar-title class="subtitle-2">
                  Información del embarque
                </v-toolbar-title>
              </v-toolbar>
              <v-divider></v-divider>
              <v-card-text>
                <v-row>
                  <v-col cols="4">
                    <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-title>ID SENATOR</v-list-item-title>
                        <v-list-item-subtitle>019668</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-title>ID CLIENTE</v-list-item-title>
                        <v-list-item-subtitle>Referencia de cliente</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-title
                        >Servicio Principal</v-list-item-title
                        >
                        <v-list-item-subtitle>Maritimo</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-title
                        >Tipo de movimiento</v-list-item-title
                        >
                        <v-list-item-subtitle>Puerta a puerta</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-title>Tipo de embarque</v-list-item-title>
                        <v-list-item-subtitle>Importacion</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-title>Incoterm</v-list-item-title>
                        <v-list-item-subtitle>EXW</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="4">
                    <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-title>Vendor</v-list-item-title>
                        <v-list-item-subtitle>Timoteo Gonzalez</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-title
                        >Numero de vendedor</v-list-item-title
                        >
                        <v-list-item-subtitle>81 1611 4144</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-title>Operador</v-list-item-title>
                        <v-list-item-subtitle>David Colorado</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-title
                        >Numero de operador</v-list-item-title
                        >
                        <v-list-item-subtitle>81 1990 3550</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-title>Numero de booking</v-list-item-title>
                        <v-list-item-subtitle>NA</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-title>Numero de factura</v-list-item-title>
                        <v-list-item-subtitle>NA</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="4">
                    <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-title>Fecha de salida</v-list-item-title>
                        <v-list-item-subtitle>14/01/2022</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-title>Fecha de llegada</v-list-item-title>
                        <v-list-item-subtitle>14/02/2022</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-title>Fecha de cierre</v-list-item-title>
                        <v-list-item-subtitle>NA</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-title>Fecha de pago</v-list-item-title>
                        <v-list-item-subtitle>NA</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-title>HBL</v-list-item-title>
                        <v-list-item-subtitle>05/21/309249</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-toolbar flat color="transparent" dense>
                <v-toolbar-title class="subtitle-2">
                  Contenedores
                </v-toolbar-title>
              </v-toolbar>
              <v-divider></v-divider>
              <v-data-table
                  :headers="containerHeaders"
                  :items="containers"
              ></v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="3">
        <v-row>
          <v-col>
            <v-card height="37rem">
              <v-toolbar flat color="transparent" dense>
                <v-toolbar-title class="subtitle-2">
                  Actualizaciones/chat
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn icon tile>
                    <v-icon>mdi-arrow-expand-all</v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <v-divider></v-divider>
              <v-list-item three-line>
                <v-list-item-avatar>
                  <v-icon>mdi-clock-remove-outline</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    Embarque - <small>019668</small> -
                    <span class="text--disabled caption">Sirone - García</span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Retraso 2 dias | <small>06/02/2022</small>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <v-row>
                      <v-col>
                        <v-icon>mdi-ferry</v-icon>
                        <v-icon>mdi-truck</v-icon>
                      </v-col>
                      <v-col class="text-right"> $ 9,250.00 USD </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item three-line>
                <v-list-item-avatar>
                  <v-icon>mdi-file-document</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    Embarque - <small>019668</small> -
                    <span class="text--disabled caption">Sirone - García</span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Barco en transito | <snnall>14/01/2022</snnall>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <v-row>
                      <v-col>
                        <v-icon>mdi-ferry</v-icon>
                        <v-icon>mdi-truck</v-icon>
                      </v-col>
                      <v-col class="text-right"> $ 9,250.00 USD </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item three-line>
                <v-list-item-avatar>
                  <v-icon>mdi-ferry</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    Embarque - <small>019668</small> -
                    <span class="text--disabled caption">Sirone - García</span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Barco Zarpo | <small>14/01/2022</small>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <v-row>
                      <v-col>
                        <v-icon>mdi-ferry</v-icon>
                        <v-icon>mdi-truck</v-icon>
                      </v-col>
                      <v-col class="text-right"> $ 9,250.00 USD </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item three-line>
                <v-list-item-avatar>
                  <v-icon>mdi-file-document</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    Embarque - <small>019668</small> -
                    <span class="text--disabled caption">Sirone - García</span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Embarque creado <small> | 10/01/2022</small>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <v-row>
                      <v-col>
                        <v-icon>mdi-ferry</v-icon>
                        <v-icon>mdi-truck</v-icon>
                      </v-col>
                      <v-col class="text-right"> $ 9,250.00 USD </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-toolbar flat color="transparent" dense>
                <v-toolbar-title class="subtitle-2">
                  Mercancía
                </v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-divider></v-divider>
              <v-card-text>
                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Tipo de mercacía
                      </v-list-item-title>
                      <v-list-item-subtitle> Componentes de colde BIB M6.2 </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Bultos
                      </v-list-item-title>
                      <v-list-item-subtitle>8</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title> Humedad </v-list-item-title>
                      <v-list-item-subtitle> NA </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title> Temperatura </v-list-item-title>
                      <v-list-item-subtitle> NA </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-toolbar flat color="transparent" dense>
                <v-toolbar-title class="subtitle-2">
                  Documentos
                </v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-divider></v-divider>
              <v-card-text>
                <v-data-table :headers="documentHeaders" :items="documents">
                  <template v-slot:item.actions>
                    <v-icon small>mdi-file-document</v-icon>
                    <v-icon small>mdi-document-outline</v-icon>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Map from "@/components/Map/Map";
export default {
  name: "Embarque",
  components: {
    Map
  },
  data: () => ({
    id: null,
    documents: [
      {
        name: "Factura",
        user: "David",
        date: "20/01/2022",
      },
      {
        name: "Packing list",
        user: "David",
        date: "20/01/2022",
      },
      {
        name: "BL",
        user: "David",
        date: "20/01/2022",
      },
    ],
    documentHeaders: [
      {
        text: "Nombre",
        value: "name",
      },
      {
        text: "Usuario",
        value: "user",
      },
      {
        text: "Fecha",
        value: "date",
      },
      {
        text: "",
        value: "actions",
      },
    ],
    accountItems: [
      {
        description: "Flete importacion Maritima",
        subTotal: "9,250 USD",
        tax: "0 USD",
        total: "9,250 USD",
      },
      {
        description: "Flete Terrestre",
        subTotal: "1,200 USD",
        tax: "0 USD",
        total: "1,200 USD",
      },
    ],
    accountHeaders: [
      {
        text: "Description",
        value: "description",
      },
      {
        text: "Subtotal",
        value: "subTotal",
      },
      {
        text: "Impuestos",
        value: "tax",
      },
      {
        text: "Total",
        value: "total",
      },
    ],

    containerHeaders: [
      {
        text: "Numero",
        value: "numero",
      },
      {
        text: "Tipo de contenedor",
        value: "containerType",
      },
      {
        text: "Volumen",
        value: "volume",
      },
      {
        text: "Peso",
        value: "weight",
      },
    ],
    containers: [
      {
        numero: "MEDU5469082",
        containerType: "20DRY",
        volume: "NA",
        weight: "3,551 KGS",
      },
    ],

    clusters: [
      {
        path: {
          show: true,
          color: "#" + Math.floor(Math.random() * 16777215).toString(16),
          route: [
            { lat: 45.7724, lng: 9.32136 },
            { lat: 44.1067, lng: 9.82922 },
            { lat: 27.710240, lng: -72.552421 },
            { lat: 22.4879779, lng: -97.8844058, },
            { lat: 25.737523, lng: -100.364365 },
          ],
        },
        markers: [
          {
            type: "address",
            location: {
              lat: 45.7724,
              lng: 9.32136,
            },
          },
          {
            type: "port",
            location: {
              lat: 44.1067,
              lng: 9.82922,
            },
          },
          {
            type: "port",
            location: {
              lat: 27.710240,
              lng: -72.552421,
            },
          },
          {
            type: "barco",
            location: {
              lat: 25.737523,
              lng: -100.364365,
            },
          },
          {
            type: "port",
            location: {
              lat: 22.4879779,
              lng: -97.8844058,
            },
          },
          {
            type: "address",
            location: {
              lat: 25.737523,
              lng: -100.364365,
            },
          },
        ],
      },
    ],
  }),
  mounted() {
    console.log(this.id)
  },
  beforeMount() {
    this.id = this.$route.params.id;
    this.id = parseInt(this.id)
  },

}
</script>

<style scoped>

</style>
