<template>
  <v-row  no-gutters>
    <v-col md="8">
      <div class="sticky-container">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                dark
                small
                fixed
                @click="addElement"
                bottom
                right
                fab
                v-bind="attrs"
                v-on="on">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Agregar</span>
        </v-tooltip>
      </div>
      <v-dialog v-model="DeletePriceDialog" width="1000">
        <v-card>
          <v-card-title class="primary white--text">¿Desea eliminar este precio?</v-card-title>
          <v-card-text>
            <v-row>
              <v-col md="3" class="offset-4">
                <v-btn color="primary" @click="deletePrice">Si</v-btn>
              </v-col>

              <v-col md="3">
                <v-btn color="red" class="white--text" @click="DeletePriceDialog=false">No</v-btn>
              </v-col>

            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <div v-if="order.statusOrder === 'Vendida'">
        <v-tabs
            color="primary"
            background-color="primary"
            dark
        >
          <v-tab href="#compra">Compra</v-tab>
          <v-tab href="#venta">Venta</v-tab>
          <v-tab-item value="compra">
            <CompraBL :shipment="shipment" :es-vendedor="true" @updateCargo="mapPricesData"></CompraBL>
          </v-tab-item>
          <v-tab-item value="venta">
            <VentaBL :shipment="shipment" :es-vendedor="true" ></VentaBL>
          </v-tab-item>
        </v-tabs>
      </div>
      <div v-else>
        <v-card
            outlined
            class="elevation-0 w-100 rounded-sm"
            v-for="(El, index) in prices"
            :key="`PriceKey${El.orderId}${index}`"
        >
          <v-container

              class="rounded-sm"
          >
            <section

                v-if="showRatableWeigth"
            >
              <v-row
                  @click="openPrice(index)"
                  class="px-2 pricesHeader"
                  justify="center"
                  transition="fade-transition"
                  no-gutters
              >
                <v-col
                    cols="2"
                    class="text-left my-auto"
                >
                  <v-icon
                      v-show="El.transportationId == 1"
                  >mdi-ferry
                  </v-icon>
                  <v-icon
                      v-show="El.transportationId == 2"
                  >mdi-airplane
                  </v-icon>
                  <v-icon
                      v-show="El.transportationId == 3"
                  >mdi-truck
                  </v-icon>
                </v-col>
                <v-col
                    cols="7"
                    class="my-auto"
                >
                  <v-row
                      no-gutters
                  >
                    <v-col
                        cols="6"
                    >
                      <span class="text-caption">Vigencia: {{ !El.validity ? '-' : El.validity }}</span>
                    </v-col>
                    <v-col
                        cols="6"
                    > <span
                        class="text-caption">Dias: {{ `${!El.transportationTime ? '' : El.transportationTime}` }} </span>


                    </v-col>
                  </v-row>
                  <v-row
                      no-gutters
                  >
                    <v-col
                        cols="6"
                    >
                      <div v-if="El.supplierId > 0">
                        <span class="text-caption">Operado por {{ El.supplier.companyName }}</span>
                      </div>
                    </v-col>
                    <v-col
                        cols="6"
                    >
                      <div
                          v-if="El.pricesOrigins[0] || El.pricesDestination[1]"
                      >
                        <span class="text-caption">Ruta: </span>
                        <span class="text-caption">{{
                            El.pricesOrigins[0] ? (El.pricesOrigins[0].port ? El.pricesOrigins[0].port.name : El.pricesOrigins[0].city) : 'NA'
                          }}</span>
                        <span class="text-caption px-1">-</span>
                        <span class="text-caption">{{
                            El.pricesDestination[0] ? (El.pricesDestination[0].port ? El.pricesDestination[0].port.name : El.pricesDestination[0].city) : 'NA'
                          }}</span>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                    class="my-auto"
                >
                  <div
                      v-show="El.total > 0"
                      transition="fade-transition"
                  >
                <span
                    v-if="El.showTotalPrice"
                    class="text-h6 font-weight-bold"
                >${{ El.total }}</span>
                  </div>
                </v-col>
                <v-col
                    cols="2"
                    class="text-right my-auto"
                >
                  <div class="d-inline-flex">
                    <v-tooltip bottom v-if="prices.length>1">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            color="red"
                            @click="removePrice(El.id)"
                            v-bind="attrs"
                            v-on="on"
                        >mdi-delete
                        </v-icon>
                      </template>
                      <span>Eliminar</span>
                    </v-tooltip>


                    <div v-if="El.isUpdating">
                      <v-icon>
                        mdi-sync
                      </v-icon>
                      <span
                          class="text-caption"
                      >Guardando...</span>
                    </div>
                    <div v-if="El.id!=0">
                      <v-icon>
                        mdi-cloud-check-outline
                      </v-icon>
                    </div>
                    <div v-if="El.id==0">
                      <v-icon size="25z" color="red">
                        mdi-weather-cloudy-alert

                      </v-icon>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-expand-transition>
                <div
                    v-if="!El.dense"
                    transition="expand-y-transition"
                >
                  <v-divider class="py-1"></v-divider>
                  <v-row>
                    <div class="rounded-lg grey lighten-2 px-2 py-1 mx-auto my-6">
                      <v-row>
                        <v-col cols="5">
                          <v-autocomplete label="Agente en origen"
                                          single-line
                                          hide-details
                                          no-filter
                                          clearable
                                          rounded
                                          prepend-icon="mdi-handshake"
                                          v-model="El.freightForwarderId"
                                          :loading="loadingForwarders"
                                          @update:search-input="findForwarder($event)"
                                          :items="forwarders"
                                          item-value="id"
                                          item-text="companyName"
                                          class="mx-1 supplier-autocomplete pa-0">
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="7">
                          <v-form v-model="supplierForm" ref="myFormSupplier">
                            <v-autocomplete
                                v-model="El.supplier"
                                class="mx-1 supplier-autocomplete pa-0"
                                rounded
                                :items="El.supplierSearchResults"
                                :loading="El.searchingSupplier"
                                hide-details
                                label="Busca tu proveedor"
                                :rules="rules.required"
                                single-line
                                @input="(val) => setAndMapSupplier(val, index)"
                                :search-input.sync="searchSupplier"
                                item-text="companyName"
                                :menu-props="{
                      closeOnContentClick: true,
                      contentClass:'included'
                    }"
                                return-object
                            >
                              <template v-slot:prepend>
                                <v-icon>mdi-magnify</v-icon>
                              </template>
                              <template v-slot:append-outer>
                                <div class="d-flex">

                                  <v-btn icon class="pt-0" @click="showAddSupplier=true">
                                    <v-icon>mdi-plus</v-icon>
                                  </v-btn>

                                  <v-tooltip bottom
                                             v-if="validatePod == false || (validatePol == false && El.transportationId == 1 && El.transportationId == 2)">
                                    <template
                                        v-slot:activator="{ on: tooltip, attrs }"
                                        v-bind="attrs"
                                    >
                                      <v-btn

                                          icon
                                          small
                                          v-on="{...tooltip}"
                                          @click="alertPolAndPod()"
                                      >
                                        <v-icon color="red">mdi-alert</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Abrir</span>
                                  </v-tooltip>


                                  <v-menu
                                      v-model="El.optionsMenuOpen"
                                      offset-y
                                      content-class="included"
                                      :close-on-content-click="false"
                                  >
                                    <template v-slot:activator="{ on: menu, attrs }">
                                      <v-tooltip top>
                                        <template v-slot:activator="{ on: tooltip }">
                                          <v-btn
                                              icon
                                              small
                                              @click="validateFields"
                                              class="mx-1"
                                              v-bind="attrs"
                                              v-on="{...menu, ...tooltip}"
                                          >
                                            <v-icon v-if="El.transportationTime==null || El.transportationTime<=0"
                                                    color="red">
                                              mdi-tune
                                            </v-icon>

                                            <v-icon v-else>mdi-tune</v-icon>


                                          </v-btn>
                                        </template>
                                        Más opciones
                                      </v-tooltip>

                                    </template>
                                    <v-card min-width="400">
                                      <v-card-title class="text-center">Opciones adicionales</v-card-title>
                                      <v-card-text>
                                        <v-row no-gutters class="mb-5">
                                          <v-col cols="5">
                                            <span class="my-auto">Vigencia</span>
                                          </v-col>
                                          <v-col cols="7">
                                            <v-menu offset-y>
                                              <template v-slot:activator="{ on: menu, attrs }">
                                                <v-form v-model="vigency" ref="myFormVigency">
                                                  <v-text-field
                                                      v-model="El.validity"
                                                      label="Vigencia"
                                                      append-icon="mdi-calendar"
                                                      class="more-options-text"
                                                      dense
                                                      :rules="rules.required"
                                                      readonly
                                                      hide-details
                                                      v-bind="attrs"
                                                      v-on="menu"
                                                  ></v-text-field>
                                                </v-form>

                                              </template>
                                              <v-date-picker
                                                  v-model="El.validity"
                                                  class="included"
                                                  no-title
                                                  scrollable
                                                  :min="incrementDate(new Date(),1).toISOString().substr(0, 10)"
                                                  color="primary"
                                              >
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="menu = false"
                                                >
                                                  Cancel
                                                </v-btn>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="$refs.menu.save(date)"
                                                >
                                                  OK
                                                </v-btn>
                                              </v-date-picker>
                                            </v-menu>
                                          </v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                          <v-col cols="5">
                                            Tiempo de transito
                                          </v-col>

                                          <v-col cols="7">
                                            <v-form v-model="validTransportation" ref="myForm">
                                              <v-text-field
                                                  dense
                                                  type="number"
                                                  :rules="rules.required"
                                                  label="Número de Dias"
                                                  v-model.number="El.transportationTime"
                                                  class="more-options-text"
                                              >
                                              </v-text-field>
                                            </v-form>
                                          </v-col>


                                        </v-row>

                                      <div v-if="order.transportationCategoryId == 11">
                                        <v-row no-gutters>
                                              <v-col cols="5">
                                                Dias libres Destino
                                              </v-col>
                                              <v-col cols="7">
                                                <v-form v-model="validFreeDays" ref="myFormfreeDays">
                                                  <v-text-field
                                                      dense
                                                      type="number"
                                                      :rules="rules.required"
                                                      label="Número de días"
                                                      v-model.number="El.freeDaysDestination"
                                                      class="more-options-text"
                                                  >

                                                  </v-text-field>
                                                </v-form>
                                              </v-col>
                                            </v-row>
                                         </div>
                                      </v-card-text>
                                      <v-card-actions>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="El.optionsMenuOpen = false"
                                        >
                                          OK
                                        </v-btn>
                                      </v-card-actions>
                                    </v-card>
                                  </v-menu>
                                  <v-tooltip bottom v-if="El.id > 0">
                                    <template v-slot:activator="{ on: tooltip }">
                                      <v-btn
                                          icon
                                          small
                                          v-on="tooltip"
                                          class="mx-1"
                                          @click="duplicatePrice(El.id)"

                                      >
                                        <v-icon>mdi-clipboard-multiple-outline</v-icon>
                                      </v-btn>
                                    </template>
                                    Duplicar
                                  </v-tooltip>

                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on: tooltip }">
                                      <v-btn
                                          icon
                                          small
                                          v-on="tooltip"
                                          class="mx-1"
                                          @click="updatePriceButton(index)"

                                      >
                                        <v-icon>mdi-cloud-upload-outline</v-icon>
                                      </v-btn>
                                    </template>
                                    Guardar
                                  </v-tooltip>



                                </div>
                              </template>
                              <template v-slot:item="{item}">
                                <div class="d-inline-block included">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on: tooltip }">
                                      <v-icon v-on="tooltip">{{ getSupplierIcon(item.supplierTypeId) }}</v-icon>
                                    </template>
                                  </v-tooltip>
                                  <span class="ml-2">{{ item.companyName }}</span>
                                </div>
                              </template>
                            </v-autocomplete>

                          </v-form>
                        </v-col>
                      </v-row>
                    </div>
                  </v-row>
                  <v-row>
                    <v-expand-transition>
                      <div class="d-flex mx-auto justify-center" v-if="El.expansion" style="width: 100%">
                        <div v-if="order.transportationId==1 && order.deliveryTypeId == 1" class="row no-gutters position-relative text-center justify-center">
                          <div class="col-5 fixed-col-size">
                            <s-port-autocomplete
                                ref="autoOrigen"
                                label="Origen"
                                :search="El.originAddress"
                                :items="El.originItems"
                                :selections="El.originSelections"
                                :filterRoute="1"
                                @change="(selections) => originSelected(selections, index)"
                                @updateAddress="(text) => updateOriginAddress(text, index)"
                                @updateItems="(items) => updateOriginItems(items, index)"
                                placeholder="Ingrese un origen"
                                transportation="SEA"
                                :id="1"
                                dense
                            >
                            </s-port-autocomplete>
                          </div>
                          <div class="col-5 fixed-col-size">
                            <s-port-autocomplete
                                dense
                                ref="autoDestino"
                                label="Destino"
                                :filterRoute="1"
                                :search="El.destinationAddress"
                                :items="El.destinationItems"
                                :selections="El.destinationSelections"
                                placeholder="Ingrese un destino"
                                @change="(selections) => destinationSelected(selections, index)"
                                @updateAddress="(text) => updateDestinationAddress(text, index)"
                                @updateItems="(items) => updateDestinationItems(items, index)"
                                transportation="SEA"
                                :id="2"
                            >
                            </s-port-autocomplete>
                          </div>
                        </div>

                        <div v-if="order.transportationId==1 && order.deliveryTypeId == 2" class="row no-gutters position-relative text-center justify-center">
                          <div class="col-5 fixed-col-size">
                            <s-port-autocomplete
                                ref="autoOrigen"
                                label="Origen"
                                :search="El.originAddress"
                                :items="El.originItems"
                                :selections="El.originSelections"
                                :filterRoute="2"
                                @change="(selections) => originSelected(selections, index)"
                                @updateAddress="(text) => updateOriginAddress(text, index)"
                                @updateItems="(items) => updateOriginItems(items, index)"
                                placeholder="Ingrese un origen"
                                transportation="SEA"
                                :id="1"
                                dense
                            >
                            </s-port-autocomplete>
                          </div>
                          <div class="col-5 fixed-col-size">
                            <s-port-autocomplete
                                dense
                                ref="autoDestino"
                                label="Destino"
                                :filterRoute="2"
                                :search="El.destinationAddress"
                                :items="El.destinationItems"
                                :selections="El.destinationSelections"
                                placeholder="Ingrese un destino"
                                @change="(selections) => destinationSelected(selections, index)"
                                @updateAddress="(text) => updateDestinationAddress(text, index)"
                                @updateItems="(items) => updateDestinationItems(items, index)"
                                transportation="SEA"
                                :id="2"
                            >
                            </s-port-autocomplete>
                          </div>
                        </div>

                        <div v-if="order.transportationId==1 && order.deliveryTypeId == 3" class="row no-gutters position-relative text-center justify-center">
                          <div class="col-5 fixed-col-size">
                            <s-port-autocomplete
                                ref="autoOrigen"
                                label="Origen"
                                :search="El.originAddress"
                                :items="El.originItems"
                                :selections="El.originSelections"
                                :filterRoute="2"
                                @change="(selections) => originSelected(selections, index)"
                                @updateAddress="(text) => updateOriginAddress(text, index)"
                                @updateItems="(items) => updateOriginItems(items, index)"
                                placeholder="Ingrese un origen"
                                transportation="SEA"
                                :id="1"
                                dense
                            >
                            </s-port-autocomplete>
                          </div>
                          <div class="col-5 fixed-col-size">
                            <s-port-autocomplete
                                dense
                                ref="autoDestino"
                                label="Destino"
                                :filterRoute="1"
                                :search="El.destinationAddress"
                                :items="El.destinationItems"
                                :selections="El.destinationSelections"
                                placeholder="Ingrese un destino"
                                @change="(selections) => destinationSelected(selections, index)"
                                @updateAddress="(text) => updateDestinationAddress(text, index)"
                                @updateItems="(items) => updateDestinationItems(items, index)"
                                transportation="SEA"
                                :id="2"
                            >
                            </s-port-autocomplete>
                          </div>
                        </div>

                        <div v-if="order.transportationId==1 && order.deliveryTypeId == 4" class="row no-gutters position-relative text-center justify-center">
                          <div class="col-5 fixed-col-size">
                            <s-port-autocomplete
                                ref="autoOrigen"
                                label="Origen"
                                :search="El.originAddress"
                                :items="El.originItems"
                                :selections="El.originSelections"
                                :filterRoute="1"
                                @change="(selections) => originSelected(selections, index)"
                                @updateAddress="(text) => updateOriginAddress(text, index)"
                                @updateItems="(items) => updateOriginItems(items, index)"
                                placeholder="Ingrese un origen"
                                transportation="SEA"
                                :id="1"
                                dense
                            >
                            </s-port-autocomplete>
                          </div>
                          <div class="col-5 fixed-col-size">
                            <s-port-autocomplete
                                dense
                                ref="autoDestino"
                                label="Destino"
                                :filterRoute="2"
                                :search="El.destinationAddress"
                                :items="El.destinationItems"
                                :selections="El.destinationSelections"
                                placeholder="Ingrese un destino"
                                @change="(selections) => destinationSelected(selections, index)"
                                @updateAddress="(text) => updateDestinationAddress(text, index)"
                                @updateItems="(items) => updateDestinationItems(items, index)"
                                transportation="SEA"
                                :id="2"
                            >
                            </s-port-autocomplete>
                          </div>
                        </div>



                        <div v-if="order.transportationId==2 && order.deliveryTypeId == 1" class="row no-gutters position-relative text-center justify-center">
                          <div class="col-5 fixed-col-size">
                            <s-airort-autocomplete
                                dense
                                ref="autoOrigenAereo"
                                label="Origen"
                                :filterRoute="1"
                                :search="El.originAddress"
                                :items="El.originItems"
                                :selections="El.originSelections"
                                transportation="AIR"
                                placeholder="Ingrese un origen"
                                @change="(selections) => originSelectedAereo(selections, index)"
                                @updateAddress="(text) => updateOriginAddress(text, index)"
                                @updateItems="(items) => updateOriginItems(items, index)"
                                :id="1"
                            >
                            </s-airort-autocomplete>
                          </div>

                          <div class="col-5 fixed-col-size">
                            <s-airort-autocomplete
                                dense
                                ref="autoDestinoAereo"
                                label="Destino"
                                :filterRoute="1"
                                :search="El.destinationAddress"
                                :items="El.destinationItems"
                                :selections="El.destinationSelections"
                                transportation="AIR"
                                placeholder="Ingrese un destino o un puerto"
                                @change="(selections) => destinationSelectedAereo(selections, index)"
                                @updateAddress="(text) => updateDestinationAddress(text, index)"
                                @updateItems="(items) => updateDestinationItems(items, index)"
                                :id="2"
                            >
                            </s-airort-autocomplete>
                          </div>
                        </div>

                        <div v-if="order.transportationId==2 && order.deliveryTypeId == 2" class="row no-gutters position-relative text-center justify-center">
                          <div class="col-5 fixed-col-size">
                            <s-airort-autocomplete
                                dense
                                ref="autoOrigenAereo"
                                label="Origen"
                                :filterRoute="2"
                                :search="El.originAddress"
                                :items="El.originItems"
                                :selections="El.originSelections"
                                transportation="AIR"
                                placeholder="Ingrese un origen"
                                @change="(selections) => originSelectedAereo(selections, index)"
                                @updateAddress="(text) => updateOriginAddress(text, index)"
                                @updateItems="(items) => updateOriginItems(items, index)"
                                :id="1"
                            >
                            </s-airort-autocomplete>
                          </div>

                          <div class="col-5 fixed-col-size">
                            <s-airort-autocomplete
                                dense
                                ref="autoDestinoAereo"
                                label="Destino"
                                :filterRoute="2"
                                :search="El.destinationAddress"
                                :items="El.destinationItems"
                                :selections="El.destinationSelections"
                                transportation="AIR"
                                placeholder="Ingrese un destino o un puerto"
                                @change="(selections) => destinationSelectedAereo(selections, index)"
                                @updateAddress="(text) => updateDestinationAddress(text, index)"
                                @updateItems="(items) => updateDestinationItems(items, index)"
                                :id="2"
                            >
                            </s-airort-autocomplete>
                          </div>
                        </div>

                        <div v-if="order.transportationId==2 && order.deliveryTypeId == 3" class="row no-gutters position-relative text-center justify-center">
                          <div class="col-5 fixed-col-size">
                            <s-airort-autocomplete
                                dense
                                ref="autoOrigenAereo"
                                label="Origen"
                                :filterRoute="2"
                                :search="El.originAddress"
                                :items="El.originItems"
                                :selections="El.originSelections"
                                transportation="AIR"
                                placeholder="Ingrese un origen"
                                @change="(selections) => originSelectedAereo(selections, index)"
                                @updateAddress="(text) => updateOriginAddress(text, index)"
                                @updateItems="(items) => updateOriginItems(items, index)"
                                :id="1"
                            >
                            </s-airort-autocomplete>
                          </div>

                          <div class="col-5 fixed-col-size">
                            <s-airort-autocomplete
                                dense
                                ref="autoDestinoAereo"
                                label="Destino"
                                :filterRoute="1"
                                :search="El.destinationAddress"
                                :items="El.destinationItems"
                                :selections="El.destinationSelections"
                                transportation="AIR"
                                placeholder="Ingrese un destino o un puerto"
                                @change="(selections) => destinationSelectedAereo(selections, index)"
                                @updateAddress="(text) => updateDestinationAddress(text, index)"
                                @updateItems="(items) => updateDestinationItems(items, index)"
                                :id="2"
                            >
                            </s-airort-autocomplete>
                          </div>
                        </div>

                        <div v-if="order.transportationId==2 && order.deliveryTypeId == 4" class="row no-gutters position-relative text-center justify-center">
                          <div class="col-5 fixed-col-size">
                            <s-airort-autocomplete
                                dense
                                ref="autoOrigenAereo"
                                label="Origen"
                                :filterRoute="1"
                                :search="El.originAddress"
                                :items="El.originItems"
                                :selections="El.originSelections"
                                transportation="AIR"
                                placeholder="Ingrese un origen"
                                @change="(selections) => originSelectedAereo(selections, index)"
                                @updateAddress="(text) => updateOriginAddress(text, index)"
                                @updateItems="(items) => updateOriginItems(items, index)"
                                :id="1"
                            >
                            </s-airort-autocomplete>
                          </div>

                          <div class="col-5 fixed-col-size">
                            <s-airort-autocomplete
                                dense
                                ref="autoDestinoAereo"
                                label="Destino"
                                :filterRoute="2"
                                :search="El.destinationAddress"
                                :items="El.destinationItems"
                                :selections="El.destinationSelections"
                                transportation="AIR"
                                placeholder="Ingrese un destino o un puerto"
                                @change="(selections) => destinationSelectedAereo(selections, index)"
                                @updateAddress="(text) => updateDestinationAddress(text, index)"
                                @updateItems="(items) => updateDestinationItems(items, index)"
                                :id="2"
                            >
                            </s-airort-autocomplete>
                          </div>
                        </div>



                        <div v-if="order.transportationId==3"
                             class="row no-gutters position-relative text-center justify-center"
                        >
                          <div class="col-5 fixed-col-size">
                            <s-land-autocomplete
                                ref="autoOrigenTerrestre"
                                label="Origen"
                                :search="El.originAddress"
                                :items="El.originItems"
                                :selections="El.originSelections"
                                placeholder="Ingrese un origen"
                                @change="(selections) => originSelectedTerrestre(selections, index)"
                                @updateAddress="(text) => updateOriginAddress(text, index)"
                                @updateItems="(items) => updateOriginItems(items, index)"
                                :id="1"
                                transportation="SEA"
                                dense
                            >
                            </s-land-autocomplete>
                          </div>
                          <div class="col-5 fixed-col-size">
                            <s-land-autocomplete
                                ref="autoDestinoTerrestre"
                                label="Destino"
                                :search="El.destinationAddress"
                                :items="El.destinationItems"
                                :selections="El.destinationSelections"
                                placeholder="Ingrese un destino o un puerto"
                                @change="(selections) => destinationSelectedTerrestre(selections, index)"
                                @updateAddress="(text) => updateDestinationAddress(text, index)"
                                @updateItems="(items) => updateDestinationItems(items, index)"
                                :id="2"
                                transportation="SEA"
                                dense
                            >
                            </s-land-autocomplete>
                          </div>
                        </div>
                      </div>


                    </v-expand-transition>
                  </v-row>
                  <v-row
                      justify="center"
                      no-gutters
                      class="mx-2"
                  >
                    <v-col md="12" class="my-3">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                          <th class="text-left">
                            Cargo
                          </th>

                          <th class="text-left" v-if="order.transportationCategoryId !== 1">
                            <v-switch
                                v-model="El.isTotalPrice"
                                dense
                                :label="!El.isTotalPrice ? 'Precio por contenedor' : 'Precio total'"
                                @change="calculateTableTotal(index)"
                            >
                            </v-switch>
                          </th>

                          <th v-else>
                            Precio total
                          </th>

                          <th class="text-left">
                            Cantidad
                          </th>

                          <th class="text-left">
                            Total
                          </th>
                          <th class="text-left">
                            Moneda
                          </th>
                          <th class="text-left">
                            IVA
                          </th>
                          <th class="text-left">
                            RET
                          </th>
                          <th class="text-left" v-if="order.transportationCategory == 1">
                            Contenedor
                          </th>

                          <th class="table-actions-row">
                          </th>
                          <th class="table-actions-row">
                          </th>

                          </thead>
                          <tbody>
                          <tr
                              v-for="(item, feeIndex) in El.priceFees"
                              :key="`Price${index}FeeKey${feeIndex}`"
                              class="table-fee-row"
                          >
                            <td>
                              <v-text-field
                                  v-model="item.name"
                                  :value="item.name"
                                  rounded
                                  class="table-fee-input col-md-12"
                                  hide-details
                              ></v-text-field>
                            </td>
                            <td>
                              <v-text-field
                                  v-model.number="item.unitPrice"
                                  @input="calculateTableTotal(index)"
                                  rounded
                                  type="number"
                                  class="table-fee-input"
                                  hide-details
                              ></v-text-field>
                            </td>
                            <td>
                              <v-text-field
                                  v-model.number="item.quantity"
                                  :value="item.quantity"
                                  @input="calculateTableTotal(index)"
                                  rounded
                                  class="table-fee-input"
                                  hide-details
                              ></v-text-field>
                            </td>
                            <td>
                              <v-text-field
                                  readonly
                                  type="number"
                                  :value="item.total"
                                  v-model="item.total"
                                  rounded
                                  class="table-fee-input"
                                  hide-details
                              ></v-text-field>
                            </td>


                            <td>
                              <v-autocomplete class="col-md-12 py-3 pb-2" v-model="item.currencyId" item-value="id"
                                              :items="Currencies" item-text="code"
                                              dense>
                              </v-autocomplete>
                            </td>

                            <td>
                              <v-checkbox v-model="item.hasVat" :disabled="item.hasRet"
                                          @change="calculateTableTotal(index)"></v-checkbox>
                            </td>
                            <td>
                              <v-checkbox v-model="item.hasRet" :disabled="item.hasVat"
                                          @change="calculateTableTotal(index)"></v-checkbox>
                            </td>
                            <td v-if="order.transportationCategoryId != 1">
                              <div v-if="priceContainers.length > 1 && order.transportationCategoryId !=1">
                                <v-select
                                    dense
                                    rounded
                                    v-model="El.containerId"
                                    :items="priceContainers"
                                    :menu-props="{
                                    closeOnContentClick: true,
                                    'content-class':'included'
                                  }"
                                    @change="setQuantityPerContainer(index,El.containerId)"
                                    item-text="description"
                                    item-value="id"
                                    style="max-width: 100px"
                                    class="table-fee-input pa-0"
                                    hide-details
                                    :value="El.containerId ? priceContainers.find(x => x.id == El.containerId).description : ''"
                                ></v-select>
                              </div>
                              <div v-else-if="order.transportationCategoryId!=1">
                                <v-text-field
                                    readonly
                                    :value="priceContainers[0].description"
                                    rounded
                                    class="table-fee-input"
                                    hide-details
                                ></v-text-field>
                              </div>
                            </td>
                            <td class="table-actions-row">
                              <v-btn
                                  @click="remove(index, feeIndex)"
                                  color="red"
                                  icon
                                  small
                                  v-if="El.priceFees.length>1"
                              >
                                <v-icon
                                >mdi-minus
                                </v-icon>
                              </v-btn>
                              <v-btn

                                  @click="add(index)"
                                  color="primary"
                                  icon
                                  small
                              >
                                <v-icon>mdi-plus-circle
                                </v-icon>
                              </v-btn>
                            </td>
                          </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                  <v-row
                      no-gutters
                  >
                    <v-col md="12" class="pa-0">
                      <file-drag v-model="El.priceDocumentsDrag" @change="ConvertFile(index)"></file-drag>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="12">
                      <v-textarea :rows="3" auto-grow v-model="El.comments" outlined
                                  label="Notas al vendedor"></v-textarea>
                    </v-col>
                  </v-row>

                </div>

              </v-expand-transition>
            </section>
            <div v-else-if="!showRatableWeigth">
              <v-row
                  @click="openPrice(index)"
                  class="px-2 pricesHeader"
                  justify="center"
                  transition="fade-transition"
                  no-gutters
              >
                <v-col
                    cols="2"
                    class="text-left my-auto"
                >
                  <v-icon
                      v-show="El.transportationId == 1"
                  >mdi-ferry
                  </v-icon>
                  <v-icon
                      v-show="El.transportationId == 2"
                  >mdi-airplane
                  </v-icon>
                  <v-icon
                      v-show="El.transportationId == 3"
                  >mdi-truck
                  </v-icon>
                </v-col>
                <v-col
                    cols="7"
                    class="my-auto"
                >
                  <v-row
                      no-gutters
                  >
                    <v-col
                        cols="6"
                    >
                      <span class="text-caption">Vigencia: {{ !El.validity ? '-' : El.validity }}</span>
                    </v-col>
                    <v-col
                        cols="6"
                    > <span
                        class="text-caption">Dias: {{ `${!El.transportationTime ? '' : El.transportationTime}` }} </span>

                    </v-col>
                  </v-row>
                  <v-row
                      no-gutters
                  >
                    <v-col
                        cols="6"
                    >
                      <div v-if="El.supplierId > 0">
                        <span class="text-caption">Operado por {{ El.supplier.companyName }}</span>
                      </div>
                    </v-col>
                    <v-col
                        cols="6"
                    >
                      <div
                          v-if="El.pricesOrigins[0] || El.pricesDestination[0]"
                      >
                        <span class="text-caption">Ruta: </span>
                        <span class="text-caption">{{
                            El.pricesOrigins[0] ? (El.pricesOrigins[0].port ? El.pricesOrigins[0].port.name : El.pricesOrigins[0].city) : 'NA'
                          }}</span>
                        <span class="text-caption px-1">-</span>
                        <span class="text-caption">{{
                            El.pricesDestination[0] ? (El.pricesDestination[0].port ? El.pricesDestination[0].port.name : El.pricesDestination[0].city) : 'NA'
                          }}</span>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                    class="my-auto"
                >
                  <div
                      v-show="El.total > 0"
                      transition="fade-transition"
                  >
                <span
                    v-if="El.showTotalPrice"
                    class="text-h6 font-weight-bold"
                >${{ El.total }}</span>
                  </div>
                </v-col>
                <v-col
                    cols="2"
                    class="text-right my-auto"
                >
                  <div class="d-inline-flex">
                    <v-tooltip bottom v-if="prices.length>1">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            color="red"
                            @click="removePrice(El.id)"
                            v-bind="attrs"
                            v-on="on"
                        >mdi-delete
                        </v-icon>
                      </template>
                      <span>Eliminar</span>
                    </v-tooltip>

                    <v-dialog v-model="DeletePriceDialog" width="1000">
                      <v-card>
                        <v-card-title class="primary white--text">¿Desea eliminar este precio?</v-card-title>
                        <v-card-text>
                          <v-row>
                            <v-col md="3" class="offset-4">
                              <v-btn color="primary" @click="deletePrice(El.id)">Si</v-btn>
                            </v-col>

                            <v-col md="3">
                              <v-btn color="red" class="white--text" @click="DeletePriceDialog=false">No</v-btn>
                            </v-col>

                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-dialog>


                    <div v-if="El.isUpdating">
                      <v-icon>
                        mdi-sync
                      </v-icon>
                      <span
                          class="text-caption"
                      >Guardando...</span>
                    </div>
                    <div v-if="El.id!=0">
                      <v-icon>
                        mdi-cloud-check-outline
                      </v-icon>
                    </div>
                    <div v-if="El.id==0">
                      <v-icon size="25" color="red">
                        mdi-weather-cloudy-alert

                      </v-icon>
                    </div>
                  </div>

                  <!--v-btn
                      icon
                      small

                  >
                    <v-icon
                        primary
                    >{{ El.isSuggested ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon>
                  </v-btn-->
                </v-col>
              </v-row>
              <v-expand-transition>
                <div
                    v-if="!El.dense"
                    transition="expand-y-transition"
                >
                  <v-divider class="py-1"></v-divider>
                  <v-row>
                    <div class="rounded-lg grey lighten-2 px-2 py-1 mx-auto my-6">
                      <v-row>
                        <v-col cols="4">
                          <v-autocomplete label="Agente en origen"
                            single-line
                            hide-details
                            no-filter
                            clearable
                            rounded
                            prepend-icon="mdi-handshake"
                            v-model="El.freightForwarderId"
                            :loading="loadingForwarders"
                            @update:search-input="findForwarder($event)"
                            :items="forwarders"
                            item-value="id"
                            item-text="companyName"
                            class="mx-1 supplier-autocomplete pa-0">
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="5">
                          <v-form v-model="supplierForm" ref="myFormSupplier" lazy-validation>
                            <v-autocomplete
                                v-model="El.supplier"
                                class="mx-1 supplier-autocomplete pa-0"
                                rounded
                                :items="El.supplierSearchResults"
                                :loading="El.searchingSupplier"
                                hide-details
                                :rules="rules.required"
                                label="Busca tu proveedor"
                                single-line
                                @input="(val) => setAndMapSupplier(val, index)"
                                :search-input.sync="searchSupplier"
                                item-text="companyName"
                                return-object
                                prepend-icon="mdi-magnify"
                            >
                              <template v-slot:item="{item}">
                                <div class="d-inline-block included">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on: tooltip }">
                                      <v-icon v-on="tooltip">{{ getSupplierIcon(item.supplierTypeId) }}</v-icon>
                                    </template>
                                  </v-tooltip>
                                  <span class="ml-2">{{ item.companyName }}</span>
                                </div>
                              </template>
                            </v-autocomplete>
                          </v-form>
                        </v-col>
                        <v-col cols="2">
                          <div class="d-flex">
                            <v-btn icon class="pt-0" @click="showAddSupplier=true">
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>

                            <v-tooltip bottom
                                       v-if="validatePod == false || validatePol == false && El.transportationId == 1 && El.transportationId == 2">
                              <template
                                  v-slot:activator="{ on: tooltip, attrs }"
                                  v-bind="attrs"
                              >
                                <v-btn

                                    icon
                                    small
                                    v-on="{...tooltip}"
                                    @click="alertPolAndPod()"
                                >
                                  <v-icon color="red">mdi-alert</v-icon>
                                </v-btn>
                              </template>
                              <span>Abrir</span>
                            </v-tooltip>

                            <v-menu
                                v-model="El.optionsMenuOpen"
                                offset-y
                                content-class="included"
                                :close-on-content-click="false"
                            >
                              <template v-slot:activator="{ on: menu, attrs }">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on: tooltip }">
                                    <v-btn
                                        icon
                                        small
                                        @click="validateFields"
                                        class="mx-1"
                                        v-bind="attrs"
                                        v-on="{...menu, ...tooltip}"
                                    >
                                      <v-icon v-if="El.transportationTime==null || El.transportationTime<=0"
                                              color="red">
                                        mdi-tune
                                      </v-icon>

                                      <v-icon v-else>mdi-tune</v-icon>


                                    </v-btn>
                                  </template>
                                  Más opciones
                                </v-tooltip>

                              </template>
                              <v-card min-width="400">
                                <v-card-title class="text-center">Opciones adicionales</v-card-title>
                                <v-card-text>
                                  <v-row no-gutters class="mb-5">
                                    <v-col cols="5">
                                      <span class="my-auto">Vigencia</span>
                                    </v-col>
                                    <v-col cols="7">
                                      <v-menu offset-y>
                                        <template v-slot:activator="{ on: menu, attrs }">
                                          <v-form v-model="vigency" ref="myFormVigency">
                                            <v-text-field
                                                v-model="El.validity"
                                                label="Vigencia"
                                                append-icon="mdi-calendar"
                                                class="more-options-text"
                                                dense
                                                :rules="rules.required"
                                                readonly
                                                hide-details
                                                v-bind="attrs"
                                                v-on="menu"
                                            ></v-text-field>
                                          </v-form>

                                        </template>
                                        <v-date-picker
                                            v-model="El.validity"
                                            class="included"
                                            no-title
                                            scrollable
                                            color="primary"
                                            :min="incrementDate(new Date(),1).toISOString().substr(0, 10)"
                                        >
                                          <v-spacer></v-spacer>
                                          <v-btn
                                              text
                                              color="primary"
                                              @click="menu = false"
                                          >
                                            Cancel
                                          </v-btn>
                                          <v-btn
                                              text
                                              color="primary"
                                              @click="$refs.menu.save(date)"
                                          >
                                            OK
                                          </v-btn>
                                        </v-date-picker>
                                      </v-menu>
                                    </v-col>
                                  </v-row>
                                  <v-row no-gutters>
                                    <v-col cols="5">
                                      Tiempo de transito
                                    </v-col>

                                    <v-col cols="7">
                                      <v-form v-model="validTransportation" ref="myForm">
                                        <v-text-field
                                            dense
                                            type="number"
                                            :rules="rules.required"
                                            label="Número de días"
                                            v-model.number="El.transportationTime"
                                            class="more-options-text"
                                        >
                                        </v-text-field>
                                      </v-form>
                                    </v-col>


                                  </v-row>
                                  <div v-if="isLandTransportation(index)">
                                    <v-row no-gutters>
                                      <v-col cols="5">
                                        Costo de estadia
                                      </v-col>
                                      <v-col cols="7">
                                        <v-text-field
                                            dense
                                            label="por hora"
                                            v-model="El.priceCondition.demurrageCost"
                                            class="more-options-text"
                                        >

                                        </v-text-field>
                                      </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                      <v-col cols="5">
                                        Tiempo de
                                      </v-col>
                                      <v-col cols="7">
                                        <v-row no-gutters>
                                          <v-col cols="6" class="pr-2">
                                            <v-text-field
                                                dense
                                                label="carga"
                                                v-model="El.priceCondition.loadingTime"
                                                class="more-options-text"
                                            >
                                            </v-text-field>
                                          </v-col>
                                          <v-col cols="6" class="pl-2">
                                            <v-text-field
                                                dense
                                                label="descarga"
                                                v-model="El.priceCondition.dischargeTime"
                                                class="more-options-text"
                                            >
                                            </v-text-field>
                                          </v-col>
                                        </v-row>
                                      </v-col>
                                    </v-row>
                                  </div>
                                </v-card-text>
                                <v-card-actions>
                                  <v-btn
                                      text
                                      color="primary"
                                      @click="El.optionsMenuOpen = false"
                                  >
                                    OK
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-menu>
                            <v-tooltip bottom v-if="El.id > 0">
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                    icon
                                    small
                                    v-on="tooltip"
                                    class="mx-1"
                                    @click="duplicatePrice(El.id)"

                                >
                                  <v-icon>mdi-clipboard-multiple-outline</v-icon>
                                </v-btn>
                              </template>
                              Duplicar
                            </v-tooltip>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                    icon
                                    small
                                    v-on="tooltip"
                                    class="mx-1"
                                    @click="updatePriceButton(index)"

                                >
                                  <v-icon>mdi-cloud-upload-outline</v-icon>
                                </v-btn>
                              </template>
                              Guardar
                            </v-tooltip>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-row>
                  <v-row>
                    <v-expand-transition>

                      <div class="d-flex mx-auto justify-center" v-if="El.expansion" style="width: 100%">
                        <div v-if="order.transportationId==1 && order.deliveryTypeId == 1" class="row no-gutters position-relative text-center justify-center">
                          <div class="col-5 fixed-col-size">
                            <s-port-autocomplete
                                ref="autoOrigen"
                                label="Origen"
                                :search="El.originAddress"
                                :items="El.originItems"
                                :selections="El.originSelections"
                                :filterRoute="1"
                                @change="(selections) => originSelected(selections, index)"
                                @updateAddress="(text) => updateOriginAddress(text, index)"
                                @updateItems="(items) => updateOriginItems(items, index)"
                                placeholder="Ingrese un origen"
                                transportation="SEA"
                                :id="1"
                                dense
                            >
                            </s-port-autocomplete>
                          </div>
                          <div class="col-5 fixed-col-size">
                            <s-port-autocomplete
                                dense
                                ref="autoDestino"
                                label="Destino"
                                :filterRoute="1"
                                :search="El.destinationAddress"
                                :items="El.destinationItems"
                                :selections="El.destinationSelections"
                                placeholder="Ingrese un destino"
                                @change="(selections) => destinationSelected(selections, index)"
                                @updateAddress="(text) => updateDestinationAddress(text, index)"
                                @updateItems="(items) => updateDestinationItems(items, index)"
                                transportation="SEA"
                                :id="2"
                            >
                            </s-port-autocomplete>
                          </div>
                        </div>

                        <div v-if="order.transportationId==1 && order.deliveryTypeId == 2" class="row no-gutters position-relative text-center justify-center">
                          <div class="col-5 fixed-col-size">
                            <s-port-autocomplete
                                ref="autoOrigen"
                                label="Origen"
                                :search="El.originAddress"
                                :items="El.originItems"
                                :selections="El.originSelections"
                                :filterRoute="2"
                                @change="(selections) => originSelected(selections, index)"
                                @updateAddress="(text) => updateOriginAddress(text, index)"
                                @updateItems="(items) => updateOriginItems(items, index)"
                                placeholder="Ingrese un origen"
                                transportation="SEA"
                                :id="1"
                                dense
                            >
                            </s-port-autocomplete>
                          </div>
                          <div class="col-5 fixed-col-size">
                            <s-port-autocomplete
                                dense
                                ref="autoDestino"
                                label="Destino"
                                :filterRoute="2"
                                :search="El.destinationAddress"
                                :items="El.destinationItems"
                                :selections="El.destinationSelections"
                                placeholder="Ingrese un destino"
                                @change="(selections) => destinationSelected(selections, index)"
                                @updateAddress="(text) => updateDestinationAddress(text, index)"
                                @updateItems="(items) => updateDestinationItems(items, index)"
                                transportation="SEA"
                                :id="2"
                            >
                            </s-port-autocomplete>
                          </div>
                        </div>

                        <div v-if="order.transportationId==1 && order.deliveryTypeId == 3" class="row no-gutters position-relative text-center justify-center">
                          <div class="col-5 fixed-col-size">
                            <s-port-autocomplete
                                ref="autoOrigen"
                                label="Origen"
                                :search="El.originAddress"
                                :items="El.originItems"
                                :selections="El.originSelections"
                                :filterRoute="2"
                                @change="(selections) => originSelected(selections, index)"
                                @updateAddress="(text) => updateOriginAddress(text, index)"
                                @updateItems="(items) => updateOriginItems(items, index)"
                                placeholder="Ingrese un origen"
                                transportation="SEA"
                                :id="1"
                                dense
                            >
                            </s-port-autocomplete>
                          </div>
                          <div class="col-5 fixed-col-size">
                            <s-port-autocomplete
                                dense
                                ref="autoDestino"
                                label="Destino"
                                :filterRoute="1"
                                :search="El.destinationAddress"
                                :items="El.destinationItems"
                                :selections="El.destinationSelections"
                                placeholder="Ingrese un destino"
                                @change="(selections) => destinationSelected(selections, index)"
                                @updateAddress="(text) => updateDestinationAddress(text, index)"
                                @updateItems="(items) => updateDestinationItems(items, index)"
                                transportation="SEA"
                                :id="2"
                            >
                            </s-port-autocomplete>
                          </div>
                        </div>

                        <div v-if="order.transportationId==1 && order.deliveryTypeId == 4" class="row no-gutters position-relative text-center justify-center">
                          <div class="col-5 fixed-col-size">
                            <s-port-autocomplete
                                ref="autoOrigen"
                                label="Origen"
                                :search="El.originAddress"
                                :items="El.originItems"
                                :selections="El.originSelections"
                                :filterRoute="1"
                                @change="(selections) => originSelected(selections, index)"
                                @updateAddress="(text) => updateOriginAddress(text, index)"
                                @updateItems="(items) => updateOriginItems(items, index)"
                                placeholder="Ingrese un origen"
                                transportation="SEA"
                                :id="1"
                                dense
                            >
                            </s-port-autocomplete>
                          </div>
                          <div class="col-5 fixed-col-size">
                            <s-port-autocomplete
                                dense
                                ref="autoDestino"
                                label="Destino"
                                :filterRoute="2"
                                :search="El.destinationAddress"
                                :items="El.destinationItems"
                                :selections="El.destinationSelections"
                                placeholder="Ingrese un destino"
                                @change="(selections) => destinationSelected(selections, index)"
                                @updateAddress="(text) => updateDestinationAddress(text, index)"
                                @updateItems="(items) => updateDestinationItems(items, index)"
                                transportation="SEA"
                                :id="2"
                            >
                            </s-port-autocomplete>
                          </div>
                        </div>


                        <div v-if="order.transportationId==2 && order.deliveryTypeId == 1" class="row no-gutters position-relative text-center justify-center">
                          <div class="col-5 fixed-col-size">
                            <s-airort-autocomplete
                                dense
                                ref="autoOrigenAereo"
                                label="Origen"
                                :filterRoute="1"
                                :search="El.originAddress"
                                :items="El.originItems"
                                :selections="El.originSelections"
                                transportation="AIR"
                                placeholder="Ingrese un origen"
                                @change="(selections) => originSelectedAereo(selections, index)"
                                @updateAddress="(text) => updateOriginAddress(text, index)"
                                @updateItems="(items) => updateOriginItems(items, index)"
                                :id="1"
                            >
                            </s-airort-autocomplete>
                          </div>

                          <div class="col-5 fixed-col-size">
                            <s-airort-autocomplete
                                dense
                                ref="autoDestinoAereo"
                                label="Destino"
                                :filterRoute="1"
                                :search="El.destinationAddress"
                                :items="El.destinationItems"
                                :selections="El.destinationSelections"
                                transportation="AIR"
                                placeholder="Ingrese un destino o un puerto"
                                @change="(selections) => destinationSelectedAereo(selections, index)"
                                @updateAddress="(text) => updateDestinationAddress(text, index)"
                                @updateItems="(items) => updateDestinationItems(items, index)"
                                :id="2"
                            >
                            </s-airort-autocomplete>
                          </div>
                        </div>

                        <div v-if="order.transportationId==2 && order.deliveryTypeId == 2" class="row no-gutters position-relative text-center justify-center">
                          <div class="col-5 fixed-col-size">
                            <s-airort-autocomplete
                                dense
                                ref="autoOrigenAereo"
                                label="Origen"
                                :filterRoute="2"
                                :search="El.originAddress"
                                :items="El.originItems"
                                :selections="El.originSelections"
                                transportation="AIR"
                                placeholder="Ingrese un origen"
                                @change="(selections) => originSelectedAereo(selections, index)"
                                @updateAddress="(text) => updateOriginAddress(text, index)"
                                @updateItems="(items) => updateOriginItems(items, index)"
                                :id="1"
                            >
                            </s-airort-autocomplete>
                          </div>

                          <div class="col-5 fixed-col-size">
                            <s-airort-autocomplete
                                dense
                                ref="autoDestinoAereo"
                                label="Destino"
                                :filterRoute="2"
                                :search="El.destinationAddress"
                                :items="El.destinationItems"
                                :selections="El.destinationSelections"
                                transportation="AIR"
                                placeholder="Ingrese un destino o un puerto"
                                @change="(selections) => destinationSelectedAereo(selections, index)"
                                @updateAddress="(text) => updateDestinationAddress(text, index)"
                                @updateItems="(items) => updateDestinationItems(items, index)"
                                :id="2"
                            >
                            </s-airort-autocomplete>
                          </div>
                        </div>

                        <div v-if="order.transportationId==2 && order.deliveryTypeId == 3" class="row no-gutters position-relative text-center justify-center">
                          <div class="col-5 fixed-col-size">
                            <s-airort-autocomplete
                                dense
                                ref="autoOrigenAereo"
                                label="Origen"
                                :filterRoute="2"
                                :search="El.originAddress"
                                :items="El.originItems"
                                :selections="El.originSelections"
                                transportation="AIR"
                                placeholder="Ingrese un origen"
                                @change="(selections) => originSelectedAereo(selections, index)"
                                @updateAddress="(text) => updateOriginAddress(text, index)"
                                @updateItems="(items) => updateOriginItems(items, index)"
                                :id="1"
                            >
                            </s-airort-autocomplete>
                          </div>

                          <div class="col-5 fixed-col-size">
                            <s-airort-autocomplete
                                dense
                                ref="autoDestinoAereo"
                                label="Destino"
                                :filterRoute="1"
                                :search="El.destinationAddress"
                                :items="El.destinationItems"
                                :selections="El.destinationSelections"
                                transportation="AIR"
                                placeholder="Ingrese un destino o un puerto"
                                @change="(selections) => destinationSelectedAereo(selections, index)"
                                @updateAddress="(text) => updateDestinationAddress(text, index)"
                                @updateItems="(items) => updateDestinationItems(items, index)"
                                :id="2"
                            >
                            </s-airort-autocomplete>
                          </div>
                        </div>

                        <div v-if="order.transportationId==2 && order.deliveryTypeId == 4" class="row no-gutters position-relative text-center justify-center">
                          <div class="col-5 fixed-col-size">
                            <s-airort-autocomplete
                                dense
                                ref="autoOrigenAereo"
                                label="Origen"
                                :filterRoute="1"
                                :search="El.originAddress"
                                :items="El.originItems"
                                :selections="El.originSelections"
                                transportation="AIR"
                                placeholder="Ingrese un origen"
                                @change="(selections) => originSelectedAereo(selections, index)"
                                @updateAddress="(text) => updateOriginAddress(text, index)"
                                @updateItems="(items) => updateOriginItems(items, index)"
                                :id="1"
                            >
                            </s-airort-autocomplete>
                          </div>

                          <div class="col-5 fixed-col-size">
                            <s-airort-autocomplete
                                dense
                                ref="autoDestinoAereo"
                                label="Destino"
                                :filterRoute="2"
                                :search="El.destinationAddress"
                                :items="El.destinationItems"
                                :selections="El.destinationSelections"
                                transportation="AIR"
                                placeholder="Ingrese un destino o un puerto"
                                @change="(selections) => destinationSelectedAereo(selections, index)"
                                @updateAddress="(text) => updateDestinationAddress(text, index)"
                                @updateItems="(items) => updateDestinationItems(items, index)"
                                :id="2"
                            >
                            </s-airort-autocomplete>
                          </div>
                        </div>

                        <div v-if="order.transportationId==3"
                             class="row no-gutters position-relative text-center justify-center"
                        >
                          <div class="col-5 fixed-col-size">
                            <s-land-autocomplete
                                ref="autoOrigenTerrestre"
                                label="Origen"
                                :search="El.originAddress"
                                :items="El.originItems"
                                :selections="El.originSelections"
                                placeholder="Ingrese un origen"
                                @change="(selections) => originSelectedTerrestre(selections, index)"
                                @updateAddress="(text) => updateOriginAddress(text, index)"
                                @updateItems="(items) => updateOriginItems(items, index)"
                                :id="1"
                                transportation="SEA"
                                dense
                            >
                            </s-land-autocomplete>
                          </div>
                          <div class="col-5 fixed-col-size">
                            <s-land-autocomplete
                                ref="autoDestinoTerrestre"
                                label="Destino"
                                :search="El.destinationAddress"
                                :items="El.destinationItems"
                                :selections="El.destinationSelections"
                                placeholder="Ingrese un destino o un puerto"
                                @change="(selections) => destinationSelectedTerrestre(selections, index)"
                                @updateAddress="(text) => updateDestinationAddress(text, index)"
                                @updateItems="(items) => updateDestinationItems(items, index)"
                                :id="2"
                                transportation="SEA"
                                dense
                            >
                            </s-land-autocomplete>
                          </div>
                        </div>
                      </div>


                    </v-expand-transition>
                  </v-row>
                  <v-row
                      justify="center"
                      no-gutters
                      class="mx-2"
                  >
                    <v-col md="12" class="my-3">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                          <th class="text-left">
                            Cargo
                          </th>

                          <th class="text-left">
                            <v-switch
                                v-model="El.isTotalPrice"
                                dense
                                :label="!El.isTotalPrice ? 'Precio por kilo' : 'Precio total'"
                                @change="calculateTableTotal(index)"
                            >
                            </v-switch>
                          </th>

                          <th class="text-left">
                            Peso Tasable (Kgs)
                          </th>

                          <th class="text-left">
                            Total
                          </th>

                          <th class="text-left">
                            Moneda
                          </th>

                          <th class="text-left">
                            IVA
                          </th>
                          <th class="text-left">
                            RET
                          </th>

                          <th class="table-actions-row">
                          </th>
                          <th class="table-actions-row">
                          </th>

                          </thead>
                          <tbody>
                          <tr
                              v-for="(item, feeIndex) in El.priceFees"
                              :key="`Price${index}FeeKey${feeIndex}`"
                              class="table-fee-row"
                          >
                            <td>
                              <v-text-field
                                  v-model="item.name"
                                  :value="item.name"
                                  rounded
                                  class="table-fee-input col-md-12"
                                  hide-details
                              ></v-text-field>
                            </td>
                            <td>
                              <v-text-field
                                  type="number"
                                  v-model.number="item.unitPrice"
                                  @input="calculateTableTotal(index)"
                                  rounded
                                  class="table-fee-input"
                                  hide-details
                              ></v-text-field>
                            </td>
                            <td>
                              <v-text-field
                                  type="number"
                                  v-model.number="item.ratableWeight"
                                  :value="item.ratableWeight"
                                  v-model="item.ratableWeight"
                                  @change="setRatableWeightWarning(index,feeIndex)"
                                  :readonly="El.isTotalPrice"
                                  :disabled="El.isTotalPrice"
                                  rounded
                                  class="table-fee-input"
                                  v-bind:class="item.ratableWeightWarning ? 'text-red' : 'text-black'"
                                  hide-details
                              >
                              </v-text-field>
                            </td>
                            <td>
                              <v-text-field
                                  readonly
                                  type="number"
                                  :value="item.total"
                                  v-model="item.total"
                                  rounded
                                  class="table-fee-input"
                                  hide-details
                              ></v-text-field>
                            </td>

                            <td>
                              <v-autocomplete class="col-md-8 py-3 pb-2" v-model="item.currencyId" :items="Currencies"
                                              item-value="id" item-text="code" >
                              </v-autocomplete>
                            </td>

                            <td>
                              <v-checkbox v-model="item.hasVat" :disabled="item.hasRet"
                                          @change="calculateTableTotal(index)"></v-checkbox>
                            </td>
                            <td>
                              <v-checkbox v-model="item.hasRet" :disabled="item.hasVat"
                                          @change="calculateTableTotal(index)"></v-checkbox>
                            </td>
                            <td class="table-actions-row">
                              <v-btn
                                  v-if="El.priceFees.length>1"
                                  @click="remove(index, feeIndex)"
                                  color="red"
                                  icon
                                  small
                              >
                                <v-icon>mdi-minus
                                </v-icon>
                              </v-btn>
                              <v-btn
                                  @click="add(index)"
                                  color="primary"
                                  icon
                                  small
                              >
                                <v-icon
                                >mdi-plus-circle
                                </v-icon>
                              </v-btn>
                            </td>
                          </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                  <v-row
                      no-gutters
                  >
                    <v-col md="12" class="pa-0">
                      <file-drag v-model="El.priceDocumentsDrag" @change="ConvertFile(index)"></file-drag>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="12">
                      <v-textarea :rows="3" auto-grow v-model="El.comments" outlined
                                  label="Notas al vendedor"></v-textarea>
                    </v-col>
                  </v-row>
                </div>
              </v-expand-transition>
            </div>
          </v-container>
        </v-card>
      </div>

    </v-col>

    <v-col md="4">

      <v-row justify="center" class="pl-0" no-gutters>

        <v-col md="12" class="pl-0">

          <v-card style="border-radius: 5px" outlined elevation="0" class="pa-0" >

            <v-spacer></v-spacer>

            <v-tabs
                color="primary"
                background-color="primary"
                dark
                v-model="tabs"
            >
              <v-tab>Resumen</v-tab>
              <v-tab>Historial</v-tab>
              <v-tab v-if="order.statusOrder === 'Vendida'">Instrucción</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tabs">
              <v-tab-item>
                <v-row
                    justify="center"
                    no-gutters
                >
                  <v-expansion-panels
                      :value="activeQuotation"
                  >
                    <v-expansion-panel
                        v-for="(quote, index) in Quotations"
                        :key="`QuoteResume${index}`"
                    >
                      <v-expansion-panel-header>
                        Cotización sugerida {{ index + 1 }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div v-if="quote.quotationDetails.length > 0">w
                          <v-simple-table>
                            <template v-slot:default>
                              <thead>
                              <th class="text-left">
                                Proveedor
                              </th>
                              <th class="text-left">
                                Ruta
                              </th>
                              <th class="text-left">
                                Total
                              </th>
                              </thead>
                              <tbody>
                              <tr
                                  v-for="(elem, index) in quote.quotationDetails"
                                  :key="`quotationPrice${index}`"
                              >
                                <td>
                                  {{ elem.price.supplierId > 0 ? elem.price.supplier.companyName : 'NA' }}
                                </td>
                                <td>
                                  <span>{{
                                      elem.price.pricesOrigins[0] ? (elem.price.pricesOrigins[0].port ? elem.price.pricesOrigins[0].port.name : elem.price.pricesOrigins[0].city.name) : 'NA'
                                    }}</span>
                                  <span class="px-1">-</span>
                                  <span>{{
                                      elem.price.pricesDestination[0] ? (elem.price.pricesDestination[0].port ? elem.price.pricesDestination[0].port.name : elem.price.pricesDestination[0].city.name) : 'NA'
                                    }}</span>
                                </td>
                                <td>
                                  {{ elem.price.total }}
                                </td>
                              </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </div>
                        <div v-else>
                          <v-divider></v-divider>
                          <v-container class="pa-3">
                            <span class="text-caption">No hay precios asociados a esta cotización :(</span>
                          </v-container>
                        </div>
                        <span class="font-weight-bold">Total: </span>{{ quote.total }}
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-row>


              </v-tab-item>
              <v-tab-item>
                <v-row>

                  <v-col md="10">
                    <v-treeview
                        v-model="fileTree"
                        :items="fileStructure"
                        activatable
                        item-text="fileName"
                        return-object
                        open-on-clickahh
                    >
                      <template v-slot:prepend="{ item, open }">
                        <v-icon v-if="!item.fileExtension">
                          {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                        </v-icon>
                        <v-icon v-else>
                          {{ fileTypes[item.fileExtension.toLowerCase()] }}
                        </v-icon>
                      </template>
                      <template v-slot:label="{ item }">
                        <div @click="downloadDocument(item)" @contextmenu="showContextItemMenu($event,item)">
                          <span>{{ item.fileName }}</span><span
                            v-if="item.fileExtension">.{{ item.fileExtension.toLowerCase() }}</span>
                        </div>
                      </template>
                      <template v-slot:append="{ item, open }">
                        <v-icon @click="deleteDoc(item, open)" v-if="item.fileExtension" color="red">
                          mdi-minus
                        </v-icon>
                      </template>
                    </v-treeview>
                    <v-menu
                        v-model="itemMenu"
                        :position-x="itemMenux"
                        :position-y="itemMenuy"
                        absolute
                        offset-y
                    >
                      <v-list>
                        <v-list-item>
                          <v-list-item-title>
                            {{ selectedItemMenu.fileName }}
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="previewItem">
                          <v-list-item-icon>
                            <v-icon>mdi-eye</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>
                            Vista previa
                          </v-list-item-title>
                        </v-list-item>
                        <!--                        <v-list-item>-->
                        <!--                          <v-list-item-icon>-->
                        <!--                            <v-icon color="red">mdi-delete</v-icon>-->
                        <!--                          </v-list-item-icon>-->
                        <!--                          <v-list-item-title class="red&#45;&#45;text">-->
                        <!--                            Eliminar-->
                        <!--                          </v-list-item-title>-->
                        <!--                        </v-list-item>-->
                      </v-list>
                    </v-menu>
                  </v-col>

                  <v-col md="2">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="py-4"
                            @click="downloadAll(fileStructure[0].children)"
                            icon
                            v-bind="attrs"
                            v-on="on">
                          <v-icon>mdi-folder-download</v-icon>
                        </v-btn>

                      </template>
                      <span>Descargar Archivos</span>
                    </v-tooltip>


                  </v-col>

                </v-row>

                <OrderHistory :idOrder="idOrder"></OrderHistory>
              </v-tab-item>
              <v-tab-item>
                <timeLinePricing v-if="order.statusOrder === 'Vendida'" v-model="shipment" :type="order.transportationId" :el-tab="tabs"/>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <v-dialog v-model="showAddSupplier" width="1000">

      <v-card>
        <v-toolbar
            flat
            dark
            color="primary"
        >

          <v-toolbar-title>
            <v-icon left>mdi-table-edit</v-icon>
            Agregar Proveedor
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
              icon
              dark
              left
              @click="showAddSupplier = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <Suppliers v-on:created="showAddSupplier = false"></Suppliers>
      </v-card>
    </v-dialog>

    <v-dialog v-model="previewDocumentDialog" min-width="500">
      <v-card min-height="500">
        <v-toolbar
            flat
            dark
            color="primary"
        >
          <v-toolbar-title>
            Vista previa
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
              icon
              dark
              left
              @click="previewDocumentDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-6">
          <v-img contain ref="imagePreview" height="500"
                 v-if="imageTypes.includes(selectedItemMenu.fileExtension)"></v-img>
          <iframe ref="pdfPreview" type="application/pdf" width="100%" height="500" style="overflow: auto;"
                  v-if="selectedItemMenu.fileExtension === 'pdf'">
          </iframe>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import debouncePromise from "debounce-promise";
import CompraBL from "@/views/Pages/Operation/components/compraBL";
import VentaBL from "@/views/Pages/Operation/components/ventaBL";
import timeLinePricing from "@/components/Pricing/timeLinePricing";
// import googleMapsAutocompleteVuetify from "@/components/google-maps-autocomplete-vuetify/index"
// import googleMapsAutocompleteVuetifyAereo from "@/components/google-maps-autocomplete-vuetify/indexAereo"
// import googleMapsAutocompleteVuetifyTerrestre from "@/components/google-maps-autocomplete-vuetify/indexTerrestre"
import Price from "../../prototypes/price";
import colors from 'vuetify/lib/util/colors'
import Suppliers from "../Accounts_Admin/Suppliers";
import OrderHistory from "../Quote/QuotDetail/OrderHistory";
import FileDrag from "../Global/FileDrag/FileDrag";
import fileDownload from "js-file-download";
import SPortAutocomplete from "@/components/google-maps-autocomplete-vuetify/index_new";
import SAirortAutocomplete from "@/components/google-maps-autocomplete-vuetify/SAirportAutocomplete";
import SLandAutocomplete from "@/components/google-maps-autocomplete-vuetify/SLandAutocomplete";
import {debounce} from "debounce";

const _GLOBALS = {
  TRANSPORTATION: {
    MARITIME: 1,
    AIR: 2,
    LAND: 3,
  },
  SUPPLIER_TYPES: {
    AIR: 2,
    LAND: 3,
    MARITIME: 4,
    MARKETING: 5,
    INSPECTION: 6,
    CARGO_SAFE: 7,
    WAREHOUSING: 8,
    PACKAGING: 9,
    FINANCING: 10
  }
}

export default {
  name: "FormatFees2",
  components: {
    VentaBL,
    CompraBL,
    timeLinePricing,
    SLandAutocomplete,
    SAirortAutocomplete,
    SPortAutocomplete,
    FileDrag,
    OrderHistory,
    Suppliers,
    // GoogleAutocomplete: googleMapsAutocompleteVuetify,
    // googleMapsAutocompleteVuetifyAereo,
    // googleMapsAutocompleteVuetifyTerrestre
  },
  data: () => ({
    loadingForwarders: false,
    shipment: {
      deliveryTypeId: null,
      supplierId: null,
      ShipName: null,
      viaje: null,
      id: null,
      orderId: null,
      transportationId: null,
      transportationCategoryId: null,
      incoterm: null,
      incotermId: null,
      users: [],
      pol: null,
      pod: null,
      masterBl: {},
      origin: null,
      destination: null,
      shippingStatusId: null,
      orderFolio: null,
      containers: [],
      emails: [],
      routes: [],
      supplier: null,
    },
    previewDocumentDialog: false,
    selectedItemMenu: {},
    destination2: {},
    itemMenu: false,
    itemMenux: 0,
    filterRoute:null,
    isTerrestre0: false,
    itemMenuy: 0,
    supplierForm: false,
    fileTree: null,
    originAddress2:"",
    destinationAddress2:"",
    destinationItems2: [],
    originSelections2: {},
    originSelections: {},
    originItems2: [],
    originAddress:"",
    fileTypes: {
      pdf: 'mdi-file-pdf-box',
      png: 'mdi-file-image',
      jpg: 'mdi-file-image',
      jpeg: 'mdi-file-image',
      txt: 'mdi-file-document-outline',
      xls: 'mdi-file-excel',
    },
    acceptedFiles: [
      "png",
      "jpg",
      "jpeg",
      "gif",
      "tiff",
      "svg",
      "pdf",
    ],
    imageTypes: [
      "png",
      "jpg",
      "jpeg",
      "gif",
      "tiff",
      "svg"
    ],
    fileStructure: [
      {
        fileName: "Documentos",
        children: [],}
    ],
    items: [
      {
        action: 'mdi-ticket',
        title: 'Documentos',
      },

    ],
    forwarders: [],
    showRatableWeigth: null,
    update: false,
    prices: [],
    rules: {
      required: [value => !!value || ""]
    },
    validTransportation: false,
    validFreeDays: false,
    vigency: false,
    successfullPrice: 0,
    PricerDocuments: [],
    notSendPrices: false,
    priceType: true,
    DeletePriceDialog: false,
    DeleteId: null,
    priceContainers: [],
    tabs: 1,
    IdOrder: 0,
    colors: colors,
    searchSupplier: "",
    supplierTypes: [],
    currencyId: 1,
    additionalServices: [
      {
        id: 1,
        name: "Embalaje"
      },
      {
        id: 8,
        name: "Seguro de mercancia"
      },
      {
        id: 14,
        name: "Financiamiento"
      },
      {
        id: 15,
        name: "Agente Aduanal"
      },
      {
        id: 16,
        name: "Almacenamiento"
      },
      {
        id: 17,
        name: "Custodia armada"
      },
      {
        id: 18,
        name: "Paletizadora"
      },
      {
        id: 19,
        name: "Inspección"
      }
    ],
    TransportationTypes: [
      {
        id: 1,
        name: 'Maritimo',
        icon: 'las la-water'
      },
      {
        id: 2,
        name: 'Aereo',
        icon: 'las la-plane'
      },
      {
        id: 3,
        name: 'Terrestre',
        icon: 'las la-truck'
      }
    ],
    Quotations: [],
    Currencies: [],
    showTotalPrice: true,
    deletePriceModal: false,
    containers: [],
    ResumeTotal: 0,
    validatePol: false,
    validatePod: false,
    selectedItem: 1,
    Origin: {
      id: 0,
      orderPriceId: 0,
      address: null,
      city: null,
      state: null,
      country: null,
      coordinates: null,
      port: null
    },
    defOrigin: {
      id: 0,
      orderPriceId: 0,
      address: null,
      city: null,
      state: null,
      country: null,
      coordinates: null,
      port: null
    },
    seasons: [
      'Winter',
      'Spring',
    ],
    Suppliers: [],
    Destination: {
      id: 0,
      orderPriceId: 0,
      address: null,
      city: null,
      state: null,
      country: null,
      coordinates: null,
      port: null
    },
    defDestination: {
      id: 0,
      orderPriceId: 0,
      address: null,
      city: null,
      state: null,
      country: null,
      coordinates: null,
      port: null
    },
    counterElement: 0,
    showAddSupplier: false,
    dropzoneOptions: {
      url: 'https://httpbin.org/post',
      thumbnailWidth: 20,
      thumbnailHeight: 20,
      maxFilesize: 2,
      headers: {"My-Awesome-Header": "header value"},
    },
    activeQuotation: 0,
    order: {}
  }),
  props: {
    idOrder: {
      type: Number,
      default: 1
    },
  },
  watch: {
    /*'order.statusOrder': {
      handler() {

      }
    },*/
    idOrder() {
      this.$nextTick(function () {
        this.mapPricesData()
      })
    },


    searchSupplier(val) {
      if (!val)
        return null

      let openPriceIndex = this.prices.findIndex(x => !x.dense)

      if (openPriceIndex == -1)
        throw new Error("No price is open and a change was detected")

      this.searchSuppliers(openPriceIndex, val)
    },
    activeQuotation() {

    },
    Quotations() {
      this.$nextTick(() => {
        this.mapPricesQuotation();
      })
    }
  },
  beforeMount() {
    this.$http.get('suppliers/SupplierType').then(response => {
      if (response.data.code === 200) {
        this.supplierTypes = response.data.data
      } else {
        //allert('Error suppliers/SupplierType FormatFees2')
      }
    }).catch(error => {
      console.error(error)
      //allert('Error suppliers/SupplierType FormatFees2')
    })
    this.$nextTick(() => {
      this.getQuotation().then((response) => {
        console.log(response)
        this.Quotations = response
      });
    })

  },
  mounted() {
    this.idOrder = this.$route.params.id;
    this.idOrder = parseInt(this.idOrder)
    this.getSuppliers();
    //this.getForwarders();
    this.getCurrencies();
    this.$nextTick(() => {
      this.mapPricesData()
     /* setTimeout(() => {
        if (this.order.statusOrder === 'Vendida') {
          this.tabs = 2
        }
      }, 4000)*/
    })
    this.filterRoute = this.$store.getters.filterRoute;

  },
  methods: {
    Confirmshipping(){
      this.$store.dispatch('working', true);
      this.$http.post(`Shipping/${this.shipment.id}/confirm`).then(response => {
        if (response.data.code == 200) {
          // console.log(response.data)
          this.$toast.info("Embarque Confirmado");
        }
        if (response.data.code == 400 || response.data.code == 500)
          this.$toast.error("Algo salio mal, intente mas tarde")
      }).catch(() => {
        // console.log(error)
      }).finally(() => {
        this.$store.dispatch('working', false);
      })
    },


    previewItem() {
      if (this.selectedItemMenu.fileExtension &&
          this.acceptedFiles.includes(this.selectedItemMenu.fileExtension)) {
        if (this.selectedItemMenu.type === "order") {
          this.$store.dispatch('working', true);
          this.$http({
            url: `Orders/${this.selectedItemMenu.orderId}/Document/${this.selectedItemMenu.id}`,
            method: 'GET',
            responseType: 'blob', // important
          }).then((response) => {
            let urlCreator = window.URL || window.webkitURL;
            let imageUrl = urlCreator.createObjectURL(response.data);
            this.previewDocumentDialog = true;
            if (this.imageTypes.includes(this.selectedItemMenu.fileExtension)) {
              this.$nextTick(() => {
                this.$refs.imagePreview.src = imageUrl
              })
            } else if (this.selectedItemMenu.fileExtension === "pdf") {

              this.$nextTick(() => {
                let reader = new FileReader();
                let that = this;
                reader.readAsDataURL(response.data);
                reader.onloadend = function () {
                  let base64data = reader.result;
                  let realb64 = base64data.split(',')[1];
                  let finalB64 = 'data:application/pdf;base64,' + realb64;
                  that.$refs.pdfPreview.src = finalB64;
                }
              })

            }
            // fileDownload(response.data, `${this.selectedItemMenu.fileName}.${this.selectedItemMenu.fileExtension}`);
            // this.$toast.info('Pdf Generado');
            // var file = new Blob([response.data], {type: 'application/pdf'});
            // var fileURL = URL.createObjectURL(file);
            // window.open(fileURL);
          }).catch(error => {
            this.$toast.error("Algo salio mal, intente mas tarde")
            console.log(error);
          }).finally(() => {
            this.$store.dispatch('working', false);
          })
        }
        if (this.selectedItemMenu.type === "cargo") {
          this.$store.dispatch('working', true);
          this.$http({
            url: `Orders/${this.selectedItemMenu.orderId}/Cargo/${this.selectedItemMenu.cargoId}/Document/${this.selectedItemMenu.id}`,
            method: 'GET',
            responseType: 'blob', // important
          }).then((response) => {
            let urlCreator = window.URL || window.webkitURL;
            let imageUrl = urlCreator.createObjectURL(response.data);
            this.previewDocumentDialog = true;
            if (this.imageTypes.includes(this.selectedItemMenu.fileExtension)) {
              this.$nextTick(() => {
                this.$refs.imagePreview.src = imageUrl
              })
            } else if (this.selectedItemMenu.fileExtension === "pdf") {

              this.$nextTick(() => {
                let reader = new FileReader();
                let that = this;
                reader.readAsDataURL(response.data);
                reader.onloadend = function () {
                  let base64data = reader.result;
                  let realb64 = base64data.split(',')[1];
                  let finalB64 = 'data:application/pdf;base64,' + realb64;
                  that.$refs.pdfPreview.src = finalB64;
                }
              })

            }
            // fileDownload(response.data, `${this.selectedItemMenu.fileName}.${this.selectedItemMenu.fileExtension}`);
            // this.$toast.info('Pdf Generado');
            // var file = new Blob([response.data], {type: 'application/pdf'});
            // var fileURL = URL.createObjectURL(file);
            // window.open(fileURL);
          }).catch(error => {
            this.$toast.error("Algo salio mal, intente mas tarde")
            console.log(error);
          }).finally(() => {
            this.$store.dispatch('working', false);
          })
        }
        if (this.selectedItemMenu.type === "price") {
          this.$http({
            url: `Orders/Price/${this.selectedItemMenu.orderPriceId}/Document/${this.selectedItemMenu.id}`,
            method: 'GET',
            responseType: 'blob', // important
          }).then(response => {
            let urlCreator = window.URL || window.webkitURL;
            let imageUrl = urlCreator.createObjectURL(response.data);
            this.previewDocumentDialog = true;
            if (this.imageTypes.includes(this.selectedItemMenu.fileExtension)) {
              this.$nextTick(() => {
                this.$refs.imagePreview.src = imageUrl
              })
            } else if (this.selectedItemMenu.fileExtension === "pdf") {

              this.$nextTick(() => {
                let reader = new FileReader();
                let that = this;
                reader.readAsDataURL(response.data);
                reader.onloadend = function () {
                  let base64data = reader.result;
                  let realb64 = base64data.split(',')[1];
                  let finalB64 = 'data:application/pdf;base64,' + realb64;
                  that.$refs.pdfPreview.src = finalB64;
                }
              })

            }
            // fileDownload(response.data, `${this.selectedItemMenu.fileName}.${this.selectedItemMenu.fileExtension}`);
            // this.$toast.info('Pdf Generado');
            // var file = new Blob([response.data], {type: 'application/pdf'});
            // var fileURL = URL.createObjectURL(file);
            // window.open(fileURL);
          }).catch(error => {
            this.$toast.error("Algo salio mal, intente mas tarde")
            console.log(error);
          }).finally(() => {
            this.$store.dispatch('working', false);
          })
        }
      }
    },
    showContextItemMenu(e, item) {
      e.preventDefault()
      this.itemMenu = false
      if (item.fileExtension && this.acceptedFiles.includes(item.fileExtension)) {
        this.selectedItemMenu = item;
        this.itemMenux = e.clientX
        this.itemMenuy = e.clientY
        this.$nextTick(() => {
          this.itemMenu = true
        })
      }
    },
    alertPolAndPod() {
      this.$toast.error('Debe agregar POL y POD');
    },
    duplicatePrice(id) {


      let price = this.prices.find(element => element.id == id)
      price = JSON.parse(JSON.stringify(price));
      this.showFees = true;
      this.counterElement++;
      price.priceFees.forEach(element => {
        element.id = 0;
      })
      price.priceCondition.orderPriceId = 0;

      let priceIndex = this.prices.findIndex(element => element.id == id)
      this.prices[priceIndex].dense = true;

      let newTotal = this.prices.push({
        ...price,
        id: 0,
        IdFee: this.counterElement,
        dense: false,
      })
      console.log("New Total", newTotal);
      let newIndex = newTotal - 1;
      this.openPrice(newIndex);
      let successPrices = this.prices.some(price => price.id === 0)
      this.$emit('successPrices', !successPrices)
      //let indexDuplicate = newIndex
    },
    incrementDate(dateInput, increment) {
      let dateFormatTotime = new Date(dateInput);
      let increasedDate = new Date(dateFormatTotime.getTime() + (increment * 86400000));
      return increasedDate;
    },
    setFeesAndUpdateQuotation(evt) {
      let detailIndex = this.QuotationsData.quotationDetails.findIndex(x => x.id == evt[0].detailId)

      if (detailIndex == -1) {
        throw new Error('No se encontró este elemento')
      }

      console.log("Set Fees And Update", evt)
      let feesUpdated = this.QuotationsData.quotationDetails[detailIndex].fees.map((item, index) => {
        return {
          ...item,
          ...evt[index]
        }
      })


      this.QuotationsData.quotationDetails[detailIndex].fees = feesUpdated
      this.QuotationsData.quotationDetails[detailIndex].profit = feesUpdated.reduce((a, b) => {
        return a + b.profit
      }, 0)

      this.QuotationsData.quotationDetails[detailIndex].discount = feesUpdated.reduce((a, b) => {
        return a + b.discount
      }, 0)

      console.log("New Profit", this.QuotationsData.quotationDetails[detailIndex].profit);

      this.calculateQuoteDetailProfit(detailIndex)
      this.calculateQuoteDetailDiscount(detailIndex)
      this.calculateQuoteDetailTotal(detailIndex)
    },
    setQuotationFeeVisibleAndUpdate(evt) {
      console.log(evt)
      let detailIndex = this.QuotationsData.quotationDetails.findIndex(x => x.id == evt[0].detailId)

      if (detailIndex == -1) {
        throw new Error('No se encontró este elemento')
      }

      let feesUpdated = this.QuotationsData.quotationDetails[detailIndex].fees.map((item, index) => {
        return {
          ...item,
          ...evt[index]
        }
      })

      this.QuotationsData.quotationDetails[detailIndex].fees = feesUpdated
      this.mapAndUpdateQuotation()
    },

    typeComponent() {

    },

    validateRatableWeight() {
      if (this.order.transportationCategoryId == 2 ||
          this.order.transportationCategoryId == 1 ||
          this.order.transportationCategoryId == 3 ||
          this.order.transportationCategoryId == 11 ||
          this.order.transportationCategoryId == 12 ||
          this.order.transportationCategoryId == 13)
        this.showRatableWeigth = true;
      else
        this.showRatableWeigth = false;
    },

    setQuantityPerContainer(index, containerId) {
      let indexCargo = this.order.cargo.findIndex(element => element.containerId == containerId);
      if (indexCargo != -1) {
        let quantity = this.order.cargo[indexCargo].quantity;
        this.prices[index].priceFees.forEach(element => {
          element.quantity = quantity;
        })

        return quantity;
      } else {
        let quantity = 1;
        return quantity
      }

    },
    deleteDoc(item, open){
      this.$store.dispatch("working", true)
      console.log(item)
      console.log(open)
      let type = null;
      console.log(type)
        this.$http.delete(`Orders/price/${item.orderPriceId}/Document/${item.id}`).then((response) => {
          console.log(response)
          this.$toast.info("Documento eliminado con exito!");
        }).catch((error) => {
          console.log(error)
          this.$toast.info("Algo salio mal, intetelo de nuevo mas tarde");
        }).finally(() => {
          console.log(this.fileStructure[0].children[0].children[0])
          this.fileStructure[0].children[0].children.forEach((precio, index1) => {
            console.log(precio)
            precio.children.forEach((element, index2) => {
              if (element.id === item.id) {
                this.fileStructure[0].children[0].children[index1].children.splice(index2, 1)
              }
              console.log(element.id)
            })
          })
          /*this.fileStructure[0].children[0].children[0].children[0].forEach((element, index)=> {
            if (item.id == element.id){
              this.fileStructure[0].children[0].children[0].children[0].splice(index,1)
            }
          })*/
          this.$store.dispatch("working", false)
        })


    },


    setCurrency(index) {
      this.prices[index].priceFees.forEach(element => {
        element.currencyId = this.currencyId;
      })
    },


    validateFields() {
      this.$nextTick(() => {
        this.$refs.myForm[0].validate();
        this.$refs.myFormfreeDays[0].validate();
        this.$refs.myFormVigency[0].validate();
      })
    },
    isLandTransportation(index) {
      return this.prices[index].transportationId === _GLOBALS.TRANSPORTATION.LAND
    },
    isMaritimeTransportation(index) {
      return this.prices[index].transportationId === _GLOBALS.TRANSPORTATION.MARITIME
    },
    setTabs(data) {
      if (data == 1) {
        this.tabs = 1;
      } else
        this.tabs = 0;
    },
    downloadAll(documents) {
      documents.forEach((pDocument) => {
        if (pDocument.children) {
          pDocument.children.forEach(element => {
            element.children.forEach(elementChildren => {
              this.downloadDocument(elementChildren)
            })
          })
        } else {
          this.downloadDocument(pDocument)
        }
      })
    },
    downloadDocument(doc) {
      // console.log("Documento",doc);
      if (doc.fileExtension) {
        if (doc.type === "order") {
          this.$store.dispatch('working', true);
          this.$http({
            url: `Orders/${doc.orderId}/Document/${doc.id}`,
            method: 'GET',
            responseType: 'blob', // important
          }).then(response => {
            fileDownload(response.data, `${doc.fileName}.${doc.fileExtension}`);
            // this.$toast.info('Pdf Generado');
            // var file = new Blob([response.data], {type: 'application/pdf'});
            // var fileURL = URL.createObjectURL(file);
            // window.open(fileURL);
          }).catch(error => {
            this.$toast.error("Algo salio mal, intente mas tarde")
            console.log(error);
          }).finally(() => {
            this.$store.dispatch('working', false);
          })
        }
        if (doc.type === "cargo") {
          this.$store.dispatch('working', true);
          this.$http({
            url: `Orders/${doc.orderId}/Cargo/${doc.cargoId}/Document/${doc.id}`,
            method: 'GET',
            responseType: 'blob', // important
          }).then(response => {
            fileDownload(response.data, `${doc.fileName}.${doc.fileExtension}`);
            // this.$toast.info('Pdf Generado');
            // var file = new Blob([response.data], {type: 'application/pdf'});
            // var fileURL = URL.createObjectURL(file);
            // window.open(fileURL);
          }).catch(error => {
            this.$toast.error("Algo salio mal, intente mas tarde")
            console.log(error);
          }).finally(() => {
            this.$store.dispatch('working', false);
          })
        }
        if (doc.type === "price") {
          this.$http({
            url: `Orders/Price/${doc.orderPriceId}/Document/${doc.id}`,
            method: 'GET',
            responseType: 'blob', // important
          }).then(response => {
            fileDownload(response.data, `${doc.fileName}.${doc.fileExtension}`);
            // this.$toast.info('Pdf Generado');
            // var file = new Blob([response.data], {type: 'application/pdf'});
            // var fileURL = URL.createObjectURL(file);
            // window.open(fileURL);
          }).catch(error => {
            this.$toast.error("Algo salio mal, intente mas tarde")
            console.log(error);
          }).finally(() => {
            this.$store.dispatch('working', false);
          })
        }
      }
    },
   async mapPricesData() {
      await this.getOrderData().then(({data}) => {
        this.order = data.data
        if (this.order.statusOrder === "Vendida") {
          this.$http.get(`Shipping/${this.order.shipping.shippingId}`).then((response) => {
            console.log(response)
            let respShipment = response.data.data;
            console.log('repShipment:')
            console.log(respShipment)
            // this.shipment.masterBl.consignee = respShipment.masterBl.consignee
            if (respShipment.masterbl.id != 0) {
              if (respShipment.transportationId === 1) {
                if (respShipment.masterbl.shippingLine === null) {
                  respShipment.masterbl.shippingLine = {
                    supplierId: respShipment.purchase.details[0].supplierId,
                  }
                }
                if (respShipment.masterbl.shipper.forwarderId === null) {
                  respShipment.masterbl.shipper.forwarderId = respShipment.purchase.details[0].freightForwarderId
                }
                if (respShipment.masterbl.shipper === null) {
                  respShipment.masterbl.shipper = null
                }
                if (respShipment.masterbl.consignee === null) {
                  respShipment.masterbl.consignee = null
                }

              }
            }
            if (respShipment.transportationId === 2) {
              if (respShipment.masterbl.shippingLine === null) {
                respShipment.masterbl.shippingLine = {
                  supplierId: respShipment.purchase.details[0].supplierId,
                }
              }
              if (respShipment.masterbl.shipper === null) {
                respShipment.masterbl.shipper = null
              } else if (respShipment.masterbl.shipper.forwarderId === null) {
                respShipment.masterbl.shipper.forwarderId = respShipment.purchase.details[0].freightForwarderId
              }

              if (respShipment.masterbl.consignee === null) {
                respShipment.masterbl.consignee = null
              }
            }
            if (respShipment.transportationId === 3) {
              if (respShipment.masterbl.shipper === null) {
                respShipment.masterbl.shipper = null
              }
              if (respShipment.masterbl.consignee === null) {
                respShipment.masterbl.consignee = null
              }
              // alert(respShipment.masterbl.houseBls.length == 0)
              if (respShipment.masterbl.houseBls == null) {
                respShipment.masterbl.houseBls = [{
                  refNumber: null,
                  shipper: {},
                  consignee: {},
                }]
              } else {
                if (respShipment.masterbl.houseBls.length == 0 ){
                  respShipment.masterbl.houseBls = [{
                    refNumber: null,
                    shipper: {},
                    consignee: {},
                  }]
                }
              }
            }

            console.log(respShipment)
            this.shipment.industry = respShipment.industry
            this.shipment.emails = respShipment.mails
            this.shipment.refNumber = Number(respShipment.referenceNumber)
            this.shipment.services = respShipment.services;
            this.shipment.client = respShipment.client;
            this.shipment.comments = respShipment.comments;
            this.shipment.transportationId = respShipment.transportationId;
            this.shipment.deliveryTypeId = respShipment.deliveryTypeId
            this.shipment.status = respShipment.status;
            this.shipment.orderType = respShipment.orderType;
            this.shipment.users = respShipment.users;
            // pito1
            this.shipment.clientSupplierName = respShipment.clientSupplierName;
            //pito 2
            this.shipment.contactName = respShipment.contactName;
            //pito 3
            this.shipment.referenceNumber = respShipment.referenceNumber
            this.shipment.transportationCategory = respShipment.transportationCategory;
            this.shipment.businessId = respShipment.businessId;
            this.shipment.additionalInfo = respShipment.additionalInfo;
            this.shipment.refCode = respShipment.refCode;
            this.shipment.transportationcategoryId = respShipment.transportationCategoryId;
            this.shipment.transportationCategoryId = respShipment.transportationCategoryId;
            this.shipment.orderTypeId = respShipment.orderTypeId;
            this.shipment.incotermId = respShipment.incotermId
            this.shipment.incoterm = respShipment.incoterm;
            this.shipment.sale = respShipment.sale;
            this.shipment.totalPurchase = respShipment.totalPurchase;
            this.shipment.quotationId = respShipment.quotationId;
            this.shipment.id = respShipment.id;
            this.shipment.totalPurchaseUsd = respShipment.totalPurchaseUsd
            this.shipment.totalSaleUsd = respShipment.totalSaleUsd
            this.shipment.supplierId = respShipment.purchase.details[0].supplierId;
            if (this.shipment.transportationId == 3){
              if (respShipment.incoterm === null){
                this.shipment.incoterm = null
              } else {
                this.shipment.incoterm = respShipment.incoterm.code;
              }
            }

            this.shipment.deliveryType = respShipment.deliveryType;
            // TODO por aqui validamos si es un embarcque unficado
            if (respShipment.masterbl.id == 0) {
              console.log('entro al 0')
              if (respShipment.transportationId === 1) {
                this.shipment.masterBl = { // <= note the parenthesis
                  shippingId: null,
                  supplierId: respShipment.purchase.details[0].supplierId,
                  shipName: null,
                  delaysInPort: null,
                  storageDays: null,
                  tripNumber: null,
                  shipper: null,
                  shippingLine: {
                    supplierId: respShipment.purchase.details[0].supplierId,
                  },
                  airline: {
                    supplierId: null,
                  },
                  additionalInfo: [{
                    flightNumber: null
                  }],
                  cargoQuantity: null,
                  cargoVolume: null,
                  cargoWeight: null,
                  consignee: null,
                  bookingNumber: null,
                  documents: [],
                  houseBls: [{
                    shipper: {
                      supplierId: respShipment.purchase.details[0].supplierId,
                      forwarderId: null,
                    },
                    consignee: respShipment.masterbl.consignee,
                    refNumber: respShipment.referenceNumber,
                    BlTypeId: null,
                    CustomAgentId: null,
                  }],
                  id: null,
                  status: false
                }
                if (respShipment.masterbl.shipper !== null){
                  this.shipment.masterBl.shipper = respShipment.masterbl.shipper
                }
              }
              if (respShipment.transportationId === 2) {
                this.shipment.masterBl = { // <= note the parenthesis
                  shippingId: null,
                  supplierId: respShipment.purchase.details[0].supplierId,
                  shipName: null,
                  tripNumber: null,
                  shipper: [{
                    forwarderId: respShipment.purchase.details[0].freightForwarderId,
                  }],
                  shippingLine: {
                    supplierId: null,
                  },
                  airline: {
                    supplierId: respShipment.purchase.details[0].supplierId,
                  },
                  additionalInfo: [{
                    flightNumber: null
                  }],
                  cargoQuantity: null,
                  cargoVolume: null,
                  cargoWeight: null,
                  consignee: [{
                    forwarderId: null,
                  }],
                  delaysInPort: null,
                  storageDays: null,
                  bookingNumber: null,
                  documents: [],
                  houseBls: [{
                    shipper: {
                      supplierId: respShipment.purchase.details[0].supplierId,
                      forwarderId: null,
                    },
                    consignee: respShipment.masterbl.consignee,
                    clientId: null,
                    supplierId: null,
                    refNumber: respShipment.referenceNumber,
                    BlTypeId: null,
                    CustomAgentId: null,
                  }],
                  id: null,
                  status: false
                }
              }
              if (respShipment.transportationId === 3) {
                console.log('entro al 3')
                this.shipment.masterBl = { // <= note the parenthesis
                  shippingId: null,
                  supplierId: respShipment.purchase.details[0].supplierId,
                  shipName: null,
                  delaysInPort: null,
                  storageDays: null,
                  refNumber: null,
                  tripNumber: null,
                  shipper: null,
                  shippingLine: {
                    supplierId: respShipment.purchase.details[0].supplierId,
                  },
                  airline: {
                    supplierId: null,
                  },
                  additionalInfo: [{
                    flightNumber: null
                  }],
                  cargoQuantity: null,
                  cargoVolume: null,
                  cargoWeight: null,
                  consignee: null,
                  bookingNumber: null,
                  documents: [],
                  houseBls: [{
                    shipper: null,
                    consignee: null,
                    refNumber: respShipment.referenceNumber,
                    BlTypeId: null,
                    CustomAgentId: null,
                  }],
                  id: null,
                  status: false
                }
                // aqui van cosas
                if (this.shipment.masterBl.shipper === null){
                  respShipment.masterbl.shipper = {
                    supplier:  respShipment.purchase.details[0].supplier,
                    supplierId: respShipment.purchase.details[0].supplier.id,
                  }
                }
                console.log(respShipment.masterbl.houseBls, 'resp')
                console.log(respShipment.masterbl.houseBls[0], 'resp')
                this.shipment.masterBl.houseBls[0].consignee = {
                  clientId: respShipment.clientId,
                  supplier: respShipment.client
                }
                this.shipment.masterBl.houseBls[0].consignee.supplier.companyName = this.shipment.masterBl.houseBls[0].consignee.supplier.name
                this.isTerrestre0 = true
                this.shipment.masterBl.houseBls[0].shipper = {
                  forwarderId: null,
                  clientBuyerId: null,
                  clientId: null,
                  supplierId: null,
                  clientSupplierId: null
                }
                console.log(this.shipment.masterBl.houseBls)
                this.shipment.masterBl.houseBls = this.shipment.masterBl.houseBls.map((element) => {
                  console.log('elmap')
                  return {
                    ...element,
                    mEmbarcador: null,
                    mSearch: null,
                    mItems: [],
                    mFirst: false,



                    cConsig: null,
                    cSearch: null,
                    cItems: [],
                    cFirst: false,
                  }
                })
                /*respShipment.masterBl.houseBls[0].consignee = {
                  clientId: respShipment.clientId,
                }*/
                /* respShipment.masterBl.houseBls = [
                   {
                     consignee: {
                       clientId: respShipment.clientId,
                       supplier: {
                         companyName: respShipment.client.contacts[0].name
                       }
                     }
                   }
                 ]*/
                /* respShipment.masterbl.houseBls[0].shipper = {
                   clientBuyerId: null,
                   clientSupplierId: null,
                   forwarderId: null,
                   supplierId: null,
                 }*/
              }
            } else {
              this.shipment.masterBl = respShipment.masterbl;
              if (respShipment.transportationId === 3) {
                this.shipment.masterBl.houseBls = this.shipment.masterBl.houseBls.map((element) => {
                  console.log('elmap')
                  return {
                    ...element,
                    mEmbarcador: null,
                    mSearch: null,
                    mItems: [],
                    mFirst: false,



                    cConsig: null,
                    cSearch: null,
                    cItems: [],
                    cFirst: false,
                  }
                })
              }
            }

            this.shipment.cargo = []
            this.shipment.cargo.push(arr)
            this.shipment.cargo.splice(-1)
            this.shipment.cargo = respShipment.cargo
            let arr = []
            this.shipment.cargo.push(arr)
            this.shipment.cargo.splice(-1)
            if (respShipment.masterbl.id != 0) {

              console.log(this.shipment)
              this.shipment.masterBl.consignee = respShipment.masterbl.consignee
              if (this.shipment.masterBl.shipper != null) {
                console.log("hola")
                if (respShipment.transportationId === 1 || respShipment.transportationId === 2) {

                  // this.shipment.masterBl.shipper.forwarderId = parseInt(this.shipment.masterBl.shipper.forwarderId);
                  console.log('este es el de operations',this.shipment.masterBl)
                  if (this.shipment.masterBl.consignee === null){
                    console.log('jajanul')
                  } else {
                    this.shipment.masterBl.consignee.forwarderId = parseInt(this.shipment.masterBl.consignee.forwarderId);
                  }
                }
              }
              this.shipment.cargo = []
              this.shipment.cargo.push(arr)
              this.shipment.cargo.splice(-1)
              this.shipment.cargo = respShipment.cargo
              let arr = []
              this.shipment.cargo.push(arr)
              this.shipment.cargo.splice(-1)
              if (this.shipment.cargo.length >= 0) {
                this.shipment.cargo.forEach((element, index) => {
                  if (element.containerNumber) {
                    let aux = element.containerNumber.split(';');
                    this.shipment.cargo[index].containerNumber = aux
                  } else {
                    this.shipment.cargo[index].containerNumber = null
                  }
                  if (element.containerSeals) {
                    let aux = element.containerSeals.split(';');
                    this.shipment.cargo[index].containerSeals = aux
                  } else {
                    this.shipment.cargo[index].containerSeals = null
                  }
                  if (element.truckNumber){
                    let aux = element.truckNumber.split(';');
                    this.shipment.cargo[index].truckNumber = aux;
                  } else {
                    this.shipment.cargo[index].truckNumber = null
                  }

                })
                console.log(this.shipment.cargo)
              }
              else {
                this.shipment.cargo = []
              }
            }
            this.shipment.shippingStatusId = respShipment.shippingStatusId;
            /*this.shipment.users = respShipment.users.map((user) => {
              return user.userId;
            });*/
            this.shipment.client = respShipment.client;

            if (respShipment.routes.length > 0) {
              this.shipment.routes = respShipment.routes;
            } else {
              this.shipment.routes = []
            }

           // this.getShipping();


            if (this.shipment.shippingStatusId > 1) {
              this.shippingStatus.shift();
            }
            if (respShipment.masterbl.houseBls === null){
              this.shipment.masterBl.houseBls = []
            } else {
              console.log('entro al map')
              if (!this.isTerrestre0) {
                this.shipment.masterBl.houseBls = respShipment.masterbl.houseBls
              }
              this.shipment.masterBl.houseBls = this.shipment.masterBl.houseBls.map((element) => {
                console.log('elmap')
                return {
                  ...element,
                  mEmbarcador: null,
                  mSearch: null,
                  mItems: [],
                  mFirst: false,



                  cConsig: null,
                  cSearch: null,
                  cItems: [],
                  cFirst: false,
                }
              })
            }
            console.log("Shipment", this.shipment);

          }).catch(error => {
                console.log(error)
              }).finally(() =>{
            this.$store.dispatch('working', false)
            this.tabs = 2
          })
        }
        /*if (this.order.statusOrder === "Vendida") {
          this.tabs = 2
        }*/
        /*if (this.order.statusOrder === "Vendida") {

        }*/
        this.fileStructure[0].children = [];
        this.order.documents = this.order.documents.map(item => ({
          ...item,
          type: "order",
          orderId: this.order.id,
        }))

        // Aqui
        if (this.order.cargo.length > 0) {

          let hasDangerous = this.order.cargo.some(cargo => {
            return cargo.documents.length > 0;
          })

          if (hasDangerous) {
            let cargoFolder = {
              fileName: "Cargo (Peligroso)",
              children: []
            };
            this.order.cargo.forEach((cargo, index) => {
              if (cargo.documents.length > 0) {
                let cargoDocs = cargo.documents.map(item => ({
                  ...item,
                  type: "cargo",
                  cargoId: cargo.id,
                  orderId: this.order.id,
                }))

                let folderCargo = {
                  fileName: index + 1,
                  children: cargoDocs
                }
                cargoFolder.children.push(folderCargo);
              }
            })
            this.fileStructure[0].children.push(cargoFolder)
          }
        }
        this.fileStructure[0].children = [...this.fileStructure[0].children, ...this.order.documents];
      }).finally(() => {
      //  alert('llegue al finaylly')
        this.getContainers().then(({data}) => {
          this.containers = data.data
          this.mapContainersFromOrder();
          this.getOrderPrices();
          this.validateRatableWeight()

        })
      })
    },
    getShipping() {
      this.$http.get(`Shipping/${this.shipment.id}`).then(response => {
        this.prices = response.data.data;

      }).catch(error => {
        console.log(error)
      })
    },
    async getOrderData() {
      return this.$http.get(`Orders/${this.idOrder}`, {
        headers: {
          'Cache-Control': 'max-age=3'
        }
      })
    },
    mapContainersFromOrder() {
      let containersArray = []
      this.order.cargo.forEach(c => {
        if (c.container) {
          containersArray.push(c.container)
        }
      })
      console.log(containersArray)
      this.priceContainers = containersArray
    },
    getContainers() {
      return this.$http.get('Containers')
    },
    openPrice(val) {
      console.log(val)
      console.log("Este Index", val);


      if (this.prices[val].dense === false) return;

      this.prices.forEach((elem) => {
        elem.dense = true;
      })
      this.prices[val].dense = false;


      this.searchSupplier = this.prices[val].supplierSearch
      this.mapPricesQuotation()
      this.prices[val].optionsMenuOpen = true;

      this.$nextTick(() => {

        this.prices[val].optionsMenuOpen = false;

        // console.log("Esto se ejecuta");

        let price = this.prices[val];

        // Has POL ?

        if (this.order.transportationId === 1) {

          let searchTerm = "";
          if (price.pricesOrigins[0].address === null) {
            searchTerm = this.order.origin[0].address;
          } else {
            searchTerm = price.pricesOrigins[0].address
          }

          this.$refs.autoOrigen[0].searchPlaces(searchTerm).then(() => {
            let originSelected = price.originItems[0];
            originSelected.selected = true;
            // console.log("Llego aqui");
            if (price.pricesOrigins[0].port) {
              let portSelected = originSelected.ports.findIndex(x => x.name == price.pricesOrigins[0].port.name)
              // console.log("portSelected",portSelected);
              if (portSelected !== -1) {
                originSelected.ports[portSelected].selected = true;
              } else {
                throw new Error("Port not found in results")
              }
            } else if (this.order.pol) {
              let portSelected = originSelected.ports.findIndex(x => x.name == this.order.pol.name)
              // console.log("portSelected",portSelected);
              if (portSelected !== -1) {
                originSelected.ports[portSelected].selected = true;
              } else {
                throw new Error("Port not found in results")
              }
            }
            // console.log("Origin Selected",originSelected);

            this.originSelected(originSelected, val)
          }).finally(() => {

            let searchTermDest = "";


            if (price.pricesDestination[0].address === null) {
              searchTermDest = this.order.destination[0].address;
            } else {
              searchTermDest = price.pricesDestination[0].address
            }


            this.$refs.autoDestino[0].searchPlaces(searchTermDest).then(() => {
              let destintionSelected = price.destinationItems[0];
              destintionSelected.selected = true;
              // console.log("Llego aqui");
              if (price.pricesDestination[0].port) {
                let portSelected = destintionSelected.ports.findIndex(x => x.name == price.pricesDestination[0].port.name)
                // console.log("portSelected",portSelected);
                if (portSelected !== -1) {
                  destintionSelected.ports[portSelected].selected = true;
                } else {
                  throw new Error("Port not found in results")
                }
              } else if (this.order.pod) {
                let portSelected = destintionSelected.ports.findIndex(x => x.name == this.order.pod.name)
                // console.log("portSelected",portSelected);
                if (portSelected !== -1) {
                  destintionSelected.ports[portSelected].selected = true;
                } else {
                  throw new Error("Port not found in results")
                }
              }
              // console.log("Origin Selected",destintionSelected);

              this.destinationSelected(destintionSelected, val)
            })
          })
        }
        if (this.order.transportationId === 2) {
          let searchTerm = "";
          if (price.pricesOrigins[0].address === null) {
            searchTerm = this.order.origin[0].address;
          } else {
            searchTerm = price.pricesOrigins[0].address
          }
          this.$refs.autoOrigenAereo[0].searchPlaces(searchTerm).then(() => {
            console.log('promesa')

            let originSelected = {}
            if(this.order.pol){
              let indexOrigin = null;
              price.originItems.forEach((element,index)=>{
                element.airports.forEach((item)=>{
                  if(item.name == this.order.pol.name){
                    indexOrigin = index;
                  }
                })
              })

              originSelected = price.originItems[indexOrigin];
            }

            else{
              originSelected = price.originItems[0];

            }


            originSelected.selected = true;
            // console.log("Llego aqui");
            if (price.pricesOrigins[0].airport) {



              let portSelected = originSelected.airports.findIndex(x => x.name == price.pricesOrigins[0].airport.name)
              // console.log("portSelected",portSelected);
              if (portSelected !== -1) {
                originSelected.airports[portSelected].selected = true;
              } else {
                throw new Error("Port not found in results")
              }
            } else if (this.order.pol) {
              let portSelected = originSelected.airports.findIndex(x => x.name == this.order.pol.name)
              // console.log("portSelected",portSelected);
              if (portSelected !== -1) {
                originSelected.airports[portSelected].selected = true;
              } else {
                throw new Error("Port not found in results")
              }
            }
            // console.log("Origin Selected",originSelected);

            this.originSelectedAereo(originSelected, val)
          }).finally(() => {

            let searchTermDest = "";

            console.log("Price", price);

            if (price.pricesDestination[0].address === null) {
              searchTermDest = this.order.destination[0].address;
            } else {
              searchTermDest = price.pricesDestination[0].address
            }

            console.log("Term", searchTermDest)

            this.$refs.autoDestinoAereo[0].searchPlaces(searchTermDest).then(() => {
              let indexDestination = null;


              let destintionSelected = {}

              if(this.order.pod){
                price.destinationItems.forEach((element,index)=>{
                  element.airports.forEach((item)=>{
                    if(item.name == this.order.pod.name){
                      indexDestination = index;
                    }
                  })
                })
                destintionSelected = price.destinationItems[indexDestination];
              }

              else{
                destintionSelected = price.destinationItems[0];

              }

              console.log("destinationItems",price.destinationItems)
              destintionSelected.selected = true;
              // console.log("Llego aqui");
              if (price.pricesDestination[0].airport) {
                let portSelected = destintionSelected.aiports.findIndex(x => x.name == price.pricesDestination[0].airport.name)
                // console.log("portSelected",portSelected);
                if (portSelected !== -1) {
                  destintionSelected.airports[portSelected].selected = true;
                } else {
                  throw new Error("Port not found in results")
                }
              } else if (this.order.pod) {
                let portSelected = destintionSelected.airports.findIndex(x => x.name == this.order.pod.name)
                // console.log("portSelected",portSelected);
                if (portSelected !== -1) {
                  destintionSelected.airports[portSelected].selected = true;
                } else {
                  throw new Error("AirPort not found in results")
                }
              }
              // console.log("Origin Selected",destintionSelected);

              this.destinationSelectedAereo(destintionSelected, val)
            })
          })
        }
        if (this.order.transportationId === 3) {
          let searchTerm = "";
          if (price.pricesOrigins[0].address === null) {
            searchTerm = this.order.origin[0].address;
          } else {
            searchTerm = price.pricesOrigins[0].address
          }
          this.$refs.autoOrigenTerrestre[0].searchPlaces(searchTerm).then(() => {
            let originSelected = price.originItems[0];
            originSelected.selected = true;
            // console.log("Origin Selected",originSelected);

            this.originSelectedTerrestre(originSelected, val)
          }).finally(() => {

            let searchTermDest = "";

            if (price.pricesDestination[0].address === null) {
              searchTermDest = this.order.destination[0].address;
            } else {
              searchTermDest = price.pricesDestination[0].address
            }

            this.$refs.autoDestinoTerrestre[0].searchPlaces(searchTermDest).then(() => {
              let destintionSelected = price.destinationItems[0];
              destintionSelected.selected = true;

              this.destinationSelectedTerrestre(destintionSelected, val)
            })
          })
        }


      })
    },
    closePrice(index) {
      this.prices[index].dense = true
    },
    setAndMapSupplier(supplier, index) {

      this.prices[index].supplierId = supplier.id
      this.prices[index].supplier = supplier;

      if (supplier.supplierTypeId == _GLOBALS.SUPPLIER_TYPES.AIR || supplier.supplierTypeId == _GLOBALS.SUPPLIER_TYPES.LAND || supplier.supplierTypeId == _GLOBALS.SUPPLIER_TYPES.MARITIME) {
        let transportation = this.getTransportationBySupplierType(supplier.supplierTypeId).id
        this.prices[index].transportationId = transportation
      } else {
        let service = this.getServiceBySupplierType(supplier.supplierTypeId)
        this.prices[index].serviceId = service
      }

    },
    getServiceBySupplierType(supplierTypeId) {
      if (supplierTypeId === 5)
        return 18
      if (supplierTypeId === 6)
        return 19
      if (supplierTypeId === 7)
        return 8
      if (supplierTypeId === 8)
        return 16
      if (supplierTypeId === 9)
        return 1
      if (supplierTypeId === 10)
        return 14
    },
    getTransportationBySupplierType(supplierTypeId) {
      if (supplierTypeId == _GLOBALS.SUPPLIER_TYPES.MARITIME)
        return this.TransportationTypes.find(x => x.id === _GLOBALS.TRANSPORTATION.MARITIME)
      if (supplierTypeId == _GLOBALS.SUPPLIER_TYPES.LAND)
        return this.TransportationTypes.find(x => x.id === _GLOBALS.TRANSPORTATION.LAND)
      if (supplierTypeId == _GLOBALS.SUPPLIER_TYPES.AIR)
        return this.TransportationTypes.find(x => x.id === _GLOBALS.TRANSPORTATION.AIR)
      else {
        console.error('Error getTransportationBySupplierType FormatFees2 ' + supplierTypeId)
        //allert('Error getTransportationBySupplierType FormatFees2')
      }
    },
    getSupplierTypeByTransportation(transportationId) {
      if (transportationId == _GLOBALS.TRANSPORTATION.MARITIME)
        return this.supplierTypes.find(x => x.id === _GLOBALS.SUPPLIER_TYPES.MARITIME)
      if (transportationId == _GLOBALS.TRANSPORTATION.LAND)
        return this.supplierTypes.find(x => x.id === _GLOBALS.SUPPLIER_TYPES.LAND)
      if (transportationId == _GLOBALS.TRANSPORTATION.AIR)
        return this.supplierTypes.find(x => x.id === _GLOBALS.SUPPLIER_TYPES.AIR)
      else {
        console.error('Error getSupplierTypeByTransportation FormatFees2 ' + transportationId)
        //allert('Error getSupplierTypeByTransportation FormatFees2')
      }
    },
    getSupplierIcon(type) {
      if (type == _GLOBALS.SUPPLIER_TYPES.AIR)
        return "mdi-airplane"
      if (type == _GLOBALS.SUPPLIER_TYPES.LAND)
        return "mdi-truck"
      if (type == _GLOBALS.SUPPLIER_TYPES.MARITIME)
        return "mdi-ferry"
      if (type == _GLOBALS.SUPPLIER_TYPES.FINANCING)
        return "mdi-currency-usd"
      if (type == _GLOBALS.SUPPLIER_TYPES.INSPECTION)
        return "mdi-format-list-checks"
      if (type == _GLOBALS.SUPPLIER_TYPES.MARKETING)
        return "mdi-store-24-hour"

      return "NA"

    },
    searchSuppliers: debouncePromise(function (index, val) {
      let supplierType = 0;
      if (val) {
        this.prices[index].supplierSearch = val
      }
      if (this.prices[index].transportationId) {
        supplierType = this.getSupplierTypeByTransportation(this.prices[index].transportationId).id;
      }
      let name = this.prices[index].supplierSearch

      if (this.prices[index].supplier) {
        if (this.prices[index].supplier.companyName === name)
          return null
      }

      if (name == "" && !supplierType) return null;

      this.prices[index].searchingSupplier = true

      this.getSupplierByName(supplierType, name).then(({data}) => {
        this.prices[index].supplierSearchResults = data.data
      }).catch((error) => {
        //allert('Error en app')
        console.error(error)
      }).finally(() => {
        this.$nextTick(() => {
          this.prices[index].searchingSupplier = false
        })
        console.log('Completed')
      })
    }, 1000),
    getSupplierByName: async function (supplierType, name) {
      let query = {}
      if (name)
        query.companyName = name

      return this.$http.get(`Suppliers/Search`, {params: query})
    },

    changeTransportationAndUpdate(index, item) {
      let supplierType = this.getSupplierTypeByTransportation(item.id)
      console.log("Sup Type", supplierType)
      this.prices[index].transportationId = item.id;
      this.prices[index].supplier.type = supplierType;
    },

    validateTotal() {
      let unique = [];
      this.prices.forEach((element, index) => {
        unique = [...new Set(this.prices[index].priceFees.map(item => item.currencyId))];

        if (unique.length > 1)
          return this.prices[index].showTotalPrice = false
        else
          return this.prices[index].showTotalPrice = true

      })


    },
    updatePriceButton(index) {
      this.validateTotal();
      this.prices[index].isUpdating = true;
      this.prices[index].isManual = true;
      let price = this.mapPrice(index);
      let validationResult = price.validate();

      if (validationResult === true) {
        this.updatePrice(price, index);
      } else {
        validationResult.forEach(error => {
          this.$toast.error(error);
        });
        this.prices[index].isUpdating = false;
      }
    },




    mapAndUpdatePrice: debouncePromise(function (index) {
      this.validateTotal();
      this.prices[index].isUpdating = true;
      let price = this.mapPrice(index);
      console.log(price)
      if (price.validate()) {
        this.updatePrice(price, index)
        ////allert(price.validate());
      } else {
        this.prices[index].isUpdating = false;
      }
    }, 5000),
    updatePrice: async function (price, index) {
      this.updatePrices([price]).then(response => {
        this.prices[index].priceDocuments = [];

        let ratableWeight = 0;
        if (this.order.transportationCategoryId == 9 || this.order.transportationId == 2)
          ratableWeight = parseFloat(this.order.cargo.reduce((a, b) => a + b.volumetricWeight, 0))


        if (response.data.code == 200 && response.data.data) {
          this.successfullPrice++;
          this.prices[index].id = response.data.data[0].id
          this.prices[index].priceFees = response.data.data[0].priceFees
          this.prices[index].priceCondition = response.data.data[0].priceCondition
          this.prices[index].priceDocumentsDrag = [];
          this.prices[index].ratableWeight = ratableWeight;

          this.prices[index].priceFees.map(element => {
            return {
              ...element,
              ratableWeightWarning: false
            }
          })

          this.prices[index].priceFees.forEach(element => {

            if (this.prices[index].ratableWeight != element.ratableWeight)
              element.ratableWeightWarning = true

            else
              element.ratableWeightWarning = false
          });


          let priceDocs = response.data.data[0].priceDocuments;

          let hasPriceDocs = this.fileStructure[0].children.findIndex(item => item.fileName === "Precios");

          if (hasPriceDocs != -1) {
            let priceDocFolder = this.fileStructure[0].children[hasPriceDocs].children.findIndex(item => item.priceId === response.data.data[0].id);
            if (priceDocFolder != -1) {
              this.fileStructure[0].children[hasPriceDocs].children[priceDocFolder].children = [];
              priceDocs.forEach(doc => {
                let priceDoc = {
                  ...doc,
                  type: "price"
                }
                this.fileStructure[0].children[hasPriceDocs].children[priceDocFolder].children.push(priceDoc);
              });
            } else {
              let newPriceDoc = {
                fileName: `Precio ${index + 1}`,
                children: [],
                priceId: response.data.data[0].id,
              };
              priceDocs.forEach(doc => {
                let priceDoc = {
                  ...doc,
                  type: "price"
                }

                newPriceDoc.children.push(priceDoc);
              });

              if (priceDocs.length > 0) {
                this.fileStructure[0].children[hasPriceDocs].children.push(newPriceDoc);
              }

            }
          } else {
            let folderPrices = {
              fileName: "Precios",
              children: [
                {
                  fileName: `Precio ${index + 1}`,
                  children: [],
                  priceId: response.data.data[0].id,
                }
              ]
            }

            priceDocs.forEach((doc) => {
              let priceDoc = {
                ...doc,
                type: "price"
              }

              folderPrices.children[0].children.push(priceDoc);
            });

            if (priceDocs.length > 0) {
              this.fileStructure[0].children.push(folderPrices);
            }
          }


          this.$emit('priceLength', this.prices.length);
        }else{
          this.$toast.error("Algo salio mal, intente mas tarde.")
        }

        let successPrices = this.prices.some(price => price.id === 0)

        this.$emit('successPrices', !successPrices)

      }).catch(error => {
        console.log("error", error)
        //allert('Error')

        if (error.code === 500 || this.prices[index].isManual) {
          this.$toast.error("Algo salio mal, intente mas tarde.")
        }
      }).finally(() => {
        this.prices[index].isUpdating = false;
        this.prices[index].isManual = false
      })
    },
    updatePrices(prices) {
      // console.log("update", prices);

      return this.$http.put(`Orders/${this.idOrder}/prices`, prices)
    },
    mapPrice(index) {
      return new Price(this.prices[index])
    },
    getValidPrices() {
      let prices = []
      this.prices.forEach((p, index) => {
        try {
          let price = new Price(p)

          if (price.validate()) {
            price.prototype.position = index;
            prices.push(price)
          }
        } catch (error) {
          console.error(error)
        }
      })

      return prices;
    },
    clickPrice(index) {
      // //allert(index)
      if (!this.prices[index].isSuggested) {
        this.addPriceQuotation(index)
      } else {
        this.removePriceQuotation(index)
      }
    },
    updateQuotation(quotation) {
      this.$http.put(`Quotation/${quotation.id}`, quotation)
          .then(response => {
            if (!response.data.code === 200) {
              // //allert(response.data)
            } else {
              this.$nextTick(() => {
                this.getQuotation().then((response) => {
                  this.Quotations = response
                });
              })
            }
          })
          .catch(error => {
            console.log(error)
            //allert(error)
          })
    },
    createQuotation(quotation) {
      this.$http.post(`Quotation`, quotation)
          .then(response => {
            if (!response.data.code === 200) {
              // //allert(response.data)
            } else {
              this.$nextTick(() => {
                this.getQuotation().then((response) => {
                  this.Quotations = response
                });
              })
            }
          })
          .catch(error => {
            console.log(error)
            //allert(error)
          })
    },
    removeQuotation(quotationId, priceId) {

      this.$http.delete(`Quotation/QuotationDetails/${priceId}/${quotationId}`)
          .then(response => {
            if (!response.data.code === 200) {
              // //allert(response.data)
            } else {
              this.$nextTick(() => {
                this.getQuotation().then((response) => {
                  this.Quotations = response
                });
              })
            }
          })
          .catch(error => {
            console.log(error)
            //allert(error)
          })
    },
    removePriceQuotation(index) {
      // //allert('Removing detail from quotation')
      console.log(this.prices[index])

      let idx = this.Quotations[this.activeQuotation].quotationDetails.findIndex(x => x.orderPriceId === this.prices[index].id)
      if (idx === -1) {
        this.$toast.error('Error no se pudo borrar')
        throw new Error('Error no se pudo borrar no encontrado')
      }

      this.removeQuotation(this.Quotations[this.activeQuotation].id, this.prices[index].id)
      this.prices[index].isSuggested = false

      this.$nextTick(() => {
        this.mapPricesQuotation()
      })
    },
    addPriceQuotation: function (index) {
      console.log(this.prices[index])

      if (this.Quotations.length == 0) {
        this.Quotations.push({
          id: 0,
          orderId: this.idOrder,
          profit: 0,
          suggested: true,
          discount: 0,
          profitPercentage: 0,
          discountPercentage: 0,
          quotationDetails: [],
        })
      }

      let idx = this.Quotations[this.activeQuotation].quotationDetails.findIndex(x => x.orderPriceId === this.prices[index].id)
      console.log(idx)
      if (idx == -1) {
        this.Quotations[this.activeQuotation].quotationDetails.push({
          quotationId: 0,
          orderPriceId: this.prices[index].id,
          isVisible: true,
          profit: 0,
          discount: 0,
          profitPercentage: 0,
          discountPercentage: 0,
          suggested: true,
          price: this.prices[index]
        })
      }

      let quotation = this.Quotations[this.activeQuotation];

      quotation.quotationDetails.forEach(q => {
        q.price = null;
      })

      console.log(this.Quotations[this.activeQuotation])

      if (quotation.id != 0) {
        let success = this.updateQuotation(quotation)
        if (success)
          this.prices[index].isSuggested = true;
      } else {
        let success = this.createQuotation(quotation)
        if (success)
          this.prices[index].isSuggested = true;
      }

      this.$nextTick(() => {
        this.mapPricesQuotation()
      })
    },
    getOrderPrices: function () {
      this.counterElement++;
      if (this.idOrder) {
        this.$http.get(`Orders/${this.idOrder}/prices`, {
        headers: {
            'Cache-Control': 'max-age=3'
          }
        }).then(response => {
          if (response.data.code == 200) {
            let res = response.data.data;
            // //allert(res.length)
            if (res.length > 0) {

              this.update = true;
              this.prices = res.map(item => {
                return this.mapPriceFromRequest(item)
              });
              // this.openPrice(0);
              // let hasDocuments = false;
              let pricesDocuments = [];

              let hasPriceDocs = this.fileStructure[0].children.findIndex(item => item.fileName === "Precios");

              if (hasPriceDocs != -1) {
                this.fileStructure[0].children.splice(hasPriceDocs, 1);
              }

              res.forEach((price, index) => {
                if (price.priceDocuments.length > 0) {
                  let priceFolder = {
                    fileName: `Precio ${index + 1}`,
                    children: [],
                    priceId: price.id
                  };

                  price.priceDocuments.forEach((doc) => {
                    let priceDoc = {
                      ...doc,
                      type: "price"
                    }
                    priceFolder.children.push(priceDoc);
                  })
                  pricesDocuments.push(priceFolder);
                }
              })

              if (pricesDocuments.length > 0) {
                let folderPrices = {
                  fileName: "Precios",
                  children: pricesDocuments
                }
                this.fileStructure[0].children.push(folderPrices);
              }
              this.$emit('priceLength', this.prices.length);
            }

            if (res.length == 0) {
              this.prices = []
              this.addElement();

              this.$emit('priceLength', 0);
            }

            let successPrices = this.prices.some(price => price.id === 0)

            this.$emit('successPrices', !successPrices)

            this.validateTotal();
            this.calculateTableTotal(0);
          }

          if (response.data.code == 400 || response.data.code == 500)
            this.$toast.error("Algo salio mal, intente mas tarde")
        }).catch(error => {
          console.error(error)


          if (error.response.status == 500) {
            console.log(error)

          }
        })
      }
    },
    mapPriceFromRequest(item) {
      // //allert('Mapping from request')
      let origins = item.pricesOrigins.map((x) => {
        if (!x.orderPriceId)
          x.orderPriceId = item.Id

        let port = null
        if (x.port) {
          port = {}
          port = {
            name: x.port.name,
            city: x.port.city.name,
            country: x.port.city.state.country.name,
            coordinates: x.port.coordinates,
          }
        }

        delete x.cityId;
        delete x.portId;

        return {
          detailId: item.id,
          ...x,
          city: x.city.name,
          state: x.city.state.name,
          country: x.city.state.country.iso2,
          coordinates: x.coordinates,
          port
        }
      });

      let destinations = item.pricesDestination.map((x) => {
        let port = null
        console.log(x.port)
        console.log(x.port ? true : false)
        if (x.port) {
          port = {}
          port = {
            name: x.port.name,
            city: x.port.city.name,
            country: x.port.city.state.country.name,
            coordinates: x.port.coordinates,
          }
        }

        delete x.cityId;
        delete x.portId;

        return {
          ...x,
          IdFee: this.counterElement,
          city: x.city.name,
          state: x.city.state.name,
          country: x.city.state.country.iso2,
          coordinates: x.coordinates,
          port: port
        }
      });

      if (!item.priceCondition) {
        item.priceCondition = {
          orderPriceId: item.id,
          demurrageCost: 0,
          loadingTime: 0,
          dischargeTime: 0
        }
      }

      if (item.validity) {
        item.validity = item.validity.substr(0, 10)
      }
      if (item.departureTime) {
        item.departureTime = item.departureTime.substr(0, 10)
      }

      // console.log(item)

      let ratableWeight = 0;
      if (this.order.transportationCategoryId == 9 || this.order.transportationId == 2)
        ratableWeight = parseFloat(this.order.cargo.reduce((a, b) => a + b.volumetricWeight, 0))

      let priceFees = item.priceFees.map(element => {
        return {
          ...element,
          ratableWeightWarning: false
        }
      })


      console.log("price fees", priceFees)


      let price = {
        ...item,
        isSuggested: false,
        containerId: Object.prototype.hasOwnProperty.call(item, 'containerId') ? item.containerId : this.priceContainers[0].id,
        name: 'Cargo',
        ratableWeight: ratableWeight,
        isUpdating: false,
        pricesOrigins: origins,
        pricesDestination: destinations,
        expansion: true,
        originItems: [],
        destinationItems: [],
        destinationSelections: {},
        originSelections: {},
        originSelections2: {},
        destinationAddress: "",
        originAddress: origins[0].address,
        supplierSearch: item.supplier.companyName,
        supplierSearchResults: [item.supplier],
        dense: true,
        optionsMenuOpen: false,
        searchingSupplier: false,
        priceDocuments: [],
        priceDocumentsDrag: [],
        priceFees: priceFees
      }

      price.priceFees.forEach(element => {
        if (price.ratableWeight != element.ratableWeight)
          element.ratableWeightWarning = true
        else
          element.ratableWeightWarning = false
      });

      console.log('price', price)
      return price;
    },
    mapPricesQuotation() {
      if (this.Quotations) {
        console.log("Map Prices Quotation", this.Quotations)
        this.Quotations.forEach(quotation => {
          let total = 0;
          quotation.quotationDetails.forEach(detail => {
            if (detail.suggested) {
              // //allert(detail.total)
              total += detail.price.total;
              // //allert(`Price ${detail.orderPriceId} is suggested ${detail.suggested}`)
              let priceIdx = this.prices.findIndex(x => x.id === detail.orderPriceId)

              if (priceIdx === -1)
                throw new Error('Couldn\'t get this price')

              this.prices[priceIdx].isSuggested = true;
            }
          })
          quotation.total = total;
        })

      }
    },

    UploadFile: function (file) {
      return new Promise((resolve, reject) => {

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error);
      });
    },

    ConvertFile: async function (index) {

      let documents = this.prices[index].priceDocumentsDrag;

      for (const doc of documents) {
        const result = await this.UploadFile(doc);
        const data = result.split(',')[1];
        const dotPosition = doc.name.lastIndexOf('.');
        const fileExt = doc.name.substr(dotPosition + 1);
        const fileName = dotPosition === -1 ? doc.name : doc.name.substr(0, dotPosition);
        this.prices[index].priceDocuments.push(
            {
              documentTypeId: 103,
              file: data,
              fileName: fileName,
              fileExtension: fileExt
            }
        )
      }
      if (documents.length > 0) {
        console.log("")
      }
    },

    add(index) {
      this.counter++;
      let ratableWeight = 0;
      if (this.order.transportationCategoryId == 9 || this.order.transportationId == 2) {
        ratableWeight = parseFloat(this.order.cargo.reduce((a, b) => a + b.volumetricWeight, 0))
        // console.log("Ratable Weight",ratableWeight);
      }

      // let index = this.prices.findIndex(e => e.id === id);


      let containerId = this.prices[index].containerId;

      let quantity = this.setQuantityPerContainer(index, containerId)


      this.prices[index].priceFees.push({
        id: 0,
        feeId: null,
        name: 'Cargo',
        quantity: quantity,
        currencyId: this.currencyId,
        orderPriceId: this.prices[index].id,
        price: 0,
        percentage: 0,
        ratableWeight: ratableWeight,
        ratableWeightWarning: false,
        ratableWeightDef: ratableWeight,
        total: 0,
        isVisible: true
      })
    },
    remove(index, feeIndex) {
      if (this.prices[index].isUpdating) {
        setTimeout(function () {
          this.removeFee(index, feeIndex)
        }, 1000)
      } else {
        this.removeFee(index, feeIndex)
      }
    },
    removeFee(index, feeIndex) {
      if (this.prices[index].priceFees[feeIndex].id == 0) {
        this.prices[index].priceFees.splice(feeIndex, 1);
      } else {
        let priceId = this.prices[index].id
        let feeId = this.prices[index].priceFees[feeIndex].id
        this.deletePriceFee(priceId, feeId).then(response => {
          if (response.data.code === 200) {
            this.prices[index].priceFees.splice(feeIndex, 1);
          } else {
            //allert('Error en proceso')
          }
        })
      }
      this.setTableTotal(index)
    },
    deletePriceFee(priceId, feeId) {
      return this.$http.delete(`/Orders/${this.idOrder}/prices/${priceId}/fees/${feeId}`)
    },
    setTableTotal(index) {
      this.prices[index].priceFees.forEach((element) => {
        if (!element.unitPrice || isNaN(element.unitPrice))
          element.unitPrice = 0

        if (!element.quantity || isNaN(element.quantity))
          element.quantity = 0

        element.unitPrice = Math.round((element.unitPrice + Number.EPSILON) * 100) / 100
        element.quantity = Math.round((element.quantity + Number.EPSILON) * 100) / 100

        if (this.showRatableWeigth) {
          if (!element.isTotalPrice) {
            element.total = Math.round((element.unitPrice * element.quantity + Number.EPSILON) * 100) / 100
          } else {
            element.quantity = 1
            element.total = Math.round((element.unitPrice + Number.EPSILON) * 100) / 100
          }
        } else {

          element.ratableWeight = parseFloat(element.ratableWeight)
          //element.ratableWeightDef = element.ratableWeight;
          if (!this.prices[index].isTotalPrice) {
            element.total = Math.round((element.unitPrice * element.ratableWeight + Number.EPSILON) * 100) / 100
          } else {
            element.quantity = 1
            element.total = Math.round((element.unitPrice + Number.EPSILON) * 100) / 100
          }
        }
      });

      this.prices[index].total = this.calculatePriceTotal(index);
    },

    setRatableWeightWarning(index, feeIndex) {
      var element = parseInt(this.prices[index].priceFees[feeIndex].ratableWeight)
      var elementDef = parseInt(this.prices[index].ratableWeight);

      if (element != elementDef) {
        this.prices[index].priceFees[feeIndex].ratableWeightWarning = true;
      } else {
        this.prices[index].priceFees[feeIndex].ratableWeightWarning = false;
      }
      this.calculateTableTotal(index);
    },

    calculateTableTotal(index) {
      console.log(this.prices[index])
      this.setTableTotal(index)
      this.$nextTick(() => {
      });
    },
    calculatePriceTotal(index) {
      let totalElement = 0;
      this.prices[index].priceFees.forEach((element) => {
        totalElement += parseFloat(element.total);
      });

      return Math.round((totalElement + Number.EPSILON) * 100) / 100;
    },
    addElement() {
      this.showFees = true;
      this.counterElement++;
      // this.prices.forEach(el => {
      //   el.dense = true;
      // })
      //Assigns the total value of volumetric weight from cargo data
      let ratableWeight = 0;
      if (this.order.transportationCategoryId == 9 || this.order.transportationId == 2) {
        ratableWeight = parseFloat(this.order.cargo.reduce((a, b) => a + b.volumetricWeight, 0))
        console.log(ratableWeight);
      }
      let quantity = 1;
      if (this.priceContainers.length > 0) {
        let indexCargo = this.order.cargo.findIndex(element => element.containerId == this.priceContainers[0].id);
        quantity = this.order.cargo[indexCargo].quantity;
      }

      this.prices.push(
          {
            id: 0,
            ratableWeight: this.ratableWeight,
            IdFee: this.counterElement,
            dense: true,
            name: "Cargo",
            supplierName: "",
            containerId: this.priceContainers.length > 0 ? this.priceContainers[0].id : null,
            transportationId: 0,
            total: null,
            freeDays: null,
            showTotalPrice: true,
            destinationStorage: null,
            originStorage: null,
            isUpdating: false,
            supplierId: null,
            supplier: null,
            priceCondition: {
              orderPriceId: 0,
              demurrageCost: 0,
              loadingTime: 0,
              dischargeTime: 0,
            },
            supplierTypeSelected: "",
            orderId: this.idOrder,
            validity: null,
            departureTime: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            DepartureDate: '2021-07-19',
            transportationTime: null,
            freeDaysDestination:null,
            supplierSearch: "",
            supplierSearchResults: [],
            optionsMenuOpen: false,
            searchingSupplier: false,
            suggested: false,
            confirmedPricing: true,
            confirmedSales: false,
            expansion: true,
            isTotalPrice: this.order.transportationCategoryId === 1,
            priceFees: [{
              id: 0,
              feeId: null,
              name: 'Cargo',
              quantity: quantity,
              currencyId: this.currencyId,
              unitPrice: 0,
              percentage: 0,
              ratableWeightWarning: false,
              profitPercentage: 0,
              discount: 0,
              total: 0,
              isVisible: true,
              ratableWeight: ratableWeight,
            }],
            pricesOrigins: [{...this.defOrigin}],
            pricesDestination: [{...this.defDestination}],
            priceDocuments: [],
            priceDocumentsDrag: [],
            originItems: [],
            destinationItems: [],
            destinationSelections: {},
            originSelections: {},
            originSelections2: {},
            destinationAddress: "",
            originAddress: "",
          })

      this.openPrice(this.prices.length - 1);
      let successPrices = this.prices.some(price => price.id === 0)

      this.$emit('successPrices', !successPrices)

    },

    //Autocomplit terrestre de venta Origen
    originSelectedTerrestre2(selections) {
      this.origin = selections;
      this.originAddress2 = selections.description
      this.orderCreation.origin[0].country = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
      this.orderCreation.origin[0].state = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
      this.orderCreation.origin[0].city = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' || a === "sublocality" || a === "administrative_area_level_3" || a === 'neighborhood') !== -1)[0]?.long_name
      this.origin.cordinates = String(this.origin.details.location.lat);
      this.origin.cordinates2 = String(this.origin.details.location.lng);
      this.orderCreation.origin[0].coordinates = this.origin.cordinates + "," + this.origin.cordinates2
      this.orderCreation.origin[0].address = this.origin.description;
    },

    updateOriginAddress2(text) {
      this.originAddress2 = text;
    },

    updateOriginItems2(items) {
      console.log(items);
      this.originItems2 = items;
    },


    //Autocomplit terrestre de venta destino
    destinationSelected2: function (selections) {
      this.destination = selections;
      this.destinationAddress = selections.description;
      this.$nextTick(() => {
        try {
          if (!Array.isArray(this.destination)) {
            this.orderCreation.destination[0].country = this.destination.details.address_components.filter(
                (value) => value.types.findIndex((a) => a === "country") !== -1
            )[0].short_name;
            this.destination.cordinates = String(this.destination.details.location.lat);
            this.destination.cordinates2 = String(this.destination.details.location.lng);
            this.orderCreation.destination[0].coordinates = this.destination.cordinates + "," + this.destination.cordinates2
            this.orderCreation.destination[0].state =
                this.destination.details.address_components.filter(
                    (value) =>
                        value.types.findIndex(
                            (a) => a === "administrative_area_level_1"
                        ) !== -1
                )[0]?.long_name ??
                this.destination.details.address_components.filter(
                    (value) => value.types.findIndex((a) => a === "country") !== -1
                )[0].long_name;
            this.orderCreation.destination[0].city = this.destination.details.address_components.filter(
                (value) =>
                    value.types.findIndex(
                        (a) =>
                            a === "locality" ||
                            a === "sublocality" ||
                            a === "administrative_area_level_3" ||
                            a === "neighborhood" ||
                            a === "administrative_area_level_2"
                    ) !== -1
            )[0]?.long_name;
            this.orderCreation.destination[0].address = this.destination.description;

            let index = this.destination.ports.findIndex((e) => e.selected);

            // this.Origin.address = this.originSelections.description;
            if (index !== -1) {
              this.orderCreation.pod.name = this.destination.ports[index].name;
              this.orderCreation.pod.city = this.destination.ports[index].city;
              this.destination.ports[index].coordinates[0] = parseFloat(
                  this.destination.ports[index].coordinates[0]
              );
              this.destination.ports[index].coordinates[1] = parseFloat(
                  this.destination.ports[index].coordinates[1]
              );
              this.orderCreation.pod.coordinates = `${this.destination.ports[index].coordinates[0]},${this.destination.ports[index].coordinates[1]}`;
              this.orderCreation.pod.country = this.destination.ports[
                  index
                  ].country;

              if (this.destination.type === "port") {
                this.destinationAddress = `${this.orderCreation.pod.name}, ${this.orderCreation.pod.city}, ${this.orderCreation.pod.country}`;
              }
            }

            // this.setPath()
          }
        } catch (e) {
          console.log(e);
        }
      });
    },

    updateDestinationItems2(items) {
      this.destinationItems2 = items;
    },

    updateDestinationAddress2(text) {
      this.destinationAddress2 = text;
    },








    updateOriginAddress(text, index) {
      this.prices[index].originAddress = text
    },
    updateDestinationItems(items, index) {
      this.prices[index].destinationItems = items
    },
    updateDestinationAddress(text, index) {
      this.prices[index].destinationAddress = text
    },
    originSelected(selectedItem, id) {
      this.prices[id].originAddress = selectedItem.description
      this.prices[id].originSelections = selectedItem;

      this.$nextTick(() => {
        try {
          this.Origin.address = this.prices[id].originSelections.description
          this.Origin.country = this.prices[id].originSelections.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
          this.Origin.state = this.prices[id].originSelections.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.prices[id].originSelections.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
          this.Origin.city = this.prices[id].originSelections.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' ||
              a === "sublocality" ||
              a === "administrative_area_level_3" ||
              a === 'neighborhood' ||
              a === "administrative_area_level_2") !== -1)[0]?.long_name
          this.Origin.coordinates = `${this.prices[id].originSelections.details.location.lat},${this.prices[id].originSelections.details.location.lng}`;
          let index = this.prices[id].originSelections.ports.findIndex(e => e.selected);


          if (index !== -1) {

            this.Origin.port = {}
            this.Origin.port.name = this.prices[id].originSelections.ports[index].name;
            this.Origin.port.city = this.prices[id].originSelections.ports[index].city;
            this.prices[id].originSelections.ports[index].coordinates[0] = parseFloat(this.prices[id].originSelections.ports[index].coordinates[0]);
            this.prices[id].originSelections.ports[index].coordinates[1] = parseFloat(this.prices[id].originSelections.ports[index].coordinates[1]);
            this.Origin.port.coordinates = `${this.prices[id].originSelections.ports[index].coordinates[0]},${this.prices[id].originSelections.ports[index].coordinates[1]}`;
            this.Origin.port.country = this.prices[id].originSelections.ports[index].country;
            this.validatePol = true

            if (this.prices[id].originSelections.type === 'port') {
              this.prices[id].originAddress = `${this.Origin.port.name}, ${this.Origin.port.city}, ${this.Origin.port.country}`
            }
          } else {
            this.validatePol = false
          }

          this.prices[id].pricesOrigins = [{...this.Origin}];
          this.Origin = {...this.defOrigin}
        } catch (e) {
          this.prices[id].originSelections = {};
          console.log(e)
        }
      })
    },
    destinationSelected: function (selections, id) {
      this.prices[id].destinationAddress = selections.description
      this.prices[id].destinationSelections = selections;

      this.$nextTick(() => {
        try {
          this.Destination.country = this.prices[id].destinationSelections.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
          this.Destination.state = this.prices[id].destinationSelections.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.prices[id].destinationSelections.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
          this.Destination.city = this.prices[id].destinationSelections.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' ||
              a === "sublocality" ||
              a === "administrative_area_level_3" ||
              a === 'neighborhood' ||
              a === "administrative_area_level_2") !== -1)[0]?.long_name
          this.Destination.coordinates = `${this.prices[id].destinationSelections.details.location.lat},${this.prices[id].destinationSelections.details.location.lng}`;
          this.Destination.address = this.prices[id].destinationSelections.description;
          let index = this.prices[id].destinationSelections.ports.findIndex(e => e.selected);


          if (index !== -1) {
            this.Destination.port = {}
            this.Destination.port.name = this.prices[id].destinationSelections.ports[index].name;
            this.Destination.port.city = this.prices[id].destinationSelections.ports[index].city;
            this.prices[id].destinationSelections.ports[index].coordinates[0] = parseFloat(this.prices[id].destinationSelections.ports[index].coordinates[0]);
            this.prices[id].destinationSelections.ports[index].coordinates[1] = parseFloat(this.prices[id].destinationSelections.ports[index].coordinates[1]);
            this.Destination.port.coordinates = `${this.prices[id].destinationSelections.ports[index].coordinates[0]},${this.prices[id].destinationSelections.ports[index].coordinates[1]}`;
            this.Destination.port.country = this.prices[id].destinationSelections.ports[index].country
            this.validatePod = true

            if (this.prices[id].destinationSelections.type === 'port') {
              this.prices[id].destinationAddress = `${this.Destination.port.name}, ${this.Destination.port.city}, ${this.Destination.port.country}`
            }


          } else {
            this.validatePod = false
          }

          this.prices[id].pricesDestination = [{...this.Destination}];
          this.Destination = {...this.defDestination}
        } catch (e) {
          this.prices[id].destinationSelections = {};
          console.log(e)
        }
      })
    },

    originSelectedAereo(selections, id) {
      this.prices[id].originAddress = selections.description
      this.prices[id].originSelections = selections;

      this.$nextTick(() => {
        try {
          this.Origin.address = this.prices[id].originSelections.description
          this.Origin.country = this.prices[id].originSelections.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
          this.Origin.state = this.prices[id].originSelections.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.prices[id].originSelections.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
          this.Origin.city = this.prices[id].originSelections.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' ||
              a === "sublocality" ||
              a === "administrative_area_level_3" ||
              a === 'neighborhood' ||
              a === "administrative_area_level_2") !== -1)[0]?.long_name
          this.Origin.coordinates = `${this.prices[id].originSelections.details.location.lat},${this.prices[id].originSelections.details.location.lng}`;
          let index = this.prices[id].originSelections.airports.findIndex(e => e.selected);


          if (index !== -1) {
            this.Origin.airports = {}
            this.Origin.airports.name = this.prices[id].originSelections.airports[index].name;
            this.Origin.airports.city = this.prices[id].originSelections.airports[index].city;
            this.Origin.airports.country = this.prices[id].originSelections.airports[index].country;
            this.Origin.airports.coordinates = `${this.prices[id].originSelections.airports[index].lat},${this.prices[id].originSelections.lon}`;
            this.validatePol = true;
            this.prices[id].pricesOrigins = [{...this.Origin}];
            this.Origin = {...this.defOrigin}

            if (this.prices[id].originSelections.type === 'airport') {
              this.prices[id].originAddress = `${this.Origin.airports.name}, ${this.Origin.airports.city}, ${this.Origin.airports.country}`
            }

          } else {
            this.validatePol = false
          }
        } catch (e) {
          console.log(e);
          this.prices[id].originSelections = {};
        }
      })
    },
    destinationSelectedAereo(selections, id) {
      this.prices[id].destinationAddress = selections.description
      this.prices[id].destinationSelections = selections;

      this.$nextTick(() => {
        try {
          this.Destination.address = this.prices[id].destinationSelections.description
          this.Destination.country = this.prices[id].destinationSelections.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
          this.Destination.state = this.prices[id].destinationSelections.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.prices[id].destinationSelections.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
          this.Destination.city = this.prices[id].destinationSelections.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' ||
              a === "sublocality" ||
              a === "administrative_area_level_3" ||
              a === 'neighborhood' ||
              a === "administrative_area_level_2") !== -1)[0]?.long_name
          this.Destination.coordinates = `${this.prices[id].destinationSelections.details.location.lat},${this.prices[id].destinationSelections.details.location.lng}`;
          let index = this.prices[id].destinationSelections.airports.findIndex(e => e.selected);


          if (index !== -1) {
            this.Destination.airports = {}
            this.Destination.airports.name = this.prices[id].destinationSelections.airports[index].name;
            this.Destination.airports.city = this.prices[id].destinationSelections.airports[index].city;
            this.Destination.airports.country = this.prices[id].destinationSelections.airports[index].country;
            this.Destination.airports.coordinates = `${this.prices[id].destinationSelections.airports[index].lat},${this.prices[id].originSelections.lon}`;
            this.validatePod = true;
            this.prices[id].pricesDestination = [{...this.Destination}];
            this.Destination = {...this.defDestination}

            if (this.prices[id].destinationSelections.type === 'airport') {
              this.prices[id].destinationAddress = `${this.Destination.airports.name}, ${this.Destination.airports.city}, ${this.Destination.airports.country}`
            }


          } else {
            this.validatePod = false;

          }
        } catch (e) {
          console.log(e);
          this.prices[id].destinationSelections = {};
        }
      })
    },


    originSelectedTerrestre(selections, id) {
      // console.log(id)
      this.prices[id].originAddress = selections.description
      this.prices[id].originSelections = selections;

      this.$nextTick(() => {
        try {
          this.Origin.address = this.prices[id].originSelections.description
          this.Origin.country = this.prices[id].originSelections.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
          this.Origin.state = this.prices[id].originSelections.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.prices[id].originSelections.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
          this.Origin.city = this.prices[id].originSelections.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' ||
              a === "sublocality" ||
              a === "administrative_area_level_3" ||
              a === 'neighborhood' ||
              a === "administrative_area_level_2") !== -1)[0]?.long_name
          this.Origin.coordinates = `${this.prices[id].originSelections.details.location.lat},${this.prices[id].originSelections.details.location.lng}`;
          this.validatePol = true;
          this.prices[id].pricesOrigins = [{...this.Origin}];
          this.Origin = {...this.defOrigin}
        } catch (e) {
          console.log(e);
          this.prices[id].originSelections = {};
        }

      })
    },
    destinationSelectedTerrestre(selections, id) {
      this.prices[id].destinationAddress = selections.description
      this.prices[id].destinationSelections = selections;

      this.$nextTick(() => {
        try {
          this.Destination.address = this.prices[id].destinationSelections.description
          this.Destination.country = this.prices[id].destinationSelections.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
          this.Destination.state = this.prices[id].destinationSelections.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.prices[id].destinationSelections.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
          this.Destination.city = this.prices[id].destinationSelections.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' ||
              a === "sublocality" ||
              a === "administrative_area_level_3" ||
              a === 'neighborhood' ||
              a === "administrative_area_level_2") !== -1)[0]?.long_name
          this.Destination.coordinates = `${this.prices[id].destinationSelections.details.location.lat},${this.prices[id].destinationSelections.details.location.lng}`;
          this.validatePod = true;
          this.prices[id].pricesDestination = [{...this.Destination}];
          this.Destination = {...this.defDestination}
        } catch (e) {
          console.log(e);
          this.prices[id].destinationSelections = {};
        }
      })
    },

    updateOriginItems(items, index) {
      this.prices[index].originItems = items;
    },



    /*    openCard: function (index) {
          this.$nextTick(() => {
            this.prices[index].dense = false
          })
        },*/

    getCurrencies() {
      this.$store.dispatch('working', true);
      this.$http.get('currencies').then(response => {
        if (response.data.code == 200) {
          this.Currencies = response.data.data;
        }
      })
    },

    getSuppliers: function () {
      this.$store.dispatch('working', true);
      this.$http.get('Suppliers', {
        headers: {
          'Cache-Control': 'max-age=30'
        }
      }).then(response => {
        if (response.data.code == 200) {
          console.log(response.data.data);
          this.Suppliers = response.data.data;
        }

        if (response.data.code == 400 || response.data.code == 500)
          this.$toast.error("Algo salio mal, intente mas tarde")
      }).catch(error => {

        console.log(error);
      }).finally(() => {
        this.$store.dispatch('working', false);
      })
    },
    findForwarder: debounce(function(name){
      console.log("=>(FormatFees2.vue:5048) name", name);
      if(!name) return this.forwarders = []
      this.getForwarders(name)
    }, 300),
    getForwarders: function (name) {
      this.loadingForwarders = true;
      this.$http.get(`Suppliers/forwarders?name=${name}`, {
        headers: {
          'Cache-Control': 'max-age=15'
        }
      }).then(response => {
        if (response.data.code == 200) {
          console.log(response.data.data);
          this.forwarders = response.data.data;
        }

        if (response.data.code == 400 || response.data.code == 500)
          this.$toast.error("Algo salio mal, intente mas tarde")
      }).catch(error => {

        console.log(error);
      }).finally(() => {
        this.$store.dispatch('working', false);
        this.loadingForwarders = false;
      })
    },
    getQuotation: async function () {
      try {
        let quotations = []
        if (this.idOrder) {
          let response = await this.$http.get(`Quotation/Order/${this.idOrder}`).then(result => result.data.data)
          for (let i = 0; i < response.length; i++) {
            if (response[i].suggested) {
              response[i] = await this.$http.get(`Quotation/${response[i].id}`).then(result => result.data.data)
            }
          }
          quotations = response.filter(x => x.suggested);
          return quotations
        }
      } catch (error) {
        throw new Error(error)
      }
    },

    removePrice(id) {
      this.DeletePriceDialog = true;
      this.DeleteId = id;
      console.log(id)
      if (this.deletePriceModal)
        console.log(this.deletePriceModal)
    },

    confirmDeletePrice(id) {
      let txt = prompt("¿Deseas eliminar un precio? Esta acción es irreversible");
      if (txt == "SI") {
        this.deletePrice(id)

      }
    },


    deletePrice() {
      let index = this.prices.findIndex(element => element.id == this.DeleteId)

      if (this.prices[index].id == 0) {
        this.prices.splice(index, 1);
        this.DeletePriceDialog = false;

      }

      if (this.prices[index].id != 0) {
        this.$store.dispatch('working', true);
        this.$http.delete(`Orders/${this.idOrder}/prices/${this.DeleteId}`).then(response => {
          if (response.data.code == 200) {
            this.$toast.info('Precio Eliminado');

            let hasPriceDocs = this.fileStructure[0].children.findIndex(item => item.fileName === "Precios");

            if (hasPriceDocs != -1) {
              this.fileStructure[0].children[hasPriceDocs].splice(index, 1);
            }

            this.removeElement(this.DeleteId)
            this.$emit('priceLength', this.prices.length)
          }

          if (response.data.code == 500)
            this.$toast.error("Algo salio mal, intente mas tarde")
          if (response.data.code == 400) {
            console.log(response)
            if (response.data.message.errors[0][0].message == "El precio no se puede borrar por que esta asociado a una cotizacion")
              this.$toast.info("El precio no se puede borrar por que esta asociado a una cotizacion")
          }

        }).catch(error => {

          console.log(error);
          this.$toast.error('Ocurrio un error intente mas tarde');
        }).finally(() => {
          this.$store.dispatch('working', false);
          this.DeletePriceDialog = false;
        })
      }

    },
    removeElement(id) {
      console.log('Dato element', id);
      let index = this.prices.findIndex(e => e.id === id);
      this.prices.splice(index, 1);
      console.log(this.prices);
    },
    includeComponents() {
      try {
        let nodes = document.querySelectorAll('.included');
        console.log(nodes)
        return Array.from(nodes).length > 0 ? Array.from(nodes) : []
      } catch (error) {
        console.error(error)
      }
    },

    updatePriceSavingStatus() {
    },
    createPrices(prices) {
      console.log(prices);
      this.$http.post(`Orders/${this.idOrder}/prices`, prices).then(response => {
        if (response.data.code == 400 || response.data.code == 500)
          this.$toast.error("Algo salio mal, intente mas tarde")
      }).catch(error => {
        console.log(error)
        this.$toast.error('Ocurrio un error intente mas tarde');
      }).finally((response) => {
        console.log(response)
      })
    }
  }
}
</script>

<style scoped>
.position-relative {
  position: relative;
}

>>> .v-input__control.v-text-field__details {
  display: none;
}

.supplier-autocomplete {
  margin: auto;
  /*max-width: 400px;*/
}

.supplier-autocomplete >>> .v-input__prepend-outer {
  margin: auto 0;
}

.supplier-autocomplete >>> .v-input__append-outer {
  margin: auto 0;
}

.supplier-autocomplete >>> .v-input__control {
  margin: auto 0;
}

.supplier-autocomplete >>> .v-input__control .v-input__slot {
  padding: 0 1em;
}

.fixed-col-size {
  min-width: 250px;
}

.pricesHeader {
  min-height: 70px;
}

.table-fee-row > td {
  min-width: 100px;
  max-width: 250px;
  padding: 0 !important;
}

.table-fee-row > td.table-actions-row {
  max-width: 30px;
  min-width: 30px;
}

.table-fee-input >>> .v-input__control .v-input__slot {
  padding: 0;
}

.table-fee-input {
  padding: 0;
  margin: 0
}

.text-red >>> .v-text-field__slot input {
  color: red
}

.text-black >>> .v-text-field__slot input {
  color: black
}


.fee-dropzone {
  min-height: 100px;
  padding: 0
}

.more-options-text >>> .v-input__control .v-input__slot {
  padding: 0
}
</style>
