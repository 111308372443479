<template>
  <Register></Register>
</template>

<script>
import Register from "@/components/Accounts_Admin/Register";
export default {
name: "IndexAdmin",
  components: {Register}
}
</script>

<style scoped>

</style>