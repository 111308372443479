<template>
  <div>
    <v-card elevation="0" tile class="pt-10">
      <v-row justify="center">
        <v-col md="12">
          <v-container tile elevation="0">
            <template>
              <v-row justify="center">
                <v-col md="12">
                  <v-container>
                    <v-data-table
                        :hide-default-footer="true"
                        :headers="headers"
                        :items="Data"
                        :search="search"
                        item-key="name"
                        :pagination="options"
                    >
                      <template v-slot:item.action = "{item}">
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn text @click="proceed(item.id)" v-bind="attrs" v-on="on">
                              <v-icon color="primary" left size="20">mdi-clock-check</v-icon>
                            </v-btn>
                          </template>
                          <span>Ver</span>
                        </v-tooltip>

                      </template>

                      <template v-slot:item.status="{item}">
                        <v-row>
                          <v-col class="pb-0">
                            <v-select
                                v-model="OrderStatusID"
                                outlined
                                dense
                                @change="showModal(item.id, OrderStatusID)"
                                class="pa-0 mt-3"
                                label="Enviado a pricing"
                                :items="OrderStatus"
                                item-text="name"
                                item-value="id"
                            >

                            </v-select>
                          </v-col>
                        </v-row>
                      </template>

                      <template v-slot:item.salesManager="{item}">
                        <div v-if="item.salesManager != null">
                          {{item.salesManager ? `${item.salesManager.firstName} ${item.salesManager.lastName}` : ''}}
                        </div>

                      </template>
                      <template v-slot:item.pricer="{item}">
                        {{item.pricer ? `${item.pricer.firstName} ${item.pricer.lastName}` : ''}}
                      </template>

                      <template v-slot:item.vigency="{item}">
                        {{item.vigency ? item.vigency : 'NA' }}
                      </template>

                      <template v-slot:item.price="{item}">
                        {{item.price ? item.price : 'NA' }}
                      </template>

                      <template v-slot:item.sale="{item}">
                        {{item.sale ? item.sale : 'NA' }}
                      </template>

                    </v-data-table>
                    <v-pagination
                        v-model="options.page"
                        :length="MetaData.totalPages"
                        @input="GetData()"
                        @next="GetData()"
                        @previous="GetData()"
                    ></v-pagination>
                  </v-container>

                </v-col>

              </v-row>
            </template>
          </v-container>



        </v-col>
      </v-row>

    </v-card>

    <v-dialog
        v-model="requestModal"
        width="1000"
    >
      <div>


        <v-toolbar
            dark
            class="primary white--text"
        >
          <h2 class="font-weight-regular"><v-icon left size="25">mdi-comment-text-outline</v-icon>Comentarios</h2>


          <v-spacer></v-spacer>
          <v-btn
              icon
              dark
              @click="requestModal = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>


        <v-card>
          <v-card-text>
            <br>
            <h2 class="font-weight-bold text-center">¿Porque se solicita a Proveedor?</h2>
            <br>
            <v-row>
              <v-col md="4">
                <v-select
                    v-model="Refuse.orderReasonId"
                    class="ml-6"
                    dense
                    outlined
                    :items="RefuseReasons"
                    item-value="Id"
                    item-text="Reasons"
                >
                </v-select>
              </v-col>
              <v-col md="10">
                <v-textarea
                    v-model="Refuse.comments"
                    outlined
                    class="ml-6 pa-0"
                    name="input-7-1"
                    label="Comentarios"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="2">
                <v-btn color="primary" class="ml-6" @click="SendRequested()">
                  Enviar Comentarios
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </v-dialog>

    <v-dialog
        v-model="ModalPending"
        width="1000"
    >
      <div>


        <v-toolbar
            dark
            class="primary white--text"
        >
          <h2 class="font-weight-regular"><v-icon left size="25">mdi-comment-text-outline</v-icon>Comentarios</h2>


          <v-spacer></v-spacer>
          <v-btn
              icon
              dark
              @click="CloseDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>


        <v-card>
          <v-card-text>
            <br>
            <h2 class="font-weight-bold text-center">¿Porque la necesidad de regresar la cotización?</h2>
            <br>
            <v-row>
              <v-col md="4">
                <v-select
                    v-model="Refuse.orderReasonId"
                    class="ml-6"
                    dense
                    outlined
                    :items="RefuseReasons"
                    item-value="Id"
                    item-text="Reasons"
                >
                </v-select>
              </v-col>
              <v-col md="10">
                <v-textarea
                    v-model="Refuse.comments"
                    outlined
                    class="ml-6 pa-0"
                    name="input-7-1"
                    label="Comentarios"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="2">
                <v-btn color="primary" class="ml-6" @click="SendPending()">
                  Enviar Comentarios
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </v-dialog>

  </div>
</template>

<script>

export default {
  name: "Send",
  props:{
    id:{
      type:Number
    }
  },
  data(){
    return{
      test:null,
      OrderStatusID:null,
      requestModal:null,
      options:{
        page: 1,
        itemsPerPage: 10,
        pageStart: 0,
        pageStop: 0,
        pageCount: 0,
        itemsLength: 0
      },
      ModalPending:null,
      QuantityStatus:0,
      e1:1,
      OrderStatus:[
        {
          id:1,
          name:'Pendiente a Pricing'
        },
        {
          id:2,
          name:'Solicitado a Proveedor'
        },
      ],
      headers: [],
      Data:[],
      RefuseReasons: [{Id: 1, Reasons: 'No hay disponibilidad', Status: true},
        {Id: 2, Reasons: 'No hay informacion', Status: true},
        {Id: 3, Reasons: 'No hay Ruta', Status: true}],
      Refuse:{
        orderId:null,
        comments:null,
        orderReasonId:1
      },
      renegotiation:{
        id:null,
      },
      testdialog:false,
      search:"",
      expanded: [],
      singleExpand: false,
    }
  },
  methods:{
    GetData:function (){
      this.$store.dispatch('working',true);

      this.$http.get('Orders/answered',
      {
        params:{
          PageSize:this.options.itemsPerPage,
          PageNumber:this.options.page,
        }
      }

      ).then(response => {
        if(response.data.code == 200){
          let res = response.data.data;
          this.Data = res.map(item => {
            return {
              ...item,
              departureDate: this.$luxon(item.departureDate, {output: "full"}),
              createdAt: this.$luxon(item.createdAt, {output: {
                format: "short",
                locale: "es-MX"
              }}),
            }
          })
          this.MetaData = response.data.meta
          console.log(this.Data);
          this.QuantityStatus = this.Data.length
          this.$emit('SendQuantity',this.QuantityStatus);
        }

        if (response.data.code == 400 || response.data.code == 500)
          this.$toast.error("Algo salio mal, intente mas tarde")
      }).catch(error => {

        console.log(error);
      }).finally(()=>{
        this.$store.dispatch('working',false);
      })
    },
    proceed:function (id){
      this.$router.push(`/ProceedQuote/${id}`)


    },
    showModal:function (id,Status) {
      this.id = id;
      if (Status == 1){
        this.ModalPending = true;
        this.Refuse.orderId = id;
      }

      if (Status == 2){
        this.SendRequested();
      }
    },
    SendRequested:function () {

      this.$store.dispatch('working', true)
      this.$http.put(`Orders/${this.id}/requested`).then(response => {
        if(response.data.code == 200){
          console.log(response.data.data);
          this.$toast.info("Enviado a Solicitado a Proveedor");
          this.requestModal = false;
          this.GetData();
        }

        if (response.data.code == 400 || response.data.code == 500)
          this.$toast.error("Algo salio mal, intente mas tarde")

      }).catch(error=>{

        this.$toast.error("Ocurrio un error");
        console.log(error)
      }).finally(()=>{
        this.$store.dispatch('working',false);
      });
    },
    SendPending:function () {

      this.renegotiation.id = this.id;
      this.$store.dispatch('working', true)
      this.$http.put(`Orders/renegotiation/${this.id}`,this.Refuse).then(response => {
        if(response.data.code == 200){
          console.log(response.data.data);
          this.$toast.info("Enviado a pricing");
          this.ModalPending = false;
          this.GetData();
        }

        if (response.data.code == 400 || response.data.code == 500)
          this.$toast.error("Algo salio mal, intente mas tarde")
      }).catch(error=>{

        this.$toast.error("Ocurrio un error");
        console.log(error)
      }).finally(()=>{
        this.$store.dispatch('working',false);
      });



    },
    UpdateItemData: function(data){
      console.log('Change data')
      this.Data.unshift(data);
      console.log(this.Data)
    }
  },
  '$store.state.filterPricingColumns': {
    handler: function (value) {
      this.headers = value
    },
    deep: true
  },
  mounted() {
    this.GetData();
    this.headers = this.$store.state.filterPricingColumns
    this.$emit('SendQuantity',this.QuantityStatus);
  },

}
</script>

<style scoped>

>>> .container {
  min-width: 100%;
}

</style>
