import axios from "axios"
//import { setup } from "axios-cache-adapter";

var httpApi = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
});

var httpAdminApi = axios.create({
    baseURL: process.env.VUE_APP_ADMIN
});

var httpPlacesApi = axios.create({
    baseURL: process.env.VUE_APP_PLACES_API
});

var httpGraphAPi = axios.create({
    baseURL: process.env.VUE_APP_GRAPHQL_HTTP,
});

var httpSignal = axios.create({
    baseURL: process.env.VUE_APP_API_SIGNAL,
});

httpGraphAPi.defaults.headers['content-type']='application/json'

httpGraphAPi.defaults.headers['accept']='*/*'

export {
    httpApi,
    httpPlacesApi,
    httpGraphAPi,
    httpSignal,
    httpAdminApi
}

