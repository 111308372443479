<template>
  <div>
    <div class="pt-4">
      <v-form
          ref="quotData"
          v-model="quotEnvio"
          lazy-validation
      >
        <slot name="quoteData"></slot>
      </v-form>
    </div>
    <div class="pt-5">
      <Card_Base>
        <template v-slot:title>
          <v-row>
            <v-col md="4" class="pa-0">
              <v-icon color="white" size="30">las la-box</v-icon>
              Envio
            </v-col>
          </v-row>
        </template>
        <template v-slot:text-card>
          <v-form
              ref="envio"
              v-model="validEnvio"
              lazy-validation
          >
            <v-row>
              <v-col md="12">
                <v-row justify="center">
                  <v-col md="6">
                    <v-autocomplete outlined label="Industria" dense v-model="orders.industryId"
                                    :rules="[v => !!v || 'Industria es requerido']" :items="industries"
                                    item-text="description"
                                    item-value="id">
                    </v-autocomplete>
                  </v-col>
                  <v-col md="6">
                    <v-text-field dense step="0.01" outlined type="Number" label="Target" v-model="orders.budget">
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col md="6">
                    <v-text-field dense label="Tipo de Comodity" v-model="orders.commodity"
                                  :rules="CommodityRules" outlined>
                    </v-text-field>
                    <v-btn-toggle
                        color="primary"
                        v-model="orders.LandTransportation"
                        outlined
                        mandatory
                    >
                      <v-btn value="T">
                        All truck
                      </v-btn>

                      <v-btn value="TR">
                        Rail + truck
                      </v-btn>

                      <v-btn value="R">
                        Rail
                      </v-btn>

                    </v-btn-toggle>
                  </v-col>
                  <v-col md="6">
                    <v-btn-toggle v-if="TypeService!=9" color="primary" v-model="orders.insideBillOfLanding"
                                  :value="orders.insideBillOfLanding ? 'true' : 'false' ">
                      <v-btn value=true>
                        Dentro de BL
                      </v-btn>
                      <v-btn value="false">
                        Fuera de BL
                      </v-btn>
                    </v-btn-toggle>
                    <br>

                    <v-checkbox v-if="TypeService!=11" color="primary" label="Dedicado" v-model="orders.landDedicated"
                                dense>
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </template>
      </Card_Base>
    </div>
    <div class="pt-5">
      <Card_Base>
        <template v-slot:title>
          <v-row>
            <v-col md="4" class="pa-0">
              <v-icon color="white" size="30">las la-th-list</v-icon>
              Detalles de Envio
            </v-col>
          </v-row>
        </template>
        <template v-slot:text-card>
          <v-form
              ref="carga"
              v-model="CargaEnvio"
              lazy-validation
          >
            <load_detail v-bind:control="TypeService"
                         v-on:loading="loadingData"
                         v-bind:loadDetail="DataService"
                         :edit-mode="Edit.edit"
                         ref="loadDetail"
                         v-bind:loadData="orders.cargo"></load_detail>
          </v-form>
        </template>
      </Card_Base>
    </div>
    <div class="pt-5">
      <Card_Base>
        <template v-slot:title>
          <v-row>
            <v-col md="4" class="pa-0">
              <v-icon color="white" size="30">las la-toolbox</v-icon>
              Servicios
            </v-col>
          </v-row>
        </template>
        <template v-slot:text-card>
          <Services
              :incoterm="Inconterm"
              ref="services"
              :type="Type"
              @serviceFields="getServiceFields"
              v-bind:Services_="Services"
              v-on:data="recibeData"
          ></Services>
        </template>
      </Card_Base>
    </div>
    <div class="pt-5">
      <Card_Base>
        <template v-slot:title>

          <v-row>
            <v-col md="4" class="pa-0">
              <v-icon color="white" size="30">las la-user</v-icon>
              Informacion del Cliente
            </v-col>
          </v-row>
        </template>
        <template v-slot:text-card>

          <v-form
              ref="cliente"
              v-model="ClientEnvio"
              lazy-validation
          >
            <v-row dense class="offset-2">


              <v-col md="6">
                <v-row style="text-align: center">
                  <v-col md="8">
                    <v-autocomplete label="Cliente" v-model="orders.clientId" dense outlined :items="companies"
                                    :search-input.sync="clientSearch"
                                    :loading="clientSearchLoading"
                                    item-value="id" item-text="name" :rules="ClientRules">

                    </v-autocomplete>
                    <v-btn color="error" outlined x-small @click="getCustomers">
                      Cargar Clientes
                    </v-btn>
                  </v-col>

                  <v-col md="2">
                    <v-dialog v-model="showAddCompany" width="1000">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon class="mt-1" v-on="on" v-bind="attrs">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="primary" icon fab size="34" v-bind="attrs"
                                      v-on="on">mdi-plus-circle
                              </v-icon>
                            </template>
                            <span>Agregar</span>
                          </v-tooltip>
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title class="primary white--text">
                          <v-icon color="white" left>mdi-table-edit</v-icon>
                          Añadir Cliente
                        </v-card-title>
                        <Customers v-on:update="getCustomers()"/>
                      </v-card>
                    </v-dialog>
                  </v-col>

                  <v-col md="6">
                    <label>Frecuencia Mensual</label>
                    <br><br>
                    <v-slider v-model="orders.Frequency" :thumb-size="18" thumb-label="always" min="1"></v-slider>
                  </v-col>
                </v-row>


              </v-col>

              <v-col md="6">
                <v-row>
                  <v-col md="8">
                    <v-autocomplete v-if="!orders.assignToPricer" disabled label="Pricer" dense outlined
                                    v-model="orders.pricerId" :items="Employees"
                                    :item-text="item => item.firstName +' '+ item.lastName" item-value="userId">
                    </v-autocomplete>

                    <v-autocomplete v-else label="Pricer" dense outlined v-model="orders.pricerId" :items="Employees"
                                    :item-text="item => item.firstName +' '+ item.lastName" item-value="userId"
                                    :rules="PricerRules">
                    </v-autocomplete>
                  </v-col>

                  <v-col md="4">
                    <v-label>Cotizar con pricer</v-label>
                    <v-switch v-model="orders.assignToPricer" dense color="primary" inset></v-switch>
                  </v-col>



                </v-row>
                <v-row>
                  <v-col>
                    <v-label>¿Urgente?</v-label>
                    <v-switch v-model="orders.urgent" dense color="primary" inset></v-switch>
                  </v-col>
                  <v-col>
                  </v-col>
                </v-row>
              </v-col>


            </v-row>
          </v-form>
        </template>
      </Card_Base>
    </div>

    <div class="pt-5">
      <Card_Base>
        <template v-slot:title>
          <v-row>

            <v-col md="4" class="pa-0">
              <v-icon color="white" size="30">las la-comments</v-icon>
              Comentarios Adicionales
            </v-col>
          </v-row>
        </template>

        <template v-slot:text-card>
          <v-row justify="center">
            <template>
              <v-btn
                  v-show="!hidden"
                  color="primary"
                  fab
                  dark
                  small
                  absolute
                  bottom
                  right
              >
                <v-file-input
                    counter
                    hide-input
                    multiple
                    show-size
                    truncate-length="15"
                    class="mb-4 ml-2"
                ></v-file-input>
              </v-btn>
            </template>

            <v-col md="10">
              <br>
              <v-textarea counter="256" @keydown="txtMaxLenght" @keyup="txtMaxLenght" outlined label="comentarios"
                          v-model="orders.comments"></v-textarea>
            </v-col>
            <v-col md="10">
              <file-drag v-model="orders.documentsDrag" @change="ConvertFile()"></file-drag>
            </v-col>

            <v-col
                class="d-flex"
                cols="3"
                sm="6"
            >
              <v-select
                  :items="business"
                  label="Empresa"
                  v-model="orders.businessId"
                  item-text="name"
                  item-value="id"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-end">
            <v-col cols="3" class="pb-0">
              <v-menu
                  ref="menu"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="date"
                      label="Vencimiento de cotizacion"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" no-title scrollable color="primary">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu2 = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(date)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </template>
      </Card_Base>
    </div>

    <v-row class="pt-3" style="text-align-last: center">
      <v-col>
        <v-btn x-large color="primary" dark @click="NewOrder">
          <slot name="btn-quote">
            ¡Solicita tu Cotizacion!
          </slot>
        </v-btn>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import Card_Base from "@/components/Quote/QuotDetail/Card_Base";
import load_detail from "@/components/Quote/QuotDetail/load_detail";
import Services from "@/components/Quote/QuotDetail/Services";
import Customers from "@/components/Accounts_Admin/Customers";
import FileDrag from "@/components/Global/FileDrag/FileDrag";
import debouncePromise from "debounce-promise";

export default {
  name: "Maritimo",
  components: {
    FileDrag,
    Customers,
    Services,
    load_detail,
    Card_Base,

  },
  props: {
    Edit: {
      type: Object,
      default: function () {
        return {
          edit: false,
          idOrder: 0,
          business:[],
        }
      }
    },
    Typetransportation: Number,
    businessId:Number,
    TypeService: Number,
    Inconterm: Number,
    Type: Number,
    Origin: {},
    DataOrder: {},
    rate:{},
    DataService: {},
    OrderServices: {},
    Destination: {},
    LoadData: [],
    OriginPort: {},
    DestinationPort: {},
    Services: {},
    filterRoute:Number,
    dueDate: String,
    AutocompleteOrigin: Boolean,
    AutocompleteDestination: Boolean

  },
  watch: {
    clientSearch (val){
      console.log(val)
      const selectedClient = this.companies.find(x => x.name == val)
      if(selectedClient?.name === val) return
      if(val) this.searchClients(this.clientSearch)
    },
    TypeService(val) {
      if (val == 9)
        this.orders.insideBillOfLanding = null;
      else
        this.orders.insideBillOfLanding = "true";
    },
    OrderServices(val) {
      this.orders.services = val
    },
    originAddressSearch() {
      this.googleSearch();
    },
    Servicedef: function (val) {
      if (val == 'NA') {
        this.$toast.error("Es necesario elegir un servicio porfavor contacte un asesor")

      }
    },


    DataService: function () {
     // this.defaultCargo();
    },

  },
  data() {
    return {
      date: null,
      menu2: false,
      business:[],
      filess: [],
      hidden: true,
      show: 1,
      Suppliers:[],
      clientSearch: "",
      showAddCompany: false,
      testData: null,
      ClientEnvio: true,
      CargaEnvio: true,
      quoteWithoutPricer: false,
      validEnvio: true,
      quotEnvio: true,
      valid: true,
      industries: [],
      NamePricer: "",
      nameRules: [
        v => !!v || 'Nombre es requerido',
      ],
      ClientRules: [
        v => !!v || 'Cliente es requerido',
      ],
      PricerRules: [
        v => !!v || 'Pricer es requerido',
      ],
      BudgetRules: [
        v => !!v || 'Budget es requerido',
      ],
      CommodityRules: [
        v => !!v || 'Commodity es requerido',
      ],

      transporteMap: "",
      transport: "",
      transportation: "Maritimo",
      serviceMap: "",
      IncotermMap: "",
      tipoEnvio: "",
      Employees: [],
      companies: [],
      clientSearchLoading: false,
      Servicios: [],
      orderCreated:{},
      resulImage: "",
      image00000: 1,
      image00001: 0,
      image00011: 0,
      image00101: 0,
      tab: null,
      Pickup: 0,
      Delivery: 0,
      PortFees: 0,
      Customclerance: 0,
      Logistic: 0,
      Insurance: 0,
      switch1: "",
      toggle_exclusive: undefined,
      ex11: false,
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      filterOptions: false,
      origin: {},
      destination: {},
      markers: [],
      originAddress: {},
      TypeRoute: ['Importacion', 'Exportacion'],
      RangePrices: ['1- 100 USD', '100 - 300 USD', '300 - 1000', '> 1000'],
      commodities: ['Agricultura', 'Alimentación', 'Comercio', 'Construcción', 'Educación'],
      price: 0,
      commodity: '',
      portsOfLoading: [],
      Incoterms: [],
      Pol: {
        name: null,
        city: null,
        coordinates: null,
        country: null
      },
      Pod: {
        name: null,
        city: null,
        coordinates: null,
        country: null
      },
      detailorder: null,
      LoadingTypes: [],
      address: '',
      address2: '',
      Destiny: null,

      menu: false,
      Incoterm: "",
      transportedef: "",

      orders: {
        businessId:1,
        transportationId: null,
        transportationCategoryId: null,
        assignToPricer: true,
        LandTransportation: null,
        landDedicated: false,
        LandTransference: false,
        incotermId: null,
        orderTypeId: null,
        origin: [{
          address: null,
          city: null,
          state: null,
          country: null,
          coordinates: null
        }],
        destination: [{
          address: null,
          state: null,
          city: null,
          country: null,
          coordinates: null
        }],
        pol: null,
        pod: null,
        departureDate: null,
        industryId: null,
        budget: null,
        commodity: null,
        insideBillOfLanding: "true",
        cargo: [],
        Services: [],
        clientId: null,
        pricerId: null,
        Frequency: null,
        urgent: false,
        comments: null,
        documents: [],
        DeliveryTypeId:null,
        documentsDrag: []
      },
      TypeRoutedef: "",
      transporte: ['Aereo', 'Maritimo', 'Terrestre'],
      Servicedef: "",
      Service: ['LCL', 'FCL', 'Bulk', 'NA'],
      items: [
        {title: 'Click Me'},
        {title: 'Click Me'},
        {title: 'Click Me'},
        {title: 'Click Me 2'},
      ],

      expand: false,
      slider: 45,
      rules: {
        txtMaxLenght: value => (value || '').length <= 200 || 'Description must be 200 characters or less'
      },
    }
  },
  methods: {
    searchClients: debouncePromise(function (search){
      if (search === '') {
        return
      }
      this.getClients(search)
    }, 300),
    getClients(search) {
      this.clientSearchLoading = true
      this.companies = []
      this.$http.get(`Companies/find`,{
            params: {
              name: search,
            }
          }
      ).then((response) => {
        console.log(response)
        this.clientSearchLoading = false
        let aux = response.data.data
        if (aux === null){
          console.log(aux)
        } else {
          aux.forEach((element) =>{
            this.companies.push(element)
          })
        }
      })
    },
    getServiceFields(data){
      this.orders.goodsCost = data.GoodsCost;
      this.orders.goodCost=parseInt(this.orders.goodCost)
    },

    getSuppliers: function () {
      this.$store.dispatch('working', true);
      this.$http.get('Suppliers', {
        headers: {
          'Cache-Control': 'max-age=30'
        }
      }).then(response => {
        if (response.data.code == 200) {
          console.log(response.data.data);
          this.Suppliers = response.data.data;
        }

        if (response.data.code == 400 || response.data.code == 500)
          this.$toast.error("Algo salio mal, intente mas tarde")
      }).catch(error => {

        console.log(error);
      }).finally(() => {
        this.$store.dispatch('working', false);
      })
    },

    getBuissnes() {
      this.$store.dispatch("working", true);
      this.$http("Business").then((response) => {
        this.business = response.data.data;
        console.log(this.business)
      }).catch((error) => {
        console.log(error);
        this.$toast.error("Algo salio mal, contacte al administrador!");
      }).finally(()=>{
        this.orders.businessId = this.DataOrder.businessId
        this.$store.dispatch("working", false);
      })

    },






    /**
     * When the location found
     * @param {Object} addressData Data of the found location
     * @param {Object} placeResultData PlaceResult object
     * @param {String} id Input container ID
     */

    txtMaxLenght: function (e) {
      this.txtAreaLenght = this.orders.comments.length
      this.txtAreaLenght >= this.txtAreaMax ? this.orders.comments = this.orders.comments.slice(0, this.txtAreaMax) : ''
      if (this.txtAreaLenght === this.txtAreaMax) {
        if (e.keyCode !== 8 && e.keyCode !== 67 && e.keyCode !== 37 && e.keyCode !== 38 && e.keyCode !== 39 && e.keyCode !== 40) {
          if (e.keyCode !== 17) {
            e.preventDefault()
          } else {
            if (e.keyCode === 67) {
              this.orders.comments = document.execCommand('copy')
            }
          }
          e.preventDefault()
        }
      }
    },
    createRatePrice(){

      let pol = null;
      let pod = null;

      if (Object.keys(this.OriginPort).length > 0) {
        console.log(this.OriginPort)
        pol = {}
        pol.country = this.OriginPort.country
        pol.city = this.OriginPort.city
        pol.name = this.OriginPort.name
        pol.coordinates = `${this.OriginPort.lon},${this.OriginPort.lat}`
      }

      if (Object.keys(this.DestinationPort).length > 0) {
        console.log(this.DestinationPort)
        pod = {}
        pod.country = this.DestinationPort.country
        pod.city = this.DestinationPort.city
        pod.name = this.DestinationPort.name
        pod.coordinates = `${this.DestinationPort.lon},${this.DestinationPort.lat}`

      }


      let priceOrigins = null;
      let priceDestination = null;


      priceOrigins = [{
        Address: this.Origin.address,
        City: this.Origin.city,
        state: this.Origin.state,
        Country: this.Origin.country,
        coordinates: `${this.Origin.coordinates.lat},${this.Origin.coordinates.lng}`,
        port:pol
      }]

      priceDestination = [{
        Address: this.Destination.address,
        City: this.Destination.city,
        state: this.orders.destination[0].state,
        Country: this.Destination.country,
        coordinates: `${this.Destination.coordinates.lat},${this.Destination.coordinates.lng}`,
        port:pod
      }]


      let supplier = this.Suppliers.find(element=>element.companyName == this.rate[0].maritime.supplier.name );
      let prices = [];
       prices.push(  {
        id: 0,
        dense: true,
        name: "StandardFCLRate",
        supplierName: this.rate[0].maritime.supplier.name,
        containerId: null,
        businessId: 1,
        transportationId: 1,
        total: 0,
        freeDays: null,
        showTotalPrice: true,
        destinationStorage: null,
        originStorage: null,
        isUpdating: false,
        supplierId: supplier.id,
        priceConditions: {
          orderPriceId: 0,
          demurrageCost: 0,
          loadingTime: 0,
          dischargeTime: 0,
        },
        supplierTypeSelected: "",
        orderId: this.orderCreated.id,
        validity: '2021-12-30',
        departureTime: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        DepartureDate: '2021-07-19',
        transportationTime: 5,
        supplierSearch: "",
        supplierSearchResults: [],
        optionsMenuOpen: false,
        searchingSupplier: false,
        suggested: false,
        confirmedPricing: true,
        confirmedSales: false,
        expansion: true,
        isTotalPrice: this.orderCreated.transportationCategoryId === 1,
        priceFees: [
          {
            id: 0,
            feeId: null,
            name: '20 Dry Freight Container',
            quantity: this.orderCreated.cargo[0].quantity,
            currencyId: 1,
            price: this.rate[0].maritime.container[0].price,
            percentage: 0,
            profitPercentage: 0,
            discount: 0,
            total: 0,
            isVisible: true,
          },

          {
            id: 0,
            feeId: null,
            name: '40 High Cube Container',
            quantity: this.orderCreated.cargo[0].quantity,
            currencyId: 1,
            price: this.rate[0].maritime.container[0].price,
            percentage: 0,
            profitPercentage: 0,
            discount: 0,
            total: 0,
            isVisible: true,
          },
          {
            id: 0,
            feeId: null,
            name: this.rate[0].maritime.vesselFees[0].description,
            quantity: 1,
            currencyId: 1,
            price: this.rate[0].maritime.vesselFees[0].amount,
            percentage: 0,
            profitPercentage: 0,
            discount: 0,
            total: 0,
            isVisible: true,
          },

          {
            id: 0,
            feeId: null,
            name: this.rate[0].maritime.vesselFees[1].description,
            quantity: 1,
            currencyId: 1,
            price: this.rate[0].maritime.vesselFees[1].amount,
            percentage: 0,
            profitPercentage: 0,
            discount: 0,
            total: 0,
            isVisible: true,
          }
        ],
        pricesOrigins: priceOrigins,
        pricesDestination: priceDestination,
        priceDocuments: [],
        priceDocumentsDrag: [],
        originItems: [],
        destinationItems: [],
        destinationSelections: {},
        originSelections: {},
        destinationAddress: "",
        originAddress: "",
      })


          this.$http.put(`Orders/${this.orderCreated.id}/prices`,prices).then(response => {
          this.$store.dispatch('working',true);
          console.log(response.data.data);
          this.$toast.info('Precio creado')
          }).catch( error => {
            this.$toast.error('Error al generar precio')
            console.log(error)
          }).finally(() =>{
            this.$store.dispatch('working',false);
          })


    },


    UploadFile: function (file) {
      return new Promise((resolve, reject) => {

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error);
      });
    },

    ConvertFile: async function () {

      let documents = this.orders.documentsDrag;

      for (const doc of documents) {
        const result = await this.UploadFile(doc);
        const data = result.split(',')[1];
        const dotPosition = doc.name.lastIndexOf('.');
        const fileExt = doc.name.substr(dotPosition + 1);
        const fileName = dotPosition === -1 ? doc.name : doc.name.substr(0, dotPosition);
        this.orders.documents.push(
            {
              documentTypeId: 103,
              file: data,
              fileName: fileName,
              fileExtension: fileExt
            }
        )
      }
      console.log(this.orders)
    },


    recibeData: function (servicesData) {
      console.log("servicio recibido", servicesData);
      this.orders.Services = servicesData;
      if (this.orders.services?.length > 0) {
        this.orders.Services.forEach(item => {
          let service = this.orders.services.find(el => item.ServiceId === el.serviceId);
          if (service) {
            item.id = service.id;
          }
        })
      }
    },

    SetDefaultOrder() {
      this.Origin = {};
      this.Destination = {};
      this.orders = {
        transportationId: null,
        businessId:1,
        transportationCategoryId: null,
        LandTransportation: null,
        assignToPricer: true,
        landDedicated: false,
        LandTransference: false,
        incotermId: null,
        orderTypeId: null,
        origin: [{
          Address: null,
          City: null,
          state: null,
          Country: null,
          coordinates: null
        }],
        destination: [{
          Address: null,
          state: null,
          City: null,
          Country: null,
          coordinates: null
        }],
        pol: null,
        pod: null,
        departureDate: null,
        industryId: null,
        budget: null,
        commodity: null,
        insideBillOfLanding: "true",
        cargo: [],
        Services: [],
        clientId: null,
        pricerId: null,
        Frequency: null,
        urgent: false,
        comments: null
      }
    },

    loadingData: function (loadingData) {
      this.testData = loadingData;
      console.log(loadingData);
      this.orders.cargo = loadingData
    },

    defaultCargo: function () {
      if (this.DataService.Container == false) {
        this.orders.cargo = [{
          stackable: false,
          totalWeight: true,
          tiltable: false,
          documents: [
            {
              documentTypeId: 16,
              file: null,
              fileExtension: "PDF",
              fileName: 'IMO'
            },
            {
              documentTypeId: 16,
              file: null,
              fileExtension: "PDF",
              fileName: 'UN'
            }

          ],
          containerId: 1,
          type: null,
          quantity: 1,
          unitMeasure: null,
          weightUnitMeasure: null,
          weight: 0,
          loadingTypeId: null,
          dangerous: false,
          large: 0,
          width: 0,
          legal: true,
          height: 0,
          Unit: null,
        }]
      }

      if (this.DataService.Pallets == false) {
        this.orders.cargo = [{
          stackable: false,
          tiltable: false,
          totalWeight: true,
          containerId: null,
          documents: [
            {
              documentTypeId: 16,
              file: null,
              fileExtension: "PDF",
              fileName: 'IMO'
            },
            {
              documentTypeId: 16,
              file: null,
              fileExtension: "PDF",
              fileName: 'UN'
            }
          ],
          type: "Pallets",
          quantity: 1,
          unitMeasure: null,
          weightUnitMeasure: null,
          weight: 0,
          loadingTypeId: 2,
          dangerous: false,
          large: 0,
          width: 0,
          legal: true,
          height: 0,
          Unit: null,
        }]
      }


    },

    QuotDataReceive: function () {

    },

    lastNameEvent: function (value) {
      console.log(value)
    },

    test: function (data) {
      console.log("a", data);
    },


    getPricer: function () {
      this.$store.dispatch('working', true)

      this.$http.get('Employees/Pricing', {
        headers: {
          'Cache-Control': 'max-age=10000'
        }
      })
          .then(response => {
            this.Employees = response.data.data;
          })
          .catch(error => {
            console.log(error)
          }).finally(() => this.$store.dispatch('working', false))
    },


    getIndustries: function () {
      this.$store.dispatch('working', true)

      this.$http.get('Industries')
          .then(response => {
            this.industries = response.data.data;
          })
          .catch(error => {
            console.log(error)
          }).finally(() => this.$store.dispatch('working', false))
    },


    NewOrder: function () {
      console.log(this.orders)
      /*if (JSON.stringify (this.Origin) === "{}") {
        this.$toast.error("Porfavor llena un origen");
        this.$store.dispatch("working", false);
        return;
      }
      if (JSON.stringify (this.Destination) === "{}") {
        this.$toast.error("Porfavor llena un origen");
        this.$store.dispatch("working", false);
        return;
      }*/
      if (this.Typetransportation === null) {
        this.$toast.error("Porfavor llena un Transporte principal");
        this.$store.dispatch("working", false);
        return;
      }
      if (this.TypeService === null) {
        this.$toast.error("Porfavor llena la categoria del transporte");
        this.$store.dispatch("working", false);
        return;
      }
      if (this.Type === null) {
        this.$toast.error("Sera impo o expo?");
        this.$store.dispatch("working", false);
        return;
      }
      if (this.Inconterm === null) {
        this.$toast.error("Que incoterm sera tu cotizacion?");
        this.$store.dispatch("working", false);
        return;
      }
      if (this.orders.clientId === null) {
        this.$toast.error("Porfavor llena un Cliente!");
        this.$store.dispatch("working", false);
        return;
      }
      if (this.orders.industryId === null) {
        this.$toast.error("Porfavor llena una industria!");
        this.$store.dispatch("working", false);
        return;
      }
      if (this.orders.commodity === null) {
        this.$toast.error("Porfavor llena un commodity");
        this.$store.dispatch("working", false);
        return;
      }
      if (this.orders.businessId === null) {
        this.$toast.error("Porfavor llena una Empresa!");
        this.$store.dispatch("working", false);
        return;
      }



      this.orders.ExpirationDate = this.date
      this.orders.transportationId = this.Typetransportation;
      this.orders.transportationCategoryId = this.TypeService;
      this.orders.orderTypeId = this.Type;
      this.orders.incotermId = this.Inconterm;

      this.orders.departureDate = this.dueDate;

      if (this.orders.insideBillOfLanding == "true")
        this.orders.insideBillOfLanding = true;

      if (this.orders.insideBillOfLanding == "false")
        this.orders.insideBillOfLanding = false;

      this.orders.cargo.forEach(element => {
        if (!element.dangerous) {
          delete element.documents
        }
        if (element.dangerous && element.documents) {
          element.documents.forEach(data => {
            if (data.file == null) {
              let index = element.documents.findIndex(index => index.file == data.file);
              element.documents.splice(index, 1);
            }
          })
        }

        if (element.type == "Container"){
          element.loadingTypeId = null
          // element.height = 0;
          // element.large = 0;
          // element.width = 0;
        }

        if (element.type=="Pallets"){
          element.containerId = null;
          element.loadingTypeId = 2;
        }

      })
      this.orders.DeliveryTypeId = this.filterRoute;

      let dataSend = JSON.parse(JSON.stringify(this.orders));
      delete dataSend.services;
      delete dataSend.transportation;
      delete dataSend.transportationCategory;
      delete dataSend.industry;
      delete dataSend.frequency;
      delete dataSend.currency;
      delete dataSend.orderType;
      delete dataSend.client;
      delete dataSend.salesManager;
      delete dataSend.pricer;
      delete dataSend.history;
      delete dataSend.documentsDrag;

      if(this.Edit.edit) {
        if(
            this.$refs.envio.validate()
            && this.$refs.cliente.validate()
            && this.$refs.carga.validate()
        ) {
          console.log("UpdateOrder")
          dataSend.origin = JSON.parse(JSON.stringify(this.orders.origin))
          dataSend.destination = JSON.parse(JSON.stringify(this.orders.destination))
          dataSend.pod = JSON.parse(JSON.stringify(this.orders.pod))
          dataSend.pol = JSON.parse(JSON.stringify(this.orders.pol))
          console.log(dataSend)
          if(this.filterRoute === 1){
            dataSend.pod = null
            dataSend.pol = null
          }

          this.$emit("UpdateOrder", JSON.parse(JSON.stringify(this.orders)));

        }
        else{
          this.$toast.error('Llene los campos requeridos');
        }
        return
      }

      console.log('hola')
      this.$refs.envio.validate();
      this.$refs.cliente.validate();
      this.$refs.carga.validate();
      this.$refs.quotData.validate();


      console.log(this.$refs.envio.validate())
      console.log(this.$refs.cliente.validate())
      console.log(this.$refs.carga.validate())
      console.log(this.$refs.quotData.validate())

      if (!this.Edit.edit) {

        if( Object.keys(this.Origin).length == 0
            && Object.keys(this.Destination).length == 0
            && this.$refs.envio.validate()
            && this.$refs.cliente.validate()
            && this.$refs.carga.validate()
            && this.$refs.quotData.validate()
        )
        {
          this.$toast.error('Llene los campos requeridos');
        }

        this.$store.dispatch("working", true);
        console.log(this.orders.budget)
        if (this.orders.budget == null || this.orders.budget =="")
          delete this.orders.budget;

        this.orders.origin[0].country = this.Origin.country
        this.orders.origin[0].city = this.Origin.city
        this.orders.origin[0].address = this.Origin.address
        this.orders.origin[0].state = this.Origin.state
        this.orders.origin[0].coordinates = `${this.Origin.coordinates.lat},${this.Origin.coordinates.lng}`

        this.orders.destination[0].country = this.Destination.country
        this.orders.destination[0].city = this.Destination.city
        this.orders.destination[0].address = this.Destination.address
        this.orders.destination[0].state = this.Destination.state
        this.orders.destination[0].coordinates = `${this.Destination.coordinates.lat},${this.Destination.coordinates.lng}`

        if(Object.keys(this.OriginPort).length>0 && Object.keys(this.DestinationPort).length>0){

          if (this.filterRoute==2){

            this.orders.pol = {}
            this.orders.pol.country = this.OriginPort.country
            this.orders.pol.city = this.OriginPort.city
            this.orders.pol.name = this.OriginPort.name
            this.orders.pol.coordinates = `${this.OriginPort.lat},${this.OriginPort.lon}`

            this.orders.origin[0].country = this.orders.pol.country
            this.orders.origin[0].city = this.orders.pol.city
            this.orders.origin[0].address = this.Origin.address
            this.orders.origin[0].state = this.Origin.state
            this.orders.origin[0].coordinates = this.orders.pol.coordinates

            this.orders.pod = {}
            this.orders.pod.country = this.DestinationPort.country
            this.orders.pod.city = this.DestinationPort.city
            this.orders.pod.name = this.DestinationPort.name
            this.orders.pod.coordinates = `${this.OriginPort.lat},${this.OriginPort.lon}`

            this.orders.destination[0].country = this.orders.pod.country
            this.orders.destination[0].city = this.orders.pod.city
            this.orders.destination[0].address = this.Destination.address
            this.orders.destination[0].state = this.Destination.state
            this.orders.destination[0].coordinates = this.orders.pod.coordinates

          }

          if (this.filterRoute == 4 || this.filterRoute == 3 || this.filterRoute==1){
            this.orders.pol = {}
            this.orders.pol.country = this.OriginPort.country
            this.orders.pol.city = this.OriginPort.city
            this.orders.pol.name = this.OriginPort.name
            this.orders.pol.coordinates = `${this.OriginPort.lat},${this.OriginPort.lon}`

            this.orders.pod = {}
            this.orders.pod.country = this.DestinationPort.country
            this.orders.pod.city = this.DestinationPort.city
            this.orders.pod.name = this.DestinationPort.name
            this.orders.pod.coordinates = `${this.OriginPort.lat},${this.OriginPort.lon}`
          }

        }

        if(Object.keys(this.OriginPort).length>0 && Object.keys(this.DestinationPort).length==0){

          this.orders.pol = {}
          this.orders.pol.country = this.OriginPort.country
          this.orders.pol.city = this.OriginPort.city
          this.orders.pol.name = this.OriginPort.name
          this.orders.pol.coordinates = `${this.OriginPort.lat},${this.OriginPort.lon}`

          this.orders.origin[0].country = this.orders.pol.country
          this.orders.origin[0].city = this.orders.pol.city
          this.orders.origin[0].address = this.Origin.address
          this.orders.origin[0].state = this.Origin.state
          this.orders.origin[0].coordinates = `${this.OriginPort.lat},${this.OriginPort.lon}`

        }

        if(Object.keys(this.OriginPort).length==0 && Object.keys(this.DestinationPort).length>0){


          this.orders.pod = {}
          this.orders.pod.country = this.DestinationPort.country
          this.orders.pod.city = this.DestinationPort.city
          this.orders.pod.name = this.DestinationPort.name
          this.orders.pod.coordinates = `${this.DestinationPort.lat},${this.DestinationPort.lon}`


          this.orders.destination[0].country = this.orders.pod.country
          this.orders.destination[0].city = this.orders.pod.city
          this.orders.destination[0].address = this.Destination.address
          this.orders.destination[0].state = this.Destination.state
          this.orders.destination[0].coordinates = `${this.DestinationPort.lat},${this.DestinationPort.lon}`


        }

        if (!this.Edit.edit) {
          console.log(this.orders)
          console.log(dataSend)
          dataSend.origin = JSON.parse(JSON.stringify(this.orders.origin))
          dataSend.destination = JSON.parse(JSON.stringify(this.orders.destination))
          dataSend.pod = JSON.parse(JSON.stringify(this.orders.pod))
          dataSend.pol = JSON.parse(JSON.stringify(this.orders.pol))
          if (dataSend.budget === null || dataSend.budget === ''){
            delete dataSend.budget
          }

          if (this.Typetransportation === 3) {
            delete dataSend.pod
            delete dataSend.pol
          }

          this.$store.dispatch('working', true);

          this.$http.post('Orders', dataSend).then(response => {
            if (response.data.code === 200) {
              console.log(response.data.data);
              this.orderCreated = response.data.data
              this.$toast.success("Cotizacion Enviada a Pricing")
              this.$emit('OrderCreated', response.data.data);

              if (this.rate != null){
                alert('ok')
                this.createRatePrice();
              }


              if (!this.orders.assignToPricer)
                this.$router.replace(`/ProceedQuoteSales/${response.data.data.id}`);
              this.$refs.loadDetail.resetCargo();
              this.$refs.services.resetServices();
              this.SetDefaultOrder();

            } else {
              this.$toast.error("¡Ups! No pasó tu cotización. Nuestro equipo ya fue notificado")
            }
          }).catch(error => {
            this.$toast.error("¡Ups! No pasó tu cotización. Nuestro equipo ya fue notificado")
            console.log(error);
          }).finally(() => {
            this.$store.dispatch('working', false)
          })
        }
      }
    },
    getCustomers: async function () {
      try {
        const response = await this.$http.get('Companies', {
          headers: {
            'Cache-Control': 'max-age=5'
          }
        });
        if (response && response.data) {
          this.companies = response.data.data;
        } else {
          console.error('Respuesta no válida:', response);
        }
      } catch (error) {
        console.error('Error al obtener clientes:', error);
      }
    },
    updateCustomers: function () {
      console.log('hola')
    },

    SetDataOrder() {
      if (this.DataOrder) {
        this.orders.salesManagerId = this.DataOrder.salesManagerId
        this.orders.landDedicated = this.DataOrder.landDedicated
        this.orders.comments = this.DataOrder.comments;
        this.orders.industryId = this.DataOrder.industryId;
        this.orders.budget = this.DataOrder.budget;
        this.orders.commodity = this.DataOrder.commodity;
        this.orders.clientId = this.DataOrder.clientId;
        this.orders.pricerId = this.DataOrder.pricerId;
        this.orders.Frequency = this.DataOrder.frequency;
        this.orders.services = this.DataOrder.services;
        this.orders.assignToPricer = this.DataOrder.pricerId !== null;
        if (this.DataOrder.urgent)
          this.orders.urgent = true;

        if (this.DataOrder.insideBillOfLanding == 1)
          this.orders.insideBillOfLanding = "true";

        if (this.DataOrder.insideBillOfLanding == 0)
          this.orders.insideBillOfLanding = "false";

        if (this.DataOrder.landTransportation == 'TR')
          this.orders.LandTransportation = 'TR'

        if (this.DataOrder.landTransportation == 'R')
          this.orders.LandTransportation = 'R'

        if (this.DataOrder.landTransportation == 'T')
          this.orders.LandTransportation = 'T'

        this.orders.cargo = [];
        this.DataOrder.cargo.forEach(element => {
          this.orders.cargo.push(
              {
                id: element.id,
                stackable: element.stackable,
                tiltable: element.tiltable,
                containerId: element.containerId > 0 ? element.containerId : null,
                type: element.type,
                quantity: element.quantity,
                unitMeasure: element.unitMeasure,
                weightUnitMeasure: element.weightUnitMeasure,
                weight: element.weight,
                loadingTypeId: element.loadingTypeId > 0 ? element.loadingTypeId : null,
                dangerous: element.dangerous,
                large: element.large,
                width: element.width,
                legal: true,
                totalWeight: element.totalWeight,
                height: element.height,
                Unit: element.weightUnitMeasure,
                messageWeigth: ''
              }
          )
        })

        // console.log("Order Cargo",this.orders.cargo)

      }
    }

  },
  mounted() {
    this.getCustomers();
    this.getPricer();
    this.getSuppliers();
    this.QuotDataReceive();
    this.getIndustries();
    this.getBuissnes();
    this.SetDataOrder();
    // this.defaultCargo();

  }
}
</script>

<style scoped>


</style>
