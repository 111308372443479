var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-autocomplete',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.inputFocusedOut),expression:"inputFocusedOut"}],ref:'autocomplete_'+ _vm.id,staticClass:"mx-1 input-autocomplete",class:[_vm.isMenuOpen ? 'inputSelected border-none' : '',],attrs:{"autocomplete":"off","label":(Array.isArray(_vm.lazySelections) && _vm.lazySelections.length == 0) ? _vm.label : '',"dense":_vm.dense,"no-filter":"","item-text":"description","items":_vm.lazyItems,"loading":_vm.isLoading,"menu-props":{
          value: _vm.isMenuOpen,
          maxWidth: 500,
          maxHeight: 350,
          bottom: true,
          nudgeBottom: 5,
          ..._vm.menuProps
        },"outlined":"","item-value":"place_id","placeholder":_vm.placeholder,"return-object":""},on:{"focus":_vm.inputFocused,"keydown":_vm.keyPressed,"mousedown":_vm.inputFocused,"update:search-input":_vm.inputChanged},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',[_vm._v(_vm._s(_vm.inputIcon))])]},proxy:true},{key:"item",fn:function({parent, item}){return [_c('AutocompleteDetails',{class:_vm.menuProps ? _vm.menuProps['content-class'] : '',attrs:{"item":item,"multiple":_vm.hasManyPlaces},on:{"expand":function($event){item.open = !item.open},"select":_vm.selectItem,"add":_vm.addItem,"remove":e => _vm.removeItem(e.placeId)}})]}},(this.hasManyPlaces || this.selections.length > 0)?{key:"selection",fn:function({item}){return [(_vm.isMenuOpen && _vm.selections.length > 0)?_c('div',[_c('v-chip',{attrs:{"close":"","small":""},on:{"click:close":function($event){return _vm.removeItem(item['place_id'])}}},[_vm._v(_vm._s(item.fixed_address))])],1):(!_vm.isMenuOpen && _vm.selections.length > 0)?_c('div',[_c('span',[_vm._v(" · "+_vm._s(item.fixed_address))])]):_vm._e()]}}:null,{key:"no-data",fn:function(){return [(_vm.items.length == 0)?_c('div',{staticClass:"mx-5"},[_vm._v(" No se encontraron registos ")]):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.lazySelections),callback:function ($$v) {_vm.lazySelections=$$v},expression:"lazySelections"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }