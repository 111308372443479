<template>
  <div>
    <v-row no-gutters>
      <v-autocomplete
          autocomplete="off"
          :label="(Array.isArray(lazySelections) && lazySelections.length == 0) ? label : ''"
          :dense="dense"
          v-model="lazySelections"
          :class="[isMenuOpen ? 'inputSelected border-none' : '',]"
          class="mx-1 input-autocomplete"
          @focus="inputFocused"
          no-filter
          item-text="description"
          :items="lazyItems"
          @keydown="keyPressed"
          :loading="isLoading"
          hint="Escribir dirección completa"
          persistent-hint
          :menu-props="{
          value: isMenuOpen,
          maxWidth: 500,
          maxHeight: 350,
          bottom: true,
          nudgeBottom: 5,
          ...menuProps
        }"
          @mousedown="inputFocused"
          :multiple="hasManyPlaces"
          outlined
          item-value="place_id"
          :placeholder="placeholder"
          :ref="'autocomplete_'+ id"
          return-object
          @update:search-input="inputChanged"
          v-click-outside="inputFocusedOut"
      >
        <template v-slot:prepend-inner>
          <v-icon>{{ inputIcon }}</v-icon>
        </template>


        <template v-slot:item="{parent, item}">
          <AutocompleteDetails
              :item="item"
              :multiple="hasManyPlaces"
              @expand="item.open = !item.open"
              @select="selectItem"
              @add="addItem"
              @remove="e => removeItem(e.placeId)"
              :class="menuProps ? menuProps['content-class'] : ''"
          />
        </template>

        <template v-slot:selection="{item}" v-if="this.hasManyPlaces || this.selections.length > 0">
          <div v-if="isMenuOpen && selections.length > 0">
            <v-chip
                close
                small
                @click:close="removeItem(item['place_id'])"
            >{{ item.fixed_address }}
            </v-chip>
          </div>
          <div v-else-if="!isMenuOpen && selections.length > 0">
            <span>&nbsp;·&nbsp;{{ item.fixed_address }}</span>
          </div>

        </template>

        <template v-slot:no-data>
          <div v-if="items.length == 0" class="mx-5">
            No se encontraron registros
          </div>
        </template>

      </v-autocomplete>
    </v-row>
  </div>
</template>

<script>
import debouncePromise from "debounce-promise";
import AutocompleteDetails from "./AutocompleteDetails/AutocompleteDetailsTerrestre";
// import AutocompletePlaces from "../GlobalComponents/AutocompletePlaces/AutocompletePlaces";

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    placeholder: {
      type: String,
      default: "¿Algún otro lugar?"
    },
    menuProps: {
      type: Object,
      default: () => {
      }
    },
    autocompleteWidth: Number,
    label: String,
    dense: {
      type: Boolean,
      default: false
    },
    transportation: String,
    isOpen: {
      type: Boolean,
      default: false
    },
    search: {
      type: String,
      default: ''
    },
    items: {
      type: [Array, Object, null]
    },
    selections: {
      type: [Array, Object, null]
    },
    restrictToCountries: {
      type: Array,
      default: () => []
    },
    noResultsFoundMessage: {
      type: String,
      default: "No results found"
    }
  },
  components: {
    AutocompleteDetails
  },
  data() {
    return {
      inputIcon: 'mdi-map-marker',
      numberOfRequests: 0,
      isLoading: false,
      isSelected: false,
      openPanels: null,
      lastItemUpdate: 0,
      autocomplete: null,
      geocoder: null,
      initialized: false,
      appendPort: '',
      value: '',
      isMenuOpen: false,
      hasManyPlaces: false,
      predictions: [],
      selectedValues: "",
      menuPosition: {
        left: 0,
        top: 0
      },
      inputValue: "Holi"
    };
  },
  computed: {
    lazyItems: {
      get: function () {
        return this.items;
      },
      set: function (val) {
        try {
          console.log('Se emitio',val)
          this.$emit('updateItems', val)
        } catch (error) {
          throw new Error("There was an error while setting lazyItems \n" + error)
        }
      }
    },
    lazySelections: {
      get: function () {
        return this.selections;
      },
      set: function (val) {
        this.$emit('change', val)
      }
    },
    address: {
      get: function () {
        return this.search;
      },
      set: function (val) {
        this.$emit('updateAddress', val)
      }
    },

  },
  watch: {
    isOpen: function (val) {
      this.isMenuOpen = val
    },
    items: function () {
      try {
        console.log('itemsChanged')
        this.removeClickEvent();
        this.setSelectedItems();
      } catch (error) {
        throw new Error(`There was an error when setting items \n` + error)
      }
    },
    isMenuOpen: function () {
      if (!this.isMenuOpen) {
        this.openPanels = false
      }
      if (this.items) {
        this.removeClickEvent();
      }
      this.$emit('menuChanged', this.isMenuOpen)
    },
    search: async function (text) {
      try {
        await this.$nextTick(async () => {
          let isTextValid;

          if (Array.isArray(this.lazySelections)) isTextValid = this.lazySelections.filter(x => x.description == text).length <= 0;
          else isTextValid = this.lazySelections.description !== text;

          console.log(this.lazySelections)
          console.log(text)
          console.log(isTextValid)

          if (text && isTextValid) {

            if (this.lazyItems.length > 0)
              this.lazyItems = [];
            this.isLoading = true;
            await this.searchPlaces(text);
          }

        })
      } catch (error) {
        console.error(error)
      }
    },
    selections: {
      handler() {
        if (this.isLoading)
          this.isLoading = false;
        this.removeClickEvent();
        this.setSelectedItems();
      },
      deep: true
    },
    hasManyPlaces: function () {
      if (this.hasManyPlaces) {
        if (this.lazySelections.description) {
          this.lazySelections = [this.lazySelections]
        } else {
          this.lazySelections = []
        }
      }
    }
  },
  created() {
    this.isLoading = false
    this.$gmapApiPromiseLazy().then(() => {
      this.geocoder = new window.google.maps.Geocoder();
      this.autocomplete = new window.google.maps.places.AutocompleteService();
      this.initialized = true;
      console.log("Se inicio")
      this.$emit("initialized");
    });

  },
  methods: {
    getSelectedPortsIndexes() {
      alert('ok')
      console.log("items", this.items)
      let selectedIndex = this.items.findIndex(x => x.selected);
      console.log(this.items)
      console.log(selectedIndex)
      return this.items[selectedIndex].ports.reduce((accumulator, currentValue, index) => {
        currentValue.selected && accumulator.push(index);
        return accumulator;
      }, []);
    },
    removeClickEvent() {
      if (this.isMenuOpen) {
        try {
          this.$nextTick(() => {
            try {
              let menu = this.$refs[`autocomplete_${this.id}`].$refs.menu
              let list = menu.$refs.content.children[0]['__vue__'];
              if (list) {
                list.$children.forEach((item) => {
                  item._events.click = () => {
                  };
                })
              }
            } catch (error) {
              throw new Error("There was an error while updating list events" + error)
            }
          })
        } catch (error) {
          throw new Error("There was an error while updating list events" + error)
        }
      }
    },
    inputChanged(val) {
      this.address = val
    },
    setSelectedItems() {
      try {
        if (this.lazySelections.length > 0) {
          this.$nextTick(() => {
            try {
              let $ = this
              let $_autocomplete = this.$refs[`autocomplete_${this.id}`];
              $_autocomplete.setSelectedItems = function () {
                if ($.hasManyPlaces || (Array.isArray($.lazySelections) && $.lazySelections.length)) {
                  this.selectedItems = $.lazySelections
                }
              }

            } catch (error) {
              throw new Error(error)
            }
          })
        }
      } catch (error) {
        console.info(this.lazySelections)
        console.error(error)
      }
    },
    keyPressed(event) {
      const keyCodes = {
        backspace: 8
      }

      if (event.keyCode === keyCodes.backspace && (this.address == null || this.address == "")) {
        event.preventDefault();
        if (this.lazySelections.length > 0) {
          let lastIndex = this.lazySelections.length - 1;
          this.removeItem(this.lazySelections[lastIndex]['place_id'])
        }
      }
    },
    inputFocused() {
      this.isMenuOpen = true
    },
    inputFocusedOut(event) {
      let menuContent = this.$refs[`autocomplete_${this.id}`].$refs.menu.$refs.content;
      if (this.isMenuOpen && !menuContent.contains(event.target)) {
        this.isMenuOpen = false;
      }
    },
    portSelected() {
      this.isMenuOpen = false;
    },
    getPlaceAddress(item) {
      const SEARCH_ADDRESS_TYPES = ['locality', "administrative_area_level_2", "administrative_area_level_1", "country"]
      let fixedAddress = "";

      if (item.details.address_components.length > 1 && this.hasManyPlaces) {
        SEARCH_ADDRESS_TYPES.forEach((type) => {
          let value = item.details.address_components.filter((x) => x.types.findIndex(e => e === type))[0].long_name;
          if (value) {
            fixedAddress = value
          }
        })
        return fixedAddress;
      }
      return item.description;
    },
    isSelectionUnique(obj) {
      if (Array.isArray(this.lazySelections)) {
        return this.lazySelections.findIndex(x => x['place_id'] === obj['placeId']) == -1 ? true : false;
      }
      return this.lazySelections['place_id'] == obj['placeId'] ? false : true;
    },
    selectPort(obj) {
      let selectionIndex;
      let placeIndex = this.lazyItems.findIndex(x => x['place_id'] === obj.placeId);
      if (placeIndex == -1) throw new Error('Place index not found in request')

      //Find indexes for port selected and port selected before
      let portIndex = this.lazyItems[placeIndex].ports.findIndex(x => x._id === obj.portId);
      let selectedPortIndex = this.lazyItems[placeIndex].ports.findIndex(x => x.selected);

      //Remove selection when is a different port and there\'s already one selected
      if (selectedPortIndex !== -1 && selectedPortIndex != portIndex && !this.hasManyPlaces) {
        this.lazyItems[placeIndex].ports[selectedPortIndex].selected = false
      }
      if (!this.hasManyPlaces) this.lazyItems[placeIndex].ports[portIndex].selected = !this.lazyItems[placeIndex].ports[portIndex].selected;

      if (Array.isArray(this.lazySelections) || this.lazySelections.length > 0) selectionIndex = this.lazySelections.findIndex(x => x['place_id'] === obj.placeId);

      if (selectionIndex == -1) console.error('Couldn\'t find selected item')

      if (this.hasManyPlaces) this.lazySelections[selectionIndex] = this.lazyItems[placeIndex];
      else this.lazySelections = this.lazyItems[placeIndex]
    },
    selectItem(obj) {
      try {
        //   let selectedPlace = this.lazyItems.findIndex(x => x.selected);
        let placeIndex = this.lazyItems.findIndex(x => x['place_id'] === obj.placeId);
        if (placeIndex === -1) throw new Error('Place index not found in request')

        if (this.isSelectionUnique(obj)) {

          //Clear port selection for selected items before changing new selection
          /*if(!this.hasManyPlaces && selectedPlace !== -1){
            let portIndex = this.getSelectedPortsIndexes();
            if(portIndex.length > 0) {
              this.lazyItems[selectedPlace].ports[portIndex].selected = false;
            }
          }*/

          this.lazyItems[placeIndex].selected = !this.lazyItems[placeIndex].selected;
          let place = this.lazyItems[placeIndex];

          console.log(place)

          if (place.type == "port")
            place.ports[0].selected = true;

          place.fixed_address = this.getPlaceAddress(place);
          place.selected = true;

          this.lazySelections = place;

          let $_autocomplete = this.$refs[`autocomplete_${this.id}`]
          $_autocomplete.selectItem(place)
        }
        if (obj.portId) this.selectPort(obj)

      } catch (error) {
        throw new Error(`There was an error while selecting and object data ${JSON.stringify(obj)}` + error)
      }

    },
    addItem(obj) {
      console.log(obj)
      let placeIndex = this.items.findIndex(x => x['place_id'] === obj.placeId);
      if (placeIndex == -1) throw new Error('Place index not found in request')

      if (this.isSelectionUnique(obj)) {
        this.lazyItems[placeIndex].selected = !this.lazyItems[placeIndex].selected;
        let place = this.items[placeIndex];
        place.fixed_address = this.getPlaceAddress(place)
        this.lazySelections.push(place)
      }
      if (obj.portId) this.selectPort(obj)
      if (this.hasManyPlaces) {
        this.address = "";
      }
    },
    removeItem(placeId) {
      const index = this.lazySelections.findIndex(x => x['place_id'] === placeId)
      const itemIndex = this.items.findIndex(x => x['place_id'] === placeId)

      if (index == -1) throw new Error('Chip could not be deleted');
      if (itemIndex != -1) this.items[itemIndex].selected = false;

      this.lazySelections.splice(index, 1)
    },
    cityUnselected(placeId) {
      let placeIndex = this.items.findIndex(x => x['place_id'] === placeId);
      if (placeIndex == -1) {
        throw new Error('Place index not found in request')
      }
      this.items[placeIndex].selected = false;

      let selectionIndex = this.lazySelections.findIndex(x => x['place_id'] === placeId)
      this.lazySelections.pop(selectionIndex)

      // this.setMenuPosition()

    },

    searchPlaces: debouncePromise(async function (text) {
      try {
        if (!this.isLoading) {
          this.isLoading = true
        }
        let predictions = await new Promise((resolve) => {
          try {
            this.autocomplete.getPlacePredictions({
                  input: text,
                  componentRestrictions: {country: this.restrictToCountries}
                }, (predictions, status) => {
                  const autocompleteError = status != window.google.maps.places.PlacesServiceStatus.OK;
                  if (autocompleteError) {
                    throw new Error("Could not retrieve places information")
                  }

                  resolve(predictions);
                }
            );
            console.log('Get place predictions')
          } catch (error) {
            throw new Error(error)
          }
        })
        let results = await this.mapPredictions(predictions)

        results = results.filter(res => res.details.address_components.length > 2)

        predictions = null;

        if (Array.isArray(this.lazySelections)) {
          this.lazySelections.forEach(elem => {
            let position = results.findIndex(x => x['place_id'] === elem.place_id)
            if (position != -1) {
              results[position] = elem
            }
          })
        } else {
          let index = results.findIndex(x => x['place_id' === this.lazySelections['place_id']])
          results[index] = this.lazySelections
        }

        this.lazyItems = results;
        this.isLoading = false
      } catch (error) {
        throw new Error(error)
      }
    }, 1000),
    mapPredictions: async function (predictions) {
      let results = [];

      for (const item of predictions) {
        try {
          item.details = await this.placeGeoData(item['place_id'])
          item.type = 'city';
          item.selected = false;
          item.open = false;

          results.push(new Promise(resolve => resolve(item)))


        } catch (error) {
          throw new Error("There was an error while mapping item: " + JSON.stringify(item) + "\n" + error)
        }


      }

      return await Promise.all(results)
    },
    placeGeoData: async function (placeId) {
      try {
        return await new Promise(resolve => {
          this.geocoder.geocode({placeId}, ([{address_components, geometry}], status) => {
            const autocompleteError = status != window.google.maps.GeocoderStatus.OK;
            if (autocompleteError) {
              throw new Error("There was an error while retrieving data from autocomplete" + status)
            }
            resolve({
              address_components: address_components,
              location: {lat: geometry.location.lat(), lng: geometry.location.lng()}
            })
          })
        })
      } catch (error) {
        throw new Error(`Error while getting geodata \n` + error)
      }
    },

    // When selected, get the lat/lng info and merge with original selection
    onSelected() {
      this.$emit('textFieldSelected', {})
    },
    onClicked(placeData, selectedPort) {
      try {
        this.isMenuOpen = false;
        this.inputIcon = placeData.ports.length > 1 ? 'mdi-city' : 'mdi-ferry'

        let allPorts = [];
        placeData.ports.forEach(port => {
          port.selected = port._id == selectedPort._id;
          allPorts.push(port)
        });

        placeData.ports = allPorts;
        this.$emit('placeSelected', placeData)

      } catch (e) {
        console.error(e)
      }
    },
    addSeaPortsToPredictions: async function () {
      try {
        // let response = [];
        // for(const pred of this.predictions){
        //   let geocode = null;
        //   let ports = null;
        //   await this.placeGeoData(pred.place_id)
        //     .then(([{address_components, geometry}]) => {
        //       if(!address_components || !geometry) throw new Error('Couldn\'t get data from place id ' + pred.place_id)
        //       geocode = {
        //         address_components,
        //         location: {
        //           lat: geometry.location.lat(), lng: geometry.location.lng()
        //         }
        //       }
        //   });
        //   if(!geocode){ throw new Error("There'Pricing not geodata"); }
        //
        //   const country = geocode.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)
        //   await this.getSeaPorts(country[0].short_name, { lat: geocode.location.lat, lon: geocode.location.lng }).then(data => ports = data)
        //
        //   const result = {
        //     name: pred.structured_formatting.main_text,
        //     address: pred.description,
        //     placeId: pred.place_id,
        //     placeDetails: geocode,
        //     ports: ports
        //   }
        //
        //   response.push(result)
        // }
        //
        // this.items = response
        // this.isLoading = false;
      } catch (error) {
        console.error(error)
      }
    },
    getItems() {
      return this.items;
    },
    getSelections() {
      return this.lazySelections;
    },
    // setItems(items) {
    //   console.log('Setting items')
    //   // this.items =  items;
    // },
    // setSelections(selections){
    //   console.log('Changing selections');
    //   console.log(selections);
    //
    //   this.selections = selections;
    // },
  },
  mounted() {
    try {
      this.$nextTick(() => {
        let $_autocomplete = this.$refs[`autocomplete_${this.id}`];
        if ($_autocomplete)
          $_autocomplete.deleteCurrentItem = () => {
          }
      })
    } catch (error) {
      throw new Error("Error while deleting event from autocomplete" + error)
    }
  },
  directives: {
    'click-outside': {
      bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          if (!(el == event.target || el.contains(event.target || event))) {
            vnode.context[binding.expression](event)
          }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      }
    }
  }
};
</script>

<style scoped>
>>> .v-expansion-panels {
  width: 400px;
}

.inputSelected {
  position: absolute;
  background-color: #fff;
  top: 0;
  z-index: 3;
  width: 500px;
  transition: width 4s;
  box-shadow: 0px 0px 1em rgba(0, 0, 0, 0.18);
}

>>> .v-menu__content::-webkit-scrollbar {
}

.input-autocomplete >>> .v-input__control .v-input__slot .v-input__append-inner {
  margin: auto;
}

.input-autocomplete >>> .v-input__control .v-input__slot .v-input__prepend-inner {
  margin: auto 0;
}

.expansion-header {
  width: 250px;
}

.expansion-content {
  width: 200px;
}

.position-relative {
  position: relative;
}

.menu-content {
  min-width: 500px;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
}

.hide-input-slot >>> .v-input__control .v-input__slot .v-text-field__slot {
  display: none;
}

>>> .v-list-item {
  display: block
}

</style>
