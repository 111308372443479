<template>
<v-container fluid>
<h2>test</h2>
</v-container>
</template>

<script>
export default {
    name: "operationVenta",
    mounted() {
        console.log(this.$route.params.id)
    }
}
</script>

<style>

</style>