import {httpApi} from "@/config/axios";
async function getS3(path, file) {
    // Al subir un documento el back regresa un path para guardarlo en s3
    // file es el archivo como tal en la variable usada para guardarlo
    let generatedURL = null;
    const elblob = await fetch(URL.createObjectURL(file)).then(res => res.blob());
    const signedURL = await httpApi.get('shipping/generate/signed-url', {
        params: {
            path: path,
            mediaType: file.type
        }
    }).then((response) => {
        generatedURL = response.data
    })
    console.log(signedURL)
    console.log(elblob);
    console.log(file)
    const upload = await fetch(generatedURL, {
        method: 'PUT',
        body: elblob,
        headers: {
            'Content-Type': file.type
        }
    })

    return upload;
}

export { getS3 }
