<template>
  <div>
    <v-input
        :class="[isMenuOpen ? 'inputSelected border-none' : '']"
        class="mx-2"
    >
      <v-menu
          offset-y
          v-model="isMenuOpen"
          max-width="500"
          min-width="500"
          nudge-top="25"
          offset-overflow
          :key="id"
          :eager="true"
          disable-keys
          :close-on-content-click="false"
          max-height="350"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              v-bind="attrs"
              v-on="on"
              :ref="'autocomplete_' + id"
              outlined
              :dense="dense"
              :prepend-inner-icon="inputIcon"
              autocomplete="new-password"
              :placeholder="placeholder"
              :label="
              Array.isArray(lazySelections) && lazySelections.length == 0
                ? label
                : ''
            "
              v-model="internalSearch"
              @input="inputChanged"
              :loading="isLoading"
          ></v-text-field>
        </template>
        <v-list subheader :key="listKey">
          <template v-if="lazyItems.length === 0">
            <v-subheader>
              No se encontraron registros.
            </v-subheader>
          </template>
          <template v-else>
            <v-list no-action>
              <template v-for="item in lazyItems">
                <v-list-group
                    :eager="true"
                    :value="item.selected"
                    v-if="item.airports && filterRoute==2 ? item.airports.length > 1 : item.airports.length > 0"
                    :prepend-icon="
                    item.type === 'port' ? 'mdi-airport' : 'mdi-city'
                  "
                    :group="item.place_id"
                    @click="selectItem({ placeId: item['place_id'] })"
                    class="s-list-group-active"
                    no-action
                    :key="item.place_id"
                >

                  <template v-slot:activator>
                    <v-list-item-title>
                      {{ item.description }}
                    </v-list-item-title>
                  </template>
                  <template>
                    <v-text-field solo v-model="searchAirPort" class="pt-5 pl-2 pr-2 col-md-12"
                                  label="Buscar Aeropuerto" dense outlined
                                  @input="searchAutocomplete(item.place_id)"></v-text-field>
                  </template>
                  <template v-for="port in item.airports">
                    <v-list-item
                        :input-value="port.selected"
                        :key="port._id"
                        class="pl-12"
                        @click="
                        selectPort({
                          placeId: item['place_id'],
                          portId: port._id,
                        })
                      "
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-airport</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ port.name }} {{ port.city }} {{ port.country }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ Number.parseFloat(port.distance).toFixed(2) }} KM
                          de distancia
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <div v-intersect="loadMore"/>
                </v-list-group>
                <v-list-item
                    v-else
                    :input-value="item.selected"
                    active-class="s-list--active"
                    @click="
                    selectItem({
                      placeId: item['place_id'],
                      portId:
                        item.type === 'airport' ? item.airports[0]._id : null,
                    })
                  "
                    :key="item.place_id"
                >
                  <v-list-item-icon>
                    <v-icon>
                      {{ item.type === "airport" ? "mdi-airport" : "mdi-city" }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{
                        item.type === "airport"
                            ? `${item.airports[0].name}, ${item.airports[0].city}, ${item.airports[0].country}`
                            : item.description
                      }}
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="item.type === 'airport'">
                      {{
                        Number.parseFloat(item.airports[0].distance).toFixed(2)
                      }}
                      KM de distancia
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </template>
        </v-list>
      </v-menu>
    </v-input>
  </div>
</template>

<script>
import debouncePromise from "debounce-promise";
import {debounce} from "debounce";
// import AutocompletePlaces from "../GlobalComponents/AutocompletePlaces/AutocompletePlaces";

export default {
  name: "SAirortAutocomplete",
  props: {
    id: {
      type: Number,
      required: true,
    },
    placeholder: {
      type: String,
      default: "¿Algún otro lugar?",
    },
    menuProps: {
      type: Object,
      default: () => {
      },
    },
    autocompleteWidth: Number,
    label: String,
    dense: {
      type: Boolean,
      default: false,
    },
    transportation: String,
    isOpen: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: "",
    },
    items: {
      type: [Array, Object, null],
    },
    selections: {
      type: [Array, Object, null],
    },
    restrictToCountries: {
      type: Array,
      default: () => [],
    },
    noResultsFoundMessage: {
      type: String,
      default: "No results found",
    },
    filterRoute: Number
  },
  components: {},
  data: () => ({
    listKey: 0,
    enableSearch: false,
    selectedItem: null,

    findPorts: [],
    searchAirPort: '',
    internalSearch: "",
    searchText: "",
    inputIcon: "mdi-map-marker",
    numberOfRequests: 0,
    isLoading: false,
    isSelected: false,
    openPanels: null,
    lastItemUpdate: 0,
    autocomplete: null,
    geocoder: null,
    initialized: false,
    appendPort: "",
    value: "",
    isMenuOpen: false,
    hasManyPlaces: false,
    predictions: [],
    selectedValues: "",
    menuPosition: {
      left: 0,
      top: 0,
    },
  }),
  computed: {
    lazyItems: {
      get: function () {
        return this.items;
      },
      set: function (val) {
        try {
          this.$emit("updateItems", val);
        } catch (error) {
          throw new Error(
              "There was an error while setting lazyItems \n" + error
          );
        }
      },
    },
    lazySelections: {
      get: function () {
        return this.selections;
      },
      set: function (val) {
        this.address = this.internalSearch = val.description;
        this.$emit('change2');
        this.$emit("change", val);
      },
    },
    address: {
      get: function () {
        return this.internalSearch;
      },
      set: function (val) {
        console.log("Address", val);
        this.$emit('change2');
        this.$emit("updateAddress", val);
      },
    },
  },
  watch: {
    isOpen: function (val) {
      this.isMenuOpen = val;
    },
    internalSearch() {

      this.enableSearch = false;
      this.searchAirPort = ""

    },
    items: function () {
      try {
        console.log("itemsChanged");
        // this.removeClickEvent();
        // this.setSelectedItems();
      } catch (error) {
        throw new Error(`There was an error when setting items \n` + error);
      }
    },
    filterRoute: function (val) {
      this.internalSearch = "";
      this.$emit('filterRoute', val)
    },
    isMenuOpen: function () {
      if (!this.isMenuOpen) {
        this.openPanels = false;
      }
      if (this.items) {
        // this.removeClickEvent();
      }
      this.$emit("menuChanged", this.isMenuOpen);
    },
    search: async function (text) {
      try {
        await this.$nextTick(async () => {
          let isTextValid;

          // Is PORT
          const isPort = this.lazySelections.type === "airport";
          if (isPort) {
            console.log("Es puerto");
            const namePort = `${this.lazySelections.airports[0].name}, ${this.lazySelections.airports[0].city}, ${this.lazySelections.airports[0].country}`;
            isTextValid =
                this.lazySelections.description !== text && namePort !== text;
          } else {
            console.log("No es puerto");
            const selectedPort = this.lazySelections.airports?.find(
                (port) => port.selected
            );
            let namePort = "";
            if (selectedPort) {
              namePort = `${selectedPort.name}, ${selectedPort.city}, ${selectedPort.country}`;
            }
            isTextValid =
                this.lazySelections.description !== text && namePort !== text;
          }

          this.internalSearch = text;

          if (text && isTextValid) {
            if (this.lazyItems.length > 0) this.lazyItems = [];
            this.isLoading = true;
            await this.searchPlaces(text);
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    selections: {
      handler() {
        if (this.isLoading) this.isLoading = false;
        // this.removeClickEvent();
        // this.setSelectedItems();
      },
      deep: true,
    },
    hasManyPlaces: function () {
      if (this.hasManyPlaces) {
        if (this.lazySelections.description) {
          this.lazySelections = [this.lazySelections];
        } else {
          this.lazySelections = [];
        }
      }
    },
  },
  created() {
    this.isLoading = false;
    this.$gmapApiPromiseLazy().then(() => {
      this.geocoder = new window.google.maps.Geocoder();
      this.autocomplete = new window.google.maps.places.AutocompleteService();
      this.initialized = true;
      // console.log("Se inicio")
      this.$emit("initialized");
    });
  },
  methods: {
    async loadMore(entries, observer, isIntersecting) {
      console.log(entries);
      console.log(observer);
      if (isIntersecting && this.selections && this.isMenuOpen) {
        // let item = this.lazyItems.find((item) => item.selected === true);
        const country = this.selections.details.address_components.filter(
            (value) => value.types.findIndex((a) => a === "country") !== -1
        );
        if (country !== -1) {
          this.selections.page++;
          let moreAirPorts = await this.getAirPorts(country[0].short_name, {
            lat: this.selections.details.location.lat,
            lon: this.selections.details.location.lng,
          }, this.selections.page);

          moreAirPorts.data = moreAirPorts.data.filter((ap) => ap.city !== "");


          let airports = moreAirPorts.data.map((item) => {
            return {...item, selected: false};
          });

          this.selections.airports = [...this.selections.airports, ...airports];
        }
      }
    },
    searchAutocomplete: debouncePromise(function (placeId) {
      if (this.searchAirPortPort != "") {
        this.findPortBySearch(placeId);
      }

    }, 1000),
    findPortBySearch(placeId) {
      let place = this.lazyItems.find(x => x.place_id == placeId)
      let name = this.searchAirPort
      let country = null;
      let coordinates = {
        lat: null,
        lon: null
      }
      coordinates.lat = place.details.location.lat;
      coordinates.lng = place.details.location.lng;
      country = place.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
      let airports = null;
      this.$httpPlacesApi.get(`/api/ports/airport/country/${country}/location/${coordinates.lat},${coordinates.lng}/?name=${name}`, {
        params: {
          page: 1,
          limit: 50,
          //100,000 km
          range: 10000000
        }
      }).then(response => {
        airports = response.data;
        /*
                ports.forEach(port=>{
                  results.push({
                    description: `${port.city}, ${port.country}`,
                    place_id: place.place_id + port._id + '_port',
                    type: 'port',
                    ports: [port]
                  });

                });
        */

        place.airports = airports.map(item => {
          return {
            ...item,
            selected: false,
          }
        });
        console.log(place)

      }).catch(error => console.error(error))
    },
    getSelectedPortsIndexes() {
      let selectedIndex = this.items.findIndex((x) => x.selected);
      return this.items[selectedIndex].airports.reduce(
          (accumulator, currentValue, index) => {
            currentValue.selected && accumulator.push(index);
            return accumulator;
          },
          []
      );
    },
    removeClickEvent() {
      if (this.isMenuOpen) {
        try {
          this.$nextTick(() => {
            try {
              let menu = this.$refs[`autocomplete_${this.id}`].$refs.menu;
              let list = menu.$refs.content.children[0]["__vue__"];
              if (list) {
                list.$children.forEach((item) => {
                  item._events.click = () => {
                  };
                });
              }
            } catch (error) {
              throw new Error(
                  "There was an error while updating list events" + error
              );
            }
          });
        } catch (error) {
          throw new Error(
              "There was an error while updating list events" + error
          );
        }
      }
    },
    inputChanged: debounce(function (val) {
      if (!this.isMenuOpen) this.isMenuOpen = true;
      this.address = val;
    }, 1000),
    setSelectedItems() {
      try {
        if (this.lazySelections > 0) {
          this.$nextTick(() => {
            try {
              console.log("Esta cosa", this.lazySelections);

              // let $ = this
              // let $_autocomplete = this.$refs[`autocomplete_${this.id}`];
              // $_autocomplete.setSelectedItems = function () {
              //   if ($.hasManyPlaces || (Array.isArray($.lazySelections) && $.lazySelections.length)) {
              //     this.selectedItems = $.lazySelections
              //   }
              // }
            } catch (error) {
              throw new Error(error);
            }
          });
        } else {
          if (this.lazySelections.description) {
            this.internalSearch = this.lazySelections.description;
          }
          // this.lazyItems.forEach((item) => {
          //
          // })
        }
      } catch (error) {
        console.info(this.lazySelections);
        console.error(error);
      }
    },
    keyPressed(event) {
      const keyCodes = {
        backspace: 8,
      };

      if (
          event.keyCode === keyCodes.backspace &&
          (this.address == null || this.address == "")
      ) {
        event.preventDefault();
        if (this.lazySelections.length > 0) {
          let lastIndex = this.lazySelections.length - 1;
          this.removeItem(this.lazySelections[lastIndex]["place_id"]);
        }
      }
    },
    inputFocused() {
      this.isMenuOpen = true;
    },
    inputFocusedOut(event) {
      let menuContent = this.$refs[`autocomplete_${this.id}`].$refs.menu.$refs
          .content;
      if (this.isMenuOpen && !menuContent.contains(event.target)) {
        this.isMenuOpen = false;
      }
    },
    portSelected() {
      this.isMenuOpen = false;
    },
    getPlaceAddress(item) {
      const SEARCH_ADDRESS_TYPES = [
        "locality",
        "administrative_area_level_2",
        "administrative_area_level_1",
        "country",
      ];
      let fixedAddress = "";

      if (item.details.address_components.length > 1 && this.hasManyPlaces) {
        SEARCH_ADDRESS_TYPES.forEach((type) => {
          let value = item.details.address_components.filter((x) =>
              x.types.findIndex((e) => e === type)
          )[0].long_name;
          if (value) {
            fixedAddress = value;
          }
        });
        return fixedAddress;
      }
      return item.description;
    },
    isSelectionUnique(obj) {
      if (Array.isArray(this.lazySelections)) {
        return this.lazySelections.findIndex(
            (x) => x["place_id"] === obj["placeId"]
        ) == -1
            ? true
            : false;
      }
      return this.lazySelections["place_id"] == obj["placeId"] ? false : true;
    },
    selectPort(obj) {
      let selectionIndex;
      let placeIndex = this.lazyItems.findIndex(
          (x) => x["place_id"] === obj.placeId
      );
      if (placeIndex == -1) throw new Error("Place index not found in request");

      //Find indexes for port selected and port selected before
      let portIndex = this.lazyItems[placeIndex].airports.findIndex(
          (x) => x._id === obj.portId
      );
      let selectedPortIndex = this.lazyItems[placeIndex].airports.findIndex(
          (x) => x.selected
      );

      //Remove selection when is a different port and there\'s already one selected
      if (
          selectedPortIndex !== -1 &&
          selectedPortIndex != portIndex &&
          !this.hasManyPlaces
      ) {
        this.lazyItems[placeIndex].airports[selectedPortIndex].selected = false;
      }
      if (!this.hasManyPlaces)
        this.lazyItems[placeIndex].airports[portIndex].selected = !this
            .lazyItems[placeIndex].airports[portIndex].selected;

      if (Array.isArray(this.lazySelections) || this.lazySelections.length > 0)
        selectionIndex = this.lazySelections.findIndex(
            (x) => x["place_id"] === obj.placeId
        );

      if (selectionIndex == -1) console.error("Couldn't find selected item");

      if (this.hasManyPlaces)
        this.lazySelections[selectionIndex] = this.lazyItems[placeIndex];
      else this.lazySelections = this.lazyItems[placeIndex];
    },
    selectItem(obj) {
      try {
        // console.log("Selected Place", obj);
        // console.log("Se esta seleccionando")
        let selectedPlace = null;
        let placeIndex = null;
        if (obj.type === "airport") {
          selectedPlace = this.lazyItems.findIndex((x) => x.selected);
          placeIndex = this.lazyItems.findIndex(
              (x) =>
                  x["place_id"] === obj.placeId &&
                  x["airports"][0]["_id"] === obj.portId
          );
        } else {
          selectedPlace = this.lazyItems.findIndex((x) => x.selected);
          placeIndex = this.lazyItems.findIndex(
              (x) => x["place_id"] === obj.placeId
          );
        }

        if (placeIndex === -1)
          throw new Error("Place index not found in request");

        if (!this.hasManyPlaces && selectedPlace !== -1) {
          // console.log('esto entra');
          let portIndex = this.getSelectedPortsIndexes();
          if (portIndex.length > 0) {
            this.lazyItems[selectedPlace].airports[portIndex].selected = false;
          }
          this.lazyItems[selectedPlace].selected = false;
          this.listKey++;
          if (placeIndex === selectedPlace) {
            this.lazySelections = {};
            this.internalSearch = "";
            this.listKey++;
            return;
          }
        }

        if (this.isSelectionUnique(obj)) {
          this.lazyItems[placeIndex].selected = !this.lazyItems[placeIndex]
              .selected;
          let place = this.lazyItems[placeIndex];

          if (place.type == "airport") place.airports[0].selected = true;

          place.fixed_address = this.getPlaceAddress(place);
          place.selected = true;

          this.lazySelections = place;
        }
      } catch (error) {
        throw new Error(
            `There was an error while selecting and object data ${JSON.stringify(
                obj
            )}` + error
        );
      }
    },
    addItem(obj) {
      // console.log(obj)
      let placeIndex = this.items.findIndex(
          (x) => x["place_id"] === obj.placeId
      );
      if (placeIndex == -1) throw new Error("Place index not found in request");

      if (this.isSelectionUnique(obj)) {
        this.lazyItems[placeIndex].selected = !this.lazyItems[placeIndex]
            .selected;
        let place = this.items[placeIndex];
        place.fixed_address = this.getPlaceAddress(place);
        this.lazySelections.push(place);
      }
      if (obj.portId) this.selectPort(obj);
      if (this.hasManyPlaces) {
        this.address = "";
      }
    },
    removeItem(placeId) {
      const index = this.lazySelections.findIndex(
          (x) => x["place_id"] === placeId
      );
      const itemIndex = this.items.findIndex((x) => x["place_id"] === placeId);

      if (index == -1) throw new Error("Chip could not be deleted");
      if (itemIndex != -1) this.items[itemIndex].selected = false;

      this.lazySelections.splice(index, 1);
    },
    cityUnselected(placeId) {
      let placeIndex = this.items.findIndex((x) => x["place_id"] === placeId);
      if (placeIndex == -1) {
        throw new Error("Place index not found in request");
      }
      this.items[placeIndex].selected = false;

      let selectionIndex = this.lazySelections.findIndex(
          (x) => x["place_id"] === placeId
      );
      this.lazySelections.pop(selectionIndex);

      // this.setMenuPosition()
    },

    searchPlaces: debouncePromise(async function (text) {
      try {
        if (!this.isLoading) {
          this.isLoading = true;
        }
        let predictions = await new Promise((resolve) => {
          try {
            this.autocomplete.getPlacePredictions(
                {
                  input: text,
                  componentRestrictions: {country: this.restrictToCountries},
                },
                (predictions, status) => {
                  const autocompleteError =
                      status != window.google.maps.places.PlacesServiceStatus.OK;
                  if (autocompleteError) {
                    throw new Error("Could not retrieve places information");
                  }
                  resolve(predictions);
                }
            );
            // console.log('Get place predictions')
          } catch (error) {
            // Retry till it works fuck it
            // this.searchPlaces(text);
            throw new Error(error);
          }
        });

        let results = await this.mapPredictions(predictions);
        results = results.filter(
            (res) => res.details.address_components.length > 2
        );
        // console.log("Results",results)

        predictions = null;

        let selectedPlace = this.lazyItems.findIndex((x) => x.selected);

        if (selectedPlace !== -1) {
          // console.log('esto entra');
          let portIndex = this.getSelectedPortsIndexes();
          if (portIndex.length > 0) {
            this.lazyItems[selectedPlace].airports[portIndex].selected = false;
          }
          this.lazyItems[selectedPlace].selected = false;
          this.lazySelections = {};
          this.listKey++;
        }

        // if (Array.isArray(this.lazySelections)) {
        //   this.lazySelections.forEach(elem => {
        //     let position = results.findIndex(x => x['place_id'] === elem.place_id)
        //     if (position != -1) {
        //       results[position] = elem
        //     }
        //   })
        // } else {
        //   let index = results.findIndex(x => x['place_id' === this.lazySelections['place_id']])
        //   results[index] = this.lazySelections
        // }
        // console.log("Results", results)

        this.lazyItems = [...results];
        // console.log("Search Result")
        // console.log([...results]);
        // console.log(this.lazyItems);
        this.isLoading = false;
      } catch (error) {
        throw new Error(error);
      }
    }, 1000),
    mapPredictions: async function (predictions) {
      // console.log("Predictions",predictions)
      let results = [];

      for (const item of predictions) {
        try {
          if (predictions.length > 0 && this.transportation == "SEA") {
            item.details = await this.placeGeoData(item["place_id"]);
            const country = item.details.address_components.filter(
                (value) => value.types.findIndex((a) => a === "country") !== -1
            );
            if (country !== -1) {

              let ports = await this.getSeaport(country[0].short_name, {
                lat: item.details.location.lat,
                lon: item.details.location.lng,
              });
              //
              // if(!ports.data){
              //   ports = await this.getClosestSeaports(country[0].short_name, {
              //     lat: item.details.location.lat,
              //     lon: item.details.location.lng
              //   })
              // }

              item.type = "city";
              item.airports = ports.data.map((item) => {
                return {...item, selected: false};
              });
              item.selected = false;
              item.open = false;

              results.push(new Promise((resolve) => resolve(item)));

              let city = item.details.address_components.filter(
                  (value) =>
                      value.types.findIndex((a) => a === "locality") !== -1 ||
                      value.types.findIndex(
                          (a) => a === "administrative_area_level_1"
                      ) !== -1
              );
              let port = ports.data.filter((x) => {
                if (
                    city.findIndex(
                        (c) =>
                            x.city.toLowerCase().normalize() ===
                            c["long_name"].toLowerCase().normalize()
                    ) !== -1
                )
                  return true;
                return false;
              });

              if (port && port.length > 0) {
                let portData = {
                  ...item,
                  description: `${port[0].city}, ${port[0].country}`,
                  place_id: item.place_id + "_port",
                  type: "port",
                  ports: [port[0]],
                };
                results.push(new Promise((resolve) => resolve(portData)));
              }
            }
          } else if (predictions.length > 0 && this.transportation == "AIR") {
            item.details = await this.placeGeoData(item["place_id"]);
            // console.log(item);
            const country = item.details.address_components.filter(
                (value) => value.types.findIndex((a) => a === "country") !== -1
            );
            // console.log(country);


            if (this.filterRoute == 2) {
              let airports = await this.getAirPorts(country[0].short_name, {
                lat: item.details.location.lat,
                lon: item.details.location.lng,
              });
              console.log('airports', airports)
              item.selected = false;
              airports.data.forEach(airport => {
                let airportData = {
                  ...item,
                  description: `${airport.city}, ${airport.country}`,
                  'place_id': item.place_id + "_airport_" + airport._id,
                  type: 'airport',
                  airports: [airport]
                }
                results.push(new Promise(resolve => resolve(airportData)))
              })

            }

            if (this.filterRoute == 1) {
              let airports = await this.getAirPorts(country[0].short_name, {
                lat: item.details.location.lat,
                lon: item.details.location.lng,
              });

              airports.data = airports.data.filter((ap) => ap.city !== "");

              // console.log(airports)

              // if(!airports.data){
              //   airports = await this.getClosestSeaports(country[0].short_name, {
              //     lat: item.details.location.lat,
              //     lon: item.details.location.lng
              //   })
              // }

              item.type = "city";
              item.airports = airports.data.map((item) => {
                return {...item, selected: false};
              });
              item.selected = false;
              item.open = false;
              item.page = 1;
              item.loadingMore = false;

              results.push(new Promise((resolve) => resolve(item)));

              /*let first5 = airports.data.slice(0, 5);

              first5.forEach((airport) => {
                let airportData = {
                  ...item,
                  description: `${airport.city}, ${airport.country}`,
                  place_id: item.place_id + "_airport_" + airport._id,
                  type: "airport",
                  airports: [airport],
                };
                results.push(new Promise((resolve) => resolve(airportData)));
              });*/

              // let city = item.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality') !== -1 || (value.types.findIndex(a => a === 'administrative_area_level_1') !== -1))
              // let airport = airports.data.filter(x => {
              //   if (city.findIndex(c => x.city.toLowerCase().normalize() === c['long_name'].toLowerCase().normalize()) !== -1)
              //     return true;
              //   return false;
              // })
              //
              // if (airport && airport.length > 0) {
              //   let airportData = {-
              //     ...item,
              //     description: `${airport[0].city}, ${airport[0].country}`,
              //     'place_id': item.place_id + '_airport',
              //     type: 'airport',
              //     airports: [airport[0]]
              //   }
              //   // console.log(airportData);
              //   results.push(new Promise(resolve => resolve(airportData)))
              // }
            }


          } else {
            throw new Error("Not supported method");
          }
        } catch (error) {
          throw new Error(
              "There was an error while mapping item: " +
              JSON.stringify(item) +
              "\n" +
              error
          );
        }
      }

      return await Promise.all(results);
    },
    placeGeoData: async function (placeId) {
      try {
        return await new Promise((resolve) => {
          this.geocoder.geocode(
              {placeId},
              ([{address_components, geometry}], status) => {
                const autocompleteError =
                    status != window.google.maps.GeocoderStatus.OK;
                if (autocompleteError) {
                  throw new Error(
                      "There was an error while retrieving data from autocomplete" +
                      status
                  );
                }
                resolve({
                  address_components: address_components,
                  location: {
                    lat: geometry.location.lat(),
                    lng: geometry.location.lng(),
                  },
                });
              }
          );
        });
      } catch (error) {
        throw new Error(`Error while getting geodata \n` + error);
      }
    },
    getSeaport: async function (country, coordinates) {
      try {
        return await this.$httpPlacesApi
            .get(
                `/api/ports/seaport/country/${country}/location/${coordinates.lat},${coordinates.lon}`
            )
            .catch((error) => console.error(error));
      } catch (error) {
        throw new Error(`Error while getting seaport data ${error}`);
      }
    },
    getAirPorts: async function (country, coordinates, page = 1) {
      try {
        return await this.$httpPlacesApi
            .get(
                `/api/ports/airport/country/${country}/location/${coordinates.lat},${coordinates.lon}`,
                {
                  params: {
                    page: page,
                    limit: 50,
                  },
                }
            )
            .catch((error) => console.error(error));
      } catch (error) {
        throw new Error(`Error while getting seaport data ${error}`);
      }
    },
    // When selected, get the lat/lng info and merge with original selection
    onSelected() {
      this.$emit("textFieldSelected", {});
    },
    onClicked(placeData, selectedPort) {
      try {
        this.isMenuOpen = false;
        this.inputIcon =
            placeData.airports.length > 1 ? "mdi-city" : "mdi-airport";

        let allPorts = [];
        placeData.airports.forEach((port) => {
          port.selected = port._id == selectedPort._id;
          allPorts.push(port);
        });

        placeData.airports = allPorts;
        this.$emit("placeSelected", placeData);
      } catch (e) {
        console.error(e);
      }
    },
    addSeaPortsToPredictions: async function () {
      try {
        // let response = [];
        // for(const pred of this.predictions){
        //   let geocode = null;
        //   let ports = null;
        //   await this.placeGeoData(pred.place_id)
        //     .then(([{address_components, geometry}]) => {
        //       if(!address_components || !geometry) throw new Error('Couldn\'t get data from place id ' + pred.place_id)
        //       geocode = {
        //         address_components,
        //         location: {
        //           lat: geometry.location.lat(), lng: geometry.location.lng()
        //         }
        //       }
        //   });
        //   if(!geocode){ throw new Error("There'Pricing not geodata"); }
        //
        //   const country = geocode.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)
        //   await this.getSeaPorts(country[0].short_name, { lat: geocode.location.lat, lon: geocode.location.lng }).then(data => ports = data)
        //
        //   const result = {
        //     name: pred.structured_formatting.main_text,
        //     address: pred.description,
        //     placeId: pred.place_id,
        //     placeDetails: geocode,
        //     ports: ports
        //   }
        //
        //   response.push(result)
        // }
        //
        // this.items = response
        // this.isLoading = false;
      } catch (error) {
        console.error(error);
      }
    },
    getItems() {
      return this.items;
    },
    getSelections() {
      return this.lazySelections;
    },
    // setItems(items) {
    //   console.log('Setting items')
    //   // this.items =  items;
    // },
    // setSelections(selections){
    //   console.log('Changing selections');
    //   console.log(selections);
    //
    //   this.selections = selections;
    // },
  },
  mounted() {
    try {
      this.$nextTick(() => {
        this.internalSearch = this.search;
        this.listKey++;
        let $_autocomplete = this.$refs[`autocomplete_${this.id}`];
        if ($_autocomplete) $_autocomplete.deleteCurrentItem = () => {
        };
      });
    } catch (error) {
      throw new Error("Error while deleting event from autocomplete" + error);
    }
    this.$emit('filterRoute', this.filterRoute)
  },
  directives: {
    "click-outside": {
      bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          if (!(el == event.target || el.contains(event.target || event))) {
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
      },
      unbind: function (el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
      },
    },
  },
};
</script>

<style scoped>
.inputSelected {
  background-color: #fff;
  top: 0;
  z-index: 3;
  width: 500px;
  transition: width 4s;
  box-shadow: 0px 0px 1em rgba(0, 0, 0, 0.18);
}

.v-list-group--active.s-list-group-active {
  background-color: rgba(2, 77, 152, 0.2);
}

.s-list--active {
  background-color: rgba(2, 77, 152, 0.2);
}
.v-application .mx-2 {
  margin-right: 0px !important;
  margin-left: 0px !important;
}
</style>
