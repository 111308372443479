<template>
  <div>
    <v-row justify="center">
      <v-col md="10">
        <v-card color="primary">
          <v-card-title class="justify-center offset-1">

            <h2 class="font-weight-light white--text">
               Places
            </h2>
          </v-card-title>
          <v-tabs
              v-model="tab"
              grow
          >

            <v-tab>Direcciones</v-tab>
            <v-tab>Puertos</v-tab>


          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-stepper
                  v-model="e6"
                  vertical
              >
                <v-stepper-step
                    :complete="e6 > 1"
                    step="1"
                    @click="e6=1"
                >
                 Seleccione un País

                </v-stepper-step>

                <v-stepper-content step="1">
                  <v-container>
                    <v-row>
                      <v-col md="3">
                        <v-autocomplete return-object v-model="countrySelected" :items="Country" item-value="id" item-text="name" label="País"></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-stepper-content>

                <v-stepper-step
                    :complete="e6 > 2"
                    step="2"
                    @click="e6=2"
                >
                  Seleccione un Estado
                </v-stepper-step>

                <v-stepper-content step="2">
                  <v-container>
                    <v-row>
                      <v-col md="3">
                        <v-autocomplete  disabled v-model="countrySelected" :items="Country" item-value="id" item-text="name" label="País"></v-autocomplete>
                      </v-col>
                      <v-col md="3">
                        <v-autocomplete v-model="stateSelected" :items="States" item-value="id" item-text="name" label="Estado"></v-autocomplete>
                        <small class="font-weight-regular  primary--text" @click="dialog=true">¿No existe el estado?</small>
                      </v-col>
                    </v-row>
                  </v-container>

                </v-stepper-content>

                <v-stepper-step
                    :complete="e6 > 3"
                    step="3"
                    @click="e6=3"
                >
                  Añadir Ciudad
                </v-stepper-step>

                <v-stepper-content step="3">
                  <v-container>
                    <v-row>
                      <v-col md="3">
                        <v-autocomplete  disabled v-model="countrySelected" :items="Country" item-value="id" item-text="name" label="País"></v-autocomplete>
                      </v-col>
                      <v-col md="3">
                        <v-autocomplete disabled v-model="stateSelected" :items="States" item-value="id" item-text="name" label="Estado"></v-autocomplete>
                      </v-col>
                      <v-col md="3">
                        <div class="row no-gutters position-relative">
                          <div class="col-4 fixed-col-size">
                            <googleMapsAutocompleteVuetify
                                label="Origen"
                                :search="originAddress"
                                :items="originItems"
                                :selections="origin"
                                transportation="SEA"
                                placeholder="Ingrese un origen"
                                @change="originSelected"
                                @updateAddress="updateOriginAddress"
                                @updateItems="updateOriginItems"
                                :isOpen="true"
                                :id="1"
                            >
                            </googleMapsAutocompleteVuetify>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                    <v-btn  color="primary" small >Aceptar</v-btn>
                  </v-container>
                </v-stepper-content>

              </v-stepper>
            </v-tab-item>

            <v-tab-item>
              <v-stepper
                  v-model="e7"
                  vertical
              >
                <v-stepper-step
                    :complete="e7 > 1"
                    step="1"
                    @click="e7=1"
                >
                  Seleccione un País

                </v-stepper-step>

                <v-stepper-content step="1">
                  <v-container>
                    <v-row>
                      <v-col md="3">
                        <v-autocomplete return-object v-model="countrySelected" :items="Country" item-value="id" item-text="name" label="País"></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-stepper-content>

                <v-stepper-step
                    :complete="e7 > 2"
                    step="2"
                    @click="e7=2"
                >
                  Seleccione un Estado
                </v-stepper-step>

                <v-stepper-content step="2">
                  <v-container>
                    <v-row>
                      <v-col md="3">
                        <v-autocomplete  disabled v-model="countrySelected" :items="Country" item-value="id" item-text="name" label="País"></v-autocomplete>
                      </v-col>
                      <v-col md="3">
                        <v-autocomplete v-model="stateSelected" :items="States" item-value="id" item-text="name" label="Estado"></v-autocomplete>
                        <small class="font-weight-regular  primary--text" @click="dialog=true">¿No existe el estado?</small>
                      </v-col>
                    </v-row>
                  </v-container>

                </v-stepper-content>

                <v-stepper-step
                    :complete="e7 > 3"
                    step="3"
                    @click="e7=3"
                >
                  Añadir Ciudad
                </v-stepper-step>

                <v-stepper-content step="3">
                  <v-container>
                    <v-row>
                      <v-col md="3">
                        <v-autocomplete  disabled v-model="countrySelected" :items="Country" item-value="id" item-text="name" label="País"></v-autocomplete>
                      </v-col>
                      <v-col md="3">
                        <v-autocomplete disabled v-model="stateSelected" :items="States" item-value="id" item-text="name" label="Estado"></v-autocomplete>
                      </v-col>
                      <v-col md="3">
                        <div class="row no-gutters position-relative">
                          <div class="col-4 fixed-col-size">
                            <googleMapsAutocompleteVuetify
                                label="Origen"
                                :search="originAddress"
                                :items="originItems"
                                :selections="origin"
                                transportation="SEA"
                                placeholder="Ingrese un origen"
                                @change="originSelected"
                                @updateAddress="updateOriginAddress"
                                @updateItems="updateOriginItems"
                                :isOpen="true"
                                :id="1"
                            >
                            </googleMapsAutocompleteVuetify>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                    <v-btn  color="primary" small >Aceptar</v-btn>
                  </v-container>
                </v-stepper-content>

              </v-stepper>
            </v-tab-item>
          </v-tabs-items>




        </v-card>


      </v-col>
    </v-row>

    <v-dialog v-model="dialog" width="650">
      <v-toolbar
          dense
          dark
          class="primary white--text"
      >
        <h2 class="font-weight-regular"><v-icon left size="25">mdi-home-city-outline</v-icon>Añadir Estado</h2>
        <v-spacer></v-spacer>
        <v-btn
            icon
            dark
            @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card>
        <v-container>
          <v-row>
            <v-col md="6">
              <v-autocomplete  disabled v-model="countrySelected" :items="Country" item-value="id" item-text="name" label="País"></v-autocomplete>
            </v-col>
            <v-col md="6">

              <div class="row no-gutters position-relative">
                <div class="col-4 fixed-col-size">
                  <googleMapsAutocompleteVuetify
                      label="Origen"
                      :search="originAddress"
                      :items="originItems"
                      :selections="origin"
                      transportation="SEA"
                      placeholder="Ingrese un origen"
                      @change="originSelected"
                      @updateAddress="updateOriginAddress"
                      @updateItems="updateOriginItems"
                      :isOpen="true"
                      :id="1"
                  >
                  </googleMapsAutocompleteVuetify>
                </div>
              </div>
            </v-col>

            <v-btn  color="primary" small class="ml-3">Aceptar</v-btn>

          </v-row>
        </v-container>
        <br>
      </v-card>
    </v-dialog>



  </div>
</template>

<script>
import googleMapsAutocompleteVuetify from "@/components/google-maps-autocomplete-vuetify/index";

export default {
  name: "Places",
  components:{
    googleMapsAutocompleteVuetify

  },
  watch:{
    countrySelected: function () {
      this.GetState(this.countrySelected.iso2);
      this.e6 = 2;
    },
    stateSelected:function (){
      this.e6 = 3;
    }
  },
  data:()=>{
   return{
     tab: null,
     dialog:false,
     origin: {},
     Places:{
       city:null,
       state:null,
       country:null
     },
     originAddress: "",
     originItems: [],
     countrySelected:null,
     Country:[],
     stateSelected:[],
     States:[],
     e6:1,
     e7:1,
     items: [
       'web', 'shopping',
     ],
     text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',

   }
  },
  methods:{
    GetCountry: function () {
      this.$store.dispatch('working', true);
      this.$http.get('Countries')
          .then(response => {
            if(response.data.code == 200){
              this.Country = response.data.data;
            }

            if (response.data.code == 400 || response.data.code == 500)
              this.$toast.error("Algo salio mal, intente mas tarde")
          })
          .catch(error => {
            if(error.response.status == 401){
              this.$store.commit('reset');
              this.$toast.error("La sesion expiro");
              this.$router.replace('/login')
            }
            console.log(error)

          }).finally(() => this.$store.dispatch('working', false))
    },
    updateOriginAddress(text){
      this.originAddress = text
    },
    updateOriginItems(items){
      console.log(items)
      this.originItems = items;
    },
    originSelected(selections) {
      try {
        console.log(selections)
        this.origin = selections
        this.$nextTick(() => {
          try {
            if(!Array.isArray(this.origin)) {
              if(this.origin.details){
                this.Places.city = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality') !== -1)[0].long_name
                 this.Places.state= this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0].long_name
                this.Places.country =  this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
              }
              //allert(this.Places.state)

            }
          }
          catch(e){
            throw new Error(`There was an error while selecting the item ${JSON.stringify(selections)} \n` + e)
          }
        })
      } catch(error){
        console.log(error)
        throw new Error(`There was an error while selecting a new origin ${JSON.stringify(selections)} \n` + error)
      }
    },

    GetState: function (code) {
      console.log(code);
      this.$store.dispatch('working', true);
      this.$http.get(`States/Country/${code}`)
          .then(response => {
            if(response.data.code == 200){
              this.States = response.data.data;
            }

            if (response.data.code == 400 || response.data.code == 500)
              this.$toast.error("Algo salio mal, intente mas tarde")
          })
          .catch(error => {
            if(error.response.status == 401){
              this.$store.commit('reset');
              this.$toast.error("La sesion expiro");
              this.$router.replace('/login')
            }

            console.log(error)

          }).finally(() => this.$store.dispatch('working', false))
    },
  },
  mounted() {
    this.GetCountry();
  }
}
</script>
<style scoped lang="scss">

.fixedOption{
  position: fixed;
  z-index: 2;
  bottom: 1em;
  right: 2em;
}

.border-rounded {
  border-radius: 10px;
}

.fixed-col-size{
  min-width: 250px;
}

.searchButton{
  z-index: 2;
}

.position-relative {
  position: relative;
}

.notification{
  cursor: pointer;
}

>>> .container {
  min-width: 100%;
}

</style>

