<template>
  <v-app>
    <v-fade-transition mode="out-in">
      <router-view/>
    </v-fade-transition>
    <VLoading :active="working" :opacity="0.75" style="z-index: 999"></VLoading>
    <div style="position: fixed; bottom: 0; right: 0; z-index: 9999; background-color: #fff; padding: 10px; border-radius: 5px;">
      <span>
        Version
        {{ deployedVersion }}
      </span>
      <div v-if="version !== deployedVersion" style="border-top: 1px solid #000; padding: 10px">
        <button @click="deployVersion">
          There's a new version available.
        </button>
      </div>
    </div>
  </v-app>
</template>


<script>
import {mapGetters} from "vuex"
import VLoading from "@/components/Global/loading/loader";
import { db } from '@/firebase/init'
import { collection, getDocs, limit, orderBy, query } from 'firebase/firestore'

export default {
  name: 'App',
  components: {VLoading},
  computed: {
    ...mapGetters([
      'working'
    ])
  },
  data: () => ({
    version: null,
    deployedVersion: process.env.VUE_APP_DEPLOYED_VERSION
  }),
  /*beforeCreate() {
    console.log('Connecting ')
  },*/
  methods: {
    async deployVersion() {
      //Refresh and remove cache 
      window.location.reload(true)
    },
    async getVersion() {
      try {
        // Get the latest version ordered by created_at
        const q = query(
          collection(db, "deployments"), // Correctly reference the collection
          orderBy("created_at", "desc"), // Order by created_at descending
          limit(1) // Get only the latest document
        );

        console.log("🚀 ~ getVersion ~ query:", q);

        const querySnapshot = await getDocs(q);
        
        if (!querySnapshot.empty) {
          const latestDoc = querySnapshot.docs[0]; // Get the first (latest) document
          console.log("🚀 ~ getVersion ~ latestDoc:", latestDoc.data());

          this.version = latestDoc.data().version;
        } else {
          console.warn("No deployments found.");
        }
      } catch (error) {
        console.error("Error getting latest version:", error);
      }
    }
  },
  mounted(){
    this.getVersion()
    this.deployedVersion = process.env.VUE_APP_DEPLOYED_VERSION
    console.log("🚀 ~ mounted ~ this.deployedVersion:", this.deployedVersion)
  },
  beforeDestroy(){
    this.$hub.Connection.stop();
  }
};
</script>

<style lang="scss">
  @import './styles/classes';
  @import './index.css';

  html, body {
    min-width: 1200px;
  }

  @media screen and (max-width: 1200px) {
    html, body {
      overflow-x: scroll;
    }

  }

</style>
