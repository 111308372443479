<template>
  <div>
    <v-container fluid class="elevation-0 pt-0">
      <v-dialog
          v-model="dialog"
          width="1200"
          transition="dialog-bottom-transition"
      >
        <template v-slot:activator="{ on, attrs }">

          <v-btn
              tile
              elevation="0"
              outlined
              small
              v-bind="attrs"
              v-on="on"
              color="#cbcbcb"
              height="60px"
              class="pt-0"

          >
            <v-icon color="primary">mdi-comment-alert</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-toolbar
              dark
              color="primary darken-1"
          >
            <v-btn
                icon
                dark
                @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Crear Embarque</v-toolbar-title>
          </v-toolbar>
          <!--        <v-list
                      three-line
                      subheader
                  >
                    <v-subheader>Informacion General</v-subheader>
                      <v-row class="ml-1 mr-2">
                        <v-col cols="2">
                          <v-autocomplete
                              label="Cliente"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="2">
                          <v-autocomplete
                              label="Agente en origen"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="2">
                          <v-autocomplete
                              label="Proveedor"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="2">
                          <v-select
                              :items="items"
                              label="Servico"
                          ></v-select>
                        </v-col>
                        <v-col cols="2">
                          <v-select
                              :items="items"
                              label="Impo-expo"
                          ></v-select>
                        </v-col>
                        <v-col cols="2">
                          <v-select
                              :items="items"
                              label="Incoterm"
                          ></v-select>
                        </v-col>
                      </v-row>
                  </v-list>
                  <v-divider></v-divider>
                  <v-list
                      three-line
                      subheader
                  >
                    <v-subheader>Destinos</v-subheader>
                    <v-row class="ml-1 mr-2">
                      <v-col cols="2">
                        <v-autocomplete
                            label="Origen"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="2">
                        <v-autocomplete
                            label="POL"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="2">
                        <v-autocomplete
                            label="POD"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="2">
                        <v-select
                            :items="items"
                            label="Destination"
                        ></v-select>
                      </v-col>
                      <v-col cols="2">
                        <v-select
                            :items="items"
                            label="Fecha salida"
                        ></v-select>
                      </v-col>
                      <v-col cols="2">
                        <v-select
                            :items="items"
                            label="Fecha llegada"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-list>
                  <v-divider></v-divider>
                  <v-list
                      three-line
                      subheader
                  >
                    <v-subheader>Poroducto</v-subheader>
                    <v-row class="ml-1 mr-2">
                      <v-col cols="2">
                        <v-autocomplete
                            label="Commodity"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="2">
                        <v-autocomplete
                            label="Peligroso?"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="2">
                        <v-autocomplete
                            label="Servicos adicionales?"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-list>
                  <v-divider></v-divider>
                  <v-list
                      three-line
                      subheader
                  >
                    <v-subheader>Servicio</v-subheader>
                    <v-row class="ml-1 mr-2">
                      <v-col cols="2">
                        <v-autocomplete
                            label="Qty"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="2">
                        <v-autocomplete
                            label="Tipo contenedor"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="2">
                        <v-autocomplete
                            label="Bultos"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="2">
                        <v-select
                            :items="items"
                            label="Peso"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-list>
                  <v-divider></v-divider>
                  <v-list
                      three-line
                      subheader
                  >
                    <v-row class="ml-2 mr-2">
                      <v-col>
                        <v-card>
                          <v-subheader>Compra</v-subheader>

                        </v-card>
                      </v-col>

                      <v-col>
                        <v-card>
                          <v-subheader>Venta</v-subheader>
                        </v-card>
                      </v-col>
                    </v-row>

                  </v-list>
                  <v-row class="mt-4 mr-4" style="float: right">
                    <v-btn
                        depressed
                        color="primary"
                    >
                      Crear
                    </v-btn>
                  </v-row>--><!--        <v-list
            three-line
            subheader
        >
          <v-subheader>Informacion General</v-subheader>
            <v-row class="ml-1 mr-2">
              <v-col cols="2">
                <v-autocomplete
                    label="Cliente"
                ></v-autocomplete>
              </v-col>
              <v-col cols="2">
                <v-autocomplete
                    label="Agente en origen"
                ></v-autocomplete>
              </v-col>
              <v-col cols="2">
                <v-autocomplete
                    label="Proveedor"
                ></v-autocomplete>
              </v-col>
              <v-col cols="2">
                <v-select
                    :items="items"
                    label="Servico"
                ></v-select>
              </v-col>
              <v-col cols="2">
                <v-select
                    :items="items"
                    label="Impo-expo"
                ></v-select>
              </v-col>
              <v-col cols="2">
                <v-select
                    :items="items"
                    label="Incoterm"
                ></v-select>
              </v-col>
            </v-row>
        </v-list>
        <v-divider></v-divider>
        <v-list
            three-line
            subheader
        >
          <v-subheader>Destinos</v-subheader>
          <v-row class="ml-1 mr-2">
            <v-col cols="2">
              <v-autocomplete
                  label="Origen"
              ></v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                  label="POL"
              ></v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                  label="POD"
              ></v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-select
                  :items="items"
                  label="Destination"
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-select
                  :items="items"
                  label="Fecha salida"
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-select
                  :items="items"
                  label="Fecha llegada"
              ></v-select>
            </v-col>
          </v-row>
        </v-list>
        <v-divider></v-divider>
        <v-list
            three-line
            subheader
        >
          <v-subheader>Poroducto</v-subheader>
          <v-row class="ml-1 mr-2">
            <v-col cols="2">
              <v-autocomplete
                  label="Commodity"
              ></v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                  label="Peligroso?"
              ></v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                  label="Servicos adicionales?"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-list>
        <v-divider></v-divider>
        <v-list
            three-line
            subheader
        >
          <v-subheader>Servicio</v-subheader>
          <v-row class="ml-1 mr-2">
            <v-col cols="2">
              <v-autocomplete
                  label="Qty"
              ></v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                  label="Tipo contenedor"
              ></v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                  label="Bultos"
              ></v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-select
                  :items="items"
                  label="Peso"
              ></v-select>
            </v-col>
          </v-row>
        </v-list>
        <v-divider></v-divider>
        <v-list
            three-line
            subheader
        >
          <v-row class="ml-2 mr-2">
            <v-col>
              <v-card>
                <v-subheader>Compra</v-subheader>

              </v-card>
            </v-col>

            <v-col>
              <v-card>
                <v-subheader>Venta</v-subheader>
              </v-card>
            </v-col>
          </v-row>

        </v-list>
        <v-row class="mt-4 mr-4" style="float: right">
          <v-btn
              depressed
              color="primary"
          >
            Crear
          </v-btn>
        </v-row>-->
          <v-row justify="center">
            <v-col md="11">
            <orderCreation @coloseModal = "coloseModal()"/>
            </v-col>
<!--            <v-col md="7">
             <compraCreation/>
            </v-col>-->

<!--            <v-col md="4">
              <ventaCreation/>
            </v-col>-->



          </v-row>


        </v-card>
      </v-dialog>
    </v-container>


  </div>
</template>

<script>
import orderCreation from "./orderCreation.vue"
//import compraCreation from "./compraCreation.vue"
/*import ventaCreation from "./ventaCreation.vue"*/
/*
import load_detail from "../../../../components/Quote/QuotDetail/load_detail";
*/
export default {
  name: "shipmentCreation",
  components: {orderCreation, /*ventaCreation*/ },
  data() {
    return {
      dialog: false,
      icon: null,
      notifications: false,
      sound: true,
      widgets: false,
    }
  },
  methods: {
    coloseModal(){
      this.dialog = false
    },

  }
}
</script>

<style scoped>

</style>
