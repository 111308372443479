<template>
  <v-row>
    <v-col cols="12">
      <v-tabs
          v-model="tab"
          background-color="white"
          icons-and-text
          centered
          color="grey"
          height="150"
      >
        <v-tab href="#provisional" @click="getOrders('', 1)">
          <CardStatus>
            <template v-slot:card-icon>
              mdi-battery-clock-outline
            </template>
            <template v-slot:card-title>
              Provisional
            </template>
            <template v-slot:QuantityStatus>
              <h1 class="font-weight-regular pt-3 text-center primary--text">
                {{ counts.provisional }}
              </h1>
            </template>
          </CardStatus>
        </v-tab>
        <v-tab href="#enTransito" @click="getOrders('', 2)">
          <CardStatus>
            <template v-slot:card-icon>
              mdi-transit-connection-horizontal
            </template>
            <template v-slot:card-title>
              En Tránsito
            </template>
            <template v-slot:QuantityStatus>
              <h1 class="font-weight-regular pt-3 text-center primary--text">
                {{ counts.inTransit }}
              </h1>
            </template>
          </CardStatus>
        </v-tab>
        <v-tab href="#arribados" @click="getOrders('', 3)">
          <CardStatus>
            <template v-slot:card-icon>
              mdi-email-send-outline
            </template>
            <template v-slot:card-title>
              Arribados
            </template>
            <template v-slot:QuantityStatus>
              <h1 class="font-weight-regular pt-3 text-center primary--text">
                {{ counts.arrived }}
              </h1>
            </template>
          </CardStatus>
        </v-tab>
        <v-tab href="#detenidos" @click="getOrders('', 4)">
          <CardStatus>
            <template v-slot:card-icon>
              mdi-stop-circle-outline
            </template>
            <template v-slot:card-title>
              Detenidos
            </template>
            <template v-slot:QuantityStatus>
              <h1 class="font-weight-regular pt-3 text-center primary--text">
                {{ counts.detained }}
              </h1>
            </template>
          </CardStatus>
        </v-tab>
        <v-tab href="#entregados" @click="getOrders('', 5)">
          <CardStatus>
            <template v-slot:card-icon>
              mdi-check-all
            </template>

            <template v-slot:card-title>
              Entregados
            </template>

            <template v-slot:QuantityStatus>
              <h1 class="font-weight-regular pt-3 text-center primary--text">
                {{ counts.delivered }}
              </h1>
            </template>
          </CardStatus>
        </v-tab>
        <v-tab href="#cerradas" @click="getOrders('', 6)">
          <CardStatus>
            <template v-slot:card-icon>
              mdi-umbrella-closed-outline
            </template>
            <template v-slot:card-title>
              Cerradas
            </template>

            <template v-slot:QuantityStatus>
              <h1 class="font-weight-regular pt-3 text-center primary--text">
                {{ counts.closed }}
              </h1>
            </template>
          </CardStatus>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" style="width: 100%">
        <v-row justify="center" class="mt-3 ">
          <v-col md="7">
            <v-text-field
                outlined
                v-model="search"
                append-icon="mdi-magnify"
                dense
                label="Buscar"
                single-line
                hide-details
                @input="SearchOrder(search)"
            >
              <template v-slot:append>
                <v-menu
                    offset-y
                    content-class="included"
                    :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                            @click="triggerServices"
                            icon
                            small
                            class="mx-1"
                            v-bind="attrs"
                            v-on="{ ...menu, ...tooltip }"
                        >
                          <v-icon>mdi-tune</v-icon>
                        </v-btn>
                      </template>
                      Buscar por...
                    </v-tooltip>
                  </template>
                  <v-card width="300">
                    <v-card-text>
                      <h2 class="font-weight-regular">Filtrar</h2>
                      <v-row>
                        <v-col md="12">
                          <v-row justify="center">
                            <v-col md="12">
                              <v-autocomplete
                                  label="Cliente"
                                  v-model="filterCustomer"
                                  :items="customers"
                                  dense
                                  item-value="id"
                                  item-text="name"
                                  clearable
                              ></v-autocomplete>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col md="12">
                          <v-row justify="center">
                            <v-col md="12">
                              <v-autocomplete
                                  label="Incoterm"
                                  v-model="filterIncoterm"
                                  :items="incoterms"
                                  item-text="code"
                                  item-value="id"
                                  dense
                                  clearable
                              ></v-autocomplete>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn text @click="filterOrders" color="primary">Buscar</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </template>
            </v-text-field>
          </v-col>
        </v-row>

        <div>
          <v-data-table
              :headers="headersOrders"
              :hide-default-footer="true"
              :items="itemsOrders.data"
              :items-per-page="17"
              :search="search"
          >
            <template v-slot:item.view="{ item }">
              <v-btn text ripple @click="seeDetail(item.id)">
                <v-icon color="primary">mdi-notebook-outline</v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <v-pagination
              class="mb-4"
              v-model="currentPage"
              :length="itemsOrders.meta.totalPages"
              @next="getOrdersP(true, true)"
              @previous="getOrdersP(true, false)"
          ></v-pagination>
        </div>
        <v-tab-item value="provisional"></v-tab-item>
      </v-tabs-items>

    </v-col>
  </v-row>
</template>

<script>
import CardStatus from "@/components/Sales/List/Card-Status";
import debouncePromise from "debounce-promise";

export default {
  name: "operationList2",
  components: {CardStatus},
  data: () => ({
    status: 1,
    currentPage: 1,
    dataFilter:[],
    filterIncoterm: null,
    filterCustomer: null,
    filterStatus: null,
    customers: [],
    incoterms: [],
    counts: {},
    filterBy: {},
    search: "",
    itemsOrders: [],
    options: {
      page: 1,
      itemsPerPage: 25,
      pageStart: 0,
      pageStop: 0,
      pageCount: 0,
      itemsLength: 0
    },
    headersOrders: [
      {
        text: "id",
        align: "start",
        sortable: true,
        value: "id",
      },
      {
        text: "Fecha creado",
        value: "createdAt",
      },
      {
        text: "Evento actual",
        value: "",
      },
      {
        text: "Sig saldia",
        value: "",
      },
      {
        text: "Sig llegada",
        value: "",
      },
      {
        text: "ETD",
        value: "departureDate",
      },
      {
        text: "ETA",
        value: "",
      },

      {
        text: "Tipo de transporte",
        value: "transportationId",
      },
      {
        text: "Importacion o expo",
        value: "orderTypeId",
      },
      {
        text: "Numero de referencia",
        value: "refCode",
      },
      {
        text: "Cliente",
        value: "clientId",
      },
      {
        text: "Embarcador",
        value: "clientSupplierName",
      },
      {
        text: "incoterm",
        value: "incoterm.code",
      },
      {
        text: "Detalles",
        value: "view",
      },
    ],
    tab: 0,
  }),
  watch: {
    currentPage: {
      handler(){
        this.$http.get(`shipping/Find?PageSize=17`, {
              params: {
                ShippingStatusId: this.status ,
                PageNumber: this.currentPage
              }
            }
        ).then((response) => {
          console.log(response)
          this.itemsOrders = response.data;
        })
      }
    }
  },
  mounted() {
    this.getOrder();
    this.getCount();

  },
  methods: {
    triggerServices(){
      this.getCustomers();
      this.getIncoterms();
    },
    SearchQuote(searchValue) {
      if (searchValue == "") this.filter = false;
      else if (searchValue != "") {
        let find = this.Data.find((f) => f.folio.includes(searchValue));

        this.aux = [];
        this.aux.push(find);
        this.filter = true;
      }
    },
    getIncoterms() {
      this.$store.dispatch("working", true);

      this.$http
          .get("Incoterms")
          .then((response) => {
            this.incoterms = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => this.$store.dispatch("working", false));
    },

    SearchOrder: debouncePromise(function (e) {
      console.log(e);
      if (e === ''){
        this.$http.get(`shipping/Find?RefCode=`).then((response) => {
          console.log(response);
          this.itemsOrders.data = response.data.data;})
      } else {
        this.getOrders(e);

      }
    }, 1000),
    getOrders(e, status) {
      if (e) {
        this.$http.get(`shipping/Find?RefCode=${e}`).then((response) => {
          console.log(response);
          this.itemsOrders.data = response.data.data;
          this.itemsOrders.meta.totalPages = response.data.meta.totalPages;
        });
      } else {
        this.search = '';

        console.log(status)
        this.status = status
        this.$http.get(`shipping/Find?ShippingStatusId=${status}`,{
          params: {
            PageSize: 17,
          }
        }).then((response) => {
          console.log(response);
          this.itemsOrders.data = response.data.data;
          this.itemsOrders.meta.totalPages = response.data.meta.totalPages;

        });
      }

    },
    getCustomers() {
      this.$http
          .get("Companies")
          .then((response) => {
            console.log(response);
            this.customers = response.data.data;
          })
          .catch((error) => {
            console.log(error)
          })
    },
    seeDetail(id) {
      console.log(id)
      this.$router.push({path: `shipment/${id}`})
    },
    getCount() {
      this.$http.get("Shipping/count").then((response) => {
        console.log(response);
        this.counts = response.data.data;
      });
    },
    getOrdersP(e, NoP) {
      this.$store.dispatch("working", true);
      console.log(e, NoP)
      if (e && NoP) {
        let pageNumber = this.itemsOrders.meta.currentPage
        if (this.itemsOrders.meta.hasNextPage) {
          pageNumber = JSON.parse(JSON.stringify(this.itemsOrders.meta.currentPage + 1))
        }
        console.log('hice este get')
        this.$http
            .get(`shipping/Find?PageSize=17`,
                {
                  params: {
                    PageNumber: pageNumber,
                  }
                }
            )
            .then((response) => {
              console.log(response);
              this.$toast.info("Ordenes filtradas con exito!");
              this.itemsOrders = response.data;

            })
            .catch((error) => {
              console.log(error);
              this.$toast.error("Ocurrio un errorr");
            })
            .finally(() => {
              this.$store.dispatch("working", false);
            });
      }
      if (e && !NoP) {
        let pageNumber = this.itemsOrders.meta.currentPage
        if (this.itemsOrders.meta.hasPreviousPage) {
          pageNumber = JSON.parse(JSON.stringify(this.itemsOrders.meta.currentPage - 1))
        }
        console.log('no deberia entrar')
        this.$http
            .get(`shipping/Find?PageSize=17`,
                {
                  params: {
                    PageNumber: pageNumber,
                  }
                }
            )
            .then((response) => {
              console.log(response);
              this.$toast.info("Ordenes filtradas con exito!");
              this.itemsOrders = response.data;

            })
            .catch((error) => {
              console.log(error);
              this.$toast.error("Ocurrio un errorr");
            })
            .finally(() => {
              this.$store.dispatch("working", false);
            });
      }
      if (e && NoP > 0) {
        console.log('entre correctamente')
      }
    },
    getOrder() {
      this.$http.get("shipping/Find?PageSize=17").then((response) => {
        console.log(response)
        this.itemsOrders = response.data;
      });
      this.getOrders('', 1);

    },
    filterOrders() {
      this.filterBy = {};
      if (this.filterCustomer) {
        this.filterBy.ClientId = this.filterCustomer;
      }

      if (this.filterIncoterm) {
        this.filterBy.IncotermId = this.filterIncoterm;
      }

      this.$store.dispatch("working", true);
      let apiUrl = "shipping/Find";
      this.$http
          .get(apiUrl, {params: this.filterBy})
          .then((response) => {
            console.log(response.data.data);
            this.itemsOrders = response.data.data;
            this.$toast.info("Ordenes filtradas con exito!");
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$store.dispatch("working", false);
          });
    },
  },
};
</script>

<style scoped>
.v-tab /deep/ {
  text-transform: none !important;
}

>>> .v-data-table td {
  padding: 0 20px;
}
</style>
