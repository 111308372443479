<template><div></div></template>
<script>
import Confetti from "vue-confetti/src/confetti.js";
export default {
  data: () => ({
    confetti: new Confetti(),
  }),
  methods: {
    start() {
      this.confetti.start({
        /*particles: [{
          type: 'heart'
        }],
        defaultColors: [
          'red',
          'pink',
          '#ba0000',
        ],*/
        defaultSize: 5,
        /*colors: ["#FF6F61", "#004C83", "#55C6A9", "#FFC82B"],*/
      });
      setTimeout(() => {this.confetti.stop()}, 5000);
    }
  },
};
</script>