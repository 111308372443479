const Place = {
    port: {
        name: String,
        city: String,
        country: String,
        coordinates: String
    }
}

const Fees = {
    quantity: Number,
    currency: String,
    price: Number,
    percentage: Number,
    total: Number,
    isVisible: Boolean
}

const Maritime = {
    supplier: String,
    pricesDestination: [Place],
    pricesOrigins: [Place],
    priceFees: [Fees],
    transportationTime: Number,
    total: Number,
    isAnswered: Boolean,
    suggested: Boolean,
    confirmedPricing: Boolean,
    confirmedSales: Boolean,
}

const Rate = {
    maritime: Maritime
}

export default Rate
