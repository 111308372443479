<template>
  <div>
    <div class="tw-grid tw-grid-cols-[40%_auto]">
      <section class="tw-px-2">
        <v-form>
          <span class="tw-text-xs">Grupos</span>
          <v-chip-group
            v-model="selectedGroups"
            multiple
            show-arrows
          >
            <v-chip v-for="group in items.pricerGroups" :key="`chip-group-${group.groupName}`" x-small :value="group.groupName">
              {{ group.groupName }}
            </v-chip>
          </v-chip-group>
          <v-autocomplete
            :items="users"
            ref="users"
            v-model="selectedUsers"
            :item-text="item => item.fullName"
            :item-value="item => item.userId"
            label="Selecciona Usuarios"
            class="tw-text-sm"
            multiple
            :rules="userRules"
          >
            <template slot="prepend-item">
              <v-checkbox
                v-model="selectAllUsers"
                @change="() => {
                  if (selectAllUsers) {
                    selectedUsers = users.map(item => item.userId)
                  } else {
                    selectedUsers = []
                  }
                }"
                label="Seleccionar Todos"
              ></v-checkbox>
            </template>
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0" x-small>
                <span>{{ item.fullName }}</span>
              </v-chip>
              <span
                v-if="index === 1"
                class="grey--text text-caption"
              >
                (+{{ selectedUsers.length - 1 }} others)
              </span>
            </template>
          </v-autocomplete>
        </v-form>
        <div class="tw-flex tw-justify-between tw-items-center">
          <div>
            <v-menu
              v-model="isDatePickerOpen"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  ref="date"
                  label="Selecciona fecha"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="dateRules"
                  class="tw-text-sm"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                @input="isDate = false"
                range
              ></v-date-picker>
            </v-menu>
          </div>
          <div>
            <v-btn-toggle
              v-model="groupByPeriod"
              tile
              group
            >
              <v-btn value="DAY" x-small>
                Día
              </v-btn>

              <v-btn value="WEEK" x-small>
                Semana
              </v-btn>

              <v-btn value="MONTH" x-small>
                Mes
              </v-btn>

              <v-btn value="YEAR" x-small>
                Año
              </v-btn>

            </v-btn-toggle>
          </div>
        </div>
        <div class="tw-border-t">
          <v-data-table
            :items= "pricingOrders.orders"
            :headers="headersOrders"
            :items-per-page="15"
            class="tw-text-xs"
            hide-default-footer
          >
            <template v-slot:item.refCode="{ item }">
              <div class="tw-w-[70px]">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                      class="tw-text-xs tw-truncate"
                    >{{ item.refCode }}</span>
                  </template>
                  <span class="tw-text-xs">{{ item.refCode }}</span>
                </v-tooltip>

              </div>
            </template>
          </v-data-table>
          <v-pagination
            v-model="pageNumber"
            :length="totalPages"
            :total-visible="7"
            circle
          ></v-pagination>
        </div>
      </section>
      <section>
        <section class="tw-grid tw-grid-cols-[25%_auto]">
          <div class="tw-border tw-border-gray-400 tw-justify-between">
            <div class="tw-border-b tw-border-gray-400 tw-h-1/2 tw-pb-2 tw-px-1 tw-overflow-y-scroll">
              <v-list>
                <v-subheader class="tw-text-sm tw-font-semibold">Usuarios</v-subheader>
                <v-list-item-group
                  v-model="selectedOrderUsers"
                  multiple
                >
                  <v-list-item
                    v-for="user in pricingAnalytics.perUser"
                    :key="user.userId"
                    :value="user.userId"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="!tw-text-xs">{{ user.fullName }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-badge
                        color="primary"
                        overlap
                        class="tw-mr-2"
                      >
                        <template v-slot:badge>
                          <span class="!tw-text-xs">{{ user.total }}</span>
                        </template>
                      </v-badge>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </div>
            <div class=" tw-h-1/2 tw-px-1">
              <v-list>
                <v-subheader class="tw-text-sm tw-font-semibold">Estatus</v-subheader>
                <v-list-item-group
                  v-model="selectedOrderStatus"
                  multiple
                >
                  <v-list-item
                  v-for="{status, total} in pricingAnalytics.perStatus"
                    :key="status"
                    :value="status"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="!tw-text-xs">{{ orderStatus[status] }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-badge
                        color="primary"
                        overlap
                        class="tw-mr-2"
                      >
                        <template v-slot:badge>
                          <span class="!tw-text-xs">{{ total }}</span>
                        </template>
                      </v-badge>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </div>
          </div>
          <div class="tw-min-h-screen">
            <div class="tw-flex tw-items-center tw-h-full" v-show="isAnalyticsLoading">
              <v-progress-circular
                indeterminate
                size="64"
                class="tw-mx-auto"
                color="primary"
              >
              </v-progress-circular>
            </div>
            <div v-show="!isAnalyticsLoading">
              <apexchart
                type="area"
                height="350"
                :options="chartOptions"
                :series="ordersPerPeriod"
                ref="ordersPerPeriodChart"

              >
              </apexchart>
              <apexchart
                type="area"
                height="350"
                :options="orderSentChartOptions"
                :series="orderSentPerPeriod"
                ref="orderSentPerPeriodChart"

              >
              </apexchart>
              <apexchart
                type="area"
                height="350"
                :options="orderSoldChartOptions"
                :series="orderSoldPerPeriod"
                ref="orderSoldPerPeriodChart"

              >
              </apexchart>
            </div>
          </div>
        </section>
      </section>
    </div>

  </div>
</template>

<script>
import axios from "axios"
import moment from "moment"

var ccAnalyticsApi = axios.create({
  baseURL: `${process.env.VUE_APP_CONTINENTE_GRAPHQL}`
});

export default {
  name: "PricingDashboard",
  data() {
    return {
      pageNumber: 1,
      selectedUsers: [],
      selectedOrderUsers: [],
      selectedOrderStatus: [],
      selectedGroups: [],
      groups: [],
      pricingStatus: {
        2: 'Pendiente',
        3: 'Solicitada',
        4: 'Enviada',
        5: 'Incompleta',
        6: 'Enviada a Cliente',
        7: 'Renegociar',
        8: 'Vendida',
        9: 'Cancelada',
        10: 'Rechazada',
      },
      hello: '',
      isDatePickerOpen: false,
      groupByPeriod: 'DAY',
      date: [],
      chartOptions: {
        chart: {
          type: 'area',
          height: 350,
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },

        title: {
          text: 'Cotizaciones Creadas',
          align: 'left'
        },
        subtitle: {
          text: 'Periodo',
          align: 'left'
        },
        labels: [],
        xaxis: {
          type: 'datetime',
        },
        yaxis: {
          opposite: true
        },
        legend: {
          horizontalAlign: 'left'
        }
      },
      orderSentChartOptions: {
        chart: {
          type: 'area',
          height: 350,
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },

        title: {
          text: 'Cotizaciones Enviadas',
          align: 'left'
        },
        subtitle: {
          text: 'Periodo',
          align: 'left'
        },
        labels: [],
        xaxis: {
          type: 'datetime',
        },
        yaxis: {
          opposite: true
        },
        legend: {
          horizontalAlign: 'left'
        }
      },
      orderSoldChartOptions: {
        chart: {
          type: 'area',
          height: 350,
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },

        title: {
          text: 'Cotizaciones Vendidas',
          align: 'left'
        },
        subtitle: {
          text: 'Periodo',
          align: 'left'
        },
        labels: [],
        xaxis: {
          type: 'datetime',
        },
        yaxis: {
          opposite: true
        },
        legend: {
          horizontalAlign: 'left'
        }
      },
      selectAllUsers: false,
      items: {
        pricerGroups: []
      },
      headersOrders:[
        {
          text: 'Folio',
          align: 'start',
          sortable: false,
          value: 'refCode',
          width: '50px',
          cellClass: '!tw-text-xs tw-truncate'
        },
        {
          text: 'Estatus',
          align: 'start',
          sortable: false,
          value: 'status',
          cellClass: '!tw-text-xs'
        },
        {
          text: 'Vendedor',
          align: 'start',
          sortable: false,
          value: 'salesManager',
          cellClass: '!tw-text-xs'
        },
        {
          text: 'Fecha Creación',
          align: 'start',
          sortable: true,
          value: 'createdAt',
          cellClass: '!tw-text-xs'
        }
      ],
      pricingOrders: {
        orders: [],
        total: 10,
        status: ''
      },
      selectedStatus: '',
      orderStatus: {
        'PENDING': 'Pendiente',
        'REQUESTED': 'Solicitada',
        'SENT': 'Enviada',
        'TO_CONFIRM': 'Por Confirmar',
        'CONFIRMED': 'Confirmada',
      },
      pricingAnalytics: {
        perUser: [],
        perStatus: [],
        perPeriod: []
      },
      ordersPerPeriod: [
        {
          name: 'Cotizaciones',
          data: []
        }
      ],
      orderSentPerPeriod: [
        {
          name: 'Cotizaciones',
          data: []
        }
      ],
      orderSoldPerPeriod: [
        {
          name: 'Cotizaciones',
          data: []
        }
      ],
      chartPeriods: [],
      userRules: [
        v => {
          if (v.length < 1) return 'Debes seleccionar al menos un usuario'
          return true
        }
      ],
      dateRules: [
        v => {
          if (!v) return 'Debes seleccionar una fecha'
          return true
        }
      ],
      isAnalyticsLoading: false
    }
  },
  methods: {
    fetchData(query) {
      return new Promise((resolve, reject) => {
        ccAnalyticsApi.post('',
          JSON.stringify({ query })
        , {
          headers: {
            'content-type': 'application/json',
          },
        })
        .then(({ data, errors }) => {
          if(errors) reject(errors)
          resolve(data.data)
        })
        .catch(error => reject(error))
      })
    },
    async getPricerGroups () {
      const { pricerGroups } = await this.fetchData(`
        query Get {
            pricerGroups{
              groupName
              users{
                fullName
                userId
              }
            }
        }
      `)
      this.items.pricerGroups = pricerGroups
    },
    async getPricingOrders() {
      const { pricingOrders } = await this.fetchData(this.pricingOrdersQuery)

      this.pricingOrders = {
        ...pricingOrders,
        orders: pricingOrders.orders.map(item => ({
          ...item,
          createdAt: moment(item.createdAt).format('yyyy/MM/DD'),
          status: this.pricingStatus[item.statusId]
        }))
      }
    },
    async getPricingAnalytics() {
      this.isAnalyticsLoading = true
      const { pricingAnalytics } = await this.fetchData(`${this.pricingAnalyticsQuery}`)

      this.pricingAnalytics = pricingAnalytics
      this.isAnalyticsLoading = false

      const [perPeriod, overallPerPeriod, overallPerPeriodLabels] = this.fillChartDataDates(this.pricingAnalytics.perPeriod, this.pricingAnalytics.overallPerPeriod, 'date')
      const [sentPerPeriod, overallSentPerPeriod, sentPerPeriodLabels] = this.fillChartDataDates(this.pricingAnalytics.sentPerPeriod, this.pricingAnalytics.overallSentPerPeriod, 'date')
      const [soldPerPeriod, overallSoldPerPeriod, soldPerPeriodLabels] = this.fillChartDataDates(this.pricingAnalytics.soldPerPeriod, this.pricingAnalytics.overallSoldPerPeriod, 'date')

      this.chartOptions.labels = new Array(...overallPerPeriodLabels)
      this.orderSentChartOptions.labels = new Array(...sentPerPeriodLabels)
      this.orderSoldChartOptions.labels = new Array(...soldPerPeriodLabels)

      this.$refs.ordersPerPeriodChart.updateOptions({ labels: this.chartOptions.labels })
      this.$refs.orderSentPerPeriodChart.updateOptions({
        labels: this.orderSentChartOptions.labels
      })
      this.$refs.orderSoldPerPeriodChart.updateOptions({
        labels: this.orderSoldChartOptions.labels
      })


      this.ordersPerPeriod = [
        {
          name: 'Total Cotizaciones - Global',
          data: overallPerPeriod.map(item => item.total)
        },
        {
          name: 'Cotizaciones Recidas',
          data: perPeriod.map(item => item.total)
        }
      ]
      this.orderSentPerPeriod = [
        {
          name: 'Total Cotizaciones Enviadas - Global',
          data: overallSentPerPeriod.map(item => item.total)
        },
        {
          name: 'Cotizaciones Creadas',
          data: sentPerPeriod.map(item => item.total)
        }
      ]
      this.orderSoldPerPeriod = [
        {
          name: 'Total Cotizaciones Vendidas - Global',
          data: overallSoldPerPeriod.map(item => item.total)
        },
        {
          name: 'Cotizaciones Vendidas',
          data: soldPerPeriod.map(item => item.total)
        },
      ]

      this.$refs.ordersPerPeriodChart.updateSeries(this.ordersPerPeriod)
      this.$refs.orderSentPerPeriodChart.updateSeries(this.orderSentPerPeriod)
      this.$refs.orderSoldPerPeriodChart.updateSeries(this.orderSoldPerPeriod)
    },
    fillChartDataDates(firstArr, secondArr, key){
      const firstArrLabels = new Set([...firstArr.map(item => item[key])])
      const secondArrLabels = new Set([...secondArr.map(item => item[key])])
      const allLabels = new Array(...new Set([...firstArrLabels, ...secondArrLabels])).sort((a, b) => new Date(a) - new Date(b))

      const missingLabelsFirstArr = new Array(...secondArrLabels).filter(item => !firstArrLabels.has(item))
      const missingLabelsSecondArr = new Array(...firstArrLabels).filter(item => !secondArrLabels.has(item))

      missingLabelsFirstArr.forEach(item => {
        firstArr.push({
          [key]: item,
          total: 0
        })
      })

      missingLabelsSecondArr.forEach(item => {
        secondArr.push({
          [key]: item,
          total: 0
        })
      })

      firstArr = firstArr.sort((a, b) => new Date(a[key]) - new Date(b[key]))
      secondArr = secondArr.sort((a, b) => new Date(a[key]) - new Date(b[key]))

      return [firstArr, secondArr, allLabels]
    }
  },
  computed: {
    users() {
      return [].concat(...this.items.pricerGroups.map(item => item.users))
    },
    formatedDate () {
      return this.date ? [moment(this.date[0]).format('yyyy/MM/DD'), moment(this.date[1]).format('yyyy/MM/DD')] : ''
    },
    pricingAnalyticsQuery () {
      if(this.selectedUsers.length < 1 || !this.formatedDate[0] || !this.groupByPeriod) return ''

      return `query GetPricingAnalytics {
        pricingAnalytics(args: {
          users: [${this.selectedUsers}],
          startDate: "${this.formatedDate[0]}",
          endDate: "${this.formatedDate[1]}",
          period: ${this.groupByPeriod}
        }) {
          perUser {
            fullName
            userId
            total
          }
          perStatus {
            status
            total
          }
          perPeriod {
            date
            total
          }
          sentPerPeriod {
            date
            total
          }
          soldPerPeriod {
            date
            total
          }
          overallSoldPerPeriod {
            date
            total
          }
          overallSentPerPeriod {
            date
            total
          }
          overallPerPeriod {
            date
            total
          }
        }
      }`
    },
    totalPages() {
      return Math.ceil(this.pricingOrders.total / 15)
    },
    pricingOrdersQuery() {
      return `
        query GetPricingOrders{
          pricingOrders(args: {
            pageNumber: ${this.pageNumber},
            pageSize: 15,
            startDate: "${this.formatedDate[0]}",
            endDate: "${this.formatedDate[1]}",
            users: [${this.selectedOrderUsers}],
            ${this.selectedOrderStatus ? `status: [${this.selectedOrderStatus}],` : ''}
          }){
            orders{
              id
              refCode
              createdAt
              statusId
              salesManager
            }
            total
            status
          }
        }
      `
    }
  },
  watch: {
    selectedUsers(){
      this.selectedOrderUsers = this.selectedUsers
    },
    pricingAnalyticsQuery: {
      handler: function (val) {
        if(val) {
          this.getPricingAnalytics().then(() => {
            this.pageNumber = 1
            this.getPricingOrders()
          })
        }
      },
      deep: true
    },
    pricingOrdersQuery: {
      handler: function (val) {
        if(val) {
          this.getPricingOrders()
        }
      },
      deep: true
    },
    selectedOrderUsers(){
      this.pageNumber = 1
    },


    selectedGroups: {
      handler: function (val) {
        const groups = this.items.pricerGroups.find(x => val.includes(x.groupName))
        this.selectedUsers = [...new Set([].concat(this.selectedUsers, ...groups.users.map(item => item.userId)))]
      },
      deep: true
    },
  },
  beforeMount() {
    ccAnalyticsApi.defaults.headers['Authorization'] = `Bearer ${this.$store.getters.token}`;
  },
  async mounted() {
    await this.getPricerGroups()
    this.$refs.ordersPerPeriodChart.updateSeries(this.ordersPerPeriod)
  },
  errorCaptured: (err, vm, info) => {
    console.log("🚀 ~ file: PricingDashboard.vue:111 ~ err, vm, info:", err, vm, info)
  },
  props: {
  }
};
</script>

<style scoped>
  >>> .v-pagination li button {
    font-size: 0.75rem;
  }
</style>