<template>
  <!--    <div>
      <v-row class="mt-1">
          <v-col cols="3">
            <v-text-field
                append-icon="mdi-magnify"
                label="Buscar Empleado"
                class="ml-3 mr-3"
            ></v-text-field>
&lt;!&ndash;            <row class="d-flex flex-row-reverse">
              <v-btn
                  class="mb-2 mr-2 ml-1"
                  color="error"
                  block
              >
                Limitar Usuario
              </v-btn>
            </row>&ndash;&gt;
            <template>
              <v-data-table
                  v-model="selected"
                  dense
                  :headers="headers"
                  :items="CountExpierd"
                  item-key="name"
                  show-select
                  class="elevation-1"
              >
                <template v-slot:top>
                </template>
              </v-data-table>
            </template>
          </v-col>
        <v-col cols="9">
          <v-card>
            <row>
              <v-text-field
                  class="mt-4 ml-3 mr-3"
                  append-icon="mdi-magnify"
                  label="Buscar Grupo"
              ></v-text-field>
            </row>
            <div class="d-flex flex-wrap">
              <div  v-for="supplier in suppliers" :key="supplier.id">
                <div>


                  <v-card
                      width="462"
                      class=" mr-4 mb-4 elevation-1"

                  >
                    <v-divider></v-divider>
                    <v-card-title class="pb-0 mb-4">
                      <v-icon color="primary">
                        mdi-account-multiple
                      </v-icon>
                      <span class="font-weight-regular ml-2">
                      <b>{{ supplier.groupName }}</b>
                    </span>
                    </v-card-title>

                    &lt;!&ndash;              <v-card-text>
                                        <span
                                            class="font-weight-regular d-block black&#45;&#45;text"
                                        >
                                          Id: {{ supplier.groupId }}
                                        </span>
                                  </v-card-text>&ndash;&gt;
                    <v-divider
                    ></v-divider>
                    <v-row class="ml-5" v-for="(employee, index) in supplier.employees" :key="index">
                      <br>
                      <v-col md="7">
                        <v-row>
                          <v-icon  class="mt-8" :style="employee.count.received <= 0 ? 'color:green' : 'color:red'">
                            mdi-account-box
                          </v-icon>
                          <v-card-title class="pb-0 mt-4">
                            <b :style="employee.count.received <= 0 ? 'color:green' : 'color:red'">{{employee.employeeName}}</b>
                          </v-card-title>
                          <v-card-text class="ml-6">
                            {{employee.employeeRole}}
                          </v-card-text>
                        </v-row>

                      </v-col>
                      <v-col md="5">
                        Pendiente Pricing: <b :style="employee.count.open <= 0 ? 'color:green' : 'color:red'">{{employee.count.open}}</b>
                        <br>
                        Enviado a ventas: <b :style="employee.count.received <= 0 ? 'color:green' : 'color:red'">{{employee.count.received}}</b>
                        <br>
                        Enviada a cliente: <b :style="employee.count.send >= 0 ? 'color:green' : 'color:red'">{{employee.count.send}}</b>

                      </v-col>

                    </v-row>
                  </v-card>
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>-->

  <div>
    <v-row>
      <v-col cols="12">
        <v-card class="mt-6 ml-4 mr-4">
          <v-row>
            <v-col cols="4" class=" mt-2" style="text-align-last: center;">
              <v-row style="justify-content: center;">
                <h2 style="color:orangered">Compras: ${{ dataAdmin.purchases_by_user_total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</h2>
                <h2 style="color:orangered" class="ml-4">Ventas: ${{ dataAdmin.sale_by_user_total.toLocaleString((undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })) }}</h2>
              </v-row>
              <h2 class="mt-2" style="color:#48d07c;">Profit : ${{ dataAdmin.profit_by_user_total.toLocaleString((undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })) }}</h2>
            </v-col>
            <v-col cols="2" class="mt-4" v-if="this.$store.getters.UserId === 22">
              <v-select
                  :items="formattedEmployees"
                  item-text="name"
                  item-value="id"
                  v-model="Employee"
                  dense
                  label="Usuario"
              ></v-select>
            </v-col>
            <v-col v-else cols="2"></v-col>
            <v-col cols="2" class="mt-4">
              <v-autocomplete
                  :items="itemsClientes"
                  item-text="name"
                  item-value="id"
                  v-model="company"
                  dense
                  label="Cliente"
              ></v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="dates"
                      label="Selecciona una Rango"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="dates"
                    no-title
                    scrollable
                    range
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="menu = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>

            </v-col>
            <v-col cols="2" style="text-align-last: end">
              <v-btn @click="getDataAdmin" class="mt-6 primary">
                Buscar
              </v-btn>
              <v-btn @click="clearData" class="mt-6 ml-2 mr-2" style="color:orangered">
                Limpiar
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="ml-3 mr-3">

     <v-col cols="7">
       <v-row style="justify-content: right;">
         <v-btn @click="downloadDocument"  color="success" class=" mt-4">
           <v-icon
               left
               dark
           >
             mdi-file-excel
           </v-icon>
           Exportar
         </v-btn>
       </v-row>
       <v-row class="pt-4 pb-4" >
         <v-col cols="12">
           <v-card>
             <div>
               <v-row>
                 <v-card
                     class="mx-auto mt-4 mb-4"
                     max-width="300"
                 >
                   <v-list-item two-line>
                     <v-list-item-content>
                       <v-list-item-title class="text-h5">
                         Profits por Categoria
                       </v-list-item-title>
                       <v-row class="mt-1 ml-2">
                         <div>
                           <v-list-item-subtitle>Importacion</v-list-item-subtitle>
                           <v-list-item-subtitle style="color:darkgrey">${{ dataAdmin.profit_by_user_impo.toLocaleString((undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })) }}</v-list-item-subtitle>
                         </div>
                         <div class="ml-2">
                           <v-list-item-subtitle>Exportacion</v-list-item-subtitle>
                           <v-list-item-subtitle style="color:darkgrey">${{ dataAdmin.profit_by_user_expo.toLocaleString((undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })) }}</v-list-item-subtitle>
                         </div>
                         <div class="ml-2">
                           <v-list-item-subtitle>Nacional</v-list-item-subtitle>
                           <v-list-item-subtitle style="color:darkgrey">${{dataAdmin.profit_by_user_national.toLocaleString((undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })) }}</v-list-item-subtitle>
                         </div>
                       </v-row>
                     </v-list-item-content>
                   </v-list-item>

                   <v-card-text>
                     <v-row align="center">
                       <v-col
                           class="text-h5"
                           cols="8"
                       >
                         ${{ dataAdmin.profit_by_user_total.toLocaleString((undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })) }}
                       </v-col>
                       <v-col cols="4">
                         <v-img
                             src="../../../assets/7314637.png" width="92"
                         ><img/></v-img>
                       </v-col>
                     </v-row>
                   </v-card-text>

                 </v-card>
                 <v-card
                     class="mx-auto mt-4 mb-4"
                     max-width="300"
                 >
                   <v-list-item two-line>
                     <v-list-item-content>
                       <v-list-item-title class="text-h5">
                         Profits por Transporte
                       </v-list-item-title>
                       <v-row class="mt-1 ml-2">
                         <div>
                           <v-list-item-subtitle>Maritimo</v-list-item-subtitle>
                           <v-list-item-subtitle style="color:darkgrey">
                             ${{ dataAdmin.profit_by_user_maritime.toLocaleString((undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })) }}
                           </v-list-item-subtitle>
                         </div>
                         <div class="ml-2">
                           <v-list-item-subtitle>Aéreo</v-list-item-subtitle>
                           <v-list-item-subtitle style="color:darkgrey">${{dataAdmin.profit_by_user_aerial.toLocaleString((undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })) }}</v-list-item-subtitle>
                         </div>
                         <div class="ml-2">
                           <v-list-item-subtitle>Terrestre</v-list-item-subtitle>
                           <v-list-item-subtitle style="color:darkgrey">${{dataAdmin.profit_by_user_land.toLocaleString((undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })) }}</v-list-item-subtitle>
                         </div>
                       </v-row>
                     </v-list-item-content>
                   </v-list-item>

                   <v-card-text>
                     <v-row align="center">
                       <v-col
                           class="text-h5"
                           cols="8"
                       >
                         ${{ dataAdmin.profit_by_user_total.toLocaleString((undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })) }}
                       </v-col>
                       <v-col cols="4">
                         <v-img
                             src="../../../assets/7314637.png" width="92"
                         ><img/></v-img>
                       </v-col>
                     </v-row>
                   </v-card-text>

                 </v-card>
               </v-row>
             </div>
           </v-card>
         </v-col>
       </v-row>
       <v-card class="mt-2 ">
         <v-row class="mt-3">
           <v-card
               class="mx-auto mt-4"
               max-width="300"
           >
             <v-list-item two-line>
               <v-list-item-content>
                 <v-list-item-title class="text-h5">
                   Embarques por Categoria
                 </v-list-item-title>
                 <v-row class="mt-1 ml-2">
                   <div>
                     <v-list-item-subtitle>Importacion</v-list-item-subtitle>
                     <v-list-item-subtitle style="color:darkgrey">{{dataAdmin.shipments_by_user_impo}}</v-list-item-subtitle>
                   </div>
                   <div class="ml-2">
                     <v-list-item-subtitle>Exportacion</v-list-item-subtitle>
                     <v-list-item-subtitle style="color:darkgrey">{{ dataAdmin.shipments_by_user_expo }}</v-list-item-subtitle>
                   </div>
                   <div class="ml-2">
                     <v-list-item-subtitle>Nacional</v-list-item-subtitle>
                     <v-list-item-subtitle style="color:darkgrey">{{ dataAdmin.shipments_by_user_national }}</v-list-item-subtitle>
                   </div>
                 </v-row>
               </v-list-item-content>
             </v-list-item>

             <v-card-text>
               <v-row align="center">
                 <v-col
                     class="text-h5"
                     cols="12"
                 >
                   {{dataAdmin.total_shipments}}
                 </v-col>
               </v-row>
             </v-card-text>

           </v-card>
           <v-card
               class="mx-auto mt-4"
               max-width="300"
           >
             <v-list-item two-line>
               <v-list-item-content>
                 <v-list-item-title class="text-h5">
                   Embarques por Transporte
                 </v-list-item-title>
                 <v-row class="mt-1 ml-2">
                   <div>
                     <v-list-item-subtitle>Maritimo</v-list-item-subtitle>
                     <v-list-item-subtitle style="color:darkgrey">{{dataAdmin.shipments_by_user_maritime}}</v-list-item-subtitle>
                   </div>
                   <div class="ml-2">
                     <v-list-item-subtitle>Aéreo</v-list-item-subtitle>
                     <v-list-item-subtitle style="color:darkgrey">{{ dataAdmin.shipments_by_user_aerial }}</v-list-item-subtitle>
                   </div>
                   <div class="ml-2">
                     <v-list-item-subtitle>Terrestre</v-list-item-subtitle>
                     <v-list-item-subtitle style="color:darkgrey">{{ dataAdmin.shipments_by_user_land }}</v-list-item-subtitle>
                   </div>
                 </v-row>
               </v-list-item-content>
             </v-list-item>

             <v-card-text>
               <v-row align="center">
                 <v-col
                     class="text-h5"
                     cols="12"
                 >
                   {{dataAdmin.total_shipments}}
                 </v-col>
               </v-row>
             </v-card-text>

           </v-card>
           <v-card
               class="mx-auto mt-4"
               max-width="300"
           >
             <v-list-item two-line>
               <v-list-item-content>
                 <v-list-item-title class="text-h5">
                   Ventas por Categoria
                 </v-list-item-title>
                 <v-row class="mt-1 ml-2">
                   <div>
                     <v-list-item-subtitle>Importacion</v-list-item-subtitle>
                     <v-list-item-subtitle style="color:darkgrey">${{ dataAdmin.sale_by_user_impo.toLocaleString((undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })) }}</v-list-item-subtitle>
                   </div>
                   <div class="ml-2">
                     <v-list-item-subtitle>Exportacion</v-list-item-subtitle>
                     <v-list-item-subtitle style="color:darkgrey">${{dataAdmin.sale_by_user_expo.toLocaleString((undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })) }}</v-list-item-subtitle>
                   </div>
                   <div class="ml-2">
                     <v-list-item-subtitle>Nacional</v-list-item-subtitle>
                     <v-list-item-subtitle style="color:darkgrey">${{ dataAdmin.sale_by_user_national.toLocaleString((undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })) }}</v-list-item-subtitle>
                   </div>
                 </v-row>
               </v-list-item-content>
             </v-list-item>

             <v-card-text>
               <v-row align="center">
                 <v-col
                     class="text-h5"
                     cols="12"
                 >
                   ${{ dataAdmin.sale_by_user_total.toLocaleString((undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })) }}
                 </v-col>
               </v-row>
             </v-card-text>

           </v-card>
         </v-row>
         <v-row class="pt-4">
           <v-card
               class="mx-auto mt-4 mb-4 "
               max-width="300"
           >
             <v-list-item two-line>
               <v-list-item-content>
                 <v-list-item-title class="text-h5">
                   Ventas por Transporte
                 </v-list-item-title>
                 <v-row class="mt-1 ml-2">
                   <div>
                     <v-list-item-subtitle>Maritimo</v-list-item-subtitle>
                     <v-list-item-subtitle style="color:darkgrey">${{dataAdmin.sale_by_user_maritime.toLocaleString((undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })) }}</v-list-item-subtitle>
                   </div>
                   <div class="ml-2">
                     <v-list-item-subtitle>Aéreo</v-list-item-subtitle>
                     <v-list-item-subtitle style="color:darkgrey">${{ dataAdmin.sale_by_user_aerial.toLocaleString((undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })) }}</v-list-item-subtitle>
                   </div>
                   <div class="ml-2">
                     <v-list-item-subtitle>Terrestre</v-list-item-subtitle>
                     <v-list-item-subtitle style="color:darkgrey">${{dataAdmin.sale_by_user_land.toLocaleString((undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })) }}</v-list-item-subtitle>
                   </div>
                 </v-row>
               </v-list-item-content>
             </v-list-item>

             <v-card-text>
               <v-row align="center">
                 <v-col
                     class="text-h5"
                     cols="12"
                 >
                   ${{ dataAdmin.sale_by_user_total.toLocaleString((undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })) }}
                 </v-col>
               </v-row>
             </v-card-text>

           </v-card>
           <v-card
               class="mx-auto mt-4 mb-4 "
               max-width="300"
           >
             <v-list-item two-line>
               <v-list-item-content>
                 <v-list-item-title class="text-h5">
                   Compras por Categoria
                 </v-list-item-title>
                 <v-row class="mt-1 ml-2">
                   <div>
                     <v-list-item-subtitle>Importación</v-list-item-subtitle>
                     <v-list-item-subtitle style="color:darkgrey">
                       ${{ dataAdmin.purchases_by_user_impo.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                     </v-list-item-subtitle>
                   </div>
                   <div class="ml-2">
                     <v-list-item-subtitle>Exportacion</v-list-item-subtitle>
                     <v-list-item-subtitle style="color:darkgrey">${{ dataAdmin.purchases_by_user_expo.toLocaleString((undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })) }}</v-list-item-subtitle>
                   </div>
                   <div class="ml-2">
                     <v-list-item-subtitle>Nacional</v-list-item-subtitle>
                     <v-list-item-subtitle style="color:darkgrey">${{ dataAdmin.purchases_by_user_national.toLocaleString((undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })) }}</v-list-item-subtitle>
                   </div>
                 </v-row>
               </v-list-item-content>
             </v-list-item>

             <v-card-text>
               <v-row align="center">
                 <v-col
                     class="text-h5"
                     cols="12"
                 >
                   ${{dataAdmin.purchases_by_user_total.toLocaleString((undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })) }}
                 </v-col>
               </v-row>
             </v-card-text>

           </v-card>
           <v-card
               class="mx-auto mt-4 mb-4 "
               max-width="300"
           >
             <v-list-item two-line>
               <v-list-item-content>
                 <v-list-item-title class="text-h5">
                   Compras por Transporte
                 </v-list-item-title>
                 <v-row class="mt-1 ml-2">
                   <div>
                     <v-list-item-subtitle>Maritimo</v-list-item-subtitle>
                     <v-list-item-subtitle style="color:darkgrey">${{dataAdmin.purchases_by_user_maritime.toLocaleString((undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })) }}</v-list-item-subtitle>
                   </div>
                   <div class="ml-2">
                     <v-list-item-subtitle>Aéreo</v-list-item-subtitle>
                     <v-list-item-subtitle style="color:darkgrey">${{dataAdmin.purchases_by_user_aerial.toLocaleString((undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })) }}</v-list-item-subtitle>
                   </div>
                   <div class="ml-2">
                     <v-list-item-subtitle>Terrestre</v-list-item-subtitle>
                     <v-list-item-subtitle style="color:darkgrey">${{dataAdmin.purchases_by_user_land.toLocaleString((undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })) }}</v-list-item-subtitle>
                   </div>
                 </v-row>
               </v-list-item-content>
             </v-list-item>

             <v-card-text>
               <v-row align="center">
                 <v-col
                     class="text-h5"
                     cols="12"
                 >
                   ${{ dataAdmin.purchases_by_user_total.toLocaleString((undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })) }}
                 </v-col>
               </v-row>
             </v-card-text>

           </v-card>
         </v-row>
       </v-card>


     </v-col>
      <v-col cols="5">
        <template>
          <v-row style="justify-content: right;">
            <v-btn @click="downloadDocumentList" color="success" class="mt-4">
              <v-icon
                  left
                  dark
              >
                mdi-file-excel
              </v-icon>
              Exportar
            </v-btn>
          </v-row>
          <v-data-table
              :headers="headers2"
              :items="dataAdmin.order_provision"
              :items-per-page="5"
              class="elevation-1 mt-7"
          ></v-data-table>
        </template>
      </v-col>
    </v-row>


  </div>
</template>

<script>
import fileDownload from "js-file-download";

export default {
  name: "seniorAnalytics",
  data: () => ({
    supplier: {},
    Grupos: [],
    suppliers: [],
    dataAdmin:[],
    itemsClientes:[],
    company:null,
    Employee:null,
    dates: [new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().split('T')[0], new Date().toISOString().split('T')[0]],
    CountExpierd: [],
    Expierd:[],
    singleSelect: false,
    selected: [],
    headers: [
      {
        text: 'Nombre',
        align: 'start',
        sortable: false,
        value: 'userName',
      },
      { text: 'vencidas', value: 'expiredCount' },
    ],
    headers2: [
      {
        text: 'Folio',
        align: 'start',
        sortable: false,
        value: 'order_number',
      },
      { text: 'Status', value: 'shipping_status' },
      { text: 'Cliente', value: 'client_name' },
      { text: 'Ventas', value: 'shipment.total_sale' },
      { text: 'Compra', value: 'shipment.total_purchase' },
      { text: 'Profit', value: 'shipment.profit' },
      { text: 'Pagado', value: 'shipment.total_payed' },
      { text: 'Pendiente', value: 'shipment.pending_balance' },
      { text: 'Dias de credito', value: 'shipment.credit_days' },
    ],
  }),

  computed: {
    formattedEmployees() {
      if (this.Grupos && this.Grupos.employees) {
        return this.Grupos.employees.map(emp => ({
          name: emp.employeeName,
          id: emp.employeeId
        }));
      }
      return [];
    }
  },

  methods: {


    getGrups(){
      this.$http
          .get("Groups")
          .then((response) => {
            this.Grupos = response.data.data;
            console.log(this.Grupos)
          })
          .catch((error) => {
            console.log(error);
          });
    },
    clearData() {
      this.Employee = null; // Restablece el modelo del v-select
      this.company = null; // Restablece el modelo del v-autocomplete
    },
    getGrupeByID: function() {
      this.$store.dispatch('working', true);

      // Determinar el ID del grupo basado en this.$store.getters.UserId
      let groupId;
      if (this.$store.getters.UserId === 22) {
        groupId = 13;
      } else if (this.$store.getters.UserId === 1) {
        groupId = 30;
      } else {
        groupId = 6; // Valor por defecto si no se cumplen las condiciones anteriores
      }

      this.$http
          .get(`Groups/${groupId}`) // Usar el ID del grupo determinado
          .then((response) => {
            this.Grupos = response.data.data;
            this.DialgoGrupData = true;
            console.log(this.Grupos)
          }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.$store.dispatch('working', false);
      });
    },



    getDataAdmin() {
      const dateStart = this.dates[0];
      const dateEnd = this.dates[1];
      const employeeId = this.Employee || this.$store.getters.UserId; // Usar el ID del empleado seleccionado o el valor por defecto

      this.$httpAdminApi(`finance/shipments/employee`, {
        params: {
          start_date: dateStart,
          finish_date: dateEnd,
          employee_id: employeeId, // Aquí se usa la variable modificada
          client_id: this.company,
          consumedByContinent: 1
        }
      }).then((response) => {
        console.log(response, "aaaqqq");
        this.dataAdmin = response.data;
        let profit = 0;
        this.dataAdmin.order_provision.forEach((element) => {
          profit =
              element.shipment.total_sale - element.shipment.total_purchase;
          profit = profit.toFixed(2);
          element.shipment.profit = profit;
        });
      });
    },
    downloadDocument() {
      const dateStart = this.dates[0];
      const dateEnd = this.dates[1];
      const employeeId = this.Employee || this.$store.getters.UserId; // Usar el ID del empleado seleccionado o el valor por defecto
      this.$store.dispatch('working', true);

      this.$httpAdminApi({
        url: `finance/shipments/employee/Export?start_date=${dateStart}&finish_date=${dateEnd}&employee_id=${employeeId}&consumedByContinent=1`, // Modificar la URL
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const fileName = `Report_${dateStart}_to_${dateEnd}.xlsx`;
        fileDownload(response.data, fileName);
      }).catch(error => {
        this.$toast.error("Algo salió mal, intente más tarde");
        console.log(error);
      }).finally(() => {
        this.$store.dispatch('working', false);
      });
    },

    downloadDocumentList() {
      const dateStart = this.dates[0];
      const dateEnd = this.dates[1];
      const employeeId = this.Employee || this.$store.getters.UserId;
      this.$store.dispatch('working', true);

      this.$httpAdminApi({
        url: `finance/shipments/employee/ExportShipments?start_date=${dateStart}&finish_date=${dateEnd}&employee_id=${employeeId}&consumedByContinent=1`,
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const fileName = `Report_${dateStart}_to_${dateEnd}.xlsx`;
        fileDownload(response.data, fileName);
      }).catch(error => {
        this.$toast.error("Algo salió mal, intente más tarde");
        console.log(error);
      }).finally(() => {
        this.$store.dispatch('working', false);
      });
    },

    getClientes() {
      this.$http("Companies")
          .then((response) => {
            this.$store.dispatch("working", true);
            this.itemsClientes = response.data.data;
          })
          .finally(() => this.$store.dispatch("working", false));
    },

    getCurrencies() {
      // this.$store.dispatch("working", true);
      this.$http.get('Orders/group/report').then((response) => {
        if (response.data.code == 200) {
          this.suppliers = response.data.data;
          console.log(this.suppliers)
        }
      });
    },
    getCountExpierd() {
      // this.$store.dispatch("working", true);
      this.$http.get('Orders/expired/count').then((response) => {
        if (response.data.code == 200) {
          this.CountExpierd = response.data.data;
          console.log(this.CountExpierd)
        }
      });
    },
    getExpierd() {
      // this.$store.dispatch("working", true);
      this.$http.get('Orders/expired').then((response) => {
        if (response.data.code == 200) {
          this.Expierd = response.data.data;
          console.log(this.Expierd)
        }
      });
    }

  },
  mounted(){
    this.getCurrencies();
    this.getCountExpierd();
    this.getExpierd();
    this.getDataAdmin();
    this.getClientes();
    this.getGrupeByID()
  },
}



</script>

<style scoped>
.rating-values {
  width: 25px;
}

</style>
