import { render, staticRenderFns } from "./shipment.vue?vue&type=template&id=7e84e9f6&scoped=true"
import script from "./shipment.vue?vue&type=script&lang=js"
export * from "./shipment.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e84e9f6",
  null
  
)

export default component.exports