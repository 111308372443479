let signalR = require("@microsoft/signalr");

class HubChat {
    constructor(url) {
        this.Connection = null;
        this.Url = url;
    }

    async startConnection(token) {
        this.Connection = new signalR.HubConnectionBuilder()
            .withUrl(`${process.env.VUE_APP_API_SIGNAL}chatHub?access_token=${token}`)
            .build();
        console.log(this.Connection)
        await this.Connection.start()
            .then(() => console.log('Chat Connection started'))
            .catch(err => {
                console.error('Error while starting connection: '+ err)
            })
    }
}

export default HubChat;
