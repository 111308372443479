<template>
  <div>

    <TableMenu></TableMenu>

  </div>
</template>
<script>

import TableMenu from "@/components/Pricing/Table-Menu";


export default {
  name: "Table-Menu",
  components:{TableMenu},


  mounted() {

  }

}
</script>

<style scoped>
.v-tab /deep/ {
  text-transform: none !important;
}

>>> .v-data-table td {
  padding: 0 20px;
}
</style>
