<template>
  <div>
    <v-bottom-sheet inset v-model="sheet">
      <v-card>
        <v-toolbar flat dark color="primary">
          <v-toolbar-title><v-icon left>mdi-table-edit</v-icon> Agregar Proveedor </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark left @click="sheet = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <Suppliers></Suppliers>
      </v-card>
    </v-bottom-sheet>
    <!-- Aqui quite el width, estaba en 2000 (por si alguna cosa se rompe)-->
    <v-card elevation="0" tile min-width="2000" class="pt-10">
      <v-row no-gutters justify="center">
        <v-col md="12">
          <v-container style="margin-left: 0px; margin-right: 20px" tile elevation="0">
            <v-row justify="center">
              <v-col md="7">
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    dense
                    solo
                    label="Buscar"
                    single-line
                    hide-details
                ></v-text-field>
              </v-col>
              <v-col md="3">
                <v-btn color="blue darken-2" dark @click="sheet = true">
                  Agregar Proveedor
                  <v-icon right>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <template>
              <v-row justify="center">
                <v-col md="12">
                  <v-container>
                    <v-data-table
                        :headers="HeaderSuppliers"
                        :items-per-page="50"
                        :items="Suppliers"
                        :hide-default-footer="true"
                        item-key="id"
                    >
                    <!-- Data validation -->
                    <template v-slot:item.companyName = {item}>
                        {{item.companyName ? item.companyName : 'NA'}}
                    </template>
                    <template v-slot:item.type.description = {item}>
                        {{item.type.description ? item.type.description : 'NA'}}
                    </template>
                    <template v-slot:item.phone = {item}>
                        {{item.phone ? item.phone : 'NA'}}
                    </template>
                    <template v-slot:item.configuration.creditDays = {item}>
                        {{item.configuration.creditDays  ? item.configuration.creditDays  : 'NA'}}
                    </template>
                    <!-- Data validation -->
                      <template v-slot:item.edit = {item}>
                        <v-btn text @click="GetDataSupplier(item.id)">
                          <v-icon color="primary">mdi-table-edit</v-icon>
                        </v-btn>
                      </template>

                    </v-data-table>
                  </v-container>
                </v-col>
              </v-row>
            </template>
          </v-container>

        </v-col>
      </v-row>
      <EditDialogBase v-bind:show="dialog" v-on:setDialog="dialog=$event">
        <template v-slot:title>
          <v-card-title class="primary white--text"><v-icon color="white" left>mdi-table-edit</v-icon>Editar Proveedor</v-card-title>
        </template>

        <template v-slot:data>
          <!-- <v-card-text>
            {{DataSupplier}}
          </v-card-text> -->
        </template>
      </EditDialogBase>
      <v-dialog v-model="dialog" width="1500">
        <Suppliers @closeDialog="dialog = false"  v-bind:SupplierData="DataSupplier"></Suppliers>
      </v-dialog>
    </v-card>

  </div>
</template>

<script>
import EditDialogBase from "@/components/Users/EditDialogBase";
import Suppliers from '../../components/Accounts_Admin/Suppliers.vue'
import debouncePromise from "debounce-promise";
export default {
name: "List-Suppliers",
  components: {Suppliers, EditDialogBase},
  data(){
    return{
      search:'',
      id:null,
      DataSupplier:[],
      dialog:false,
      sheet: false,
      SuppliersStatus:null,
      HeaderSuppliers:[
        {text:'Company Name', value:'companyName'},
        {text: 'Supplier Type', value: 'type.description'},
        {text: 'Company Phone', value: 'phone'},
        {text:'Supplier Credit Day', value: 'configuration.creditDays'},
        {text: 'Editar',value: 'edit'}
      ],
      Suppliers:[],
    }
  },
  watch: {
    search(val) {
      this.searchSupplier(val)
    },
  },
  methods:{
    getSuppliers:function (){
      this.$store.dispatch('working',true);
      this.$http.get('Suppliers',{
            params: {
              pageSize: 20,
              pageNumber: 1
            },
            headers: {
              'Cache-Control': 'max-age=30'
            }
          }).then(response => {
        console.log(response.data.data);
        this.Suppliers = response.data.data;
        this.SuppliersStatus = this.Suppliers.length
        this.$emit('SuppliersQuantity',this.SuppliersStatus);
      }).catch(error => {
        console.log(error);
      }).finally( () => {
        this.$store.dispatch('working',false);
      })
    },
    GetDataSupplier:function (id) {
      this.dialog = true;
      this.id = id;
      this.$store.dispatch('working',true)

      this.$http.get(`Suppliers/${this.id}`).then(response => {
        console.log(response)
        this.DataSupplier = response.data.data;
        this.id = 0;

      }).catch(error=>{
        console.log(error)
      }).finally(()=>{
        this.$store.dispatch('working',false);
      })
    },
    searchSupplier: debouncePromise(function (search){
      if (search === '') {
        return
      }
      this.getSupplier(search)
    }, 1000),
    getSupplier(search) {
      this.$http.get(`Suppliers/search`,{
            params: {
              companyName: search,
            }
          }
      ).then((response) => {
        this.Suppliers = response.data.data
      })
    }

  },
  mounted() {
    this.getSuppliers();
  }
}
</script>

<style scoped>
>>> .container {
  min-width: 100%;
}
</style>
