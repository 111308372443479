<template>
  <div>
    <v-card elevation="0">
      <v-row justify="center">
        <v-col md="12">
            <v-row justify="center">
            </v-row>
            <template>
              <v-row justify="center">
                <v-col md="12">
                  <v-container>
                      <v-data-table
                        :headers="headers"
                        :items="Data"
                        :hide-default-footer="true"
                        :pagination="options"
                        :items-per-page="25"
                        item-key="name"
                        :search="search"
                    >

                      <template v-slot:expanded-item>
                      </template>

                        <template v-slot:item.urgent="{item}">
                          <v-icon :color="item.urgent?'red':''">mdi-alert</v-icon>
                        </template>

                      <template v-slot:item.dangerous="{item}">
                        {{item.dangerous ?'Si':'No'}}
                      </template>


                        <template v-slot:item.pricerData="{item}">
                          <div v-if="item.pricer != null">
                            {{item.pricer && item.pricer.firstName ?  item.pricer && item.pricer.firstName : "" }} {{item.pricer && item.pricer.lastName ?  item.pricer && item.pricer.lastName : "" }}
                          </div>
                          <div v-else>
                            {{item.salesManager && item.salesManager.firstName ? item.salesManager && item.salesManager.firstName : "" }} {{item.salesManager && item.salesManager.lastName ? item.salesManager && item.salesManager.lastName : "" }}
                          </div>

                        </template>

                      <template v-slot:item.view="{item}">

                        <v-btn  text ripple @click="Proceed(item.id)">

                            <v-icon color="primary">mdi-notebook-outline</v-icon>

                        </v-btn>


                      </template>



                    </v-data-table>

                    <v-pagination
                        v-model="options.page"
                        :length="MetaData.totalPages"
                        @input="GetData()"
                        @next="GetData()"
                        @previous="GetData()"
                    ></v-pagination>


                  </v-container>
                </v-col>
              </v-row>
            </template>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog
        v-model="proceed"
        class="pa-0"
        width="1200"
    >
      <v-card>
       <h1>h</h1>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import folio_mixin from "@/mixins/folio_mixin";

export default {

  name: "Received",
  components:{},
  props:{
    active:Number
  },
  mixins: [folio_mixin],
  data(){
    return{
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        dictDefaultMessage: 'Adjuntar Archivos'

      },
      MetaData:{},

      options:{
        page: 1,
        itemsPerPage: 25,
        pageStart: 0,
        pageStop: 0,
        pageCount: 0,
        itemsLength: 0
      },
      e1:1,
      newFees:false,
      DeNuevo:false,
      Data:[],
      headers: [
        { text: 'Folio', value: 'refCode' },
        {
          text: 'Recibida',
          value: 'createdAt'
        },
        { text: 'Mercancia lista', value: 'departureDate' },
        {
          text: 'Cliente',
          value: 'client.name'
        },
        {
          text: 'Responsable',
          value: 'pricerData'
        },
        {
          text: 'Servicio',
          value: 'orderType.description'
        },
        {
          text: 'Status',
          value: 'statusOrder'
        },
        {
          text: 'Urgente',
          value: 'urgent'
        },
        {
          text: 'Detalles',
          value: 'view'
        },

      ],
      testdialog:false,
      proceed:false,
      search:"",
      idOrder:null
    }
  },
  watch:{
    active:function (){
      this.GetData();
    }
  },
  methods:{
    GetData:function (){
      this.$store.dispatch('working',true);

      this.$http.get('Orders/Received',
          {
            params:{
              PageSize:this.options.itemsPerPage,
              PageNumber:this.options.page,
            }
          }).then(response => {
        if (response.data.code == 200){
          let res = response.data.data;
          this.MetaData = response.data.meta

          this.Data = res.map(item => {
            return{
              ...item,
              departureDate: this.$luxon(item.departureDate, {output: "full"}),
              createdAt: this.$luxon(item.createdAt, {output: "full"}),
              dangerous: item.cargo.filter(x => x.Dangerous).length > 0,
              folio: this.returnFolio(item),
            }
          })
          console.log(this.Data);
          this.QuantityStatus = this.Data.length
          console.log(this.QuantityStatus);
          this.$emit('SaleRequestQuantity',this.QuantityStatus);
        }

        if (response.data.code == 400 || response.data.code == 500){
          this.$toast.error("Algo salio mal, intente mas tarde")
        }

      }).catch(error => {

        if(error.response.status == 401){
          this.$store.commit('reset');
          this.$toast.error("La sesion expiro");
          this.$router.replace('/login')
        }
        console.log(error);
      }).finally(()=>{
        this.$store.dispatch('working',false);
      })
      this.active = 0;

    },

    Proceed:function (id){
      console.log(id)
      this.proceed = true;
      this.idOrder = id;
      this.$router.push({path: `ProceedQuoteSales/${this.idOrder}`})
    },
    UpdateItemData: function(data){
      this.Data.unshift(data);
    }
  },
  mounted() {
    this.GetData();
  }
}
</script>

<style scoped>
>>> .container {
  min-width: 100%;
}

</style>























