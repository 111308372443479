<template>
  <div>
    <v-card width="300" elevation="5" outlined style="border-radius: 10px"  >
      <v-card-text>
        <v-row>
          <v-col md="3">
            <v-icon size="75" color="primary" left><slot name="card-icon"></slot></v-icon>

          </v-col>

          <v-col md="9">
            <slot name="QuantityStatus"></slot>
            <h1 class="font-weight-regular pt-2 text-center primary--text"><slot name="card-title"></slot></h1>
          </v-col>



        </v-row>

      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
name: "Card-Status",
  data(){
    return {
      Quantity:0
    }
  },
  props:{
    CardNumber:Number
  },
  watch:{
    CardNumber:function(val){
      this.CardNumber = val
    }
  }
}
</script>

<style scoped>

</style>
