<template>
  <v-row class="justify-space-between" style="position: relative">
    <v-divider class="v-divider-full"></v-divider>
    <v-col
        cols="3"
        v-for="(route, index) in routes"
        :key="index"
        class="text-center"
    >
      <div style="display: block; height: 64px; margin-bottom: 15px">
        <v-avatar color="primary" size="52">
          <v-icon color="white">{{ route.icon }}</v-icon>
        </v-avatar>
      </div>
      <v-row justify="center">
        <h3 class="text--disabled text-center">{{ route.address }}</h3>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "SenPreviewTimeLine",
  props: {
    prices: Array,
  },
  data: () => ({
    routes: [],
    transportations: [
      {
        transportationId: 1,
        icon: "mdi-ferry",
      },
      {
        transportationId: 2,
        icon: "mdi-airplane",
      },
      {
        transportationId: 3,
        icon: "mdi-truck",
      },
    ],
  }),
  computed: {},
  mounted() {
    console.log("Precios", this.prices);
    this.generateRoute();
  },
  methods: {
    generateRoute() {
      // let lastAddress = null;
      this.prices.forEach((element) => {
        // if (
        //   lastAddress === null ||
        //   element.pricesOrigin[0].cityId !== lastAddress
        // ) {
        //
        // }

        if (element.pricesOrigins[0].port ||
            element.pricesOrigins[0].airports) {
          let route = {};
          if (element.pricesOrigins[0].city !== element.pricesOrigins[0].port?.city &&
              element.pricesOrigins[0].city !== element.pricesOrigins[0].airports?.city) {
            route.icon = "mdi-city-variant-outline";
            route.address = element.pricesOrigins[0].address;
            this.routes.push(route);
          }
          route = {};
          route.icon = this.transportations.find(
              (item) => element.transportationId === item.transportationId
          ).icon;
          route.address = element.pricesOrigins[0].airports?.name??element.pricesOrigins[0].port.name;
          this.routes.push(route);
        } else {
          let route = {};
          route.icon = this.transportations.find(
              (item) => element.transportationId === item.transportationId
          ).icon;
          route.address = element.pricesOrigins[0].address;
          this.routes.push(route);
        }

        if (element.pricesDestination[0].port ||
            element.pricesDestination[0].airports) {
          let route = {};
          route.icon = this.transportations.find(
              (item) => element.transportationId === item.transportationId
          ).icon;
          route.address = element.pricesDestination[0].airports?.name??element.pricesDestination[0].port.name;
          this.routes.push(route);
          if (element.pricesDestination[0].city !== element.pricesDestination[0].port?.city &&
              element.pricesDestination[0].city !== element.pricesDestination[0].airports?.city) {
            let route = {};
            route.icon = "mdi-city-variant-outline";
            route.address = element.pricesDestination[0].address;
            this.routes.push(route);
          }
        } else {
          let route = {};
          route.icon = this.transportations.find(
              (item) => element.transportationId === item.transportationId
          ).icon;
          route.address = element.pricesDestination[0].address;
          this.routes.push(route);
        }
        // lastAddress = element.pricesDestination[0].cityId;
      });
    },
  },
};
</script>

<style scoped>
.v-divider-full {
  position: absolute;
  width: 80%;
  left: 5.5rem;
  top: 2.5rem;
  border: 1px solid #024d98;
}
</style>
