<template>
  <v-card>
    <v-card-text>
      <br>
      <v-textarea
          outlined
          name="input-7-1"
          label="Notas"
          v-model="localTerms"
          @change="handleInput"
      ></v-textarea>
      <v-row>
        <v-col md="2">
          <v-btn color="primary" @click="SendNotes()">
            Guardar Notas
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "PricingNotes",
  props: {
    Id: {
      type: Number,
      required: true
    },
    sendNotes:{
      type:Boolean,
    },
    value: {
      type: String,
    }
  },
  watch: {
    // sendNotes(val){
    //   //allert(val);
    // },
  },
  data: (vm) => ({
    localTerms: vm.value
  }),
  methods:{
    handleInput: function () {
      this.$emit("input",this.localTerms)
    },
    SendNotes: function() {
      this.$http.put(`Orders/${this.Id}/terms`, `"${this.localTerms}"`,
          { headers: {
            'Content-Type': 'application/json'
          }}
        ).then(data => {
        if(data.data.code === 200){
          this.$emit('success')
          this.$toast.info('Terminos actualizados');
        }
        else{
          console.error(data)
          this.$toast.error('Ocurrio un error');
        }
      }).catch(error => {
        console.error(error)
        //allert('Error')
      })
    }
  },
  mounted(){
  }
}
</script>

<style scoped>

</style>
