<template>
  <div>
    <v-text-field label="test" v-model="updateShipmentQ.test1"></v-text-field>
    <h2>elship {{this.updateShipmentQ.test1}}</h2>
  </div>

</template>

<script>
export default {
  data() {
    return{
      updateShipmentQ: {
        test1: '',
      },
    }
  },
}
</script>