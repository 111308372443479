<template>
  <div class="fill-height">
    <v-bottom-sheet inset v-model="sheet">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="blue darken-2" dark right bottom fixed v-bind="attrs" v-on="on" fab>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar flat dark color="primary">
          <v-toolbar-title><v-icon left>mdi-table-edit</v-icon> Agregar Empleado </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark left @click="sheet = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <Employees></Employees>
      </v-card>
    </v-bottom-sheet>
    <v-card elevation="0" tile width="2000" class="pt-10">
      <v-btn @click="GetDataCustomerReporte">
        Descargar reporte
      </v-btn>
      <v-row justify="center">
        <v-col md="12">
          <v-container style="margin-left: 0px; margin-right: 20px" tile elevation="0">
            <v-row justify="center">
              <v-col md="7">
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    dense
                    solo
                    label="Buscar"
                    single-line
                    hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <template>
              <v-row justify="center">
                <v-col md="12">
                  <v-container>
                    <v-data-table
                        :headers="HeaderEmployees"
                        :items-per-page="50"
                        :items="Business"
                        :hide-default-footer="true"
                        item-key="id"
                        :search="search"
                    >
                      <!-- Data validation -->
                      <template v-slot:item.id = {item}>
                        {{item.id ? item.id : 'NA'}}
                      </template>
                      <template v-slot:item.name = {item}>
                        {{item.name ? item.name : 'NA'}}
                      </template>
                      <template v-slot:item.taxNumber = {item}>
                        {{item.taxNumber ? item.taxNumber : 'NA'}}
                      </template>
                      <!-- Data validation -->
                      <template v-slot:item.edit = {item}>
                        <v-btn text @click="GetDataEmployee(item)">
                          <v-icon color="primary">mdi-table-edit</v-icon>
                        </v-btn>
                      </template>

                      <template v-slot:item.status = {item}>
                        <v-icon :color="item.isAvailable ? 'green' : 'red' ">mdi-checkbox-blank-circle</v-icon>
                      </template>

                      <template v-slot:item.employees = {item}>
                        <v-select label="Operador"

                                  multiple
                                  :rules="requiredSelectRule"
                                  v-model="shipment.users"
                                  :items="item.employees.employeeId"
                                  :item-text="item => item.firstName + ' ' + item.lastName"
                                  item-value="id"
                                  dense
                                  class="pt-2"
                        >
                          <template v-slot:selection="{item,index}">
                          <span v-if="index === 0"
                                class="v-select__selection v-select__selection--comma">
                          </span>
                          </template>
                        </v-select>
                      </template>
                    </v-data-table>
                  </v-container>
                </v-col>
              </v-row>
            </template>
          </v-container>
        </v-col>
      </v-row>


    </v-card>
    <v-dialog
    v-model="dialog"
    width="800"
    >
      <v-card>

          <v-card-title class="text-h5 blue darken-4 white--text">
            Empleados
          </v-card-title>
<!--          <v-btn @click="GetDataCustomerReporte">
            Hola
          </v-btn>-->
          <v-card-text>
            <v-select label="Empleados"
                      chips
                      deletable-chips
                      multiple
                      v-model="EmployesSelected"
                      :items="Employees"
                      :item-text="item => item.firstName + ' ' + item.lastName"
                      item-value="id"
                      dense
                      class="mt-4"
            >
            </v-select>
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="dialog = false"
          >
            Cerrar
          </v-btn>
          <v-btn
              color="primary"
              text
              @click="putEmployees()"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Employees from "../../Accounts_Admin/Employees";
var fileDownload = require('js-file-download')
export default {
  name: "List-Employees",
  components:{Employees},
  data(){
    return{
      nombreEmpresa: null,
      employee: null,
      idBusiness: null,
      EmployesSelected: [],
      search:'',
      EmployeesStatus:null,
      DataEmployee:[],
      id:null,
      dialog:false,
      sheet: false,
      HeaderEmployees:[
        { text: 'id', value: 'id' },
        {text: 'Nombre de Empresa', value: 'name',},
        { text: 'RFC', value: 'taxNumber' },
        {text: 'Empleados',value: 'edit'},
        {text: 'Estado',value: 'configuration[0]status'},
        {text: 'Cambiar Status',value: 'changeStatus'},
      ],
      Employees:[],
      Business:[]
    }
  },

  methods:{

    GetDataCustomerReporte(){
      this.$store.dispatch('working',true)
      let obj = {
        startDate: "2022-11-01T22:15:42.467Z",
        endDate: "2022-11-30T22:15:42.467Z",
      }

      this.$http.post(`Shipping/business/${this.idBusiness}/sale/report`, obj ).then(response => {
        console.log(response.data.data);
        fileDownload(response.data, `hola.csv`);
        this.$toast.info('Pdf Generado');


      }).catch(error=>{
        console.log(error)
      }).finally(()=>{
        this.$store.dispatch('working',false);
      })
    },

    putEmployees(){
      this.$store.dispatch('working',true);
      this.GetBusiness()
      let obj = {
        id: this.idBusiness,
        email: "info@Business.com",
        taxNumber: "RFC0000",
        name: this.nombreEmpresa,
        employees: [],
      }
      this.EmployesSelected.forEach((element) => {
        let employees = {
          employeeId: element,
          businessId: this.idBusiness
        }
        obj.employees.push(employees)
      })
      console.log(obj)
      this.$http.put(`Business/${this.idBusiness}`, obj).then((response) => {
        console.log(response)
      }).catch((error) => {
        console.log(error)
      }).finally(()=> {
        setTimeout(() => {
          this.GetBusiness();
          this.$store.dispatch('working',false);
          this.dialog = false
        }, 500)
      })
    },
    getEmployees(){
      this.$store.dispatch('working',true);
      this.$http.get('Employees').then(response => {
        console.log(response.data.data);
        this.Employees = response.data.data;
        this.EmployeesStatus = this.Employees.length
        this.$emit('EmployeesQuantity',this.EmployeesStatus);
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.$store.dispatch('working',false);
      })
    },
    GetDataEmployee(item){
      console.log(item)
      this.nombreEmpresa = item.name
      this.EmployesSelected = []
      this.idBusiness = item.id;
      this.dialog = true
      this.$store.dispatch('working',true);
      this.$http.get(`Business/${item.id}`).then((response) => {
        this.employee = response.data.data
        let aux = response.data.data.employees
        aux.forEach((element) => {
          this.EmployesSelected.push(element.employeeId)
        })
        console.log(response)
      }).catch((error) => {
        console.log(error)
      }).finally(()=> {
        this.$store.dispatch('working',false);
      })
    },
    GetBusiness(){
      this.$store.dispatch('working',true);
      this.$http.get('Business/list').then(response => {
        console.log(response)
        this.Business = response.data.data;
        console.log(this.Business)
        this.$emit('businessQuantity',this.Business.length);
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.$store.dispatch('working',false);
      })
    },

    changeStatus(id,status){
      this.$http.get(`Employees/${id}`).then(response => {
        this.DataEmployee = response.data.data;
        this.updateStatus(this.DataEmployee,status);
      }).catch(error=>{
        console.log(error)
      }).finally(()=>{
        this.$store.dispatch('working',false);
      });

    },
    UpdateCompany(){
      this.dialog = true
    },
  },
  mounted() {
    this.GetBusiness();
    this.getEmployees();
  }
}
</script>

<style scoped>

>>> .container {
  min-width: 100%;
}

</style>
