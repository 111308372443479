<template>
  <div>
    <div v-if="!showRatableWeigth">
      <v-simple-table   :dense="dense">
        <template v-slot:default>
          <v-card
              :disabled="order.statusOrder === 'Vendida' || colorVis"
              :style="{ 'background-color': colorVis ? '#ffed6e' : '' }"
          >



          <thead>
          <th class="text-left"></th>
          <th class="text-left">
            Cargo
          </th>

          <th class="text-left">
            Compra
          </th>

          <th class="text-left">
            Cantidad
          </th>

          <th class="text-left">
            Profit
          </th>

          <th class="text-left">
            Descuento
          </th>

          <th class="text-left" style="padding-left: 23px">
            IVA
          </th>
          <th class="text-left" style="padding-left: 21px">
            RET
          </th>
          <th class="text-left" v-if="order.transportationCategoryId != 1">
            Contenedor
          </th>
          <th class="text-left">
            Moneda
          </th>
          <th class="text-left">
            Total
          </th>
          <th class="text-left"></th>
          </thead>
            <draggable v-model="items" handle=".selectDrag" @change="saveOrder" tag="tbody">
              <tr
                  v-for="(item, feeIndex) in items"
                  :key="
                `${item.quotationDetailId}_${item.orderPriceFeesId}priceFees${feeIndex}`

              "
                  :class="item.isVisible ? '' : 'text--disabled'"
                  class="table-fee-row"
              >
                <td class="table-actions-row">
                  <v-icon class="selectDrag">mdi-drag</v-icon>
                </td>
                <td>
                  <!-- Cargo -->
                  <v-text-field
                      :disabled="item.isVisible ? false : true"

                      v-model="item.name"
                      class="table-fee-input mr-2"
                      hide-details
                      @blur="changeFee(feeIndex)"
                  ></v-text-field>
                </td>
                <!-- Compra -->
                <td>
                  <v-text-field
                      :disabled="item.isVisible ? false : true"
                      v-model.number="item.unitPrice"
                      class="table-fee-input mr-2"
                      hide-details
                      @blur="changeFee(feeIndex)"
                  ></v-text-field>
                </td>
                <!-- Cantidad -->
                <td>
                  <v-text-field
                      :disabled="item.isVisible ? false : true"
                      v-model="item.quantity"
                      @blur="changeFee(feeIndex)"
                      class="table-fee-input mr-2"
                      hide-details
                  >
                  </v-text-field>
                </td>
                <!-- Profit -->
                <td>
                  <v-text-field
                      :disabled="item.isVisible ? false : true"
                      v-model.number="item.profit"
                      @blur="calculateChangeInPrice(feeIndex)"
                      class="table-fee-input mr-2"
                      hide-details
                  ></v-text-field>
                </td>
                <!-- descuet -->
                <td>
                  <v-text-field
                      :disabled="item.isVisible ? false : true"
                      v-model.number="item.discount"
                      @blur="calculateChangeInPrice(feeIndex)"
                      class="table-fee-input mr-2"
                      hide-details
                  >
                  </v-text-field>
                </td>

                <td>
                  <v-checkbox
                      :disabled="!item.isVisible || item.hasRet"
                      v-model="item.hasVat"
                      @change="changeFee(feeIndex)"
                      class="ml-6"
                  ></v-checkbox>
                </td>
                <td>
                  <v-checkbox
                      :disabled="!item.isVisible || item.hasVat"
                      v-model="item.hasRet"
                      @change="changeFee(feeIndex)"
                      class="ml-6"
                  ></v-checkbox>
                </td>
                <!-- Container -->
                <td>
                  <span>{{ container }}</span>
                </td>

                <!-- <td v-if="order.transportationCategoryId != 1">
                   <v-select
                     label="Tipo contenedor"
                     :items="cargoTypes"
                     item-value="id"
                     item-text="description"
                     v-model="elContainerId.id"
                     class="mr-2"
                   ></v-select>
                 </td>-->
                <!-- Moneda -->
                <td>
                  <v-autocomplete
                      class="mr-2 mt-5"
                      style="margin-top: 18px !important"
                      v-model="item.currencyId"
                      item-value="id"
                      :items="Currencies"
                      item-text="code"
                      dense
                      @blur="changeFee(feeIndex)"
                  >
                  </v-autocomplete>
                  <!-- <span>{{ item.currency ? item.currency.code : "-" }}</span> -->
                </td>
                <!-- Total -->
                <td>
                  <v-text-field
                      :disabled="item.isVisible ? false : true"
                      readonly
                      rounded
                      type="number"
                      v-model="item.subtotal"
                      class="col-md-10 table-fee-input mr-2"
                      hide-details
                  >
                  </v-text-field>
                  <!--              <div-->
                  <!--                  v-if="item.changeAmount !== 0"-->
                  <!--                  :class="item.changeAmount > 0 ? 'green&#45;&#45;text' : 'red&#45;&#45;text'"-->
                  <!--              >-->
                  <!--                  <span v-if="item.changeAmount > 0"-->
                  <!--                  ><v-icon x-small>mdi-arrow-up</v-icon>&nbsp;{{-->
                  <!--                      Math.abs(item.changeAmount)-->
                  <!--                    }}</span-->
                  <!--                  >-->
                  <!--                <span v-if="item.changeAmount < 0"-->
                  <!--                ><v-icon x-small>mdi-arrow-down</v-icon>&nbsp;{{-->
                  <!--                    item.changeAmount-->
                  <!--                  }}</span-->
                  <!--                >-->
                  <!--              </div>-->
                </td>
                <td>
                  <v-btn
                      @click="remove(feeIndex)"
                      color="red"
                      icon
                      small
                      v-if="items.length > 1"
                  >
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                  <v-btn @click="add()" color="primary" icon small>
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </td>
              </tr>
            </draggable>
          </v-card>
        </template>
      </v-simple-table>
    </div>

    <div v-else>
      <v-simple-table  style="color: red" :dense="dense">
        <template v-slot:default>
          <v-card
              :disabled="order.statusOrder === 'Vendida' || colorVis"
              :style="{ 'background-color': colorVis ? '#ffed6e' : '' }"
          >
            <thead>
            <th class="text-left"></th>
            <th class="text-left">
              Cargos
            </th>

            <th class="text-left">
              Price
            </th>

            <th class="text-left">
              Ratable weight
            </th>

            <th class="text-left">
              Profit
            </th>

            <th class="text-left">
              Descuento
            </th>

            <th class="text-left">
              Moneda
            </th>

            <th class="text-left">
              IVA
            </th>
            <th class="text-left">
              RET
            </th>

            <th class="text-left">
              Total
            </th>
            <th class="text-left"></th>
            </thead>
            <draggable v-model="items" handle=".selectDrag" @change="saveOrder" tag="tbody">
              <tr
                  :style="{ 'background-color': colorVis ? '#ffed6e' : '' }"
                  v-for="(item, feeIndex) in items"
                  :key="
                `${item.quotationDetailId}_${item.orderPriceFeesId}priceFees${feeIndex}`
              "
                  :class="item.isVisible ? '' : 'text--disabled'"
                  class="table-fee-row"

              >
                <td class="table-actions-row">
                  <v-icon class="selectDrag">mdi-drag</v-icon>
                </td>
                <td>
                  <v-text-field
                      :disabled="item.isVisible ? false : true"
                      v-model="item.name"
                      class="table-fee-input mr-2"
                      hide-details
                      @blur="changeFee(feeIndex)"
                  ></v-text-field>
                </td>

                <td>
                  <v-text-field
                      :disabled="item.isVisible ? false : true"
                      v-model="item.unitPrice"
                      class="table-fee-input mr-2"
                      hide-details
                      @blur="changeFee(feeIndex)"
                  ></v-text-field>
                </td>

                <td>
                  <v-text-field
                      v-if="item.ratableWeight != ratableWeightDef"
                      :disabled="item.isVisible ? false : true"
                      v-model="item.ratableWeight"
                      class="table-fee-input text-red mr-2"
                      hide-details
                      @blur="changeFee(feeIndex)"
                  ></v-text-field>

                  <v-text-field
                      v-else
                      :disabled="item.isVisible ? false : true"
                      v-model="item.ratableWeight"
                      class="table-fee-input mr-2"
                      hide-details
                      @blur="changeFee(feeIndex)"
                  ></v-text-field>
                </td>
                <!-- Profit -->
                <td>
                  <v-text-field
                      :disabled="item.isVisible ? false : true"
                      v-model.number="item.profit"
                      @blur="calculateChangeInPrice(feeIndex)"
                      class="table-fee-input mr-2"
                      hide-details
                  ></v-text-field>
                </td>
                <!-- descuet -->
                <td>
                  <v-text-field
                      :disabled="item.isVisible ? false : true"
                      v-model.number="item.discount"
                      @blur="calculateChangeInPrice(feeIndex)"
                      class="table-fee-input mr-2"
                      hide-details

                  >
                  </v-text-field>
                </td>

                <td>
                  <v-autocomplete
                      class="mr-2 mt-5"
                      style="margin-top: 18px !important"
                      v-model="item.currencyId"
                      item-value="id"
                      :items="Currencies"
                      item-text="code"
                      dense
                      @blur="changeFee(feeIndex)"
                  >
                  </v-autocomplete>
                </td>

                <td>
                  <v-checkbox
                      :disabled="!item.isVisible || item.hasRet"
                      v-model="item.hasVat"
                      @change="changeFee(feeIndex)"
                  ></v-checkbox>
                </td>
                <td>
                  <v-checkbox
                      :disabled="!item.isVisible || item.hasVat"
                      v-model="item.hasRet"
                      @change="changeFee(feeIndex)"
                  ></v-checkbox>
                </td>

                <td>
                  <v-text-field
                      readonly
                      rounded
                      :disabled="item.isVisible ? false : true"
                      @blur="calculateChangeInPrice(feeIndex)"
                      type="number"
                      v-model="item.subtotal"
                      class="table-fee-input col-md-9"
                      hide-details

                  >
                  </v-text-field>
                </td>
                <td>
                  <v-btn
                      @click="remove(feeIndex)"
                      color="red"
                      icon
                      small
                      v-if="items.length > 1"
                  >
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                  <v-btn @click="add()" color="primary" icon small>
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </td>
              </tr>
            </draggable>
          </v-card>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
// import QuotationDetailFee from '../../../prototypes/Quotation/quotationDetailFee';
// import QuotationDetails from '../../../prototypes/quotation'
import draggable from 'vuedraggable'
import customVmodelMixin from "@/mixins/custom.vmodel.mixin";

export default {
  name: "QuotationDetails",
  mixins: [
    customVmodelMixin
  ],
  props: {
    colorVis: {
      type: Boolean,
      required: true
    },
    order: {
      type: Object,
      required: true,
    },
    container: {
      type: String,
      required: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    draggable,
  },
  data() {
    return {
      Currencies: [],
      elContainerId: null,
      elCurrenciesId: null,
      cargoTypes: [],
      showRatableWeigth: false,
      items: null,
      ratableWeightDef: 0,
      demoItem: {
        changeAmount: 0,
        currencyId: 1,
        discount: 0,
        discountPercentage: 0,
        editPrice: false,
        hasRet: false,
        hasVat: false,
        id: 0,
        isVisible: true,
        name: "",
        percentage: 0,
        position: 0,
        price: 0,
        profit: 0,
        profitPercentage: 0,
        quantity: 1,
        ratableWeight: 0,
        status: false,
        subtotal: 0,
        total: 0,
        unitPrice: 0,
        quotationDetailId: null,
      },
      multipleCargo: false,
    };
  },
  computed: {
    detailFees() {
      return this.localValue.fees;
    }
  },
  watch: {
    // order: {
    //   handler() {
    //     this.mapDetailIntoItem();
    //   },
    // },
    detailFees: {
      handler() {
        this.mapDetailIntoItem();
      },
    },
  },
  methods: {
    getCurrencies(){
      this.$http.get("currencies").then((response) => {
        if (response.data.code == 200) {
          this.Currencies = response.data.data;
        }
      });
    },
    saveOrder(event) {
      // console.log(event)
      this.items[event.moved.newIndex].position = event.moved.newIndex + 1;
      this.items[event.moved.oldIndex].position = event.moved.oldIndex + 1;
      //
      // console.log("Items Fees", this.items);
      this.changeFee(event.moved.newIndex);
    },
    checkCargos() {
      if (this.order.cargo.length > 1) {
        this.multipleCargo = true;
      } else {
        this.multipleCargo = false;
      }
    },
    setDefaultC() {
      for (let i = 0; i < this.items.length; i++) {
        for (let j = 0; j < this.Currencies.length; j++) {
          if (this.items[i].currencyName === this.Currencies[j].code) {
            this.items[i].currencyId = this.Currencies[j].id;
          }
        }
      }
    },
    remove(feeIndex) {
      if (this.items[feeIndex].id) {
        this.$http.delete(`Quotation/${this.localValue.quotationId}/detail/${this.localValue.id}/fee/${this.items[feeIndex].id}`).then(() => {
          this.items.splice(feeIndex, 1)
        }).catch(() => {
        })
      } else {
        this.items.splice(feeIndex, 1)
      }
    },
    add() {
      this.items.push({...this.demoItem, quotationDetailId: this.localValue.id, position: this.items.length + 2});
    },
    compareDesc() {
      this.elContainerId = this.cargoTypes.find(
          (element) => element.description === this.container
      );
    },
    getCargoTypes() {
      this.$http
          .get("Containers")
          .then((response) => {
            this.cargoTypes = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    validateRatableWeight() {
      if (
          this.order.transportationCategoryId === 2 ||
          this.order.transportationCategoryId === 1 ||
          this.order.transportationCategoryId === 3 ||
          this.order.transportationCategoryId === 11 ||
          this.order.transportationCategoryId === 12 ||
          this.order.transportationCategoryId === 13
      )
        this.showRatableWeigth = false;
      else this.showRatableWeigth = true;

      let ratableWeight = 0;
      if (
          this.order.transportationCategoryId == 9 ||
          this.order.transportationId == 2
      ) {
        ratableWeight = parseFloat(
            this.order.cargo.reduce((a, b) => a + b.volumetricWeight, 0)
        );
        this.ratableWeightDef = ratableWeight;
      }
    },
    mapDetailIntoItem() {
      // console.log("Map detail Into Item", this.localValue.fees);

      if (!this.localValue.fees) {
        this.items = [];
      }

      this.items = this.localValue.fees.map((fee) => {
        // console.log({...fee});
        let rwq = fee.ratableWeight === 0 ? fee.quantity : fee.ratableWeight;
        // console.log("RW or Q: ",rwq)
        // console.log("Unit Price: ",fee.unitPrice)
        // console.log("Total: ",fee.unitPrice * rwq)
        let changeAmount = 0;

        if (fee.profit > 0) {
          // console.log('Has profit')
          fee.subtotal = fee.subtotal + fee.profit;
          changeAmount = fee.profit;
        }
        if (fee.discount > 0) {
          // console.log('Has discount')
          fee.subtotal = fee.subtotal - fee.discount;
          changeAmount = fee.discount * -1;
        }

        return {
          ...fee,
          name: fee.name,
          quantity: fee.quantity,
          ratableWeight: fee.ratableWeight,
          subtotal: ((fee.unitPrice * rwq) + fee.profit - fee.discount) ?? 0,
          total: (fee.unitPrice * rwq) ?? 0,
          editPrice: !fee.editPrice ? false : fee.editPrice,
          changeAmount: changeAmount,
        };
      });


    },
    setFeeVisible(feeIndex) {
      this.items[feeIndex].isVisible = !this.items[feeIndex].isVisible;
      this.$emit("setVisible", this.items);
    },
    calculateChangeInPrice(feeIndex) {
      // this.items[feeIndex].subtotal = (this.items[feeIndex].unitPrice * this.items[feeIndex].ratableWeight === 0 ? this.items[feeIndex].quantity : this.items[feeIndex].ratableWeight) + this.items[feeIndex].profit - this.items[feeIndex].discount;
      // this.items[feeIndex].subtotal = parseFloat(this.items[feeIndex].subtotal);
      // this.items[feeIndex].total = parseFloat(this.items[feeIndex].total);
      //
      // let change =
      //     (this.items[feeIndex].total - this.items[feeIndex].subtotal) * -1;

      // this.items[feeIndex].changeAmount = change;

      // this.items[feeIndex].profit = 0;
      // this.items[feeIndex].discount = 0;
      //
      // if (change > 0) {
      //   this.items[feeIndex].profit = Math.abs(change);
      // }
      // if (change < 0) {
      //   this.items[feeIndex].discount = Math.abs(change);
      // }

      this.changeFee(feeIndex);
    },

    changeFee(feeIndex) {
      // console.log(this.items);
      let rwq = this.items[feeIndex].ratableWeight === 0 ? this.items[feeIndex].quantity : this.items[feeIndex].ratableWeight;
      this.items[feeIndex].subtotal = (this.items[feeIndex].unitPrice * rwq) + this.items[feeIndex].profit - this.items[feeIndex].discount;
      this.items[feeIndex].total = (this.items[feeIndex].unitPrice * rwq);
      if (this.items[feeIndex].id === 0) {
        this.items[feeIndex].orderPriceFeesId = null;
        this.items[feeIndex].quotationDetailId = this.localValue.id;
      }

      if (this.items[feeIndex].unitPrice > 0 && this.items[feeIndex].total > 0) {
        this.$emit("change", this.items);
      }
    },
  },
  mounted() {
    this.getCurrencies();
    this.validateRatableWeight();
    this.getCargoTypes();
    setTimeout(() => {
      this.setDefaultC();
      this.compareDesc();
    }, 2000);
  },
  beforeMount() {
    this.mapDetailIntoItem();
  },
};
</script>

<style scoped>
.table-fee-row > td {
  min-width: 100px;
  max-width: 250px;
  padding: 0 !important;
}

.table-fee-row > td.table-actions-row {
  max-width: 30px;
  min-width: 30px;
}

.text-red >>> .v-text-field__slot input {
  color: red;
}

.text-black >>> .v-text-field__slot input {
  color: black;
}

.table-fee-input >>> .v-input__control .v-input__slot {
  padding: 0;
}

.table-fee-input {
  padding: 0;
  margin: 0;
}
</style>
