<template>
  <section class="w-100">
    <div class="header-expansion pa-2 d-flex" @click="$emit('select', { placeId: item['place_id'] })">
      <div class="mx-1 my-auto">
        <v-icon
            medium
            class="mr-1"
        >
          {{item.type === 'port' ? 'mdi-ferry' : 'mdi-city'}}
        </v-icon>
      </div>
      <div
        class="mx-1 my-auto text-truncate w-75"
      >
        <span>{{item.description}}</span>
      </div>
      <div class="ma-auto" v-if="item.ports.length > 1">
        <span class="text-sm-caption">Todos los puertos</span>
      </div>
      <v-btn
        icon
        small
        @click="$emit('expand', item['place_id'])"
      >
        <v-icon>{{item.ports.length > 1 ? '$expand' : ''}}</v-icon>
      </v-btn>
    </div>
    <v-expand-transition>
      <div v-if="item.open && item.ports.length > 1">
        <v-list-item
            v-for="(port) in item.ports"
            :key="port._id"
            :input-value="port.selected"
            @click="$emit(`${multiple ? 'add' : 'select'}`, {placeId: item['place_id'], portId: port._id})"
        >
          <template v-slot:default>
            <v-list-item-content class="px-3">
              <v-row>
                <v-col
                    cols="9"
                    class="my-auto ml-1 py-0 pr-0 d-flex expansion-content text-truncate"
                >
                  <div v-if="!multiple" class="mx-1 my-auto">
                    <v-icon class="mr-1">mdi-ferry</v-icon>
                  </div>
                  <div v-else class="mx-1 my-auto">
                    <v-checkbox
                      :input-value="port.selected"
                      v-model="port.selected"
                      class="pa-0 ma-0 checkbox"
                    >
                    </v-checkbox>
                  </div>

                  <div class="ml-5 my-auto">
                    <div class="secondary--text font-weight-bold text-truncate">{{ port.city }}, {{ port.country }}</div>
                    <div class="secondary--text caption">{{Number.parseFloat(port.distance).toFixed(2)}} KM a llegada a puerto</div>
                  </div>
                  <br><br><br>
                </v-col>
                <v-col class="caption text-truncate text-right" v-if="port.unlocs">
                  {{port.unlocs[0]}}
                </v-col>
              </v-row>
            </v-list-item-content>
          </template>
        </v-list-item>
      </div>

    </v-expand-transition>
  </section>
</template>

<script>
export default {
  name: 'AutocompleteDetails',
  props: {
    item: {
      type: Object,
      required: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return{
      model: {}
    }
  },
  computed: {
    computedModel: {
      get: function(){
        return this.getSelectedPortsIndexes();
      }
    }
  },
  watch: {
    item: {
      handler() {
        console.log('item changed')
        let portIndexes = this.getSelectedPortsIndexes();
        if (!this.item.selected && portIndexes.length > 0 ){
          console.log('reset')
          this.resetNotSelectedPorts(portIndexes)
        }
      },
      deep: true
    }
  },
  methods: {
    resetNotSelectedPorts(indexes){
      if (this.multiple) {
        for(let i = 0; i < indexes.length; i++) {
          this.item.ports[indexes[i]].selected = false;
        }
      }
      else{
        this.item.ports[indexes[0]].selected = false;
      }
      this.model= -1;
    },
    getSelectedPortsIndexes(){
      return this.item.ports.reduce((accumulator, currentValue, index) => {
        currentValue.selected && accumulator.push(index);
        return accumulator;
      }, []);
    },
  }

}
</script>

<style scoped>
.header-expansion{
  cursor: pointer;
}
.w-75{
  width: 75%;

}
</style>
