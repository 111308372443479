<template>
  <div>
    <v-row>
      <v-col md="12">
        <v-tabs centered icons-and-text v-model="loadDetail.tab">
          <v-tab class="ml-3" :disabled="loadDetail.Container">
            <span>
               <v-icon>las la-pallet</v-icon> Containers
           </span>
          </v-tab>
          <v-tab :disabled="loadDetail.Pallets">
            <span>
              <v-icon>las la-boxes</v-icon> Boxes/Pallets
            </span>
          </v-tab>
          <v-tab :disabled="loadDetail.Bulk">
            <span>
              <v-icon>las la-weight-hanging</v-icon> Bulk
            </span>
          </v-tab>
          <v-tab-item>
            <v-card flat v-if="loadDetail.tab === 0">
              <v-card-text>
                <br>
                <div v-for="(load,index) in loadingData" :key="index">
                  <v-row>
                    <v-col md="1" sm="4" xs="12" class="ml-2">
                      <label class="text-caption">Qty</label>
                      <v-text-field type="number" outlined dense v-model="load.quantity"
                                    :name="`loadingData[${index}][quantity]`" @change="sendData(index)"></v-text-field>
                    </v-col>
                    <v-col md="2" sm="5" xs="12" class="pl-0">
                      <label class="text-caption">Tipo de Contenedor</label>
                      <v-autocomplete v-model="load.containerId" :items="Containers" item-text="description"
                                      item-value="id" :name="`loadingData[${index}][containerId]`"
                                      @change="sendData(index)" outlined
                                      dense>
                      </v-autocomplete>
                    </v-col>

                    <v-col md="2" class=" pl-0">
                      <label class="text-caption">Peso</label>
                      <v-text-field type="Number" step="0.01" v-model.number="load.weight"
                                    :name="`loadingData[${index}][weight]`" @change="validateWeigth(index)"
                                    :messages="messageWeigth" outlined dense :disabled="load.legal">
                      </v-text-field>
                    </v-col>

                    <v-col md="2" class=" pl-0 ml-0">
                      <label class="text-caption">Unidad de </label>
                      <v-select :items="Units" v-model="load.weightUnitMeasure" item-value="name" item-text="name"
                                 outlined dense></v-select>
                    </v-col>
                    <v-col md="1 mt-2">
                      <v-checkbox color="primary" v-model="load.legal" :name="`loadingData[${index}][legal]`"
                                  label="Legal" @change="WeightLegal(index)"></v-checkbox>
                    </v-col>
                    <v-col md="1" class="ml-3 mt-2">
                      <v-checkbox color="primary" v-model="load.dangerous" :name="`loadingData[${index}][dangerous]`"
                                  @change="sendData(index)" label="Peligroso"></v-checkbox>
                    </v-col>
                    <v-col md="1" class="ml-8 mt-2">
                      <v-checkbox color="primary" v-model="load.metrics" :name="`loadingData[${index}][metrics]`"
                                  @change="sendData(index)" label="Medidas"></v-checkbox>
                    </v-col>

                    <v-col md="1" class="ml-5 mt-2">

                      <v-btn class="mt-3" icon color="pink" @click="deleteLoad(index)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <div>

                    <v-divider></v-divider>

                    <v-row v-if="load.dangerous == true">
                      <v-col md="4">
                        <v-col md="12">
                          <v-file-input v-model="load.IMO"
                                        prepend-icon=""
                                        prepend-inner-icon="mdi-paperclip"
                                        label="Clasificacion IMO"
                                        outlined
                                        @change="sendData(index)"
                                        :rules="editMode ? []:[v => !!v || ' Requerido']"
                                        dense></v-file-input>
                        </v-col>
                      </v-col>

                      <v-col md="4">
                        <v-col md="12">
                          <v-file-input v-model="load.UN"
                                        prepend-icon=""
                                        prepend-inner-icon="mdi-paperclip"
                                        label="Numero UN"
                                        @change="sendData(index)"
                                        outlined
                                        :rules="editMode ? []:[v => !!v || ' Requerido']"
                                        dense></v-file-input>
                        </v-col>
                      </v-col>
                    </v-row>
                  </div>
                  <div v-if="load.metrics === true">
                    <v-divider class="mb-3"></v-divider>
                    <v-row>
                      <v-col md="1" class="pa-0 pt-3 ml-3">
                        <label>Largo</label>
                        <v-text-field v-model.number="load.large" step="0.01" :rules="[numberRule]"
                                      :name="`loadingData[${index}][large]`" type="Number" outlined
                                      dense @change="sendData(index)"></v-text-field>
                      </v-col>
                      <v-col md="1" class="pa-0 pt-3 ml-1">
                        <label>Ancho</label>
                        <v-text-field v-model.number="load.width" step="0.01" :rules="[numberRule]"
                                      :name="`loadingData[${index}][width]`" outlined dense
                                      type="Number" @change="sendData(index)"></v-text-field>
                      </v-col>
                      <v-col md="1" class="pa-0 pt-3 ml-1">
                        <label>Alto</label>
                        <v-text-field v-model.number="load.height" step="0.01" :rules="[numberRule]"
                                      :name="`loadingData[${index}][height]`" type="Number" outlined
                                      dense class="pa-0" @change="sendData(index)"></v-text-field>
                      </v-col>
                      <v-col md="2" class="ml-1 pa-0 pt-3">
                        <label>Unidad de Medida</label>
                        <v-select :items="UnitsOfLength" :rules="[v => !!v || ' Requerido']" v-model="load.unitMeasure"
                                  item-value="name" item-text="name"
                                  @change="sendData(index)" outlined dense></v-select>
                      </v-col>
                    </v-row>
                  </div>

                </div>

                <v-btn text color="primary" @click="add()">+ Añadir carga</v-btn>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card flat v-if="loadDetail.tab === 1">
              <v-card-text>
                <br>
                <div v-for="(load,index) in loadingData" :key="index">
                  <v-form v-model="validateMeasures" ref="formValidateMeasure">

                    <v-row>

                      <v-col md="1" class="pa-0 pt-3">
                        <label>Qty</label>
                        <v-text-field type="number" outlined dense v-model="load.quantity"
                                      :rules="[v => !!v || ' Requerido']"
                                      :name="`loadingData[${index}][quantity]`" @change="sendData(index)"></v-text-field>
                      </v-col>

                      <v-col md="1" class="pa-0 pt-3 ml-3">
                        <label>Largo</label>
                        <v-text-field v-model.number="load.large" step="0.01" :rules="[numberRule]"
                                      :name="`loadingData[${index}][large]`" type="Number" outlined
                                      dense @change="sendData(index)"></v-text-field>
                      </v-col>
                      <v-col md="1" class="pa-0 pt-3 ml-1">
                        <label>Ancho</label>
                        <v-text-field v-model.number="load.width" step="0.01" :rules="[numberRule]"
                                      :name="`loadingData[${index}][width]`" outlined dense
                                      type="Number" @change="sendData(index)"></v-text-field>
                      </v-col>
                      <v-col md="1" class="pa-0 pt-3 ml-1">
                        <label>Alto</label>
                        <v-text-field v-model.number="load.height" step="0.01" :rules="[numberRule]"
                                      :name="`loadingData[${index}][height]`" type="Number" outlined
                                      dense class="pa-0" @change="sendData(index)"></v-text-field>
                      </v-col>

                      <v-col md="2" class="ml-1 pa-0 pt-3">
                        <label>Unidad de Medidaa</label>
                        <v-select :items="UnitsOfLength" :rules="[v => !!v || ' Requerido']" v-model="load.unitMeasure"
                                  item-value="name" item-text="name"
                                  @change="sendData(index)" outlined dense></v-select>
                      </v-col>

                      <v-col md="2" class="mt-2">
                        <v-checkbox color="primary" v-model="load.dangerous" :name="`loadingData[${index}][dangerous]`"
                                    @change="sendData(index)" label="Peligroso"></v-checkbox>
                      </v-col>


                      <v-col md="1" class="mt-2">
                        <v-radio-group
                            v-model="load.stackable"
                            column
                            @change="sendData(index)"
                            :name="`loadingData[${index}][stackable]`"
                            color="primary"
                        >
                          <v-radio
                              active-class="font-weight-black"
                              label="No estibable"
                              :value="false"
                          ></v-radio>
                          <v-radio
                              active-class="font-weight-black"
                              label="Estibable"
                              :value="true"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>


                    </v-row>

                    <v-row>


                      <v-col md="2" class="pa-0 pt-3">
                        <label>Peso</label>
                        <v-text-field type="Number" v-model.number="load.weight"
                                      step="0.01"
                                      :rules="[numberRule]"
                                      :name="`loadingData[${index}][weight]`" @change="sendData(index)" outlined dense
                        ></v-text-field>
                      </v-col>

                      <v-col md="2" class="pa-0 pt-3 ml-3">
                        <label>Unidad de peso</label>
                        <v-select :items="Units" :rules="[v => !!v || ' Requerido']" v-model="load.weightUnitMeasure"
                                  item-value="name" item-text="name"
                                  @change="sendData(index)" outlined dense></v-select>

                      </v-col>




                      <v-col md="3">
                        <v-radio-group
                            v-model="load.totalWeight"
                            column
                            @change="sendData(index)"
                        >
                          <v-radio
                              label="Peso total"
                              :value="true"
                          ></v-radio>
                          <v-radio
                              label="Peso por pallet"
                              :value="false"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>



                      <v-col md="1">

                        <v-btn class="mt-6" icon color="pink" @click="deleteLoad(index)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>

                  </v-form>


                  <div v-if="load.dangerous == true">


                    <v-divider></v-divider>

                    <v-row>
                      <v-col md="4">
                        <v-col md="12">
                          <v-file-input class=""
                                        v-model="load.IMO"
                                        prepend-icon=""
                                        prepend-inner-icon="mdi-paperclip"
                                        label="Clasificacion IMO"
                                        @change="sendData(index)"
                                        outlined
                                        :rules="editMode ? []:[v => !!v || ' Requerido']"
                                        dense></v-file-input>
                        </v-col>
                      </v-col>

                      <v-col md="4">
                        <v-col md="12">
                          <v-file-input class=""
                                        v-model="load.UN"
                                        prepend-icon=""
                                        prepend-inner-icon="mdi-paperclip"
                                        label="Numero UN"
                                        @change="sendData(index)"
                                        :rules="editMode ? []:[v => !!v || ' Requerido']"
                                        outlined
                                        dense></v-file-input>
                        </v-col>
                      </v-col>


                    </v-row>

                    <v-divider></v-divider>

                  </div>

                </div>
                <v-btn text color="primary" @click="add()">+ Añadir carga</v-btn>
              </v-card-text>
            </v-card>
          </v-tab-item>


          <v-tab-item>
            <v-card flat v-if="loadDetail.tab === 2">
              <v-card-text>

                <div v-for="res in Element" :key="res.id">
                  <v-row>

                    <v-col md="2">
                      <label>Q-ty Contenedores</label>
                      <br>
                      <br>
                      <v-text-field outlined dense value="0"></v-text-field>
                    </v-col>

                    <v-col md="2">
                      <label>Tipo de Contenedor</label>
                      <br>
                      <br>
                      <v-text-field outlined dense value="0"></v-text-field>
                    </v-col>

                    <v-col md="1">
                      <label>Medida</label>
                      <br>
                      <br>
                      <v-text-field outlined dense value="0"></v-text-field>

                    </v-col>

                    <v-col md="2">
                      <label>Gross weight, lbs</label>
                      <br>
                      <br>
                      <v-text-field outlined dense value="0"></v-text-field>

                    </v-col>

                    <v-col md="1">
                      <label>Cantidad</label>
                      <br>
                      <br>
                      <v-text-field outlined dense value="0"></v-text-field>

                    </v-col>
                    <v-col md="1">
                      <br>
                      <v-checkbox color="primary" label="Peligroso"></v-checkbox>
                    </v-col>

                    <v-col md="1">
                      <br>
                      <v-radio-group
                          v-model="load.stackable"
                          column
                          @change="sendData(index)"
                          :name="`loadingData[${index}][stackable]`"
                          color="primary"
                      >
                        <v-radio
                            active-class="font-weight-black"
                            label="No estibable"
                            :value="false"
                        ></v-radio>
                        <v-radio
                            active-class="font-weight-black"
                            label="Estibable"
                            :value="true"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>

                    <v-col md="1" offset="1">

                      <br>
                      <br>
                      <v-btn icon color="pink">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>

                    </v-col>

                  </v-row>
                </div>

                <v-btn text color="primary" @click="add()">Añadir carga</v-btn>

                <v-divider></v-divider>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Load_detail",
  computed: {},
  props: {
    control: {
      default: 11,
      type: Number
    },
    loadDetail: Object,
    loadData: {
      type: Array,
      default: () => []
    },
    editMode: {
      type: Boolean,
      default: false,
    },

  },
  watch: {
    control: function () {
      this.ValidateContainer();
    },
    "loadDetail.tab": function () {
      if (this.isInit) {
        this.loadingData.forEach((el) => {
          if (!el.modified) {
            el.weight = this.loadDetail.tab === 0 ? 21000 : 0;
          }

          // if (this.loadDetail.tab === 0) {
          //   el.loadingTypeId = null;
          //   el.type = "Container";
          // }
          //
          // if (this.loadDetail.tab === 1) {
          //   el.type = "Pallets"
          //   el.containerId = null;
          //   el.loadingTypeId = 2;
          // }

          el.legal = el.weight === 21000;
          el.dangerous = false;
          el.IMO = null;
          el.UN = null;
          el.documents = [
            {
              documentTypeId: 16,
              file: null,
              fileExtension: 'PDF',
              fileName: 'IMO'
            },

            {
              documentTypeId: 16,
              file: null,
              fileExtension: 'PDF',
              fileName: 'UN'
            },
          ]
        })
      }
    },
  },
  data: () => ({
    isInit: false,
    numberRule: v => {
      if (!isNaN(parseFloat(v)) && v > 0 ) return true;
      return 'Valor es requerido.';
    },
    Legal: true,
    validateMeasures:false,
    tab: 0,
    ruleCargo: {
      required: [v => !!v || 'Requerido'],
      greaterThan: [v => (v > 0) || "Debe ser mayor a 0"]
    },
    IMO: null,
    UN: null,
    Unit: "Kg",
    UnitOfLength: 2,
    currentUnit: {
      id: 2,
      name: 'Kg',
      value: 1
    },
    currentUnitOfLength: {
      id: 2,
      name: 'Mts',
      value: 1
    },
    Pallets: null,
    bulk: null,
    Units: [{
      id: 1,
      name: 'Gr',
      value: 1000
    },
      {
        id: 2,
        name: 'Kg',
        value: 1
      },
      {
        id: 3,
        name: 'Ton',
        value: 0.001
      },
      {
        id: 4,
        name: 'Oz',
        value: 0.028
      },
      {
        id: 5,
        name: 'Lb',
        value: 0.453
      }
    ],
    UnitsOfLength: [{
      id: 1,
      name: 'Cm',
      value: 100
    },
      {
        id: 2,
        name: 'Mts',
        value: 1
      },
      {
        id: 3,
        name: 'Pulgada',
        value: 0.0254
      },
      {
        id: 4,
        name: 'Pies',
        value: 0.3048
      },
    ],
    messageWeigth: '',
    UnitSelected: "Mts",

    user: {
      lastname: "Mireles"
    },
    Containers: [],
    containers: "",
    orders: {
      companyId: null,
      commodity: null,
      budget: null,
      status: null,
      urgent: null,
      comments: null,
      frequence: null,
      orderTypeId: null,
      date: null,
      insideBillOfLanding: null,
      dueDate: null,
      originCountry: null,
      originCity: null,
      destinationCountry: null,
      destinationCity: null,
      OrderUsers: [{
        UserId: null,
      }],
      orderCargo: [{
        quantity: 0,
        large: 0,
        width: 0,
        heigth: 0,
        weigth: 21000,
        loadingTypeId: null,
        stackable: true,
        tiltable: null,
        packaging: null,
        dangerous: null,
        containerId: null,
        temperature: null,
        dangerSubdivisionId: null,
        color: null,
      }],

    },
    loadingTypeId: null,
    loadingData: [
      {
        stackable: true,
        tiltable: false,
        totalWeight: true,
        containerId: 0,
        type: "Container",
        documents: [
          {
            documentTypeId: 16,
            file: null,
            fileExtension: 'PDF',
            fileName: 'IMO'
          },

          {
            documentTypeId: 16,
            file: null,
            fileExtension: 'PDF',
            fileName: 'UN'
          },

        ],
        quantity: 1,
        landFull: false,
        weight: 0,
        dangerous: false,
        large: 0,
        width: 0,
        legal: true,
        height: 0,
        unitMeasure: 'Mts',
        unit: 'Kg',
        weightUnitMeasure: 'Kg',
        modified: false,
      }
    ],
    Element: [{
      qtyp: 0,
      TipoPal: 0,
      Medidas: 0,
      Gross: 0,
      qty: 0,
      peligroso: null,
      estibable: null,
      line_total: 0,
      id: 1,
    }],
  }),
  methods: {
    resetCargo() {
      this.loadingData = [];
      this.add();
    },
    validateFields() {
      this.$refs.formValidateMeasure.validate();
    },

    add() {
      this.loadingData.push({
        stackable: true,
        tiltable: false,
        totalWeight: true,
        containerId: this.loadDetail.tab === 0 ? this.defaultContainer : null,
        type: "Container",
        documents: [
          {
            documentTypeId: 16,
            file: null,
            fileExtension: 'PDF',
            fileName: 'IMO'
          },

          {
            documentTypeId: 16,
            file: null,
            fileExtension: 'PDF',
            fileName: 'UN'
          },

        ],
        quantity: 1,
        landFull: false,
        weight: this.loadDetail.tab === 0 ? 21000 : 0,
        dangerous: false,
        large: 0,
        width: 0,
        legal: true,
        height: 0,
        unitMeasure: 'Mts',
        unit: 'Kg',
        weightUnitMeasure: 'Kg',
        modified: false,
      })
      // console.log(this.loadingData)
      this.$nextTick(() => {
        this.$emit('loading', this.loadingData)
      })
      // console.log(this.loadingData)
    },
    WeightLegal(index) {

      if (this.loadingData[index]) {
        this.Containers.forEach(element => {
          if (element.id == this.loadingData[index].containerId)
            this.loadingData[index].weight = 21000
        })
      }

    },
    deleteLoad(index) {
      this.loadingData.splice(index, 1)
    },
    ConvertToKilograms: function (equivalence, value) {
      return value / equivalence;
    },
    ConvertToMeters: function (equivalence, value) {
      return value / equivalence;
    },
    /*CalculateWeigth: function (index) {
      const selectedUnit = this.Units.find(x => x.name == this.loadingData[index].Unit);
      let value = this.loadingData[index].weight;
      this.loadingData[index].WeightUnitMeasure = selectedUnit.name;
      if (this.currentUnit.name == 'Kg') {
        value = value * selectedUnit.value
      } else {
        let standardWeight = this.ConvertToKilograms(this.currentUnit.value, value)
        value = standardWeight * selectedUnit.value;
      }
      this.currentUnit = selectedUnit
      this.loadingData[index].weight = value
    },
    CalculateUnitOfLength: function (index) {
      const selectedUnitOfLength = this.UnitsOfLength.find(x => x.id == this.UnitOfLength);
      let large = this.loadingData[index].large;
      let width = this.loadingData[index].width;
      let height = this.loadingData[index].height;

      if (this.currentUnitOfLength.name == 'Mts') {
        large = large * selectedUnitOfLength.value
        width = width * selectedUnitOfLength.value
        height = height * selectedUnitOfLength.value
        console.log(large);

      } else {
        let standardLarge = this.ConvertToMeters(this.currentUnitOfLength.value, large)
        large = standardLarge * selectedUnitOfLength.value;
        console.log(large);

        let standardWidth = this.ConvertToMeters(this.currentUnitOfLength.value, width)
        large = standardWidth * selectedUnitOfLength.value;

        let standardheight = this.ConvertToMeters(this.currentUnitOfLength.value, height)
        large = standardheight * selectedUnitOfLength.value;
      }
      this.currentUnitOfLength = selectedUnitOfLength
      this.loadingData[index].large = large
      this.loadingData[index].width = width
      this.loadingData[index].height = height
    },*/
    validateWeigth: function (index) {

      if (this.loadingData[index].weight > 21000) {
        this.messageWeigth = "Aplican Cargos"
      } else {
        this.messageWeigth = ''
      }

      this.$emit('loading', this.loadingData);

    },
    ValidateContainer: function () {
      if (this.control == 9) {
        this.Pallets = false;
        this.bulk = false;
      }

      if (this.control == 11) {
        this.Pallets = true;
        this.bulk = true;
      }

      if (this.control == 12) {
        this.Pallets = false;
        this.bulk = false;
      }

      if (this.control == 13) {
        this.Pallets = false;
        this.bulk = false;
      }

    },
    sendData: function (id) {
      if (!this.loadingData[id].modified) this.loadingData[id].modified = true;

      if (!this.loadingData[id].documents && this.loadingData[id].dangerous) {
        this.loadingData[id].documents = [
          {
            documentTypeId: 16,
            file: null,
            fileExtension: 'PDF',
            fileName: 'IMO'
          },

          {
            documentTypeId: 16,
            file: null,
            fileExtension: 'PDF',
            fileName: 'UN'
          },
        ]
      }

      if (this.loadDetail.tab === 0) {
        this.loadingData[id].loadingTypeId = null;
        this.loadingData[id].type = "Container";
      }

      if (this.loadDetail.tab === 1) {
        this.loadingData[id].type = "Pallets"
        this.loadingData[id].containerId = null;
        this.loadingData[id].loadingTypeId = 2;
      }

      // Convert files
      if (this.loadingData[id].IMO) {
        this.UploadFile(this.loadingData[id].IMO).then(response => {
          let data = response.split(',')[1];
          this.loadingData[id].documents[0].file = data;
        }).catch(error => {
          console.log(error)
        })
      }

      if (this.loadingData[id].UN) {
        this.UploadFile(this.loadingData[id].UN).then(response => {
          let data = response.split(',')[1];
          this.loadingData[id].documents[1].file = data;
        }).catch(error => {
          console.log(error)
        })
      }

      this.$emit('loading', this.loadingData)
    },
    getContainers: function () {
      this.$http.get('Containers')
          .then(response => {
            this.containers = [];
            this.Containers = response.data.data;
            //this.Containers = this.Containers.filter(element => element.transportationId === 3)
            // console.log(this.containers)
            this.defaultContainer = this.Containers[0].id
            if (!this.loadingData[0].containerId) {
              this.loadingData[0].containerId = this.defaultContainer;
            }
            this.$emit('loading', this.loadingData)
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.loading = false)
    },
    UploadFile: function (file) {

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    ConvertFile: function (file, index, docindex) {
      console.log(file.name);


      this.UploadFile(file).then(response => {


        console.log(response);
        var data = response.split(',')[1];
        console.log(data)
        this.loadingData[index].documents[docindex].file = data;
        console.log(this.loadingData[index].documents)
      })
          .catch(error => {
            console.log(error)
          })


    },
    setLoadDetail() {
      if (this.loadData.length > 0) {
        this.loadingData = []
        this.loadingData = this.loadData
        this.loadingData.forEach((el) => {
          // this.WeightLegal(index);
          if (el.weight === 21000) {
            el.modified = true;
            el.legal = true;
          }
        });
      }
      this.$nextTick(() => {
        this.$emit('loading', this.loadingData)
        this.isInit = true;
      })
    }
  },

  mounted() {
    this.getContainers();
    this.$nextTick(() => {
      // this.WeightLegal();
      this.setLoadDetail();
    })
    this.validateFields();

  },

}
</script>

<style scoped>

</style>
