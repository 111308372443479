function Quotation(obj) {
  this.id = obj.id;
  this.orderId = obj.orderId;
  this.profit = obj.profit;
  this.suggested = obj.suggested;
  this.discount = obj.discount;
  this.discountPercentage = obj.discountPercentage;
  this.profitPercentage = obj.profitPercentage;
  this.quotationDetails = [];
  obj.quotationDetails.forEach((detail) => {
    this.quotationDetails.push(new QuotationDetails(detail));
  });
}

function QuotationDetails(obj) {
  this.id = obj.id;
  this.quotationId = obj.quotationId;
  this.orderPriceId = obj.orderPriceId;
  this.isVisible = obj.isVisible;
  this.profit = obj.profit;
  this.discount = obj.discount;
  this.discountPercentage = obj.discountPercentage;
  this.profitPercentage = obj.profitPercentage;
  this.showTotalPrice = obj.showTotalPrice;
  this.position = obj.position;
  // this.fees = [];
  // obj.fees.forEach((fee) => {
  //   console.log(fee);
  //   this.fees.push(new QuotationDetailFee(fee));
  // });
}

function QuotationDetailFee(obj) {
  this.id = obj.id;
  this.feeId = obj.feeId;
  this.quotationDetailId = obj.quotationDetailId;
  this.orderPriceFeesId = obj.orderPriceFeesId??null;
  this.profit = obj.profit;
  this.discount = obj.discount;
  this.discountPercentage = obj.discountPercentage;
  this.profitPercentage = obj.profitPercentage;
  this.isVisible = obj.isVisible;
  this.hasVat = obj.hasVat;
  this.hasRet = obj.hasRet;
}

function isNumber(number) {
  try {
    if (isNaN(number)) return false;
  } catch (e) {
    console.error(e);
    return false;
  }

  return true;
}

Quotation.prototype.validate = function() {
  if (!isNumber(this.id) || this.id == 0) {
    console.log("id is invalid");
    return false;
  }

  if (!isNumber(this.orderId) || this.orderId == 0) {
    console.log("orderId is invalid");
    return false;
  }

  if (!isNumber(this.profit)) {
    console.log("profit is invalid");
    return false;
  }

  if (!isNumber(this.discount)) {
    console.log("discount is invalid");
    return false;
  }

  if (!isNumber(this.discountPercentage)) {
    console.log("discountPercentage is invalid");
    return false;
  }

  if (!isNumber(this.profitPercentage)) {
    console.log("profitPercentage is invalid");
    return false;
  }

  let detailsIsValid = true;
  this.quotationDetails.forEach((q) => {
    if (!q.validate()) {
      detailsIsValid = false;
    }
  });

  if (!detailsIsValid) {
    console.log("details is invalid");
    return false;
  }

  return true;
};

QuotationDetails.prototype.validate = function() {
  if (!isNumber(this.id) || this.id == 0) {
    console.log("id is invalid");
    return false;
  }

  if (!isNumber(this.quotationId) || this.quotationId == 0) {
    console.log("quotationId is invalid");
    return false;
  }

  if (!isNumber(this.orderPriceId) || this.orderPriceId == 0) {
    console.log("orderPriceId is invalid");
    return false;
  }

  if (!isNumber(this.profit)) {
    console.log("profit is invalid");
    return false;
  }

  if (!isNumber(this.discount)) {
    console.log("profit is invalid");
    return false;
  }

  if (!isNumber(this.discountPercentage)) {
    console.log("profit is invalid");
    return false;
  }

  if (!isNumber(this.profitPercentage)) {
    console.log("profitPercentage is invalid");
    return false;
  }

  let isFeesValid = true;

  this.fees.forEach((fee) => {
    if (fee.validate()) {
      console.log("details fee is invalid");
      isFeesValid = false;
    }
  });

  if (!isFeesValid) {
    return false;
  }

  return true;
};

QuotationDetailFee.prototype.validate = function() {
  if (!isNumber(this.quotationDetailId)) {
    return false;
  }

  if (!isNumber(this.orderPriceFeesId) || this.orderPriceFeesId == 0) {
    console.log("orderPriceFeeId is invalid");
    return false;
  }

  if (!isNumber(this.profit)) {
    console.log(this.profit)
    console.log("profit is invalid");
    return false;
  }

  if (!isNumber(this.discount)) {
    console.log("discount is invalid");
    return false;
  }

  if (!isNumber(this.discountPercentage)) {
    console.log("discountPercentage is invalid");
    return false;
  }

  if (!isNumber(this.profitPercentage)) {
    console.log("profitPercentage is invalid");
    return false;
  }
};

export default Quotation;
