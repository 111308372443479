<template>
  <v-app-bar app elevation="0" color="white">
    <v-spacer></v-spacer>
    <span class="pr-3 font-weight-bold">{{ $store.getters.name + ' ' + $store.getters.lastname }}</span>
    <v-toolbar-items>
      <v-menu
          :close-on-content-click="false"
          offset-x
          offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-bind="attrs"
              v-on="on"
              tile
              elevation="0"
              outlined
              small
              color="#cbcbcb"
          >
            <v-badge
                overlap
                bordered
                avatar
                color="secondary"
            >
              <template v-slot:badge>
                <div style="height: 100%" class="d-flex">
                  <div class="ma-auto d-inline-block">{{ notificationsCount.data }}</div>
                </div>
              </template>
              <v-icon color="primary">
                mdi-bell
              </v-icon>
            </v-badge>
          </v-btn>
        </template>
        <v-card
            min-width="400"
            elevation="1"
        >
          <v-card-title>Notificaciones</v-card-title>

          <v-virtual-scroll :items="Notifications"
                            :item-height="70"
                            height="300">
            <template v-slot:default="{item,index}">
              <v-list-item @click="NotificationsGo(index)" :color="item.seen ? 'gray' : 'primary'" two-line>
                <v-list-item-icon>
                  <v-icon :color="item.seen ? 'gray' : 'primary'">mdi-alert-circle-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap">{{ item.message.Description }}</v-list-item-title>
                  <v-list-item-subtitle>Hace {{ moment(item.createdAt).fromNow(true) }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon :color="item.seen ? 'grey' : 'primary'">
                    {{ item.seen ? 'mdi-eye' : 'mdi-eye-off' }}
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-virtual-scroll>
        </v-card>
      </v-menu>
      <div
      v-if="this.$store.getters.roles[0] === 'Operaciones'"

      >
        <ShipmentCreation></ShipmentCreation>
      </div>
      <v-btn
          tile
          elevation="0"
          outlined
          small
          @click="RequestQuote()"
          color="#cbcbcb"
          v-if="this.$store.getters.roles[0] != 'Operaciones'"

      >
        <v-icon color="primary">mdi-comment-alert</v-icon>
      </v-btn>
      <v-dialog
          v-model="dialog"
          class="pa-0"
          width="1200"
          transition="dialog-bottom-transition"
          scrollable
          :key="dialogKey"
      >
        <v-card>
          <v-toolbar
              flat
              dark
              color="primary"
          >

            <v-toolbar-title>
              <v-icon id="20">mdi-frequently-asked-questions</v-icon>
              Request Quote
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
                icon
                dark
                left
                @click="dialog = true"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <Maritimo
                v-if="showComponent == 1"
                @OrderCreated="OrderCreated"
                v-bind="QuotData"
            >
              <template v-slot:quoteData>
                <QuotData
                    ref="notQuote"
                    v-on:QuotData="RecibeData"
                    v-on:Transporte="showComponent = $event"
                    @markersUpdated="setMarkers"
                    v-bind="QuotData"
                >
                  <template slot="autocomplete">

                    <div class="row no-gutters position-relative" v-if="QuotData.filterRoute == 4">
                      <div class="col-6 fixed-col-size">
                        <googleMapsAutocompleteVuetifyNew
                            label="Origen"
                            :search="originAddress"
                            :items="originItems"
                            :selections="origin"
                            :filterRoute="1"
                            transportation="SEA"
                            placeholder="Ingrese un origen"
                            @change="originSelected"
                            @updateAddress="updateOriginAddress"
                            @updateItems="updateOriginItems"
                            :isOpen="true"
                            :id="3"
                        >
                        </googleMapsAutocompleteVuetifyNew>
                      </div>
                      <div class="col-6 fixed-col-size">
                        <googleMapsAutocompleteVuetifyNew
                            label="Destino"
                            :search="destinationAddress"
                            :items="destinationItems"
                            :selections="destination"
                            :filterRoute="2"
                            transportation="SEA"
                            placeholder="Ingrese un destino"
                            @change="destinationSelected"
                            @updateAddress="updateDestinationAddress"
                            @updateItems="updateDestinationItems"
                            :id="4"
                        >
                        </googleMapsAutocompleteVuetifyNew>
                      </div>
                    </div>
                    <!--Este es el de maritimo, puerto a puerto-->
                    <div class="row no-gutters position-relative" v-if="QuotData.filterRoute == 2">
                      <div class="col-6 fixed-col-size">
                        <googleMapsAutocompleteVuetifyNew
                            label="Origen"
                            :search="originAddress"
                            :items="originItems"
                            :selections="origin"
                            :filterRoute="2"
                            transportation="SEA"
                            placeholder="Ingrese un origen"
                            @change="originSelected"
                            @updateAddress="updateOriginAddress"
                            @updateItems="updateOriginItems"
                            :isOpen="true"
                            :id="3"
                        >
                        </googleMapsAutocompleteVuetifyNew>
                      </div>
                      <div class="col-6 fixed-col-size">
                        <googleMapsAutocompleteVuetifyNew
                            label="Destino"
                            :search="destinationAddress"
                            :items="destinationItems"
                            :selections="destination"
                            :filterRoute="2"
                            transportation="SEA"
                            placeholder="Ingrese un destino"
                            @change="destinationSelected"
                            @updateAddress="updateDestinationAddress"
                            @updateItems="updateDestinationItems"
                            :id="4"
                        >
                        </googleMapsAutocompleteVuetifyNew>
                      </div>
                    </div>

                    <div class="row no-gutters position-relative" v-if="QuotData.filterRoute == 1">
                      <div class="col-6 fixed-col-size">
                        <googleMapsAutocompleteVuetifyNew
                            label="Origen"
                            :search="originAddress"
                            :items="originItems"
                            :selections="origin"
                            :filterRoute="1"
                            transportation="SEA"
                            placeholder="Ingrese un origen"
                            @change="originSelected"
                            @updateAddress="updateOriginAddress"
                            @updateItems="updateOriginItems"
                            :isOpen="true"
                            :id="3"
                        >
                        </googleMapsAutocompleteVuetifyNew>
                      </div>
                      <div class="col-6 fixed-col-size">
                        <googleMapsAutocompleteVuetifyNew
                            label="Destino"
                            :search="destinationAddress"
                            :items="destinationItems"
                            :selections="destination"
                            :filterRoute="1"
                            transportation="SEA"
                            placeholder="Ingrese un destino"
                            @change="destinationSelected"
                            @updateAddress="updateDestinationAddress"
                            @updateItems="updateDestinationItems"
                            :id="4"
                        >
                        </googleMapsAutocompleteVuetifyNew>
                      </div>
                    </div>

                    <div class="row no-gutters position-relative" v-if="QuotData.filterRoute == 3">
                      <div class="col-6 fixed-col-size">
                        <googleMapsAutocompleteVuetifyNew
                            label="Origen"
                            :search="originAddress"
                            :items="originItems"
                            :selections="origin"
                            :filterRoute="2"
                            transportation="SEA"
                            placeholder="Ingrese un origen"
                            @change="originSelected"
                            @updateAddress="updateOriginAddress"
                            @updateItems="updateOriginItems"
                            :isOpen="true"
                            :id="3"
                        >
                        </googleMapsAutocompleteVuetifyNew>
                      </div>
                      <div class="col-6 fixed-col-size">
                        <googleMapsAutocompleteVuetifyNew
                            label="Destino"
                            :search="destinationAddress"
                            :items="destinationItems"
                            :selections="destination"
                            :filterRoute="1"
                            transportation="SEA"
                            placeholder="Ingrese un destino"
                            @change="destinationSelected"
                            @updateAddress="updateDestinationAddress"
                            @updateItems="updateDestinationItems"
                            :id="4"
                        >
                        </googleMapsAutocompleteVuetifyNew>
                      </div>
                    </div>


                  </template>
                </QuotData>
              </template>

            </Maritimo>

            <Aereo
                v-if="showComponent === 2"
                v-bind="QuotData"
                @OrderCreated="OrderCreated"
            >
              <template v-slot:quoteData>
                <QuotData
                    ref="notQuote"
                    v-on:QuotData="RecibeData"
                    v-on:Transporte="showComponent=$event"
                    @markersUpdated="setMarkers"
                    v-bind="QuotData"
                >
                  <template slot="autocomplete">
                    <div class="row no-gutters position-relative" v-if="QuotData.filterRoute == 2">
                      <div class="col-6 fixed-col-size">
                        <s-airort-autocomplete
                            label="Origen"
                            :search="originAddress"
                            :items="originItems"
                            :filterRoute="QuotData.filterRoute"
                            :selections="origin"
                            @change="originSelectedAereo"
                            @updateAddress="updateOriginAddress"
                            @updateItems="updateOriginItems"
                            @menuChanged="isOriginOpen = $event"
                            :isOpen="isOriginOpen"
                            :id="1"
                            transportation="AIR"
                            placeholder="Ingrese un origen"
                        >
                        </s-airort-autocomplete>
                      </div>
                      <div class="col-6 fixed-col-size">
                        <s-airort-autocomplete
                            label="Destino"
                            :search="destinationAddress"
                            :items="destinationItems"
                            :selections="destination"
                            :filterRoute="QuotData.filterRoute"
                            @change="destinationSelectedAereo"
                            @updateAddress="updateDestinationAddress"
                            @updateItems="updateDestinationItems"
                            @menuChanged="isDestinationOpen = $event"
                            :isOpen="isDestinationOpen"
                            :id="2"
                            transportation="AIR"
                            placeholder="Ingrese un Destino"
                        >
                        </s-airort-autocomplete>
                      </div>
                    </div>

                    <div class="row no-gutters position-relative" v-if="QuotData.filterRoute == 4">
                      <div class="col-6 fixed-col-size">
                        <s-airort-autocomplete
                            label="Origen"
                            :search="originAddress"
                            :items="originItems"
                            :filterRoute="1"
                            :selections="origin"
                            @change="originSelectedAereo"
                            @updateAddress="updateOriginAddress"
                            @updateItems="updateOriginItems"
                            @menuChanged="isOriginOpen = $event"
                            :isOpen="isOriginOpen"
                            :id="1"
                            transportation="AIR"
                            placeholder="Ingrese un origen"
                        >
                        </s-airort-autocomplete>
                      </div>
                      <div class="col-6 fixed-col-size">
                        <s-airort-autocomplete
                            label="Destino"
                            :search="destinationAddress"
                            :items="destinationItems"
                            :selections="destination"
                            :filterRoute="2"
                            @change="destinationSelectedAereo"
                            @updateAddress="updateDestinationAddress"
                            @updateItems="updateDestinationItems"
                            @menuChanged="isDestinationOpen = $event"
                            :isOpen="isDestinationOpen"
                            :id="2"
                            transportation="AIR"
                            placeholder="Ingrese un Destino"
                        >
                        </s-airort-autocomplete>
                      </div>
                    </div>

                    <div class="row no-gutters position-relative" v-if="QuotData.filterRoute==1">
                      <div class="col-6 fixed-col-size">
                        <s-airort-autocomplete
                            label="Origen"
                            :search="originAddress"
                            :items="originItems"
                            :filterRoute="1"
                            :selections="origin"
                            @change="originSelectedAereo"
                            @updateAddress="updateOriginAddress"
                            @updateItems="updateOriginItems"
                            @menuChanged="isOriginOpen = $event"
                            :isOpen="isOriginOpen"
                            :id="1"
                            transportation="AIR"
                            placeholder="Ingrese un origen"
                        >
                        </s-airort-autocomplete>
                      </div>
                      <div class="col-6 fixed-col-size">
                        <s-airort-autocomplete
                            label="Destino"
                            :search="destinationAddress"
                            :items="destinationItems"
                            :selections="destination"
                            :filterRoute="1"
                            @change="destinationSelectedAereo"
                            @updateAddress="updateDestinationAddress"
                            @updateItems="updateDestinationItems"
                            @menuChanged="isDestinationOpen = $event"
                            :isOpen="isDestinationOpen"
                            :id="2"
                            transportation="AIR"
                            placeholder="Ingrese un Destino"
                        >
                        </s-airort-autocomplete>
                      </div>
                    </div>

                    <div class="row no-gutters position-relative" v-if="QuotData.filterRoute==3">
                      <div class="col-6 fixed-col-size">
                        <s-airort-autocomplete
                            label="Origen"
                            :search="originAddress"
                            :items="originItems"
                            :filterRoute="2"
                            :selections="origin"
                            @change="originSelectedAereo"
                            @updateAddress="updateOriginAddress"
                            @updateItems="updateOriginItems"
                            @menuChanged="isOriginOpen = $event"
                            :isOpen="isOriginOpen"
                            :id="1"
                            transportation="AIR"
                            placeholder="Ingrese un origen"
                        >
                        </s-airort-autocomplete>
                      </div>
                      <div class="col-6 fixed-col-size">
                        <s-airort-autocomplete
                            label="Destino"
                            :search="destinationAddress"
                            :items="destinationItems"
                            :selections="destination"
                            :filterRoute="1"
                            @change="destinationSelectedAereo"
                            @updateAddress="updateDestinationAddress"
                            @updateItems="updateDestinationItems"
                            @menuChanged="isDestinationOpen = $event"
                            :isOpen="isDestinationOpen"
                            :id="2"
                            transportation="AIR"
                            placeholder="Ingrese un Destino"
                        >
                        </s-airort-autocomplete>
                      </div>
                    </div>
                  </template>
                </QuotData>
              </template>

            </Aereo>

            <Terrestre
                v-if="showComponent == 3"
                v-bind="QuotData"
                @OrderCreated="OrderCreated"
            >
              <template v-slot:quoteData>
                <QuotData
                    ref="notQuote"
                    v-on:QuotData="RecibeData"
                    v-on:Transporte="showComponent=$event"
                    @markersUpdated="setMarkers"
                    v-bind="QuotData"
                >
                  <template slot="autocomplete">
                    <div class="row no-gutters position-relative">
                      <div class="col-6 fixed-col-size">
                        <s-land-autocomplete
                            label="Origen"
                            :search="originAddress"
                            :items="originItems"
                            :selections="origin"
                            @change="originSelectedTerrestre"
                            @updateAddress="updateOriginAddress"
                            @updateItems="updateOriginItems"
                            placeholder="Ingrese un origen"
                            transportation="SEA"
                            :id="1"
                        >
                        </s-land-autocomplete>
                      </div>
                      <div class="col-6 fixed-col-size">
                        <s-land-autocomplete
                            label="Destino"
                            :search="destinationAddress"
                            :items="destinationItems"
                            :selections="destination"
                            @change="destinationSelectedTerrestre"
                            @updateAddress="updateDestinationAddress"
                            @updateItems="updateDestinationItems"
                            placeholder="Ingrese un Destino"
                            transportation="SEA"
                            :id="2"
                        >
                        </s-land-autocomplete>
                      </div>
                    </div>
                  </template>
                </QuotData>
              </template>

            </Terrestre>

          </v-card-text>
        </v-card>
      </v-dialog>
    </v-toolbar-items>

  </v-app-bar>
</template>

<script>
import moment from 'moment'
import Maritimo from "./Quote/Maritimo";
import QuotData from "./Quote/QuotData";
// import googleMapsAutocompleteVuetifyAereo from "@/components/google-maps-autocomplete-vuetify/indexAereo";
import googleMapsAutocompleteVuetifyNew from "@/components/google-maps-autocomplete-vuetify/index_new";
// import googleMapsAutocompleteVuetifyTerrestre from "@/components/google-maps-autocomplete-vuetify/indexTerrestre";
import Aereo from "./Quote/Aereo";
import Terrestre from "./Quote/Terrestre";
import {mapGetters} from "vuex";
import SAirortAutocomplete from "@/components/google-maps-autocomplete-vuetify/SAirportAutocomplete";
import SLandAutocomplete from "@/components/google-maps-autocomplete-vuetify/SLandAutocomplete";
import ShipmentCreation from "@/views/Pages/Operation/components/ShipmentCreation";

moment.updateLocale('en', {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: 'a few seconds',
    ss: '%d s',
    m: "1 m",
    mm: "%d minutes",
    h: "1 h",
    hh: "%d h",
    d: "1 d",
    dd: "%d d",
    w: "1 w",
    ww: "%d w",
    y: "1 y",
    yy: "%d y"
  }
})

export default {
  name: "NotificationBar",
  components: {
    SLandAutocomplete,
    SAirortAutocomplete,
    Terrestre,
    Aereo,
    QuotData,
    Maritimo,
    googleMapsAutocompleteVuetifyNew,
    ShipmentCreation,

    // googleMapsAutocompleteVuetifyAereo,
    // googleMapsAutocompleteVuetifyTerrestre
  },
  computed: {
    ...mapGetters([
      'updateNotifications'
    ])
  },
  watch: {
    areSelected: {
      handler() {
        if (this.areSelected.origenSelected && this.areSelected.destinoSelected) {
          this.pricesSearchMaritimoPuertoAPuerto(this.QuotData.OriginPort.city, this.QuotData.DestinationPort.city)
        }
      },
      deep: true
    },
    showComponent() {
      this.defaultPlaces();
    },
    updateNotifications: function (val) {
      console.log('changed');
      if (val) {
        console.log("Si esta actualizando")
        this.GetNotifications();
        this.countNotification();
        this.$store.dispatch("updateNotifications", false);
      }
    }
  },
  data: () => ({
    areSelected: {
      origenSelected: false,
      destinoSelected: false,
    },
    rate: [
      {
        typename: "StandardFCLRate",
        maritime: {
          _id: "60c772c24cd795cc7809bccd",
          frequency: "Semanal",
          reference_number: "TA_LATAM-ALL_LATAM",
          validity_from: "Desconocido",
          validity: "1582934400000",
          validity_to: "Desconocido",
          travel_time: "5 dias",
          supplier: {
            _id: "601495c6007a633af0171365",
            name: "CMA CGM",
            details: {
              logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/CMA_CGM_logo.svg/1024px-CMA_CGM_logo.svg.png"
            }
          },
          pol: {
            _id: "5fbd2ec13469a15224eab795",
            name: "Manzanillo",
            city: "Manzanillo",
            country: "Mexico",
            code: "20120",
            unlocs: [
              "MXZLO"
            ]
          },
          pod: {
            _id: "5fbd2ec13469a15224eab4f1",
            name: "Buenaventura",
            city: "Buenaventura",
            country: "Colombia",
            code: "30151",
            unlocs: [
              "COBUN"
            ]
          },
          container: [
            {
              type: [
                "20DC",
                "20GP",
                "20STD",
                "OF20",
                "20DRY",
                ""
              ],
              name: "20 Dry Freight Container",
              price: 165,
              currency: "USD",
              fees: []
            },
            {
              type: [
                "40HC",
                "40HQ",
                "OF40HC",
                "40HDRY"
              ],
              name: "40 High Cube Container",
              price: 315,
              currency: "USD",
              fees: []
            }
          ],
          vesselFees: [
            {
              rate: "1",
              amount: "17",
              currency: "USD",
              description: "ISPS"
            },
            {
              rate: "1",
              amount: "70",
              currency: "USD",
              description: "Doc_Fee"
            }
          ]
        },
        origin: [],
        bestOffer: {
          originId: null,
          maritimeId: "60c772c24cd795cc7809bccd",
          price: 165
        }
      }
    ],
    dialogKey: 0,
    pageNumber: 1,
    pageSize: 20,
    showComponent: 1,
    message: '',
    QuotData: {
      Typetransportation: null,
      TypeService: null,
      Inconterm: null,
      typeRoute: null,
      dueDate: new Date().toISOString().substr(0, 10),
      origin: {},
      destination: {},
      searchVariables: {},
      DestinationPort: {},
      OriginPort: {},
      AutocompleteOrigin: false,
      AutocompleteDestination: false,
      DataService: {},
      filterRoute: null
    },
    UserData: [],
    originAddress: "",
    originSelections: {},
    destinationSelections: {},
    origin: {},
    destination: {},
    originItems: [],
    destinationAddress: "",
    destinationItems: [],
    dialog: false,
    icons: [
      'mdi-comment-alert',
    ],
    Notifications: [],
    notificationsCount: 0,
  }),

  methods: {
    pricesSearchMaritimoPuertoAPuerto(ori, des) {
      console.log(des)
      this.$http.get(`Prices/Search`, {params: {TransportationCategory: this.QuotData.TypeService , TransportationId: this.QuotData.Typetransportation, PodCity: des}}).then((response) => {
        console.log(response)
      }).catch((error) => {
        console.log(error)
      })
    },
    loadNotificationNextPage() {
      this.GetNotifications(this.pageSize, this.pageNumber + 1)
    },
    test() {
      alert('seleccione')
    },
    defaultPlaces() {
      this.originItems = [];
      this.destinationItems = [];
      this.origin = {};
      this.destination = {};
      this.originAddress = "";
      this.destinationAddress = "";
    },
    OrderCreated() {
      this.dialog = false
      this.originAddress = ""
      this.origin = {}
      this.originItems = []
      this.destinationAddress = ""
      this.destination = {}
      this.destinationItems = []
      // this.$refs.notQuote.rese();
      this.$nextTick(() => {
        this.QuotData = {
          Typetransportation: null,
          TypeService: null,
          Inconterm: null,
          typeRoute: null,
          dueDate: new Date().toISOString().substr(0, 10),
          origin: {},
          destination: {},
          searchVariables: {},
          DestinationPort: {},
          OriginPort: {},
          AutocompleteOrigin: false,
          AutocompleteDestination: false,
          DataService: {},
          filterRoute: null
        };
        this.dialogKey += 1;
      });
      // this.QuotDataDefault();
    },
    QuotDataDefault() {
      this.QuotData = {
        Typetransportation: null,
        TypeService: null,
        Inconterm: null,
        typeRoute: null,
        dueDate: new Date().toISOString().substr(0, 10),
        origin: {},
        destination: {},
        searchVariables: {},
        DestinationPort: {},
        OriginPort: {},
        AutocompleteOrigin: false,
        AutocompleteDestination: false,
        DataService: {},
        filterRoute: null
      }
    },

    NotificationsGo(index) {
      let UserRole = this.$store.getters.roles;
      let orderId = this.Notifications[index].message.OrderId
      let NotificationId = this.Notifications[index].id;
      this.UpdateNotification(NotificationId);

      this.$nextTick(() => {


        if (UserRole.indexOf('Pricing') > -1) {
          this.$router.push({path: `/ProceedQuote/${orderId}`})
        } else if (UserRole.indexOf('Ventas') > -1) {
          this.$router.push({path: `/ProceedQuoteSales/${orderId}`})
        }


      })
    },


    setMarkers: function (markers) {
      console.log(markers)
    },

    RequestQuote() {
      let UserRole = this.$store.getters.roles;

      if (UserRole.indexOf('Ventas') > -1) {
        this.dialog = true;
      }
    },

    moment: function (date) {
      return moment(date)
    },

    updateDestinationAddress(text) {
      this.destinationAddress = text
    },
    updateOriginAddress(text) {
      console.log('update de originadress')
      this.originAddress = text

    },
    GetNotifications(pageSize, pageNumber) {
      let query = {}

      if (pageNumber) {
        query.pageNumber = pageNumber
      }

      if (pageSize) {
        query.pageSize = pageSize
      }


      this.Notifications = []
      // this.$http.get('Notification', {params: query}).then(response => {
      //   // this.pageNumber = response.data.meta.currentPage;
      //   // this.pageSize = response.data.meta.pageSize;
      //   response.data.data.forEach(element => {
      //     let utc = moment.utc(element.createdAt).toDate()
      //     element.createdAt = moment(utc).local();
      //     element.message = JSON.parse(element.message)
      //   })
      //   this.Notifications.push(...response.data.data);
      // }).catch(error => {
      //   console.log(error);
      // })
    },
    RecibeData: function (data) {
      console.log(data)
      this.QuotData.Typetransportation = data.transport;
      this.QuotData.TypeService = data.service;
      this.QuotData.Inconterm = data.incoterm;
      this.QuotData.Type = data.typeRoute;
      this.QuotData.dueDate = data.dueDate;
      this.QuotData.AutocompleteOrigin = false;
      this.QuotData.DataService = data.DataService;
      this.QuotData.filterRoute = data.filterRoute;
      console.log(this.origin)
      if (this.origin.description) {
        console.log(this.origin)
        this.QuotData.origin["country"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
        this.QuotData.origin["state"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
        this.QuotData.origin["city"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' ||
            a === "sublocality" ||
            a === "administrative_area_level_3" ||
            a === 'neighborhood' ||
            a === "administrative_area_level_2") !== -1)[0]?.long_name
        this.QuotData.origin["coordinates"] = this.origin.details.location
        this.QuotData.AutocompleteOrigin = true;
      }

      if (this.destination.description) {
        console.log(this.destination)
        this.QuotData.destination["country"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
        this.QuotData.destination["state"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
        this.QuotData.destination["city"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' ||
            a === "sublocality" ||
            a === "administrative_area_level_3" ||
            a === 'neighborhood' ||
            a === "administrative_area_level_2") !== -1)[0]?.long_name
        this.QuotData.destination["coordinates"] = this.destination.details.location
        this.QuotData.AutocompleteDestination = true;
      }

      console.log(this.QuotData)

    },
    originSelected(selections) {
      try {
        // console.log("Selection result",selections)
        this.origin = selections
        this.originAddress = selections.description
        this.$nextTick(() => {
          try {
            if (this.origin.details) {

              this.QuotData.origin["country"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
              this.QuotData.origin["state"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
              this.QuotData.origin["city"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' ||
                  a === "sublocality" ||
                  a === "administrative_area_level_3" ||
                  a === 'neighborhood' ||
                  a === "administrative_area_level_2") !== -1)[0]?.long_name
              this.QuotData.origin["coordinates"] = this.origin.details.location;
              this.QuotData.origin["ports"] = this.origin.ports;
              this.QuotData.origin["address"] = this.origin.description;
              this.QuotData.AutocompleteOrigin = true;
              let index = this.origin.ports.findIndex(e => e.selected);
              this.QuotData.origin["ports"] = this.origin.ports;
              console.log(index)

              this.origin.address = this.originSelections.description;
              if (index !== -1) {

                this.QuotData.OriginPort = this.origin.ports[index];
                this.QuotData.origin["ports"].name = this.origin.ports[index].name;
                this.QuotData.origin["ports"].city = this.origin.ports[index].city;
                this.origin.ports[index].coordinates[0] = parseInt(this.origin.ports[index].coordinates[0]);
                this.origin.ports[index].coordinates[1] = parseInt(this.origin.ports[index].coordinates[1]);
                this.QuotData.origin["ports"].coordinates = `${this.origin.ports[index].coordinates[0]},${this.origin.ports[index].coordinates[1]}`;
                this.QuotData.origin["ports"].country = this.origin.ports[index].country;

                if (this.origin.type === 'port') {
                  this.originAddress = `${this.QuotData.origin["ports"].name}, ${this.QuotData.origin["ports"].city}, ${this.QuotData.origin["ports"].country}`
                }

                this.areSelected.origenSelected = true;
              }
            } else {
              this.QuotData.origin = {}
              this.originAddress = "";
            }
          } catch (e) {
            this.QuotData.origin = {}
            throw new Error(`There was an error while selecting the item ${JSON.stringify(selections)} \n` + e)
          }
        })
      } catch (error) {
        this.QuotData.origin = {}
        console.log(error)
        throw new Error(`There was an error while selecting a new origin ${JSON.stringify(selections)} \n` + error)
      }
    },

    destinationSelected: function (selections) {
      this.destination = selections
      this.destinationAddress = selections.description
      this.$nextTick(() => {
        try {
          if (!Array.isArray(this.destination)) {
            this.QuotData.destination["country"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
            this.QuotData.destination["state"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
            this.QuotData.destination["city"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' ||
                a === "sublocality" ||
                a === "administrative_area_level_3" ||
                a === 'neighborhood' ||
                a === "administrative_area_level_2") !== -1)[0]?.long_name
            this.QuotData.destination["coordinates"] = this.destination.details.location
            this.QuotData.destination["ports"] = this.destination.ports;
            this.QuotData.AutocompleteDestination = true;
            this.QuotData.destination["address"] = this.destination.description;

            let index = this.destination.ports.findIndex(e => e.selected);
            this.QuotData.destination["ports"] = this.destination.ports;

            // this.Origin.address = this.originSelections.description;
            if (index !== -1) {
              this.QuotData.DestinationPort = this.destination.ports[index];
              this.QuotData.destination["ports"].name = this.destination.ports[index].name;
              this.QuotData.destination["ports"].city = this.destination.ports[index].city;
              this.destination.ports[index].coordinates[0] = parseInt(this.destination.ports[index].coordinates[0]);
              this.destination.ports[index].coordinates[1] = parseInt(this.destination.ports[index].coordinates[1]);
              this.QuotData.destination["ports"].coordinates = `${this.destination.ports[index].coordinates[0]},${this.destination.ports[index].coordinates[1]}`;
              this.QuotData.destination["ports"].country = this.destination.ports[index].country;

              if (this.destination.type === 'port') {
                this.destinationAddress = `${this.QuotData.destination["ports"].name}, ${this.QuotData.destination["ports"].city}, ${this.QuotData.destination["ports"].country}`
              }

            }
            this.areSelected.destinoSelected = true;
            // this.setPath()
          }
        } catch (e) {
          console.log(e);
          this.QuotData.destination = {}
        }
      })
    },

    originSelectedAereo(selections) {
      try {
        this.origin = selections
        this.originAddress = selections.description
        this.$nextTick(() => {
          try {
            if (this.origin) {
              this.QuotData.origin["country"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
              this.QuotData.origin["state"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
              this.QuotData.origin["city"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' || a === "sublocality" || a === "administrative_area_level_3" || a === 'neighborhood') !== -1)[0]?.long_name
              this.QuotData.origin["state"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
              this.QuotData.origin["city"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' ||
                  a === "sublocality" ||
                  a === "administrative_area_level_3" ||
                  a === 'neighborhood' ||
                  a === "administrative_area_level_2") !== -1)[0]?.long_name
              this.QuotData.origin["coordinates"] = this.origin.details.location;
              this.QuotData.origin["airports"] = this.origin.airports;
              this.QuotData.origin["address"] = this.origin.description;
              this.QuotData.AutocompleteOrigin = true;
              let index = this.origin.airports.findIndex(e => e.selected);
              if (index !== -1) {


                this.QuotData.origin["airports"].name = this.origin.airports[index].name;
                this.QuotData.origin["airports"].city = this.origin.airports[index].city;
                this.QuotData.origin["airports"].coordinates = `${this.origin.airports[index].lat},${this.origin.airports[index].lon[1]}`;
                this.QuotData.origin["airports"].country = this.origin.airports[index].country;
                this.QuotData.OriginPort = this.origin.airports[index];

                if (this.origin.type === 'airport') {
                  this.originAddress = `${this.QuotData.origin["airports"].name}, ${this.QuotData.origin["airports"].city}, ${this.QuotData.origin["airports"].country}`
                }

              }
            } else {
              this.QuotData.origin = {}
            }
          } catch (e) {
            this.QuotData.origin = {}
            throw new Error(`There was an error while selecting the item ${JSON.stringify(selections)} \n` + e)
          }
        })
      } catch (error) {
        this.QuotData.origin = {}
        console.log(error)
      }
    },


    destinationSelectedAereo(selections) {
      try {
        console.log(selections)
        this.destination = selections
        this.destinationAddress = selections.description
        this.$nextTick(() => {
          try {
            if (!Array.isArray(this.destination)) {
              this.QuotData.destination["country"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
              this.QuotData.destination["state"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
              this.QuotData.destination["city"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' || a === "sublocality" || a === "administrative_area_level_3" || a === 'neighborhood') !== -1)[0]?.long_name
              this.QuotData.destination["state"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
              this.QuotData.destination["city"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' ||
                  a === "sublocality" ||
                  a === "administrative_area_level_3" ||
                  a === 'neighborhood' ||
                  a === "administrative_area_level_2") !== -1)[0]?.long_name
              this.QuotData.destination["coordinates"] = this.destination.details.location;
              this.QuotData.destination["airports"] = this.destination.airports;
              this.QuotData.destination["address"] = this.destination.description;
              this.QuotData.AutocompleteOrigin = true;
              let index = this.destination.airports.findIndex(e => e.selected);
              if (index !== -1) {

                /* if (this.QuotData.filterRoute==2){
                   this.QuotData.destination["airports"].name = this.destination.airports[index].name;
                   this.QuotData.destination["airports"].city = this.QuotData.destination.city;
                   this.QuotData.destination["airports"].coordinates = `${this.QuotData.destination.coordinates.lat},${this.QuotData.destination.coordinates.lng}`;
                   this.QuotData.destination["airports"].country = this.QuotData.destination.country;
                   this.QuotData.DestinationPort = this.destination.airports[index];
                   if (this.destination.type === 'airport') {
                     this.destinationAddress = `${this.QuotData.destination["airports"].name}, ${this.QuotData.destination["airports"].city}, ${this.QuotData.destination["airports"].country}`
                   }

                 }*/

                this.QuotData.destination["airports"].name = this.destination.airports[index].name;
                this.QuotData.destination["airports"].city = this.destination.airports[index].city;
                this.QuotData.destination["airports"].coordinates = `${this.destination.airports[index].lat},${this.destination.airports[index].lon}`;
                this.QuotData.destination["airports"].country = this.destination.airports[index].country;
                this.QuotData.DestinationPort = this.destination.airports[index];
                if (this.destination.type === 'airport') {
                  this.destinationAddress = `${this.QuotData.destination["airports"].name}, ${this.QuotData.destination["airports"].city}, ${this.QuotData.destination["airports"].country}`
                }


              }
            }
          } catch (e) {
            this.QuotData.origin = {}
            throw new Error(`There was an error while selecting the item ${JSON.stringify(selections)} \n` + e)
          }
        })
      } catch (error) {
        this.QuotData.origin = {}
        console.log(error)
      }
    },

    originSelectedTerrestre(selections) {
      this.origin = selections;
      this.originAddress = selections.description


      this.$nextTick(() => {
        try {
          this.QuotData.origin["address"] = this.origin.description
          this.QuotData.origin["country"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
          this.QuotData.origin["state"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
          this.QuotData.origin["city"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' || a === "sublocality" || a === "administrative_area_level_3" || a === 'neighborhood') !== -1)[0]?.long_name
          this.QuotData.origin["state"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
          this.QuotData.origin["city"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' ||
              a === "sublocality" ||
              a === "administrative_area_level_3" ||
              a === 'neighborhood' ||
              a === "administrative_area_level_2") !== -1)[0]?.long_name
          this.QuotData.origin["coordinates"] = this.origin.details.location;
          this.QuotData.AutocompleteOrigin = true;
        } catch (e) {
          console.log(e);
          this.QuotData.origin = {}
        }
      })
    },
    destinationSelectedTerrestre(selections) {
      this.destination = selections;
      this.destinationAddress = selections.description;

      this.$nextTick(() => {
        try {
          this.QuotData.destination["address"] = this.destination.description
          this.QuotData.destination["country"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
          this.QuotData.destination["state"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
          this.QuotData.destination["city"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' || a === "sublocality" || a === "administrative_area_level_3" || a === 'neighborhood') !== -1)[0]?.long_name
          this.QuotData.destination["state"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
          this.QuotData.destination["city"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' ||
              a === "sublocality" ||
              a === "administrative_area_level_3" ||
              a === 'neighborhood' ||
              a === "administrative_area_level_2") !== -1)[0]?.long_name
          this.QuotData.destination["coordinates"] = this.destination.details.location;
          this.QuotData.AutocompleteDestination = true;
        } catch (e) {
          this.QuotData.destination = {}
        }
      })
    },

    updateOriginItems(items) {
      console.log(items)
      this.originItems = items;
    },

    updateDestinationItems(items) {
      this.destinationItems = items;
    },

    UpdateNotification(notificationId) {

      this.$http.put(`Notification/${notificationId}`).then(({data}) => {
        if (data.code === 200) {
          let index = this.Notifications.findIndex(x => x.id == notificationId)
          if (index === -1) {
            this.$toast.error('Hubo un error')
            throw new Error('No se pudo actualizar la notificación a visto')
          }

          this.Notifications[index].seen = true;
          this.countNotification();
        }
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.$store.dispatch('working', false);
      })
    },

    countNotification() {
      this.notificationsCount = []
      // this.$http.get('Notification/Unseen').then(({data}) => {
      //    = data
      // }).catch(error => {
      //   console.log(error)
      // })
    }
  },
  mounted() {
    this.GetNotifications();
    this.countNotification();
    this.$hub.Connection.on("NewNotification", ({notifications}) => {
      // console.log(data);
      if (notifications) {
        let notification = notifications;
        notification.message = JSON.parse(notification.message)
        this.Notifications.splice(0, 0, notifications)
      }

      this.countNotification();
    })
  },
  beforeDestroy() {
    this.$hub.Connection.off("NewNotification")
  }
}
</script>

<style scoped lang="scss">

.fixedOption {
  position: fixed;
  z-index: 2;
  bottom: 1em;
  right: 2em;
}

.border-rounded {
  border-radius: 10px;
}

.fixed-col-size {
  min-width: 250px;
}

.searchButton {
  z-index: 2;
}

.position-relative {
  position: relative;
}

.notification {
  cursor: pointer;
}

</style>

