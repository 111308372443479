<template>
    <v-row>
      <v-col cols="12">
        <v-tabs
            v-model="tab"
            background-color="white"
            icons-and-text
            centered
            color="grey"
            height="150"
        >
          <v-tab href="#provisional" @click="getOrders('', 1)">
            <CardStatus>
              <template v-slot:card-icon>
                mdi-battery-clock-outline
              </template>
              <template v-slot:card-title>
                Provisional
              </template>
              <template v-slot:QuantityStatus>
                <h1 class="font-weight-regular pt-3 text-center primary--text">
                  {{ counts.provisional }}
                </h1>
              </template>
            </CardStatus>
          </v-tab>
          <v-tab href="#enTransito" @click="getOrders('', 2)">
            <CardStatus>
              <template v-slot:card-icon>
                mdi-transit-connection-horizontal
              </template>
              <template v-slot:card-title>
                En Tránsito
              </template>
              <template v-slot:QuantityStatus>
                <h1 class="font-weight-regular pt-3 text-center primary--text">
                  {{ counts.inTransit }}
                </h1>
              </template>
            </CardStatus>
          </v-tab>
          <v-tab href="#arribados" @click="getOrders('', 3)">
            <CardStatus>
              <template v-slot:card-icon>
                mdi-email-send-outline
              </template>
              <template v-slot:card-title>
                Arribados
              </template>
              <template v-slot:QuantityStatus>
                <h1 class="font-weight-regular pt-3 text-center primary--text">
                  {{ counts.arrived }}
                </h1>
              </template>
            </CardStatus>
          </v-tab>
          <v-tab href="#detenidos" @click="getOrders('', 4)">
            <CardStatus>
              <template v-slot:card-icon>
                mdi-stop-circle-outline
              </template>
              <template v-slot:card-title>
                Detenidos
              </template>
              <template v-slot:QuantityStatus>
                <h1 class="font-weight-regular pt-3 text-center primary--text">
                  {{ counts.detained }}
                </h1>
              </template>
            </CardStatus>
          </v-tab>
          <v-tab href="#entregados" @click="getOrders('', 5)">
            <CardStatus>
              <template v-slot:card-icon>
                mdi-check-all
              </template>

              <template v-slot:card-title>
                Entregados
              </template>

              <template v-slot:QuantityStatus>
                <h1 class="font-weight-regular pt-3 text-center primary--text">
                  {{ counts.delivered }}
                </h1>
              </template>
            </CardStatus>
          </v-tab>
          <v-tab href="#Terminadas" @click="getOrders('', 9)">
            <CardStatus>
              <template v-slot:card-icon>
                mdi-umbrella-closed-outline
              </template>
              <template v-slot:card-title>
                Terminadas
              </template>

              <template v-slot:QuantityStatus>
                <h1 class="font-weight-regular pt-3 text-center primary--text">
                  {{ counts.finished }}
                </h1>
              </template>
            </CardStatus>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" style="width: 100%">
          <v-row justify="center" class="mt-3 ">
            <v-col md="7">
              <v-text-field
                  outlined
                  v-model="search"
                  append-icon="mdi-magnify"
                  dense
                  label="Buscar"
                  single-line
                  hide-details
                  @input="SearchOrder(search)"
              >
                <template v-slot:append>
                  <v-menu
                      offset-y
                      content-class="included"
                      :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on: menu, attrs }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                          <v-btn
                              @click="triggerServices"
                              icon
                              small
                              class="mx-1"
                              v-bind="attrs"
                              v-on="{ ...menu, ...tooltip }"
                          >
                            <v-icon>mdi-tune</v-icon>
                          </v-btn>
                        </template>
                        Buscar por...
                      </v-tooltip>
                    </template>
                    <v-card width="300">
                      <v-card-text>
                        <h2 class="font-weight-regular">Filtrar</h2>
                        <v-row>
                          <v-col md="12">
                            <v-row justify="center">
                              <v-col md="12">
                                <v-autocomplete
                                    label="Cliente"
                                    v-model="filterCustomer"
                                    :items="customers"
                                    dense
                                    item-value="id"
                                    item-text="name"
                                    clearable
                                ></v-autocomplete>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col md="12">
                            <v-row justify="center">
                              <v-col md="12">
                                <v-autocomplete
                                    label="Incoterm"
                                    v-model="filterIncoterm"
                                    :items="incoterms"
                                    item-text="code"
                                    item-value="id"
                                    dense
                                    clearable
                                ></v-autocomplete>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col md="12">
                            <v-row justify="center">
                              <v-col md="12">
                                <v-select
                                    label="Incoterm"
                                    v-model="filterType"
                                    :items="orderOptions"
                                    item-text="name"
                                    item-value="name"
                                    dense
                                    clearable
                                ></v-select>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col md="12">
                            <v-row justify="center">
                              <v-col md="12">
                                <v-checkbox
                                  v-model="delay"
                                  label="Embarques con demora"
                                  >
                                </v-checkbox>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn text @click="filterOrders" color="primary">Buscar</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <div>
            <v-data-table
                :headers="headersOrders"
                :hide-default-footer="true"
                :items="itemsOrders.data"
                :items-per-page="17"
                :search="search"
            >
            <template v-slot:item.refCode="{item}">
              <span :class="item.key == 'RefCode' ? 'text-no-wrap yellow' : ''">{{ item.refCode }}</span>
            </template>
            <template v-slot:item.clientName="{item}">
              <span :class="item.key == 'Client' ? 'text-no-wrap yellow' : ''">{{ item.clientName }}</span>
            </template>
            <template v-slot:item.mblRefNumber="{item}">
              <span :class="item.key == 'MasterBl' ? 'text-no-wrap yellow' : ''">{{ item.mblRefNumber }}</span>
            </template>
            <template v-slot:item.hblRefNumber="{item}">
              <span :class="item.key == 'HouseBl' ? 'text-no-wrap yellow' : ''">{{ item.hblRefNumber }}</span>
            </template>
            <template v-slot:item.mblBookingNumber="{item}">
              <span :class="item.key == 'BookingNumber' ? 'text-no-wrap yellow' : ''">{{ item.mblBookingNumber }}</span>
            </template>
            <template v-slot:item.containerNumber="{item}">
              <span :class="item.key == 'ContainerNumber' ? 'text-no-wrap yellow' : ''">{{ item.containerNumber }}</span>
            </template>
            <template v-slot:item.noSeal="{item}">
              <span :class="item.key == 'NoSeal' ? 'text-no-wrap yellow' : ''">{{ item.noSeal }}</span>
            </template>
            <template v-slot:item.incoterm="{item}">
              <span :class="item.key == 'Incoterm' ? 'text-no-wrap yellow' : ''">{{ item.incoterm }}</span>
            </template>
            <template v-slot:item.delayDays="{item}">
              <span :class="item.key == 'Delay' ? 'text-no-wrap yellow' : ''">{{ item.delayDays }}</span>
            </template>

              <template v-slot:item.transportationId="{item}">
                <div v-if="item.transportationId === 1">
                  <v-icon
                  color="primary">
                    mdi-ferry
                  </v-icon>
                </div>
                <div v-if="item.transportationId === 2">
                  <v-icon color="red">
                    mdi-airplane
                  </v-icon>
                </div>
                <div v-if="item.transportationId === 3">
                  <v-icon color="green">
                    mdi-truck
                  </v-icon>
                </div>
              </template>
              <template v-slot:item.createdAt="{item}">
                {{item.createdAt | luxon({
                output: {
                  zone: "local",
                  format: "DATE_HUGE"
                }
              })}}
              </template>
              <template v-slot:item.orderTypeId="{item}">
                <div v-if="item.orderTypeId === 1">
                  Importación
                </div>
                <div v-if="item.orderTypeId === 2">
                  Exportación
                </div>
              </template>
              <template v-slot:item.currentEvent="{item}">
                {{ events.findIndex((element) => element.id === item.currentEvent) >= 0 ? events[events.findIndex((element) => element.id === item.currentEvent)].name : 'N/A' }}
              </template>
              <template v-slot:item.nextArrival="{item}">
                {{item.nextArrival | luxon({
                output: {
                  zone: "local",
                  format: "DATE_HUGE"
                }
              })}}
              </template>
              <template v-slot:item.users="{item}">
                {{item.users[item.users.findIndex(x => x.user.roles[0].roleId === 3)].user.firstName}} {{item.users[item.users.findIndex(x => x.user.roles[0].roleId === 3)].user.lastName}}
              </template>
              <template v-slot:item.view="{ item }">
                <v-btn text ripple @click="seeDetail(item.id)">
                  <v-icon color="primary">mdi-notebook-outline</v-icon>
                </v-btn>
              </template>
            </v-data-table>
            <!-- <v-pagination 
                class="mb-4"
                v-model="currentPage"
                :length="itemsOrders.meta.totalPages"
                @next="getOrdersP(true, true)"
                @previous="getOrdersP(true, false)"
            ></v-pagination> -->
          </div>
          <v-tab-item value="provisional"></v-tab-item>
        </v-tabs-items>

      </v-col>
    </v-row>
</template>

<script>
import CardStatus from "@/components/Sales/List/Card-Status";
import debouncePromise from "debounce-promise";

export default {
  name: "operationList2",
  components: {CardStatus},
  data: () => ({
    events: [
      {name: 'Confirmación de recolección', id: 'pickup_confirmation'},
      {name: 'Confirmación de zarpe', id: 'departure_confirmation'},
      {name: 'Confirmación de arribo', id: 'arrival_confirmation'},
      {name: 'Proceso de despacho', id: 'dispatch_merchandise'},
      {name: 'Entrega', id: 'delivery'},
      {name: 'Ingreso a terminal', id: 'entry_container_terminal'},
      {name: 'Despacho de mercancía', id: 'dispatch_merchandise'},
      {name: 'Transito a shipper', id: 'transit_to_shipper'},
      {name: 'LLego con shipper', id: 'arrive_to_shipper'},
      {name: 'Cargando y en transito a recibidor', id: 'loading_transit_to_receiver'},
      {name: 'LLego a recibidor', id: 'arrived_to_receiver'},
      {name: 'Descargando', id: 'Of_loaded'},
    ],
    status: 1,
    currentPage: 1,
    dataFilter:[],
    filterIncoterm: null,
    filterCustomer: null,
    filterStatus: null,
    delay: false,
    customers: [],
    incoterms: [],
    orderOptions: [
      {
        name: "CHAT"
      },
      {
        name: "CREATED_AT"
      },
      {
        name: "UPDATED_AT"
      }
    ],
    counts: {},
    filterBy: {},
    search: "",
    itemsOrders: {
      data: []
    },
    options: {
      page: 1,
      itemsPerPage: 25,
      pageStart: 0,
      pageStop: 0,
      pageCount: 0,
      itemsLength: 0
    },
    headersOrders: [
      {
        text: "Transporte",
        value: "transportationId",
      },
      {
        text: "Folio",
        value: "refCode",
      },
      {
        text: 'Operador',
        value: 'operatorName',
      },
      {
        text: "Cliente",
        value: "clientName",
      },
      {
        text: "Master BL",
        value: "mblRefNumber",
      },
      {
        text: "incoterm",
        value: "incoterm",
      },
      {
        text: "Imp/Exp",
        value: "orderTypeId",
      },
      {
        text: "Numero de contenedor",
        value: "containerNumber",
      },
      {
        text: "Booking Number",
        value: "mblBookingNumber",
      },
      //{
      //  text: "Waybill",
      //  value: "waybill",
      //},
      {
        text: "Numero de sello",
        value: "noSeal",
      },
      {
        text: "House",
        value: "hblRefNumber"
      },
      {
        text: "Embarcador",
        value: "clientSupplierName",
      },
      {
        text: "Fecha creado",
        value: "createdAt",
      },
      {
        text: "Días de demora",
        value: "delayDays",
      },
      {
        text: "Detalles",
        value: "view",
      },
    ],
    tab: 0,
  }),
  watch: {
    currentPage: {
      handler(){
        this.$http.get(`shipping/Find?PageSize=17`, {
          params: {
            ShippingStatusId: this.status ,
            PageNumber: this.currentPage
          }
        }
        ).then((response) => {
          console.log(response)
          this.itemsOrders = response.data;
        })
      }
    }
  },
  mounted() {
    this.getOrders('', 1);
    this.getCount();

  },
  methods: {
    triggerServices(){
      this.getIncoterms();
    },
    SearchQuote(searchValue) {
      if (searchValue == "") this.filter = false;
      else if (searchValue != "") {
        let find = this.Data.find((f) => f.folio.includes(searchValue));

        this.aux = [];
        this.aux.push(find);
        this.filter = true;
      }
    },
    getIncoterms() {
      this.$store.dispatch("working", true);

      this.$http
          .get("Incoterms")
          .then((response) => {
            this.incoterms = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => this.$store.dispatch("working", false));
    },

    SearchOrder: debouncePromise(function (e) {
      console.log(e);
      if (e === ''){
        this.$http.get(`shipping/Find?Value=`).then((response) => {
          console.log(response);
          this.itemsOrders.data = response.data.data;})
      } else {
          this.getOrders(e);

        }
    }, 1000),
    getOrders(e, status) {
      if (e) {
        this.$http.get(`shipping/Find?Value=${e}`).then((response) => {
          console.log(response);
          this.itemsOrders.data = response.data.data;
          //this.itemsOrders.meta.totalPages = response.data.meta.totalPages;
        });
      } else {
        this.search = '';

        console.log(status)
        this.status = status
        this.$http.get(`shipping/Find?ShippingStatusId=${status}`,{
          params: {
            PageSize: 17,
          }
        }).then((response) => {
          console.log(response);
          this.itemsOrders.data = response.data.data;
          //this.itemsOrders.meta.totalPages = response.data.meta.totalPages;

        });
      }

    },
    seeDetail(id) {
      console.log(id)
      this.$router.push(`/Operation/${id}`)
    },
    getCount() {
      this.$http.get("Shipping/count").then((response) => {
        console.log(response);
        this.counts = response.data.data;
      });
    },
    getOrdersP(e, NoP) {
      this.$store.dispatch("working", true);
      console.log(e, NoP)
      if (e && NoP) {
        let pageNumber = this.itemsOrders.meta.currentPage
        if (this.itemsOrders.meta.hasNextPage) {
          pageNumber = JSON.parse(JSON.stringify(this.itemsOrders.meta.currentPage + 1))
        }
        console.log('hice este get')
        this.$http
            .get(`shipping/Find?PageSize=17`,
                {
                  params: {
                    PageNumber: pageNumber,
                  }
                }
            )
            .then((response) => {
              console.log(response);
              this.$toast.info("Ordenes filtradas con exito!");
              this.itemsOrders = response.data;

            })
            .catch((error) => {
              console.log(error);
              this.$toast.error("Ocurrio un errorr");
            })
            .finally(() => {
              this.$store.dispatch("working", false);
            });
      }
      if (e && !NoP) {
        let pageNumber = this.itemsOrders.meta.currentPage
        if (this.itemsOrders.meta.hasPreviousPage) {
          pageNumber = JSON.parse(JSON.stringify(this.itemsOrders.meta.currentPage - 1))
        }
        console.log('no deberia entrar')
        this.$http
            .get(`shipping/Find?PageSize=17`,
                {
                  params: {
                    PageNumber: pageNumber,
                  }
                }
            )
            .then((response) => {
              console.log(response);
              this.$toast.info("Ordenes filtradas con exito!");
              this.itemsOrders = response.data;

            })
            .catch((error) => {
              console.log(error);
              this.$toast.error("Ocurrio un errorr");
            })
            .finally(() => {
              this.$store.dispatch("working", false);
            });
      }
      if (e && NoP > 0) {
        console.log('entre correctamente')
      }
    },
    filterOrders() {
      this.filterBy = {};

      if (this.filterCustomer) {
        this.filterBy.ClientId = this.filterCustomer;
      }
      if (this.filterType) {
        this.filterBy.OrderBy = this.filterType; // Changed this line to add the OrderBy param
      }
      if (this.filterIncoterm) {
        this.filterBy.IncotermId = this.filterIncoterm;
      }
      this.filterBy.Delay = this.delay;

      this.$store.dispatch("working", true);
      let apiUrl = "shipping/Find";
      this.$http
          .get(apiUrl, {params: this.filterBy})
          .then((response) => {
            console.log(response.data.data);
            this.itemsOrders = response.data;
            this.$toast.info("Ordenes filtradas con exito!");
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$store.dispatch("working", false);
          });
    },
  },
};
</script>

<style scoped>
.v-tab /deep/ {
  text-transform: none !important;
}

>>> .v-data-table td {
  padding: 0 20px;
}
</style>
