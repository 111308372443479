  function Port(obj){
  this.name = obj.name
  this.city = obj.city
  this.address = obj.address
  this.country = obj.country
}

function Place(obj) {
  this.id = obj.id ?? 0
  this.orderPriceId = obj.orderPriceId ?? 0
  this.address = obj.address
  this.city = obj.city
  this.state = obj.state
  this.country = obj.country
  this.coordinates = obj.coordinates
  if(obj.port)
    this.port = new Port(obj.port)

  if(obj.airports)
    this.port = new Port(obj.airports)
}

function Fee(obj) {
  this.id = obj.id ? obj.id : 0
  this.feeId = obj.feeId
  this.name = obj.name
  this.orderPriceId = obj.orderPriceId ? obj.orderPriceId : 0
  this.currencyId = obj.currencyId
  this.quantity = obj.quantity ? obj.quantity : 0
  this.unitPrice = obj.unitPrice ? obj.unitPrice : 0
  this.percentage = obj.percentage ? obj.percentage : 0
  this.total = obj.total ? obj.total : 0
  this.isVisible = obj.isVisible
  this.ratableWeightWarning = obj.ratableWeightWarning
  this.ratableWeightDef = obj.ratableWeightDef
  this.profit = obj.profit ? obj.profit : 0
  this.discount = obj.discount ? obj.discount : 0
  this.ratableWeight = obj.ratableWeight
  this.profitPercentage = obj.profitPercentage ? obj.profitPercentage : 0
  this.discountPercentage = obj.discountPercentage ? obj.discountPercentage : 0
  this.currencyId = obj.currencyId ? obj.currencyId : 0,
  this.hasVat = obj.hasVat
  this.hasRet = obj.hasRet
}

function PriceCondition(obj) {
  this.orderPriceId = obj.orderPriceId
  this.demurrageCost = obj.demurrageCost
  this.loadingTime = obj.loadingTime
  this.dischargeTime = obj.dischargeTime
}

function Price(obj) {
  this.supplierId = obj.supplierId
  this.orderId = obj.orderId
  this.serviceId = obj.serviceId
  this.containerId = obj.containerId
  this.validity = obj.validity
  this.departureTime = obj.departureTime
  this.transportationTime = obj.transportationTime
  this.transportationId = obj.transportationId
  this.showTotalPrice = obj.showTotalPrice
  this.profit = obj.profit ? obj.profit: 0
  this.discount = obj.discount ? obj.discount: 0
  this.profitPercentage = obj.profitPercentage ? obj.profitPercentage: 0
  this.discountPercentage = obj.discountPercentage  ? obj.discountPercentage : 0
  this.total = obj.total ? obj.total : 0
  this.comments = obj.comments
  this.isAnswered = obj.isAnswered ? obj.isAnswered : 0
  this.referenceNumber = obj.referenceNumber
  this.confirmedPricing = obj.confirmedPricing ? obj.confirmedPricing : 0
  this.confirmedSales = obj.confirmedSales ? obj.confirmedSales : 0
  this.id = obj.id
  this.destinationStorage = obj.destinationStorage
  this.originStorage = obj.originStorage
  this.freeDaysDestination = obj.freeDaysDestination
  this.isTotalPrice = obj.isTotalPrice
  this.isUpdating = obj.isUpdating
  this.priceFees = []
  this.pricesDestination = []
  this.pricesOrigins = []
  this.freightForwarderId = obj.freightForwarderId

  obj.priceFees.forEach(fee => {
    this.priceFees.push(new Fee(fee))
  })

  obj.pricesDestination.forEach(place => {
    console.log(place)
    this.pricesDestination.push(new Place(place))
  })

  obj.pricesOrigins.forEach(place => {
    this.pricesOrigins.push(new Place(place))
  })

  this.priceCondition = new PriceCondition(obj.priceCondition)
  this.priceDocuments = obj.priceDocuments
}

function isNumber(number) {
  try {
    if (isNaN(number))
      return false;
  } catch (e) {
    console.error(e)
    return false;
  }

  return true;
}

Fee.prototype.validate = function () {
  if(!isNumber(this.unitPrice)){
    console.log('feePrice is invalid')
    return false;
  }

  if(!isNumber(this.ratableWeight) && this.ratableWeight == null){
    console.log('ratableWeight is invalid')
    return false;
  }

  if(!isNumber(this.profitPercentage)){
    console.log('profitPercentage is invalid')
    return false;
  }

  if(!isNumber(this.percentage)) {
    console.log('percentage is invalid')
    return false;
  }

  if(!isNumber(this.total)) {
    console.log('total is invalid')
    return false;
  }

  if(!isNumber(this.quantity)){
    console.log('quantity is invalid')
    return false;
  }

  if(!isNumber(this.discount)){
    console.log('discount is invalid')
    return false;
  }

  if(!isNumber(this.discountPercentage)){
    console.log('discountPercentage is invalid')
    return false;
  }

  if(!isNumber(this.id)){
    console.log('id is invalid')
    return false;
  }

  if(!isNumber(this.orderPriceId)){
    console.log('orderPriceId is invalid')
    return false;
  }


  if(!isNumber(this.profit)){
    console.log('profit is invalid')
    return false;
  }

  return true;
}

Place.prototype.validate = function () {
  if(!isNumber(this.id)){
    console.log('id is invalid')
    return false;
  }

  if(!isNumber(this.orderPriceId)){
    console.log('orderPriceId is invalid')
    return false;
  }

  if(!this.city){
    console.log('city is invalid')
    return false;
  }

  if(!this.address){
    console.log('address is invalid')
    return false;
  }

  if(!this.coordinates){
    console.log('coordinates is invalid')
    return false;
  }

  if(!this.country){
    console.log('country is invalid')
    return false;
  }

  if(!this.state){
    console.log('state is invalid')
    return false;
  }



  return true;
}

Port.prototype.validate = function () {
  if(!this.city){
    console.log('port city is invalid')
    return false;
  }

  if(!this.country){
    console.log('port country is invalid')
    return false;
  }

  if(!this.name){
    console.log('port name is invalid')
    return false;
  }

  return true;

}

PriceCondition.prototype.validate = function () {
  if(!isNumber(this.orderPriceId)){
    console.log('orderPriceId is invalid')
    return false;
  }

  if(!isNumber(this.demurrageCost)){
    console.log('demurrageCost is invalid')
    return false;
  }
  if(!isNumber(this.loadingTime)){
    console.log('loadingTime is invalid')
    return false;
  }
  if(!isNumber(this.dischargeTime)){
    console.log('dischargeTime is invalid')
    return false;
  }

  return true;
}

  Price.prototype.validate = function () {
    let errors = [];

    if(!isNumber(this.supplierId) || this.supplierId == 0){
      errors.push('Introdusca un provedor');
    }


  if(!isNumber(this.orderId) || this.orderId == 0){
    errors.push('orderId es Invalido')
  }

  if(!isNumber(this.transportationId) || this.transportationId == 0){
    errors.push('Tipo de proveedor es invalido')
  }

  if(!isNumber(this.destinationStorage)){
    errors.push('destinationStorage is invalid')
  }

  if(!isNumber(this.originStorage)){
    errors.push('originStorage is invalid')
  }

  /*if(!isNumber(this.freeDays)){
    console.log('freeDays is invalid')
    return false;
  }*/

  if(!isNumber(this.transportationTime) || this.transportationTime === 0){
    errors.push('transportationTime is invalid')
  }


  if(!this.validity) {
    errors.push('validity is invalid')
  }

  if(!this.priceCondition.validate()){
    errors.push('Price conditions is not valid');
  }

  let originValid = true;
  let destinationValid = true;

  this.pricesOrigins.forEach(place => {
    if(!place.validate())
      originValid = false
  })

  if(!originValid){
    errors.push('Origin is not valid')
  }

  this.pricesDestination.forEach(place => {
    if(!place.validate()){
      destinationValid = false
    }
  })

  if(!destinationValid){
    errors.push('Destination is not valid')
  }

  let priceFeesValid = true;

  this.priceFees.forEach(place => {
    if(!place.validate()){
      priceFeesValid = false
    }
  })

  if(!priceFeesValid){
    errors.push('Fees not valid')
  }
    return errors.length > 0 ? errors : true;
}

export default Price
