<template>
  <div>
    <v-card class="mb-7" v-if="dataItem.enable==true">
      <v-card-text>
        <v-row
            class="rate"
            align="center"
        >
          <v-col md="11">
            <v-row>
              <v-col md="2">
                <v-img
                    :src="dataItem.maritime.supplier.details.logo ? dataItem.maritime.supplier.details.logo : 'https://image.flaticon.com/icons/png/512/3321/3321752.png'"
                ></v-img>
              </v-col>
              <v-col>
                <v-row>
                  <v-col class="pb-0">
                    <v-flex>
                      <span class="text-h6">Fecha salida</span>
                      <v-slide-y-transition>
                        <div style="width: 50px" class="d-inline-block text-center">
                          <span class="text-h6 px-2" v-if="!dataItem.isExpanded">&#10230;</span>
                        </div>
                      </v-slide-y-transition>
                      <v-slide-y-transition>
                        <span class="text-h6" v-if="!dataItem.isExpanded">Fecha llegada</span>
                      </v-slide-y-transition>
                    </v-flex>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0">
                    <v-flex>
                      <span class="text-caption">{{ dataItem.maritime.pol.unlocs[0] + ' (' + dataItem.maritime.pol.name + ')'}}</span>
                      <div style="width: 50px" class="d-inline-block"></div>
                      <span class="text-caption" v-if="!dataItem.isExpanded">{{ dataItem.maritime.pod.unlocs[0] + ' (' + dataItem.maritime.pod.name + ')'}}</span>
                    </v-flex>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <div class="d-inline-block rate-transportation-timeline" style="position: relative">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="px-2"
                                  v-on="on"
                                  v-bind="attrs"
                                  v-ripple="false"
                                  @click="dataItem.services[0].value = !dataItem.services[0].value"
                                  v-bind:class="dataItem.services[0].value ? 'primary--text' : ''"
                          >mdi-checkbox-blank-circle-outline</v-icon>
                        </template>
                        <div>
                          <span>Pick Up</span>
                          <v-divider></v-divider>
                          <span>{{ variables.origin.city }}</span>
                        </div>
                      </v-tooltip>
                      <span
                          class="rate-services text-center"
                          v-bind:class="dataItem.services[0].value ? 'primary--text': ''"
                          v-bind:style="[!variables.origin.code ? {left: '-0.8em'} : '']"
                      >
                    {{ !variables.origin.code ? variables.origin.city : `${variables.origin.country}${variables.origin.code}`}}
                  </span>
                    </div>
                    <div class="d-inline-block rate-stepper-divider" style="position: relative">
                      <div class="divider-transportation w-100 text-center text-h5">
                        <i v-bind:class="dataItem.services[0].value ? 'primary--text': ''" class="las la-truck-loading"></i>
                      </div>
                      <v-divider
                          class="divider-width-2"
                          v-bind:class="dataItem.services[0].value ? 'border-primary': ''"
                      ></v-divider>
                    </div>
                    <div class="d-inline-block rate-transportation-timeline" style="position: relative">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="px-2"
                                  v-on="on"
                                  v-bind="attrs"
                                  v-ripple="false"

                                  v-bind:class="dataItem.services[1].value ? 'primary--text' : ''"
                          >mdi-checkbox-blank-circle-outline</v-icon>
                        </template>
                        <div>
                          <span>Port of Origin</span>
                          <v-divider></v-divider>
                          <span>{{ dataItem.maritime.pol.city }}</span>
                        </div>
                      </v-tooltip>
                      <span class="rate-services" v-bind:class="dataItem.services[1].value ? 'primary--text': ''">{{ !dataItem.maritime.pol.unlocs[0] ? dataItem.maritime.pol.name : dataItem.maritime.pol.unlocs[0] }}</span>
                    </div>
                    <div class="d-inline-block rate-stepper-divider" style="position: relative">
                      <div class="divider-transportation w-100 text-center text-h6">
                        <i v-bind:class="dataItem.services[2].value ? 'primary--text': ''" class="las la-ship"></i>
                      </div>
                      <v-divider
                          class="divider-width-2"
                          v-bind:class="dataItem.services[2].value ? 'border-primary': ''"
                      ></v-divider>
                    </div>
                    <div class="d-inline-block rate-transportation-timeline" style="position: relative">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="px-2"
                                  v-on="on"
                                  v-bind="attrs"
                                  v-ripple="false"
                                  v-bind:class="dataItem.services[2].value? 'primary--text' : ''"
                          >mdi-checkbox-blank-circle-outline</v-icon>
                        </template>
                        <div>
                          <span>Port of Discharge</span>
                          <v-divider></v-divider>
                          <span>{{ dataItem.maritime.pod.city }}</span>
                        </div>
                      </v-tooltip>
                      <span class="rate-services" v-bind:class="dataItem.services[3].value ? 'primary--text': ''">{{ !dataItem.maritime.pod.unlocs[0] ? dataItem.maritime.pod.name : dataItem.maritime.pod.unlocs[0] }}</span>
                    </div>
                    <div class="d-inline-block rate-stepper-divider" style="position: relative">
                      <div class="divider-transportation w-100 text-center text-h5 invert-icon">
                        <i v-bind:class="dataItem.services[4].value ? 'primary--text': ''" class="las la-truck-loading"></i>
                      </div>
                      <v-divider
                          class="divider-width-2"
                          v-bind:class="dataItem.services[4].value ? 'border-primary': ''"
                      ></v-divider>
                    </div>
                    <div class="d-inline-block rate-transportation-timeline" style="position: relative">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                              class="px-2"
                              v-on="on"
                              v-bind="attrs"
                              v-ripple="false"
                              @click="dataItem.services[4].value = !dataItem.services[4].value"
                              v-bind:class="dataItem.services[4].value ? 'primary--text' : ''"
                          >mdi-checkbox-blank-circle-outline</v-icon>
                        </template>
                        <div>
                          <span>Delivery</span>
                          <v-divider></v-divider>
                          <span>{{ variables.destiny.city }}</span>
                        </div>
                      </v-tooltip>
                      <span
                          class="rate-services text-center"
                          v-bind:class="dataItem.services[4].value ? 'primary--text': ''"
                          v-bind:style="[!variables.destiny.code ? {left: '-0.8em'} : '']"
                      >
                    {{ !variables.destiny.code ? variables.destiny.city : `${variables.destiny.country}${variables.destiny.code}`}}
                  </span>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="2" class="text-center my-auto">
                <div><span class=" font-weight-bold text-caption text-center">Salida: {{dataItem.frecuency}} </span></div>
                <div><v-btn color="primary" size="32"  outlined dark @click="selectRate">$ {{dataItem.maritime.total}} USD</v-btn></div>
              </v-col>
            </v-row>

          </v-col>




        </v-row>
        <v-expand-transition>
          <v-row v-if="dataItem.isExpanded">

            <v-col md="3">
              <v-autocomplete
                  label="Cliente"
                  dense
                  outlined
                  v-model="order.clientId"
                  :items="companies"
                  item-value="id"
                  item-text="name"
              ></v-autocomplete>
            </v-col>

            <v-col md="1">
              <v-dialog v-model="showAddCompany" width="1000">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon class="mt-1" v-on="on" v-bind="attrs">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="primary" icon fab size="30" v-bind="attrs"
                                v-on="on">mdi-plus-circle
                        </v-icon>
                      </template>
                      <span>Agregar</span>
                    </v-tooltip>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="primary white--text">
                    <v-icon color="white" left>mdi-table-edit</v-icon>
                    Añadir Cliente
                  </v-card-title>
                  <Customers></Customers>
                </v-card>
              </v-dialog>
            </v-col>

            <v-col md="3">
              <v-text-field v-model="order.commodity" dense outlined label="Commodity"></v-text-field>
            </v-col>

            <v-col md="3">
              <v-autocomplete outlined label="Industria"
                              dense
                              v-model="order.industryId"
                              :items="industries"
                              item-text="description"
                              item-value="id">
              </v-autocomplete>
            </v-col>

            <v-col md="4">
              <v-label class="mt-10">Cotizar sin pricer</v-label>
              <v-switch readonly v-model="order.assignToPricer" dense color="primary" inset></v-switch>
            </v-col>

            <v-col md="8">
              <v-textarea
                  outlined
                  v-model="order.comments"
                  name="input-7-1"
                  label="Comentarios"
                  hint="Hint text"
              ></v-textarea>
            </v-col>

            <v-col md="6">
              <v-tabs fixed-tabs>
                <v-tab>Fees</v-tab>
                <v-tab>Vessel Fees</v-tab>
                <v-tab-item>

                  <v-data-table
                      :hide-default-footer="true"
                      :headers="headersFees"
                      :items="feeContainer"
                  >

                  </v-data-table>

                </v-tab-item>
                <v-tab-item>

                  <v-data-table
                      :hide-default-footer="true"
                      :headers="headersVesselFees"
                      :items="dataItem.maritime.vesselFees"
                  >

                  </v-data-table>

                </v-tab-item>
              </v-tabs>
            </v-col>

            <v-col md="6" align="center">
              <v-btn  color="primary" size="30" @click="createOrder">Book Now!</v-btn>
            </v-col>

            <v-col md="6" class="overflow-y-scroll" style="overflow-y: scroll; max-height: 250px" >
              <div
                  v-for="item in dataItem.origin"
                  :key="item._id"
              >
                <v-list-item
                    class="pa-0 elevation-0"
                    style="height: 50px"
                >
                  <v-expansion-panels class="elevation-0">
                    <v-expansion-panel class="pa-0 elevation-0">

                      <v-expansion-panel-header  class="px-3 py-1 expansion-fee-header elevation-0" >


                        <v-checkbox
                            class="mt-0"
                        >
                          <template slot="label">
                            <div class="text-h6">
                              <i class="las la-truck primary--text"></i>
                            </div>
                            <div class="w-100 pl-1 primary--text">
                              <span>Pick up ({{item.supplier.name}})</span>
                              <div class="text-right">
                                ${{MapLandPrice(item)}}
                              </div>
                            </div>
                          </template>
                        </v-checkbox>


                        <!--                <template v-slot:actions>-->
                        <!--                  <span class="ma-auto primary--text font-weight-bold">${{origin.container[0].price}}</span>-->
                        <!--                  <v-icon class="primary--text">mdi-menu-down</v-icon>-->
                        <!--                </template>-->
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        {{item}}
                        <!--                <v-row-->
                        <!--                    v-for="origin in data.origin"-->
                        <!--                    :key="origin"-->
                        <!--                    align-content="center"-->
                        <!--                    no-gutters-->
                        <!--                >-->
                        <!--                  {{data}}-->
                        <!--                </v-row>-->
                        <!--                <v-row-->
                        <!--                    v-for="fee in origin.container[0].fees.filter(x => parseInt(x.amount) > 0)"-->
                        <!--                    :key="origin._id+'rate_container'+fee.type"-->
                        <!--                    align-content="center"-->
                        <!--                    no-gutters-->
                        <!--                >-->
                        <!--                  <v-col-->
                        <!--                      md="8"-->
                        <!--                      align-self="center"-->
                        <!--                  >-->
                        <!--                    <v-checkbox-->
                        <!--                        v-model="originFeesSelected"-->
                        <!--                        class="mt-1 pt-1"-->
                        <!--                        :value="fee.type"-->
                        <!--                    >-->
                        <!--                      <template slot="label">-->
                        <!--                        <span class="text-truncate fee-name primary&#45;&#45;text">{{fee.type + ' - ' + fee.name}}</span>-->
                        <!--                      </template>-->
                        <!--                    </v-checkbox>-->
                        <!--                  </v-col>-->
                        <!--                  <v-col-->
                        <!--                      md="4"-->
                        <!--                      class="text-right"-->
                        <!--                  >-->
                        <!--                    <div class="mt-1 pt-1">-->
                        <!--                      <label class="text-right pr-1 primary&#45;&#45;text font-weight-bold">$ {{fee.amount_usd}}</label>-->
                        <!--                      <label class="text-right price-label primary&#45;&#45;text font-weight-bold">USD</label>-->
                        <!--                    </div>-->
                        <!--                  </v-col>-->
                        <!--                </v-row>-->
                        <v-row no-gutters><v-divider></v-divider></v-row>
                        <!--                <v-row class="mt-0">-->
                        <!--                  <v-container-->
                        <!--                      v-for="(fee, index) in data.maritime.vesselFees.filter(x => x.amount > 0)"-->
                        <!--                      :key="data.maritime._id+'rate_vessel'+fee.description"-->
                        <!--                      class="w-100 pb-0"-->
                        <!--                  >-->
                        <!--                    <v-row-->
                        <!--                        align-content="center"-->
                        <!--                        no-gutters-->
                        <!--                    >-->
                        <!--                      <v-col-->
                        <!--                          md="8"-->
                        <!--                      >-->
                        <!--                        <v-checkbox-->
                        <!--                            v-model="originFeesSelected"-->
                        <!--                            class="mt-0 pt-0"-->
                        <!--                            :value="fee.description"-->
                        <!--                        >-->
                        <!--                          <template slot="label">-->
                        <!--                            <label class="text-truncate primary&#45;&#45;text fee-name">{{fee.description}}</label>-->
                        <!--                          </template>-->
                        <!--                        </v-checkbox>-->
                        <!--                      </v-col>-->
                        <!--                      <v-col md="4" class="text-right">-->
                        <!--                        <label class="text-left pr-1 primary&#45;&#45;text font-weight-bold">$ {{fee.amount}}</label>-->
                        <!--                        <label class="text-right price-label primary&#45;&#45;text font-weight-bold">USD</label>-->
                        <!--                      </v-col>-->
                        <!--                    </v-row>-->
                        <!--                    <v-row-->
                        <!--                        no-gutters-->
                        <!--                        v-if="index < (data.maritime.vesselFees.filter(x => x.amount > 0).length - 1)"-->
                        <!--                    >-->
                        <!--                      <v-divider></v-divider>-->
                        <!--                    </v-row>-->
                        <!--                  </v-container>-->
                        <!--                </v-row>-->
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <!--            <v-expansion-panel>-->
                    <!--              <v-expansion-panel-header  class="px-3 py-1 expansion-fee-header" color="blue-grey lighten-5">-->
                    <!--                <v-checkbox-->
                    <!--                    class="mt-0"-->
                    <!--                >-->
                    <!--                  <template slot="label">-->
                    <!--                    <div class="text-h6">-->
                    <!--                      <i class="las la-anchor primary&#45;&#45;text"></i>-->
                    <!--                    </div>-->
                    <!--                    <span class="w-100 pl-1 primary&#45;&#45;text">Port of origin (Altamira)</span>-->
                    <!--                  </template>-->
                    <!--                </v-checkbox>-->
                    <!--                <template v-slot:actions>-->
                    <!--                  <span class="ma-auto primary&#45;&#45;text font-weight-bold">$471</span>-->
                    <!--                  <v-icon class="primary&#45;&#45;text">mdi-menu-down</v-icon>-->
                    <!--                </template>-->
                    <!--              </v-expansion-panel-header>-->
                    <!--              <v-expansion-panel-content>-->
                    <!--                <v-row-->
                    <!--                    v-for="fee in data.maritime.container[0].fees.filter(x => x.amount_usd > 0)"-->
                    <!--                    :key="data.maritime._id+'rate_container'+fee.type"-->
                    <!--                    align-content="center"-->
                    <!--                    no-gutters-->
                    <!--                >-->
                    <!--                  <v-col-->
                    <!--                      md="8"-->
                    <!--                      v-if="fee.amount_usd > 0"-->
                    <!--                      align-self="center"-->
                    <!--                  >-->
                    <!--                    <v-checkbox-->
                    <!--                        v-model="originFeesSelected"-->
                    <!--                        class="mt-1 pt-1"-->
                    <!--                        :value="fee.type"-->
                    <!--                    >-->
                    <!--                      <template slot="label">-->
                    <!--                        <span class="text-truncate fee-name">{{fee.type + ' - ' + fee.name}}</span>-->
                    <!--                      </template>-->
                    <!--                    </v-checkbox>-->
                    <!--                  </v-col>-->
                    <!--                  <v-col-->
                    <!--                      md="4"-->
                    <!--                      class="text-right"-->
                    <!--                  >-->
                    <!--                    <div class="mt-1 pt-1">-->
                    <!--                      <label class="text-right pr-1 primary&#45;&#45;text font-weight-bold">$ {{fee.amount_usd}}</label>-->
                    <!--                      <label class="text-right price-label primary&#45;&#45;text font-weight-bold">USD</label>-->
                    <!--                    </div>-->
                    <!--                  </v-col>-->
                    <!--                </v-row>-->
                    <!--                <v-row no-gutters><v-divider></v-divider></v-row>-->
                    <!--                <v-row class="mt-0">-->
                    <!--                  <v-container-->
                    <!--                      v-for="(fee, index) in data.maritime.vesselFees.filter(x => x.amount > 0)"-->
                    <!--                      :key="data.maritime._id+'rate_vessel'+fee.description"-->
                    <!--                      class="w-100 py-1"-->
                    <!--                  >-->
                    <!--                    <v-row-->
                    <!--                        align-content="center"-->
                    <!--                        no-gutters-->
                    <!--                    >-->
                    <!--                      <v-col-->
                    <!--                          md="8"-->
                    <!--                          class="py-1"-->
                    <!--                      >-->
                    <!--                        <v-checkbox-->
                    <!--                            v-model="originFeesSelected"-->
                    <!--                            class="mt-0 pt-0"-->
                    <!--                            :value="fee.description"-->
                    <!--                        >-->
                    <!--                          <template slot="label">-->
                    <!--                            <span class="text-truncate fee-name">{{fee.description}}</span>-->
                    <!--                          </template>-->
                    <!--                        </v-checkbox>-->
                    <!--                      </v-col>-->
                    <!--                      <v-col md="4" class="text-right py-1">-->
                    <!--                        <label class="text-left pr-1 primary&#45;&#45;text font-weight-bold">$ {{fee.amount}}</label>-->
                    <!--                        <label class="text-right price-label primary&#45;&#45;text font-weight-bold">USD</label>-->
                    <!--                      </v-col>-->
                    <!--                    </v-row>-->
                    <!--                    <v-row-->
                    <!--                        no-gutters-->
                    <!--                        v-if="index < (data.maritime.vesselFees.filter(x => x.amount > 0).length - 1)"-->
                    <!--                    >-->
                    <!--                      <v-divider></v-divider>-->
                    <!--                    </v-row>-->
                    <!--                  </v-container>-->
                    <!--                </v-row>-->
                    <!--              </v-expansion-panel-content>-->
                    <!--            </v-expansion-panel>-->
                  </v-expansion-panels>




                </v-list-item>
              </div>
            </v-col>




          </v-row>
        </v-expand-transition>
      </v-card-text>

    </v-card>
  </div>



</template>

<script>
import Rate from './../../prototypes/rate';
import Customers from "../Accounts_Admin/Customers";

export default {
  name: "RateCard",
  components: {Customers},
  data: () => ({
    rate: {},
    Suppliers:[],
    Currencies:[],
    feeContainer:[],
    orderCreated:{},
    isExpanded: false,
    headersFees:[
      {
        text: 'Fee',
        value: 'type',
      },
      { text: 'Amount', value: 'amount' },

    ],
    headersVesselFees:[
      {
        text: 'VesselFee',
        value: 'description',
      },
      { text: 'Amount', value: 'amount' },
      { text: 'Currency', value: 'currency' },
    ],
    vesselFees:[],
    order: {
      transportationId: null,
      transportationCategoryId: null,
      assignToPricer: false,
      LandTransportation: null,
      landDedicated: false,
      LandTransference: false,
      incotermId: null,
      orderTypeId: null,
      origin: [{
        country: null,
        city: null,
        address: null,
        state: null,
        coordinates: null
      }],
      destination: [{
        country: null,
        city: null,
        address: null,
        state: null,
        coordinates: null
      }],
      pol: null,
      pod: null,
      departureDate: null,
      industryId: null,
      budget: null,
      commodity: null,
      insideBillOfLanding: "true",
      cargo: [


      ],
      Services: [],
      clientId: null,
      pricerId: null,
      Frequency: 1,
      urgent: false,
      comments: null,
      document: [],
      documentsDrag: []
    },
    services:[
      {
        id:1,
        name:"originSelected",
        value:false
      },
      {
        id:2,
        name:"originPortSelected",
        value:false
      },

      {
        id:3,
        name:"internationalTransportation",
        value:true
      },

      {
        id:4,
        name:"s",
        value:false
      }
    ],
    originSelected: false,
    showAddCompany:false,
    industries:[],
    originFeesSelected: [],
    containers:[],
    companies:[],
    originPortSelected: false,
    originPortFeesSelected: [],
    internationalTransportation: true,
    internationalFeesSelected: [],
    destinationPortSelected: false,
    destinationPortFeesSelected: [],
    destinationSelected: false,
    destinationFeesSelected: [],
    origin: {},
    originPort: {},
    international: {},
    destination: {},
    destinationPort: {},
  }),
  props: {
    dataItem: Object,
    variables: {
      type: Object,
      default: () => {}
    },
    transportation: {
      type: String,
      default: "SEA"
    },
    QuotData:{
      type:Object
    },
    cargo:{
      type:Array
    },
  },
  watch: {
    originSelected: function() {
      if(!this.originSelected){
        this.isExpanded = true;
      }
    },
    dataItem: {
      handler() {
        console.log('data changed')
        this.getFeesfromContainers();
      },
      deep: true
    },

  },
  methods: {
    showRateDetails: function(){
      this.dataItem.isExpanded = !this.dataItem.isExpanded;
    },

    getFeesfromContainers(){
     this.dataItem.maritime.container.forEach(container => {
        container.fees.forEach(containerFee =>{
          this.feeContainer.push(containerFee);
        })
      })
      console.log(this.feeContainer)
    },


    getCustomers: function () {
      this.$store.dispatch('working', true)
      this.$http.get('Companies', {
        headers: {
          'Cache-Control': 'max-age=5'
        }
      })
          .then(response => {
            this.showAddCompany = false
            this.companies = response.data.data;
          })
          .catch(error => {
            console.log(error)
            if (error.response.status == 401) {
              this.$store.commit('reset');
              this.$toast.error("La sesion expiro");
              this.$router.replace('/login')
            }
          }).finally(() => this.$store.dispatch('working', false))
    },

    getIndustries: function () {
      this.$store.dispatch('working', true)

      this.$http.get('Industries')
          .then(response => {
            this.industries = response.data.data;
          })
          .catch(error => {
            console.log("error industries",error)
          }).finally(() => this.$store.dispatch('working', false))
    },

    getContainers: function () {
      this.$http.get(`Containers/${1}`)
          .then(response => {
            this.containers = [];
            this.Containers = response.data.data;
            //this.Containers = this.Containers.filter(element => element.transportationId === 3)
            // console.log(this.containers)

          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.loading = false)
    },

    createOrder(){
      this.order.incotermId = this.QuotData.Inconterm;
      this.order.transportationId = this.QuotData.Typetransportation;
      this.order.transportationCategoryId = this.QuotData.TypeService
      this.order.departureDate = this.QuotData.dueDate;
      this.order.orderTypeId = this.QuotData.Type;

      this.order.origin[0].country = this.QuotData.origin.country
      this.order.origin[0].city = this.QuotData.origin.city
      this.order.origin[0].address =this.QuotData.origin.address
      this.order.origin[0].state = this.QuotData.origin.state
      this.order.origin[0].coordinates = `${this.QuotData.origin.coordinates.lat},${this.QuotData.origin.coordinates.lng}`

      this.order.destination[0].country = this.QuotData.destination.country
      this.order.destination[0].city = this.QuotData.destination.city
      this.order.destination[0].address = this.QuotData.destination.address
      this.order.destination[0].state = this.QuotData.destination.state
      this.order.destination[0].coordinates = `${this.QuotData.destination.coordinates.lat},${this.QuotData.destination.coordinates.lng}`

      if (Object.keys(this.QuotData.OriginPort).length > 0) {
        this.order.pol = {}
        this.order.pol.country = this.QuotData.OriginPort.country
        this.order.pol.city = this.QuotData.OriginPort.city
        this.order.pol.name = this.QuotData.OriginPort.name
        this.order.pol.coordinates = `${this.QuotData.OriginPort.lon},${this.QuotData.OriginPort.lat}`
      }

      if (Object.keys(this.QuotData.DestinationPort).length > 0) {
        this.order.pod = {}
        this.order.pod.country = this.QuotData.DestinationPort.country
        this.order.pod.city = this.QuotData.DestinationPort.city
        this.order.pod.name = this.QuotData.DestinationPort.name
        this.order.pod.coordinates = `${this.QuotData.DestinationPort.lon},${this.QuotData.DestinationPort.lat}`

      }

      if (this.order.budget == null)
        delete this.order.budget;

      if (this.order.insideBillOfLanding == "true")
        this.order.insideBillOfLanding = true;

      if (this.order.insideBillOfLanding == "false")
        this.orders.insideBillOfLanding = false;

      if (!this.order.assignToPricer)
        delete this.order.pricerId;

      delete this.order.documentsDrag;

      this.setServices();
      this.setCargo();



      this.$http.post('Orders', this.order).then(response => {
        this.$store.dispatch('working',true);
        if (response.data.code === 200) {
          this.orderCreated = response.data.data
          this.$toast.success("Cotizacion Enviada a Pricing")
          //this.$emit('OrderCreated', response.data.data);

           this.$router.replace(`/ProceedQuoteSales/${response.data.data.id}`);

          this.createRatePrice();
        }
      }).catch(error => {
        this.$toast.error("¡Ups! No pasó tu cotización. Nuestro equipo ya fue notificado")
        console.log(error);
      }).finally(() => {
        this.$store.dispatch('working', false)
      })
    },

    createRatePrice(){


      let pol = null;
      let pod = null;

      if (Object.keys(this.QuotData.OriginPort).length > 0) {
        pol = {}
        pol.country = this.QuotData.OriginPort.country
        pol.city = this.QuotData.OriginPort.city
        pol.name = this.QuotData.OriginPort.name
        pol.coordinates = `${this.QuotData.OriginPort.lon},${this.QuotData.OriginPort.lat}`
      }


      if (Object.keys(this.QuotData.DestinationPort).length > 0) {
        pod = {}
        pod.country = this.QuotData.DestinationPort.country
        pod.city = this.QuotData.DestinationPort.city
        pod.name = this.QuotData.DestinationPort.name
        pod.coordinates = `${this.QuotData.DestinationPort.lon},${this.QuotData.DestinationPort.lat}`

      }


      let priceOrigins = null;
      let priceDestination = null;


      priceOrigins = [{
        Address: this.QuotData.origin.address,
        City: this.QuotData.origin.city,
        state: this.QuotData.origin.state,
        Country: this.QuotData.origin.country,
        coordinates: `${this.QuotData.origin.coordinates.lat},${this.QuotData.origin.coordinates.lng}`,
        port:pol
      }]

      priceDestination = [{
        Address: this.QuotData.destination.address,
        City: this.QuotData.destination.city,
        state: this.QuotData.destination.state,
        Country: this.QuotData.destination.country,
        coordinates: `${this.QuotData.destination.coordinates.lat},${this.QuotData.destination.coordinates.lng}`,
        port:pod
      }]

      //let supplier = null;
      let supplier = this.Suppliers.find(element=>element.companyName == this.dataItem.maritime.supplier.name );
      let prices = [];
      let pricesFees = [];
      this.cargo.forEach(element => {
        let index = this.Currencies.findIndex(e => e.code == element.currency)
        pricesFees.push(
            {
              id: 0,
              feeId: null,
              name: element.name,
              quantity: element.quantity,
              currencyId: this.Currencies[index].id,
              price:element.price,
              percentage: 0,
              profitPercentage: 0,
              discount: 0,
              total: 0,
              isVisible: true,
            }
        )
      })

      this.dataItem.maritime.vesselFees.forEach(element => {
        let index = this.Currencies.findIndex(e => e.code == element.currency)
        pricesFees.push(
            {
              id: 0,
              feeId: null,
              name: element.description,
              quantity: 1,
              currencyId:this.Currencies[index].id,
              price:element.amount,
              percentage: 0,
              profitPercentage: 0,
              discount: 0,
              total: 0,
              isVisible: true,
            }
        )
      })

      prices.push({
        id: 0,
        dense: true,
        name: "StandardFCLRate",
        supplierName: supplier.companyName,
        containerId: null,
        transportationId: this.orderCreated.transportationId,
        total: 0,
        freeDays: null,
        showTotalPrice: true,
        destinationStorage: null,
        originStorage: null,
        isUpdating: false,
        supplierId: supplier.id,
        priceConditions: {
          orderPriceId: 0,
          demurrageCost: 0,
          loadingTime: 0,
          dischargeTime: 0,
        },
        supplierTypeSelected: "",
        orderId: this.orderCreated.id,
        validity: '2021-12-30',
        departureTime: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        DepartureDate: '2021-07-19',
        transportationTime: 5,
        supplierSearch: "",
        supplierSearchResults: [],
        optionsMenuOpen: false,
        searchingSupplier: false,
        suggested: false,
        confirmedPricing: true,
        confirmedSales: false,
        expansion: true,
        isTotalPrice: this.orderCreated.transportationCategoryId === 1,
        priceFees: pricesFees,
        pricesOrigins: priceOrigins,
        pricesDestination: priceDestination,
        priceDocuments: [],
        priceDocumentsDrag: [],
        originItems: [],
        destinationItems: [],
        destinationSelections: {},
        originSelections: {},
        destinationAddress: "",
        originAddress: "",
      })




      this.$http.put(`Orders/${this.orderCreated.id}/prices`,prices).then(response => {
        console.log(response.data)
        this.$store.dispatch('working',true);
        this.$toast.info('Precio creado')
      }).catch( error => {
        this.$toast.error('Error al generar precio')
        console.log(error)
      }).finally(() =>{
        this.$store.dispatch('working',false);
      })

    },

    getSuppliers: function () {
      this.$store.dispatch('working', true);
      this.$http.get('Suppliers', {
        headers: {
          'Cache-Control': 'max-age=30'
        }
      }).then(response => {
        if (response.data.code == 200) {
          this.Suppliers = response.data.data;
        }

        if (response.data.code == 400 || response.data.code == 500)
          this.$toast.error("Algo salio mal, intente mas tarde")
      }).catch(error => {

        console.log(error);
      }).finally(() => {
        this.$store.dispatch('working', false);
      })
    },

    setServices(){
      let services = [];

      this.dataItem.services.forEach(element => {
        if (element.value)
          services.push(element)
      })

      this.order.Services = [];

      services.forEach(element => {

        if (element.name == "Recoleccion" ){
          this.order.Services.push(
              {
                ServiceId: 2
              }
          )
        }

        if (element.name == "gastosEnOrigen" ){
          this.order.Services.push(
              {
                ServiceId: 4
              }
          )
        }

        if (element.name == "internationalMaritimeTransportation" ){
          this.order.Services.push(
              {
                ServiceId: 5
              }
          )
        }

        if (element.name == "gastosEnDestino" ){
          this.order.Services.push(
              {
                ServiceId: 6
              }
          )
        }

        if (element.name == "Entrega" ){
          this.order.Services.push(
              {
                ServiceId: 13
              }
          )
        }


      })
    },

    setCargo(){

      this.cargo = this.cargo.filter(element => element.quantity > 0)

      this.order.cargo = JSON.parse(JSON.stringify(this.cargo));

      this.order.cargo.forEach(element => {
        delete element.container;
        delete element.name
        delete element.price
        delete element.currency
      })
    },


  /*  mapRate: function(){
      console.log('Mapping rate')
      this.rate = this.dataItem
      this.rate.selected = false;

      this.rate.origin.forEach(item => {
        if(this.rate['bestOffer']['originId'] && item._id === this.rate['bestOffer']['originId']) {
          console.log('Best origin present')
          console.log(this.rate['bestOffer'])
          this.originSelected = true
          item.selected = true;
        }
        else
          item.selected = false;
      })
    },*/
    MapLandPrice:function (origin){
      let container = origin.container.filter((x) => x.type === "20DC")[0]
      return container.prices.filter(o => o.type === "OW")[0].amount
    },
    selectRate: function(){



        this.$emit('showRateOptions',this.dataItem)



      let rate = new Rate();
      let totalPrice = 0;

      rate.maritime.
          supplier = this.dataItem.supplier.name;

      rate.maritime.pricesOrigins = [{
        port: {
          city: this.dataItem.pol.city,
          name: this.dataItem.pol.name,
          country: this.dataItem.pol.country,
          coordinates: ""
        }
      }];
      rate.maritime.pricesDestination = [{
        port: {
          city: this.dataItem.pol.city,
          name: this.dataItem.pol.name,
          country: this.dataItem.pol.country,
          coordinates: ""
        }
      }];

      rate.maritime.priceFees = [];

      let feesSelected = this.dataItem.container.filter(x => x['fees'].selected);
      let vesselFeesSelected = this.dataItem.container.filter(x => x['vesselFees'].selected);

      feesSelected.forEach(fee => {
        rate.maritime.priceFees.push({
          quantity: 1,
          currency: fee.currency,
          isVisible: true,
          percentage: 1,
          price: fee.amount,
          total: fee.amount
        })
        totalPrice += fee.amount;
      })

      vesselFeesSelected.forEach(fee => {
        rate.maritime.priceFees.push({
          quantity: 1,
          currency: fee.currency,
          isVisible: true,
          percentage: 1,
          price: fee.amount,
          total: fee.amount
        })

        totalPrice += fee.amount;
      })

      rate.maritime.total = totalPrice;

      this.$emit('click', rate)
    },
    getCurrencies() {
      this.$store.dispatch('working', true);
      this.$http.get('currencies').then(response => {
        if (response.data.code == 200) {
          this.Currencies = response.data.data;
        }
      })
    },
  },
  mounted() {
    this.getIndustries();
    this.getCustomers();
    this.getCurrencies();
    this.getSuppliers();
    this.getContainers();
    this.getFeesfromContainers();
   // this.mapRate();

  }
}
</script>

<style scoped>
>>> .v-input--checkbox .v-input__control{
  width: 200px;
}

>>> .v-input--checkbox .v-input__control .v-messages{
  display: none
}

.fee-name{
  max-width: 250px;
}

.price-label{
  min-width: 3rem;
}

.full-width{
  width:100%;
}

.rate{
  min-height: 150px;
  min-width: 400px;
}

.rate-stepper-divider{
  min-width: 20%;
}

.divider-width-2{
  border-width: 1px;
}

.divider-transportation{
  position: absolute;
  top: -2rem
}
.btn-selected {
  background-color: #bdbdbd;
}

.rate-services{
  left: -0.5em;
  position: absolute;
  bottom: -1.5em;
  right: 0;
  width: 4rem;
}

.w-100{
  width: 100%;
}

.rate-transportation-timeline >>> .v-icon:after{
  background-color: transparent;
}

.invert-icon>i{
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.expansion-fee-header >>>.v-input--selection-controls {
  width: 100%;
}
</style>
